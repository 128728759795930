import React,{ useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Typography, Divider, Snackbar, Alert } from '@mui/material';
import {SaveIcon,CancelIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { AreaNegocioService } from '@/service/services/AreaNegocio.service';


type ModalProps = {
   open: boolean,
   setOpen:any,
   addAreaNegocio:any,
   updteAreaNegocio:any,
   editArea:any,
   action:any
 }
export const ModalAddAreaNegocio:React.FC<ModalProps> = (
   props: ModalProps
 ): JSX.Element => {
  const [error, setError] = useState(null);
  const [newArea, setnewArea] = useState<any>('');

   const handleInputChange = (e) => {
      setnewArea(e.target.value);
      // if(newArea.length>0)
      // {
      //    setError('');
      // }
   }

   useEffect(()=> {
    if(props.action == 'add'){
      setnewArea('')
    }
   },[props.action])

   useEffect(() => {
      if(!!props.editArea){
        setnewArea(props.editArea.descripcion)
      }
   }, [props.editArea])

  const AddAreaNegocio = async () =>{
     if(newArea.trim())
     {
      if(props.editArea.action=='edit')
      {
         props.updteAreaNegocio(newArea,props.editArea.idarea_negocio)
      }else
      {
       props.addAreaNegocio(newArea)
      }
     }else
     {
      console.log('Error al Guardar');
      setError('area')
     }
  }

  const bodyModal=(
   <Box className='Modal'>
   <Typography id="modal-modal-title" variant="h6" component="h1" textAlign='center'>
     <strong>Crear Área de Negocio</strong>
   </Typography>
      <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12}>
          <TextField
            margin="normal"
            error={error === "area" ? true : false}
            helperText={error === "area" ? " Nombre Area Negocio es un campo requerido" : ""}
            sx={{bgcolor:'#fff'}}
            fullWidth
            id="area"
            label="Nombre Área"
            name="area"
            autoComplete="area"
            value={newArea}
            onChange={handleInputChange}
            autoFocus
          />
          </Grid>
      </Grid>
      <Divider />
        <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
          <Grid item >
            <Button
              variant='contained'
              onClick={() => {props.setOpen(false) }}
              endIcon={<CancelIcon />}
              sx={{
               background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                  bgcolor: '#bf6c00'
               }
            }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              type='submit'
              endIcon={<SaveIcon />}
              onClick={()=>AddAreaNegocio()}
              sx={{background:'#155172', mt: "10px",color:'#fff', '&:hover':{
               bgcolor:'#155172'
              }}}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
  )

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => { props.setOpen(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         {bodyModal}
      </Modal>

    </div>
  );
}
