import logokyte from "@assets/img/vacio.png";

export const Nfound = () => {
   return (
      <div className="body_">
         <div className="maintenance-container">
            <img src={logokyte} alt="Icono de Kyte" className="maintenance-image" />
            {/* <h1>KYTE CRM</h1> */}
            <p>
               Lamentamos informarle que la campaña a la que intenta acceder ya no está disponible, ya que ha concluido o no existe en la actualidad. Agradecemos su interés y le recomendamos estar atento a futuras iniciativas y campañas que puedan estar en curso.</p>
         </div>
      </div>
   );
}
