import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel,Autocomplete, FormControl, Grid, Typography, InputAdornment, Chip, IconButton, FormHelperText } from '@mui/material'
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import './Modal.sass';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WorkIcon from '@mui/icons-material/Work';
import DescriptionIcon from '@mui/icons-material/Description';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { proyeccionService } from '@/service/services/Proyeccion.service';
import moment from 'moment';
import AssignmentIcon from '@mui/icons-material/Assignment';

const ListItem = styled('li')(({ theme }) => ({
   margin: theme.spacing(0.5),
}));

const initialFormValues = {
   nombre: "",
   tipoproyeccion: 0,
   prioridad: 0,
   area: 0,
   fechainicio: "",
   fechafin: "",
   descripcion: "",
   hito: ""
}

const TypeTask = [
   { id: 1, title: 'Llamada' },
   { id: 2, title: 'Correo' },
   { id: 4, title: 'Otro' }
];
type ModalProps = {
   openNewCampaing: boolean,
   setOpenNewCampaing: React.Dispatch<React.SetStateAction<boolean>>,
   renderProjection?: () => void
}
export const ModalAddTaskProjection: React.FC<ModalProps> = ({
   openNewCampaing,
   setOpenNewCampaing,
   renderProjection
}): JSX.Element => {
   const [formValues, setFormValues] = useState(initialFormValues);
   const { nombre, tipoproyeccion = "", prioridad = "", area = "", fechainicio = "", fechafin = "", descripcion = "", hito = "" } = formValues;
   const [openCombo, setOpenCombo] = useState(false);
   const [dataInicial, setDataInicial] = useState<any>({
      company_area: [],
      proyeccion_type: [],
      prioridad: []
   });
   const [error, setError] = useState(null);
   const [areaNegocio, setAreaNegocio] = useState<any>([]);
   // /pppppppppppppppppp
   const [chipData, setChipData] = React.useState([
      // { key: 0, label: 'Angular' },
      // { key: 1, label: 'jQuery' },
      // { key: 2, label: 'Polymer' },
      // { key: 3, label: 'React' },
      // { key: 4, label: 'Vue.js' },
   ]);
   const handleDelete = (chipToDelete) => () => {
      setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
   };
   const [iterador, setiterador] = React.useState(0);
   const [valueHito, setValueHito] = React.useState(`${hito}`);

   useEffect(() => {
      //console.log('xd')
      if(openNewCampaing){
         proyecciondata();
      }

   }, [openNewCampaing])

   const proyecciondata = async () => {
      const dataInicial = await proyeccionService.getProyeccionData();
      //console.log("ProyeccionData", dataInicial)
      setDataInicial(prev=>({...prev,company_area:dataInicial.data.company_area,prioridad:dataInicial.data.prioridad,proyeccion_type:dataInicial.data.proyeccion_type}))
   };

   const setValue = (iterador, valorEntrante) => {
      setiterador(iterador + 1);
      setValueHito(valorEntrante);
      setChipData(
         [
            ...chipData,
            { id: iterador, nombre: `${hito}` }
         ]
      );
      setFormValues(prev=>({...prev,hito:''}));
      // localStorage.setItem("array", JSON.stringify(chipData));
      //console.log(chipData);

   }

   const handleInputChange = (e) => {
      const changedFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }
      nombre.trim() !== "" && setError("")
      prioridad !== null && setError("");
      tipoproyeccion !== null && setError("");
     // area !== null && setError("");
      fechainicio.trim() !== "" && setError("")
      fechafin.trim() !== "" && setError("")
      descripcion.trim() !== "" && setError("")
      hito.trim() !== "" && setError("")
      setFormValues(changedFormValues);
   }
   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      let now = moment().format('YYYY-MM-DD')
      if (!nombre.trim()) {
         return setError("nombre");
      }
      if (tipoproyeccion==0) {
         return setError("tipoproyeccion");
      }
      if (prioridad==0) {
         return setError("prioridad");
      }
      // if (area==0) {
      //    return setError("area");
      // }
      if (moment(fechainicio).format('YYYY-MM-DD')<now)
      {
         return setError("rangoA");
       }
      if (!fechainicio.trim()) {
         return setError("fechainicio");
      }
      if (!fechafin.trim()) {
         return setError("fechafin");
      }
      if (!descripcion.trim()) {
         return setError("descripcion");
      }
      if (chipData.length==0) {
         return setError("hito");
      }
      else {
         console.log(areaNegocio);
         const idsArea=[]
         areaNegocio.map((area)=>{
            idsArea.push(area.idarea_negocio);
         })
         const values = {
            ...formValues,
            idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
            hito:JSON.stringify(chipData).replace(/\\n/g, '')
         } || {}

         console.log({ values })
         const resp = await proyeccionService.createProyeccion(values)
         setFormValues(initialFormValues);
          setOpenNewCampaing(false)
         if(renderProjection){
            renderProjection()
         }
      }

   }


   return (
      <>

         <Modal
            open={openNewCampaing}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box className='Modal'>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column'
                  }}>
                  <Grid mb={1}>
                     <Typography id="modal-modal-title" textAlign='center' sx={{color:'#3F9BCC', fontWeight:'700', fontSize:'2em'}}>
                     Nueva Proyección
                     </Typography>

                  </Grid>
                  <form onSubmit={handleSubmit} >
                     <Grid container direction="row" spacing={2} mt={1}>
                        <Grid item xs={12}>
                           <TextField
                              fullWidth
                              error={error === "nombre" ? true : false}
                              helperText={error === "nombre" ? " El nombre es un campo requerido" : ""}
                              size="small"
                              id="nombre"
                              placeholder="Nombre*"
                              sx={{ bgcolor: '#fff' }}
                              name="nombre"
                              type="text"
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <AutoGraphIcon />
                                    </InputAdornment>
                                 ),
                              }}
                              value={nombre}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <FormControl fullWidth error={error === "tipoproyeccion" ? true : false}>
                              <InputLabel id="tipoproyeccion">Tipo</InputLabel>
                              <Select
                                 labelId="tipo"
                                 id="tipoproyeccion"
                                 sx={{bgcolor:'#fff'}}
                                 size="small"
                                 fullWidth
                                 name="tipoproyeccion"
                                 value={tipoproyeccion}
                                 label="tipo"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    <InputAdornment position='start'>
                                    <AssignmentIcon />
                                 </InputAdornment>
                                 }
                              >
                                 {dataInicial.proyeccion_type.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "tipoproyeccion" ? "Tipo Proyeccion es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={error === "prioridad" ? true : false}>
                              <InputLabel id="prioridad">Prioridad</InputLabel>
                              <Select
                                 labelId="prioridad"
                                 id="prioridad"
                                 sx={{bgcolor:'#fff'}}
                                 size="small"

                                 fullWidth
                                 name="prioridad"
                                 value={prioridad}
                                 label="Prioridad"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    <InputAdornment position="start">
                                     <TimelapseIcon />
                                   </InputAdornment>
                                 }
                              >
                                 {dataInicial.prioridad.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.prioridad}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "prioridad" ? "Prioridad es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                        <FormControl fullWidth error={error === "area" ? true : false}>
                              <InputLabel id="area">Área</InputLabel>
                              <Select
                                 labelId="area"
                                 id="area"
                                 sx={{bgcolor:'#fff'}}
                                 size="small"
                                 fullWidth
                                 name="area"
                                 value={area}
                                 label="area"
                                 onChange={handleInputChange}
                              >
                                 {dataInicial.company_area.map((option, i) => {
                                    return <MenuItem key={i} value={option.idarea_negocio}>{option.descripcion}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "area" ? "Area Negocio es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                        <Autocomplete
                                 multiple
                                 id="tags-outlined"
                                 options={dataInicial.company_area}
                                 getOptionLabel={(option: any) => option.descripcion ? option.descripcion : ''}
                                 //defaultValue={[valueProComprados]}
                                 fullWidth
                                 sx={{ bgcolor: '#fff' }}
                                // sx={{maxWidth:'28vw'}}
                                 value={areaNegocio}
                                 onChange={(e, newValue) => {
                                    console.log(newValue)
                                    setAreaNegocio(newValue);
                                    // setvalueProComprados(newValue)
                                    // if(newValue){
                                    //    setError("")
                                    // }else{

                                    // }
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Area de negocio"
                                      // error={error === "procomprados" ? true : false}
                                       placeholder=""
                                       fullWidth
                                    />
                                 )}
                              />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              fullWidth
                              error={error === "fechainicio" ? true : false}
                              helperText={error === "fechainicio" ? " Fecha Apertura debe ser mayor a la fecha actual" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              size="small"
                              label="Fecha Inicio"
                              id="fechainicio"
                              sx={{ bgcolor: '#fff' }}
                              name="fechainicio"
                              type="date"
                              autoFocus
                              value={fechainicio}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              fullWidth
                              error={error === "fechafin" ? true : false}
                              helperText={error === "fechafin" ? " La Fecha de Fin es un campo requerido" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              size="small"
                              id="fechafin"
                              label="Fecha Fin"
                              sx={{ bgcolor: '#fff' }}
                              name="fechafin"
                              type="date"
                              autoFocus
                              value={fechafin}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField
                              fullWidth
                              error={error === "descripcion" ? true : false}
                              helperText={error === "descripcion" ? " La Descripción es un campo requerido" : ""}
                              size="small"
                              placeholder="Descripción"
                              id="descripcion"
                              sx={{ bgcolor: '#fff' }}
                              name="descripcion"
                              multiline
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <DescriptionIcon />
                                    </InputAdornment>
                                 ),
                              }}
                              value={descripcion}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={6}>

                           <TextField
                              fullWidth
                              error={error === "hito" ? true : false}
                              helperText={error === "hito" ? " El hito es un campo requerido" : ""}
                              size="small"
                              placeholder="Hitos"
                              id="hito"
                              sx={{ bgcolor: '#fff' }}
                              name="hito"
                              multiline
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <AddTaskIcon />
                                    </InputAdornment>
                                 ),
                              }}
                              value={hito}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <IconButton onClick={() => setValue(iterador, hito)}>
                              <AddCircleIcon color='primary' />
                           </IconButton>
                        </Grid>

                        <Grid item xs={12}
                           sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              listStyle: 'none',
                              p: 0.5,
                              m: 0,
                           }}
                           component="ul"
                        >
                           {chipData.map((data) => {
                              return (
                                 <ListItem key={data.key}>
                                    <Chip
                                       // icon={icon}
                                       label={data.nombre}
                                       onDelete={data.nombre === data.nombre ? handleDelete(data) : undefined}
                                    />
                                 </ListItem>
                              );
                           })}
                        </Grid>


                        <Grid item xs={12} md={6} >
                           <Button
                              onClick={() => {
                                 setOpenNewCampaing(false)
                              }}
                              variant="contained"
                              fullWidth
                              color="error"
                              startIcon={<CancelIcon />}
                              sx={{background:'#bf6c00', color:'#fff',mt:'10px', '&:hover':{
                                 bgcolor:'#bf6c00'
                                }}}
                           >
                              Cancelar
                           </Button>

                        </Grid>
                        <Grid item xs={12} md={6} >
                           <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              startIcon={<SaveIcon />}
                              sx={{background:'#155172', color:'#fff',mt:'10px', '&:hover':{
                                 bgcolor:'#155172'
                                }}}
                           >
                              Agregar
                           </Button>
                        </Grid>
                        {/* <Grid item xs={12}>
                                    <p>El párrafo es</p>
                                    {chipData.map((elementos, i)=>{
                                        return <p>
                                            El primer elemento, su id es: {elementos.key} y elemento es {elementos.label}
                                        </p>
                                    })}
                                </Grid> */}

                     </Grid>
                  </form>
               </Box>
            </Box>
         </Modal>
      </>
   );
}
