import { http } from '../http/http';
import { API_URL_BASE, API_URL_SOFTNET } from '@toolbox/defaults/app';

export const cotizacionRepository = {
   cotizacionProducto,
   consultaDocumento,
   cotizacionesCRM: async (cotizations): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/cotizacion/export`, {
         cotizations: cotizations
      })
      return resp

   },
   cotizacionesChangeEstado: async (folio, estado): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/cotizacion/updateEstado`, {
         folio: folio,
         estado_cotizacion: estado
      })
      return resp

   },
   cotizacionesChangeUser: async (folio, iduser): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/cotizacion/updateCliente`, {
         folio: folio,
         idusuario: iduser
      })
      return resp

   },
   cotizacionMail: async(data, mail): Promise<any> =>{
      const resp = await http.post<any>(`${API_URL_BASE}/v1/mail/sendCotizacion`,{data:data, mail:mail})
      return resp
   },
   cotizacionInsert: async(cotizacion): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/cotizacion/create`,{cotizacion})
      return resp
   },
   getCotizaciones: async(idetallecampana): Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/cotizacion/list?idetallecampana=${idetallecampana}`)
      return resp
   },
   getCotizacionByDetalleCampana: async(idetallecampana): Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/cotizacion/byDetailCampaign?idetallecampana=${idetallecampana}`)
      return resp
   },
}

function cotizacionProducto(token, cotizacion){
   return fetch('https://api2.softnet.cl/CotizacionPorProducto', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
      body: JSON.stringify(cotizacion),
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}

function consultaDocumento(token, documento){
   return fetch('https://api2.softnet.cl/consultaDocumento', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
      body: JSON.stringify(documento),
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}


