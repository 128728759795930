import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './AreaNegocio.type';
import { Container, Grid, TextField, Typography, Button, Alert, Snackbar, Backdrop } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { ROUTE_USER_BY_AREA_NEGOCIO } from '@/toolbox/constants/route-map';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { MenuLateral } from '@/components/common/LateralMenu';
import { SpinnerGrow } from '@/components/common/Spinner';
import { AreaNegocioService } from '@/service/services/AreaNegocio.service';
import { ModalAddAreaNegocio } from '@/components/common/Modal/ModalAddAreaNegocio';

export const AreaNegocioView: React.FC<Props> = (props: Props): JSX.Element => {
   const header = [
      { name: 'descripcion', label: 'Descripcion', width: '25%' }
   ]
   const dataAction = [
      { name: 'edit', color: 'primary', route: null },
      { name: 'delete', color: 'secondary', route: null },
      { name: 'listUser', color: 'secondary', route: ROUTE_USER_BY_AREA_NEGOCIO },
   ]
   const [open, setOpen] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [action, setAction] = useState('');
   const [dataArea, setDataArea] = useState<any>([])
   const [editArea, setEditArea] = useState<any>({})
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      Id: null,
      message: `¿Desea eliminar al usuario ---?`
   })

   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0)
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })



   useEffect(() => {

      try {
         const data = readLocalStorage(KEY_USER_DATA)
         getAreaNegocio();
      } catch (e) {
         console.log(e)
      }
   }, [pageData, perPageData])

   async function getAreaNegocio() {
      setOpen(true)
      const resp = await AreaNegocioService.getAreaPage(perPageData,pageData);
      setDataArea(resp.data.data)
      setOpen(false)
      setTotal(resp.data.total)
   }


   const PerPage = async (data) =>{
      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) =>{
      setPageData(data+1)
   }

   const getSearchArea = async (search) => {
      try{
         setOpen(true)
         const resp= await AreaNegocioService.getAreaSearch(search, perPageData, pageData);
         setDataArea(resp.data.data)
         setOpen(false)
         setTotal(resp.data.total)

       }catch(e){
          console.log(e)
       }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>2)
               {
                  getSearchArea(value)
               }
               if(value.length==0)
               {
                  getAreaNegocio();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };

   const addAreaNegocio = async (descripcion) => {
      const resp:any = await AreaNegocioService.createArea(descripcion);
      if(resp.data)
      {
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          message: 'Área creada correctamente',
       }));
       getAreaNegocio()
       setSearch('')
       setOpenModal(false);
      }
   }

   const updteAreaNegocio = async (descripcion,id) => {
      const resp:any = await AreaNegocioService.updateArea(id,descripcion);
      console.log(resp);
      if(resp.data)
      {
         console.log(resp.data);
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          message: 'Área Actualizada correctamente',
       }));
       getAreaNegocio()
       setSearch('')
       setOpenModal(false);
      }
   }

   const deleteUsuario = async () =>
   {
      if (Dialog.confirm == true) {
         const resp= await AreaNegocioService.deleteArea(Dialog.Id);
         if (resp.data) {
            setSearch('')
            getAreaNegocio()
         }
      }
   }
   const idDelete = async (dataArea) => {
      console.log(dataArea);
      if(dataArea.action=='edit')
      {
         setAction('edit')
         setOpenModal(true)
         setEditArea(dataArea);
      }else{
         setAction('delete')
         const Id = dataArea.idarea_negocio;
         setDialog(prev => ({ ...prev, message: `¿Esta seguro en eliminar el Area de ${dataArea.descripcion}?`,Id:Id,open: true, confirm:true}));
      }
       };

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
              <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <Grid container flexDirection='row' spacing={2}>
               <Grid item md={3} container>
                  <MenuLateral />
               </Grid>
               <Grid item md={9}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #3F9BCC ', mt: 3 }}
               >
                  <Grid container sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }}>
                     <ConfirmDialog
                        open={Dialog.open}
                        title={Dialog.title}
                        message={Dialog.message}
                        onConfirm={() => deleteUsuario()}
                        onClose={() => setDialog(prev => ({ ...prev, open: false }))}
                     />

                     <Grid container rowSpacing={2}>
                        <Grid item container xs={6} rowSpacing={2} >
                           <Grid item xs={12}>
                              <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.5em'}}>Áreas de la empresa</Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <Button variant='contained'
                               sx={{background:'#155172',ml:2, color:'#fff','&:hover':{
                                 bgcolor:'#155172'
                                }
                              }}
                              onClick={()=>{setAction('add');setOpenModal(true)}}
                              >Agregar Área</Button>
                           </Grid>
                        </Grid>
                        <Grid item xs={6} >
                           <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="search"
                              value={search}
                              onChange={handleInput}
                              placeholder="Buscar"
                              type="search"
                              id="password"
                              autoComplete="current-password"
                              size="small"
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${Total? `${Total}` : `0`} Registros encontrados`}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} >
                           <TableData
                              header={header}
                              data={dataArea}
                              action={dataAction}
                              RecuperarData={idDelete}
                              total={Total? Total:0}
                              perPage={PerPage}
                              page={page}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Container>
         <ModalAddAreaNegocio
         open={openModal}
         setOpen={setOpenModal}
         addAreaNegocio={addAreaNegocio}
         updteAreaNegocio={updteAreaNegocio}
         editArea={editArea}
         action={action}
         />
          <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
      </Protected>
   )
};
