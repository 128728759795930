import {
   ROUTE_HOME,
   ROUTE_SEETING,
   ROUTE_ACCOUNT,
   ROUTE_CAMPAIGN,
   ROUTE_CONTACT,
   ROUTE_DETAILCAMPAIGN,
   ROUTE_CONTACT_CREATE,
   ROUTE_PRODUCT_CREATE,
   ROUTE_USER_CREATE,
   ROUTE_ACTIVIDADES,
   ROUTE_PROGRAMATION,
   ROUTE_RULE,
   ROUTE_PRODUCT,
   ROUTE_PERFIL,
   ROUTE_ENTITY,
   ROUTE_USER,
   ROUTE_SINCRONIZACION,
   ROUTE_CONFIGURACION,
   ROUTE_USER_UPDATE,
   ROUTE_CONTACT_UPDATE,
   ROUTE_ENTITY_CREATE,
   ROUTE_ENTITY_UPDATE,
   ROUTE_ENTITY_USERS,
   ROUTE_NEGOCIOS,
   ROUTE_PROYECCION,
   ROUTE_COTIZACION_INTERNA,
   ROUTE_AREA_NEGOCIO,
   ROUTE_PRODUCT_RELATIONS,
   ROUTE_CLIENTS_DOC,
   ROUTE_CORREOS,
   ROUTE_CORREOS_IMAP,
   ROUTE_DESTACADOS,
   ROUTE_ARCHIVADOS,
   ROUTE_ARRIENDOS,
   ROUTE_ONBOARDING_ARRIENDOS,
   ROUTE_SERVICE
} from "../constants/route-map";

export const ROLE_SUPER_ADMIN = 'SUPERADMIN';
export const ROLE_ADMIN = 'ADMINISTRADOR';
export const ROLE_SUPERVISOR = 'SUPERVISOR';
export const ROLE_VENDEDOR = 'VENDEDOR';
export const ROLE_TRABAJADOR = 'TRABAJADOR';
export const ROLE_ADMIN_PROYECCION = 'ADMIN_PROYECCION';

export const ROUTES_FOR_SUPER_ADMIN = [
   {
      module: ROUTE_ENTITY,
      navigators: []
   },
   {
      module: ROUTE_PROYECCION,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL]
   },
   {
      module: ROUTE_PROGRAMATION,
      navigators: []
   },
]
export const ROUTES_FOR_TRABAJADOR = [
   {
      module: ROUTE_PROYECCION,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL]
   },
   {
      module: ROUTE_PROGRAMATION,
      navigators: []
   },
]
export const ROUTES_FOR_ADMIN_PROYECCION = [
   {
      module: ROUTE_PROYECCION,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL,ROUTE_AREA_NEGOCIO]
   },
   {
      module: ROUTE_PROGRAMATION,
      navigators: []
   },
]
export const ROUTES_FOR_ADMIN = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_USER,
     navigators: []
   },
   {
      module: ROUTE_CONTACT,
      navigators: []
   },
   {
      module: ROUTE_CLIENTS_DOC,
      navigators: []
   },
   {
      module: ROUTE_PRODUCT,
      navigators: [ROUTE_PRODUCT_RELATIONS]
   },
   {
      module: ROUTE_SERVICE,
      navigators: []
   },
   {
      module: ROUTE_ARRIENDOS,
      navigators: []
   },
   {
      module: ROUTE_ONBOARDING_ARRIENDOS,
      navigators: []
   },
   {
      module: ROUTE_CAMPAIGN,
      navigators: []
   },
   {
      module: ROUTE_NEGOCIOS,
      navigators: []
   },
   {
      module: ROUTE_ACTIVIDADES,
      navigators: []
   },
   {
      module: ROUTE_PROYECCION,
      navigators: []
   },
   {
      module: ROUTE_PROGRAMATION,
      navigators: []
   },
   {
      module: ROUTE_COTIZACION_INTERNA,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [
         ROUTE_PERFIL,
         ROUTE_USER,
         ROUTE_AREA_NEGOCIO,
         ROUTE_SINCRONIZACION,
         ROUTE_CONFIGURACION]
   },
   {
      module: ROUTE_SINCRONIZACION,
      navigators: []
   },
   {
      module: ROUTE_CONFIGURACION,
      navigators: []
   },
   {
      module: ROUTE_CORREOS_IMAP,
      navigators: [
         ROUTE_CORREOS,
         ROUTE_DESTACADOS,
         ROUTE_ARCHIVADOS]
   },
]
export const ROUTES_FOR_VENDEDOR = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [ROUTE_PERFIL]
   },
   {
      module: ROUTE_NEGOCIOS,
      navigators: []
   },
   {
      module: ROUTE_CONTACT,
      navigators: []
   },
   {
      module: ROUTE_PRODUCT,
      navigators: []
   },
   {
      module: ROUTE_CAMPAIGN,
      navigators: []
   },
   {
      module: ROUTE_RULE,
      navigators: []
   },
   {
      module: ROUTE_ACTIVIDADES,
      navigators: []
   },
   {
      module: ROUTE_CORREOS_IMAP,
      navigators: []
   },
   {
      module: ROUTE_CORREOS,
      navigators: []
   },
   {
      module: ROUTE_DESTACADOS,
      navigators: []
   },
   {
      module: ROUTE_ARCHIVADOS,
      navigators: []
   },
]
export const ROUTES_FOR_SUPERVISOR = [
   {
      module: ROUTE_HOME,
      navigators: []
   },
   {
      module: ROUTE_USER,
     navigators: []
   },
   {
      module: ROUTE_COTIZACION_INTERNA,
      navigators: []
   },
   {
      module: ROUTE_CONTACT,
      navigators: []
   },
   {
      module: ROUTE_PRODUCT,
      navigators: []
   },
   {
      module: ROUTE_CAMPAIGN,
      navigators: []
   },
   {
      module: ROUTE_NEGOCIOS,
      navigators: []
   },
   {
      module: ROUTE_ACTIVIDADES,
      navigators: []
   },
   {
      module: ROUTE_PROGRAMATION,
      navigators: []
   },
   {
      module: ROUTE_ACCOUNT,
      navigators: [
         ROUTE_PERFIL,
         ROUTE_USER,
         ROUTE_PROYECCION
      ]
   }
]

export const MAIN_REDIRECT_FOR_SUPER_ADMIN = ROUTE_HOME;
export const MAIN_REDIRECT_FOR_ADMIN       = ROUTE_HOME;

