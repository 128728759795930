import { Protected } from '@/components/layout/Protected';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Alert, Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, Container, Grid, IconButton, ListItemAvatar, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Props } from './Sincronizacion.type';
import { TableData } from '@/components/common/Table';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { MenuLateral } from '@/components/common/LateralMenu';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { sincronizacionService } from '@/service/services/Sincronizacion.service';
import { TransferList } from './components';
import { bgcolor, fontSize } from '@mui/system';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import logo from "@assets/img/logo-softnet.png";
import { SpinnerGrow } from '@/components/common/Spinner';

const header = [{ name: 'created_at', label: 'Fecha', width: '' }, { name: 'status', label: 'Estado', width: '' }];


export const SincronizacionView: React.FC<Props> = ({

}): JSX.Element => {

   const [entidad, setEntidad] = useState<any>([])
   const [open, setOpen] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const [disabled, setDisabled] = useState<boolean>(true);
   const [details, setDetails] = useState<any>([]);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [data, setData] = useState<any>([])

   useEffect(() => {
      entidadesSync();
      getSyncList();
   }, [])

   const entidadesSync = async () => {
      setLoading(true);
      const resp = await sincronizacionService.getModulosToSync();
      setData(resp.data);
      setLoading(false);
      //getSyncList();

   }
   const setEntidades = (dataentidades) => {
      setEntidad(dataentidades);
   }

   const postAsync = async () => {
      const resp = await sincronizacionService.postSync();

      if (resp.data) {
         EnviarData();
      }

   }

   const SyncProduct = async () => {
      setOpen(true);
      const resp = await sincronizacionService.SyncProduct();
      if(resp.data.status)
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: resp.data.descripcion,
         }));
      }
      if (!resp.data.status) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp.data.descripcion,
         }));
      }
      setOpen(false);
   }

   const getSyncList = async () => {
      const resp = await sincronizacionService.getSyncList();

      let detalle_List_Sync=[];
      resp.data.map((detalleSync)=>{
         const objSync={
            ...detalleSync,
            status:detalleSync.status==0?<ErrorIcon color='error'/>:(detalleSync.status==1?<CheckCircleOutlineIcon color='success'/>:<WarningAmberIcon color='warning'/>),
            detalle:detalleSync.detalle
         }
         detalle_List_Sync.push(objSync);
      })

      setDetails(detalle_List_Sync)
   }

   const SyncClient = async () => {
      setOpen(true);
      const resp = await sincronizacionService.SyncClient();
      if(resp.data?.status)
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: resp.data.descripcion,
         }));
      }
      if (!resp.data.status) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp.data.descripcion,
         }));
      }
      setOpen(false);
   }

   const EnviarData = () => {
      entidad.map((value) => {
         if (value.id == 1) {
            SyncProduct();
         }
         if (value.id == 2) {
            SyncClient();
         }
      });
      getSyncList();

      // setOpen(false);
      // setInterval(() => {

      // }, 5000)
      // saveLocalStorage(KEY_SINCRONIZACION, false);
      //setOpen(false);
   }

   return (
      <Protected>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
         >
              <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1, bgcolor: '#000' }}
               open={open}
            >

               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                     <CircularProgress color="inherit"
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                          mr:2
                      }}
                      size={50}
                     />
                     <h1 style={{fontSize:'30px'}}><strong>Sincronizando...</strong></h1>
               </Grid>

            </Backdrop>
            <Grid container flexDirection='row' spacing={1}>
               <Grid item md={3} container>
                  <MenuLateral />
               </Grid>

               <Grid item md={9}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #3F9BCC ', mt: 3 }}
               >
                  <Grid container spacing={2}>
                     <Grid item xs={5}>
                        <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Sincronización</Typography>
                     </Grid>
                     <Grid item lg={7}>
                        <Typography textAlign={'center'} sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Preparar datos</Typography>
                     </Grid>
                     <Grid item lg={5} >
                        <TableData
                           header={header}
                           data={details}
                           action={null} />
                           <ul style={{padding:'15px'}}>
                           <li><CheckCircleOutlineIcon sx={{width:20}} color='success'/> <span style={{fontSize:'15px', paddingLeft:'4px'}}>Sincronizacion Exitosa</span></li>
                           <li><WarningAmberIcon sx={{width:20}} color='warning'/> <span style={{fontSize:'15px', paddingLeft:'4px'}}>Sincronizacion sin data nueva</span></li>
                           <li><ErrorIcon sx={{width:20}} color='error'/> <span style={{fontSize:'15px', paddingLeft:'4px'}}>Sincronizacion Errónea</span></li>
                           </ul>
                     </Grid>
                     <Grid item lg={7} container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}>

                        <Grid item lg={12} container>
                           <TransferList
                              setEntidades={setEntidades}
                              setDisabled={setDisabled}
                           />
                        </Grid>
                        <Grid item lg={4}>
                           <Button
                              variant='contained'
                              sx={{background:'#bf6c00', color:'#fff', '&:hover':{
                                 bgcolor:'#bf6c00'
                                }}}
                                disabled={disabled}
                              onClick={() => { postAsync() }}>
                              SINCRONIZAR
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Container>
      </Protected>
   )
}
