export const ROUTE_HOME            = '/';
export const ROUTE_LOGIN           = '/login';
export const ROUTE_SEETING         = '/configuraciones';
export const ROUTE_PRODUCT         = "/producto";
export const ROUTE_PRODUCT_CREATE  = "/producto/crear";
export const ROUTE_PRODUCT_RELATIONS  = "/producto/relaciones/:id";
export const ROUTE_SERVICE         = "/servicio";
export const ROUTE_INVENTORY_PRODUCTS = "/inventarios-productos";
export const ROUTE_ARRIENDOS       = "/arriendos";
export const ROUTE_DETALLE_ARRIENDOS = "/detalle-arriendos";
export const ROUTE_ARRIENDOS_CREATE = "/arriendos-registro";
export const ROUTE_ARRIENDOS_CONFIRMATION = "/arriendos-confirmacion";
export const ROUTE_ARRIENDOS_PAY = "/arriendos-pago";
export const ROUTE_ARRIENDOS_PAYMENT_ADMIN = "/arriendos-pagos";
export const ROUTE_ONBOARDING_ARRIENDOS = "/onboarding-arriendos";
export const ROUTE_CONTACT         = "/contacto";
export const ROUTE_CONTACT_CREATE  = "/contacto/crear";
export const ROUTE_CONTACT_UPDATE  = "/contacto/detalle";
export const ROUTE_ACTIVIDADES     = "/actividades";
export const ROUTE_PROYECCION   = "/proyeccion";
export const ROUTE_CAMPAIGN        = "/campaña";
export const ROUTE_RULE            = "/regla";
export const ROUTE_ACCOUNT         = "/cuenta";
export const ROUTE_DETAILCAMPAIGN  = "/detalleCampana";
export const ROUTE_DETAILCAMPAIGNLEADS = "/detalleCampanaLeads";
export const ROUTE_DETAILCAMPAIGNLEADSCLIENT = "/detalleCampanaLeadsClient";
export const ROUTE_DETAIL_CLIENTS_TASK = '/tareas-cliente';
export const ROUTE_DETAILCAMPAIGNGENERALCLIENT = "/detalleCampanaGeneralClient";
export const ROUTE_NOTIFY          = "/notificaciones";
export const ROUTE_ENTITY          = "/empresas";
export const ROUTE_ENTITY_CREATE   = "/empresas/crear";
export const ROUTE_ENTITY_UPDATE   = "/empresas/detalle";
export const ROUTE_ENTITY_USERS    = "/empresas/usuarios";
export const ROUTE_PERFIL          = "/perfil";
export const ROUTE_CONFIGURACION   = "/configuracion";
export const ROUTE_SINCRONIZACION   = "/sincronizacion";
export const ROUTE_USER            = "/usuarios"
export const ROUTE_USER_CREATE     = "/usuarios/crear"
export const ROUTE_USER_UPDATE     = "/usuarios/detalle"
export const ROUTE_CONFIRMATION_ACCOUNT  = "/confirmacion"
export const ROUTE_RECOVERY_ACCOUNT = '/recovery'
export const ROUTE_LEADS            = '/leads-form'
export const ROUTE_PROGRAMATION    = "/programacion";
export const ROUTE_TRELLO          = "/trello"
export const ROUTE_TRELLO_LEADS    = "/trello-leads"
export const ROUTE_TRELLO_COTIZACIONES = "/trello-cotizaciones"
export const ROUTE_NEGOCIOS        = "/negocios"
export const ROUTE_COTIZACION= '/cotizacion-producto'
export const ROUTE_COTIZACION_INTERNA ='/cotizaciones'
export const ROUTE_CREATE_CONTACT   = "/contacto-crear";
export const ROUTE_AREA_NEGOCIO   = "/area-negocio";
export const ROUTE_USER_BY_AREA_NEGOCIO_CREATE   = "/user_by_area-negocio/crear";
export const ROUTE_USER_BY_AREA_NEGOCIO_UPDATE   = "/user_by_area-negocio/detalle";
export const ROUTE_USER_BY_AREA_NEGOCIO    = "/user_by_area-negocio/user";
export const ROUTE_PROYECCION_DETALLE   = "/proyeccion/detalle";
export const ROUTE_CAMPAING_LEADS_FORM   = "/campaña/leads_form";
// export const ROUTE_LEADS_FORM_CAMPAING   = "/view_leads_form";
export const ROUTE_LEADS_FORM_CAMPAING   = '/:name';
export const ROUTE_LIST_CAMPAIGN   = "/list_campaign";
export const ROUTE_CAMPAIGN_GENERALES = "/campaña/generales/:id";
export const ROUTE_DOCUMENTOS = "/documents";
export const ROUTE_LOGIN_DOCUMENTS = "/login-documentos";
export const ROUTE_REGISTRAR_USER_DOC = "/register-userDoc";
export const ROUTE_CLIENTS_DOC = "/clientes-doc";
export const ROUTE_DETALLE_CLIENTS_DOC = "/clientes-doc/:id";
export const ROUTE_VENDEDORES = "/vendedores";
export const ROUTE_URI = "/uri/url";
export const ROUTE_CORREOS_IMAP = 'correos-imap';
export const ROUTE_CORREOS = '/correo-entrantes';
export const ROUTE_DESTACADOS = '/correos-destacados';
export const ROUTE_ARCHIVADOS = '/correos-archivados';
export const ROUTE_BROCHURE = '/campaña/view_brochure';
export const ROUTE_LEADS_FORM_CAMPAING_EDIT = '/view_edit_form/:id';
export const ROUTE_REGISTER_VIEW_LANDING = '/landig/vistas';
export const ROUTE_PROSPECT_WS         = "/prospect-ws";
export const ROUTE_TEMPLATE_WS         = "/template-ws";
export const ROUTE_TEMPLATE_WS_CREATE  = "/template-ws/create";
