import {  Typography, InputBase } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState, useContext } from "react";
import ContextApi from "../ContextApi";
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/system";


const useStyle = makeStyles((theme) => ({
  title: {
    display: "flex",
    margin: 1,
  },
  titleText: {
    flexGrow: 1,
    fontSize: "1.2 rem",
    fontWeight: "bold",
  },
  input: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    margin: 1,
    "&:focus": {
      background: "#ddd",
    },
  },
}));
const ListTitle = ({ title, listId, provided }) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const { updateListTitle } = useContext(ContextApi);

  const handleBlur = () => {
    //actualizar title a nuevo title
    updateListTitle(newTitle, listId);
    setOpen(false);
  };
  return (
    <>
      {open ? (
        <InputBase
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onBlur={handleBlur}
          autoFocus
          fullWidth
          inputProps={{ className: classes.input }}
        />
      ) : (
        <Box sx={{ display: "flex",
        margin: 1,}}>
          <Typography
            sx={{ flexGrow: 1,
                fontSize: "1.2 rem",
                fontWeight: "bold",}}
            onClick={() => setOpen(true)}
          >
            {title}
          </Typography>
          <MoreHorizIcon onClick={() => console.log(provided)} />
        </Box>
      )}
    </>
  );
};

export default ListTitle;
