import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, MenuItem, Autocomplete, Select, InputLabel, FormControl, IconButton, Grid, Divider, Checkbox, InputAdornment, Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material'
import { DownloadIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import CircleIcon from '@mui/icons-material/Circle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { ModalAddTask } from "@/components/common/Modal/ModalAddTask";
import { ModalAddAction } from "@/components/common/Modal/ModalAddAction";
import Modal from '@mui/material/Modal';
import './Modal.sass'
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { productService } from '@/service/services/Product.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { cotizacionService } from '@/service/services/Cotizacion.service';

import { ModalCotizacionMail } from './ModalCotizacionMail';
import moment from 'moment';
type ModalProps = {
   open: boolean,
   setOpen: any,
   data?: any,
}
export const ModalCotizacion: React.FC<ModalProps> = ({
   open,
   setOpen,
   data
}): JSX.Element => {

   const dataUser = readLocalStorage(KEY_USER_DATA);
   const [products, setProducts] = useState<any>([])
   const [openModalCotizacionMail,setOpenModalCotizacionMail] = useState<boolean>(false);
   const [dataCotizacion,setDataCotizacion] = useState<any>({})
   const [renderTabla, setRenderTabla] = useState<boolean>(false)
   const [cantidad, setCantidad] = useState<any>({})
   const tokenSft = readLocalStorage(KEY_SOFTNET)
   const [selectBodega, setSelectBodega] = useState<any>(null)
   const [bodega, setBodega] = useState<any>({})
   const [vendedor, setVendedor] = useState<any>({})
   const [selectedVendedor, setSelectedVendedor] = useState<any>(null)
   const [selectPago, setSelectPago] = useState<any>(null)
   const [pago, setPago] = useState<any>({})
   const [selectAreaNegocio, setSelectedAreaNegocio] = useState<any>(null)
   const [areaNegocio, setAreaNegocio] = useState<any>({})
   const [validez, setValidez] = useState<any>('')

   const [error, setError] = useState(null);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   useEffect(() => {
      if (open) {
         console.log(data)
         //traer el rut cliente
         const productscomprados = JSON.parse(data.productosComprados);
         const products = data.productosComplementarios;
         const productsRelacionados = data.productosRelacionados;
         let productSArray = productscomprados.concat(products, productsRelacionados);
         productSArray.map((value, i) => {
            if (value != null) {
               const pro = {
                  ...value, cantidad: '1'
               }
               productSArray[i] = pro
            }
         })
         console.log(productSArray)
         setProducts(productSArray);
         getBodega(tokenSft)
         getVendedor(tokenSft)
         getPago(tokenSft)
         getAreaNegocio(tokenSft)
      } else {
         setCantidad({})
         setProducts([])
      }
   }, [open])

   const EliminarRelacion = (value, i) => {
      products.splice(i, 1)
      setProducts(products)
      setRenderTabla(!renderTabla)
   }

   const handleInputCantidad = (e) => {
      const changedFormValuesUser = {
         ...cantidad,
         [e.target.name]: e.target.value
      }
      setCantidad(changedFormValuesUser)

      products.map((value, i) => {
         if (value?.codigo == e.target.name) {
            const proCantidad = {
               ...value, cantidad: e.target.value
            }
            return products.splice(i, 1, proCantidad)
         }
      })
   }

   const getBodega = async (tokenSft) => {
      const resp = await productService.getBodega(tokenSft)
      if (resp) {
         setBodega(resp)
      }
   }

   const getVendedor = async (tokenSft) => {
      const resp = await productService.getVendedor(tokenSft)
      if (resp) {
         setVendedor(resp)
      }
   }

   const getPago = async (tokenSft) => {
      const resp = await productService.getPago(tokenSft)
      if (resp) {
         setPago(resp)
      }
   }

   const getAreaNegocio = async (tokenSft) => {
      const resp = await productService.getAreaNegocio(tokenSft)
      if (resp) {
         setAreaNegocio(resp)
      }
   }

   const cotizacionInsert = async (cotization) =>{
      const dataCot = {
         'folio'            : cotization.idDoc.folio,
         'tipo_doc'         : cotization.idDoc.tipo,
         'rut_cliente'      : cotization.receptor.RUTRecep,
         'rut_vendedor'     : cotization.emisor.RUTEmisor,
         'nombre_vendedor'  : cotization.emisor.RznSoc,
         'estado'           : 'GENERADAS/ABIERTAS',
         'estado_cotizacion': 1,
         'idusuario'        : null,
         'descuento'        : 0,
         'fecha'            : cotization.idDoc.fechaEmis,
         'razon_social'     : cotization.receptor.RznSocRecep,
         'valor_exento'     : cotization.totales.MntExe,
         'valor_iva'        : cotization.totales.TasaIVA,
         'valor_neto'       : cotization.totales.MntNeto,
         'valor_total'      : cotization.totales.MntTotal,
         'iddetallecampana' : data.iddetallecampaña,
      }

      const respCotizacionCrm = await cotizacionService.cotizacionInsert(dataCot);
   }
   const cotizacionCrm = async(dataDoc, funcInsert, token, rut_empresa) =>{
      rut_empresa = rut_empresa.replace(/ /g, "")
      const resp = await cotizacionService.consultaDocumento(  token, {
         folio: dataDoc.folio,
         tipo: dataDoc.tipo,
         rut: rut_empresa,
      });

      funcInsert(resp)
      console.log(resp)
   }
   let arrayProducts = [];
   let totalNeto: number = 0;
   products.map((value) => {
      if (value != null) {
         const productObj = {
            "Codigo": value.codigo,
            "Cantidad": value.cantidad,
            "Descuento": "0",
            "Precio": value.precio,
            "Afecto": true,
            "Bodega": !!selectBodega && selectBodega.id 
         }
         arrayProducts.push(productObj)
         totalNeto = totalNeto + parseFloat(value.precio.toFixed(2))
      }
   })

   let TotalBruto:number = totalNeto ;
   totalNeto = Math.ceil(totalNeto)/1.19;
   let iva = TotalBruto - totalNeto;
   const GenerateCotizacion = async (tokenSft) => {
      if (!!!selectedVendedor) { return setError("vendedor")}
      if (!!!selectBodega) { return setError("bodega")}
      if (!!!selectPago) { return setError("pago")}
      if (!!!selectAreaNegocio) { return setError("area negocio")}
      if (!!!validez) { return setError("validez")}
      else {
         setError("")
         const cotizacion = [
            {
               Encabezado: {
                  Receptor: data.rut_cliente,
                  MontoNeto: TotalBruto,
                  MontoExento: "0",
                  Descuento: "0",
                  TipoDocumento: "777",
                  Folio: 0,
                  AreaNegocio: !!selectAreaNegocio && selectAreaNegocio.id,
                  CondicionPago: !!selectPago && selectPago.id,
                  Vendedor: !!selectedVendedor && selectedVendedor.rut_vendedor,
                  Validez: validez,
                  Observacion: "Sin Observacion",
                  Direccion: "1"
               },
               Detalle: arrayProducts,
               Adicional: {
                  Uno: !!selectPago && selectPago.id,
                  Dos: !!selectedVendedor && selectedVendedor.rut_vendedor,
                  Treinta: "123"
               }
            }
         ]
            const resp = await productService.enviarCotizacion(tokenSft, cotizacion )
            if (resp) {
               if (resp[0]['mensaje'] == 'Documento generado con exito.') {
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     severity: 'success',
                     message: resp[0]['mensaje'],
                  }));
                  close();

                  cotizacionCrm(resp[0], cotizacionInsert, tokenSft, dataUser.user.rut_empresa);  
                 
               } else {
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     severity: 'error',
                     message: 'No se pudo crear el documento',
                  }));
               }

            }
         
      }
   }

   const close = () => {
      setSelectPago([]);
      setSelectBodega([]);
      setSelectedAreaNegocio([]);
      setSelectedVendedor([]);
      setOpen(false);
   }

   const tablaSelected =
      <>
         <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
               <TableHead>
                  <TableRow sx={{ bgcolor: '#3f9bcc' }}>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} width={'15%'}> </TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'15%'}>Codigo</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'25%'}>Nombre</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'20%'}>Precio</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'25%'}>Total</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'5em'}></TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {(products || []).map((row, i) => (
                     row != null &&
                     <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     >
                        <TableCell component="th" scope="row">
                           <TextField
                              margin="normal"
                              required
                              fullWidth
                              name={row.codigo}
                              maxRows={4}
                              value={cantidad[row.codigo] ? cantidad[row.codigo] : '1'}
                              sx={{ bgcolor: '#E3F4FD' }}
                              onChange={handleInputCantidad}
                              InputProps={{
                                 inputProps: { min: 1 }
                              }}
                              type="number"
                              id="cantidad"
                              size="small"
                           />
                        </TableCell>
                        <TableCell component="th" scope="row">
                           {row.codigo}
                        </TableCell>
                        <TableCell align="left">{row.nombre}</TableCell>
                        <TableCell align="right">{moneyFormat(row.precio)}</TableCell>
                        <TableCell align="right">{moneyFormat(cantidad[row.codigo] ? row.precio * cantidad[row.codigo] : row.precio)}</TableCell>
                        <TableCell align="left">
                           <IconButton onClick={() => {
                              EliminarRelacion(row, i)
                           }}>
                              <HighlightOffIcon />
                           </IconButton>
                        </TableCell>
                     </TableRow>
                  ))}
                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell align="right"><Typography sx={{fontWeight:500, fontSize:'1.2em'}}>Monto Neto</Typography></TableCell>
                     <TableCell align="right">{moneyFormat(totalNeto)}</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell align="right"><Typography sx={{fontWeight:500, fontSize:'1.2em'}}>IVA</Typography></TableCell>
                     <TableCell align="right">{moneyFormat(iva)}</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell align="right"><Typography sx={{fontWeight:500, fontSize:'1.2em'}}>Total</Typography></TableCell>
                     <TableCell align="right">{moneyFormat(TotalBruto)}</TableCell>
                  </TableRow>
               </TableBody>
            </Table>
         </TableContainer>
      </>

   const bodyModal = <>
      <Box className='Modal' sx={{ width: '960px' }}>
         <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em', my:2 }} variant="h5" component="h1" textAlign='center' color='primary'>
            {/* {props.CampaignEdit?'Editar Campaña':'Crear Campaña'} */} Generar Cotización
         </Typography>
         <Box component='form'>
            <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} >
               <Grid item md={12} container flexDirection='row' ml={2}>
                  <Typography > <span style={{ color: '#3F9BCC', fontSize: '1.2em', textDecoration: 'bold', fontWeight: 700 }}><strong>CLIENTE:</strong></span> <span> {data.nombre_rz}</span></Typography>
               </Grid>
               <Grid item md={5}  mt={1} container spacing={2} justifyContent='right' alignItems='end' sx={{ mx: 1 }}>
                  <Autocomplete
                     id="tags-outlined"
                     options={vendedor}
                     getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                     size={'small'}
                     fullWidth
                     sx={{ bgcolor: '#fff' }}
                     value={selectedVendedor}
                     onChange={(e, newValue) => {
                        setSelectedVendedor(newValue);
                        setError(null)
                     }}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           error={error === "vendedor" ? true : false}
                           helperText={error === "vendedor" ? "El vendedor es un campo requerido" : ""}
                           label="Seleccionar una vendedor"
                           placeholder=""
                           fullWidth
                        />
                     )}
                  />
               </Grid>
               <Grid item md={5}  mt={1} container spacing={2} justifyContent='right' alignItems='end' sx={{ mx: 1 }}>
                  <Autocomplete
                     id="tags-outlined"
                     options={bodega}
                     getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                     size={'small'}
                     fullWidth
                     sx={{ bgcolor: '#fff' }}
                     value={selectBodega}
                     onChange={(e, newValue) => {
                        setSelectBodega(newValue);
                        setError(null)
                     }}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           error={error === "bodega" ? true : false}
                           helperText={error === "bodega" ? "El bodega es un campo requerido" : ""}
                           label="Seleccionar una Bodega"
                           placeholder=""
                           fullWidth
                        />
                     )}
                  />
               </Grid>
               <Grid item md={5} mt={1} container spacing={2} justifyContent='right' alignItems='end' sx={{ mx: 1 }}>
                  <Autocomplete
                     id="tags-outlined"
                     options={pago}
                     getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                     size={'small'}
                     fullWidth
                     sx={{ bgcolor: '#fff' }}
                     value={selectPago}
                     onChange={(e, newValue) => {
                        setSelectPago(newValue);
                        if(newValue){
                           setError(null)
                        }

                     }}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           error={error === "pago" ? true : false}
                           helperText={error === "pago" ? "El pago es un campo requerido" : ""}
                           label="Seleccionar una Pago"
                           placeholder=""
                           fullWidth
                        />
                     )}
                  />
               </Grid>
               <Grid item md={5}  mt={1} container spacing={2} justifyContent='right' alignItems='end' sx={{ mx: 1 }}>
                  <Autocomplete
                     id="tags-outlined"
                     options={areaNegocio}
                     getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                     size={'small'}
                     fullWidth
                     sx={{ bgcolor: '#fff' }}
                     value={selectAreaNegocio}
                     onChange={(e, newValue) => {
                        setSelectedAreaNegocio(newValue);
                        if(newValue){
                           setError(null)
                        }

                     }}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           error={error === "area negocio" ? true : false}
                           helperText={error === "area negocio" ? "El area negocio es un campo requerido" : ""}
                           label="Seleccionar una area negocio"
                           placeholder=""
                           fullWidth
                        />
                     )}
                  />
               </Grid>
               <Grid item md={5} mt={1} container spacing={2} justifyContent={'right'} alignItems='end' sx={{mx: 1}}>
                  <TextField
                     id="validez"
                     name="validez"
                     size={'small'}
                     sx={{ bgcolor: '#fff' }}
                     type="date"
                     InputLabelProps={{ shrink: true }}
                     fullWidth
                     value={validez}
                     onChange={(e)=> {setError(null);setValidez(e.target.value)}}
                     error={error === "validez" ? true : false}
                     helperText = {error == "validez" ? "La validez es un campo requerido" : ""}
                     label="Indicar una validez"
                  />
               </Grid>
               <Grid item xs={12} mt={2}>
                  {renderTabla ? tablaSelected : tablaSelected}
               </Grid>
            </Grid>
            <Divider />
            <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
               <Grid item >
                  <Button
                     variant='contained'
                     color='error'
                     //disabled={loading}
                     onClick={() => { close() }}
                     startIcon={<CancelIcon />}
                     sx={{
                        background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                           bgcolor: '#bf6c00'
                        }
                     }}
                  >
                     Cancelar
                  </Button>
               </Grid>
               <Grid item >
                  <Button
                     variant='contained'
                     color='error'
                     //disabled={loading}
                     onClick={() => { GenerateCotizacion(tokenSft) }}
                     startIcon={<SaveIcon />}
                     sx={{
                        background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                           bgcolor: '#155172'
                        }
                     }}
                  >
                     Guardar
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   </>
   return (
      <div>
         <Modal
            open={open}
            onClose={() => { close() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            {bodyModal}
         </Modal>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         {
            openModalCotizacionMail && <ModalCotizacionMail
            open={openModalCotizacionMail}
            setOpen={setOpenModalCotizacionMail}
            dataCotizacion={dataCotizacion}
            dataDetailCampaign={data}
           /> 
         }
         
      </div>
   );
}
