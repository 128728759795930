import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';
import {
   ROLE_ADMIN,
   ROLE_SUPER_ADMIN,
   ROLE_SUPERVISOR,
   ROLE_VENDEDOR,
   ROLE_TRABAJADOR,
   ROLE_ADMIN_PROYECCION
} from '@/toolbox/defaults/static-roles';
import Unauthorized from '@views/_unautorized';
import { LoginView } from "@views/Login"
import { HomeView } from "@views/Home"
import { LeadsFormView } from '@views/LeadsForm';
import { PrivateRoute, AuthRoute } from './PrivateRoute';
import { CampaingView } from '@/views/Campaing/Campaing';
import { ProductView } from '@/views/Product';
import { CalendarView } from '@/views/Calendar';
import { ContactView } from '@/views/Contact';
import { PerfilView } from '@/views/Perfil';
import { CreateContactView } from '@/views/Create/Contact';
import { CreateUserView } from '@/views/Create/User';
import { EnterpriseView } from '@/views/Enterprise';
import { DetailCampaignClientsLeadsView, DetailCampaignClientsView, DetailCampaingView } from '@/views/DetailCampaing';
import { RuleView } from '@/views/Rule';
import { SteperAccount } from '@/views/SteperAccount';
import { NotifyView } from '@/views/Notify';
import { ConfiguracionView } from '@/views/Configuracion';
import { EnterpriseUserView } from '@/views/EnterpriseUser';
import { UserView } from '@/views/Users';
import { SincronizacionView } from '@/views/Sincronizacion';
import { CreateProductView } from '@/views/Create/Product';
import { ConfirmacionEmailView } from '@/views/ConfirmacionEmail';
import {RecoveryEmailView} from '@/views/RecoveryEmail';
import { ProgrammationView } from '@/views/Programmation';
import { ProjectionView } from '@/views/Projection';
import { TrelloView } from '@/views/Trello';
import { CotizacionesView } from '@/views/Cotizaciones';
import { SteperContact } from '@/views/SteperContact/SteperContact';
import { CotizacionesInternasView } from '@/views/Cotizacones_Interna';
import { LeadsView } from '@/views/Leads';
import { DetailCampaingLeadsView } from '@/views/DetailCampaing/DetailCampaignLeads';
import { TrelloLeadsView } from '@/views/Trello_Leads';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { TrelloCotizacionesView } from '@/views/Trello_cotizaciones';
import { AreaNegocioView } from '@/views/Area_Negocio/AreaNegocio';
import { CreateUserByAreaNegocioView } from '@/views/Create/UserByAreaNegocio';
import { UserAreaNegocioView } from '@/views/UsersAreaNegocio';
import { ProyeccionDetailView } from '@/views/ProyeccionDetail';
import { LeadsFormCampaingView } from '@/views/LeadsFormCampaing';
import { ListCampaignView } from '@/views/ListCampaign';
import { DetailCampaignGeneralView } from '@/views/DetailCampaignGeneral';
import { DocumentsView} from '@/views/Documents';
import { LoginDocumentsView } from '@/views/LoginDocuments';
import { RegistroView } from '@/views/Documents/Registro';
import { ClientDocuments } from '@/views/ClientDocuments';
import {DetailClientDocView} from '@/views/DeatailClientDoc';
import { DetailCampaignClientsGeneralView } from '@/views/DetailCampaignGeneral/DetailCampaignGeneralClient';
import { SupervisorView } from '@/views/Supervisor';
import { CorreosEntrantesView } from '@/views/CorreosEntrantes';
import { UriView } from '@/views/Uri_Lading';
import { BrochureView } from '@/views/Brochure/Brochure';
import { LeadsFormCampaingEditView } from '@/views/LeadsFormCampaingEdit';
import { CorreosArchivadosView } from '@/views/CorreosArchivados';
import { CorreosDestacadosView } from '@/views/CorreosDestacados';
import { ViewRedirectView } from '@/views/View_Redirect';
import { ArriendosView } from '@/views/Arriendos/Arriendos';
import { ArriendosPublic } from '@/views/Arriendos/ArriendosPublic/ArriendosPublic';
import { PagoArriendosPublic } from '@/views/Arriendos/PagoArriendosPublic/PagoArriendosPublic';
import { PagosArriendosView } from '@/views/Arriendos/PagosArriendos/PagosArriendos';
import { CreateArriendos } from '@/views/Arriendos/CreateArriendos/CreateArriendos';
import { DetalleArriendo } from '@/views/Arriendos/DetalleArriendo/DetalleArriendo';
import { InventoryProducts } from '@/views/Arriendos/InventoryProducts/InventoryProducts';
import { OnBoarding } from '@/views/Arriendos/OnBoarding/OnBoarding';
import { ServicesView } from '@/views/Arriendos/Services/Services';
import { ChatsWsProspectView } from '@/views/ChatsWsProspect';
import { TemplateWsView } from '@/views/TemplateWs';
import { TemplateWsCreateView } from '@/views/TemplateWsCreate';

const AllRoutes: React.FC = () => {


   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const userData = readLocalStorage(KEY_USER_DATA);

   const moduleHome = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={HomeView} />,
   ];

   const moduleProduct = [
      <PrivateRoute key={12} exact path={Routes.ROUTE_PRODUCT} component={ProductView} />,
      // <PrivateRoute key={13} exact path={Routes.ROUTE_PRODUCT_CREATE} component={({ location, ...rest }) => {
      //    const state = location.state;
      //    console.log(state)
      //    return <CreateProductView
      //    product={location.state}
      //    />;
      //    // if(!!state){
      //    // 	return <CreateContactView contact={location.state}/>;
      //    // }else{
      //    // 	return <Redirect to={Routes.ROUTE_CONTACT} />
      //    // }
      // }} />,
      <PrivateRoute key={13} exact path={Routes.ROUTE_PRODUCT_RELATIONS} component={({ location, ...rest }) => {
         const state = location.state;
         if (!!state) {
            return <CreateProductView
            product={location.state}
             />;
         } else {
            return <Redirect to={Routes.ROUTE_PRODUCT} />
         }
      }} />,
   ];

   const moduleService = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SERVICE} component={ServicesView} />,
   ]
   const moduleProductsInventory = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_INVENTORY_PRODUCTS} component={InventoryProducts} />,
   ]

   const moduleArriendos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARRIENDOS} component={ArriendosView} />,
   ];
   const modulePagosArriendos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARRIENDOS_PAYMENT_ADMIN} component={PagosArriendosView} />,
   ];
   const moduleCreateArriendos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARRIENDOS_CREATE} component={CreateArriendos} />,
   ];
   const moduleArriendosPublic = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARRIENDOS_CONFIRMATION} component={ArriendosPublic} />,
   ];
   const moduleDetalleArriendos = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETALLE_ARRIENDOS} component={DetalleArriendo} />,
   ]
   const moduleArriendosOnBoarding = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ONBOARDING_ARRIENDOS} component={OnBoarding} />,
   ]
   const moduleNotify = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_NOTIFY} component={NotifyView} />,
   ];
   const moduleCreateContact = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CREATE_CONTACT} component={SteperContact} />,
   ];
   const moduleCotizacion = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COTIZACION} component={CotizacionesView} />,
   ]
   const moduleCotizacionIntern = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_COTIZACION_INTERNA} component={CotizacionesInternasView} />,
   ]
   const moduleTrello = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TRELLO} component={TrelloView} />,
   ];
   const moduleTrelloLeads = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TRELLO_LEADS} component={TrelloLeadsView} />,
   ];
   const moduleTrelloCotizaciones = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TRELLO_COTIZACIONES} component={TrelloCotizacionesView} />,
   ];
   const modulePerfil = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PERFIL} component={PerfilView} />,
   ];

   const moduleChatWs = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROSPECT_WS} component={ChatsWsProspectView} />,
   ];

   const moduleTemplateWs = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TEMPLATE_WS} component={TemplateWsView} />,
   ];

   const moduleTemplateWsCreate = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_TEMPLATE_WS_CREATE} component={TemplateWsCreateView} />,
   ];

   const moduleSincronizacion = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_SINCRONIZACION} component={SincronizacionView} />,
   ];
   const moduleConfirmacion= [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CONFIRMATION_ACCOUNT} component={ConfirmacionEmailView} />,
   ];

   const moduleCorreosEntrantes = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CORREOS} component={CorreosEntrantesView} />,
   ];
   const moduleCorreosArchivados = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ARCHIVADOS} component={CorreosArchivadosView} />,
   ];
   const moduleCorreosDestacados = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DESTACADOS} component={CorreosDestacadosView} />,
   ];
   // const moduleRecovery= [
   //    <PrivateRoute key={5} exact path={Routes.ROUTE_RECOVERY_ACCOUNT} component={RecoveryEmailView} />,
   // ];

   const moduleLeadsForm= [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CAMPAING_LEADS_FORM} component={LeadsFormView} />,
   ];

   const moduleBrochure= [
      <PrivateRoute key={5} exact path={Routes.ROUTE_BROCHURE} component={BrochureView} />,
   ];

   const moduleLeadsFormCampaingEdit= [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LEADS_FORM_CAMPAING_EDIT} component={LeadsFormCampaingEditView} />,
   ];

   // const moduleLeadsFormCampaing= [
   //    <PrivateRoute key={5} exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />,
   // ];

   // const moduleLeads= [
   //    <PrivateRoute key={5} exact path={Routes.ROUTE_RECOVERY_ACCOUNT} component={RecoveryEmailView} />,
   // ];

   // const moduleContact = [
   //    <PrivateRoute key={5} exact path={Routes.ROUTE_CONTACT} component={ContactView} />,
   // ];
   // const moduleCrearContacto = [
   //    <PrivateRoute key={5} exact path={Routes.ROUTE_CONTACT_CREATE} component={CreateContactView} />,
   // ];

   const moduleContact = [
      <PrivateRoute key={12} exact path={Routes.ROUTE_CONTACT} component={ContactView} />,
      <PrivateRoute key={13} exact path={Routes.ROUTE_CONTACT_CREATE} component={({ location, ...rest }) => {
         const state = location.state;
         //console.log(state)
         return <CreateContactView contact={location.state} />;
         // if(!!state){
         // 	return <CreateContactView contact={location.state}/>;
         // }else{
         // 	return <Redirect to={Routes.ROUTE_CONTACT} />
         // }
      }} />,
      <PrivateRoute key={14} exact path={Routes.ROUTE_CONTACT_UPDATE} component={({ location, ...rest }) => {
         const state = location.state;
         if (!!state) {
            return <CreateContactView contact={location.state} />;
         } else {
            return <Redirect to={Routes.ROUTE_CONTACT} />
         }
      }} />,
   ]
   const moduleUser = [
      <PrivateRoute key={12} exact path={Routes.ROUTE_USER} component={UserView} />,
     <PrivateRoute key={13} exact path={Routes.ROUTE_USER_CREATE} component={({ location, ...rest }) => {
        const state = location.state;
        //console.log(state)
        return <CreateUserView user={location.state}/>;
     }} />,
     <PrivateRoute key={14} exact path={Routes.ROUTE_USER_UPDATE} component={({ location, ...rest }) => {
        const state = location.state;
        if (!!state) {
           return <CreateUserView user={location.state} />;
        } else {
           return <Redirect to={Routes.ROUTE_USER} />
        }
     }} />,
  ]

  const moduleAreaNegocio = [
   <PrivateRoute key={12} exact path={Routes.ROUTE_AREA_NEGOCIO} component={AreaNegocioView} />,
  <PrivateRoute key={13} exact path={Routes.ROUTE_USER_BY_AREA_NEGOCIO_CREATE} component={({ location, ...rest }) => {
     const state = location.state;
     console.log(state)
     return <CreateUserByAreaNegocioView idarea_negocio={location.state}/>;
  }} />,
  <PrivateRoute key={14} exact path={Routes.ROUTE_USER_BY_AREA_NEGOCIO} component={({ location, ...rest }) => {
   const state = location.state;
   if (!!state) {
      //console.log(state)
      return <UserAreaNegocioView user={location.state} />;
   } else {
      return <Redirect to={Routes.ROUTE_AREA_NEGOCIO} />
   }
}} />,
  <PrivateRoute key={14} exact path={Routes.ROUTE_USER_BY_AREA_NEGOCIO_UPDATE} component={({ location, ...rest }) => {
     const state = location.state;
     if (!!state) {
        return <CreateUserByAreaNegocioView user={location.state} />;
     } else {
        return <Redirect to={Routes.ROUTE_AREA_NEGOCIO} />
     }
  }} />,
]
  const moduleProyection = [
      <PrivateRoute key={12} exact path={Routes.ROUTE_PROYECCION} component={ProjectionView} />,
   <PrivateRoute key={13} exact path={Routes.ROUTE_PROYECCION_DETALLE} component={({ location, ...rest }) => {
      const state = location.state;
      return <ProyeccionDetailView data={location.state}/>;
   }} />,
   // <PrivateRoute key={14} exact path={Routes.ROUTE_USER_UPDATE} component={({ location, ...rest }) => {
   //    const state = location.state;
   //    if (!!state) {
   //       return <CreateUserView user={location.state} />;
   //    } else {
   //       return <Redirect to={Routes.ROUTE_USER} />
   //    }
   // }} />,
   ]
   const moduleEntity = [
      <PrivateRoute key={12} exact path={Routes.ROUTE_ENTITY} component={EnterpriseView} />,
      <PrivateRoute key={13} exact path={Routes.ROUTE_ENTITY_CREATE} component={({ location, ...rest }) => {
         const state = location.state;
         //console.log(state)
         return <SteperAccount entity={location.state} />;
      }} />,

      <PrivateRoute key={14} exact path={Routes.ROUTE_ENTITY_USERS} component={({ location, ...rest }) => {
         const state = location.state;
         if (!!state) {
            //console.log(state)
            return <EnterpriseUserView user={location.state} />;
         } else {
            return <Redirect to={Routes.ROUTE_ENTITY} />
         }
      }} />,
      <PrivateRoute key={14} exact path={Routes.ROUTE_ENTITY_UPDATE} component={({ location, ...rest }) => {
         const state = location.state;
         if (!!state) {
            return <SteperAccount entity={location.state}/>;
         } else {
            return <Redirect to={Routes.ROUTE_ENTITY} />
         }
      }} />,
   ]

   const moduleDocuments = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CLIENTS_DOC} component={ClientDocuments} />,
   ]
   const moduleDeatilDocuments = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETALLE_CLIENTS_DOC} component={DetailClientDocView} />,
   ]

   const moduleActividades = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_ACTIVIDADES} component={CalendarView} />,
   ];
   const moduleProgramaciones = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_PROGRAMATION} component={ProgrammationView} />,
   ];

   const moduleCampaign = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CAMPAIGN} component={CampaingView} />,
   ];

   const moduleRule = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_RULE} component={RuleView} />,
   ];

   const moduleCampaignClientTask =[
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETAIL_CLIENTS_TASK} component={DetailCampaignClientsView} />,

   ]

   const moduleCampaignDetailLeads =[
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETAILCAMPAIGNLEADS} component={DetailCampaingLeadsView} />,

   ]
   const moduleCampaignDetailGeneral =[
      <PrivateRoute key={5} exact path={Routes.ROUTE_CAMPAIGN_GENERALES} component={DetailCampaignGeneralView} />,

   ]

   const moduleCampaignDetailLeadsClient =[
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETAILCAMPAIGNLEADSCLIENT} component={DetailCampaignClientsLeadsView} />,

   ]

   const moduleCampaignDetailGeneralClient =[
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETAILCAMPAIGNGENERALCLIENT} component={DetailCampaignClientsGeneralView} />,

   ]


   const moduleConfiguracion = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_CONFIGURACION} component={ConfiguracionView} />,
   ];

   const moduleDetailCampaign = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_DETAILCAMPAIGN} component={DetailCampaingView} />,
   ];

   const moduleListCampaign = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_LIST_CAMPAIGN} component={ListCampaignView} />,
   ];
   const moduleVendedores = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_VENDEDORES} component={SupervisorView} />,
   ];

 const routes = useMemo(() => {
      let role: string = 'prueba';
      //console.log(role)
      if (!!dataUser) {
         console.log(dataUser.user.rut_empresa)
         if (!!dataUser.user) {
            role = dataUser.user.role;

}
      }
      console.log(role)
      switch (role) {

         case ROLE_SUPER_ADMIN:
            return (
               <Router>
                  <Switch>
                  <Route exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />
                     <Route exact path={Routes.ROUTE_CONFIRMATION_ACCOUNT} component={ConfirmacionEmailView}/>
                     <Route exact path={Routes.ROUTE_RECOVERY_ACCOUNT} component={RecoveryEmailView}/>
                     <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {moduleEntity}
                     {modulePerfil}
                     {/* {moduleProgramaciones}
                     {moduleProyection}
                     {moduleAreaNegocio} */}
						{ <Route path="*" exact={true} component={() => {
                     return <Redirect to={Routes.ROUTE_PROGRAMATION}/>
						}} />}
						</Switch>
					</Router>
				);
            case ROLE_TRABAJADOR:
               return (
                  <Router>
                     <Switch>
                     <Route exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />
                        <Route exact path={Routes.ROUTE_CONFIRMATION_ACCOUNT} component={ConfirmacionEmailView}/>
                        <Route exact path={Routes.ROUTE_RECOVERY_ACCOUNT} component={RecoveryEmailView}/>
                        <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
                        <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                        {modulePerfil}
                        {moduleProgramaciones}
                        {moduleProyection}
                        {moduleAreaNegocio}
                     { <Route path="*" exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_PROGRAMATION}/>
                     }} />}
                     </Switch>
                  </Router>
               );
               case ROLE_ADMIN_PROYECCION:
                  return (
                     <Router>
                        <Switch>
                        <Route exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />
                           <Route exact path={Routes.ROUTE_CONFIRMATION_ACCOUNT} component={ConfirmacionEmailView}/>
                           <Route exact path={Routes.ROUTE_RECOVERY_ACCOUNT} component={RecoveryEmailView}/>
                           <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
                           <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                           {modulePerfil}
                           {moduleProgramaciones}
                           {moduleProyection}
                           {moduleAreaNegocio}
                           {moduleCampaignDetailGeneral}
                        { <Route path="*" exact={true} component={() => {
                           return <Redirect to={Routes.ROUTE_PROGRAMATION}/>
                        }} />}
                        </Switch>
                     </Router>
                  );
			case ROLE_ADMIN :
				return (
					<Router>
						<Switch>
                      <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
							<AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {moduleHome}
                     {moduleTrello}
                     {moduleProduct}
                     {moduleContact}
                     {moduleEntity}
                     {moduleActividades}
                     {moduleProgramaciones}
                     {moduleCotizacion}
                     {moduleCampaign}
                     {moduleRule}
                     {moduleCorreosEntrantes}
                     {moduleSincronizacion}
                     {moduleConfirmacion}
                     {moduleDetailCampaign}
                     {moduleNotify}
                     {modulePerfil}
                     {moduleChatWs}
                     {moduleTemplateWs}
                     {moduleTemplateWsCreate}
                     {moduleUser}
                     {moduleTrelloLeads}
                     {moduleTrelloCotizaciones}
                     {moduleProyection}
                     {moduleConfiguracion}
                     {moduleCreateContact}
                     {moduleCampaignClientTask}
                     {moduleCotizacionIntern}
                     {moduleCampaignDetailLeads}
                     {moduleCampaignDetailLeadsClient}
                     {moduleCampaignDetailGeneralClient}
                     {moduleAreaNegocio}
                     {moduleLeadsForm}
                     {moduleBrochure}
                     {moduleListCampaign}
                     {moduleCampaignDetailGeneral}
                     {moduleDocuments}
                     {moduleDeatilDocuments}
                     {moduleVendedores}
                     {moduleLeadsFormCampaingEdit}
                     {/* {moduleLeadsFormCampaing} */}
                     {moduleCorreosDestacados}
                     {moduleCorreosArchivados}
                     {moduleArriendos}
                     {modulePagosArriendos}
                     {moduleCreateArriendos}
                     {moduleDetalleArriendos}
                     {moduleProductsInventory}
                     {moduleArriendosOnBoarding}
                     {moduleService}
							{<Route path='*' exact={true} component={() => {
								return <Redirect to={Routes.ROUTE_HOME}/>
							}} />}
						</Switch>
					</Router>
				);
			case ROLE_SUPERVISOR :
				return (
					<Router>
						<Switch>
                     <Route exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />
                     <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
							<AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {moduleHome}
                     {moduleTrello}
                     {moduleProduct}
                     {moduleContact}
                     {moduleActividades}
                     {moduleProgramaciones}
                     {moduleCotizacion}
                     {moduleCampaign}
                     {moduleRule}
                     {moduleDetailCampaign}
                     {moduleNotify}
                     {modulePerfil}
                     {moduleUser}
                     {moduleTrelloLeads}
                     {moduleTrelloCotizaciones}
                     {moduleProyection}
                     {moduleCreateContact}
                     {moduleCampaignClientTask}
                     {moduleCotizacionIntern}
                     {moduleCampaignDetailLeads}
                     {moduleCampaignDetailLeadsClient}
                     {moduleCampaignDetailGeneralClient}
                     {moduleLeadsForm}
                     {moduleBrochure}
                     {moduleListCampaign}
                     {moduleCampaignDetailGeneral}
                     {moduleVendedores}
                     {moduleChatWs}
                     {moduleTemplateWs}
                     {moduleTemplateWsCreate}
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_HOME} />
                     }} />}
                  </Switch>
               </Router>
            );
         case ROLE_VENDEDOR:
            return (
               <Router>
                  <Switch>
                  <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     {/* <Route exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />
                     <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/> */}
                     {moduleHome}
                     {moduleTrello}
                     {moduleTrelloLeads}
                     {moduleProduct}
                     {moduleContact}
                     {moduleTrelloCotizaciones}
                     {moduleActividades}
                     {moduleCampaign}
                     {moduleRule}
                     {modulePerfil}
                     {moduleDetailCampaign}
                     {moduleCampaignDetailLeads}
                     {moduleCampaignClientTask}
                     {moduleNotify}
                     {moduleCampaignDetailLeadsClient}
                     {moduleCampaignDetailGeneralClient}
                     {moduleListCampaign}
                     {moduleCampaignDetailGeneral}
                     {moduleCreateContact}
                     {moduleCorreosEntrantes}
                     {moduleCorreosArchivados}
                     {moduleCorreosDestacados}
                     {moduleChatWs}
                     {moduleTemplateWs}
                     {moduleTemplateWsCreate}
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_HOME} />
                     }} />}
                  </Switch>
               </Router>
            );
         default:
            return (
               <Router>
                  <Switch>
                     {moduleHome}
                     {moduleCorreosEntrantes}
                     {moduleTrello}
                     {moduleProduct}
                     {moduleContact}
                     {moduleEntity}
                     {moduleActividades}
                     {moduleProgramaciones}
                     {moduleCotizacion}
                     {moduleCampaign}
                     {moduleRule}
                     {moduleSincronizacion}
                     {moduleCampaignDetailLeadsClient}
                     {moduleCampaignDetailGeneralClient}
                     {moduleDetailCampaign}
                     {moduleNotify}
                     {modulePerfil}
                     {moduleUser}
                     {moduleTrelloLeads}
                     {moduleProyection}
                     {moduleConfiguracion}
                     {moduleCreateContact}
                     {moduleCampaignClientTask}
                     {moduleCotizacionIntern}
                     {moduleCampaignDetailLeads}
                     {moduleAreaNegocio}
                     {moduleLeadsForm}
                     {moduleBrochure}
                     {moduleListCampaign}
                     {moduleCampaignDetailGeneral}
                     {moduleLeadsFormCampaingEdit}
                     {/* {moduleLeadsFormCampaing} */}
                     {moduleCorreosDestacados}
                     {moduleCorreosArchivados}
                     {moduleDetalleArriendos}
                     {moduleArriendos}
                     {modulePagosArriendos}
                     {moduleCreateArriendos}
                     {moduleDetalleArriendos}
                     {moduleProductsInventory}
                     {moduleArriendosOnBoarding}
                     {moduleService}
                     {/* { moduleArriendosPublic } */}
                     <AuthRoute exact path={Routes.ROUTE_LOGIN} component={LoginView} />
                     <Route exact path={Routes.ROUTE_CONFIRMATION_ACCOUNT} component={ConfirmacionEmailView}/>
                     <Route exact path={Routes.ROUTE_RECOVERY_ACCOUNT} component={RecoveryEmailView}/>
                     <Route exact path={Routes.ROUTE_LEADS} component={LeadsView}/>
                     <Route exact path={Routes.ROUTE_ARRIENDOS_CONFIRMATION} component={ArriendosPublic}/>
                     <Route exact path={Routes.ROUTE_ARRIENDOS_PAY} component={PagoArriendosPublic}/>
                     <Route exact path={Routes.ROUTE_LEADS_FORM_CAMPAING} component={LeadsFormCampaingView} />
                     <Route exact path={Routes.ROUTE_DOCUMENTOS} component={DocumentsView} />
                     <Route exact path={Routes.ROUTE_LOGIN_DOCUMENTS} component={LoginDocumentsView} />
                     <Route exact path={Routes.ROUTE_REGISTER_VIEW_LANDING} component={ViewRedirectView} />
                     <Route exact path={Routes.ROUTE_REGISTRAR_USER_DOC} component={RegistroView} />

                     <Route path={Routes.ROUTE_COTIZACION} exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_COTIZACION} />
                     }} />
                     {/* {<Route path={Routes.ROUTE_DOCUMENTOS} exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_DOCUMENTOS} />
                     }} />} */}
                     {<Route path='*' exact={true} component={() => {
                        return <Redirect to={Routes.ROUTE_LOGIN} />
                     }} />}
                  </Switch>
               </Router>
            )
      }
   },// eslint-disable-next-line
      [JSON.stringify(dataUser)]);

   return routes;
}

export default AllRoutes;
