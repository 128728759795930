import { http } from '../http/http';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const mailRepository = {
   sendMail: async () => {
      const areas = await http.post(`${API_URL_BASE}/v1/mail`)
   },
   sendFeedbackNotifyOpening: async (periodId: number) => {
      const opening = await http.post(`${API_URL_BASE}/v1/mail/feedback/${periodId}/opening`)
   },
   sendFeedbackNotifyClosure: async (feedbackMmeberId: number) => {
      const closure = await http.post(`${API_URL_BASE}/v1/mail/feedback/${feedbackMmeberId}/closure`)
   },
   sendEmailLeads: async (idCampaign, email) => {
      const closure = await http.post(`${API_URL_BASE}/softnet/sync/sendEmail?idcampaign=${idCampaign}&email=${email}`)
      return closure
   },
   insertHistorialCorreo: async (data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/insertDetalleHistorial`, data)
      return resp
   },
   getHistorialCorreo: async(id_detalle) => {
      const resp = await http.get(`${API_URL_BASE}/v1/mail/historial?id_detalle=${id_detalle}`)
      return resp
   },
   getHistorialCorreoCliente: async(id_cliente) => {
      const resp = await http.get(`${API_URL_BASE}/v1/mail/historialClient?id_cliente=${id_cliente}`)
      return resp
   },
   updateDesarchivar:async(id) => {
      const resp = await http.get(`${API_URL_BASE}/v1/mail/desarchivar?id=${id}`)
      return resp
   },
   getCuentaCorreoImap: async ()=>{
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/cuenta/imap?id_empresa=${id_empresa}`)
      return resp_correo
   },
   getCreateOrUpdateCuentaCorreoImap: async (data)=>{
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.post(`${API_URL_BASE}/v1/mail/cuenta/register-imap`,{
         correo: data.correo,
         password_generate: data.password_generate,
         id_usuario: data.idusuario,
         host: data.host,
         puerto: data.puerto,
         id_empresa: id_empresa
      })
      return resp_correo
   },
   getCorreosRecibidos: async() => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/recibidos?id_empresa=${id_empresa}`)
      return resp_correo
   },

   getMailEspecificacion: async(especificacion) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_usuario = dataUser?.user?.idusuario;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/especificacion?especificacion=${especificacion}&id_usuario=${id_usuario}`)
      return resp_correo
   },

   getDescargarArchivo: async(attach, uid) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/adjuntos?id_empresa=${id_empresa}&attach=${attach}&uid=${uid}`)
      return resp_correo
   },
   getBuscadorCorreo: async(palabra) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/buscador?palabra=${palabra}&id_empresa=${id_empresa}`)
      return resp_correo
   },
   getBuscadorCorreoCliente: async(palabra) => {
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/buscador/cliente?palabra=${palabra}`)
      return resp_correo
   },
   getBuscadorCorreoFecha: async(fecha) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/buscador/fecha?fecha=${fecha}&id_empresa=${id_empresa}`)
      return resp_correo
   },
   getVerifyCLient: async(correo) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/verify-client?correo=${correo}&&id_empresa=${id_empresa}`)
      return resp_correo
   },
   postProspectoCliente: async(data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/asignarProspecto`,{data
      })
      return resp
   },
   buscarCampaign: async(palabra) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const resp_correo = await http.get(`${API_URL_BASE}/v1/mail/campana?palabra=${palabra}&&rut_empresa=${rut_empresa}`)
      return resp_correo
   },
   enlazarCampana: async(data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/asignarCampana`,{data
      })
      return resp
   },
   sendMailCotizacion: async(data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/sendCotizacion`,{data})
      return resp 
   },
   sendLandingMail: async(data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/sendLandingMail`,data)
      return resp 
   },
   sendLandingMailMasivo: async(data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/sendLandingMailMasivo`,data)
      return resp 
   },
   getUltimaPlantillaCampaña: async(idcampana, tipo) => {
      const resp = await http.get(`${API_URL_BASE}/v1/mail/ultimaPlantillaCampana?idcampana=${idcampana}&tipo=${tipo}`)
      return resp
   },
   getVerifyPlantillaDetalleCampana: async(iddetallecampana, tipo) => {
      const resp = await http.get(`${API_URL_BASE}/v1/mail/verifyPlantillaDetalleCampana?iddetallecampana=${iddetallecampana}&tipo=${tipo}`)
      return resp
   }
}
