import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Protected } from '@components/layout/Protected';
import { Props } from './Antecedentes.type';
import { TableData } from '@/components/common/Table';
import { Button, Grid, TextField, Backdrop, CircularProgress, Avatar, MenuItem, Select, InputLabel, FormControl, FormHelperText, Snackbar, Box, AppBar, Toolbar, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { bypassService } from '@/service/services/Bypass.service';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import img_pdf from "@assets/img/ico_pdf.png";
import img_archive from "@assets/img/ico_archive.png";
import img_default from "@assets/img/img_default.png";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_DOCUMENTS } from '@/toolbox/constants/local-storage';
import { ConfirmDialog } from '@components/common/DialogConfirm';

export const AntecedentesView: React.FC<Props> = ({ }): JSX.Element => {
    
   const dataDocumentos = readLocalStorage(KEY_DOCUMENTS) || [];
   console.log(dataDocumentos)
   const [dataDocuments, setDataDocuments] = useState<any>([])
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Guardar cambios',
      confirm: false,
      contactId: null,
      message: ``
   })
   const [open, setOpen] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const [fileUrl, setFileUrl] = useState<any>(null);
   const [activeFiles, setActiveFiles] = useState<any>([
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
      { file: false, data: null },
   ]);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   useEffect(() => {
      getTypeDocumentForClient()
   }, [])

   const getTypeDocumentForClient = async () => {
      const resp = await bypassService.getTypeDocumentForClient(dataDocumentos?dataDocumentos.idtipo_prospecto:'');
      console.log(resp)
      setDataDocuments(resp?.data)
   }

   function processFile(event) {
      console.log(event.target.id)
      console.log(event.target.files[0])
      switch (event.target.id) {
         case 'file0_1':
            setActiveFiles([...activeFiles, activeFiles[0].file = true, activeFiles[0].data = event.target.files[0]]);
            break;
         case 'file1_1':
            setActiveFiles([...activeFiles, activeFiles[1].file = true, activeFiles[1].data = event.target.files[0]]);
            break;
         case 'file2_1':
            setActiveFiles([...activeFiles, activeFiles[2].file = true, activeFiles[2].data = event.target.files[0]]);
            break;
         case 'file3_1':
            setActiveFiles([...activeFiles, activeFiles[3].file = true, activeFiles[3].data = event.target.files[0]]);
            break;
         case 'file4_1':
            setActiveFiles([...activeFiles, activeFiles[4].file = true, activeFiles[4].data = event.target.files[0]]);
            break;
         case 'file5_1':
            setActiveFiles([...activeFiles, activeFiles[5].file = true, activeFiles[5].data = event.target.files[0]]);
            break;
         case 'file6_1':
            setActiveFiles([...activeFiles, activeFiles[6].file = true, activeFiles[6].data = event.target.files[0]]);
            break;
         case 'file7_1':
            setActiveFiles([...activeFiles, activeFiles[7].file = true, activeFiles[7].data = event.target.files[0]]);
            break;
         case 'file8_1':
            setActiveFiles([...activeFiles, activeFiles[8].file = true, activeFiles[8].data = event.target.files[0]]);
            break;
         case 'file9_1':
            setActiveFiles([...activeFiles, activeFiles[9].file = true, activeFiles[9].data = event.target.files[0]]);
            break;

         case 'file0_2':
            setActiveFiles([...activeFiles, activeFiles[0].file = true, activeFiles[0].data = event.target.files[0]]);
            break;
         case 'file1_2':
            setActiveFiles([...activeFiles, activeFiles[1].file = true, activeFiles[1].data = event.target.files[0]]);
            break;
         case 'file2_2':
            setActiveFiles([...activeFiles, activeFiles[2].file = true, activeFiles[2].data = event.target.files[0]]);
            break;
         case 'file3_2':
            setActiveFiles([...activeFiles, activeFiles[3].file = true, activeFiles[3].data = event.target.files[0]]);
            break;

         case 'file0_3':
            setActiveFiles([...activeFiles, activeFiles[0].file = true, activeFiles[0].data = event.target.files[0]]);
            break;
         case 'file1_3':
            setActiveFiles([...activeFiles, activeFiles[1].file = true, activeFiles[1].data = event.target.files[0]]);
            break;

         case 'file0_4':
            setActiveFiles([...activeFiles, activeFiles[0].file = true, activeFiles[0].data = event.target.files[0]]);
            break;
         case 'file1_4':
            setActiveFiles([...activeFiles, activeFiles[1].file = true, activeFiles[1].data = event.target.files[0]]);
            break;
         case 'file2_4':
            setActiveFiles([...activeFiles, activeFiles[2].file = true, activeFiles[2].data = event.target.files[0]]);
            break;
         case 'file3_4':
            setActiveFiles([...activeFiles, activeFiles[3].file = true, activeFiles[3].data = event.target.files[0]]);
            break;
         case 'file4_4':
            setActiveFiles([...activeFiles, activeFiles[4].file = true, activeFiles[4].data = event.target.files[0]]);
            break;
      }
   }

   const SaveDocumentsDependiente = async () => {
         const formData = new FormData();
         formData.append('documento0', activeFiles[0].data || '');
         formData.append('documento1', activeFiles[1].data || '');
         formData.append('documento2', activeFiles[2].data || '');
         formData.append('documento3', activeFiles[3].data || '');
         formData.append('documento4', activeFiles[4].data || '');
         formData.append('documento5', activeFiles[5].data || '');
         formData.append('documento6', activeFiles[6].data || '');
         formData.append('documento7', activeFiles[7].data || '');
         formData.append('documento8', activeFiles[8].data || '');
         formData.append('documento9', activeFiles[9].data || '');
         formData.append('tipodocumento0', "1");
         formData.append('tipodocumento1', "2");
         formData.append('tipodocumento2', "3");
         formData.append('tipodocumento3', "4");
         formData.append('tipodocumento4', "5");
         formData.append('tipodocumento5', "6");
         formData.append('tipodocumento6', "7");
         formData.append('tipodocumento7', "8");
         formData.append('tipodocumento8', "9");
         formData.append('tipodocumento9', "10");
         formData.append('rut', dataDocumentos.rut);
         formData.append('tipo_prospecto', '1')//mandar del local storage
         formData.append('id_login_documento', dataDocumentos?dataDocumentos.id:'')//mandar del local storage
         const resp = await bypassService.saveDocumento(formData);
   };

   const SaveDocumentsIndependiente = async () => {
      const formData = new FormData();
      formData.append('documento0', activeFiles[0].data || '');
      formData.append('documento1', activeFiles[1].data || '');
      formData.append('documento2', activeFiles[2].data || '');
      formData.append('documento3', activeFiles[3].data || '');
      formData.append('tipodocumento0', "11");
      formData.append('tipodocumento1', "12");
      formData.append('tipodocumento2', "13");
      formData.append('tipodocumento3', "14");
      formData.append('rut', dataDocumentos.rut);
      formData.append('tipo_prospecto', '2')//mandar del local storage
      formData.append('id_login_documento',  dataDocumentos?dataDocumentos.id:'')//mandar del local storage
      const resp = await bypassService.saveDocumento(formData);
   };

   const SaveDocumentsRentistas = async () => {
      const formData = new FormData();
      formData.append('documento0', activeFiles[0].data || '');
      formData.append('documento1', activeFiles[1].data || '');
      formData.append('tipodocumento0', "15");
      formData.append('tipodocumento1', "16");
      formData.append('rut', dataDocumentos.rut);
      formData.append('tipo_prospecto', '3')//mandar del local storage
      formData.append('id_login_documento', dataDocumentos?dataDocumentos.id:'')//mandar del local storage
      const resp = await bypassService.saveDocumento(formData);
   };

   const SaveDocumentsSocioEmpresa = async () => {
      const formData = new FormData();
      formData.append('documento0', activeFiles[0].data || '');
      formData.append('documento1', activeFiles[1].data || '');
      formData.append('documento2', activeFiles[2].data || '');
      formData.append('documento3', activeFiles[3].data || '');
      formData.append('documento4', activeFiles[4].data || '');
      formData.append('tipodocumento0', "17");
      formData.append('tipodocumento1', "18");
      formData.append('tipodocumento2', "19");
      formData.append('tipodocumento3', "20");
      formData.append('tipodocumento4', "21");
      formData.append('rut', dataDocumentos.rut);
      formData.append('tipo_prospecto', '4')//mandar del local storage
      formData.append('id_login_documento',  dataDocumentos?dataDocumentos.id:'')//mandar del local storage
      const resp = await bypassService.saveDocumento(formData);
   };

   // const HandleSubmit = async () =>{
   //    if()
   // }

   const ValidateSaveDocument = () => {
      let contador = 0;
      for (let i = 0; i < dataDocumentos.countDocument; i++) {
         if (!activeFiles[i].file) {
            contador = contador + 1;
         }
      }
      if (contador == 0) {
         setDialog(prev => ({ ...prev, message: `Se estan guardando ${dataDocumentos.countDocument} documentos`, open: true, confirm: true }));
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: "Agregue todos los documentos ",
         }));
      }

   }

   const SaveDocumentos = async () => {
      if (Dialog.confirm == true) {
         switch (dataDocumentos.idtipo_prospecto) {
            case 1:
               SaveDocumentsDependiente();
               break;
            case 2:
               SaveDocumentsIndependiente();
               break;
            case 3:
               SaveDocumentsRentistas();
               break;
            case 4:
               SaveDocumentsSocioEmpresa();
               break;
         }
      }
   }

   return (
      <Box sx={{ p: 6 }}>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               onConfirm={() => SaveDocumentos()}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Grid container spacing={2}>
            <Grid item container direction="row"
               justifyContent="end"
               alignItems="center">
           <Grid item xs={11}>
               <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>{`Antecedentes`}</Typography>
            </Grid>
            <Grid item xs={1}>
               <Button variant='contained' onClick={() => { ValidateSaveDocument() }}>Guardar</Button>
            </Grid>
           </Grid>
            {
               dataDocuments.map((value, i) => {
                  const fileValue = `file${i}`;
                  return (
                     <>
                        <Grid item container flexDirection='row'>
                           <Avatar sx={{ bgcolor: '#007EA7', mr: 2, mb: 2 }}>{i + 1}</Avatar>
                           <Typography variant='h5'>{value.nombre}</Typography>
                        </Grid>
                        <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em', ml: 6 }}> {'(*) '}{value.especificacion}</Typography>
                        <Grid item xs={12} container flexDirection='row' justifyContent={'center'} style={{ padding: "0px 5px", position: 'relative' ,margin:'20px 0px'}}>
                           <Button component="label" style={{ width: "20%", height:'170px',border: '3px solid #155172'}}>
                              <Grid item xs={12}>
                                 <Grid item container flexDirection='row' justifyContent={'center'}>
                                    <Box
                                       component="img"
                                       style={{
                                          height: 60,
                                          width: 60,
                                       }}
                                       src={activeFiles[i].file ? img_pdf : img_default}
                                    />
                                 </Grid>
                                 {!activeFiles[i].file && <span style={{ fontSize: '12px', textTransform: 'none', textAlign: 'center', color: '#155172' }}>
                                    Adjunte un archivo aquí
                                 </span>}
                              </Grid>
                              {!activeFiles[i].file && <input id={`file${i}_${value.tipo_prospecto}`} type="file" accept="application/pdf" onChange={processFile} style={{ display: 'none' }} />}
                           </Button>
                           {activeFiles[i].file &&
                              (<Tooltip title="Eliminar Documento" placement="right-start">
                                 <HighlightOffIcon color='warning' sx={{ position: 'absolute', top: '3%', right: '45%' }}
                                    onClick={() => {
                                       setActiveFiles([...activeFiles, activeFiles[i].file = false, activeFiles[i].data = null]);
                                    }} />
                              </Tooltip>)
                           }
                        </Grid>

                     </>
                  )
               })
            }
           
         </Grid>
      </Box>
   )
}
