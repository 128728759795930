import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
//import { Props } from './DetailCampaing.type';
import { useHistory } from 'react-router-dom'
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, InputLabel, TextField, Typography, Button, IconButton, Backdrop, Chip, Avatar, Tooltip } from '@mui/material';
import { AcordionUserBussines } from '@/components/common/Acordion';
import { TableData } from '@/components/common/Table';
import { ModalNewCampaign } from '@/components/common/Modal';
import { ROUTE_CAMPAIGN, ROUTE_CONTACT_UPDATE, ROUTE_DETAIL_CLIENTS_TASK, ROUTE_TRELLO } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import { campaingService } from '@/service/services/Campaing.service';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ModalChangeDetailCampaign } from '@/components/common/Modal/ModalChangeDetailCampaing';
import { ModalAddProspectosCampaing } from '@/components/common/Modal/ModalAddProspectosCampaing';
import { ModalMoveClient } from '@/components/common/Modal/ModalMoveClient';
import { SpinnerGrow } from '@/components/common/Spinner';
import { ROLE_SUPERVISOR, ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ModalCotizacion } from '@/components/common/Modal/ModalCotizacion';
import { ModalOptionsCotizacion } from '@/components/common/Modal/ModalOptionsCotizacion';
import { ModalCorreoEnviados } from '@/components/common/Modal/ModalCorreosEnviados';

export const DetailCampaingView: React.FC<any> = (props): JSX.Element => {

   const header = [{ name: 'nombre_rz', label: 'Nombre', width: '' },
   { name: 'nombre_usuario', label: 'Vendedor Asignado', width: '' },
   //  {name:'fechafin',label:'Fecha de Fin',width:''},
   //  {name:'cantidad',label:'Cantidad',width:''},
   { name: 'nombre_pipelines', label: 'Etapa', width: '' }];
   const dataAction = [
      //   {name: 'delete',color:'secondary'},
      { name: 'edit', color: 'primary' },
      { name: 'view', color: 'success', route: ROUTE_DETAIL_CLIENTS_TASK },
      { name: 'move', color: 'danger' },
      { name: 'cotizacion', color: 'primary'},
      { name:'options', color:'primary'},
      { name: 'hilo_correo', color: 'primary'}
   ]


   const history = useHistory();
   const [isDetail, setIsDetail] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [openModalMove, setOpenModalMove] = useState(false);
   const [dataDetailCampaing, setDataDetailCampaing] = useState<any>([]);
   const [nombreCampaing, setnombreCampaing] = useState<any>('');
   const [openView, setOpenView] = useState(false);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [dataModalCotizacion, setDataModalCotizacion] = useState<any>([]);
   const [openModalCotizacion, setOpenModalCotizacion] = useState <boolean>(false);
   const [openModalOptions, setOpenModalOptions] = useState<boolean>(false);
   const [tipoModalOptions, setTipoModalOptions] = useState<any>('')
   const [dataMail,setDataMail] = useState<any>({})
   const [cita, setCita] = useState<any>(0);
   const [calificado, setCalificado] = useState<any>(0);
   const [contrato, setContrato] = useState<any>(0);
   const [ganado, setGanado] = useState<any>(0);
   const [perdido, setPerdido] = useState<any>(0);
   const [presentacion, setPresentacion] = useState<any>(0);
   const [campaign, setCampaign] = React.useState([]);
   const [detailSelect, setDetailSelect] = useState<any>([])
   const [openModalCorreosEnviados, setOpenModalCorreosEnviados] = useState<boolean>(false);
   const [ dataCorreosEnviados,setDataCorreosEnviados] = useState<any>([])
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [dataModal, setDataModal] = useState<any>([])
   const [listProductos, setListProductos] = useState<any>(props.location.state?props.location.state.productos:[])
   const [busqueda, setBusqueda] = useState<any>("");
   console.log(props.location.state)
   useEffect(() => {

      estados();
      getDetalleCampaing();


   }, [props.location.state.id])



   useEffect(() => {
      estados();
   }, [dataDetailCampaing])

   const nextPage = (toroute, row) => {
      history.push(toroute, row);
   };
   console.log(props.location.state)

   async function getDetalleCampaing() {

      try {
         setOpenView(true)
         const resp = await campaingService.getDataCampaingDetails(props.location.state.id);
         if(resp.data !='Detalle Campaña no encontrada')
         {
            estados();
            setDataDetailCampaing(resp.data?resp.data:[]);
            setnombreCampaing(resp.data?resp.data[0].nombre_campana:'');
            setTablaBusqueda(resp.data?resp.data:[]);
         }else{
            setnombreCampaing(props.location.state.nombre)
         }
         setOpenView(false)
      }
      catch (e) {
         console.log(e)
      }


   }


   //buscador general de detalle

   const handleChange = (e) => {

      setBusqueda(e.target.value)
      filtrar(e.target.value)
   }


   const filtrar = (terminoBusqueda) => {
      var resultBusqueda = tablaBusqueda.filter((elemento) => {
         if (elemento.nombre_rz.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())
            || elemento.nombre_usuario.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())
            || elemento.nombre_pipelines.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())) {
            return elemento
         }
      })
      setDataDetailCampaing(resultBusqueda)
   }


   const RecuperarChangeDetail = async (data) => {
      console.log(data)
      if (data.idvendedor !== '') {

         const resp = await campaingService.updateUserSalesDetail(data.iddetallecampaña, data.idvendedor)

         if (resp.data) {
            getDetalleCampaing();
            setOpen(false)
         }
      }

      if (data.idpipelines !== '') {
         const resp = await campaingService.updatePipelinesDetails(data.iddetallecampaña, data.idpipelines)

         if (resp.data) {
            getDetalleCampaing();
            setOpen(false)
         }

      }
   };

   async function getCampaignPost(value) {

      try {
          const resp = await campaingService.getCampaignTraslate(value)
          if (resp) {
              setCampaign(resp.data)
          }
      } catch (e) {
          console.log(e)
      }

  }

   const RecuperarData = async (data) => {
      if (data.action == 'edit') {
         setOpen(true)
         // //setFormValues(data)
         setDetailSelect(data)

      } else if(data.action == 'move'){
         getCampaignPost(data.idcampaña)
         setDataModal(data)
         setOpenModalMove(true)
      } else if(data.action == 'cotizacion'){
         setDataModalCotizacion(data)
         setOpenModalCotizacion(true)
      }else if(data.action == 'view'){
         console.log(data)
         //    setCampaignObtenida('')
         //    console.log("Eliminando...")
         //    const resp = await ruleService.deleteRule(id);
         //    console.log(resp)
         //   let deleteContact = {};
         //   contact && setDialog(prev => ({ ...prev, message: `¿Desea eliminar al contacto ${contact.nombre_rz} con Rut ${contact.rut_cliente}?`, open: true }));
         //   getRule();
      }else if(data.action == 'options'){
         setTipoModalOptions('detalle');
         setOpenModalOptions(true)
         setDataMail(data);
      }else if(data.action == 'hilo_correo'){
         setDataCorreosEnviados(data);
         setOpenModalCorreosEnviados(true);
      }

   };


   const estados = () => {
      let contCita = 0;
      let contCalificado = 0;
      let contPresentacion = 0;
      let contContrato = 0;
      let contGanado = 0;
      let contPerdido = 0;

     (dataDetailCampaing||[]).map((data) => {
         {console.log(data)}
         switch (data.idpipelines) {
            case 1:
               contCita++;
               break;
            case 2:
               contCalificado++;
               break;
            case 3:
               contPresentacion++;
               break;
            case 4:
               contContrato++;
               break;
            case 5:
               contGanado++;
               break;
            case 6:
               contGanado++;
               break;

         }
      })
      setCita(contCita);

      setCalificado(contCalificado)
      setPresentacion(contPresentacion)
      setContrato(contContrato)
      setGanado(contGanado)
      setPerdido(contPerdido)
   }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 2, mb: 1 }}>
            <Grid container flexDirection='row' justifyContent='space-between'>
               {/* <Button startIcon={<ArrowBackIcon/>} component={Link} to={ROUTE_CAMPAIGN}></Button> */}

               <Grid item md={8} container flexDirection='row'>
                  <IconButton component={Link} to={ROUTE_CAMPAIGN} sx={{ mr: 2 }}>
                     <ArrowBackIcon fill="black" />
                  </IconButton>
                  <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '2.5em' }} >
                     {nombreCampaing}
                  </Typography>

               </Grid>
               <Grid item md={4} container spacing={2} justifyContent='right' alignItems='end'>
               {dataUser.user.role !== ROLE_SUPERVISOR && <Button
                     onClick={() => {  setTipoModalOptions('campaña');
                     setOpenModalOptions(true)}}
                     sx={{
                        background: '#5C68E2', color: '#fff', mr:2, '&:hover': {
                           bgcolor: '#5C68E2'
                        }
                     }}>Mailing</Button>}

                  {dataUser.user.role !== ROLE_SUPERVISOR && <Button
                     onClick={() => { setOpenModal(true)}}
                     sx={{
                        background: '#0E74FF', color: '#fff', mr:2, '&:hover': {
                           bgcolor: '#0E74FF'
                        }
                     }}>Agregar Cliente</Button>}

                  {dataUser.user.role !== ROLE_SUPERVISOR && <Button
                     //variant='outlined'
                     onClick={() => { nextPage(ROUTE_TRELLO, props.location) }}
                     sx={{
                        background: '#bf6c00', color: '#fff', '&:hover': {
                           bgcolor: '#bf6c00'
                        }
                     }}>Vista Trello</Button>}
               </Grid>
               <Grid item md={8} container flexDirection='row' sx={{mt:2}}>
                  {/* <Typography variant='h4' sx={{ color: '#155172',fontWeight: '400', fontSize: '1.5em' }} >
                     Productos:
                  </Typography> */}
                  {listProductos.map((value,i)=>{
                        return(
                           <Tooltip title={value.codigo} key={i} sx={{ml:1}}>
                              <Chip label={`${value.nombre}`} color={value.color} key={i} />
                           </Tooltip>
                        )
                     })}
               </Grid>
            </Grid>
            {/* <Divider sx={{ mt: 3, mb:4 }}/>
            <Typography variant='h5' >Asignación de Clientes a Usuarios Vendedores</Typography>
            <Grid sx={{mt:2,mb:5}}>
                <AcordionUserBussines
                />
            </Grid> */}
            <Divider sx={{ mt: 3, mb: 4 }} />
            {/* <Typography variant='h5' >
            {nombreCampaing}
               </Typography> */}
            {dataUser.user.role !== ROLE_SUPERVISOR && <> <Grid container flexDirection='row' justifyContent='space-around' spacing={2} sx={{ mt: 2, mb: 4 }}>

               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{cita}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Cita</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{calificado}</Typography>
                  <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }} variant='h6'>Calificado</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{presentacion}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Presentacion</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{contrato}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Contrato</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{ganado}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Ganados</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{perdido}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Perdidos</Typography>
               </Grid>
            </Grid>
               <Divider sx={{ mt: 5, mb: 5 }} /></>}
            <Grid container spacing={1} sx={{ mb: 2 }}>
               <Grid item md={12}>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{dataDetailCampaing.length} Clientes encontrados</Typography>
                  <span style={{ color: '#155172', fontSize: '1em' }}>Filtrar Resultados:</span>
               </Grid>
               <Grid item md={12}>
                  <TextField
                     type='search'
                     size='small'
                     onChange={handleChange}
                     name="text"
                     value={busqueda}
                     fullWidth
                  />
               </Grid>
            </Grid>
            <TableData
               header={header}
               data={dataDetailCampaing}
               action={dataAction}
               RecuperarData={RecuperarData} />

         </Container>
         <ModalChangeDetailCampaign
            open={open}
            setOpen={setOpen}
            detailSelect={detailSelect}
            setDetailSelect={setDetailSelect}
            RecuperarData={RecuperarChangeDetail}
         />
          <ModalAddProspectosCampaing
            open={openModal}
            setOpen={setOpenModal}
            dataCampaing={props.location.state}
            refreshData={getDetalleCampaing}

         />
         <ModalMoveClient
            open={openModalMove}
            setOpen={setOpenModalMove}
            data={dataModal}
            dataListCampaing={campaign}
            refreshData={getDetalleCampaing}
         />
         <ModalCotizacion
         open={openModalCotizacion}
         setOpen={setOpenModalCotizacion}
         data={dataModalCotizacion}/>
         
         {
            openModalOptions && 
            <ModalOptionsCotizacion
            open={openModalOptions}
            setOpen={setOpenModalOptions}
            data={dataMail}
            dataCampana = {props.location.state}
            tipo={tipoModalOptions}
            dataProductos={dataDetailCampaing.length > 0 ? dataDetailCampaing[0].productosComplementarios : []}/>
         }
         {
            openModalCorreosEnviados && 
            <ModalCorreoEnviados
            open={openModalCorreosEnviados}
            setOpen={setOpenModalCorreosEnviados}
            data = {dataCorreosEnviados}
            />
         }
      </Protected>
   )
};



