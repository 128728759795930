import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { indigo } from '@mui/material/colors';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { bypassService } from '@/service/services/Bypass.service';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { Link, useHistory } from 'react-router-dom';
import { ModalSearchProduct } from '@/components/common/Modal/ModalSearchProduct';
import { ConfirmDialog } from '@components/common/DialogConfirm';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data:any,
    setData:any
}


export const ModalVerCorreo: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props.data)
    const history = useHistory();
    const bodycorreoref = useRef(null);
    const textcorreo = useRef(null);
    const [renderHtml, setRenderHtml] = useState(false)
    const [textBody,setTextBody] = useState(null)

   useEffect(()=>{
       let modalhtml = document.getElementById('modal');
       let textc: any = document.getElementById('textcorreo');
       let bodyc: any = document.getElementById('bodycorreo');
       let cajaModal:any = document.getElementById('cajaModal');
         const prueba = bodycorreoref.current
         console.log(prueba)
       console.log(modalhtml)
       console.log(textc)
       console.log(bodyc)
       console.log(cajaModal)
    
    setRenderHtml(true)
   },[])

   useEffect(()=>{
    let modalhtml = document.getElementById('modal');
    let textc: any = document.getElementById('textcorreo');
    let bodyc: any = document.getElementById('bodycorreo');
    let cajaModal:any = document.getElementById('cajaModal');
      const prueba = bodycorreoref.current
      console.log(prueba)
    console.log(modalhtml)
    console.log(textc)
    console.log(bodyc)
    console.log(cajaModal)

    setTimeout(()=>{
   if (props.data !== {} && props.open) {

            verCorreo(props.data);
        }
    },100)
    
   },[renderHtml])

   
   const verCorreo = (value) => {
      
      const parser = new DOMParser();
      const body = parser.parseFromString(value.body, "text/html");
      const text = parser.parseFromString(value.html, "text/html");
     
      let textc:any = document.getElementById('textcorreo');
      let bodyc:any = document.getElementById('bodycorreo');
      
      if(textc !== null){
        textc.innerHTML = text.body.outerHTML;
      }else{
        bodyc.innerHTML = body.body.outerHTML;
      }
    //  
    //   if(textc !== null && value.html !== null){
         
    //   }else{
    //      
    //   }
   }

    const bodyModal = (
        <Box className='Modal' >
            <Box id='cajaModal' sx={{ overflowY: 'scroll', maxHeight: '80vh' }}>
                <div id={'textcorreo'} >

                </div>
                <div id={'bodycorreo'}>

                </div>
                <div ref={bodycorreoref}>
                </div>
            </Box>

        </Box> 
    )
    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false); props.setData({}) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
            >
                    {bodyModal}
            </Modal>
        </div>
    );
}
