import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Props } from './FormContact.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { entityService } from '@/service/services/Entity.service';
import './style.sass'
import Entity from "@assets/img/entity.png";
import RuleIcon from '@mui/icons-material/Rule';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArticleIcon from '@mui/icons-material/Article';
import FlagIcon from '@mui/icons-material/Flag';
import { borderColor, width } from '@mui/system';
import Person from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Link, useHistory } from 'react-router-dom'

export const FormContact: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [openModal, setOpenModal] = useState(false)
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [typeRubro, setTypeRubro] = useState<any>([]);
   const [typePersona, setTypePersona] = useState<any>([]);
   const history = useHistory();

   const volver = () => {
      history.push('/contacto')
   }

   const initialFormValuesContact = {
      rut_cliente: '',
      idtipopersona: '',
      idtiporubro: 3,
      nombre_rz: '',
      nombre_fantasia: '',
      giro: '',
      prospecto: '',
      observacion: '',
      extranjero: '',
      // cto_cargo: '',
      cto_cobranza: '',
      fax: '',
      web: '',
   }


   /* REGLAS GENERALES DE VALIDACION*/

   const rules = useMemo(() => ({
      rut_cliente: [
         VALIDATORS.REQUIRED,
         // VALIDATORS.ONLY_LOWERCASE,
      ],
      idtipopersona: [
         VALIDATORS.REQUIRED,

      ],
      idtiporubro: [
         VALIDATORS.REQUIRED,
      ],
      nombre_rz: [
         VALIDATORS.REQUIRED,

      ],
      nombre_fantasia: [
         // VALIDATORS.REQUIRED,

      ],
      giro: [
         // VALIDATORS.REQUIRED,

      ],
      prospecto: [
         VALIDATORS.REQUIRED,

      ],
      observacion: [
         VALIDATORS.REQUIRED,

      ],
      extranjero: [
         VALIDATORS.REQUIRED,

      ],
      // cto_cargo: [
      //    VALIDATORS.REQUIRED,

      // ],
      cto_cobranza: [
         VALIDATORS.REQUIRED,

      ],
   }), []);

   /* VALIDACION DE INPUTS Y TOMA DE VALORES*/

   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_cliente':
            props.setValueContact(prev => ({ ...prev, rut_cliente: value }));
            break;
         case 'idtipopersona':
            props.setValueContact(prev => ({ ...prev, idtipopersona: value }))
            break;
         case 'idtiporubro':
            props.setValueContact(prev => ({ ...prev, idtiporubro: value }))
            break;
         case 'nombre_rz':
            props.setValueContact(prev => ({ ...prev, nombre_rz: value }));
            break;
         case 'nombre_fantasia':
            props.setValueContact(prev => ({ ...prev, nombre_fantasia: value }));
            break;
         // case 'giro':
         //    props.setValueContact(prev => ({ ...prev, giro: value }));
         //    break;
         case 'prospecto':
            props.setValueContact(prev => ({ ...prev, prospecto: value }));
            break;
         case 'observacion':
            props.setValueContact(prev => ({ ...prev, observacion: value }));
            break;
         case 'extranjero':
            props.setValueContact(prev => ({ ...prev, extranjero: value }));
            break;
         case 'web':
            props.setValueContact(prev => ({ ...prev, web: value }));
            break;
         case 'cto_cobranza':
            props.setValueContact(prev => ({ ...prev, cto_cobranza: value }));
            break;
         default:
            break;
      }
   };


   /* EVALUAR QUE NO ESTEN VACIOS */

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueContact(prev => ({ ...prev, [name]: newVal }));
   }

   /* AL DAR SUBMIT */
   const handleSubmit = async (event: React.SyntheticEvent) => {

      event.preventDefault();

      try {
         // const respNombre_rz = await entityService.verifyRazonSocial(props.valueContact.nombre_rz);
         // const respRut = await entityService.verifyRut(props.valueContact.rutempresa);
         const dataEnterprise = {
            rut_cliente: props.valueContact.rut_cliente,
            idtipopersona: props.valueContact.idtipopersona,
            idtiporubro: props.valueContact.idtiporubro,
            nombre_rz: props.valueContact.nombre_rz,
            nombre_fantasia: props.valueContact.nombre_fantasia,
            giro: props.valueContact.idtipopersona == 1 ? 'Empresa' : 'Persona',
            prospecto: props.valueContact.prospecto,
            observacion: props.valueContact.observacion,
            extranjero: props.valueContact.extranjero,
            web: props.valueContact.web,
            cto_cobranza: props.valueContact.cto_cobranza,
         }
         // if (props.valueContact.action == 'edit') {
         //    props.setValueContact({...dataEnterprise,action:'edit'});
         //    console.log(props.valueContact)
         //    props.handleNext();
         // }
         // else if (props.valueContact.action == 'view') {
         //    props.handleNext();
         // }
         if (validate()) {
            console.log(dataEnterprise);
            props.setValueContact(dataEnterprise);

            props.handleNext();
         }
         // else {
         // if (respRut.data == false && respNombre_rz.data == false) {
         //    if (validate()) {

         //       props.setValueEntity(prev => ({ ...prev, ...dataEnterprise }));
         //       props.handleNext();
         //    }
         // }
         // else {
         //    setEstado({ rut: respRut.data, nombre_rz: respNombre_rz.data })
         //    setOpenModal(true);
         // }
         // }
      }
      catch (error) {
         // setDataEntity(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }

   /* VALIDACIONES DE REFS */

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   useEffect(() => {

      // if (props.valueContact.action) {

      //    if (props.valueContact.action == 'view') {

      //       setIsDisabled(true);
      //    }
      //    //getDataUbigeo(props.valueContact.idcomuna);
      // } else {
      //    // if (props.valueContact.idcomuna !== "") {
      //    //    getDataUbigeo(props.valueContact.idcomuna);
      //    // }
      //    // getPais();
      // }
      getDataInitial();
   }, [])

   async function getDataInitial() {
      const resp = await entityService.getDataInitial();
      setTypeRubro(resp.data.entry_type);
      setTypePersona(resp.data.person_type);
   }

   /*RENDERIZADO */

   return (
      <Container maxWidth="xl">
         <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={4}>
                  <FormControl fullWidth >
                     <InputLabel id="demo-simple-select-label">Tipo de Persona</InputLabel>
                     <Select
                        id="idtipopersona"
                        sx={{ bgcolor: '#F3F3F3' }}
                        onChange={handleInput}
                        startAdornment={
                           <InputAdornment position="start">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        placeholder='Tipo de Persona'
                        label='Tipo de Persona '
                        value={props.valueContact.idtipopersona}
                        name='idtipopersona'
                        size='small'
                        disabled={isDisabled}
                     >
                        {typePersona.map((option, i) => {
                           return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                        })
                        }
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                  <Input
                     className='Input'
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="rut_cliente"
                     type='text'
                     placeholder="Rut Cliente"
                     //value={data.rut_empresa}
                     value={props.valueContact.rut_cliente}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.rut_cliente}
                     disableElevation
                     validateOnBlur
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>

               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                     <InputLabel id="demo-simple-select-label">Tipo de Rubro </InputLabel>
                     <Select
                        id="idtiporubro"
                        sx={{ bgcolor: '#F3F3F3' }}
                        onChange={handleInput}
                        startAdornment={
                           <InputAdornment position="start">
                         <BusinessIcon />
                        </InputAdornment>
                        }
                        //onBlur={handleInputBlur}
                        //value={data.idtiporubro}
                        placeholder='Tipo de Rubro'
                        label='Tipo de Rubro '
                        value={props.valueContact.idtiporubro}
                        name='idtiporubro'
                        size='small'
                        disabled={isDisabled}
                     >
                        {typeRubro.map((option, i) => {
                           return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                        })
                        }
                     </Select>
                  </FormControl>
               </Grid> */}
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BusinessIcon />
                        </InputAdornment>
                     }
                     height={35}
                     name="nombre_rz"
                     placeholder="Razon Social"
                     //value={data.nombre_rz}
                     value={props.valueContact.nombre_rz}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.nombre_rz}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BusinessCenterIcon />
                        </InputAdornment>
                     }
                     className='Input'
                     height={35}
                     name="nombre_fantasia"
                     placeholder="Nombre Fantasia"
                     //value={data.nombre_fantasia}
                     value={props.valueContact.nombre_fantasia}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.nombre_fantasia}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>

               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[3] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <CorporateFareIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="giro"
                     placeholder="Giro"
                     //value={data.giro}
                     value={props.valueContact.giro}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.giro}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Prospecto </InputLabel>
                     <Select
                        id='prospecto'
                        name='prospecto'
                        sx={{ bgcolor: '#F3F3F3' }}
                        label="Prospecto"
                        onChange={handleInput}
                        //value={data.prospecto}
                        value={props.valueContact.prospecto}
                        size='small'
                        placeholder='Prospecto'
                        startAdornment={
                           <InputAdornment position="start">
                         <ArticleIcon />
                        </InputAdornment>
                        }
                        disabled={isDisabled}>
                        <MenuItem key={1} value={1}>{"SI"}</MenuItem>
                        <MenuItem key={2} value={0}>{"NO"}</MenuItem>
                     </Select>
                  </FormControl>
               </Grid> */}
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[4] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <SpeakerNotesIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="observacion"
                     placeholder="Observacion"
                     //value={data.observacion}
                     value={props.valueContact.observacion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.observacion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                     <InputLabel id="demo-simple-select-label">Extranjero </InputLabel>
                     <Select
                        id='Extranjero'
                        name='extranjero'
                        sx={{ bgcolor: '#F3F3F3' }}
                        label="Extranjero"
                        placeholder='Extranjero'
                        onChange={handleInput}
                        // value={data.extranjero}
                        startAdornment={
                           <InputAdornment position="start">
                              <FlagIcon />
                           </InputAdornment>
                        }
                        value={props.valueContact.extranjero}
                        size='small'
                        disabled={isDisabled}>
                        <MenuItem key={1} value={1}>{"SI"}</MenuItem>
                        <MenuItem key={2} value={0}>{"NO"}</MenuItem>
                     </Select>
                  </FormControl>
               </Grid> */}
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <LanguageIcon />
                        </InputAdornment>
                     }
                     height={35}
                     name="web"
                     placeholder="Web"
                     //value={data.web}
                     // value={props.valueContact2.web}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     // rules={rules.web}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[5] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LanguageIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="cto_cargo"
                     placeholder="Cargo"
                     //value={data.cto_cargo}
                     value={props.valueContact.cto_cargo}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.cto_cargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Cobranza </InputLabel>
                     <Select
                        id='Cobranza'
                        name='cto_cobranza'
                        sx={{ bgcolor: '#F3F3F3' }}
                        label="Cobranza"
                        placeholder='Cobranza'
                        onChange={handleInput}
                        // value={data.extranjero}
                        startAdornment={
                           <InputAdornment position="start">
                         <LanguageIcon />
                        </InputAdornment>
                        }
                        value={props.valueContact.cto_cobranza}
                        size='small'
                        disabled={isDisabled}>
                        <MenuItem key={1} value={'1'}>{"SI"}</MenuItem>
                        <MenuItem key={2} value={'0'}>{"NO"}</MenuItem>
                     </Select>
                  </FormControl>
               </Grid> */}

               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center" >
                  <Grid item >
                     <Button
                        fullWidth={true}
                        variant='contained'
                        onClick={volver}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon/>}
                     >
                        <span>Cancelar</span>
                     </Button>
                  </Grid>
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                  </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -70%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               {/* <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {` ${estado.rut ? ' El Rut, ' : ''}${estado.nombre_rz ? 'Razon Social,' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography> */}
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
