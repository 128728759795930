import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormContact2.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { entityService } from '@/service/services/Entity.service';
import Entity from "@assets/img/entity.png";
import RuleIcon from '@mui/icons-material/Rule';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArticleIcon from '@mui/icons-material/Article';
import FlagIcon from '@mui/icons-material/Flag';
import { borderColor, width } from '@mui/system';
import Person from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ContactsIcon from '@mui/icons-material/Contacts';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WorkIcon from '@mui/icons-material/Work';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

export const FormContact2: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [openModal, setOpenModal] = useState(false)
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [typeRubro, setTypeRubro] = useState<any>([]);


   const initialFormValuesContact = {
      // fax: '',
      // web: '',
      cto_nombre:'',
      cto_email: '',
      cto_telefono: '',
      cto_celular: '',
      cto_cargo: '',

   }


/* REGLAS GENERALES DE VALIDACION*/

   const rules = useMemo(() => ({
      fax: [
         VALIDATORS.REQUIRED,
        // VALIDATORS.ONLY_LOWERCASE,
      ],
      web: [
         VALIDATORS.REQUIRED,

      ],
      cto_email: [
         VALIDATORS.REQUIRED,

      ],
      cto_nombre: [
         VALIDATORS.REQUIRED,

      ],
      cto_telefono: [
         VALIDATORS.REQUIRED,

      ],
      cto_celular: [
         VALIDATORS.REQUIRED,

      ],

   }), []);

   /* VALIDACION DE INPUTS Y TOMA DE VALORES*/

   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         
         case 'cto_nombre':
            props.setValueContact2(prev => ({ ...prev, cto_nombre: value }))
            break;
         case 'cto_email':
            props.setValueContact2(prev => ({ ...prev, cto_email: value }))
            break;
         case 'cto_telefono':
            props.setValueContact2(prev => ({ ...prev, cto_telefono: value }));
            break;
         case 'cto_celular':
            props.setValueContact2(prev => ({ ...prev, cto_celular: value }));
            break;
            case 'cto_cargo':
               props.setValueContact2(prev => ({ ...prev, cto_cargo: value }));
               break;
         default:
            break;
      }
   };


   /* EVALUAR QUE NO ESTEN VACIOS */

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueContact2(prev => ({ ...prev, [name]: newVal }));
   }

   /* AL DAR SUBMIT */
   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      try {
         // const respNombre_rz = await entityService.verifyRazonSocial(props.valueContact.nombre_rz);
         // const respRut = await entityService.verifyRut(props.valueContact.rutempresa);
         const dataEnterprise = {
            cto_nombre: props.valueContact2.cto_nombre,
            cto_email: props.valueContact2.cto_email,
            cto_telefono: props.valueContact2.cto_telefono,
            cto_celular: props.valueContact2.cto_celular,
            cto_cargo: props.valueContact2.cto_cargo,
         }
         if (validate()) {
            console.log(dataEnterprise)
         props.setValueContact2(dataEnterprise);
         props.handleNext();
         }
         // if (props.valueContact2.action == 'edit') {
         // //    props.setValueContact2({...dataEnterprise,action:'edit'});
         // //    props.handleNext();
         // }
         // else if (props.valueContact2.action == 'view') {
         //    props.handleNext();
         // }
         // else {
            // if (respRut.data == false && respNombre_rz.data == false) {
            //    if (validate()) {

            //       props.setValueEntity(prev => ({ ...prev, ...dataEnterprise }));
            //       props.handleNext();
            //    }
            // }
            // else {
            //    setEstado({ rut: respRut.data, nombre_rz: respNombre_rz.data })
            //    setOpenModal(true);
            // }
         // }
      }
      catch (error) {
         // setDataEntity(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }

   /* VALIDACIONES DE REFS */

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   useEffect(() => {

      if (props.valueContact2.action) {

         if (props.valueContact2.action == 'view') {

            setIsDisabled(true);
         }
         //getDataUbigeo(props.valueContact.idcomuna);
      } else {
         // if (props.valueContact.idcomuna !== "") {
         //    getDataUbigeo(props.valueContact.idcomuna);
         // }
         // getPais();
      }
     // getDataInitial();
   }, [])


   /*RENDERIZADO */

   return (
      <Container maxWidth="xl">
         <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className={`e-mb-lg`}>

               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LanguageIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="fax"
                     placeholder="Fax"
                     //value={data.fax}
                     value={props.valueContact2.fax}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.fax}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LanguageIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="web"
                     placeholder="Web"
                     //value={data.web}
                     value={props.valueContact2.web}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.web}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
                 <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <ContactsIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="cto_nombre"
                     placeholder="Nombre"
                     //value={data.cto_email}
                     value={props.valueContact2.cto_nombre}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.cto_nombre}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[3] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <AlternateEmailIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="cto_email"
                     placeholder="Email"
                     //value={data.cto_email}
                     value={props.valueContact2.cto_email}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.cto_email}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[4] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <ContactPhoneIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="cto_telefono"
                     placeholder="Telefono"
                     //value={data.cto_telefono}
                     value={props.valueContact2.cto_telefono}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     // rules={rules.cto_telefono}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[5] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <PhoneAndroidIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="cto_celular"
                     placeholder="Celular"
                     //value={data.cto_celular}
                     value={props.valueContact2.cto_celular}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.cto_celular}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[6] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <WorkIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="cto_cargo"
                     placeholder="Cargo"
                     //value={data.cto_cargo}
                     value={props.valueContact2.cto_cargo}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     // rules={rules.cto_cargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                 >
                    <Grid item >
                     <Button
                        fullWidth={true}
                        variant='contained'
                        onClick={props.handleBack}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon/>}
                     >
                        <span>Volver</span>
                     </Button>
                     </Grid>

                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -70%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               {/* <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {` ${estado.rut ? ' El Rut, ' : ''}${estado.nombre_rz ? 'Razon Social,' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography> */}
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
