import { MenuLateral } from '../CorreosEntrantes/components/MenuLateral'
import { Protected } from '@/components/layout/Protected';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Alert, Avatar, Backdrop, Button, Checkbox, CircularProgress, Container, Divider, Grid, IconButton, Input, InputAdornment, ListItem, ListItemAvatar, ListItemButton, ListItemText, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Props } from './CorreosArchivos.type';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Link } from 'react-router-dom';
import { ModalCambiarContraseña } from "@/components/common/Modal/ModalCambiarContraseña";
import { SpinnerGrow } from '@/components/common/Spinner';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';
import { mailService } from '@/service/services/Mail.service';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { useHistory } from 'react-router-dom';
import { ModalVerCorreo } from '@/components/common/Modal/ModalVerCorreo';
import { ModalCorreoCliente } from '@/components/common/Modal/ModalCorreoCliente';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PhotoIcon from '@mui/icons-material/Photo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ReplyIcon from '@mui/icons-material/Reply';

export const CorreosArchivadosView : React.FC<Props> = (props:any) : JSX.Element => {
   const history = useHistory();
   const [open, setOpen] = useState<boolean>(false);
   const [openCorreo, setOpenCorreo] = useState<boolean>(false)
   const [openModalClient, setOpenModalClient] = useState<boolean>(false)
   const [clientCorreo, setClientCorreo] = useState<any>([]);
   const data = readLocalStorage(KEY_USER_DATA)
   const [correoSelect, setCorreoSelect] = useState<any>({})
   const [correosArchivados, setCorreosArchivados ] = useState<any>([]);

   const [valueBuscador, setValueBuscador] = useState<any>('');
   const [rutaDownLoad,setRutaDownLoad] = useState<any>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
  })
  
   useEffect(()=>{
      setOpen(true)
      getCorreosEspecificacion();
   },[])

   const handleClick = async(attach, uid) => {
      const resp:any = await mailService.getDescargarArchivo(attach, uid);
      if(resp.data){
         setRutaDownLoad(resp.data.ruta);
         window.open(resp.data.ruta)
      }
   }

   const getCorreosEspecificacion = async() => {
        const resp = await mailService.getMailEspecificacion('archivado');
        setCorreosArchivados(resp);
        setOpen(false)
   }

   const verCorreo = (data) => {
    
       const dataArchivados = {
          body: data.body_correo,
          html: data.html_correo
       }
       setCorreoSelect(dataArchivados)
       setOpenCorreo(true)
 }
   
   const MoverACampañaGeneral = async(data, funcOpen) => {
      funcOpen(true)

      const respVerify:any = await mailService.getVerifyCLient( data.mail);
      console.log(respVerify)
      if(respVerify){
         console.log(respVerify)
         setOpenModalClient(true)
         setCorreoSelect(data)
         setClientCorreo(respVerify?.data?respVerify?.data:[])    
      }

       funcOpen(false)
   }

   const Desarchivar = async (data) => {
        const resp:any = await mailService.updateDesarchivar(data.id);
        if(resp.data){
            getCorreosEspecificacion();
        }else{
            setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'Ocurrio un problema'})
        }
   }

   return(
   <Protected>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
             <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando bandeja de correos...</strong></h1>
               </Grid>
         </Backdrop>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      <Container maxWidth='xl' sx={{mt:1}}>
      <Grid container justifyContent={'space-between'}>
         <Grid item xs={9}>
            <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em', mb: 2 }} >Mis Correos Archivados</Typography>

         </Grid>
         <Grid item xs={3}  
               container
               direction="row"
               justifyContent="flex-end">
           <Grid item mt={2} >
           <Button variant='contained'
             sx={{background:'#E4980D', color:'#fff', '&:hover':{
               bgcolor:'#E4980D'
            }}}
            onClick={()=>{getCorreosEspecificacion()}}>Actualizar</Button>
           </Grid>

         </Grid>

      </Grid>
      

         
         <Grid item md={12}
               sx={{bgcolor: 'background.paper',height:{xs:'55vh',md:'55vh'},overflowX:'hidden', overflowY:'scroll',borderRadius:'0.5rem', border:'0.5px solid #eff3f5',borderLeft: '5px solid #3F9BCC ', mt:3,ml:{xs:1, md:0}}}
         >
             
            {(correosArchivados || []).map((value, i) => {
                   
                   return (
                    <>
                       <ListItem
                          key={i}
                          disablePadding
                          sx={{bgcolor:'#fff'}}
                       >
                         <ListItemButton  onClick={()=>{verCorreo(value)}}>
                         <ListItemText
                                     primary={
                                        <React.Fragment>
                                           <span style={{color:'black'}}>
                                              {dateFormat(value.fecha_correo)+' '+dateFormatTime(value.fecha_correo)}
                                           </span>
                                           
                                        <Typography
                                           sx={{ display: 'inline' , fontSize:'20px',textDecoration:'none',color:'black', ml:1, fontWeight:700}}
                                        >
                                             <strong> 
                                              {value.from_personal}</strong>
                                        </Typography>
                                     </React.Fragment>
                                     }
                                     secondary={
                                        <>
                                           <Typography
                                              sx={{ display: 'inline' , fontSize:'15px',textDecoration:'none', color:value?.todo?.flags?.seen=='Seen'?'#B5B2B2':'black'}}
                                              component="span"
                                              variant="body2"
                                           >
                                              {value.body_correo? value.body_correo.substring(0,120)+'...':'...'}
                                           </Typography>
                                        </>
                                     }
                                  />
                         </ListItemButton>
                         <Tooltip title="Desarchivar">
                                 <IconButton sx={{ justifyContent: 'right' }} onClick={() => { Desarchivar(value) }}>
                                       <ReplyIcon/>
                                    </IconButton>
                        </Tooltip>
                       </ListItem>
                       
                       <Stack direction="row" spacing={1} sx={{zIndex:1000, pl:2}}>
                                             {
                                                value.adjuntos.map((item)=>{
                                                   let icono = <AttachFileIcon/>;

                                                   if(item.tipo == 'application/pdf'){ icono = <PictureAsPdfIcon />}
                                                   if(item.tipo == "image/png"){icono = <PhotoIcon/>}
                                                   return(
                                                      <Chip
                                                         label={item.nombre}
                                                         onClick={()=>{handleClick(item.nombre, value.uid_correo)}}
                                                         icon={icono}
                                                         color="secondary"
                                                         variant="outlined"
                                                      />
                                                   )
                                                })
                                             }
                                       </Stack>
                      <hr></hr> 
                    </>
                 );
                })}   
               
         </Grid>
      </Container>
      {
             openCorreo && <ModalVerCorreo
                setOpen={setOpenCorreo}
                open={openCorreo}
                data={correoSelect}
                setData={setCorreoSelect}
             />
          }
     
   </Protected>
)
   }
