import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import { Props } from './Documents.type';
import { Grid, Backdrop, Snackbar, Box, Container } from '@mui/material';
import { readLocalStorage, removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_DOCUMENTS} from '@/toolbox/constants/local-storage';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material';
import { SituacionEstadoView } from './SituacionEstado';
import { AntecedentesView } from './Antecedentes';
import { PerfilDocumentsView } from './Perfil';
import { ROUTE_LOGIN_DOCUMENTS } from '@/toolbox/constants/route-map';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import { SpinnerGrow } from '@/components/common/Spinner';

export const DocumentsView: React.FC<Props> = ({ }): JSX.Element => {

   const history = useHistory();
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
    })

    const [vistaSelect, setVistaSelect] = useState<any>('EstadoSituacion-Documents')

   useEffect(()=>{
      const dataUserDoc = readLocalStorage(KEY_DOCUMENTS)
      setOpen(true)
      if(!dataUserDoc){
         history.push(ROUTE_LOGIN_DOCUMENTS)
      }
      setOpen(false)
   },[])

   const cerrarSesion = () => {
      removeLocalStorage(KEY_DOCUMENTS)
      history.push(ROUTE_LOGIN_DOCUMENTS)
   }

   return (
      <>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
      </Backdrop>
      <Box sx={{p:6}}>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Container maxWidth='xl'>
            <Grid container flexDirection='row' spacing={2}>
               <Grid item md={3} container>
                  <Box sx={{ width: '100%', bgcolor: 'background.paper', mr: 5 }}>
                     <nav aria-label="main mailbox folders">
                        <List sx={{ display: { xs: 'flex', md: 'block' } }}
                        >
                           <ListItem
                              disablePadding
                              sx={{ border: '0.5px solid #eff3f5', bgcolor: vistaSelect == 'Perfil-Documents' ? '#3F9BCC' : '#fff', maxWidth: { xs: '20%', md: '100rem' } }}
                           >
                              <ListItemButton
                                 onClick={()=>{setVistaSelect('Perfil-Documents')}}
                              >
                                 <ListItemIcon >
                                    <AccountCircleIcon sx={{ color: vistaSelect == 'Perfil-Documents' ? '#fff' : '#000' }} />
                                 </ListItemIcon>
                                 {isMobile ? (<></>) : (<ListItemText primary="Perfil" sx={{ color: vistaSelect == 'Perfil-Documents' ? '#fff' : '#000' }} />)}
                              </ListItemButton>
                           </ListItem>
                           <ListItem
                              disablePadding
                              sx={{ border: '0.5px solid #eff3f5', bgcolor: vistaSelect == 'EstadoSituacion-Documents' ? '#3F9BCC' : '#fff', maxWidth: { xs: '20%', md: '100rem' } }}
                           >
                              <ListItemButton
                                 onClick={()=>{setVistaSelect('EstadoSituacion-Documents')}}
                              >
                                 <ListItemIcon >
                                    <ArticleIcon sx={{ color: vistaSelect == 'EstadoSituacion-Documents' ? '#fff' : '#000' }} />
                                 </ListItemIcon>
                                 {isMobile ? (<></>) : (<ListItemText primary="Estado - Situación" sx={{ color: vistaSelect == 'EstadoSituacion-Documents' ? '#fff' : '#000' }} />)}
                              </ListItemButton>
                           </ListItem>
                           <ListItem
                              disablePadding
                              sx={{ border: '0.5px solid #eff3f5', bgcolor: vistaSelect == 'Antecedentes-Documents' ? '#3F9BCC' : '#fff', maxWidth: { xs: '20%', md: '100rem' } }}
                           >
                              <ListItemButton
                                 onClick={()=>{setVistaSelect('Antecedentes-Documents')}}
                              >
                                 <ListItemIcon >
                                    <InfoIcon sx={{ color: vistaSelect == 'Antecedentes-Documents' ? '#fff' : '#000' }} />
                                 </ListItemIcon>
                                 {isMobile ? (<></>) : (<ListItemText primary="Antecedentes" sx={{ color: vistaSelect == 'Antecedentes-Documents' ? '#fff' : '#000' }} />)}
                              </ListItemButton>
                           </ListItem>
                           <ListItem
                              disablePadding
                              sx={{ border: '0.5px solid #eff3f5', bgcolor: '#E4980D', maxWidth: { xs: '20%', md: '100rem' } }}
                           >
                              <ListItemButton
                                 onClick={()=>{cerrarSesion()}}
                              >
                                 <ListItemIcon >
                                    <CloseIcon sx={{ color: '#fff' }} />
                                 </ListItemIcon>
                                 {isMobile ? (<></>) : (<ListItemText primary="Cerrar Sesión" sx={{ color: '#fff' }} />)}
                              </ListItemButton>
                           </ListItem>
                        </List>
                     </nav>
                  </Box>
               </Grid>
               <Grid item md={9}  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #3F9BCC ', mt: 3 }}
               >
                  {
                     vistaSelect == 'Perfil-Documents' &&
                     <PerfilDocumentsView/>
                  }
                  {
                     vistaSelect == 'EstadoSituacion-Documents' &&
                     <SituacionEstadoView/>
                  }
                  {
                     vistaSelect == 'Antecedentes-Documents' &&
                     <AntecedentesView/>
                  }
               </Grid>
            </Grid>
         </Container>
      </Box>
      </>
      
   )
}
