import { taskService } from '@/service/services/Task.service';
import {
   CAMPANA,
} from '@constants/action-type';

export function actionSetDataCampana(data: any) {
   return {
      type: CAMPANA,
      payload: data
   }
}

