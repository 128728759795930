import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { DrawerDetailExpirationBar } from './DrawerDetailExpirationBar';
import { ArriendosService } from '@/service/services/Arriendos.service.';


export const ExpirationBar: React.FC<any> = (props): JSX.Element => {

    const {setOpen, setSnackBarConfig, snackBarConfig } = props
    const [data, setData] = useState<any>([]);

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<any>(null)

    useEffect(()=>{
        getDashboardEstadoVencimiento();
    },[])

    const getDashboardEstadoVencimiento = async() => {
        setOpen(true);
        const response = await ArriendosService.getDashboardEstadoVencimiento();
        if (!!response?.status) {
           setData(response?.data)
        } else {
           setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
     }

    const optionsBar = {
        scales: {
            y: {
                beginAtZero: true,
                suggestedMin: 0,
                ticks: {
                    callback: (value) => (Number.isInteger(value) ? value : ''),
                },
            },
        },
        onClick: (event, elements) => {
            // Manejar clic en la barra
            // if (elements.length > 0) {
            //     const clickedBarIndex = elements[0]._index;
            //     setItemSelected(data.labels[clickedBarIndex])
            //     setOpenDrawer(true)
            // }
        },
    };


    return (
        <>
            <Bar data={data} options={optionsBar} height={15} width={30} />
            {
                openDrawer && <DrawerDetailExpirationBar
                    openDrawer={openDrawer}
                    setOpenDrawer={setOpenDrawer}
                    itemSelected={itemSelected}
                />
            }
        </>

    )
}
