import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormContactUbigeo.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { entityService } from '@/service/services/Entity.service';
import Entity from "@assets/img/entity.png";
import RuleIcon from '@mui/icons-material/Rule';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArticleIcon from '@mui/icons-material/Article';
import FlagIcon from '@mui/icons-material/Flag';
import { borderColor, width } from '@mui/system';
import Person from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { ubigeoService } from '@/service/services/Ubigeo.service';
import { contactService } from '@/service/services/Contact.service';
import { ROUTE_CONTACT } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom';


export const FormContactUbigeo: React.FC<Props> = (
   props: Props
): JSX.Element => {

   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [openModal, setOpenModal] = useState(false)
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [typeRubro, setTypeRubro] = useState<any>([]);
   const [idpais, setIdPais] = useState(null);
   const [idregion, setIdRegion] = useState(null);
   const [idprovincia, setIdProvincia] = useState(null);
   const [idcomuna, setIdComuna] = useState(null);
   const [ubigeo, setUbigeo] = useState<any>({ pais: [], region: [], provincia: [], comuna: [] });


   const initialFormValuesContact = {
      direccion: '',

   }

   console.log(props.valueContact2)
   // useEffect(() => {
   //    // if (props.valueContactUbigeo.action) {
   //    //    if (props.valueContactUbigeo.action == 'view') {
   //    //       setIsDisabled(true);
   //    //    }
   //    // } else {
   //       props.setValueContactUbigeo(prev => ({ ...prev, idtipousuario: 2, textError: '' }));
   //    // }

   // }, [])



   /* REGLAS GENERALES DE VALIDACION*/

   const rules = useMemo(() => ({
      direccion: [
         VALIDATORS.REQUIRED,
         // VALIDATORS.ONLY_LOWERCASE,
      ],

   }), []);

   /* VALIDACION DE INPUTS Y TOMA DE VALORES*/

   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'direccion':
            props.setValueContactUbigeo(prev => ({ ...prev, direccion: value }));
            break;
         default:
            break;
      }
   };


   /* EVALUAR QUE NO ESTEN VACIOS */

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueContactUbigeo(prev => ({ ...prev, [name]: newVal }));
   }

   /* AL DAR SUBMIT */
   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      try {
         // const respNombre_rz = await entityService.verifyRazonSocial(props.valueContact.nombre_rz);
         // const respRut = await entityService.verifyRut(props.valueContact.rutempresa);

         const dataEnterprise = {
            direccion: props.valueContactUbigeo.direccion,
            idcomuna: idcomuna.id
         }
         // if (props.valueContactUbigeo.action == 'edit') {
         //    props.setValueContactUbigeo({...dataEnterprise,action:'edit'});
         //    props.handleNext();
         // }
         // else if (props.valueContactUbigeo.action == 'view') {
         //    props.handleNext();
         // }
         // else {
         //    // if (respRut.data == false && respNombre_rz.data == false) {
         //    //    if (validate()) {

         //    //       props.setValueEntity(prev => ({ ...prev, ...dataEnterprise }));
         //    //       props.handleNext();
         //    //    }
         //    // }
         //    // else {
         //    //    setEstado({ rut: respRut.data, nombre_rz: respNombre_rz.data })
         //    //    setOpenModal(true);
         //    // }
         // }
         let dataContact;
         if (validate()) {
            if (idcomuna.id !== '') {

               props.setValueContactUbigeo(dataEnterprise);

               if (props.valueContact.idtipopersona === "") {
                  const respRut = await contactService.verifyRut(props.valueContact.rut_cliente);

                  if (respRut.data == false) {
                     let resp;
                     dataContact = { ...props.valueContact, ...props.valueContact2, ...dataEnterprise }
                     console.log(dataContact)
                     resp = await contactService.createContact(dataContact);

                     if (!!resp.data) {
                        history.push(ROUTE_CONTACT);
                     }
                  }
                  else {
                     // setSnackBarConfig(prev => ({
                     //    ...prev,
                     //    open: true,
                     //    message: 'El rut de la empresa que ingreso ya está ocupado'
                     // }))

                  }
               } else {
                  let resp;
                  dataContact = { ...props.valueContact, ...props.valueContact2, ...dataEnterprise }
                  console.log(dataContact)
                  resp = await contactService.createContact(dataContact);

                  if (!!resp.data) {
                     history.push(ROUTE_CONTACT);

                  }


               }

            }


         }


         //props.handleNext();
      }
      catch (error) {
         // setDataEntity(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }

   /* VALIDACIONES DE REFS */

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   useEffect(() => {

      // if (props.valueContactUbigeo.action) {
      //    console.log('')
      //    if (props.valueContactUbigeo.action == 'view') {

      //       setIsDisabled(true);
      //    }
      //    getDataUbigeo(props.valueContactUbigeo.idcomuna);
      // } else {
      //    if (props.valueContact.idcomuna !== "") {
      //       getDataUbigeo(props.valueContactUbigeo.idcomuna);
      //    }
      //    getPais();
      // }
      if (props.valueContactUbigeo.idcomuna !== "") {
         getDataUbigeo(props.valueContactUbigeo.idcomuna);
      }
      getPais();
      getDataInitial();
   }, [])

   const onSelectPais = (pais) => {
      if (pais) {
         if (pais.id != null || pais.id != idpais.id) {
            setIdRegion(null)
            setUbigeo(prev => ({ ...prev, region: [], provincia: [], comuna: [] }));
            setIdProvincia(null)
            setIdComuna(null)
         }
         setIdPais(pais)
      }
   }
   const onSelectRegion = (region) => {
      if (region) {
         // setError("")
         if (region.id != null || region.id != idregion.id) {
            setUbigeo(prev => ({ ...prev, provincia: [], comuna: [] }));
            setIdProvincia(null)
            setIdComuna(null)
         }
         setIdRegion(region)
      }
   }
   const onSelectProvincia = (provincia) => {
      if (provincia) {
         if (provincia.id != null || provincia.id != idprovincia.id) {
            setUbigeo(prev => ({ ...prev, comuna: [] }));
            setIdComuna(null)
         }
         setIdProvincia(provincia)
      }
   }
   const onSelectComuna = (idcomuna) => {

      if (idcomuna) {
         //setError("")
         setIdComuna(idcomuna)
      }
   }
   async function getDataInitial() {
      const resp = await entityService.getDataInitial();
      //setTypeRubro(resp.data.entry_type);
   }
   async function getPais() {
      const resp = await ubigeoService.getPais();
      setUbigeo(prev => ({ ...prev, pais: resp.data.pais }));
   }
   async function getRegion(idPais: number) {
      const resp = await ubigeoService.getRegion(idPais);
      setUbigeo(prev => ({ ...prev, region: resp.data.region }));
   }
   async function getProvincia(idregion: number) {
      const resp = await ubigeoService.getProvincia(idregion);
      setUbigeo(prev => ({ ...prev, provincia: resp.data.province }));
   }
   async function getComuna(idprovincia: number) {

      const resp = await ubigeoService.getComuna(idprovincia);
      setUbigeo(prev => ({ ...prev, comuna: resp.data.comuna }));
   }
   async function getDataUbigeo(id) {

      const resp = await ubigeoService.getUbigeo(id);

      getPais();
      setIdPais({ id: resp.data[0].idpais, pais: resp.data[0].pais })
      getRegion(resp.data[0].idpais)
      setIdRegion({ id: resp.data[0].idregion, region: resp.data[0].region })
      getProvincia(resp.data[0].idregion)
      setIdProvincia({ id: resp.data[0].idprovincia, provincia: resp.data[0].provincia })
      getComuna(resp.data[0].idprovincia)
      setIdComuna({ id: resp.data[0].idcomuna, comuna: resp.data[0].comuna })
   }

   /*RENDERIZADO */

   return (
      <Container maxWidth="xl">
         <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[0] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <HomeWorkIcon />
                        </InputAdornment>
                     }
                     height={35}
                     name="direccion"
                     placeholder="Direccion"
                     //value={data.direccion}
                     value={props.valueContactUbigeo.direccion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.direccion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  {/* <small>País</small> */}
                  <Autocomplete
                     disabled={isDisabled}
                     autoComplete
                     size='small'
                     placeholder='Pais'

                     includeInputInList
                     value={idpais}
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, pais) => {
                        pais && getRegion(pais.id);
                        onSelectPais(pais)
                     }}
                     id="idpais"
                     options={ubigeo.pais}
                     getOptionLabel={(option) => option.pais}
                     renderInput={(params) => <TextField {...params} label='País'
                     />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disabled={isDisabled}
                     value={idregion}
                     size='small'
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, region) => {
                        region && getProvincia(region.id);
                        onSelectRegion(region)
                     }}
                     id="idregion"
                     options={ubigeo.region && ubigeo.region}
                     getOptionLabel={(option) => option.region ? option.region : ""}
                     renderInput={(params) => <TextField {...params} label='Región'

                     />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disabled={isDisabled}
                     value={idprovincia}
                     size='small'
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, provincia) => {
                        provincia && getComuna(provincia.id);
                        onSelectProvincia(provincia)
                     }}
                     id="idprovincia"
                     options={ubigeo.provincia && ubigeo.provincia}
                     getOptionLabel={(option) => option.provincia ? option.provincia : ""}
                     renderInput={(params) => <TextField {...params} label='Provincia' />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disabled={isDisabled}
                     value={idcomuna}
                     size='small'
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, comuna) => {
                        onSelectComuna(comuna)
                     }}
                     id="idcomuna"
                     options={ubigeo.comuna && ubigeo.comuna}
                     getOptionLabel={(option) => option.comuna ? option.comuna : ""}
                     renderInput={(params) => <TextField {...params} label='Comuna' />}
                  />
               </Grid>


               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
               >
                  <Grid item >
                     <Button
                        fullWidth={true}
                        variant='contained'
                        onClick={props.handleBack}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon />}
                     >
                        <span>Volver</span>
                     </Button>
                  </Grid>

                  <Grid item >

                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={props.valueContactUbigeo.action == 'view' ? true : loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{!props.valueContactUbigeo.action ? 'Guardar' : props.valueContactUbigeo.action == 'edit' ? 'Editar' : 'Detalle'}</span>
                        }
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -70%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               {/* <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {` ${estado.rut ? ' El Rut, ' : ''}${estado.nombre_rz ? 'Razon Social,' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography> */}
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
