import { TaskRepository } from "../repositories/Task.repository";

export const taskService= {
   getTask,
   createTask,
   createFeeds,
   getFeeds,
   getFeedsById,
   getFeedsByClient,
   getFeedsCallByClient,
   getFeedsByClientLead,
   EditFeeds,
   getFeedsData,
   feedsCompleted,
   getFeedsByUsers,
   feedsComentario,
   getCampaingbyCliente,
   deleteFeeds,
   getFeedsCallByForm_Client,
   getFeedsByClientAndCampaign,
   filterFeeds
};

async function getTask () {
   const task= await TaskRepository.getTask();
   return task
}

async function getFeeds () {
   const task= await TaskRepository.getFeeds();
   return task
}

async function filterFeeds (ids_tipo_feeds, completed) {
   const task= await TaskRepository.filterFeeds(ids_tipo_feeds, completed);
   return task
}

async function getFeedsByUsers (idusuario, ids_tipo_feeds, completed) {
   const task= await TaskRepository.getFeedsByUsers(idusuario, ids_tipo_feeds,completed);
   return task
}
async function getCampaingbyCliente (idcliente) {
   const task= await TaskRepository.getCampaingbyCliente(idcliente);
   return task
}

async function getFeedsData () {
   const task= await TaskRepository.getFeedsData();
   return task
}

async function getFeedsById (id) {
   const task= await TaskRepository.getFeedsById(id);
   return task
}
async function getFeedsByClient (id) {
   const task= await TaskRepository.getFeedsByClient(id);
   return task
}
async function getFeedsByClientAndCampaign(id) {
   const task= await TaskRepository.getFeedsByClientAndCampaign(id);
   return task
}

async function getFeedsCallByClient (id) {
   const task= await TaskRepository.getFeedsCallByClient(id);
   return task
}

async function getFeedsCallByForm_Client(form_cliente) {
   const task= await TaskRepository.getFeedsCallByForm_Client(form_cliente);
   return task
}

async function getFeedsByClientLead (data) {
   const task= await TaskRepository.getFeedsByClientLead(data);
   return task
}
async function EditFeeds (feeds) {
   const task= await TaskRepository.EditFeeds(feeds);
   return task
}

function createTask(task){
   return TaskRepository.createTask(task)
}

function createFeeds(feeds){
   return TaskRepository.createFeeds(feeds)
}

async function feedsCompleted ( id: number, data: any) {
   const feeds= await TaskRepository.feedsCompleted(id, data);
   return feeds
}

async function feedsComentario ( id: number, data: any) {
   const feeds= await TaskRepository.feedsComentario(id, data);
   return feeds
}

function clienteOrForm(feeds){
   return TaskRepository.clienteOrForm(feeds)
}

function deleteFeeds(id){
   return TaskRepository.deleteFeed(id)
}