import { ArriendosService } from "@/service/services/Arriendos.service.";
import { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';

export const BarStatusProducts: React.FC<any> = (props): JSX.Element => {
    
    const optionsHbar = {
        scales: {
           x: {
              beginAtZero: true,
              max: 100,
           },
           y: {
              beginAtZero: true,
           },
        },
     };

    const {setOpen, setSnackBarConfig, snackBarConfig } = props
    const [data, setData] = useState<any>([]);

   
    useEffect(()=>{
        getDashboardEstadoProductos();
    },[])

    const getDashboardEstadoProductos = async() => {
        setOpen(true);
        const response = await ArriendosService.getDashboardEstadoProductos();
        if (!!response?.status) {
           setData(response?.data)
        } else {
           setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
     }

    return (
    <>
        <Bar data={data} options={optionsHbar} height={30} width={30} />

    </>
 )
}