import { productRepository } from "../repositories/Product.repository";

// export const productService = {
//    getProduct: () => {
//       return productRepository.getProduct()
//    },
// };


export const productService= {
   getProduct,
   getProductService,
   getSearchService,
   getDataProducts,
   getProductType,
   getSearchProduct,
   getProductChildren,
   getProductChildrenForId,
   getProductRelationsInterval,
   deleteRelationsProduct,
   postGenerateRelationsProducts,
   searchProduct,
   createBitacora,
   getProductChildrenForIDS,
   enviarCotizacion,
   getProductCampaign,
   getProductByCampaign,
   getBodega,
   getVendedor,
   getPago,
   getAreaNegocio
};

async function getProduct (perPage, page) {
   const product= await productRepository.getProduct(perPage, page);

   return product
}

async function getProductService (perPage=null, page=null) {
   const product= await productRepository.getProductService(perPage, page);

   return product
}

async function getSearchService (search) {
   const product= await productRepository.getSearchService(search);

   return product
}
async function getDataProducts(){
   const product = await productRepository.getDataProduct();
   return product
}
async function getProductChildrenForId (id) {
   const product= await productRepository.getProductChildrenForId(id);
   return product
}
async function getProductChildrenForIDS (products, tipo) {
   const product= await productRepository.getProductChildrenForIDS(products, tipo);
   return product
}
async function getSearchProduct (search) {
   const product= await productRepository.getSearchProduct(search);

   return product
}
async function getProductType () {
   const product= await productRepository.getProductType();

   return product
}
async function getProductChildren (perPage, page) {
   const product= await productRepository.getProductChildren(perPage, page);

   return product
}
async function postGenerateRelationsProducts (id, productosHijos) {
   const product= await productRepository.postGenerateRelationsProducts(id, productosHijos);

   return product
}

async function deleteRelationsProduct (id) {
   const product= await productRepository.deleteRelationsProduct(id);
   return product
}

async function getProductRelationsInterval () {
   const product= await productRepository.getProductRelationsInterval();
   return product
}

async function searchProduct (term) {
   const product= await productRepository.searchProduct(term);
   return product
}

async function createBitacora (dataBitacora) {
   const resp = await productRepository.createBitacora(dataBitacora);
   return resp
}

async function enviarCotizacion(token, cotizacion){
   const resp = await productRepository.enviarCotizacion(token, cotizacion);
   return resp
}

async function getProductCampaign(){
   const resp = await productRepository.getProductCampaign();
   return resp
}

async function getProductByCampaign(id){
   const resp = await productRepository.getProductByCampaign(id);
   return resp
}


async function getBodega(token){
   const resp = await productRepository.getBodega(token);
   return resp
}

async function getVendedor(token){
   const resp = await productRepository.getVendedor(token);
   return resp
}

async function getPago(token){
   const resp = await productRepository.getPago(token);
   return resp
}

async function getAreaNegocio(token){
   const resp = await productRepository.getAreaNegocio(token);
   return resp
}