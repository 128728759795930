import { Protected } from '@/components/layout/Protected';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Avatar, Button, Checkbox, Container, Grid, IconButton, ListItemAvatar, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
//import { Props } from './Trello.type';
import { TableData } from '@/components/common/Table';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { MenuLateral } from '@/components/common/LateralMenu';
import ContextApi from "./ContextApi";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TrelloList from './components/TrelloList';
import AddCardorList from './components/AddCardorList';
import { makeStyles } from '@mui/styles';
import { campaingService } from '@/service/services/Campaing.service';
import { pink } from '@mui/material/colors';
import { ROUTE_CAMPAIGN, ROUTE_DETAILCAMPAIGN, ROUTE_DETAILCAMPAIGNLEADS } from '@/toolbox/constants/route-map';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { cotizacionService } from '@/service/services/Cotizacion.service';

 const dt= {
   "lists": {
     "1": {
       "id": '1',
       "title": "GENERADA/ABIERTAS",
       "cards": []
     },
     "2": {
       "id":'2',
       "title": "ADJUDICADA C/VN",
       "cards": []
     },
     "3": {
       "id": '3',
       "title": "ANULADA",
       "cards": []
     },
     "4": {
       "id": '4',
       "title": "FACTURADA",
       "cards": []
     },

   },
   "listids": [
     "1",
     "2",
     "3",
     "4"
   ]
 }


 const useStyle= makeStyles((theme) => ({
   root: {
     height: "80vh",
     overflowY: "hidden",
   },
   container: {
     display: "flex",
   },
 }));

 type ModalProps = {
   Data?:any,
   open?:any,
   setOpen:any,
   CambioDataTable?:({})=>void,
 }

export const TrelloCotizacionesView : React.FC<any> = (
   props: ModalProps
) : JSX.Element => {
   const classes = useStyle();
   const history= useHistory();

   // console.log('DSFS',Data)
   const [data1, setData1] = useState<any>(dt);
   //const [nombreCampaing, setNombreCampaing] = useState(props.location.state.state.nombre)

   useEffect(()=>{
    //Pipelines();
   //  if(props?.Data.length>0){
   //    setData1(props.Data)
   //  }
    setData1(props.Data)
    //setData1(data.props)
   },[props?.Data])


   const aa=(aa)=>
   {
      props.CambioDataTable(aa);
      //console.log(aa)
   }

   // console.log(props)

   const Pipelines = async () =>
   {


         // const resp = await campaingService.detailsCampaignLeadsTrello(1);
         // console.log(dt);
         // console.log(resp)
         // setData1(resp);

   }
   const updateCard=(data, id)=>
   {
      const list = data1.lists[id];
      list.cards= list.cards.map((producto) =>producto.folio === data.folio? data : producto)
      setData1({
        ...data1,
        lists: {
          ...data1.lists,
          [id]: list,
        },
      });
   }

   const updateListTitle = (title, listId) => {
   //    console.log(title, listId)
   //   const list = data1.list[listId];
   //   list.title = title;
   //   setData1({
   //     ...data1,
   //     lists: {
   //       ...data1.lists,
   //       [listId]: list,
   //     },
   //   });
   };

   const addCard = (title, listId) => {
     //crear un id único para el  nuevo card
     const newCardId = Date.now();
     //crear el nuevo card
     const newCard = {
       id: newCardId,
       title: title,
     };
     //añadir el nuevo card al array que tiene la lista
     const list = data1.lists[listId];
     list.cards = [...list.cards, newCard];
     setData1({
       ...data1,
       lists: {
         ...data1.lists,
         [listId]: list,
       },
     });
   };
   const addList = (title) => {
     //generar un id unico nuevo para la lista nueva
     const newListId = Date.now();
     setData1({
       listids: [...data1.listids, newListId],
       lists: {
         ...data1.lists,
         [newListId]: {
           id: newListId,
           title: title,
           cards: [],
         },
       },
     });
   };

   const onDragEnd = async(result) => {

     const {
       destination,
       destination: { droppableId: destdroppableId, index: destIndex },
       source,
       source: { droppableId: sourcedroppableId, index: sourceIndex },
       draggableId,
       type,
     } = result;

     console.table([{
        sourcedroppableId,
        destdroppableId,
        draggableId
     }])

     console.table([
      {
         type,
         sourceIndex,
         destIndex
      }
     ])


     if (!destination) {
       return;
     }

     if (type === "list") {
       const newListIds = data1.listids;
       newListIds.splice(sourceIndex, 1);
       newListIds.splice(destIndex, 0, draggableId);
       return;
     } else {
       const sourceList = data1.lists[sourcedroppableId];
       const destinationList = data1.lists[destdroppableId];

       const draggingCard = sourceList.cards.filter(
         (card) => card.idcotizacion == draggableId
       );

       if (sourcedroppableId == destdroppableId) {
         const result = Array.from(sourceList.cards);
         const [removed] = result.splice(sourceIndex, 1);

         result.splice(destIndex, 0, removed);

         //utilizaremos splice para intercambiar los indices

         sourceList.cards.splice(sourceIndex, 1);
         destinationList.cards.splice(destIndex, 0, draggingCard);

         //ACTUALIZAREMOS SETDATA1 CON LOS NUEVOS INDICES

         setData1({
           ...data1,
           lists: {
             ...data1.lists,
             [sourceList.id]: {
               id: sourceList.id,
               title: sourceList.title,
               cards: result,
             },
           },
         });
       } else {
         sourceList.cards.splice(sourceIndex, 1);


         destinationList.cards.splice(destIndex, 0, draggingCard[0]);
         // console.log(sourceList)

         // console.log(draggingCard[0])

         // // sacar destinationList de colum-2 el 2 entra al metodo
         // // let cadena = destinationList.id.indexOf("-")
         // console.log(draggingCard[0].idcotizacion)
         // console.log(destinationList)


      const resp = await cotizacionService.cotizacionesChangeEstado(draggingCard[0].folio,destinationList.idestado)
      props.CambioDataTable(resp.data);

       }
     }
   };

   return(


      //  <Protected >
      //    <Grid container flexDirection='row' justifyContent='space-between'>
      //          <Button startIcon={<ArrowBackIcon/>} component={Link} to={ROUTE_CAMPAIGN}></Button>

      //           <Grid item md={8} container flexDirection='row'>
      //           <IconButton onClick={()=>{history.push(ROUTE_CAMPAIGN)} } sx={{mr:2}}>
      //                       <ArrowBackIcon fill="black"/>
      //                   </IconButton>
      //                <Typography variant='h4' >
      //                  {nombreCampaing}
      //                  </Typography>
      //           </Grid>
      //           <Grid item md={4} container spacing={2} justifyContent='right' alignItems='end'>
      //             <Button variant='outlined'  onClick={()=>{nextPage(ROUTE_TRELLO,props.location.state )} }>Vista Trello</Button>
      //           </Grid>
      //       </Grid>
   <ContextApi.Provider value={{ updateListTitle, addCard, addList}}>
   <div className={classes.root}>
     <DragDropContext onDragEnd={onDragEnd}>
       <Droppable droppableId="12345" type="list" direction="horizontal">
         {(provided) => (
           <div
             className={classes.container}
             ref={provided.innerRef}
             {...provided.droppableProps}
           >
             {data1.listids.map((listid, index) => {
               const list = data1.lists[listid];

               return (
                 <TrelloList
                   list={list}
                   key={listid}
                   index={index}
                   CambioDataTable={aa}
                   pipelines={Pipelines}
                   updateCard={updateCard}
                 ></TrelloList>
               );
             })}
             {/* <div>
               <AddCardorList type="list" listId={null}/>
               {provided.placeholder}
             </div> */}
           </div>
         )}
       </Droppable>
     </DragDropContext>
   </div>
 </ContextApi.Provider>
         // </Protected>
   )

}
