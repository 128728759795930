import { Protected } from '@/components/layout/Protected';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Avatar, Backdrop, Button, Checkbox, Container, Grid, IconButton, ListItemAvatar, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
//import { Props } from './Trello.type';
import { TableData } from '@/components/common/Table';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { MenuLateral } from '@/components/common/LateralMenu';
import ContextApi from "./ContextApi";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TrelloList from './components/TrelloList';
import AddCardorList from './components/AddCardorList';
import { makeStyles } from '@mui/styles';
import { campaingService } from '@/service/services/Campaing.service';
import { pink } from '@mui/material/colors';
import { ROUTE_CAMPAIGN, ROUTE_DETAILCAMPAIGN, ROUTE_DETAILCAMPAIGNLEADS } from '@/toolbox/constants/route-map';
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SpinnerGrow } from '@/components/common/Spinner';
import { ModalAddPriceAndCount } from '@/components/common/Modal/ModalAddPriceAndCount';

 const dt= {
   "lists": {
     "1": {
       "id": '1',
       "title": "Cita Programada",
       "cards": []
     },
     "2": {
       "id":'2',
       "title": "Calificado para comprar",
       "cards": []
     },
     "3": {
       "id": '3',
       "title": "Presentación programada",
       "cards": []
     },
     "4": {
       "id": '4',
       "title": "Contrato Enviado",
       "cards": []
     },
     "5": {
       "id": '5',
       "title": "Cierre Ganado",
       "cards": []
     },
     "6": {
       "id": '6',
       "title": "Cierre Perdido",
       "cards": []
     }
   },
   "listids": [
     "1",
     "2",
     "3",
     "4",
     "5",
     "6"
   ]
 }


 const useStyle= makeStyles((theme) => ({
   root: {
     height: "80vh",
     overflowY: "hidden",
   },
   container: {
     display: "flex",
   },
 }));



export const TrelloView : React.FC<any> = (
   props
) : JSX.Element => {
   const classes = useStyle();
   const history= useHistory();

console.log(props)
   const [data1, setData1] = useState<any>(dt);
   const [openView, setOpenView] = useState(false);
   const [open, setOpen] = useState(false);
   const [nombreCampaing, setNombreCampaing] = useState(props.location.state.state.nombre)
   const [idDetailsCampaing,setIdDetailsCampaing] = useState([])

   useEffect(()=>{
    Pipelines();
   },[])


   const Pipelines = async () =>
   {
      setOpenView(true);
      if(props.location.state.pathname == ROUTE_DETAILCAMPAIGNLEADS){

      }else if(props.location.state.pathname == ROUTE_DETAILCAMPAIGN){
         const resp = await campaingService.detailsCampaignTrello(props.location.state.state.id);

         setData1(resp);
      }else{

      }
      setOpenView(false)

   }


   const updateListTitle = (title, listId) => {
   //    console.log(title, listId)
   //   const list = data1.list[listId];
   //   list.title = title;
   //   setData1({
   //     ...data1,
   //     lists: {
   //       ...data1.lists,
   //       [listId]: list,
   //     },
   //   });
   };

   const addCard = (title, listId) => {
     //crear un id único para el  nuevo card
     const newCardId = Date.now();
     //crear el nuevo card
     const newCard = {
       id: newCardId,
       title: title,
     };
     //añadir el nuevo card al array que tiene la lista
     const list = data1.lists[listId];
     list.cards = [...list.cards, newCard];
     setData1({
       ...data1,
       lists: {
         ...data1.lists,
         [listId]: list,
       },
     });
   };
   const addList = (title) => {
     //generar un id unico nuevo para la lista nueva
     const newListId = Date.now();
     setData1({
       listids: [...data1.listids, newListId],
       lists: {
         ...data1.lists,
         [newListId]: {
           id: newListId,
           title: title,
           cards: [],
         },
       },
     });
   };

   const onDragEnd = async(result) => {

     const {
       destination,
       destination: { droppableId: destdroppableId, index: destIndex },
       source,
       source: { droppableId: sourcedroppableId, index: sourceIndex },
       draggableId,
       type,
     } = result;

     console.table([{
        sourcedroppableId,
        destdroppableId,
        draggableId
     }])

     console.table([
      {
         type,
         sourceIndex,
         destIndex
      }
     ])


     if (!destination) {
       return;
     }

     if (type === "list") {
       const newListIds = data1.listids;
       newListIds.splice(sourceIndex, 1);
       newListIds.splice(destIndex, 0, draggableId);
       return;
     } else {
       const sourceList = data1.lists[sourcedroppableId];
       const destinationList = data1.lists[destdroppableId];

       const draggingCard = sourceList.cards.filter(
         (card) => card.iddetallecampaña == draggableId
       );

       if (sourcedroppableId == destdroppableId) {
         const result = Array.from(sourceList.cards);
         const [removed] = result.splice(sourceIndex, 1);

         result.splice(destIndex, 0, removed);

         //utilizaremos splice para intercambiar los indices

         sourceList.cards.splice(sourceIndex, 1);
         destinationList.cards.splice(destIndex, 0, draggingCard);

         //ACTUALIZAREMOS SETDATA1 CON LOS NUEVOS INDICES
        
         setData1({
           ...data1,
           lists: {
             ...data1.lists,
             [sourceList.id]: {
               id: sourceList.id,
               title: sourceList.title,
               cards: result,
             },
           },
         });
       } else {
         sourceList.cards.splice(sourceIndex, 1);


         destinationList.cards.splice(destIndex, 0, draggingCard[0]);

        if(destinationList.idpipelines==5)
        {
          setOpen(true)
          setIdDetailsCampaing(draggingCard[0].iddetallecampaña)
        }else
        {
          const dataPipeline = {
            pipeline:destinationList.idpipelines
          }

          const resp = await campaingService.updatePipelinesDetails(draggingCard[0].iddetallecampaña,dataPipeline)
        }
       

       }
     }
   };

   return(

      <Protected >
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Grid container flexDirection='row' justifyContent='space-between'>
               {/* <Button startIcon={<ArrowBackIcon/>} component={Link} to={ROUTE_CAMPAIGN}></Button> */}

                <Grid item md={8} container flexDirection='row'>
                <IconButton onClick={()=>{history.push(ROUTE_CAMPAIGN)} } sx={{mr:2}}>
                            <ArrowBackIcon fill="black"/>
                        </IconButton>
                    <Typography variant='h4' sx={{color:'#155172', fontWeight:'700', fontSize:'2.0em'}}>
                       {nombreCampaing}
                       </Typography>
                </Grid>
                <Grid item md={4} container spacing={2} justifyContent='right' alignItems='end'>
                  {/* <Button variant='outlined'  onClick={()=>{nextPage(ROUTE_TRELLO,props.location.state )} }>Vista Trello</Button> */}
                </Grid>
            </Grid>
               <ContextApi.Provider value={{ updateListTitle, addCard, addList }}>
      <div className={classes.root}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="12345" type="list" direction="horizontal">
            {(provided) => (
              <div
                className={classes.container}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {data1.listids.map((listid, index) => {
                  const list = data1.lists[listid];

                  return (
                    <TrelloList
                      list={list}
                      key={listid}
                      index={index}
                      pipelines={Pipelines}
                    ></TrelloList>
                  );
                })}
                {/* <div>
                  <AddCardorList type="list" listId={null}/>
                  {provided.placeholder}
                </div> */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </ContextApi.Provider>
    <ModalAddPriceAndCount
    open={open}
    setOpen={setOpen}
    idDetailCampaing={idDetailsCampaing}
    destination={0}
    />
    </Protected>
)
   }
