import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { Protected } from '@components/layout/Protected';
import { Props } from './Calendar.type';
import { Alert, Backdrop, Button, CircularProgress, Container, Grid, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { ModalAddTask } from "@/components/common/Modal/ModalAddTask";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Fab from '@mui/material/Fab';
import { LeaveIcon } from "@toolbox/constants/icons";
import { ModalNewCampaign, ModalDetailsEvent } from "@/components/common/Modal";
import { ruleService } from "@/service/services/Rule.service";
import { taskService } from "@/service/services/Task.service";
import { campaingService } from "@/service/services/Campaing.service";
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import { ROUTE_TRELLO } from "@/toolbox/constants/route-map";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "@/components/common/DialogConfirm";
import { contactService } from "@/service/services/Contact.service";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import { SpinnerGrow, SpinnerLoad } from "@/components/common/Spinner";
import GroupIcon from '@mui/icons-material/Group';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import moment from "moment";
import { entityService } from "@/service/services/Entity.service";
import AddIcon from '@mui/icons-material/Add';
import { supervisorService } from "@/service/services/Supervisor.service";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const CalendarView: React.FC<Props> = ({ }): JSX.Element => {
   const [tasks, setTasks] = useState<any>([]);
   const [taskEdit, setTaskEdit] = useState(null);
   const [open, setOpen] = useState(false);
   const [openNewCampaing, setOpenNewCampaing] = useState(false);
   const [openDetailsEvent, setOpenDetailsEvent] = useState(false);
   const [sentDateData, setSentDateData] = useState(null);
   const [dataRule, setDataRule] = useState<any>([])
   const [openView, setOpenView] = useState(false);
   const [openView2, setOpenView2] = useState(false);
   const [valueUserSelect, setValueUserSelect] = useState<any>();
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   // const idempresacliente = readLocalStorage(KEY_USER_DATA);
   const [Users, setUsers] = useState<any>([]);
   const [valueButton, setValueButton] = React.useState<any>('11');
   const [filterTipeFeeds, setFilterTipeFeeds] = useState<any>([]);
   const [activeCheck, setActiveCheck] = useState<any>({
      call:false,
      mail:false,
      meeting:false,
      other:false,
   });
   const [select, setSelect] = useState('0');
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      actionId: null,
      message: `¿Desea eliminar la accion ---?`
   })
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'warning',
      message: 'Error',
      autoHideDuration: 1000,
   })

   // const [visualiteButton, setVisualiteButton] = useState<any>([{status:true, id:0}]);

   useEffect(() => {
      try {
         getFeeds();
         getRule();
         getDataUser(dataUser.user.idempresacliente);
      } catch (e) {
         console.log(e)
      }

   }, [])

   const handleChange = (event: SelectChangeEvent) => {
      setSelect(event.target.value);
   };

   const handleChangeButton = async (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(event.target.value)
      setValueButton(event.target.value);
      if(valueUserSelect)
      {
         const resp = await taskService.getFeedsByUsers(valueUserSelect,JSON.stringify(filterTipeFeeds),event.target.value)
         setTasks(resp ? resp.data : []);
      }else
      {
         const resp = await taskService.filterFeeds(JSON.stringify(filterTipeFeeds),event.target.value)
         setTasks(resp ? resp.data : []);
      }
   };

   async function getFeeds() {
      try {
         setOpenView(true);
         // visualiteButton[0]={status:true,id:0};
         setValueUserSelect('')
         const resp = await taskService.filterFeeds(JSON.stringify(filterTipeFeeds),valueButton)
         setTasks(resp ? resp.data : []);
         setOpenView(false);
      } catch (e) {
         console.log(e)
      }
   }

   async function getFeedsByUsers(idusuario) {
      try {
         setOpenView(true);
         // if (dataUser.user.role == ROLE_SUPERVISOR) {
         //    const resp = await supervisorService.seguimientoVendedor(idusuario)
         //    select == '0' ? setTasks(resp ? resp.data.Tareas : []) : setTasks(resp ? resp.data.Ventas : [])
         // } else {
            // visualiteButton&& visualiteButton.map((value)=>{
            //    if(value.id==idusuario)
            //    {
            //       value.status=true
            //    }
            // })
            setValueUserSelect(idusuario)
            console.log(filterTipeFeeds);
            const resp = await taskService.getFeedsByUsers(idusuario,JSON.stringify(filterTipeFeeds),valueButton)
            setTasks(resp ? resp.data : []);
         // }
         setOpenView(false);
      } catch (e) {
         console.log(e)
      }
   }
   async function getDataUser(idempresacliente) {
      try {
         //    if (dataUser.user.role == ROLE_SUPERVISOR) {
         //       const resp = await supervisorService.getVendedor();
         //       setUsers(resp.data)
         //    } else {
         const resp = await entityService.getEntityUsers(idempresacliente);
         const dataUsers = resp.data.filter(user => user.idtipousuario == 4)
         setUsers(dataUsers)
         // dataUsers && dataUsers.map((value, i)=>{
         // setVisualiteButton(dataUsers)
         // visualiteButton.push({
         //    status:false,
         //    id: value.idusuario ? value.idusuario : value.idvendedor
         // })
         // })
         // }
      } catch (error) {
         console.log(error)
      }

   }


   const PosicionEvent = async (id, tipotarea) => {

      try {
         const pos = parseInt(id);
         setOpenView2(true);
         const resp = await taskService.getFeedsById(pos);
         const data = resp.data[0];
         setOpenView2(false);
         setTaskEdit(data);
         setOpenDetailsEvent(true)
      } catch (e) {
         console.log(e)
      }

   };

   const taskAdd = async (task) => {

      try {
         setOpenView2(true);
         if (task.idfrecuencia) {

            let FechaFinalCampaign;


            if (task.campana) {
               FechaFinalCampaign = moment(task.campana.fecha_cierre).format('YYYY-MM-DD')

            } else {
               FechaFinalCampaign = moment('2022-04-04').format('YYYY-MM-DD')

            }

            let FechaInicialAccion = moment(task.fechacreacion).format('YYYY-MM-DD')
            let FechaFinalAction = moment(task.fechacierre).format('YYYY-MM-DD')


            let resp;

            while (FechaFinalAction < FechaFinalCampaign) {

               resp = await taskService.createFeeds(task);
               // if (resp.data) {
               //    const changedAction = [resp.data, ...actions];
               //    setActions(changedAction);

               // }
               if (task.idfrecuencia == 1) {
                  FechaFinalAction = moment(FechaFinalAction).add(1, 'w').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(1, 'w').format('YYYY-MM-DD')
               }
               if (task.idfrecuencia == 2) {
                  FechaFinalAction = moment(FechaFinalAction).add(4, 'd').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(4, 'd').format('YYYY-MM-DD')

               }
               if (task.idfrecuencia == 3) {
                  FechaFinalAction = moment(FechaFinalAction).add(1, 'M').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(1, 'M').format('YYYY-MM-DD')

               }
               if (task.idfrecuencia == 4) {
                  FechaFinalAction = moment(FechaFinalAction).add(15, 'd').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(15, 'd').format('YYYY-MM-DD')

               }

               if (task.horainicio === "00:00" && task.horafin === '00:00') {
                  task.fechacreacion = FechaInicialAccion;
                  task.fechacierre = FechaFinalAction;
               } else {
                  task.fechacreacion = `${FechaInicialAccion} ${task.horainicio}`;
                  task.fechacierre = `${FechaFinalAction} ${task.horafin}`;
               }


            }
            setOpen(false);
            setOpenView2(false);
            getFeeds()

         } else {
            const resp = await taskService.createFeeds(task);
            if (resp.data) {
               getFeeds();
               setOpen(false);
               setOpenView2(false);

            }
         }


      } catch (e) {
         console.log(e)
      }

   };

   const taskUpdate = async (taskEdit) => {

      try {
         setOpenView2(true);
         const resp: any = await taskService.EditFeeds(taskEdit);
         if (resp.data) {
            getFeeds();
            setOpen(false);
            setOpenView2(false);
         }
      } catch (e) {
         console.log(e)
      }

   };


   const taskDelete = (data) => {
      console.log(data)
      try {
         const taskId = data.id;
         setDialog(prev => ({
            ...prev,
            message: `¿Desea eliminar la Tarea ${data.tipo}-${data.nombre_cliente ? data.nombre_cliente : ''}?`,
            actionId: taskId, open: true, confirm: true
         }));
      } catch (e) {
         console.log(e)
      }
   }

   const deleteAccion = async () => {
      if (Dialog.confirm == true) {
         const resp = await taskService.deleteFeeds(Dialog.actionId);
         if (resp.data) {
            getFeeds();
         }
      }
   }

   const handleDateSelect = (selectInfo) => {

      var now = moment().format('YYYY-MM-DD')
      let FechaInicial = moment(selectInfo.start).format('YYYY-MM-DD')

      if (now <= FechaInicial) {
         setOpen(true);

         const datadate = {
            // fechacreacion: new Date(selectInfo.startStr).toISOString().slice(0, 10),
            // horacreacion: selectInfo.start.toLocaleTimeString("it-IT"),
            fechacreacion: dateFormat(selectInfo.start),
            horainicio: dateFormatTime(selectInfo.start),
            // fechacierre: new Date(selectInfo.endStr).toISOString().slice(0, 10),
            // horacierre: selectInfo.end.toLocaleTimeString("it-IT"),
            fechacierre: dateFormat(selectInfo.end),
            horafin: dateFormatTime(selectInfo.end),
         };
         setSentDateData(datadate);
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "solo se puede crear tareas actuales",
         }));

      }

   };

   const handleEventClick = (clickInfo) => {
      PosicionEvent(clickInfo.event.id, clickInfo.event.title);
   };

   async function getRule() {
      const resp = await ruleService.getRule()
      const data = resp?.data;
      setDataRule(data ? data : []);
   }

   const FeedsByUser = async () => {
      setOpenView(true)
      //const resp = await taskService.getAccion();
      //setTasks(resp.data);
      setOpenView(false)
   }

   const onChangeSelectTypeFeeds = async (e,type) => {

      // setOpenView(true);
  
     if(type==1)
     {
      setActiveCheck(prev => ({ ...prev, call: e.target.checked }))
       if(!e.target.checked)
       {
         var myIndex = filterTipeFeeds.indexOf(1);
         if (myIndex !== -1) {
          filterTipeFeeds.splice(myIndex, 1);
         }
       }else
       {
         filterTipeFeeds.push(1);
       }
     }
     if(type==2)
     {
      setActiveCheck(prev => ({ ...prev, mail: e.target.checked }))
      if(!e.target.checked)
      {
        var myIndex = filterTipeFeeds.indexOf(2);
        if (myIndex !== -1) {
         filterTipeFeeds.splice(myIndex, 1);
        }
      }else
      {
        filterTipeFeeds.push(2);
      }
     }
     if(type==3)
     {
      setActiveCheck(prev => ({ ...prev, meeting: e.target.checked }))
      if(!e.target.checked)
      {
        var myIndex = filterTipeFeeds.indexOf(3);
        if (myIndex !== -1) {
         filterTipeFeeds.splice(myIndex, 1);
        }
      }else
      {
        filterTipeFeeds.push(3);
      }
     }
     if(type==4)
     {
      setActiveCheck(prev => ({ ...prev, other: e.target.checked }))
      if(!e.target.checked)
      {
        var myIndex = filterTipeFeeds.indexOf(4);
        if (myIndex !== -1) {
         filterTipeFeeds.splice(myIndex, 1);
        }
      }else
      {
        filterTipeFeeds.push(4);
      }
     }


     
   console.log(filterTipeFeeds);
     
    const resp = await taskService.filterFeeds(JSON.stringify(filterTipeFeeds),valueButton)
    setTasks(resp ? resp.data : []);
   setOpenView(false);
   }

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const Usuarios = Users?.map((value, i) => {
      return (
         <Grid key={i}>
            <Button
            fullWidth
               variant='outlined'
               startIcon={<PersonOutlineIcon sx={{ color: '#007EA7' }} />}
               // variant='text'
               sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em', mt: 1 }}
               onClick={() => {
                  getFeedsByUsers(value.idusuario ? value.idusuario : value.idvendedor)
               }}

            >
               {value.nombre ? value.nombre : value.nombre_vendedor}
            </Button>
         </Grid>


      )

   })

   return (
      <Protected>
         <ConfirmDialog
            open={Dialog.open}
            title={Dialog.title}
            message={Dialog.message}
            onConfirm={() => deleteAccion()}
            onClose={() => setDialog(prev => ({ ...prev, open: false }))}
         />
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView2}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerLoad />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth="xl" sx={{ mb: 2 }}>
            <Grid container >
               <Grid item container direction="column" xs={12} lg={4}>
                  <Grid item >
                     {dataUser.user.role == ROLE_ADMIN && <Button variant="contained"
                        color="primary"
                        size='large'
                        startIcon={<AddIcon />}
                        sx={{
                           background: '#E4980D', color: '#fff', '&:hover': {
                              bgcolor: '#E4980D'
                           }
                        }}
                        onClick={() => { setOpenNewCampaing(true) }}>
                        CREAR CAMPAÑA
                     </Button>}
                  </Grid>
                  {/* {dataUser.user.role == ROLE_SUPERVISOR ?
                  <Grid item mt={6} sx={{ width: '220px' }}>
                     <FormControl fullWidth>
                        <InputLabel>Ver por</InputLabel>
                        <Select
                           value={select}
                           label="Seleccione"
                           onChange={handleChange}
                        >
                           <MenuItem value={0}>Tareas</MenuItem>
                           <MenuItem value={1}>Ventas</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  : ''
                  } */}

                  {dataUser.user.role == ROLE_VENDEDOR ? '' : 
                  <Grid item container
                  direction="column"
                  justifyContent="center" 
                  mt={6} sx={{ width: '220px' }}>
                     <Button
                       fullWidth
                       variant='outlined'
                        startIcon={<GroupIcon sx={{ color: '#007EA7' }} />}
                        // variant='text'
                        sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em' }}
                        onClick={() => {
                           getFeeds();
                        }}
                     >
                        {<span >Todos</span>}
                     </Button>
                     {Usuarios}
                  </Grid>}
                  <Grid item container sx={{ p: '20px 20px 20px 0px' }}>
                     <FormControl>
                        <RadioGroup
                           row
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="controlled-radio-buttons-group"
                           value={valueButton}
                           onChange={handleChangeButton}
                        >
                           <FormControlLabel value={'11'} control={<Radio />} label="Todos" />
                           <FormControlLabel value={'0'} control={<Radio />} label="Vigente" />
                           <FormControlLabel value={'1'} control={<Radio />} label="Completado" />
                        </RadioGroup>
                     </FormControl>
                     <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                           <TableHead>
                              <TableRow>
                                 <TableCell></TableCell>
                                 <TableCell>Filtro</TableCell>
                                 <TableCell>Vigente</TableCell>
                                 {/* <TableCell>Caducado</TableCell> */}
                                 <TableCell>Completado</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              <TableRow
                              >
                                 <TableCell>Llamada</TableCell>
                                 <TableCell >
                                 <Checkbox checked={activeCheck.call} onChange={(e)=>{onChangeSelectTypeFeeds(e,1)}}/>
                                 </TableCell>
                                 <TableCell align="center">
                                    <div style={{borderRadius:'200%', background:'#E4980D', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                                 <TableCell align="center">
                                  <div style={{borderRadius:'200%', background:'#864000', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                              </TableRow>
                              <TableRow
                              >
                                 <TableCell>Correo</TableCell>
                                 <TableCell >
                                 <Checkbox checked={activeCheck.mail} onChange={(e)=>{onChangeSelectTypeFeeds(e,2)}}/>
                                 </TableCell>
                                 <TableCell align="center">
                                     <div style={{borderRadius:'200%', background:'#007EA7', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                                 <TableCell align="center">
                                  <div style={{borderRadius:'200%', background:'#003e90', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                              </TableRow>
                              <TableRow
                              >
                                 <TableCell>Reunión</TableCell>
                                 <TableCell >
                                 <Checkbox checked={activeCheck.meeting} onChange={(e)=>{onChangeSelectTypeFeeds(e,3)}}/>
                                 </TableCell>
                                 <TableCell align="center">
                                     <div style={{borderRadius:'200%', background:'#511F74', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                                 <TableCell align="center">
                                     <div style={{borderRadius:'200%', background:'#9e6788', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                              </TableRow>
                              <TableRow
                              >
                                 <TableCell>Otro</TableCell>
                                 <TableCell >
                                 <Checkbox checked={activeCheck.other} onChange={(e)=>{onChangeSelectTypeFeeds(e,4)}}/>
                                 </TableCell>
                                 <TableCell component="th" scope="row">
                                     <div style={{borderRadius:'200%', background:'#9DC9B1', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                                 <TableCell align="center">
                                     <div style={{borderRadius:'200%', background:'#00c6ab', width:'0px',padding:'10px'}}></div>
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </Grid>
               </Grid>

               <Grid item xs={12} lg={dataUser.user.role == ROLE_ADMIN || dataUser.user.role == ROLE_SUPERVISOR ? 8 : 8} >
                  <FullCalendar
                     height={570}
                     initialView="listMonth"
                     plugins={[
                        timeGridPlugin,
                        dayGridPlugin,
                        listPlugin,
                        interactionPlugin,
                        bootstrapPlugin,
                     ]}
                     headerToolbar={{
                        left: "title",
                        right:
                           `prev,next,today,dayGridMonth,timeGridWeek,timeGridDay,listMonth`

                     }}
                     buttonText={{
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Dia",
                        list: "Lista",
                     }}
                     locale={"es-us"}
                     themeSystem="Cerulean"
                     allDayText="Todo el día"
                     events={tasks.map((task, i) => {
                        return {
                           id: task.id,
                           title: `${task.tipo} - ${task.nombre_cliente ? task.nombre_cliente : task.form_cliente} `,
                           start: dateFormatTime(task.fechacreacion) == "00:00" ? dateFormat(task.fechacreacion) : task.fechacreacion,
                           end: dateFormatTime(task.fechacierre) == "00:00" ? dateFormat(task.fechacierre) : task.fechacierre,
                           //start: task.fechacreacion,
                           //end: task.fechacierre,
                           color: task.completed == '0' ? task.color : task.color_completado,
                           icon: <GroupIcon />,
                        };
                     })}
                     //editable={true}
                     droppable={true}
                     selectable={true}
                     select={handleDateSelect}
                     eventClick={handleEventClick}
                     // eventLimit={true}
                     navLinks={true}
                     handleWindowResize={true}
                  />
               </Grid>
            </Grid>
         </Container>
         <ModalAddTask
            taskAdd={taskAdd}
            open={open}
            setOpen={setOpen}
            //taskEdit={taskEdit}
            //setTaskEdit={setTaskEdit}
            //taskUpdate={taskUpdate}
            sentDateData={sentDateData}
            setSentDateData={setSentDateData}
         />
         <ModalNewCampaign
            open={openNewCampaing}
            setOpen={setOpenNewCampaing}
            dataRule={dataRule}
         />
         <ModalDetailsEvent
            openDetailsEvent={openDetailsEvent}
            setOpenDetailsEvent={setOpenDetailsEvent}
            taskEdit={taskEdit}
            setTaskEdit={setTaskEdit}
            taskUpdate={taskUpdate}
            taskDelete={taskDelete} />
      </Protected>
   );
}
