import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './TemplateWsCreate.type';
import { Alert, Box, Button, Card, CardContent, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TemplateWsService } from '@/service/services/TemplateWs.service';


const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#155172' : '#155172',
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
   color: '#fff',
}));


type SnackBar = {
   open: boolean,
   severity: any,
   message: string,
   autoHideDuration: number,
}

type VariableDataHeader = {
   [key: string]: string;
};
type VariableDataBody = {
   [key: string]: string;
};

const Mensaje = ({ children, esMio }) => {
   const estiloMensaje: CSSProperties = {
      maxWidth: '70%',
      margin: '8px',
      padding: '10px',
      borderRadius: '8px',
      clear: 'both',
      wordWrap: 'break-word',
      position: 'relative',
      backgroundColor: esMio ? '#ffffff' : '#ffffff',
      border: esMio ? 'none' : '1px solid #e0e0e0',
   };

   const estiloPunta: CSSProperties  = {
      position: 'absolute',
      content: '""',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '8px',
      borderColor: esMio
        ? 'transparent transparent #ffffff transparent'
        : '#ffffff transparent transparent transparent',
      top: '-16px',
      left: '8px',
    };

   return (
     <div style={estiloMensaje}>
       <div style={estiloPunta}></div>
       {children}
     </div>
   );
 };

export const TemplateWsCreateView: React.FC<Props> = (props: Props): JSX.Element => {

   const dataUser = readLocalStorage(KEY_USER_DATA);
   const [snackBarConfig, setSnackBarConfig] = useState<SnackBar>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })
   const [name, setName] = useState<string>('');
   const [selectHeader, setSelectHeader] = useState<string>('2');
   const [textHeader, setTextHeader] = useState<string>('');
   const [textBody, setTextBody] = useState<string>('');
   const [textFooter, setTextFooter] = useState<string>('');
   const maxCharacterCountHeader = 60;
   const maxCharacterCountBody = 1024;
   const [variablesHeader, setVariablesHeader] = useState([]);
   const [inputValuesHeader, setInputValuesHeader] = useState<VariableDataHeader>({});

   const [variablesBody, setVariablesBody] = useState([]);
   const [inputValuesBody, setInputValuesBody] = useState<VariableDataBody>({});

   const replaceVariablesHeader = (text: string, variableValues: VariableDataHeader): string => {
      let replacedText = text;
      Object.entries(variableValues).forEach(([variable, value]) => {
         const variablePlaceholder = `${variable}`;
         replacedText = replacedText.split(variablePlaceholder).join(value);
      });
      return replacedText;
   };

   const handleChange = (event: SelectChangeEvent) => {
      console.log('event.target.value', typeof event.target.value)
      setSelectHeader(event.target.value);
   };

   const handleChangeHeader = (event) => {
      const newText = event.target.value;
      if (newText.length <= maxCharacterCountHeader) {
         const updatedVariablesHeader = [...variablesHeader];
         variablesHeader.forEach((variableHeader) => {
            newText.replace(variableHeader, '');
            if (!newText.includes(variableHeader)) {
               const index = updatedVariablesHeader.indexOf(variableHeader);
               if (index !== -1) {
                  updatedVariablesHeader.splice(index, 1);
               }
            }
         });
         setVariablesHeader(updatedVariablesHeader);
         setTextHeader(newText);
      }
   };
   const handleAddVariableHeader = () => {
      const newVariableHeader = `{{${variablesHeader.length + 1}}}`;
      setVariablesHeader([...variablesHeader, newVariableHeader]);
      setInputValuesHeader({ ...inputValuesHeader, [newVariableHeader]: '' });
      setTextHeader((prevText) => prevText + newVariableHeader);
   };

   const handleVariableHeaderInputChange = (variableHeader, value) => {
      setInputValuesHeader({ ...inputValuesHeader, [variableHeader]: value });
   };

   //BODY
   const replaceVariablesBody = (text: string, variableValues: VariableDataBody): React.ReactNode => {
      let replacedText = text;
      Object.entries(variableValues).forEach(([variable, value]) => {
         const variablePlaceholder = `${variable}`;
         replacedText = replacedText.split(variablePlaceholder).join(value);
      });
      const lines = replacedText.split('\n');
      return lines.map((line, index) => (
         <React.Fragment key={index}>
            {index > 0 && <br />}
            {line}
         </React.Fragment>
      ));
   };

   const handleChangeBody = (event) => {
      const newText = event.target.value;
      const cursorPosition = event.target.selectionStart;
      if (event.key === 'Enter') {
         const updatedText = `${newText.substring(0, cursorPosition)}\n${newText.substring(cursorPosition)}`;
         setTextBody(updatedText);
         event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      } else {
         if (newText.length <= maxCharacterCountBody) {
            const updatedVariablesBody = [...variablesBody];
            variablesBody.forEach((variableBody) => {
               newText.replace(variableBody, '');
               if (!newText.includes(variableBody)) {
                  const index = updatedVariablesBody.indexOf(variableBody);
                  if (index !== -1) {
                     updatedVariablesBody.splice(index, 1);
                  }
               }
            });
            setVariablesBody(updatedVariablesBody);
            setTextBody(newText);
         }
      }
   };
   const handleAddVariableBody = () => {
      const newVariableBody = `{{${variablesBody.length + 1}}}`;
      setVariablesBody([...variablesBody, newVariableBody]);
      setInputValuesBody({ ...inputValuesBody, [newVariableBody]: '' });
      setTextBody((prevText) => prevText + newVariableBody);
   };

   const handleVariableBodyInputChange = (variableBody, value) => {
      console.log('variableBody', variableBody);
      setInputValuesBody({ ...inputValuesBody, [variableBody]: value });
   };

   //FOOTER

   const handleChangeFooter = (event) => {
      const newText = event.target.value;
      if (newText.length <= maxCharacterCountHeader) {
         setTextFooter(newText);
      }
   };


   useEffect(() => {
      const matches = textHeader.match(/\{\{(\d+)\}\}/g); // Utilizamos la 'g' para hacer la búsqueda global
      if (matches) {
         const uniqueVariables = matches.filter(
            (variable, index, self) => self.indexOf(variable) === index
         );
         const sortedVariables = uniqueVariables.sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)[0]);
            const numB = parseInt(b.match(/\d+/)[0]);
            return numA - numB;
         });

         let updatedVariables = [];
         let nextVariableNumber = 1;
         let updatedText = textHeader;

         for (const variable of sortedVariables) {
            const currentVariableNumber = parseInt(variable.match(/\d+/)[0]);
            if (currentVariableNumber !== nextVariableNumber) {
               const updatedVariable = `{{${nextVariableNumber}}}`;
               updatedVariables.push(updatedVariable);
               updatedText = updatedText.replace(variable, updatedVariable);
               setVariablesHeader((prevVariablesHeader) => [
                  ...prevVariablesHeader.filter(
                     (prevVariable) => !uniqueVariables.includes(prevVariable)
                  ),
                  ...updatedVariables,
               ]);
               setTextHeader(updatedText);
               return;
            }

            updatedVariables.push(variable);
            updatedText = updatedText.replace(variable, `{{${nextVariableNumber}}}`);
            nextVariableNumber++;
         }

         setVariablesHeader((prevVariablesHeader) => [
            ...prevVariablesHeader.filter(
               (variable) => !uniqueVariables.includes(variable)
            ),
            ...updatedVariables,
         ]);

         setTextHeader(updatedText);
      }
   }, [textHeader]);

   useEffect(() => {
      const matches = textBody.match(/\{\{(\d+)\}\}/g); // Utilizamos la 'g' para hacer la búsqueda global
      if (matches) {
         const uniqueVariables = matches.filter(
            (variable, index, self) => self.indexOf(variable) === index
         );
         const sortedVariables = uniqueVariables.sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)[0]);
            const numB = parseInt(b.match(/\d+/)[0]);
            return numA - numB;
         });
         let updatedVariables = [];
         let nextVariableNumber = 1;
         let updatedText = textBody;
         for (const variable of sortedVariables) {
            const currentVariableNumber = parseInt(variable.match(/\d+/)[0]);
            if (currentVariableNumber !== nextVariableNumber) {
               const updatedVariable = `{{${nextVariableNumber}}}`;
               updatedVariables.push(updatedVariable);
               updatedText = updatedText.replace(variable, updatedVariable);
               setVariablesBody((prevVariablesBody) => [
                  ...prevVariablesBody.filter(
                     (prevVariable) => !uniqueVariables.includes(prevVariable)
                  ),
                  ...updatedVariables,
               ]);
               setTextBody(updatedText);
               return;
            }
            updatedVariables.push(variable);
            updatedText = updatedText.replace(variable, `{{${nextVariableNumber}}}`);
            nextVariableNumber++;
         }
         // Actualizar el estado solo con las nuevas variables únicas
         setVariablesBody((prevVariablesBody) => [
            ...prevVariablesBody.filter(
               (variable) => !uniqueVariables.includes(variable)
            ),
            ...updatedVariables,
         ]);

         setTextBody(updatedText);
      }
   }, [textBody]);

   const saveTemplate = async () => {
      if (!name) {
        return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'agregar un nombre a la plantilla',
         }));
      }
      if (selectHeader === '2' && !textHeader) {
         return setSnackBarConfig(prev => ({
             ...prev,
             open: true,
             severity: 'error',
             message: 'agregar texto al encabezado ',
          }));
       }
      if (!textBody) {
         return setSnackBarConfig(prev => ({
             ...prev,
             open: true,
             severity: 'error',
             message: 'agregar texto al cuerpo',
          }));
       }
      const data = {
         selectHeader: selectHeader,
         name: name,
         variablesHeader: JSON.stringify(variablesHeader),
         variablesBody: JSON.stringify(variablesBody),
         inputValuesHeader: JSON.stringify(inputValuesHeader),
         inputValuesBody: JSON.stringify(inputValuesBody),
         textHeader: textHeader,
         textBody: textBody,
         textFooter: textFooter,
      }
      const resp: any = await TemplateWsService.createTemplate({ ...data });
      if (resp?.code === 200) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: resp?.data?.message,
         }));


      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const handleChangeTitle = (e) => {
      const inputValue = e.target.value;
      const filteredValue = inputValue.replace(/[^a-zA-Z0-9_]|ñ|Ñ/g, '');
      const lowercaseValue = filteredValue.toLowerCase();
      setName(lowercaseValue);
    };

    const handleKeyDown = (e) => {
      if (e.key === ' ' || e.key === 'Spacebar') {
        e.preventDefault();
        setName((prevName) => prevName + '_');
      }
    };

    const handleLink = () =>{
      const newTabUrl = `/template-ws`;
      window.open(newTabUrl, '_self');
    }

   return (
      <Protected>
         <Grid item xs={12} >
            <Card>
               <CardContent>
                  <Grid item container alignItems={'center'} justifyContent={'right'} xs={12} >
                     <Button onClick={() => { handleLink() }} sx={{ m:2 }} variant='outlined' color='error'>volver</Button>
                     <Button onClick={() => { saveTemplate() }} sx={{ m:2  }} variant='outlined' color='info'>Enviar</Button>
                  </Grid>
               </CardContent>
            </Card>
         </Grid>
         <Grid container  >
            <Grid item xs={12} md={8} sx={{bgcolor: '#155172', color:'#fff', p:2}}>
               <Grid item container xs={12} justifyContent={'center'}>
                  CREAR PLANTILLA
               </Grid>
            </Grid>

            <Grid item xs={12} md={4} sx={{bgcolor: '#155172', color:'#fff', p:2}}>
               <Grid item container xs={12} justifyContent={'center'}>
                  VISTA PREVIA DEL MENSAJE
               </Grid>
            </Grid>
         </Grid>

         <Card>
            <CardContent>
            <Grid container justifyContent={'space-between'} sx={{mt:3}} >
            <Grid item container xs={12} md={7} >
               <Grid item xs={12}>
                  <Typography>Asigna un nombre a la plantilla de mensaje.</Typography>
               </Grid>
               <Grid item xs={12} sx={{mb:2}}>
                  <FormControl fullWidth>
                     <TextField
                        size="small"
                        required
                        sx={{ bgcolor: '#fff' }}
                        id="name"
                        name="name"
                        placeholder="Asigna un nombre a la plantilla de mensaje."
                        fullWidth
                        value={name}
                        onChange={handleChangeTitle}
                        onKeyDown={handleKeyDown}
                     />
                  </FormControl>
               </Grid>
               <Grid item xs={12}>
                  <Typography>1. Encabezado (opcional)</Typography>
                  <Typography>Agrega un título o elige el tipo de contenido multimedia que usarás para este encabezado.</Typography>
               </Grid>
               <Grid item container spacing={2} xs={12}>
                  <Grid item xs={3}>
                     <FormControl fullWidth>
                        <Select
                           id="select-header"
                           value={selectHeader}
                           onChange={handleChange}
                           size='small'
                        >
                           <MenuItem value={'1'}>Ninguna</MenuItem>
                           <MenuItem value={'2'}>Mensaje de Texto</MenuItem>
                           {/* <MenuItem value={3}>Medios</MenuItem> */}
                        </Select>
                     </FormControl>
                  </Grid>
                  {selectHeader === '2' && <Grid item xs={9}>
                     <FormControl fullWidth>
                        <TextField
                           size="small"
                           sx={{ bgcolor: '#fff' }}
                           id="textHeader"
                           name="textHeader"
                           placeholder="Escribir texto en Spanish(SPA)"
                           fullWidth
                           value={textHeader}
                           onChange={handleChangeHeader}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end">
                                    {textHeader.length}/{maxCharacterCountHeader}
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </FormControl>
                  </Grid>}
               </Grid>
               <Grid item container justifyContent={'flex-end'} xs={12}>
                  <Button disabled={variablesHeader?.length >= 1 && true} onClick={handleAddVariableHeader}>✚ Agregar Variable</Button>
               </Grid>
               {variablesHeader?.length>0 && <Grid item xs={12}>
                  <Typography>Ejemplos de contenido del encabezado</Typography>
                  <Typography>Para ayudarnos a revisar tu contenido, proporciona ejemplos de las variables o del contenido multimedia en el encabezado. No incluyas información del cliente. La API de la nube alojada por Meta revisa las plantillas y los parámetros de las variables para proteger la seguridad e integridad de nuestros servicios.</Typography>
               </Grid>}
               <Grid item xs={12} sx={{mb:2}}>
                  {variablesHeader.map((variableHeader) => (
                     <Grid key={variableHeader} item container alignItems={'center'} xs={12}>
                        <Grid item xs={1}>
                           <Typography>{variableHeader}</Typography>
                        </Grid>
                        <Grid item xs={11}>
                           <FormControl fullWidth>
                              <TextField
                                 size="small"
                                 sx={{ bgcolor: '#fff' }}
                                 id={variableHeader}
                                 name={variableHeader}
                                 placeholder={`Escribe para ${variableHeader}`}
                                 fullWidth
                                 value={inputValuesHeader[variableHeader] || ''}
                                 onChange={(e) => handleVariableHeaderInputChange(variableHeader, e.target.value)}
                              />
                           </FormControl>
                        </Grid>
                     </Grid>
                  ))}
               </Grid>
               <Grid item xs={12}>
                  <Typography>2. Cuerpo</Typography>
                  <Typography>Ingresa el texto del mensaje en el idioma español.</Typography>
               </Grid>
               <Grid item xs={12}>
                  <FormControl fullWidth>
                     <TextField
                        multiline
                        size="small"
                        sx={{ bgcolor: '#fff' }}
                        id="textBody"
                        name="textBody"
                        placeholder="Escribir texto en Spanish(SPA)"
                        fullWidth
                        rows={6}
                        value={textBody}
                        onChange={handleChangeBody}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 {textBody.length}/{maxCharacterCountBody}
                              </InputAdornment>
                           ),
                        }}
                     />
                  </FormControl>
               </Grid>
               <Grid item container justifyContent={'flex-end'} xs={12}>
                  <Button onClick={handleAddVariableBody}>✚ Agregar Variable</Button>
               </Grid>
               <Grid item xs={12} sx={{mb:2}}>
                  {variablesBody.map((variableBody) => (
                     <Grid key={variableBody} item container alignItems={'center'} xs={12}>
                        <Grid item xs={1}>
                           <Typography>{variableBody}</Typography>
                        </Grid>
                        <Grid item xs={11}>
                           <FormControl fullWidth>
                              <TextField
                                 size="small"
                                 sx={{ bgcolor: '#fff' }}
                                 id={variableBody}
                                 name={variableBody}
                                 placeholder={`Escribe para ${variableBody}`}
                                 fullWidth
                                 value={inputValuesBody[variableBody] || ''}
                                 onChange={(e) => handleVariableBodyInputChange(variableBody, e.target.value)}
                              />
                           </FormControl>
                        </Grid>
                     </Grid>
                  ))}
               </Grid>
               <Grid item xs={12}>
                  <Typography>3. Pie de página (Opcional)</Typography>
                  <Typography>Agrega una breve línea de texto en la parte inferior de la plantilla de mensaje. Si agregas el botón para no recibir mensajes de marketing, el pie del mensaje asociado se mostrará aquí de forma predeterminada.</Typography>
               </Grid>
               <Grid item xs={12}>
                  <FormControl fullWidth>
                     <TextField
                        size="small"
                        sx={{ bgcolor: '#fff' }}
                        id="textFooter"
                        name="textFooter"
                        placeholder="Escribir texto en Spanish(SPA)"
                        fullWidth
                        value={textFooter}
                        onChange={handleChangeFooter}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 {textFooter.length}/{maxCharacterCountHeader}
                              </InputAdornment>
                           ),
                        }}
                     />
                  </FormControl>
               </Grid>
            </Grid>

            <Grid item xs={12} md={4}  sx={{ minHeight: '30vh', padding: '16px', backgroundColor: '#e5ddd5', lineHeight: '25px',}}>
            <Mensaje esMio={true}>
               {selectHeader === '2' && <Grid item xs={12}>
                   {replaceVariablesHeader(textHeader, inputValuesHeader)}
               </Grid>}
               <Grid item xs={12}>
                   {replaceVariablesBody(textBody, inputValuesBody)}
               </Grid>
               <Grid item xs={12}>
                  {textFooter}
               </Grid>
               </Mensaje>
            </Grid>
         </Grid>
            </CardContent>
         </Card>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig?.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>

      </Protected>
   )
};
