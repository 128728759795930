import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, ListItem, Chip, Autocomplete, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { Box, spacing } from '@mui/system';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './Modal.sass'
import { productService } from '@/service/services/Product.service';
import { entityService } from '@/service/services/Entity.service';
import { campaingService } from '@/service/services/Campaing.service';
import { ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";

type ModalProps = {
  open: boolean,
  setOpen: any,
  data?: any,
  campana?: any,
  estado?: any,
  getContact?: any
}


const dataInitial = {
  criterio: [],
  nota: ''
}
export const ModalTomarNota: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element => {

  // const [data, setData] = useState([]);
  const [estado, setEstado] = useState<boolean>(false);
  const [snackBarConfig, setSnackBarConfig] = useState<any>({
    open: false,
    severity: 'success',
    message: 'éxito',
    autoHideDuration: 3000,
  })

  const [dataForm, setDataForm] = useState<any>(dataInitial);
  const { criterio, nota } = dataForm;
  const [chipData, setChipData] = React.useState([]);
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };
  const [iterador, setiterador] = React.useState(0);
  const [valueCriterio, setValueCriterio] = React.useState(`${criterio}`);
  const [idasignado, setIdAsignado] = useState(null);
  const [error, setError] = useState(null);
  const [dataUser, setDataUser] = useState<any>([])
  const [dataUsuario] = useLocalStorage(KEY_USER_DATA, undefined);

  useEffect(() => {
    if (props.open == true) {
      console.log(props.open)
    }
    getDataUser()
  }, [props.open])

  async function getDataUser() {
    const resp = await entityService.getVendedor()
    if (resp) {
      setDataUser(resp.data)
    }
  }

  const setValue = (iterador, valorEntrante) => {
    setiterador(iterador + 1);
    setValueCriterio(valorEntrante);
    setChipData(
      [
        ...chipData,
        { id: iterador, nombre: `${criterio}` }
      ]
    );
    setDataForm(prev => ({ ...prev, criterio: '' }));
  }

  const handleFormValues = (e) => {
    const changedFormValues = {
      ...dataForm,
      [e.target.name]: e.target.value
    }
    setDataForm(changedFormValues)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(dataUsuario)
    let usuario = null
    dataUsuario.user.role == ROLE_VENDEDOR
        ? usuario = dataUsuario.user.idusuario
        : usuario = idasignado?.idusuario ? idasignado?.idusuario : null

    const dataBitacora = {
      idcliente: props.data,
      idusuario: usuario,
      observaciones: nota,
      etiquetas: chipData,
    }
    console.log('PROPS',props)
    console.log('DATA',props.data)

    const dataVendedor = {
      idcliente: props.data,
      idusuario: usuario
    }

    const resp = await productService.createBitacora(dataBitacora)
    if (resp) {
      if(props?.estado && props?.campana && dataVendedor?.idusuario){
        const respDetail = await campaingService.updateVendedorCampanaGeneral(props.campana, dataVendedor);
      }
      props.setOpen(false)
      if(props.getContact){
        props.getContact(props.data)
      }
      setChipData([])
      setDataForm(dataInitial)
      setSnackBarConfig(prev => ({
        ...prev,
        open: true,
        severity: 'success',
        message: 'Apunte registrado con éxito',
      }));
    }
  }

  const bodyModal = (
    <Box className='Modal'>
      <Box >
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{ color: '#3F9BCC', mt: 1, fontWeight: '700', fontSize: '2em' }}>
          Toma apuntes de manera rápida
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} justifyContent='center'>
            <Grid item xs={12} container spacing={2} sx={{ ml: 2, mt: 1 }}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  // error={error === "hito" ? true : false}
                  // helperText={error === "hito" ? " El hito es un campo requerido" : ""}
                  size="small"
                  placeholder="Criterios"
                  id="criterio"
                  sx={{ bgcolor: '#E3F4FD' }}
                  name="criterio"
                  multiline
                  value={criterio}
                  onChange={handleFormValues}
                />
              </Grid>
              <Grid item xs={4}>
                <IconButton onClick={() => setValue(iterador, criterio)}>
                  <AddCircleOutlineIcon color='primary' />
                </IconButton>
              </Grid>

              <Grid item
              >
                {chipData.map((data) => {
                  return (
                    //<ListItem key={data.key}>
                    <Chip
                      // icon={icon}
                      sx={{ ml: 2, mt: 1 }}
                      label={data.nombre}
                      onDelete={data.nombre === data.nombre ? handleDelete(data) : undefined}
                    />
                    // </ListItem>
                  );
                })}
              </Grid>
            </Grid>
            {dataUsuario.user.role == ROLE_ADMIN || dataUsuario.user.role == ROLE_SUPERVISOR ?
              (<Grid item xs={12} >
                <Autocomplete
                  autoComplete
                  sx={{ bgcolor: '#fff' }}
                  size='small'
                  includeInputInList
                  value={idasignado}
                  onChange={(e, asignado) => {
                    if (asignado) {
                      setIdAsignado(asignado)
                    }
                    idasignado !== null && setError("");
                  }}
                  id="asignado"
                  options={dataUser}
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => <TextField {...params} label={"Asignado a..*"} error={error === "asignado" ? true : false} helperText={error === "asignado" ? "Vendedor es un campo requerido" : ""} />}
                />
              </Grid>) :
              (<Grid item xs={12}></Grid>)
            }
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name={'nota'}
                maxRows={8}
                multiline={true}
                value={nota}
                sx={{ bgcolor: '#E3F4FD' }}
                onChange={handleFormValues}
                placeholder="En este espacio puedes anotar observación adicional a los criterios"
                type="text"
                id="nota"
                size="small"
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
            <Grid item >
              <Button
                sx={{
                  background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                    bgcolor: '#bf6c00'
                  }
                }}
                color='error'
                onClick={() => {
                  props.setOpen(false);
                  setChipData([])
                  setDataForm(dataInitial)
                }}
                endIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button
                sx={{
                  background: '#155172', mt: "10px", color: '#fff', '&:hover': {
                    bgcolor: '#155172'
                  }
                }}
                type='submit'
                endIcon={<EditIcon />}
              >
                Guardar
              </Button>
            </Grid>

          </Grid>
        </form>
      </Box>
    </Box>
  )



  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false)

        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
      <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
