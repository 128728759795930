import React, {useEffect, useState} from 'react';
import { Button, Grid, TextField, Backdrop, CircularProgress, IconButton,InputAdornment, Snackbar, Box, AppBar, Toolbar, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import logoLogin from "@assets/img/logo-login.jpg";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authenticationService } from '@/service/services/Authentication.service';
import { useHistory } from 'react-router-dom'
import { ROUTE_DOCUMENTOS, ROUTE_REGISTRAR_USER_DOC } from '@/toolbox/constants/route-map';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_DOCUMENTS } from '@/toolbox/constants/local-storage';
import { Icon } from '@components/common/Icon';
import { Props } from './View_Redirect.type';
import * as qs from 'qs'
import { LeadsFormService } from '@/service/services/LeadsForm.service';

const theme = createTheme();

export const ViewRedirectView: React.FC<any> = (props): JSX.Element => {

const {u,ca,cl,dc} = props.location && qs.parse(props.location.search.slice(1, -1));
// console.log(u)
// console.log(ca)
// console.log(cl)
// console.log(dc)

const  HistoryViewLanding = async (data) =>{
  const resp =  await  LeadsFormService.HistoryViewLanding(data)
}
    useEffect(() => {
const data = {
  idcampananegocio:ca,
  idcliente:cl,
  idempresa:'',
  iddetallecampana:dc,
}
HistoryViewLanding(data);
// const decodeEmail = atob(u);

  window.open(u, "_self", "");
// console.log(decodeEmail);
   
    }, []);
   return (
    <ThemeProvider theme={theme}>
         <Grid container component="main" sx={{ height: '100vh' }}>
         </Grid>
      </ThemeProvider>
   )
};


