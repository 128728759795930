import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { RuleDTO } from '../http/dto/RuleDTO';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const ruleRepository = {
   getRule: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const rule = await http.get<RuleDTO>(`${API_URL_BASE}/v1/business-rules?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = rule;
      return {
         data,
         error,
         message
      }
   },
   getRulePage: async (perPage, page): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const rule = await http.get<RuleDTO>(`${API_URL_BASE}/v1/business-rules?per_page=${perPage}&page=${page}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = rule;
      return {
         data,
         error,
         message
      }
   },
   getSearchRule: async (search: string): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const rule = await http.get<RuleDTO>(`${API_URL_BASE}/v1/business-rules?search=${search}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = rule;
      return {
         data,
         error,
         message
      }
   },
   getDataInitial: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const rule = await http.get<any>(`${API_URL_BASE}/v1/business-rules/data?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = rule;
      return {
         data,
         error,
         message
      }
   },
   getTypeRule: async (): Promise<any>=>{
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/typeRule?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },
   createRule: async (dataRule): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/business-rules`, {
         nombre: dataRule.nombre,
         idtipoRegla: dataRule.idtipoRegla,
         fechainicio: dataRule.fechainicio,
         fechafin: dataRule.fechafin,
         productoscomprados: dataRule.productoscomprados,
         productoscomplementarios: dataRule.productoscomplementarios,
         productosrelacionados: dataRule.productosrelacionados,
         id_empresa: dataRule.idempresa,
         propietario: dataRule.propietario,
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   updateRule: async (id: number, dataRule): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/business-rules/${id}`, {
         nombre: dataRule.nombre,
         idtipoRegla: dataRule.idtipoRegla,
         fechainicio: dataRule.fechainicio,
         fechafin: dataRule.fechafin,
         productoscomprados: dataRule.productoscomprados,
         productoscomplementarios: dataRule.productoscomplementarios,
         productosrelacionados: dataRule.productosrelacionados,
         datosusuario: dataRule.datosusuario,
         propietario: dataRule.propietario,
         id_empresa: dataRule.idempresa,
      });

      return resp;
   },

   deleteRule: async (id: number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/business-rules/${id}`)
      return resp;
   },

   searchDateBussinessRule: async (dataRule): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/business-rules/searchDate?fechainicio=${dataRule.fecha}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   searchBussinessRule: async (dataRule): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/business-rules/searchNameRules?word=${dataRule.word}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

}
