import { notifyRepository } from "../repositories/Notify.repository";

// export const productService = {
//    getProduct: () => {
//       return productRepository.getProduct()
//    },
// };


export const notifyService= {
   getNotify,
   getViewReceived,
   getViewSent,
   createNotiy,
   getNotiNoVistos,
   editVisto
};

async function getNotify () {
   const notify= await notifyRepository.getNotify();
   //console.log(notify)
   return notify
}
async function editVisto(id) {
   const notify= await notifyRepository.editVisto(id);
   return notify
}
async function getNotiNoVistos (perPage,page) {
   const notify= await notifyRepository.getNotiNoVistos(perPage,page);
   return notify
}
async function getViewReceived (idusuario:number) {
   const viewReceived= await notifyRepository.getViewReceived(idusuario);
   // console.log(viewReceived)
   return viewReceived
}
async function getViewSent (idusuario:number) {
   const viewSent= await notifyRepository.getViewSent(idusuario);
   //console.log(notify)
   return viewSent
}
function createNotiy(notify){
   return notifyRepository.createNotiy(notify)
}
