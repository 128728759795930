import  React,{useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider,FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, IconButton, Tooltip} from '@mui/material';
import { Box } from '@mui/system';
import {SaveIcon,CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecommendIcon from '@mui/icons-material/Recommend';
import { ROUTE_CAMPAIGN } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom'


type ModalProps = {
  open: boolean,
  setOpen:any,
  data?:any,
  setData?:any
}
export const ModalValidateFormView:React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element =>{

  const bodyModal = (
    <Box className='Modal' sx={{width:'400px'}}>
       <Box >
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' 
        sx={{color:'#3F9BCC', fontWeight:'700', fontSize:'1.4em', mb:2}}>
         Complete los siguientes Campos Requeridos
         </Typography>
        {props.data&&
           <ul>
               { props.data.map((row,i)=>{
                return(
                  <li key={i}>
                    <Typography> - {row.etiqueta}</Typography>   
                  </li>
                )
            })}
           </ul>
        }
      </Box>
    </Box>
  )

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
            props.setOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
}
