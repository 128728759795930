import { ubigeoRepository } from "../repositories/Ubigeo.repository";

export const ubigeoService= {
   getPais,
   getRegion,
   getProvincia,
   getComuna,
   getUbigeo,
   getComunaByName
};

async function getPais () {
   return ubigeoRepository.getPais();
}
async function getRegion (idPais:number) {
   return ubigeoRepository.getRegion(idPais);
}
async function getProvincia(idRegion:number) {
   return ubigeoRepository.getProvincia(idRegion);
}
async function getComuna (idProvincia:number) {
   return ubigeoRepository.getComuna(idProvincia);
}
async function getUbigeo(idComuna:number) {
   return ubigeoRepository.getUbigeo(idComuna);
}
async function getComunaByName(name) {
   return ubigeoRepository.getComunaByName(name);
}
