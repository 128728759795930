import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import ReactGauge from 'react-liquid-gauge';

export const LiquidPorcentajeCumpliance: React.FC<any> = (props): JSX.Element => {

    const {textGraph,  porcentage} = props
   

    const getColor = (value) => {
        if (value >= 80) {
            return '#5bc0de'; // Azul
        } else if (value >= 60) {
            return '#5cb85c'; // Verde
        } else {
            return '#d9534f'; // Rojo
        }
    };
   
    return (
        <>
            <ReactGauge
            style={{ margin: '0 auto' }}
            width={260}
            height={260}
            percent={'%'}
            value={porcentage}
            textStyle={{
                fill: getColor(porcentage),
            }}
            circleStyle={{
                fill: getColor(porcentage),
            }}
            waveStyle={{
                fill: getColor(porcentage),
            }}
            
        />
            <Typography variant="h5" mt={3} textAlign='center' fontWeight={700} color={getColor(porcentage)}>{textGraph}</Typography>
        
        </>
    )
}