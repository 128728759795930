import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
//import { Props } from './DetailCampaing.type';
import { useHistory } from 'react-router-dom'
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, Snackbar, Alert, InputLabel, TextField, Typography, Button, IconButton, ListItem, TableCell, TableHead, Backdrop, Box, Tooltip, Chip } from '@mui/material';
import { AcordionUserBussines } from '@/components/common/Acordion';
import { TableData } from '@/components/common/Table';
import { ModalNewCampaign } from '@/components/common/Modal';
import { ROUTE_CAMPAIGN, ROUTE_CAMPAING_LEADS_FORM, ROUTE_CONTACT_UPDATE, ROUTE_DETAILCAMPAIGNLEADS, ROUTE_DETAILCAMPAIGNLEADSCLIENT, ROUTE_DETAIL_CLIENTS_TASK, ROUTE_TRELLO_LEADS } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import { campaingService } from '@/service/services/Campaing.service';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ModalChangeDetailCampaign } from '@/components/common/Modal/ModalChangeDetailCampaing';
import { byPassRepository } from '@/service/repositories/Bypass.repository';
import { bypassService } from '@/service/services/Bypass.service';
import { typography } from '@mui/system';
import { ModalViewURL } from '@/components/common/Modal/ModalViewUrl';
import { ModalAsignarClient } from '@/components/common/Modal/ModalAsignarClient';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import LinkIcon from '@mui/icons-material/Link';
import { SpinnerGrow } from '@/components/common/Spinner';
import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { DATA_CAMPAING_LEADS } from '@/toolbox/constants/local-storage';
import { ModalMoveClient } from '@/components/common/Modal/ModalMoveClient';

export const DetailCampaingLeadsView: React.FC<any> = (props): JSX.Element => {

   //  const header=[{name: 'inputs',label:'Prospecto',width:''},
   //  {name:'nombre_cliente', label:'Cliente Enlazado',width:''},
   //  {name:'nombre_usuario', label:'Vendedor Asignado',width:''},
   //  {name: 'canal', label:'Canal'},
   //  {name:'nombre_pipelines',label:'Etapa',width:''}];
   const header = [{ name: 'nombre_cliente', label: 'Prospecto', width: '' },
   // {name:'nombre_cliente', label:'Cliente Enlazado',width:''},
   { name: 'nombre_usuario', label: 'Vendedor Asignado', width: '' },
   { name: 'canal', label: 'Canal' },
   { name: 'nombre_pipelines', label: 'Etapa', width: '' }];
   const dataAction = [
      { name: 'edit', color: 'primary' },
      { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGNLEADSCLIENT },
      { name: 'move', color: 'danger' },
      { name: 'asignar', color: 'success'},
   ]


   const history = useHistory();
   const [isDetail, setIsDetail] = useState<boolean>(false);
   const [openView, setOpenView] = useState<boolean>(false)
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [openAsing, setOpenAsing] = useState(false)
   const [DataAsing, setDataAsing] = useState({})
   const [dataDetailCampaing, setDataDetailCampaing] = useState<any>([]);
   const [nombreCampaing, setnombreCampaing] = useState<any>(props.location.state.nombre);
   const [listProductos, setListProductos] = useState<any>(props.location.state ? props.location.state.productos : [])
   const [dataDetailCrm, setDataDetailCrm] = useState<any>([])
   const [openModalMove, setOpenModalMove] = useState(false);
   const [dataModal, setDataModal] = useState<any>([])
   const [campaign, setCampaign] = React.useState([]);
   const [cita, setCita] = useState<any>(0);
   const [calificado, setCalificado] = useState<any>(0);
   const [contrato, setContrato] = useState<any>(0);
   const [ganado, setGanado] = useState<any>(0);
   const [perdido, setPerdido] = useState<any>(0);
   const [presentacion, setPresentacion] = useState<any>(0);
   const [detailSelect, setDetailSelect] = useState<any>([])
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [busqueda, setBusqueda] = useState<any>("");
   const [openLeads, setOpenLeads] = useState<boolean>();
   const [urlLeads, setUrlLeads] = useState<any>([])
   const [viewForm, setViewForm] = useState<boolean>(false);
   const [campana, setCampana] = useState([])
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   useEffect(() => {

      try {
         // getDetalleCampaing();
         detalleCampañaGenerales();
      } catch (e) {
         console.log(e)
      }

   }, [props.location.state.id])

   useEffect(() => {
      try {
         estados();
      } catch (e) {
         console.log(e)
      }
   }, [dataDetailCampaing])

   const nextPage = (toroute, row) => {
      history.push(toroute, row);
   };

   const detalleCampañaGenerales = async () => {
      // const idcampaña = props.location.pathname.split("/")[3];
      // const paramter = await campaingService.getIdParameter(props.location.state.id)
      // if (paramter.data == 'No cuenta con leads') {
      //    setViewForm(true)
      // }
      const resp = await campaingService.getDetailCampanaleadsById(props.location.state.id)//`${idcampaña}-KYTE`
      if (resp) {
         setCampana(props.location.state.id)
         let idParameter = null;
         let DataForm = [];
         let DataClient = [];
         resp.data.map((value) => {
            if (value.idparameter && !value.idcliente) {
               idParameter = value.idparameter;
               DataForm.push(value);
            } else {
               DataClient.push(value)
            }
         })
         if (idParameter) {
            detalleParameterGenerales(idParameter, resp.data, DataForm, DataClient);
         } else {
            setDataDetailCampaing(resp.data)
         }
      }

      const urls = await bypassService.getUrlsLeads(props.location.state.id);

      if (urls) {
         setUrlLeads(urls.channels)
      } else {
         setViewForm(true)
      }

   }

   // async function getDetalleCampaing() {
   //    try{
   //       setOpenView(true)
   //       const paramter = await campaingService.getIdParameter(props.location.state.id)
   //      if( paramter.data == 'No cuenta con leads'){
   //         setViewForm(true)
   //      }
   //       const resp = await bypassService.postListaByCampaign(paramter.data);
   //       const urls = await bypassService.getUrlsLeads(`${props.location.state.id}`);
   //       setUrlLeads(urls.channels)
   //       const trat = await Tratamiento(resp)
   //       estados();
   //       console.log(dataDetailCrm)
   //       setDataDetailCampaing(dataDetailCrm);
   //       setTablaBusqueda(resp);
   //       setOpenView(false)
   // }
   //    catch(e){
   //       console.log(e)
   //    }
   // }

   const DataProsectoAsing = (data) => {
      setDataAsing(data)
      setOpenAsing(true)
   }

   const detalleParameterGenerales = async (idParameter, data, DataForm, DataClient) => {
      const respParameter = await bypassService.postListaByCampaign(idParameter);
      if (respParameter) {
         const Array = [];
         respParameter.map((value) => {
            DataForm.map((item) => {
               if (value.codigo == item.form_cliente) {
                  let objeto = {
                     form_cliente: value.codigo,
                     idpipelines: item.idpipelines,
                     idusuario: item.idusuario,
                     nombre_usuario: item.nombre_usuario,
                     // nombre_cliente: <Box><Typography>{'Nombre'} : {item.nombre_rz}</Typography><Typography>{'Rut Cliente'} : {item.rut_cliente}</Typography></Box>,
                     idcampananegocio: item.idcampananegocio,
                     idcliente: item.idcliente,
                     iddetallecampaña: item.iddetallecampaña,
                     nombre_pipelines: item.nombre_pipelines,
                     campaña: value.campaña,
                     canal: value.canal,
                     nombre_cliente: value.inputs.map((value) => {
                        return (
                           <>
                              <Typography>{value.input} : {value.value}</Typography>
                           </>
                        )

                     })
                  }
                  Array.push(objeto)
               }
            })
         })
         const newArray = Array.concat(DataClient)
         setDataDetailCampaing(newArray)
      }
   }


   // async function Tratamiento (resp){
   //   try{
   //       const crm = await campaingService.getDetailsCampaignLeads()
   //       setDataDetailCrm([])
   //       resp.map((value)=>{
   //          console.log(value)
   //          crm.data.map((item)=>{

   //             console.log(item)
   //             if(value.codigo == item.form_cliente){
   //                console.log('hola')

   //                let objeto = {
   //                   form_cliente: value.codigo,
   //                   idpipelines: item.idpipelines,
   //                   idusuario: item.idusuario,
   //                   nombre_usuario: item.nombre_usuario,
   //                   nombre_cliente: <Box><Typography>{'Nombre'} : {item.nombre_cliente}</Typography><Typography>{'Rut Cliente'} : {item.rut_cliente}</Typography></Box>,
   //                   idcampananegocio: item.idcampana,
   //                   idcliente: item.idcliente,
   //                   iddetallecampaña: item.iddetallecampaña,
   //                   nombre_pipelines: item.nombre_pipelines,
   //                   campaña: value.campaña,
   //                   canal: value.canal,
   //                   inputs:value.inputs.map((value)=>{
   //                      return(
   //                         <>
   //                      <Typography>{value.input} : {value.value}</Typography>
   //                         </>
   //                      )

   //                   })
   //                }

   //                console.log(objeto)
   //                dataDetailCrm.push(objeto)
   //             }
   //          })
   //       })

   //       }
   //    catch(e){
   //       console.log(e)
   //    }
   //    //<Typography>{value.input} : {value.value}</Typography>

   // }

   //buscador general de detalle

   const handleChange = (e) => {

      try {
         setBusqueda(e.target.value)
         filtrar(e.target.value)
      } catch (error) {
         console.log(error)
      }
   }


   const filtrar = (terminoBusqueda) => {
      try {
         var resultBusqueda = tablaBusqueda.filter((elemento) => {
            if (elemento.nombre_rz.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())
               || elemento.nombre_usuario.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())
               || elemento.nombre_pipelines.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())) {
               return elemento
            }
         })
         setDataDetailCampaing(resultBusqueda)
      } catch (e) {
         console.log(e)
      }
   }


   const RecuperarChangeDetail = async (data) => {

      try {
         if (data.idvendedor !== '') {

            const resp = await campaingService.updateDetailsCampaignLeadsUser(data.iddetallecampaña, data.idvendedor)

         }
         if (data.idpipelines !== '') {
            const resp = await campaingService.updateDetailsCampaignLeadsPipelines(data.iddetallecampaña, data.idpipelines)

         }
         // getDetalleCampaing();
         detalleCampañaGenerales()
         setOpen(false)
      } catch (e) {
         console.log(e)
      }


   };

   //preventa
   const RecuperarData = async (data) => {

      try {

         if (data.action == 'edit') {
            setOpen(true)
            // //setFormValues(data)
            setDetailSelect(data)

         } else if (data.action == 'move') {
            if (data.idcliente) {
               getCampaignPost(data.idcampananegocio)
               setDataModal(data)
               setOpenModalMove(true)
            } else {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  severity: 'error',
                  message: 'El prospecto no esta relacionado'
               }));
            }
         }

      } catch (e) {

         console.log(e)

      }

   }

   async function getCampaignPost(value) {

      try {
         const resp = await campaingService.getCampaignTraslate(value)
         if (resp) {
            setCampaign(resp.data)
         }
      } catch (e) {
         console.log(e)
      }

   }

   const estados = () => {

      try {

         let contCita = 0;
         let contCalificado = 0;
         let contPresentacion = 0;
         let contContrato = 0;
         let contGanado = 0;
         let contPerdido = 0;

         dataDetailCampaing.map((data) => {
            switch (data.idpipelines) {
               case 1:
                  contCita++;
                  break;
               case 2:
                  contCalificado++;
                  break;
               case 3:
                  contPresentacion++;
                  break;
               case 4:
                  contContrato++;
                  break;
               case 5:
                  contGanado++;
                  break;
               case 6:
                  contGanado++;
                  break;

            }
         })
         setCita(contCita);
         setCalificado(contCalificado)
         setPresentacion(contPresentacion)
         setContrato(contContrato)
         setGanado(contGanado)
         setPerdido(contPerdido)

      } catch (e) {
         console.log(e)
      }
   }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 2, mb: 1 }}>
            <Grid container flexDirection='row' justifyContent='space-between'>
               {/* <Button startIcon={<ArrowBackIcon/>} component={Link} to={ROUTE_CAMPAIGN}></Button> */}

               <Grid item md={9} container flexDirection='row'>
                  <IconButton component={Link} to={ROUTE_CAMPAIGN} sx={{ mr: 2 }}>
                     <ArrowBackIcon fill="black" />
                  </IconButton>
                  <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.8em' }} >
                     {nombreCampaing}
                  </Typography>
                 {!viewForm &&<IconButton sx={{ mr: 2 }} onClick={() => { setOpenLeads(true) }}>
                     <TravelExploreIcon sx={{ color: '#bf6c00' }} />
                  </IconButton>}
               </Grid>
               <Grid item md={3} container spacing={2} justifyContent='right' alignItems='end'>
                  {
                     viewForm ? <Button
                        sx={{
                           background: '#155172', color: '#fff', mr: 2, '&:hover': {
                              bgcolor: '#155172'
                           }
                        }}
                        onClick={() => {
                           saveLocalStorage(DATA_CAMPAING_LEADS, props.location.state);
                           // props.$action.actionSetDataCampana(props.location.state)
                           //  props.ViewAddLeadsForm();
                           history.push(ROUTE_CAMPAING_LEADS_FORM)
                        }}
                     >
                        Generar Formulario
                     </Button> : <></>
                  }

                  <Button
                     //variant='outlined'
                     onClick={() => {
                        if (dataDetailCampaing.length > 0) {
                           nextPage(ROUTE_TRELLO_LEADS, props.location)
                        }
                     }}
                     sx={{
                        background: '#bf6c00', color: '#fff', '&:hover': {
                           bgcolor: '#bf6c00'
                        }
                     }}
                  >Vista Trello</Button>
               </Grid>
            </Grid>
            <Grid item md={8} container flexDirection='row' sx={{ mt: 2 }}>
               {/* <Typography variant='h4' sx={{ color: '#155172',fontWeight: '400', fontSize: '1.5em' }} >
                     Productos:
                  </Typography> */}
               {listProductos.map((value, i) => {
                  return (
                     <Tooltip title={value.codigo} key={i}>
                        <Chip label={`${value.nombre}`} color="primary" key={i} />
                     </Tooltip>
                  )
               })}
            </Grid>
            {/* <Divider sx={{ mt: 3, mb:4 }}/>
            <Typography variant='h5' >Asignación de Clientes a Usuarios Vendedores</Typography>
            <Grid sx={{mt:2,mb:5}}>
                <AcordionUserBussines
                />
            </Grid> */}
            <Divider sx={{ mt: 3, mb: 4 }} />
            {/* <Typography variant='h5' >
            {nombreCampaing}
               </Typography> */}
            <Grid container flexDirection='row' justifyContent='space-around' spacing={2} sx={{ mt: 2, mb: 4 }}>

               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{cita}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Cita</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{calificado}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Calificado</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{presentacion}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Presentación</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{contrato}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Contrato</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{ganado}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Ganados</Typography>
               </Grid>
               <Grid item xs={4} md={2} container flexDirection='column' alignItems='center'>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{perdido}</Typography>
                  <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>Perdidos</Typography>
               </Grid>
            </Grid>
            <Divider sx={{ mt: 5, mb: 5 }} />
            <Grid container spacing={1} sx={{ mb: 2 }}>
               <Grid item md={12}>
                  <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{dataDetailCampaing.length} Clientes encontrados</Typography>
                  <span style={{ color: '#155172', fontSize: '1em' }}>Filtrar resultados por:</span>
               </Grid>
               <Grid item md={3}>
                  {/* <IconButton aria-label="delete" disabled color="primary">
                        <DeleteIcon />
                     </IconButton> */}
                  {/* <Button
                        onClick={() => {}}
                        sx={{
                           background: '#bf6c00', color: '#fff', '&:hover': {
                              bgcolor: '#bf6c00'
                           }
                        }}
                     >Vista Trello</Button> */}

                  {/* <TextField
                        type='search'
                        size='small'
                        onChange={handleChange}
                        name="text"
                        value={busqueda}
                        fullWidth
                        /> */}
               </Grid>
            </Grid>
            {/* <TableData
                  header={header}
                  data={dataDetailCampaing}
                  action={dataAction}
                  RecuperarData={RecuperarData}
                  Recuperarid={DataProsectoAsing}/> */}
            <TableData
               header={header}
               data={dataDetailCampaing}
               action={dataAction}
               RecuperarData={RecuperarData}
               Recuperarid={DataProsectoAsing} />

         </Container>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <ModalChangeDetailCampaign
            open={open}
            setOpen={setOpen}
            detailSelect={detailSelect}
            setDetailSelect={setDetailSelect}
            RecuperarData={RecuperarChangeDetail}
         />
         <ModalViewURL
            open={openLeads}
            setOpen={setOpenLeads}
            data={urlLeads}
         />
         <ModalAsignarClient
            open={openAsing}
            setOpen={setOpenAsing}
            DataAsing={DataAsing}
         />
         <ModalMoveClient
            open={openModalMove}
            setOpen={setOpenModalMove}
            data={dataModal}
            dataListCampaing={campaign}
            refreshData={detalleCampañaGenerales}
         />

      </Protected>
   )
};



