import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText, Backdrop } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { ModalProspectoRapido } from './ModalProspectoRapido';
import { propsToClassKey } from '@mui/styles';
import { mailService } from '@/service/services/Mail.service';
import { ModalCampaignCorreo } from './ModalCampaignCorreo';
import { cotizacionService } from '@/service/services/Cotizacion.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import { SpinnerGrow } from '../Spinner';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data:any,
    dataCotizacion:any
}


export const ModalCotizacionEdit: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props.data);
    console.log(props.dataCotizacion)
    const userData = readLocalStorage(KEY_USER_DATA);
    const tokenSft = readLocalStorage(KEY_SOFTNET)
    const [open, setOpen] = useState<boolean>(false)
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [statusEdit, setStatusEdit] = useState<boolean>(false);
   const [renderPage, setRenderPage] = useState<boolean>(false);
   const dataInitial = {
     titulo:`COTIZACIÓN - ${props.dataCotizacion.emisor.RznSoc}`,
     img_principal:'',
     array_productos: JSON.stringify(props.dataCotizacion.Detalle),
     total_iva: props.dataCotizacion.totales.IVA,
     total_neto:props.dataCotizacion.totales.MntNeto,
     total_bruto:props.dataCotizacion.totales.MntTotal,
     tipo:'cotizacion',
     folio: props.dataCotizacion.idDoc.folio,
     frase_principal:`Muchas gracias por Cotizar con nosotros, ${props.dataCotizacion.receptor.RznSocRecep}`,
     descripcion: `A continuación te mostramos el detalle de tu cotización  realizada el ${props.dataCotizacion.idDoc.fechaEmis} , esperamos seguir contando con tu preferencia.`,
     condiciones: '(*) La presente cotización tiene una validez de 15 dias a partir de la fecha de hoy',
     frase_secundaria: `Estamos felices de que formes parte de  ${props.dataCotizacion.emisor.RznSoc}  y agradecidos por tu preferencia, nos mantenemos actualizados y enfocados en atenderte como mereces`,
     iddetallecampananegocioclientes: props.data.iddetallecampaña
 }

    useEffect(()=>{
        console.log('hola')
        
    },[props.open])
    
    const [dataLanding, setDataLanding] = useState<any>(dataInitial)
   
   const EnviarCotizacion = async() => {
    setOpen(true)
    if(!dataLanding.titulo){
        return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'El titulo es necesario para generar el envio de correo'})
    }
    if(!dataLanding.frase_principal){
        return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'La frase principal es necesario para generar el envio de correo'})
    }
    if(!dataLanding.descripcion){
        return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'La descripcion es necesario para generar el envio de correo'})
    }
    if(!dataLanding.condiciones){
        return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'Las condiciones es necesario para generar el envio de correo'})
    }
    if(!dataLanding.frase_secundaria){
        return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message:'La frase secundaria es necesario para generar el envio de correo'})
    }
    setDataLanding({...dataLanding, mail:'lf.ramirezmendoza@gmail.com', tipo:'cotizacion'})
    const resp = await mailService.sendLandingMail(dataLanding);
    setOpen(false)
}

    const handleChange = (e) =>{
        console.log(e)
        const name = e.target.name;
        console.log(name)
        switch(name){
            case 'titulo':
                setDataLanding({...dataLanding, titulo: e.target.value});
                e.target.value
                break;
            case 'frase_principal':
                setDataLanding({...dataLanding, frase_principal: e.target.value});
                e.target.value
                break;
            case 'descripcion':
                setDataLanding({ ...dataLanding, descripcion: e.target.value });
                e.target.value
                break;
            case 'condiciones':
                setDataLanding({ ...dataLanding, condiciones: e.target.value });
                e.target.value
                break;
            case 'frase_secundaria':
                setDataLanding({ ...dataLanding, frase_secundaria: e.target.value });
                e.target.value
                break;
        }
    }



    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Personaliza el envio de cotización por correo`}
            </Typography>
            <Grid container justifyContent={'right'} mt={2} spacing={2}>
            <Button 
             sx={{
                background: '#E4980D', width:'100px', color: '#fff', mr:2, fontWeight: '700', '&:hover': {
                    bgcolor: '#E4980D'
                }
            }}
            onClick={()=>{setStatusEdit(!statusEdit)}}>{statusEdit?'Ver':'Editar'}</Button>
            <Button 
             sx={{
                background: '#5C68E2', width:'100px', color: '#fff', mr:2, fontWeight: '700', '&:hover': {
                    bgcolor: '#5C68E2'
                }
            }}
            onClick={()=>{EnviarCotizacion()}}>{'Enviar'}</Button>
            </Grid>
            
            <Grid container justifyContent={'center'} paddingX={8} mt={3} maxHeight={'70vh'} sx={{overflowY:'scroll'}}>
                {statusEdit ?

                    <Grid item container xs={12} sx={{ background: '#044767' }}>
                        <h2 style={{ marginTop: '0', padding:'30px', color: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <TextField
                            name={'titulo'}
                            sx={{ width:'400px',background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                            placeholder={'Introduce el nombre de la empresa o un titulo'}
                            type='input'
                            value={dataLanding.titulo}
                            onChange={(e)=>{handleChange(e)}}
                        /></h2>
                       
                    </Grid>
                    :
                    <Grid item container xs={12} sx={{ background: '#044767' }}>
                        <h2 style={{ marginTop: '0', padding:'30px', color: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        {dataLanding.titulo}</h2>
                    </Grid>

                }

                {
                    statusEdit?
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} justifyContent='center'>
                        <h2 style={{ marginTop: '0',lineHeight:'36px', padding:'30px', color: '#333333', fontSize: '30px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <TextField
                            name={'frase_principal'}
                            sx={{ width:'450px' ,background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                            placeholder={'Introduce una frase principal'}
                            multiline
                            fullWidth
                            type='input'
                            value={dataLanding.frase_principal}
                            onChange={(e)=>{handleChange(e)}}/>

                        </h2>
                    </Grid>
                    <Grid item container xs={12} sx={{ background: '#fff' }} justifyContent='center'>
                        <h2 style={{ marginTop: '0',lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#777777', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <TextField
                            name={'descripcion'}
                            sx={{ width:'450px', background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                            placeholder={'Introduce una descripción'}
                            multiline
                            fullWidth
                            type='input'
                            value={dataLanding.descripcion}
                            onChange={(e)=>{handleChange(e)}}/>

                        </h2>
                    </Grid>
                    </> :
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>
                        <h2 style={{ marginTop: '0',lineHeight:'36px', padding:'30px', color: '#333333', fontSize: '30px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            {dataLanding.frase_principal}</h2>
                    </Grid>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>
                        <h2 style={{ marginTop: '0',lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#777777', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            {dataLanding.descripcion}</h2>
                    </Grid>
                    </> 
                    
                }

                {
                    statusEdit ?
                    <Grid item container xs={12} sx={{ background: '#fff', paddingX:'35px' }} >
                        <table><tr >
                        <td style={{background:"#eeeeee", padding:'10px'}} align="left" width={'100%'}><h4 >FOLIO N°{props.dataCotizacion.idDoc.folio}</h4> </td>
                        </tr></table>
                    </Grid> :
                    <Grid item container xs={12} sx={{ background: '#fff', paddingX:'35px' }} justifyContent='rigth' >
                        <table><tr >
                        <td style={{background:"#eeeeee", padding:'10px'}} align="left" width={'100%'}><h4 >FOLIO N°{props.dataCotizacion.idDoc.folio}</h4> </td>
                        </tr></table>
                    </Grid> 
                }
                
                
                    <Grid item container xs={12} sx={{ background: '#fff', paddingX:'35px' }} textAlign='center'>
                    <table width={'100%'} style={{paddingRight:'35px', paddingLeft:'35px'}}>
                    {
                        props.dataCotizacion.Detalle.map((value)=>{
                            return(
                                <tr>
                                    <td width="20%" align="left">
                                        <p style={{ marginTop: '0',lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '16px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{value.QtyItem}</p></td>
                                    <td width="60%" align="left">
                                    <p style={{ marginTop: '0',lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '16px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{value.NmbItem}
                                    </p></td>
                                    <td width="40%" align="right"><p style={{ marginTop: '0',lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '16px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{moneyFormatInt(value.MontoItem)}
                                    </p></td>
                                </tr>
                            )
                        })
                    }
                    </table>
                    <Grid container sx={{}}>
                        <Grid item xs={9} textAlign='left'>
                            <h4 style={{ marginTop: '0',lineHeight:'120%', paddingTop:'15px', paddingBottom:'20px', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>Monto Neto</h4>
                        </Grid>
                        <Grid item xs={3} textAlign='right'>
                        <h4 style={{ marginTop: '0',lineHeight:'120%', paddingTop:'15px', paddingBottom:'20px', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{moneyFormatInt(props.dataCotizacion.totales.MntNeto)}</h4>
                        </Grid>

                        <Grid item xs={9} textAlign='left'>
                        <h4 style={{ marginTop: '0',lineHeight:'120%', paddingTop:'15px', paddingBottom:'20px', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>IVA</h4>
                        </Grid>
                        <Grid item xs={3} textAlign='right'>
                        <h4 style={{ marginTop: '0',lineHeight:'120%', paddingTop:'15px', paddingBottom:'20px', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{moneyFormatInt(props.dataCotizacion.totales.IVA)}</h4>
                        </Grid>

                        <Grid item xs={9} textAlign='left'>
                        <h4 style={{ marginTop: '0',lineHeight:'120%', paddingTop:'15px', paddingBottom:'20px', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>Monto Total</h4>
                        </Grid><Grid item xs={3} textAlign='right'>
                        <h4 style={{ marginTop: '0',lineHeight:'120%', paddingTop:'15px', paddingBottom:'20px', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{moneyFormatInt(props.dataCotizacion.totales.MntTotal)}</h4>
                        </Grid>
                    </Grid>
                    </Grid> 
                 
                {
                    statusEdit?
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>
                        <h2 style={{ marginTop: '0', paddingLeft:'25px' ,lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#777777', fontSize: '15px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <TextField
                            name={'condiciones'}
                            sx={{ width:'450px',background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                            placeholder={'Introduce las condiciones de la cotización, vigencia o stock'}
                            type='input'
                            multiline
                            value={dataLanding.condiciones}
                            onChange={(e)=>{handleChange(e)}}/>
                            </h2>
                    </Grid>:
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>
                        <h2 style={{ marginTop: '0', paddingLeft:'25px' ,lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#777777', fontSize: '15px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            {dataLanding.condiciones}</h2>
                    </Grid>
                    </> 
                    
                }
                {
                    statusEdit?
                    <Grid item container xs={12} sx={{ background: '#1b9ba3' }} textAlign='center'>
                    <h2 style={{ marginTop: '0',lineHeight:'29px', padding:'30px', color: '#fff', fontSize: '24px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                    <TextField
                            name={'frase_secundaria'}
                            sx={{ width:'450px',background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                            placeholder={'Introduce una frase adicional'}
                            type='input'
                            multiline
                            value={dataLanding.frase_secundaria}
                            onChange={(e)=>{handleChange(e)}}/></h2>
                        </Grid>
                    :
                    <>
                    <Grid item container xs={12} sx={{ background: '#1b9ba3' }} textAlign='center'>
                        <h2 style={{ marginTop: '0',lineHeight:'29px', padding:'30px', color: '#fff', fontSize: '24px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            {dataLanding.frase_secundaria}</h2>
                    </Grid>
                    </> 
                    
                }
                <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>

                    <h6 style={{ marginTop: '0', lineHeight: '21px', padding: '30px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <strong>{'Desarrollado por Softnet SPA'}</strong>
                    </h6>
                    <h6 style={{ marginTop: '0', lineHeight: '21px', padding: '30px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <strong><a href='www.softnet.cl' target='_blank'>{'softnet.cl'}</a></strong></h6>

                </Grid>
            </Grid>
        </Box> 
    )

    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
                sx={{width:'120%', left:'-10%'}}
            >
                <>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <SpinnerGrow />
                            <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                        </Grid>
                    </Backdrop>
                    {renderPage ? <div>hola</div>:bodyModal}
                </>
                    
            </Modal>
           
        </div>
    );
}
