import React, { useEffect, useState } from 'react';
import { Grid, TextField, Divider, Autocomplete, Dialog, DialogTitle, Modal, Snackbar, Alert, Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';
import { Props } from "./ModalDetail.type";
import { TableData } from '@/components/common/Table';
import { entityService } from '@/service/services/Entity.service';
import { contactService } from '@/service/services/Contact.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ubigeoRepository } from '@/service/repositories/Ubigeo.repository';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const header = [
   { name: 'rut', label: 'Rut', width: '120px' },
   { name: 'nombre_rz', label: 'Razon Social', width: '' },
   { name: 'giro', label: 'Giro', width: '' },]

const Action = [
   { name: 'select', color: 'success', route: null },
]
type ModalProps = {
   open: boolean,
   setOpen: any,
   DataAsing?: any,
}

export const ModalAsignarClient: React.FC<ModalProps> = ({
   open,
   setOpen,
   DataAsing,
}): JSX.Element => {

   const [entitySoftnet, setEntitySoftnet] = useState<any>([]);
   const [valueEntity, setValueEntity] = useState<any>();
   const [pushData, setPushData] = useState<any>();
   const [status, setStatus] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   const Data = async () => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const data = {
         rut: pushData,
         idleads: DataAsing.iddetallecampaña,
         idcampana: DataAsing.idcampananegocio,
         rut_empresa:rut_empresa
      }
      console.log(data)
      const dataEmpresa = await contactService.clienteRutSync(data)
      if (dataEmpresa && dataEmpresa?.data?.descripcion == 'El cliente se ha asignado correctamente') {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: dataEmpresa.data.descripcion,
         }));
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'Ingrese un rut válido',
         }));
      }
      setOpen(false)
   }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               // if (value.length == 10) {
                  setPushData(value);
               // }
               break;
         }
      } catch (e) {
         console.log(e)
      }
   };
   return (
      <div>
         <Modal
            open={open} >
            <Box className='Modal' sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 600,
               borderRadius: '20px',
               bgcolor: 'background.paper',
               boxShadow: 24,
               p: 3,
               background: '#F5F5F5'
            }}>
               <Typography style={{ fontSize: "18px", textAlign: 'center' }}>ENLAZAR PROSPECTO</Typography>
               <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ mt: 3 }}>
                  <span>Ingrese el rut del cliente</span>
                  <br /><br />
                  <TextField
                     fullWidth
                     InputLabelProps={{ shrink: true }}
                     sx={{ bgcolor: '#fff', mb: 2 }}
                     size="small"
                     placeholder='Ejm: 71453722-2'
                     id="search"
                     name="search"
                     type="search"
                     autoFocus
                     value={valueEntity}
                     onChange={handleInput}
                  />
                  <Grid item container direction={'row'} justifyContent='end' sx={{ mt: 2 }}>
                     <Button variant='contained' color={'error'} onClick={() => { setOpen(false) }} sx={{ mr: 2 }}>
                        Salir
                     </Button>
                     <Button variant='contained' onClick={() => { Data() }}>
                        Enlazar prospecto
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      </div>

   );
}
