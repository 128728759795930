import { SpinnerGrow } from "@/components/common/Spinner"
import { Protected } from "@/components/layout/Protected"
import { Alert, Backdrop, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Divider, Drawer, FormControl, FormLabel, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { ModalGarantiaArriendo } from "../ModalGarantiaArriendo/ModalGarantiaArriendo"
import { ArriendosService } from "@/service/services/Arriendos.service."
import { DatosGenerales } from "./DatosGenerales"
import { DatosCliente } from "./DatosCliente"
import { DatosClienteFacturacion } from "./DatosClienteFacturacion"
import { DetallePagos } from "./DetallePagos/DetallePagos"
import { DetalleServicios } from "./DetalleServicios/DetalleServicios"
import { useHistory } from "react-router-dom"
import { ROUTE_ARRIENDOS } from "@/toolbox/constants/route-map"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const DetalleArriendo: React.FC<any> = (props): JSX.Element => {

  const data: any = props?.location?.state;
  const history = useHistory();

  const [snackBarConfig, setSnackBarConfig] = useState<any>({
    open: false,
    severity: 'success',
    message: 'Error',
    autoHideDuration: 5000,
  })
  const [open, setOpen] = useState<boolean>(false);
  const [dataArriendo, setDataArriendo] = useState<any>(null)

  useEffect(() => {
    if (!!data) {
      console.log(data)
      getArriendo(data.id)
    }
  }, [data])

  async function getArriendo(id) {
    setOpen(true);
    const response = await ArriendosService.getArriendoById(id);
    if (!!response?.status) {
      setDataArriendo(response?.data)
    } else {
      setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
    }
    setOpen(false);
  }

  const handleFacturacionStatus = async() => {
    setOpen(true);
    const status = dataArriendo.status_facturacion_mensual == '0' ? '1' : (dataArriendo.status_facturacion_mensual == '1' ? '0': '');
    const response = await ArriendosService.statusFacturacionMensual(data.id, status);
    if (!!response?.status) {
        await getArriendo(data.id)
    } else {
      setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
    }
    setOpen(false);
  }

  return (
    <Protected>
      <div style={{ padding: "20px" }}>
        <Snackbar
          open={snackBarConfig.open}
          autoHideDuration={snackBarConfig.autoHideDuration}
          onClose={() => setSnackBarConfig((prev) => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackBarConfig((prev) => ({ ...prev, open: false }))}
            severity={snackBarConfig.severity}
            variant="filled"
          >
            {snackBarConfig.message}
          </Alert>
        </Snackbar>

        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <SpinnerGrow />
            <Typography variant="h6" sx={{ marginLeft: "10px", fontWeight: "bold" }}>
              Cargando...
            </Typography>
          </Grid>
        </Backdrop>

        <Grid container xs={12} mx={2} mb={2} sx={{justifyContent: 'space-between'}}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant='text'
            onClick={() => { history.push(ROUTE_ARRIENDOS) }}
            sx={{
              background: '#E4980D', color: '#fff', '&:hover': {
                bgcolor: '#E4980D'
              }
            }}
          >
            Volver
          </Button>
          <Button
           variant='text'
           onClick={handleFacturacionStatus}
           sx={{
             background: '#43738E', color: '#fff', '&:hover': {
               bgcolor: '#43738E'
             }
           }}
         >
            {dataArriendo?.status_facturacion_mensual === '1' ? 'Desactivar facturacion mensual': 'Activar facturacion mensual'}
         </Button>
        </Grid>
        <Grid container spacing={4}>
          {/* Columna 1: Datos Generales */}
          <Grid item xs={12} md={4}>
            { !!dataArriendo &&  <DatosGenerales dataArriendo = {dataArriendo} />}
          </Grid>

          {/* Columna 2: Datos del Cliente */}
          <Grid item xs={12} md={4}>
            { !!dataArriendo && <DatosCliente dataArriendo = {dataArriendo} />}
          </Grid>

          {/* Columna 3: Datos de Facturación */}
          <Grid item xs={12} md={4}>
            { !!dataArriendo && <DatosClienteFacturacion  dataArriendo = {dataArriendo}/>}
          </Grid>

          {/* Columna 3: Detalle de pagos */}
          <Grid item xs={12} md={4}>
            { !!dataArriendo && <DetallePagos data={data} dataArriendo={dataArriendo} getArriendo={getArriendo}/>}
          </Grid>

          {/* Columna 4: Datos de los Servicios */}
          <Grid item xs={12} md={8}>
            { !!dataArriendo && <DetalleServicios data={data} dataArriendo={dataArriendo} getArriendo={getArriendo} />}
        </Grid>
        </Grid>
      </div>
      
    </Protected>

  );
}