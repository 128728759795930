import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Projection.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, Container, Divider, Grid, InputLabel, TextField, Typography, Button, Alert, Snackbar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Backdrop, CircularProgress } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { TabsComponent } from '@/components/common/Tabs/index';
import { Box, width } from '@mui/system';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { contactService } from '@/service/services/Contact.service';
import { Link } from 'react-router-dom';
import { ROUTE_CONTACT_CREATE, ROUTE_CONTACT_UPDATE, ROUTE_PROYECCION_DETALLE } from '@/toolbox/constants/route-map';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import Paper from '@mui/material/Paper';
import { MenuLateral } from '@/components/common/LateralMenu';
import { proyeccionService } from '@/service/services/Proyeccion.service';
import { accionService } from '@/service/services/Accion.service';
import { ModalProyectionDetails } from '@/components/common/Modal/ModalProyectionDetails';
import { ModalActionProgress } from '@/components/common/Modal/ModalActionProgress';
import { SpinnerGrow } from '@/components/common/Spinner';

export const ProjectionView: React.FC<Props> = (props: Props): JSX.Element => {
   const header1 = [
      { name: 'nombre', label: 'Proyección', width: '' },
      // { name: 'area_negocio', label: 'Área', width: '' },
      { name: 'progreso', label: 'Progreso', width: '' },
      { name: 'fecha_inicio', label: 'Fecha Inicio', width: '' },
      { name: 'fecha_fin', label: 'Fecha Fin', width: '' },

   ]
   const header2 = [
      { name: 'asunto', label: 'Accion', width: '' },
      // { name: 'area_negocio', label: 'Área de Negocio', width: '' },
      { name: 'hito', label: 'Hito', width: '' },
      { name: 'fecha_inicio', label: 'Fecha Inicio', width: '' },
      { name: 'fecha_fin', label: 'Fecha Fin', width: '' }
   ]
   const labels = [{ label: 'Proyección' }, { label: 'Acciones' }]

   const dataActionProyection = [
      { name: 'view', color: 'success',route:ROUTE_PROYECCION_DETALLE}
   ]
   const dataActionAction = [
      { name: 'view', color: 'success' }
   ]
   const [open, setOpen] = useState(true);
   const [dataProyeccion, setDataProyeccion] = useState<any>([])
   const [dataAction, setDataAction] = useState<any>([])
   const [dataEntity, setDataEntity] = useState<any>([])
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      message: `¿Desea eliminar al contacto ALDAIR con Rut 22222?`
   })

   const [openProyection, setOpenProyection] = useState<boolean>(false);
   const [valueForm, setValueForm] = useState<number>(0)
   const [actionProjection, setActionProjection] = useState<any>();

   const [actionProgress, setActionProgress] = useState<any>();
   const [openProgress, setOpenProgress] = useState<boolean>(false);




   useEffect(() => {
      getProyeccion();
      getAction();
   }, [])

   async function getProyeccion() {
      setOpen(true);
      const resp = await proyeccionService.getProyeccion();
       setDataProyeccion(resp.data);
       setOpen(false);
   }

   async function getAction() {
      setOpen(true);
      const resp = await accionService.getAccion();
      setDataAction(resp.data)
      setOpen(false)
   }


const RecuperarData = async(data) =>{

   if(data.action=='view'){
      const resp = await accionService.getActionByProjection(data.idproyeccion);
      console.log(resp.data)
      setActionProjection(resp.data);
      setOpenProyection(true)
   }

}


const RecuperarDataAction = async(data) =>{


   if(data.action=='view'){

      const resp = await accionService.getActionByProgress(data.id);

      setActionProgress(resp.data);
      setOpenProgress(true)
   }

}

   return (
      <Protected>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
              <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
         <Grid container flexDirection='row' spacing={2}>
            {/* <Grid item md={3} container>
                  <MenuLateral/>
               </Grid> */}
            <Grid item md={12}
                  // sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #1976D2 ', mt: 3 }}
               >
                  <Grid container sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }}>
            <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               // onConfirm={()=> idContact}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />
            <Typography  sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Mi Progreso</Typography>
            {/* <Grid container justifyContent='flex-end' alignContent='center'>
               <Grid item sx={{ mb: 3 }}>
                  <Button variant='outlined' component={Link} to={ROUTE_CONTACT_CREATE}>Crear Contacto</Button>
               </Grid>
            </Grid> */}
            <Grid item xs={12} mt={2}>
               <TabsComponent
                  labels={labels}
                  setValueForm={setValueForm}
                  childrenOne={
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              <Typography variant="body2" sx={{ mt: '20px' }} >
                                 {dataProyeccion.length ? `${dataProyeccion.length} registros encontrados` : `0 registros encontrados`}
                              </Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <InputLabel >Filtrar Resultados</InputLabel>
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 name="text"
                                 sx={{bgcolor:'#E3F4FD'}}
                                 placeholder="Introduce una Palabra"
                                 type="search"
                                 id="password"
                                 autoComplete="current-password"
                                 size="small"
                              />
                           </Grid>
                        </Grid>
                        <Grid container >
                           <TableData
                              header={header1}
                              data={dataProyeccion}
                              action={dataActionProyection}
                              RecuperarData={RecuperarData} />
                        </Grid>

                     </Container>
                  }
                  childrenTwo={
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              <Typography variant="body2" sx={{ mt: '20px' }} >
                                 {dataAction.length ? `${dataAction.length} registros encontrados` : `0 registros encontrados`}
                              </Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <InputLabel >Filtrar Resultados</InputLabel>
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 name="text"
                                 sx={{bgcolor:'#E3F4FD'}}
                                 placeholder="Introduce una Palabra"
                                 type="search"
                                 id="password"
                                 autoComplete="current-password"
                                 size="small"
                              />
                           </Grid>
                        </Grid>
                        <Grid container >
                           <TableData
                              header={header2}
                              data={dataAction}
                              action={dataActionAction}
                              RecuperarData={RecuperarDataAction} />
                        </Grid>
                     </Container>
                  }
               />
               </Grid>
         </Grid>

            </Grid>
         </Grid>
         <ModalProyectionDetails
            open={openProgress}
            setOpen={setOpenProgress}
            action={actionProgress}
            />
         {/* <ModalActionProgress
         open={openProgress}
         setOpen={setOpenProgress}
         action={actionProgress}
         /> */}
         </Container>
      </Protected>
   )
};
