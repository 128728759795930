import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, IconButton, Tooltip, CircularProgress, Chip, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { DATA_CAMPAING_LEADS, KEY_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';
import PaidIcon from '@mui/icons-material/Paid';
import BusinessIcon from '@mui/icons-material/Business';
import RuleIcon from '@mui/icons-material/Rule';
import TimelineIcon from '@mui/icons-material/Timeline';
import EditIcon from '@mui/icons-material/Edit';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { propsToClassKey } from '@mui/styles';
import { ROUTE_CAMPAIGN, ROUTE_CAMPAING_LEADS_FORM } from '@/toolbox/constants/route-map';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment';
import { bypassService } from '@/service/services/Bypass.service';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from "@mui/styles";
import { LeadsFormView } from '@/views/LeadsForm';

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetDataCampana } from '@/redux/actions';
import { Link, useHistory } from 'react-router-dom';
import { CheckBox } from '@components/common/CheckBox';
import { TemplateWsService } from '@/service/services/TemplateWs.service';


//recuerda ver iteemmmmmsss!
let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let fechaapertura2 = year + '-' + (month < 10 ? '0' + month : month) + '-' + date;
let fechaaperturaVisual = year + '-' + (month < 10 ? '0' + month : month) + '-' + date;

const initialFormValues = {
  nombre: '',
  cantidad: '',
  utilidad: '',
  productos: '',
  fechaapertura: fechaapertura2,
  fechacierre: '',
  //preciooferta: '',
  tiponegocio: '',
  etapa: '',
  regla: '',
}
  // let today= new Date()
  // let month= today.getMonth()+1;
  // let day= today.getDate();
  // if(day<10){
  //   var dayFormat= `0${day}`;
  // }
  // if(month<10){
  //   var monthFormat=`0${month}`;
  // }
  // const fechaapertura = today.getFullYear() + '-' + monthFormat + '-'+dayFormat;
  ;
type ModalProps = {
  open: boolean,
  setOpen: any,
  dataRule: any,
  CampaignEdit?: any,
  setCampaingEdit?: any,
  setDataCampaing?: any,
  dataCampaing?: any,
  renderCampaign?: () => void,
  // ViewAddLeadsForm?:()=>void,
}
export const ModalAddCampaign: React.FC<any> = (
  props: any
): JSX.Element => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectRule, setSelectRule] = useState<boolean>(false);
  const [selectRuleLeads, setSelectRuleLeads] = useState<any>(0);
  const [arrayRutsClient, setArrayRutsClients] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [respCampaign, setrespCampaign] = useState<any>();
  const [respEmpresa, setRespEmpresa] = useState<any>();
  const [openTool, setOpenTool] = useState<boolean>(false);
  const [inputsLeads, setInputsLeads] = useState<any>();
  const [channelLeads, setChannelLeads] = useState<any>();
  const [fixedOptions, setFixedOptions] = useState<any>()
  const [dataLeadsInput, setDataLeadsInput] = useState<any>([]);
  const [dataLeadsChannel, setDataLeadsChannel] = useState<any>([])
  const token = readLocalStorage(KEY_SOFTNET);
  const [respLink, setResLink] = useState<any>([])
  const [statustype, setStatusType] = useState<any>(null)
  const [statusGeneral, setStatusGeneral] = useState<boolean>(false)
  const [listStatusCheck, setListStatusCheck] = useState<boolean>(true)
  const [snackBarConfig, setSnackBarConfig] = useState<any>({
    open: false,
    severity: 'success',
    message: 'Error',
    autoHideDuration: 5000,
  })
  const [urlTemplate, setUrlTemplate] = useState<string>('');



  const useStyles = makeStyles({
    customDisable: {
      "& .MuiInputBase-input.Mui-disabled": {
        color: "red !important",
        "-webkit-text-fill-color": "black !important",
        borderColor: "red !important"
      },
    }
  });
  const classes = useStyles();

  const {
    nombre,
    cantidad,
    utilidad,
    productos,
    fechaapertura,
    fechacierre,
    tiponegocio,
    etapa,
    regla,
  } = formValues

  useEffect(() => {

    if (props.CampaignEdit) {
      setFormValues({
        ...props.CampaignEdit,
        fechaapertura: props.CampaignEdit.fecha_inicio,
        fechacierre: props.CampaignEdit.fecha_cierre,
        utilidad: props.CampaignEdit.utilidad_estimada,
        productos: props.CampaignEdit.productos_estimados
      })
      setSelectRule(true)
    }
    else {
      setFormValues(initialFormValues);
      getChannel()
      getInputs()
      getUrlTemplate()
    }
  }, [props.CampaignEdit])


  async function getChannel() {
    const resp = await bypassService.getChannel()
    setDataLeadsChannel(resp)
  }

  async function getUrlTemplate() {
   const resp = await TemplateWsService.getUrlTemplate()
   if (resp?.code === 200) {
      if (resp?.data?.detail?.url_landing_page) {
         setUrlTemplate(resp?.data?.detail?.url_landing_page)
      }
   }
}

  async function getInputs() {
    const resp = await bypassService.getInputs()
    setDataLeadsInput(resp ? resp : [])
    const fixed = resp.filter(data => data.name == 'nombre' || data.name == 'correo')
    setFixedOptions(fixed);
    setInputsLeads(fixed)
  }

  const ValidateCampaignGeneral = () => {
    setListStatusCheck(true)
    if (statusGeneral) {
      if (!nombre) {
        setError("nombre");
      } else {
        setError("")
        setListStatusCheck(false)
        setSelectRule(true);
        setSelectRuleLeads(1)

      }
    }
    else {
      if (!nombre) {
        setError("nombre");
      } else {
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          severity: 'warning',
          message: 'Para poder proceder a la creación de la campaña debe de seleccionar una Regla de Negocio',
        }));
        setListStatusCheck(true)
      }

    }

  }

  // obtengo los productos y seteo un array de ruts
  const ObtenerRUT = async () => {
    if (!nombre) {
      return setError("nombre");
    }
    if (!regla) {
      return setError("regla")
    }
    else {

      setLoading(true);
      const reglaSelect: any = regla
      let FechaIncial = moment(reglaSelect.fechainicio).format('YYYY-MM-DD')
      let FechaFinal = moment(reglaSelect.fechafin).format('YYYY-MM-DD');

      let arrayRuts = [];
      let arrayProducts = []
      let resp;
      let tipo = '';

      console.log(reglaSelect)
      if (reglaSelect.productosrelacionados && reglaSelect.productoscomprados) {
        const proComprados = JSON.parse(reglaSelect.productoscomprados)
        proComprados.map((value) => {
          arrayProducts.push(value.codigo)
        })
        tipo = 'relacionados'
      }
      else if (reglaSelect.productoscomprados && reglaSelect.productoscomplementarios) {
        const proComprados = JSON.parse(reglaSelect.productoscomprados)
        proComprados.map((value) => {
          arrayProducts.push(value.codigo)
        })
        tipo = 'complementarios'
      }
      else {
        const proComprados = JSON.parse(reglaSelect.productoscomprados)
        proComprados.map((value) => {
          arrayProducts.push(value.codigo)
        })
        console.log(arrayProducts);
        tipo = 'comprados'
      }

      if (tipo == 'relacionados' || tipo == 'complementarios' || tipo == 'comprados') {
        console.log(FechaIncial)
        console.log(FechaFinal)
        while (FechaIncial < FechaFinal) {
          console.log('entro')
          let periodo = moment(FechaIncial).format('YYYY')
          let mes = moment(FechaIncial).format('MM')
          console.log(arrayProducts);
          for (let i = 0; i < arrayProducts.length; i++) {

            resp = await campaingService.dataClientesByProduct(arrayProducts[i], {
              periodo: periodo,
              mes: mes
            })
            resp.map((value) => {
              arrayRuts.push(value.rut_c)
            })
          }

          FechaIncial = moment(FechaIncial).add(1, 'M').format('YYYY-MM-DD')

        }

        if (arrayRuts.length == 0) {
          //aca se corta y le impido generar la campaña
          // props.setOpen(false)
          setError("")
          setSelectRule(true);
          setSelectRuleLeads(1)

        } else {
          let result = arrayRuts.filter((item, index) => {
            return arrayRuts.indexOf(item) === index;
          })

          const resultArray = JSON.stringify(result)
          setArrayRutsClients(resultArray)
          setSelectRule(true)
        }
        setListStatusCheck(false);
        setLoading(false)


      } else {
        // props.setOpen(false)
        setError("")
        setSelectRule(true);
        setSelectRuleLeads(1)
        // setFormValues(initialFormValues);
      }
    }

  }


  const GenerarLeadsLink = async (e) => {
    e.preventDefault();
    setSelectRuleLeads(3);
    setSelectRule(false)

    let arrayInputs = [];
    inputsLeads.map((value) => {
      arrayInputs.push(value.id)
    })

    let arrayChannel = [];
    channelLeads.map((value) => {
      arrayChannel.push(value.id)
    })


    const arrayJsonInputs = JSON.stringify(arrayInputs)
    const arrayJsonChannel = JSON.stringify(arrayChannel)


    //falta la gurdar la resp de la campaña para obtener el nombre

    const resp = await bypassService.postCrearCampaña(respCampaign.nombre, arrayJsonInputs, arrayJsonChannel, respEmpresa,'',urlTemplate)

    setResLink(resp)

    // aca proceso los array los mando a la peticion de generar leads
    // obtengo la respuesta los link
    // los seteeo a una constante para recuperarlos sobre los inputs

  }

  const GenerarLeads = async (e) => {
    e.preventDefault();
    setLoading(false)
    let now = moment().format('YYYY-MM-DD')
    // despues de generar se pondra en false
    if (!nombre) { return setError("nombre"); }
    if (!cantidad) { return setError("cantidad"); }
    if (!utilidad) { return setError("utilidad"); }
    if (!productos) { return setError("productos"); }
    if (!regla) { return setError("regla"); }
    if (!fechaapertura) { return setError("fechaapertura"); }
    if (moment(fechaapertura).format('YYYY-MM-DD') < now) { return setError("rangoA"); }
    if (!fechacierre) { return setError("fechacierre"); }
    if (moment(fechacierre) < moment(fechaapertura)) { return setError("rangoC"); }
    else {
      setLoading2(true);
      const usuario = readLocalStorage(KEY_USER_DATA)
      const reglaId: any = regla
      const data = {
        ...formValues,
        regla: reglaId.id,
        idempresa: usuario.user.idusuario,
        propietario: usuario.user.rut_empresa,
        array_rut: null
      }
      try {
        const resp = await campaingService.createCampaign(data);
        if (resp.message == 'OK') {
          saveLocalStorage(DATA_CAMPAING_LEADS, resp.data);
          props.$action.actionSetDataCampana(resp.data)
          history.push(ROUTE_CAMPAING_LEADS_FORM)
          setrespCampaign(resp.data)
          props.setOpen(false)
          setSelectRuleLeads(0)
          setSelectRule(false)
          setLoading2(false);
          setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Campaña creada con éxito',
          }));
        } else {
          setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'Error en la creación de la campaña',
          }));
          setSelectRule(false)
          setSelectRuleLeads(1)
        }
      } catch (error) {
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          severity: 'error',
          message: 'Error en la creación de la campaña',
        }));
        setSelectRule(false)
        setSelectRuleLeads(1)
      }
    }
  }

  const GenerarCampaignGeneral = async (e) => {
    e.preventDefault();
    setLoading(false)
    let now = moment().format('YYYY-MM-DD')
    // despues de generar se pondra en false
    if (!nombre) { return setError("nombre"); }
    //  if (!cantidad) {return setError("cantidad");}
    //  if (!utilidad) {return setError("utilidad");}
    //  if (!productos) {return setError("productos");}
    if (!fechaapertura) { return setError("fechaapertura"); }
    if (moment(fechaapertura).format('YYYY-MM-DD') < now) { return setError("rangoA"); }
    else {
      setLoading2(true);
      const usuario = readLocalStorage(KEY_USER_DATA)
      const reglaId: any = regla
      const data = {
        ...formValues,
        //fechaapertura: fechaapertura,
        regla: null,
        idempresa: usuario.user.idusuario,
        propietario: usuario.user.rut_empresa,
        array_rut: null
      }
      try {
        const resp = await campaingService.createCampaign(data);
        if (resp.message == 'OK') {
          saveLocalStorage(DATA_CAMPAING_LEADS, resp.data);
          props.$action.actionSetDataCampana(resp.data)
          history.push(ROUTE_CAMPAING_LEADS_FORM)
          setrespCampaign(resp.data)
          props.setOpen(false)
          setSelectRuleLeads(0)
          setSelectRule(false)
          setLoading2(false);
          setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Campaña creada con éxito',
          }));
        } else {
          setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'Error en la creación de la campaña',
          }));
          setSelectRule(false)
          props.setOpen(false);
          setSelectRuleLeads(1)
        }
      } catch (error) {
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          severity: 'error',
          message: 'Error en la creación de la campaña',
        }));
        setSelectRule(false)
        props.setOpen(false);
        setSelectRuleLeads(1)
      }
    }
  }

  const handleFormValues = (e) => {
    const handleChangeFormValues = {
      ...formValues,
      [e.target.name]: e.target.value
    }
    handleChangeFormValues.nombre.trim() !== "" && setError("");
    if (parseInt(cantidad) > 0) { setError('') }
    if (parseInt(utilidad) > 0) { setError('') }
    if (parseInt(productos) > 0) { setError('') }
    //handleChangeFormValues.cantidad.trim() !== "" && setError("");
    //handleChangeFormValues.utilidad.trim() !== "" && setError("");
    //handleChangeFormValues.productos.trim() !== "" && setError("");
    // handleChangeFormValues.tiponegocio.trim() !== "" && setError("");
    //handleChangeFormValues.regla.trim() !== "" && setError("");
    // handleChangeFormValues.fechaapertura.trim() !== "" && setError("");
    handleChangeFormValues.fechacierre.trim() !== "" && setError("");
    // handleChangeFormValues.preciooferta.trim() !== "" && setError("");
    //handleChangeFormValues.etapa.trim() !== "" && setError("");
    setFormValues(handleChangeFormValues)
    if (e.target.name == 'regla') {
      //  console.log(e.target.value)
      const Regla = props.dataRule.filter((row) => row.id == e.target.value.id)
      setStatusType(Regla[0].idtipoRegla)
    }

  }
  const handleForm = async (e) => {
    e.preventDefault();
    if (props.CampaignEdit) {
      const dataEdit = {
        nombre: nombre,
        cantidad: cantidad,
        fecha_inicio: fechaapertura,
        fecha_cierre: fechacierre,
        productos_estimados: productos,
        status: '1',
        rut_propietario: props.CampaignEdit.rut_propietario,
      }
      const editcampaing = props.dataCampaing.map((campaing) =>
        campaing.id === props.CampaignEdit.id ? dataEdit : campaing
      );
      props.setDataCampaing(editcampaing);
      const resp = await campaingService.updateCampaign(props.CampaignEdit.id, formValues)
      if (resp.data) {
        setFormValues(initialFormValues);
        props.setCampaingEdit(null);
        setSelectRule(false);
        props.setOpen(false);
      }
      if (props.renderCampaign) {
        props.renderCampaign()
      }
    }
    else {
      var now = moment().format('YYYY-MM-DD')
      if (!nombre) { return setError("nombre"); }
      if (!cantidad) { return setError("cantidad"); }
      if (parseInt(cantidad) <= -1) { return setError('menor0') }
      if (!utilidad) { return setError("utilidad"); }
      if (parseInt(utilidad) <= -1) { return setError('menor0U') }
      if (!regla) { return setError("regla"); }
      if (!productos) { return setError("productos"); }
      if (parseInt(productos) <= -1) { return setError('menor0P') }
      if (!fechaapertura) { return setError("fechaapertura"); }
      if (moment(fechaapertura).format('YYYY-MM-DD') < now) { return setError("rangoA"); }
      if (!fechacierre) { return setError("fechacierre"); }
      if (moment(fechacierre) < moment(fechaapertura)) { return setError("rangoC"); }
      else {
        const usuario = readLocalStorage(KEY_USER_DATA)
        const reglaId: any = regla
        const data = {
          ...formValues,
          regla: reglaId.id,
          idempresa: usuario.user.idusuario,
          propietario: usuario.user.rut_empresa,
          array_rut: arrayRutsClient
        }
        try {
          const resp = await campaingService.createCampaign(data);
          if (resp.message == 'OK') {
            setFormValues(initialFormValues);
            props.setOpen(false);
            if (props.renderCampaign) {
              props.renderCampaign()
            }
            setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              severity: 'success',
              message: 'Campaña creada con éxito',
            }));
          } else {
            setSnackBarConfig(prev => ({
              ...prev,
              open: true,
              severity: 'error',
              message: 'Error en la creación de la campaña',
            }));
            setSelectRule(false)
            props.setOpen(false);
            setSelectRuleLeads(1)
          }
        } catch (error) {
          setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'Error en la creación de la campaña',
          }));
          setSelectRule(false)
          setSelectRuleLeads(1)
          props.setOpen(false);
        }
      }
      setFormValues(initialFormValues);
      props.setOpen(false);
      setSelectRuleLeads(0)
    }
  }


  const bodyModalGenerarLead = (
    <>
      <Grid item xs={12}  >
        <Grid item >
          <Autocomplete
            multiple
            fullWidth
            id="tags-outlined"
            size='small'
            sx={{ background: '#fff' }}
            options={dataLeadsInput}
            getOptionLabel={(option: any) => option.name ? option.name : ''}
            filterSelectedOptions
            value={inputsLeads}
            onChange={(e, newValue) =>
              setInputsLeads([
                ...fixedOptions,
                ...newValue.filter((value) => fixedOptions.indexOf(value) === -1)
              ])}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  key={index}
                  label={option.name}
                  {...getTagProps({ index })}
                  disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ingrese los campos para el formulario"
                placeholder=""

              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}  >
        <Grid item >
          <Autocomplete
            multiple
            fullWidth
            id="tags-outlined"
            size='small'
            sx={{ background: '#fff' }}
            options={dataLeadsChannel}
            getOptionLabel={(option: any) => option.name ? option.name : ''}
            filterSelectedOptions
            value={channelLeads}
            onChange={(e, newValue) =>
              setChannelLeads(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filtre los canales que deseas"
                placeholder=""

              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )

  const bodyLinksGenerados = (
    <>
      {
        respLink.map((value, i) => {
          return (

            <Grid item xs={12} key={i}>
              <Typography>{value.channel}</Typography>
              <CopyToClipboard text={value.link}
                onCopy={() => { }}>
                <TextField
                  value={value.link}
                  fullWidth
                  size='small'
                  sx={{ bgcolor: '#E3F4FD' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Copiar" placement="top">
                          <IconButton >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </CopyToClipboard>

            </Grid>
          )
        })
      }
    </>
  )

  const bodyModal = (
    <Box className='Modal'>
      <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
        {props.CampaignEdit ? 'Editar Campaña' : 'Crear Campaña'}
      </Typography>
      <Box component='form' onSubmit={handleForm}>
        <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} >
          <Grid item xs={12}>
            <TextField
              sx={{ bgcolor: '#fff' }}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <CampaignIcon />
                  </InputAdornment>
                ),
              }}
              error={error === "nombre" ? true : false}
              helperText={error === "nombre" ? "Nombre es es un campo requerido" : ""}
              id="nombre"
              className={classes.customDisable}
              //label="Nombre"
              placeholder='Nombre de la Campaña'
              name='nombre'
              disabled={selectRuleLeads == 3 ? true : false}
              value={nombre}
              fullWidth
              onChange={handleFormValues}
            />
          </Grid>
          {!props.CampaignEdit ?
            <Grid item xs={12} >
              {!statusGeneral && <FormControl
                fullWidth>
                <InputLabel id="demo-simple-select-label">Regla de negocio</InputLabel>
                <Select
                  label="Regla de Negocio"
                  error={error === "regla" ? true : false}
                  disabled={selectRule || selectRuleLeads == 2 || selectRuleLeads == 3 ? true : false}
                  className={classes.customDisable}
                  id="regla"
                  onChange={handleFormValues}
                  startAdornment={
                    <InputAdornment position="start">
                      <RuleIcon />
                    </InputAdornment>
                  }
                  value={regla}
                  name='regla'
                  sx={{ bgcolor: '#fff' }}
                  size='small'
                >
                  {props.dataRule.map((option, i) => {
                    return <MenuItem key={i} value={option}>{option.nombre}</MenuItem>
                  })
                  }
                </Select>
              </FormControl>}

              {listStatusCheck && <Grid item container justifyContent='end' alignItems='center'>
                <span>Crear campaña general</span>
                <Checkbox checked={statusGeneral} onClick={() => {
                  setStatusGeneral(!statusGeneral),
                    setFormValues(prev => ({ ...prev, regla: '' }))
                  setStatusType(null)
                }} />
              </Grid>}
            </Grid>
            : (<></>)}
          {selectRule ?
            <>
              <Grid item xs={12} md={6}  >
                <TextField
                  type="number"
                  sx={{ bgcolor: '#fff' }}
                  size='small'
                  error={error === "cantidad" ? true : error == 'menor0' ? true : false}
                  helperText={error === "cantidad" ? "Cantidad es es un campo requerido" : error == 'menor0' ? 'Cantidad debe ser mayor a 0  para ser válido' : ''}
                  id="cantidad"
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <PaidIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder='Cantidad a invertir'
                  label='Cantidad a Invertir'
                  name='cantidad'
                  value={cantidad}
                  fullWidth
                  onChange={handleFormValues}
                />
              </Grid>
              <Grid item xs={12} md={6} >
                <TextField
                  type="number"
                  sx={{ bgcolor: '#fff' }}
                  size='small'
                  error={error === "utilidad" ? true : error == 'menor0U' ? true : false}
                  helperText={error === "utilidad" ? "Utilidad esperada es un campo requerido" : error == 'menor0U' ? 'Utilidad debe ser mayor a 0  para ser válido' : ''}
                  id="utilidad"
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <PaidIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder='Utilidad esperada'
                  label='Utilidad esperada'
                  name='utilidad'
                  value={utilidad}
                  fullWidth
                  onChange={handleFormValues}
                />
              </Grid>
              <Grid item xs={12}  >
                <TextField
                  type="number"
                  sx={{ bgcolor: '#fff' }}
                  size='small'
                  error={error === "productos" ? true : error == 'menor0P' ? true : false}
                  helperText={error === "productos" ? "Cantidad de venta de productos esperada es es un campo requerido" : error == 'menor0P' ? 'Cantidad de Ventas productos debe ser mayor a 0  para ser válido' : ''}
                  id="productos"
                  InputProps={{
                    inputProps: { min: 0 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <ProductionQuantityLimitsIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder='Cantidad de productos que espera vender'
                  name='productos'
                  value={productos}
                  fullWidth
                  onChange={handleFormValues}
                />
              </Grid>
              <Grid item xs={12} md={6} >
                <TextField
                  // disabled
                  sx={{ bgcolor: '#fff', mt: 1 }}
                  size='small'
                  error={error === "fechaapertura" ? true : error === "rangoA" ? true : false}
                  helperText={error === "fechaapertura" ? "Fecha Apertura es es un campo requerido" : error === "rangoA" ? "Fecha Apertura debe ser mayor a la fecha actual" : ''}
                  id="fechaapertura"
                  // type='date'
                  name='fechaapertura'
                  type='date'
                  value={fechaapertura}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: true }}
                  label="Fecha de Apertura"
                  onChange={handleFormValues}
                />
              </Grid>
              {!statusGeneral && <Grid item xs={12} md={6} >
                <TextField
                  size='small'
                  error={error === "fechacierre" ? true : error === "rangoC" ? true : false}
                  helperText={error === "fechacierre" ? "Fecha Cierre es es un campo requerido" : error === "rangoC" ? "Fecha Cierre debe ser mayor a Fecha apertura" : ''}
                  id="fechacierre"
                  type='date'
                  name='fechacierre'
                  value={fechacierre}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: true }}
                  label="Fecha de Cierre"
                  onChange={handleFormValues}
                  sx={{ bgcolor: '#fff', mt: 1 }}
                />
              </Grid>}

            </>
            : (<>
              {
                selectRuleLeads == 2 ?
                  <>
                    {/* {bodyModalGenerarLead
          //se muestra el modal de generar leads

          }  */}
                    <LeadsFormView />
                  </>
                  : <></>
              }
              {
                selectRuleLeads == 3 ?
                  <>
                    {bodyLinksGenerados}
                  </>
                  : <></>
              }
            </>)
          }

        </Grid>
        <Divider />
        <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
          {
            selectRuleLeads == 3 ?
              (
                <>
                </>
              ) : (
                <Grid item >
                  <Button
                    variant='contained'
                    color='error'
                    //disabled={loading}
                    onClick={() => {
                      props.setOpen(false);
                      setSelectRule(false);
                      setFormValues(initialFormValues);
                      setError("")
                      setSelectRuleLeads(0)
                      setLoading(false)
                      setListStatusCheck(true)
                      setStatusGeneral(false)
                      setLoading(false)
                      setStatusType(null)
                      props.setCampaingEdit ? (props.setCampaingEdit(null)) : ''

                    }}
                    startIcon={<CancelIcon />}
                    sx={{
                      background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                        bgcolor: '#bf6c00'
                      }
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              )
          }
          {selectRule ? (
            <>
              {selectRuleLeads == 1 ?
                <>
                  <Grid item>
                    {statusGeneral ? (
                      <Box sx={{ position: 'relative' }}>
                        <Button
                          fullWidth={true}
                          variant='contained'
                          onClick={GenerarCampaignGeneral}
                          disabled={loading2}
                          startIcon={props.CampaignEdit ? <EditIcon /> : <SaveIcon />}
                          disableElevation
                          sx={{
                            background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                              bgcolor: '#155172'
                            }
                          }}
                        >
                          <span>{props.CampaignEdit ? 'Editar' : 'Generar Campaña General'}</span>
                        </Button>
                        {loading2 && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Box>
                    ) :
                      (
                        <Box sx={{ position: 'relative' }}>
                          <Button
                            fullWidth={true}
                            variant='contained'
                            onClick={GenerarLeads}
                            disabled={loading2}
                            startIcon={props.CampaignEdit ? <EditIcon /> : <SaveIcon />}
                            disableElevation
                            sx={{
                              background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                                bgcolor: '#155172'
                              }
                            }}
                          >
                            <span>{props.CampaignEdit ? 'Editar' : 'Generar Leads'}</span>
                          </Button>
                          {loading2 && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Box>
                      )}
                  </Grid>
                </> : <>
                  <Grid item>
                    <Box sx={{ position: 'relative' }}>
                      <Button
                        fullWidth={true}
                        variant='contained'
                        type='submit'
                        disabled={loading2}
                        startIcon={props.CampaignEdit ? <EditIcon /> : <SaveIcon />}
                        disableElevation
                        sx={{
                          background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                            bgcolor: '#155172'
                          }
                        }}
                      >
                        <span>{props.CampaignEdit ? 'Editar' : 'Guardar'}</span>
                      </Button>
                      {loading2 && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                    {/* <Button
                variant='contained'
                type='submit'
                startIcon={props.CampaignEdit?<EditIcon/>:<SaveIcon/>}
              >
                {props.CampaignEdit?'Editar':'Guardar'}
              </Button> */}
                  </Grid>
                </>}

            </>
          ) : (<>
            {selectRuleLeads == 2 ?
              <>
                <Grid item>
                  <Button
                    variant='contained'
                    onClick={GenerarLeadsLink}
                    startIcon={props.CampaignEdit ? <EditIcon /> : <SaveIcon />}
                    sx={{
                      background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                        bgcolor: '#155172'
                      }
                    }}
                  >
                    {props.CampaignEdit ? 'Editar' : 'Generar Link'}
                  </Button>
                </Grid>
              </>
              : <></>}

            {selectRuleLeads == 3 ?
              <>
                <Grid item>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setError("");
                      setSelectRule(false);
                      setSelectRuleLeads(0);
                      setFormValues(initialFormValues)
                      props.setOpen(false)
                      if (props.renderCampaign) {
                        props.renderCampaign()
                      }
                    }
                    }

                    startIcon={props.CampaignEdit ? <EditIcon /> : <SaveIcon />}
                    sx={{
                      background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                        bgcolor: '#155172'
                      }
                    }}
                  >
                    {props.CampaignEdit ? 'Editar' : 'OK'}
                  </Button>
                </Grid>
              </>
              : <></>}

            {
              selectRuleLeads == 2 || selectRuleLeads == 3 ?
                <>
                </> :
                <>
                  <Grid item>
                    <Box sx={{ position: 'relative' }}>
                      <Button
                        fullWidth={true}
                        variant='contained'
                        onClick={() => {
                          console.log(statustype)
                          // if (statustype == 2) {
                          //   ObtenerRUT()
                          // } if (statustype == 1) {
                          //   ObtenerRUT()
                          // }
                          if (statustype == null) {
                            ValidateCampaignGeneral();
                          } else {
                            ObtenerRUT()
                          }
                        }}
                        disabled={loading}
                        startIcon={props.CampaignEdit ? <EditIcon /> : <SaveIcon />}
                        disableElevation
                        sx={{
                          background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                            bgcolor: '#155172'
                          }
                        }}
                      >
                        <span>{loading ? 'Procesando' : 'Procesar'}</span>
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </>
            }

          </>)}
        </Grid>
      </Box>
    </Box>
  )



  return (
    <div>
      <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
}


const mapStateToProps = ({ CampanaReducer }) => ({
  $store: {
    CampanaReducer,
  },
});
const mapDispatchToProps = (dispatch) => ({
  $action: bindActionCreators(
    {
      actionSetDataCampana: actionSetDataCampana
    },
    dispatch
  ),
});


export const ModalNewCampaign: any = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ModalAddCampaign);
