import  React,{useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider,FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, IconButton, Tooltip} from '@mui/material';
import { Box } from '@mui/system';
import {SaveIcon,CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecommendIcon from '@mui/icons-material/Recommend';
import { ROUTE_CAMPAIGN } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom'


type ModalProps = {
  open: boolean,
  direccionamiento?: boolean,
  setOpen:any,
  data?:any,
  formLeads?:any
}
export const ModalViewURL:React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element =>{

   const initialFormValues ={
      nombre:''
   }


   const [dataUserStorage] = useLocalStorage(KEY_USER_DATA, undefined);
   const history = useHistory();


  useEffect(() => {
   const data = readLocalStorage(KEY_USER_DATA)
   if(props.open==true){

   }

   }, [props.open])

  // obtengo los productos y seteo un array de ruts
  const handleDireccionamiento = () =>{
   // console.log(props.direccionamiento)
    if(props.direccionamiento)
    {
      // console.log('direccion')
      history.push(ROUTE_CAMPAIGN);
    }else{
      // console.log('no direccion')
      props.setOpen(false);
    }
  }

  const bodyLinksGenerados = (
   <>
   {
      props.data?.map(( value, i)=>{
        return(

           <Grid item xs={12}>
              <Typography sx={{color:'#155172', fontWeight:'750', fontSize:'1.3em'}}>{value.channel}</Typography>
              <CopyToClipboard text={value.link}
                 onCopy={()=>{}}>
                    <TextField
                    value={value.link}
                    fullWidth
                    size='small'
                    sx={{bgcolor:'#E3F4FD'}}
                    InputProps={{
                       endAdornment: (
                          <InputAdornment position="end">
                             <Tooltip title="Copiar" placement="top">
                                <IconButton >
                                   <ContentCopyIcon />
                                </IconButton>
                             </Tooltip>
                          </InputAdornment>
                       ),
                    }}
                    />
              </CopyToClipboard>

           </Grid>
        )
      })
   }
   </>
  )



  const bodyModal = (
    <Box className='Modal'>
       <Box >
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC', fontWeight:'700', fontSize:'1.8em'}}>
         URLS LEADS
         </Typography>
        <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} >
          <Grid item xs={12}>
            {bodyLinksGenerados}
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
          <Grid item >
            <Button
              variant='contained'
              color='primary'
              onClick={() => {handleDireccionamiento()}}
            sx={{background:'#155172', mt: "10px",color:'#fff', '&:hover':{
              bgcolor:'#155172'
             }}}
              endIcon={<RecommendIcon/>}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )



  return (
    <div>
      <Modal
        open={props.open}
        // onClose={() => {props.setOpen(false)

        //  //setFormValues(initialFormValues);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
}
