import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const configurationRepository = {
   getConfiguration: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const configuration = await http.get<any>(`${API_URL_BASE}/v1/configuration?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = configuration
      return {
         data,
         // (data||[]).map((dt) => ({
         //    nombre: dt.nombre,
         //    codigo: dt.codigo,
         //    precio_oferta: dt.precio_oferta,
         //    sub_familia:dt.sub_familia,
         //    familia:dt.familia,
         //    stock:dt.stock
         // })),
         error,
         message
      };
   },

   getConfigCampana: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const configCampana = await http.get<any>(`${API_URL_BASE}/v1/configCampaign?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = configCampana
      return {
         data,
         error,
         message
      };
   },

   updateConfigCampana: async (id: number, data: any) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const configCampana = await http.patch<any>(`${API_URL_BASE}/v1/configCampaign/idconfig/${id}?id_empresa=${id_empresa}`, {
         idtipofeeds: data.idtipofeeds,
         hora_inicio_t1: data.hora_incio_t1,
         hora_fin_t1: data.hora_fin_t1,
         hora_inicio_t2: data.hora_incio_t2,
         hora_fin_t2:data.hora_fin_t2,
         status:1,
      });

      return configCampana;
   },

   changeStatusConfigCampana: async (id: number, estado) : Promise<any> => {
      const configCampanaStatus = await http.patch<any>(`${API_URL_BASE}/v1/configCampaign/status/${id}`, {
         status:estado,
      });

      return configCampanaStatus;
   },


   updateConfiguration: async (id: number, data: any) : Promise<any> => {
      const configurationEdit = await http.patch<any>(`${API_URL_BASE}/v1/configuration/${id}`, {
         sync_time: data.sync_time,
      });

      return configurationEdit;
   },

   changeStatusCampaingGenerales: async (data) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/campaign/actualizarCampanasCanales`, {
         idCanales: data,
         id_empresa:id_empresa
      });

      return resp;
   },

   ListStatusCampaingGenerales: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/campaign/campanasCanales?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      return resp;
   },

   asignacionSupervisor: async (dataSupervisor) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/supervisor/asignacion`, {dataSupervisor:dataSupervisor, id_empresa});
      return resp;
   },

   changeAsignacion: async (asignacion) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/supervisor/statusAsignacion?asignacion=${asignacion}&id_empresa=${id_empresa}`);
      return resp;
   },

   statusAsignacion: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/supervisor/statusAsignacion?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      return resp;
   },

   getSettingWsEntity: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/chats/setting-ws?idempresacliente=${id_empresa}`);
      return resp;
   },

   updateSettingWsEntity: async (data) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/chats/setting-ws`, {
         ...data,
         idempresacliente:id_empresa
      });

      return resp;
   },

}

