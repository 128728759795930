import  React,{useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider,FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, IconButton, Tooltip} from '@mui/material';
import { Box } from '@mui/system';
import {SaveIcon,CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecommendIcon from '@mui/icons-material/Recommend';
import { ROUTE_CAMPAIGN } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom'
import { API_URL_BASE_FRONT } from '@/toolbox/defaults/app';


type ModalProps = {
  open: boolean,
  setOpen:any,
  data?:any,
  setData?:any,
  urlTemplate?: string
}
export const ModalUri: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element =>{

   const [URI, setURI] = useState<any>('view_leads_form');

   const handleInputChange = () => {
    props.setData(URI);
    props.setOpen(false);
    }

  const bodyModal = (
    <Box className='Modal'>
       <Box>
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC', fontWeight:'500', fontSize:'1.8em'}}>
         Personalizar url leads
         </Typography>
        <Grid container direction="row" justifyContent={'center'} alignItems='center' sx={{ mt: 1, mb: 2 }} >
            <span style={{background:'#fff', padding:'12px', borderRadius:'10px'}}>{`${props?.urlTemplate}`}</span>
            <TextField
               // fullWidth
               sx={{ bgcolor: '#fff' }}
               size="small"
               id="comentarios"
               inputProps={{
                  maxLength: 200,
               }}
               name="comentarios"
               value={URI}
               onChange={(e)=>setURI(e.target.value)}
            />
      </Grid>
        <Divider />
        <Grid container direction='row' justifyContent='flex-end' alignContent='center' >
          <Grid item >
            <Button
              variant='contained'
              color='primary'
              onClick={() => {handleInputChange()}}
            sx={{background:'#155172', mt: "10px",color:'#fff', '&:hover':{
              bgcolor:'#155172'
             }}}
              endIcon={<RecommendIcon/>}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )



  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {props.setOpen(false)}}
      >
        {bodyModal}
      </Modal>
    </div>
  );
}
