import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './ChatsWsProspect.type';
import { Alert, Box, Button, Grid, IconButton, InputBase, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { chatService } from '@service/services/Chat.service';
import ChatItem from './components/ChatItem';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import '@assets/styles/kyte-app.css';
import '@assets/styles/kyte-app.scss';
import SendIcon from '@mui/icons-material/Send';
import { ModalProspectoRapidoWS } from '@/components/common/Modal/ModalProspectoRapidoWS';
import ImageIcon from '@mui/icons-material/Image';
import ModalUploadImage from './components/ModalUploadImage';

const Item = styled(Paper)(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
   color: theme.palette.text.primary,
}));

type Chats = {
   id: number,
   id_ws_prospect: number,
   ws_p_telefono: string,
   idchat_type: number,
   chatTypeName: string,
   status: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}

type ContentChats = {
   iddetail_chat: number,
   idchats: number,
   idusuario: number | null,
   idsend_type: number,
   send_date: number | null,
   name_user: string | null,
   id_ws_prospect: number,
   idchat_type: number,
   sendTypeName: string,
   message: string,
   sendHeader:  string | null,
   imgHeader:  string | null,
   sendFooter:  string | null,
   viewed: string,
   codeWS: string,
   textIdWs: string,
   textIdWsReference: string | null,
}

type SnackBar = {
   open: boolean,
   severity: any,
   message: string,
   autoHideDuration: number,
}

export const ChatsWsProspectView: React.FC<Props> = (props: Props): JSX.Element => {

   const [chats, setChats] = useState<Chats[]>([]);
   const [chatsContents, setChatsContents] = useState<ContentChats[]>([]);
   const [selectChat, setSelectChat] = useState<Chats>({
      id: 0,
      id_ws_prospect: 0,
      ws_p_telefono: '',
      idchat_type: 0,
      chatTypeName: '',
      status: ''
   });
   const dataUser = readLocalStorage(KEY_USER_DATA);
   const [newMesaage, setNewMessage] = useState<string>('')
   const [snackBarConfig, setSnackBarConfig] = useState<SnackBar>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })
   const [renderChats, setRenderChats] =  useState<number>(0);
   const [selectNumber, setSelectNumber] =  useState<number>(0);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [showPreviewImage, setShowPreviewImage] = useState<boolean>(false);
   const [saveImageChat, setSaveImageChat] = useState<any>({
      name: '',
      path: '',
      data: null,
      preview: null
   });

   useEffect(() => {
      if (renderChats === 0) {
         getChatsVigents()
      }
      if (renderChats === 1) {
         getChatsByUsers()
      }
   }, [renderChats])


   const getChatsVigents = async () => {
      const resp: any = await chatService.getChatsVigents();
      if (resp?.code === 200) {
         resp?.data?.detail && setChats(resp?.data?.detail)
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const getChatsByUsers = async () => {
      const resp: any = await chatService.getChatsByUsers();
      if (resp?.code === 200) {
         resp?.data?.detail && setChats(resp?.data?.detail)
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const handleClickChat = async (chat: Chats) => {
      setSelectChat(chat);
      const resp: any = await chatService.getDetailChats(chat?.id);
      if (resp?.code === 200) {
         resp?.data?.detail && setChatsContents(resp?.data?.detail)
      } else {
         setChatsContents([])
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
      }
   }

   const handleSendMessage = async () => {
      if (newMesaage && selectChat?.id) {
         const resp: any = await chatService.sendMessage(selectChat?.id, { idsend_type: 1, send: newMesaage });
         if (resp?.code === 200) {
            resp?.data?.detail && chatsContents.push(resp?.data?.detail)
            setNewMessage('')
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   const handleSendMessageImage = async () => {
      if(!selectChat?.id){
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: 'seleccione un chat',
         }));
      }
      if (saveImageChat?.data && selectChat?.id) {
         const dataUser = readLocalStorage(KEY_USER_DATA);
         const id_usuario = dataUser?.user?.idusuario;
         const formData = new FormData();
         formData.append("idsend_type", '2');
         formData.append('imageChat',saveImageChat?.data || null);
         formData.append('send', 'null');
         formData.append('idusuario', id_usuario);
         const resp: any = await chatService.sendMessageImage(selectChat?.id, formData);
         if (resp?.code === 200) {
            resp?.data?.detail && chatsContents.push(resp?.data?.detail)
            closeModalUpload();
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   const handleParticipantChat = async () => {
      if (selectChat?.id) {
         const resp: any = await chatService.createParticipantChat({ idChat: selectChat?.id });
         if (resp?.code === 200) {
            setRenderChats(1)
            resp?.data?.detail && setSelectChat(resp?.data?.detail);
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp?.data?.message,
            }));

         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   const handleCloseChat = async () =>{
      if (selectChat?.id) {
         const resp: any = await chatService.closeChat({ idChat: selectChat?.id });
         if (resp?.code === 200) {
            setRenderChats(1)
            initialChats()
            getChatsByUsers()
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp?.data?.message,
            }));

         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      }
   }

   function processImage(event) {
      if (event && event.target.files && event.target.files.length > 0) {
        const imageFile = event.target.files[0];
        console.log(imageFile)
        const imageUrl = URL.createObjectURL(imageFile);
        setSaveImageChat(prev => ({ ...prev, data: imageFile, preview : imageUrl}))
        setShowPreviewImage(true);
      } else {
        setSaveImageChat(prev => ({ ...prev, data: null, preview : null}))
      }
    }
    const closeModalUpload = () => {
      setShowPreviewImage(false);
      setSaveImageChat(prev => ({ ...prev, data: null, preview : null}))
    }



   const handleRenderChats = (value: number) => {
      initialChats()
      setRenderChats(value)
   }

   const initialChats = () =>{
      setSelectChat({
         id: 0,
         id_ws_prospect: 0,
         ws_p_telefono: '',
         idchat_type: 0,
         chatTypeName: '',
         status: ''
      })
      setChatsContents([])
   }

   const handleTemplateWs = () => {
      const newTabUrl = `/template-ws`;
      window.open(newTabUrl, '_blank');
   }

   const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        // Insertar un salto de línea en el valor del mensaje
        setNewMessage((prevMessage) => prevMessage + '\n');
        // Prevenir el comportamiento por defecto de Enter
        event.preventDefault();
      }

      if (!event.ctrlKey && event.key === 'Enter') {
         event.preventDefault(); // Prevent default behavior
         handleSendMessage(); // Execute your custom action
       }
    };

   const handleSearchIdWsReference = (idReference) => {
      if(idReference && chatsContents.length>0){
         return chatsContents.find(item => item?.textIdWs == idReference)
      }
      return null
   }

   return (
      <Protected>
           <Grid container sx={{mb:3}}>
            <Grid item xs={6} md={2}>
               <Button fullWidth onClick={() => { handleTemplateWs() }} variant='contained' color='success'>Mis plantillas</Button>
            </Grid>
         </Grid>
         <Grid container spacing={2} bgcolor={'#f1f1f1'}>
            <Grid item xs={6} md={2}>
               <Button fullWidth onClick={() => { handleRenderChats(0) }} variant={ renderChats === 0 ? 'contained' : 'text'}>PROSPECTOS</Button>
            </Grid>
            <Grid item xs={6} md={2}>
               <Button fullWidth onClick={() => { handleRenderChats(1) }} variant={ renderChats === 1 ? 'contained' : 'text'}>CHAT</Button>
            </Grid>
            <Grid item xs={12} md={8}>
               <Item>
                  <Grid item container direction={'row'} xs={12} alignItems={'center'}>
                     <Grid item xs={12} md={8}>
                     {selectChat?.nameRzClient} ({selectChat?.ws_p_telefono})
                     </Grid>
                    { renderChats === 0 && selectChat.id > 0  && <Grid item xs={12} md={2}>
                        <Button onClick={() => { handleParticipantChat() }}>Unirme al chat</Button>
                     </Grid>}
                     { renderChats === 1 && selectChat.id > 0  && <Grid item xs={12} md={2}>
                        <Button onClick={() => { handleCloseChat() }}>Cerrar chat</Button>
                     </Grid>}

                     { renderChats === 1 && selectChat.id > 0 && selectChat?.idClient === null  && <Grid item xs={12} md={2}>
                        <Button onClick={() => { setOpenModal(true) }}>Agregar Prospecto</Button>
                     </Grid>}

                     {/* { renderChats === 1 && selectChat.id > 0 && selectChat?.idClient !== null  && <Grid item xs={12} md={2}>
                        <Button onClick={() => { setOpenModal(true) }}>Unirme a la campaña Ws</Button>
                     </Grid>} */}
                  </Grid>
               </Item>
            </Grid>
         </Grid>
         <Grid container spacing={2} bgcolor={'#f1f1f1'}>
            <Grid item xs={12} md={4}>
               {chats.length > 0 && chats.map((chat, index) =>
                  <Item key={index} onClick={() => { handleClickChat(chat) }} sx={{ m: 1, bgcolor: selectChat?.id === chat?.id ? 'red': '#f1f1f1' }}>{chat?.nameRzClient} {chat?.ws_p_telefono}</Item>
               )}
            </Grid>

            <Grid item xs={12} md={8}>
               <Grid item xs={12}>
                  <div className="main-chat-content" >
                     {chatsContents.length > 0 && chatsContents.map((chatsContent, index) =>
                        <ChatItem
                           key={index}
                           user={chatsContent?.name_user}
                           msg={chatsContent.idsend_type == 1 ? chatsContent.message : ''}
                           isMyMessage={chatsContent.idusuario == dataUser?.user?.idusuario}
                           hour={chatsContent.send_date}
                           codeWS = {chatsContent?.codeWS}
                           sendHeader = {chatsContent?.sendHeader}
                           imgHeader = {chatsContent?.imgHeader}
                           sendFooter = {chatsContent?.sendFooter}
                           referenceObj = {handleSearchIdWsReference(chatsContent?.textIdWsReference)}
                        />
                     )}
                  </div>
               </Grid>
               <Grid item xs={12}>
                  <Paper >
                     <Grid container xs={12} display="flex" justifyContent="space-between" alignItems="center">
                     <InputBase
                           sx={{ ml: 1, flex: 1 }}
                           multiline
                           disabled={renderChats === 0 || selectChat.id === 0 ? true : false}
                           value={newMesaage}
                           onChange={(e) => setNewMessage(e?.target?.value || "")}
                           placeholder="Escribe un Mensaje"
                           name='mensaje'
                           id='mensaje'
                           onKeyPress={event => { event.key === 'Enter' && handleSendMessage() }}
                           onKeyDown={handleKeyDown}
                        />
                        <Grid item xs={1} >
                           <input accept="image/*" style={{ display: 'none' }} id="upload-image" type="file" onChange={processImage}  disabled={renderChats === 0 || selectChat.id === 0 ? true : false} />
                           <label htmlFor="upload-image" >
                              <IconButton component="span" disabled={renderChats === 0 || selectChat.id === 0 ? true : false}>
                                 <ImageIcon className="chat-footer-icon" />
                              </IconButton>
                           </label>
                        </Grid>
                        <Grid item xs={1} >
                           <IconButton
                              type="button"
                              sx={{ p: "10px" }}
                              aria-label="search"
                              disabled={renderChats === 0 || selectChat.id === 0 ? true : false}
                              onClick={(e) => { handleSendMessage() }}
                           >
                              <SendIcon />
                           </IconButton>
                        </Grid>

                     </Grid>
                  </Paper>
               </Grid>
            </Grid>
         </Grid>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig?.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <ModalProspectoRapidoWS
           open={openModal}
           setOpen={setOpenModal}
           phone={selectChat}
           refreshData={getChatsByUsers}
         />
         <ModalUploadImage
          open={showPreviewImage}
          handleClose={closeModalUpload}
          uploadImage={saveImageChat?.preview}
          sendMessage={handleSendMessageImage}
         />
      </Protected>
   )
};
