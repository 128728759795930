import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './UserByAreaNegocio.type';
import { InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, MenuItem, Backdrop } from '@mui/material'
import { ArrowBackIcon, ArrowDownIcon, SaveIcon, VisibilityIcon, VisibilityOffIcon } from "@toolbox/constants/icons";
import { Icon } from '@components/common/Icon';
import User from "@assets/img/user.png";
import { useHistory } from 'react-router-dom'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { ROUTE_ENTITY, ROUTE_USER, ROUTE_USER_BY_AREA_NEGOCIO } from '@toolbox/constants/route-map';
import { MenuLateral } from '@/components/common/LateralMenu';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BadgeIcon from '@mui/icons-material/Badge';
import { AreaNegocioService } from '@/service/services/AreaNegocio.service';

const getPassword = () => {
   var chars = "+@_0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
   var password = '';
   for (let i = 0; i < 8; i++) {
      password += chars.trim().charAt(Math.floor(Math.random() * chars.length));
   }
   return password;
}
export const CreateUserByAreaNegocioView: React.FC<Props> = (props: Props): JSX.Element => {
   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [typeUser, setTypeUser] = useState<any>([]);
   const [AreaNegocio, setAreaNegocio] = useState<any>([]);
   const [open, setOpen] = useState(false);
   const [estado, setEstado] = useState<any>({ user_nick: false, email: false })
   const [data, setData] = useState({
      idtipousuario: '',
      nombre: "",
      user_nick: '',
      email: '',
      telefono: '',
      idarea_negocio:'',
      password: getPassword(),
      idempresacliente:'',
      textError: '',
      showPassword: false
   });
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      userId: null,
      message: `¿Desea eliminar al usuario ---?`
   })


   const getUserData = async()=>{
      const resp= await userService.getUserData();
      setTypeUser(resp.data.user_type)
   }

   const getAreaNegocio = async()=>{
      const resp= await AreaNegocioService.getArea();
      console.log(resp)
      setAreaNegocio(resp.data)
   }

   useEffect(() => {
      getUserData();
      getAreaNegocio();
      if (props.user !== undefined) {
         if (props.user.action == 'view') {
            setIsDisabled(true);
         }
         setData(props.user)
         //getDataUbigeo(props.contact.idcomuna)
      } else {
         setData(prev => ({ ...prev, idarea_negocio: props.idarea_negocio , textError: '' }))
         //getPais();
      }
   }, [])

   const rules = useMemo(() => ({
      string_lowecase: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      string_required: [
         VALIDATORS.REQUIRED,
      ],
      phone: [
         VALIDATORS.REQUIRED,
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.PHONE,
      ],
      email: [
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'nombre':
            setData(prev => ({ ...prev, nombre: value, textError: '' }));
            break;
         case 'user_nick':
            setData(prev => ({ ...prev, user_nick: value, textError: '' }));
            break;
         case 'email':
            if ((/^[\w@.-]{0,86}$/).test(value)) {
               setData(prev => ({ ...prev, email: value, textError: '' }));
            }
            setData(prev => ({ ...prev, email: value, textError: '' }));
            break;
         case 'idtipousuario':
            setData(prev => ({ ...prev, idtipousuario: value, textError: '' }))
            break;
         case 'idarea_negocio':
            setData(prev => ({ ...prev, idarea_negocio: value, textError: '' }))
            break;
         case 'telefono':
            setData(prev => ({ ...prev, telefono: value, textError: '' }))
            break;
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               setData(prev => ({ ...prev, password: value, textError: '' }));
            }
            break;
         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      //const { user_nick, telefono, password } = data;
      try {
         const respUsernick = await userService.verifyUsernick(data.user_nick);
         const respEmail = await userService.verifyEmail(data.email);
         const idempresacliente = readLocalStorage(KEY_USER_DATA);
         const dataAdminUser = {
            nombre: data.nombre,
            user_nick: data.user_nick,
            email_admin: data.email,
            idtipousuario: 5,
            telefono: data.telefono,
            password: data.password,
            idarea: data.idarea_negocio,
            idempresacliente:idempresacliente.user.idempresacliente
         }
         let resp;
         if (props.user) {
            //console.log('ID',props.user.idusuario + 'DATA',dataAdminUser);
            resp = await userService.updateUser(props.user.idusuario, dataAdminUser)
         }
         else {
            if (respUsernick.data == false && respEmail.data == false) {
               if (validate()) {
                  resp = await AreaNegocioService.createTrabajador(dataAdminUser);
                  console.log(dataAdminUser);
               }
            }
            else {
               setEstado({ user_nick: respUsernick.data, email: respEmail.data })
               setOpenModal(true);
            }
         }
         if (!!resp.data) {
            history.push(ROUTE_USER_BY_AREA_NEGOCIO);
         }
      }
      catch (error) {
         // setDataEntity(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   const handleClickShowPassword = () => {
      setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };



   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <Grid container flexDirection='row' spacing={2}>
               <Grid item md={3} container>
                  <MenuLateral />
               </Grid>
               <Grid item md={9}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #1976D2 ', mt: 3 }}
               >
                  <Grid container sx={{ p:6}}>
                     <Typography variant='h4' sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em',mb:2}} > {props.user == undefined ? "Crear Trabajador" : !isDisabled ? "Editar Contacto" : "Detalle Contacto"}</Typography>
                     <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} className={`e-mb-lg`}>
                           <Grid item xs={6}>
                              <Input
                                 ref={ref => inputRefs.current[0] = ref}
                                 label="Nombre"
                                 name="nombre"
                                 prependInnerAdornment={
                                    <InputAdornment position="end">
                                    <PersonIcon />
                                   </InputAdornment>
                                   }
                                 height={35}
                                 placeholder="Nombre"
                                 value={data.nombre}
                                 onChange={handleInput}
                                 onBlur={handleInputBlur}
                                 backgroundColor="#eeeff1"
                                 rules={rules.string_required}
                                 disableElevation
                                 validateOnBlur
                                 dense
                                 disabled={isDisabled}
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <Input
                                 ref={ref => inputRefs.current[1] = ref}
                                 label="User_Nick"
                                 name="user_nick"
                                 prependInnerAdornment={
                                    <InputAdornment position="end">
                                    <AccountCircleIcon />
                                   </InputAdornment>
                                   }
                                 height={35}
                                 placeholder="User_Nick"
                                 value={data.user_nick}
                                 onChange={handleInput}
                                 onBlur={handleInputBlur}
                                 backgroundColor="#eeeff1"
                                 rules={rules.string_lowecase}
                                 disableElevation
                                 validateOnBlur
                                 dense
                                 disabled={props.user?.action=='edit'?true:isDisabled}
                              />
                           </Grid>
                           <Grid item xs={6}>
                              <Input
                                 ref={ref => inputRefs.current[2] = ref}
                                 label="Correo Electrónico"
                                 name="email"
                                 prependInnerAdornment={
                                    <InputAdornment position="end">
                                    <EmailIcon />
                                   </InputAdornment>
                                   }
                                 height={35}
                                 placeholder="Correo Electrónico"
                                 value={data.email}
                                 onChange={handleInput}
                                 onBlur={handleInputBlur}
                                 backgroundColor="#eeeff1"
                                 rules={rules.email}
                                 disableElevation
                                 validateOnBlur
                                 dense
                                 disabled={props.user?.action=='edit'?true:isDisabled}
                              />
                           </Grid>
                           {/* <Grid item xs={6}>
                              <FormControl fullWidth >
                                 <small>Tipo Usuario</small>
                                 <Select
                                    id="idtipousuario"
                                    sx={{ bgcolor: '#EEEFF1' }}
                                    onChange={handleInput}
                                    disabled={isDisabled}
                                    //onBlur={handleInputBlur}
                                    value={data.idtipousuario}
                                    name='idtipousuario'
                                    size='small'
                                    startAdornment={
                                       <InputAdornment position="start">
                                     <BadgeIcon />
                                    </InputAdornment>
                                    }

                                 >
                                    {typeUser.map((option, i) => {
                                       return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                                    })
                                    }
                                 </Select>
                              </FormControl>
                           </Grid> */}
                           <Grid item xs={6}>
                              <FormControl fullWidth >
                                 <small>Area Negocio</small>
                                 <Select
                                    id="idarea_negocio"
                                    sx={{ bgcolor: '#EEEFF1' }}
                                    onChange={handleInput}
                                    disabled={isDisabled}
                                    value={data.idarea_negocio}
                                    name='idarea_negocio'
                                    size='small'
                                    startAdornment={
                                       <InputAdornment position="start">
                                     <BadgeIcon />
                                    </InputAdornment>
                                    }

                                 >
                                    {AreaNegocio.map((option, i) => {
                                       return <MenuItem key={i} value={option.idarea_negocio}>{option.descripcion}</MenuItem>
                                    })
                                    }
                                 </Select>
                              </FormControl>
                           </Grid>

                           <Grid item xs={props.user===undefined?6:12}>
                              <Input
                                 ref={ref => inputRefs.current[3] = ref}
                                 label="Telefono"
                                 name="telefono"
                                 prependInnerAdornment={
                                    <InputAdornment position="end">
                                    <LocalPhoneIcon />
                                   </InputAdornment>
                                   }
                                 height={35}
                                 type="text"
                                 placeholder="Telefono"
                                 value={data.telefono}
                                 onChange={handleInput}
                                 onBlur={handleInputBlur}
                                 backgroundColor="#eeeff1"
                                 rules={rules.phone}
                                 disableElevation
                                 validateOnBlur
                                 dense
                                 disabled={isDisabled}
                              />
                           </Grid>
                           {props.user === undefined && (
                              <Grid item xs={6}>
                                 <Input
                                    ref={ref => inputRefs.current[4] = ref}
                                    label="Contraseña"
                                    name="password"
                                    height={35}
                                    type={data.showPassword ? 'text' : 'password'}
                                    placeholder="Ingrese la contraseña"
                                    value={data.password}
                                    onChange={handleInput}
                                    onBlur={handleInputBlur}
                                    backgroundColor="#eeeff1"
                                    rules={rules.password}
                                    disabled
                                    appendAdornment={
                                       <InputAdornment position="end">
                                          <IconButton
                                             disabled
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}
                                             onMouseDown={handleMouseDownPassword}
                                          >
                                             {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                    disableElevation
                                    validateOnBlur
                                    dense
                                 />
                              </Grid>
                           )}
                           {isDisabled==false&&
                           <Grid container item xs={12}
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center">
                              <Grid item xs={3}>
                                 <Button
                                    fullWidth
                                    type='submit'
                                    size='small'
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    disableElevation
                                    startIcon={<SaveIcon />}
                                    sx={{background:'#E4980D', color:'#fff', '&:hover':{
                                       bgcolor:'#E4980D'
                                      }}}
                                 >
                                    {
                                       loading ?
                                          <CircularProgress className="CircularProgress" size={24} /> :
                                          <span>{props.user==undefined?"Crear":"Actualizar"}</span>
                                    }
                                 </Button>
                              </Grid>
                           </Grid>
                           }
                        </Grid>
                     </form>
                     <Modal
                        open={openModal}
                        //onClose={() => { setOpenModal(false) }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                     >
                        <Box sx={{
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           transform: 'translate(-50%, -80%)',
                           width: 300,
                           bgcolor: '#ffffff',
                           boxShadow: 24,
                           p: 4,
                        }}>
                           <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                              <Box
                                 component="img"
                                 textAlign="center"
                                 sx={{
                                    width: "100px",
                                    height: "100px",
                                 }}
                                 src={User}
                              ></Box>
                           </Grid>
                           <Divider />
                           <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                              {`El ${estado.user_nick ? 'User Nick,' : ''}${estado.email ? 'Email,' : ''} ya esta tomado por otro usuario`}
                           </Typography>
                           <Divider />
                           <Grid container item xs={12} sx={{ pt: 2 }}
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center">
                              <Grid item>
                                 <Button
                                    fullWidth
                                    variant='contained'
                                    onClick={() => { setOpenModal(false) }}
                                 >
                                    Aceptar
                                 </Button>
                              </Grid>
                           </Grid>
                        </Box>
                     </Modal>
                  </Grid>
               </Grid>
            </Grid>
         </Container>
      </Protected>
   )
};
