
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Autocomplete, Box, Button, Drawer, FormControl, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const dataPaymentWay = [
    { id: 1, name: 'Efectivo' }, 
    { id: 2, name: 'Cheque' }, 
    { id: 3, name: 'Transferencia' },
    //  { id: 4, name: '30 DIAS' }, 
     { id: 5, name: 'Redcompra' }
    ];

export const DrawerDetallePagoProductos: React.FC<any> = (props): JSX.Element => {

    const { openDrawer, setOpenDrawer, itemSelected, getArriendo, data } = props;
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    });
    const [paymentImage, setPaymentImage] = useState(null);
    const [codePay, setCodePay] = useState("");

    const [bancos, setBancos] = useState<any>([]);
    const [bancoSelected, setBancoSelected] = useState<any>(null);
    const [bancosEmpresa, setBancosEmpresa] = useState<any>([]);
    const [bancosEmpresaSelected, setBancosEmpresaSelected] = useState<any>(null);

    const [paymentWaySelected, setPaymentWaySelected] = useState<any>(null);

    useEffect(() => {
        getBancos();
        getBancosEmpresa();
    }, []);

    const getBancos = async () => {
        const bancos = await ArriendosService.getBancos();
        setBancos(bancos.data);
    }

    const getBancosEmpresa = async () => {
        const bancos = await ArriendosService.getBancosEmpresa();
        setBancosEmpresa(bancos.data);
    }

    const confirmarPago = async () => {
        if (!paymentWaySelected ) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: 'Por favor, completa todos los campos.', severity: 'error' });
            return;
        }
        if ((paymentWaySelected.id == 2 || paymentWaySelected.id == 3) && (!bancoSelected || !bancosEmpresaSelected)) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: 'Por favor, completa todos los campos.', severity: 'error' });
            return;
        }
        
        if (paymentWaySelected.id == 2  && !codePay) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: 'Por favor, completa todos los campos.', severity: 'error' });
            return;
        }

        const dataUser = readLocalStorage(KEY_USER_DATA);
        const rut_empresa = dataUser?.user?.rut_empresa;
        
        const formData = new FormData();
        formData.append('codigo', itemSelected.cod_cobranza);
        formData.append('img_pago', paymentImage);
        if(codePay){
            formData.append('cod_pago', codePay || '');
        }
        if(bancoSelected){
            formData.append('banco_origen', bancoSelected?.nombre || '');
        }
        if(bancosEmpresaSelected){
            formData.append('banco_destino', bancosEmpresaSelected?.id || '');
        }
        formData.append('folio', itemSelected.folio);
        formData.append('total_factura', itemSelected.monto_total);
        formData.append('forma_pago', paymentWaySelected.name);
        formData.append('rut_cliente', data.rut_cliente);
        formData.append('tipo_documento', "33");
        formData.append('rut_empresa', rut_empresa);

        const registerArriendo = await ArriendosService.pagarMensualidad(formData);
        if (!!registerArriendo?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'success' });
            await getArriendo(data?.id);
            setOpenDrawer(false);
        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'error' });
        }
    }

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: "450px",
                    padding: "20px",
                    paddingX: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                    Detalle de Pago
                </Typography>

                <div style={{
                    borderBottom: '3px solid #155172',
                    borderTop: '3px solid #155172',
                    padding: '10px',
                    marginBottom: '10px',
                    height: '400px',
                    minWidth: '400px',
                    overflowY: 'auto',
                    margin: '0 auto',
                }}>
                    {itemSelected.detalle.map((item, index) => (
                        <div key={item.id} style={{
                            borderBottom: index < itemSelected.detalle.length - 1 ? '1px solid #ddd' : 'none',
                            paddingBottom: '10px',
                            paddingTop: '5px',
                            marginBottom: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}>
                            <>
                                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000' }}>
                                    {
                                        itemSelected.tipo == 2 ?
                                            `(${item?.servicio_devolucion?.codigo}) ${item?.servicio_devolucion?.nombre}`
                                            :
                                            `(${item?.servicio?.codigo || item?.producto?.codigo}) ${item?.servicio?.nombre || item?.producto?.nombre}`
                                    }
                                </Typography>

                                <Typography sx={{ color: '#606060' }}>
                                    {`${item?.cantidad} productos`}
                                </Typography>

                                <Typography sx={{ color: '#606060' }}>
                                    {item?.servicio &&
                                        (itemSelected.status_name === "Pagado"
                                            ? `${itemSelected.total_dias} día(s) pagado`
                                            : `${itemSelected.total_dias} día(s) pendientes de pago`
                                        )
                                    }
                                </Typography>

                                {item?.servicio ?
                                    <Typography sx={{ color: '#000' }}>
                                        {` $ ${item?.monto_xcantidad * itemSelected.total_dias} CLP`}
                                    </Typography> :
                                    <Typography sx={{ color: '#000' }}>
                                        {` $ ${item?.monto_xcantidad} CLP`}
                                    </Typography>
                                }
                            </>
                        </div>
                    ))}
                </div>

                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000', marginTop: "20px", marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${itemSelected.monto_neto} CLP`}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000', marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>IVA</strong>
                    {` $ ${itemSelected.iva} CLP`}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#000', marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${itemSelected.monto_total} CLP`}
                </Typography>
                {
                    itemSelected?.status_name != 'Pagado'
                    &&
                    <>
                        <Autocomplete
                            disablePortal
                            id="combo-box-company"
                            sx={{ bgcolor: '#fff', marginTop: "10px" }}
                            value={paymentWaySelected}
                            options={dataPaymentWay || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            fullWidth
                            renderInput={(params) => <TextField {...params} size="small" label="Forma de pago" />}
                            onChange={(e, newValue) => {
                                setPaymentWaySelected(newValue);
                            }}
                        />

                        {
                            (paymentWaySelected?.id == 2 || paymentWaySelected?.id == 3) &&
                            <>
                                <Autocomplete
                                    value={bancoSelected}
                                    sx={{ bgcolor: '#fff', marginTop: "10px" }}
                                    size='small'
                                    onChange={(e, banco: any) => {
                                        setBancoSelected(banco);
                                    }}
                                    id="banco"
                                    options={bancos}
                                    getOptionLabel={(option: any) => option.nombre ? option.nombre : ""}
                                    renderInput={(params) => <TextField {...params} label="Banco de origen" />}
                                />


                                <Autocomplete
                                    value={bancosEmpresaSelected}
                                    sx={{ bgcolor: '#fff', marginTop: "10px" }}
                                    size='small'
                                    onChange={(e, banco: any) => {
                                        setBancosEmpresaSelected(banco);
                                    }}
                                    id="banco"
                                    options={bancosEmpresa}
                                    getOptionLabel={(option: any) => option.nombre ? option.nombre : ""}
                                    renderInput={(params) => <TextField {...params} label="Banco de destino" />}
                                />

                            </>
                        }

                        {
                            paymentWaySelected?.id == 2 && 
                            <FormControl sx={{ marginTop: "10px", marginBottom: "10px" }}>
                                <TextField
                                    id="cod_pago"
                                    type="text"
                                    multiline
                                    name="cod_pago"
                                    fullWidth
                                    size="small"
                                    value={codePay}
                                    onChange={(e) => setCodePay(e.target.value)}
                                    placeholder="Ingrese el número de pago como referencia"
                                />
                            </FormControl>
                        }
                        
                       

                        <input type="file" accept="image/*"
                            onChange={(e) => { setPaymentImage(e.target.files[0]) }}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                marginTop: "20px",
                                backgroundColor: "#155172",
                                color: "white",
                                padding: '20px',
                                position: 'relative',
                                overflow: 'hidden',
                                '&:hover': {
                                    background: 'linear-gradient(to right, rgba(21, 81, 114, 0.8) 100%, transparent 50%)',
                                }
                            }}
                            onClick={() => confirmarPago()}
                        >
                            Pagar
                        </Button>
                    </>
                }
            </Box>
        </Drawer>
    )
}
