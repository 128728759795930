import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog, Container, InputLabel } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { indigo } from '@mui/material/colors';
import { ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import { entityService } from '@/service/services/Entity.service';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data?: any,
    dataListCampaing?: any,
    refreshData?: () => void,
}

export const ModalMoveClient: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {

    const [open, setOpen] = useState(false);
    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const [vendedor, setVendedor] = useState([]);
    const [selectCampaign, setSelectCampaign] = useState([]);
    const [selectVendedor, setSelectVendedor] = useState([]);
    const [] = React.useState();
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
    const [error, setError] = useState(null);

    useEffect(() => {
        getVendedor();
    }, [])

    const moveClient = async () => {
        try {
            let usuario
            const id_empresa = dataUser?.user?.idempresacliente;
            dataUser.user.role == ROLE_VENDEDOR
                ? usuario = dataUser.user.idusuario
                : usuario = selectVendedor['idusuario']
            const dataCampaign = {
                'campaign': props.data.idcampaña ? props.data.idcampaña : props.data.idcampananegocio,
                'translateCampana': selectCampaign['id'],
                'idcliente': props.data.idcliente,
                'tipo': selectCampaign['tipo'],
                'idusuario': usuario,
                'id_empresa': id_empresa
            }

            const resp = await campaingService.translateClient(dataCampaign)
            if (resp.data.error) {
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'error',
                    message: resp.data.error,
                }));
            } else {
                props.setOpen(false)
                setSelectCampaign([])
                setSelectVendedor([])
                props.refreshData()
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function getVendedor() {
        const resp = await entityService.getVendedor()
        if (resp.data) {
            setVendedor(resp.data)
        }
    }

    const bodyModal = (
        <>
            <Grid item xs={12} mt={2}>
                <Container maxWidth='xl' sx={{ mt: 1, mb: 2, maxHeight: 600, overflow: 'auto' }}>
                    <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5em', mb: 3, textAlign: 'center' }} >
                        {'TRANSFERENCIA DE CLIENTE'}
                    </Typography>
                    <Typography sx={{ mb: 2, fontWeight: 100, color: '#000' }}>
                        ¿DESEA MOVER AL CLIENTE {props.data.nombre_rz} A OTRA CAMPAÑA?
                    </Typography>
                    {dataUser.user.role == ROLE_ADMIN || dataUser.user.role == ROLE_SUPERVISOR
                    ?   <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <Autocomplete
                                id="tags-outlined"
                                options={vendedor}
                                getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                                size={'small'}
                                fullWidth
                                sx={{ bgcolor: '#fff' }}
                                value={selectVendedor}
                                onChange={(e, newValue) => {
                                    setSelectVendedor(newValue);
                                    selectVendedor !== null && setError("");
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seleccionar Vendedor"
                                        placeholder=""
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    : <Grid item xs={12}></Grid>
                    }
                    <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                        <Autocomplete
                            id="tags-outlined"
                            options={props.dataListCampaing}
                            getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                            size={'small'}
                            fullWidth
                            sx={{ bgcolor: '#fff' }}
                            value={selectCampaign}
                            onChange={(e, newValue) => {
                                setSelectCampaign(newValue);
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Seleccionar Campaña"
                                    placeholder=""
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                </Container>
                <Grid item container
                    direction="row"
                    justifyContent="end"
                    alignItems="center">
                    <Button
                        onClick={() => {
                            props.setOpen(false)
                            setSelectCampaign([])
                            setSelectVendedor([])
                        }}
                        variant="contained"
                        // fullWidth
                        color="error"
                        startIcon={<CancelIcon />}
                        sx={{
                            background: '#bf6c00', color: '#fff', mt: '10px', mr: '10px', '&:hover': { bgcolor: '#bf6c00' }
                        }}>
                        <span>Cancelar</span>
                    </Button>
                    <Button
                        variant='contained'
                        onClick={moveClient}
                        startIcon={<SaveIcon />}
                        sx={{
                            background: '#155172', color: '#fff', mt: '10px', mr: '10px', '&:hover': {
                                bgcolor: '#155172'
                            }
                        }}
                    >
                        <span>Guardar</span>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
    return (
        <div>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px',
                    bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p: 1,
                }}>

                    {bodyModal}
                </Box>

            </Modal>
        </div>
    );

}
