import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Product.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, InputLabel, TextField, Typography, Button, linearProgressClasses, FormControl, Select, MenuItem, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { productService } from '@/service/services/Product.service';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import { Link } from 'react-router-dom';
import { ROUTE_PRODUCT_CREATE, ROUTE_PRODUCT_RELATIONS } from '@/toolbox/constants/route-map';
import { ProductoSoftnetService } from '@/service/api/services/ProductoSoftnet.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { sincronizacionService } from '@/service/services/Sincronizacion.service';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { SpinnerGrow } from '@/components/common/Spinner';
import SyncIcon from '@mui/icons-material/Sync';
import {useHistory} from 'react-router-dom'

const header = [
   { name: 'codigo', label: 'Código', width: '' },
{ name: 'nombre', label: 'Nombre', width: '' },
{ name: 'precio', label: 'Precio', width: '', money:true },
// { name: 'stock', label: 'Stock', width: '',integer:true }
];
const dataAction = [
   { name: 'view', color: 'success' },
]


export const ProductView: React.FC<Props> = ({ }): JSX.Element => {
   const history = useHistory()
   const [dataProduct, setDataProduct] = useState<any>([])
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [busqueda, setBusqueda] = useState<any>("");
   const token = readLocalStorage(KEY_TOKEN_SOFTNET);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [open, setOpen] = useState<boolean>(false);
   const [openSync, setOpenSync] = useState(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })


   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0)

   useEffect(() => {
      try{
         getProduct();
      }catch(e){
         console.log(e)
      }
   }, [pageData,perPageData])

   const recuperarData = (data) => {
      history.push(`/producto/relaciones/${data.id}`,data)
   }

   async function getProduct() {
      setOpen(true);
      const resp = await productService.getProduct(perPageData,pageData)
      setDataProduct(resp.data.products.data);
      // setTablaBusqueda(resp.data.products)
      setTotal(resp.data.products.total)
      setOpen(false);
      // const resp = await ProductoSoftnetService.getProducto(token);
      // setDataProduct(resp?resp:[]);
   }

   const PerPage = async (data) =>{
      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) =>{
      setPageData(data+1)
   }

   const getSearchProduct = async (search) => {
      try{
         const resp= await productService.getSearchProduct(search);
         setDataProduct(resp.data.products);
       }catch(e){
          console.log(e)
       }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  getSearchProduct(value)
               }
               if(value.length==0)
               {
                  getProduct();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };


   const SyncProduct = async () => {
      setOpenSync(true);
      const respSync = await sincronizacionService.postSync();
      if (respSync.data) {
         const resp = await sincronizacionService.SyncProduct();
         if(resp.data.status)
         {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp.data.descripcion,
            }));
            getProduct()
         }
         if (!resp.data.status) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp.data.descripcion,
            }));
         }
      }
      setOpenSync(false);
   }

   const viewInventoryProducts = () => {
      history.push("/inventarios-productos")
   }

   return (
      <Protected>
          <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1, bgcolor: '#000' }}
               open={openSync}
            >

               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{fontSize:'30px'}}><strong>Sincronizando...</strong></h1>
               </Grid>

            </Backdrop>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container maxWidth='xl'>
            <Grid container justifyContent='space-between' spacing={3}>
               <Grid item xs={5} container alignItems="center">
               <Typography  sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}} >Mis Productos</Typography>
               </Grid>

               <Grid item xs={4}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={3}
                  mt={3}
                  alignItems="center">

                     {dataUser.user.role==ROLE_ADMIN&& 
                     <>
            <Button onClick={()=>{viewInventoryProducts()}}>Ver inventario de productos</Button>

                      <Button variant='contained'
                     sx={{background:'#E4980D', color:'#fff', '&:hover':{
                        bgcolor:'#E4980D'
                       }}}
                       endIcon={<SyncIcon fontSize='large'/>}
                       onClick={()=>{SyncProduct()}}>Sincronizar</Button>
                     </>
                    }

               </Grid>
            </Grid>
            <Divider
               sx={{ mt: 2 }} />
            <Grid container spacing={1} >
               <Grid item xs={12}>
                  <Typography variant="body2" sx={{ mt: '20px' }} >
                     {Total ? `${Total} registros encontrados` : `0`}
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <InputLabel >Filtrar Resultados</InputLabel>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="search"
                        value={search}
                        onChange={handleInput}
                        placeholder="Introduce un Nombre o Código Producto"
                        type="search"
                        id="password"
                        autoComplete="current-password"
                        size="small"
                     />
               </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
               <TableData
                  header={header}
                  data={dataProduct?dataProduct:[]}
                  action={dataAction}
                  RecuperarData={recuperarData}
                  total={Total? Total:0}
                  perPage={PerPage}
                  page={page}
                  />
            </Grid>
         </Container>
      </Protected>
   )
};
