import React, { useState, useEffect, useRef, Children} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText, Stack, Chip } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { mailService } from '@/service/services/Mail.service';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';
import { ModalVerCorreo } from './ModalVerCorreo';
import { ModalCuponEdit } from './ModalCuponEdit';
import { ModalPromocionEdit } from './ModalPromocionEdit';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import PhotoIcon from '@mui/icons-material/Photo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from '@mui/icons-material/AttachFile';

type ModalProps = {
    open: boolean,
    setOpen: any,
   data?:any,
   id_cliente?:any
}

export const ModalCorreoEnviados: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props)
   
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   const [dataHistorial,setDataHistorial] = useState<any>([])
   const [correoSelect, setCorreoSelect] = useState<any>({})
   const [openCorreo ,setOpenCorreo] = useState<boolean>(false)
   const [openCorreoCupon ,setOpenCorreoCupon] = useState<boolean>(false)
   const [openCorreoPromocion ,setOpenCorreoPromocion] = useState<boolean>(false)
   const [landing, setLanding] = useState<any>({})
   useEffect(()=>{
      if(props.data?.iddetallecampaña){

         getHistorialCorreo(props.data?.iddetallecampaña);
      }else{
         getHistorialCorreoCliente(props.id_cliente)
      }
   },[])

   const handleClick = async(attach, uid) => {
      const resp:any = await mailService.getDescargarArchivo(attach, uid);
      if(resp.data){
         window.open(resp.data.ruta)
      }
   }

   const getHistorialCorreo = async(id_detalle) => {
        const resp:any = await mailService.getHistorialCorreo(id_detalle);
        setDataHistorial(resp);
   }

   const getHistorialCorreoCliente = async(id_cliente) => {
      const resp:any = await mailService.getHistorialCorreoCliente(id_cliente);
        setDataHistorial(resp);
   }

   const verCorreo = (data) => {
      if(data.id_landing){
         setLanding(data.landing)
         if(data.landing.tipo == 'cupon'){
            setOpenCorreoCupon(true)
         }
         if(data.landing.tipo == 'promocion'){
            setOpenCorreoPromocion(true)
         }
      }else{
         const dataRecibidos = {
            body: data.body_correo,
            html: data.html_correo
         }
         setCorreoSelect(dataRecibidos)
         setOpenCorreo(true)
      }
   }
    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>

            <Typography id="modal-modal-title" sx={{ mb:2,color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Historial de correos`}
            </Typography>

          <Stack direction="row" spacing={1} mb={2} sx={{ zIndex: 1000, pl: 2 }}>
             
            <Chip
               label={'Enviados'}
               onClick={() => { console.log('enviados') }}
               icon={<SendIcon/>}
               color="success"
               variant="outlined"
            />
            <Chip
               label={'Recibidos'}
               onClick={() => { console.log('recibidos') }}
               icon={<EmailIcon/>}
               color="secondary"
               variant="outlined"
            />
            
          </Stack>
           <Grid container maxHeight={'65vh'} sx={{overflowY:'scroll', bgcolor:'#fff'}}>
           {dataHistorial.map((value, i) => {
                    
                    return (
                       <>
                          <ListItem
                             key={i}
                             disablePadding
                             sx={{bgcolor:'#fff'}}
                          >
                            <ListItemButton  onClick={()=>{verCorreo(value)}}>
                            <ListItemText
                                        primary={
                                           <React.Fragment>
                                             {value.id_landing? <SendIcon color='success' sx={{mr:2, mt:1, height:'18px'}}/>:<EmailIcon color='secondary' sx={{mr:2, mt:1, height:'18px'}}/>}
                                              <span style={{color:'black'}}>
                                                 {dateFormat(value.fecha_correo)+' '+dateFormatTime(value.fecha_correo)}
                                              </span>
                                              
                                           <Typography
                                              sx={{ display: 'inline' , fontSize:'20px',textDecoration:'none',color:'black', ml:1, fontWeight:700}}
                                           >
                                                <strong> 
                                                 {value.id_landing?
                                                value?.landing?.mail
                                                 :value.from_personal}</strong>
                                           </Typography>
                                        </React.Fragment>
                                        }
                                        secondary={
                                           <>
                                              <Typography
                                                 sx={{ display: 'inline' , fontSize:'15px',textDecoration:'none', color:value?.todo?.flags?.seen=='Seen'?'#B5B2B2':'black'}}
                                                 component="span"
                                                 variant="body2"
                                              >
                                                 {value.id_landing?value?.landing?.titulo :value.body_correo? value.body_correo.substring(0,120)+'...':'...'}
                                              </Typography>
                                           </>
                                        }
                                     />
                            </ListItemButton>
                            
                          </ListItem>
                          <Stack direction="row" spacing={1} sx={{zIndex:1000, pl:2}}>
                                                {
                                                   value.adjuntos.map((item)=>{
                                                      let icono = <AttachFileIcon/>;

                                                      if(item.tipo == 'application/pdf'){ icono = <PictureAsPdfIcon />}
                                                      if(item.tipo == "image/png"){icono = <PhotoIcon/>}
                                                      return(
                                                         <Chip
                                                            label={item.nombre}
                                                            onClick={()=>{handleClick(item.nombre, value.uid_correo)}}
                                                            icon={icono}
                                                            color="secondary"
                                                            variant="outlined"
                                                         />
                                                      )
                                                   })
                                                }
                                          </Stack>
                         <hr></hr> 
                       </>
                    );
                 })}  
           </Grid>
        </Box> 
    )
    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
            >
                    {bodyModal}
            </Modal>
          {
             openCorreo && <ModalVerCorreo
                setOpen={setOpenCorreo}
                open={openCorreo}
                data={correoSelect}
                setData={setCorreoSelect}
             />
          }
          {
            openCorreoCupon && <ModalCuponEdit
            setOpen={setOpenCorreoCupon}
            open={openCorreoCupon}
            data={landing}
            tipo='hilo_correo'/>
          }
          {
            openCorreoPromocion && <ModalPromocionEdit
            setOpen={setOpenCorreoPromocion}
            open = {openCorreoPromocion}
            data={landing}
            tipo='hilo_correo'
            />
          }
        </div>
    );
}
