
import { proyeccionRepository } from "../repositories/Proyeccion.repository";

export const proyeccionService= {
   getProyeccionData,
   getProyeccion,
   createProyeccion,
   getHitosByProyeccion,
   getProyeccionById,
   getHitoById
};

async function getProyeccionData () {
   const proyeccion= await proyeccionRepository.getProyeccionData();
   return proyeccion;
}

async function getProyeccion () {
   const proyeccion= await proyeccionRepository.getProyeccion();
   return proyeccion;
}

async function createProyeccion (dataProyeccion) {
   const proyeccion= await proyeccionRepository.createProyeccion(dataProyeccion);
   return proyeccion;
}

async function getHitosByProyeccion (idProyeccion) {
   const proyeccion= await proyeccionRepository.getHitosByProyeccion(idProyeccion);
   return proyeccion;
}

async function getProyeccionById (idProyeccion:number,idhito:number) {
   const proyeccion= await proyeccionRepository.getProyeccionById(idProyeccion,idhito);
   return proyeccion;
}
async function getHitoById (idHito:number) {
   const hito= await proyeccionRepository.getHitoById(idHito);
   return hito;
}


