import React from "react";
import {
    Paper,
    CssBaseline,
    IconButton,
    Button,
    createTheme
} from "@mui/material";
import ListTitle from "./ListTitle";
import TrelloCard from "./TrelloCard";
import AddCardorList from "./AddCardorList";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from '@mui/styles';


const TrelloList = ({ list, index , pipelines}) => {

    const classes = useStyle();
    return (
        <Draggable draggableId={list.id} index={index}>
            {(provided) => (
                <div {...provided.draggableProps} ref={provided.innerRef}>
                    <Paper
                    className={classes.root}
                    sx={{
                        background: "#EBECF0",
                        margin: 2,
                    }}
                     {...provided.dragHandleProps}>
                        <CssBaseline />
                        <ListTitle
                            title={list.title}
                            listId={list.id}
                            provided={provided}
                        />
                        <div>
                        <Droppable
                        droppableId={list.id}

                        >
                            {(provided) => (
                                <div ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={classes.cardContainer}
                                >
                                    {list.cards.map((card, i) => (
                                        <>
                                            <TrelloCard card={card} key={card.iddetallecampaña} index={i}  pipelines={pipelines}/>
                                        </>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        </div>

                        {/* <AddCardorList type="card" listId={list.id} /> */}
                    </Paper>
                </div>
            )}
        </Draggable>
    );
};

export default TrelloList;

const theme = createTheme();
const useStyle = makeStyles({
    root: {
            minWidth: '300px',
            marginLeft: theme.spacing(1),
        },
    cardContainer: {
            marginTop: theme.spacing(4),
        },
  });
