import React, { useState } from 'react';
import {Box,Button,Modal,TextField,MenuItem,Select,InputLabel,FormControl,Grid, Typography} from '@mui/material'
import { DownloadIcon, CloseCircleIcon} from "@toolbox/constants/icons";
import './Modal.sass'
import { notifyService } from '@/service/services/Notify.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROUTE_NOTIFY } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';

const initialFormValues = {
   fromusuario: "",
    usuario: "",
    titulo: "",
    descripcion: "",
    fecha: "",
    ruta: "",
}

type ModalProps = {
    openNewNotify: boolean,
     setOpenNewNotify: React.Dispatch<React.SetStateAction<boolean>>,
     dataUser: any

}
export const ModalAddNotify: React.FC<ModalProps> = ({
    openNewNotify,
     setOpenNewNotify,
     dataUser
}): JSX.Element => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const { fromusuario="",usuario="", titulo="", descripcion="", fecha="",ruta="" } = formValues;
    const [openCombo, setOpenCombo] = useState(false);
    const [error, setError] = useState(null);
    const handleCloseCombo = () => setOpenCombo(false);
    const handleOpenCombo = () => setOpenCombo(true);
    const handleInputChange = (e) => {
        const changedFormValues = {
            ...formValues,
            [e.target.name]: e.target.value
        }
        titulo.trim()!==""&&setError("")
        descripcion.trim()!==""&&setError("")
        fecha.trim()!==""&&setError("")
      //   ruta.trim()!==""&&setError("")
        setFormValues(changedFormValues);
    }
    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      //   if (!usuario.trim()) {
      //       return setError("usuario");
      //   }
        if (!titulo.trim()) {
            return setError("titulo");
        }
        if (!descripcion.trim()) {
            return setError("descripcion");
        }
        if (!fecha.trim()) {
            return setError("fecha");
         }
      //   if (!ruta.trim()) {
      //       return setError("ruta");
      //   }
        else {
         const data = readLocalStorage(KEY_USER_DATA)
         const idusuario = data.user.idusuario;
            const values = {
                ...formValues,
                fromusuario:idusuario
            } || {}

            // setFormValues(...formValues)
           // console.log(values)

            const resp = await notifyService.createNotiy(values);
            //console.log(resp)

            setFormValues(initialFormValues);
            setOpenNewNotify(false)

        }

    }
    return (
        <>
            <Modal
                open={openNewNotify}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'auto',
                    bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p: 3
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Typography>Nuevo Recordatorio</Typography>
                        <form onSubmit={handleSubmit} >
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="usuario">Asigne Usuario</InputLabel>
                                        <Select
                                            labelId="usuario"
                                            id="usuario"
                                            size="small"
                                            open={openCombo}
                                            error={error === "usuario" ? true : false}
                                            fullWidth
                                            onClose={handleCloseCombo}
                                            onOpen={handleOpenCombo}
                                            name="usuario"
                                            value={usuario}
                                            label="Asigne Usuario"
                                            onChange={handleInputChange}
                                        >
                                            {dataUser.map((option, i) => {
                                                return <MenuItem key={i} value={option.idusuario}>{option.nombre}</MenuItem>
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "titulo" ? true : false}
                                        helperText={error === "titulo" ? " La titulo es un campo requerido" : ""}
                                        size="small"
                                        id="titulo"
                                        label="Titulo*"
                                        name="titulo"
                                        type="text"
                                        value={titulo}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "descripcion" ? true : false}
                                        helperText={error === "descripcion" ? " El Cliente es un campo requerido" : ""}
                                        size="small"
                                        id="descripcion"
                                        label="Descripcion*"
                                        name="descripcion"
                                        type="text"
                                        value={descripcion}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "fecha" ? true : false}
                                        helperText={error === "fecha" ? " La Fecha de Inicio es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        size="small"
                                        label="Fecha"
                                        id="fecha"
                                        name="fecha"
                                        type="date"
                                        autoFocus
                                        value={fecha}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "ruta" ? true : false}
                                        helperText={error === "ruta" ? " La ruta es un campo requerido" : ""}
                                        size="small"
                                        label="Ruta"
                                        id="ruta"
                                        name="ruta"
                                        multiline
                                        value={ruta}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Button
                                        onClick={() => {
                                            setOpenNewNotify(false)
                                             setFormValues(initialFormValues)
                                        }}
                                        variant="contained"
                                        fullWidth
                                        color="error"
                                        startIcon={<CloseCircleIcon />}
                                        sx={{ mt: "10px" }}
                                    >
                                        Cancel
                                    </Button>

                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        startIcon={<DownloadIcon />}
                                        sx={{ mt: "10px" }}
                                    >
                                        Agregar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
