
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './DetailClientDoc.type';
import { FormControl, Autocomplete, Select, MenuItem, FormHelperText, Container, Divider, Grid, InputLabel, Typography, TextField, Button, Alert, Snackbar, IconButton, Card, Backdrop, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBackIconv2, PencilIcon, CancelIcon, SaveIcon, EditContactIcon } from "@toolbox/constants/icons";
import { useHistory } from 'react-router-dom'
import { ROUTE_CAMPAIGN, ROUTE_CLIENTS_DOC, ROUTE_CONTACT } from '@toolbox/constants/route-map';
import { DataClients } from '@service/models/Contact'
import { ConfirmDialog } from '@/components/common/DialogConfirm';
import { makeStyles } from "@mui/styles";
import { SpinnerGrow } from '@/components/common/Spinner';
import logokyte from "@assets/img/vacio.png";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA, KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import moment from 'moment';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { TableData } from '@/components/common/Table';
import img_pdf from "@assets/img/ico_pdf.png";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { bypassService } from '@/service/services/Bypass.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';


export const DetailClientDocView: React.FC<any> = (props): JSX.Element => {
    const idClientDocument = props.location.pathname.split("/")[2];
    console.log(idClientDocument)
    const nombreClient = props?.location?.state?.nombre_rz;
    const [dataURL, SetDataURL] = useState<any>([])
    const [urlEstado, setUrlEstado] = useState<any>('')
    const [openView,setOpenView] = useState<boolean>()

    useEffect(()=>{
        getURLDocs();
    },[])

    const getURLDocs = async() => {
        setOpenView(true)
        if(idClientDocument)
        {
            const resp = await bypassService.getClientDocumentsURL(idClientDocument);
            if(resp.data)
            {
                SetDataURL(resp?.data.documentos_antecedentes)
                setUrlEstado(resp?.data.documentos_situacion[0]?.ruta)
            }
           
        }

        setOpenView(false)
        // if(resp.data.length>0)
        // {
        //     // console.log(resp)
        //     // SetDataURL(resp?.data[0]?.documentos_antecedentes)
        //     // setUrlEstado(resp?.data[0]?.documentos_situacion[0]?.ruta)
        // }
       
    }

   return (
       <Protected>
           <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
           <Grid item md={12} container flexDirection='row'>
                  <IconButton component={Link} to={ROUTE_CLIENTS_DOC} sx={{ mr: 2 }}>
                     <ArrowBackIcon fill="black" />
                  </IconButton>
                  <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '2.5em' }} >
                     {nombreClient}
                  </Typography>
            </Grid>
            <Divider sx={{ mt: 3, mb: 4 }} />
           <Typography sx={{ color: '#0E74FF', fontWeight: '700', fontSize: '2em' }}>Estado - Situación</Typography>
                {console.log(urlEstado)}
                {console.log(dataURL)}
           {urlEstado && <Grid item xs={3}>
               <Grid item container flexDirection='column' justifyContent={'center'}>
                   <a href={`${API_URL_BASE}/${urlEstado}`} target='_blank' style={{ textDecoration: 'none' }}>
                       <Card sx={{ maxWidth: 150 }}>
                           <CardActionArea>
                               <CardMedia
                                   component="img"
                                   style={{
                                       height: 120,
                                       width: 120,
                                   }}
                                   image={img_pdf}
                               />
                               <CardContent>
                                   <Typography style={{ fontSize: '12px', textTransform: 'none', textAlign: 'center', color: '#155172' }}>
                                       Situación - Estado
                                   </Typography>
                               </CardContent>
                           </CardActionArea>
                       </Card>
                   </a>
               </Grid>
           </Grid>}
           <Divider sx={{ mt: 3, mb: 4 }} />
           <Typography sx={{ color: '#0E74FF', fontWeight: '700', fontSize: '2em' }}>Antecedentes</Typography>

           <Grid container direction="row" justifyContent="center" alignItems="center" xs={12}>
               {dataURL.map((value,i)=>{
                  
                    return (
                        <Grid key={i} item container direction="row" justifyContent="center" alignItems="center" xs={3} sx={{p:2}}>
                              <Card sx={{ maxWidth: 150 }}>
                         <CardActionArea>
                            <Grid item container direction="row" justifyContent="center" xs={12} >
                                <a href={`${API_URL_BASE}/${value.ruta}`} target='_blank'>
                                    <CardMedia
                                        component="img"
                                        style={{
                                            height: 120,
                                            width: 120,
                                        }}
                                        src={img_pdf}
                                    />
                                </a>
                            </Grid>
                            <CardContent>
                            <Grid item container direction="row" justifyContent="center" xs={12}>
                                <span style={{ fontSize: '12px', textTransform: 'none', textAlign: 'center', color: '#155172' }}>
                                    {value.nombre}
                                </span>
                            </Grid>
                            </CardContent>
                            </CardActionArea>
                         </Card>
                        </Grid>
                    )
                  
               })}
              
           </Grid>
       </Protected>
   )
};
