import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const idempresacliente = readLocalStorage(KEY_USER_DATA);
export const proyeccionRepository = {
   getProyeccionData: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const proyeccion = await http.get<any>(`${API_URL_BASE}/v1/proyeccion/data?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = proyeccion;
      return {
         data,
         error,
         message
      }
   },
   getProyeccion: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const proyeccion = await http.get<any>(`${API_URL_BASE}/v1/proyeccion?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = proyeccion;
      return {
         data,
         error,
         message
      }
   },
   getHitosByProyeccion: async (idProyeccion): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const proyeccion = await http.get<any>(`${API_URL_BASE}/v1/proyeccion/hitos?idproyeccion=${idProyeccion}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = proyeccion;
      return {
         data,
         error,
         message
      }
   },
   getProyeccionById: async (idproyeccion,idhito): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const proyeccion = await http.get<any>(`${API_URL_BASE}/v1/proyeccion/idproyeccion?idproyeccion=${idproyeccion}&idhito=${idhito}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = proyeccion;
      return {
         data,
         error,
         message
      }
   },
   getHitoById: async (idhito): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const hito = await http.get<any>(`${API_URL_BASE}/v1/hito/idhito?idhito=${idhito}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = hito;
      return {
         data,
         error,
         message
      }
   },
   createProyeccion: async (dataProyeccion): Promise<any> => {
      console.log(dataProyeccion);
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/proyeccion`, {
         hitos: dataProyeccion.hito,
         nombre: dataProyeccion.nombre,
         idtipo: dataProyeccion.tipoproyeccion,
         fecha_inicio: dataProyeccion.fechainicio,
         fecha_fin: dataProyeccion.fechafin,
         idfrecuencia: 1,
         idprioridad: dataProyeccion.prioridad,
         id_empresa: id_empresa,
         // idarea_negocio: dataProyeccion.area,
         idarea_negocio:dataProyeccion.idsArea
      })
      return {
         // error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

}
