import { ArriendosService } from "@/service/services/Arriendos.service.";
import {
    Alert,
    Box,
    Button,
    Chip,
    Divider,
    Drawer,
    FormControl,
    FormLabel,
    IconButton,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const DrawerDetailInventory: React.FC<any> = (props): JSX.Element => {
    const { openDrawer, setOpenDrawer, data } = props;
    console.log(data)
    const history = useHistory();
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
     })
  
    const dataDetalleInventario = [
        { codigo: 541, is_arriendo: true, cod_arriendo: 'A001', estado: 'Disponible' },
        { codigo: 542, is_arriendo: false, cod_arriendo: null, estado: 'En uso' },
        { codigo: 543, is_arriendo: true, cod_arriendo: 'A002', estado: 'Mantenimiento' },
        { codigo: 544, is_arriendo: false, cod_arriendo: null, estado: 'Disponible' },
        { codigo: 545, is_arriendo: true, cod_arriendo: 'A003', estado: 'En revisión' },
        { codigo: 546, is_arriendo: false, cod_arriendo: null, estado: 'En uso' },
        { codigo: 547, is_arriendo: true, cod_arriendo: 'A004', estado: 'Disponible' },
        { codigo: 548, is_arriendo: false, cod_arriendo: null, estado: 'Mantenimiento' },
        { codigo: 549, is_arriendo: true, cod_arriendo: 'A005', estado: 'En uso' },
        { codigo: 554, is_arriendo: false, cod_arriendo: null, estado: 'En revisión' }
    ];


    const [openLoading, setOpenLoading] = useState<boolean>(false);
  
    const [dataDetailInventory, setDataDetailInventory] = useState<any>([]);
    const [totalNotCode, setTotalNotCode] = useState<any>('')
    
    const [newCodigo, setNewCodigo] = useState('');
    const [arrayNewCodes, setArrayNewCodes] = useState<any>([]);
    const [openAsigned, setOpenAsigned] = useState<boolean>(false);

    const handleCodeToggle = (code) => {

        if(arrayNewCodes.length == totalNotCode) {
            setSnackBarConfig({...snackBarConfig, open: true, severity: 'warning', message: 'No puede ingresar mas códigos'});
            return;
        }

        if (arrayNewCodes.includes(code) || dataDetailInventory.find( item => item.code == code)) {
           setSnackBarConfig({...snackBarConfig, open:true, severity: 'warning',message:'El código que desea ingresar ya existe.'})
            return;
        }

        setArrayNewCodes((prevCodes) => [...prevCodes, code]);
    };

    const handleDelete = (codeToDelete) => {
        setArrayNewCodes((prevCodes) =>
            prevCodes.filter((code) => code !== codeToDelete)
        );
    };

    useEffect(() => {
        getCodesProductsArriendo()
    },[])

    const getCodesProductsArriendo = async () => {
        setOpenLoading(true);
        const response = await ArriendosService.getCodesProductsArriendo(data.id_producto);
  
        if (!!response?.status) {
            console.log(response?.data?.dataCode)
            setDataDetailInventory(response?.data?.dataCode)
            setTotalNotCode(response?.data?.total_no_code)
        } else {
           setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpenLoading(false);
     }

     const sendCodesProducts  = async() => {

        const payload = {
            idproducto : data.id_producto,
            codes: JSON.stringify(arrayNewCodes)
        }

        const response = await ArriendosService.postCodesProducts(payload);
        if (!!response?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'success' })

         } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
         }

         await getCodesProductsArriendo();
         setArrayNewCodes([])
         setNewCodigo('')
     }

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
            <Box
                sx={{
                    width: "450px",
                    padding: "20px",
                    paddingX: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                    Detalle de inventario
                </Typography>

                <Typography sx={{mb:3}}>{data.nombre_producto}</Typography>
                <Typography>{data.stock} producto(s) encontrado(s)</Typography>
                <Typography>{data.productos_disponibles} disponibles</Typography>
                <Typography>{data.productos_arrendados} arrendados</Typography>

                <Typography>Existen {totalNotCode} producto(s) sin código asignado</Typography>
                <div style={{padding:'10px'}}>
                {/* <Button variant="contained" fullWidth onClick={()=>setOpenAsigned(true)}>Asignar</Button> */}

                </div>

                {
                    !!dataDetailInventory  && dataDetailInventory.length > 0 && 
                    <div style={{
                        borderBottom: '3px solid #155172', // Línea inferior gruesa
                        borderTop: '3px solid #155172', // Línea superior gruesa solo para el primer elemento
                        padding: '10px',
                        marginBottom: '10px',
                        height: '400px',
                        overflowY: 'auto',
                        width: '400px',
                        margin: '0 auto', // Centrar el contenedor en el drawer
                    }}>
                
                        {
                             (dataDetailInventory || []).map((item, index) => (
                                <div key={item.codigo} style={{
                                    borderBottom: index <  dataDetailInventory.length - 1 ? '1px solid #ddd' : 'none', // Línea delgada entre elementos
                                    paddingBottom: '10px',
                                    paddingTop: '5px',
                                    marginBottom: '10px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px'
                                }}>
                                    <>
                                        <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000' }}>
                                            {`cod. ${item?.code}`}
                                        </Typography>
                
                                        {
                                            item?.idarriendoproducto ?
                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                            <Typography sx={{ color: '#606060' }}>
                                                    {`Arriendo # ${item?.cod_arriendo} `}
                                                </Typography> 
                                                <IconButton onClick={() => {history.push(`/detalle-arriendos`, {id: item?.idarriendo})}}>
                                                <VisibilityIcon/>
                                                </IconButton>
                                            </div>

                                                :
                                                <Typography sx={{ color: '#606060' }}>
                                                    Producto Disponible
                                                </Typography>
                                        }
                                        <Typography sx={{ color: '#606060' }}>
                                            {item?.estado}
                                        </Typography>
                
                                    </>
                
                                </div>
                            ))
                        }
                
                    </div>
                }

               

                <div style={{ width: '400px', margin: '0 auto', textAlign: 'center', marginTop: '40px', padding: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                        <TextField
                            name="codigo"
                            type="number"
                            placeholder="Introduce un código"
                            onChange={(e) => setNewCodigo(e.target.value)}
                            size="small"
                            style={{ marginRight: '10px', flex: '1' }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => handleCodeToggle(newCodigo)}
                            style={{ flex: '1' }}
                        >
                            Agregar
                        </Button>
                    </div>

                    <div style={{
                        width: '350px', margin: '0 auto',
                        justifyItems: 'flex-start', marginTop: '20px', padding: '20px', border: '1px solid #ddd'
                    }}>
                        {arrayNewCodes ? arrayNewCodes.map((itemCode, index) => (
                            <Chip
                                key={index}
                                label={itemCode}
                                onDelete={() => handleDelete(itemCode)}
                                sx={{
                                    maxWidth: '100%',
                                    marginBottom: '5px',
                                    fontSize: '18px',
                                    backgroundColor: '#4CAF50',
                                    color: '#fff',
                                    marginRight: '5px',
                                }}
                            />
                        )) : null}
                    </div>
                    <div style={{padding:'10px'}}>
                <Button variant="contained" fullWidth onClick={()=>{sendCodesProducts()}}>Guardar Cambios</Button>

                </div>

                </div>

            </Box>
            {

            }
        </Drawer>

    );
};
