import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { indigo } from '@mui/material/colors';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { bypassService } from '@/service/services/Bypass.service';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { Link, useHistory } from 'react-router-dom';
import { ModalSearchProduct } from '@/components/common/Modal/ModalSearchProduct';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { ModalProspectoRapido } from './ModalProspectoRapido';
import { propsToClassKey } from '@mui/styles';
import { mailService } from '@/service/services/Mail.service';
import { ModalCampaignCorreo } from './ModalCampaignCorreo';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data:any,
    dataClient?:any,
    setDataClient?:any
}


export const ModalCorreoCliente: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {

    //aqui los states
    const [decision, setDecision] = useState<number>(0) // 1= enlazarProspecto, 2=crearProspectoRapido
    const [valueClient, setValueClient] = useState<any>();
    const [rutCliente, setRutCliente] = useState<any>();
    const [prospectoRut, setProspectoRut] = useState<any>({})

    const [openModalProspecto,setOpenModalProspecto] = useState<boolean>(false)
    const [openModalCampaignCorreo, setOpenModalCampaignCorreo] = useState<boolean>(false)
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

    useEffect(()=>{
      if(props.dataClient.length == 1){
         setProspectoRut(props.dataClient[0])
         setOpenModalCampaignCorreo(true)
      }
    },[])

    const EnlazarProspecto = async () => {
      console.log(rutCliente)
      console.log(props.data)
      const dataProspecto = {
         rut_cliente : rutCliente,
         data_correo: JSON.stringify(props.data).replace(/\\n/g, ''),
         uid_correo: props.data.UID,
         correo: props.data.mail
      }
      const resProspecto:any = await mailService.postProspectoCliente(dataProspecto);

      if(!resProspecto.data){
         console.log('false')
         return setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message:'No se ha encontrado clientes con el RUT especificado'})
      }

      setSnackBarConfig({...snackBarConfig, open:true, severity:'success',message:'Cliente encontrado con éxito'})
      // abrimos modal para encontrarle una campaña o lanzarla a la campaña general
      setProspectoRut(resProspecto.data);
      setOpenModalCampaignCorreo(true);
      console.log('todo ok')
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
           const name = event.target.name;
           const value = event.target.value;
           switch (name) {
              case 'search':
                 // if (value.length == 10) {
                    setRutCliente(value);
                 // }
                 break;
           }
        } catch (e) {
           console.log(e)
        }
     };
    // cuerpo de cuando hay clientes
    const bodyClient = (
      <Grid container justifyContent={'center'} paddingX={8} mt={3} maxHeight={'30vh'} sx={{overflowY:'scroll'}}>
      {
          props.dataClient.map((value)=>{
            return(
                  <ListItem>
                     <ListItemButton sx={{border:'2px solid black'}} onClick={()=>{setProspectoRut(value);setOpenModalCampaignCorreo(true);}}>
                     <ListItemText
                     primary={
                        <Typography>{value.rut_cliente} - {value.nombre_rz}</Typography> 
                     }
                     ></ListItemText>
                     </ListItemButton>
                  </ListItem>
            )
         })
      }
      </Grid> 
    )

    //cuerpo de cuando no hay clientes
    const bodyNotClient = (
        <Grid container spacing={2} mt={1}>
            <Grid item md={6}>
                <Button
                sx={{
                    background: '#155172', mt: "10px", color: '#fff', '&:hover': {
                       bgcolor: '#155172'
                    }
                 }}
                 fullWidth
                 style={{textTransform: "inherit"}}
                onClick={()=>{
                setDecision(1);}}>Buscar el cliente por RUT</Button>
            </Grid>
            <Grid item md={6}>
                <Button
                sx={{
                    background: '#E4980D', mt: "10px", color: '#fff', '&:hover': {
                       bgcolor: '#E4980D'
                    }
                 }}
                 fullWidth
                 style={{textTransform: "inherit"}}
                onClick={()=>{
                setDecision(2); setOpenModalProspecto(true)}}>Crear el prospecto de manera rápida</Button>
            </Grid>
        </Grid> 
    )

    //cuerpo cuando decision es igual a 1
    const bodyEnlazarCliente = (
       <>
               <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ mt: 3 }}>
                  <span>Ingrese el rut del cliente</span>
                  <br /><br />
                  <TextField
                     fullWidth
                     InputLabelProps={{ shrink: true }}
                     sx={{ bgcolor: '#fff', mb: 2 }}
                     size="small"
                     placeholder='Ejm: 71453722-2'
                     id="search"
                     name="search"
                     type="search"
                     autoFocus
                     value={valueClient}
                     onChange={handleInput}
                  />
                  <Grid item container direction={'row'} justifyContent='end' sx={{ mt: 2 }}>
                     <Button variant='contained' color={'error'} onClick={() => { setDecision(0) }} sx={{ mr: 2 }}>
                        Volver
                     </Button>
                     <Button variant='contained' onClick={() => { EnlazarProspecto() }}>
                        Enlazar prospecto
                     </Button>
                  </Grid>
               </Grid>
       </> 
    )

    const bodyCrearProspectoRapido = (
        <>
        </>
    )

    const TituloModal = (
          props?.dataClient.length > 0 ? <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
            {`Hemos encontrado este correo en ${props.dataClient.length} clientes, selecciona a cual pertenece`}
            </Typography>
            : decision == 0 ?<Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
            {`No tenemos registrado este correo, tenemos dos opciones para ti`}
            </Typography>: decision == 1?<Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
            {`Enlazar Prospecto`}
            </Typography>:<></>
    )

    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>

           {TituloModal}
           
            {props.dataClient.length > 0 ?bodyClient:
            decision == 0 ? bodyNotClient: decision == 1? bodyEnlazarCliente: <></>}

        </Box> 
    )
    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
            >
                    {bodyModal}
            </Modal>
           {openModalProspecto && <ModalProspectoRapido
           open={openModalProspecto}
           setOpen={setOpenModalProspecto}
           dataCorreo={props.data}
           setOpenAnt={props.setOpen}
           setDecision={setDecision}/> } 
           {
            openModalCampaignCorreo && <ModalCampaignCorreo
            open={openModalCampaignCorreo}
            setOpen={setOpenModalCampaignCorreo}
            data={prospectoRut}
            setData={setProspectoRut}
            setOpenAnt = {props.setOpen}
            dataCorreo = {props.data}
            />
           }
        </div>
    );
}
