import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { indigo } from '@mui/material/colors';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { bypassService } from '@/service/services/Bypass.service';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { Link, useHistory } from 'react-router-dom';
import { ModalSearchProduct } from '@/components/common/Modal/ModalSearchProduct';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { ModalProspectoRapido } from './ModalProspectoRapido';
import { propsToClassKey } from '@mui/styles';
import { mailService } from '@/service/services/Mail.service';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data:any,
    setData?:any,
    dataClient?:any,
    setDataClient?:any,
    setOpenAnt?:any,
    setOpenPreAnt?:any,
    dataCorreo?:any
}


export const ModalCampaignCorreo: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props)

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [campaignOptions, setCampaignOptions] = useState<any>([])
   const [nombreCampaign, setNombreCampaign] = useState<any>('')
   const [openDetail, setOpenDetail] = useState<boolean>(false)
   const userData = readLocalStorage(KEY_USER_DATA);
   console.log(userData)
    useEffect(()=>{
        console.log(props.data)
        if(nombreCampaign.length > 3){
            buscar();
        }
    },[nombreCampaign])

    const enlazarCampaign = async(value) => {
        console.log(props.dataCorreo)
        const dataEnlazar = { idcampana:value.id, 
            idcliente:props.data?.idcliente,
            idtipousuario: userData.user.idtipousuario,
            idusuario: userData.user.idusuario}
        const resp:any = await mailService.enlazarCampana(dataEnlazar);
        console.log(resp)
        if(resp){
            props.setOpen(false)
            props.setOpenAnt(false)
            if(props.setOpenPreAnt){props.setOpenPreAnt(false)}
            const detalleInsert = await mailService.insertHistorialCorreo( {
                data_correo : props.dataCorreo,
                uid_correo: props.dataCorreo.UID,
                fecha_correo: props.dataCorreo.fecha,
                from_personal: props.dataCorreo.from.personal,
                body_correo: props.dataCorreo.body,
                html_correo: props.dataCorreo.html,
                id_usuario: userData.user.idusuario,
                adjuntos_correo: JSON.stringify(props.dataCorreo.attachments),
                id_detalle_campana : resp.iddetallecampananegocioclientes
            })
            setSnackBarConfig({...snackBarConfig, open:true,
            severity:'success', message:'Relacionado con éxito'})
        }
    }

    const buscar = async() => {
        const resp:any = await mailService.buscarCampaign(nombreCampaign);
        if(resp){
            setCampaignOptions(resp);
        }
    }

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
           const name = event.target.name;
           const value = event.target.value;
           switch (name) {
              case 'search':
                 // if (value.length == 10) {
                    setNombreCampaign(value);
                 // }
                 break;
           }
        } catch (e) {
           console.log(e)
        }
     };

    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Busque la campaña ideal para este prospecto`}
            </Typography>
            <TextField
            placeholder='Indique el nombre de la campaña'
            name='search'
            fullWidth
            value={nombreCampaign}
            type="search"
            autoFocus
            onChange={handleInput}
            />
             
      <Grid container justifyContent={'center'} paddingX={8} mt={3} maxHeight={'30vh'} sx={{overflowY:'scroll'}}>
      {
          (campaignOptions || []).map((value)=>{
            return(
                  <ListItem sx={{border:'2px solid black', mb:2}}>
                     <ListItemButton  onClick={()=>{enlazarCampaign(value)}}>
                     <ListItemText
                     primary={
                        <>
                        <Typography>{value.nombre}</Typography> 
                        </>     
                     }
                     ></ListItemText>
                     </ListItemButton>
                     <IconButton><VisibilityIcon/></IconButton> 
                  </ListItem>
            )
         })
      }
      </Grid> 
        </Box> 
    )

    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
            >
                    {bodyModal}
            </Modal>
        </div>
    );
}
