import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './User.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, Container, Divider, Grid, InputLabel, TextField, Typography, Button, Alert, Snackbar, CircularProgress, Backdrop } from '@mui/material';
import { ModalCreateContact } from '@/components/common/Modal';
import { TableData } from '@/components/common/Table';
import { TabsComponent } from '@/components/common/Tabs/index';
import { ROUTE_ENTITY_CREATE, ROUTE_ENTITY_USERS, ROUTE_USER_CREATE, ROUTE_USER_UPDATE } from '@/toolbox/constants/route-map';
import { contactService } from '@/service/services/Contact.service';
import { userService } from '@/service/services/User.service';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { entityService } from '@/service/services/Entity.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import userReducer from '@/redux/reducers/user.reducer';
import { MenuLateral } from '@/components/common/LateralMenu';
import { SpinnerGrow } from '@/components/common/Spinner';
import { ModalChangeParticipacion } from '@/components/common/Modal/ModalChangeParticipacion';

export const UserView: React.FC<Props> = (props: Props): JSX.Element => {
   const header = [
      { name: 'nombre', label: 'Nombre', width: '' },
      { name: 'email', label: 'Email', width: '' },
      { name: 'telefono', label: 'Telefono', width: '' },
      { name: 'user_nick', label: 'UserNick', width: '' },
      { name: 'status_confirm', label: 'Estado de Confirmación', width: '', 
      formated: (row) => row.status_confirm == "1" ? 'Confirmado' : 'Pendiente'},
      { name: 'concepto', label:'Rol', width:''},
   ]
   const dataAction = [
      { name: 'view', color: 'success', route: ROUTE_USER_UPDATE },
      { name: 'edit', color: 'primary', route: ROUTE_USER_UPDATE },
      { name: 'delete', color: 'secondary', route: null },
      { name: 'ResendPassword', color: 'success', route: null }
   ]
   const [open, setOpen] = useState(false);
   const [openChangeParticipacion, setOpenChangeParticipacion] = useState(false);
   const [dataUser, setDataUser] = useState<any>([])
   const [dataUserModal, setDataUserModal] = useState<any>([])
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      userId: null,
      message: `¿Desea eliminar al usuario ---?`
   })

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 8000,
   })

   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0)


   useEffect(() => {

      try{
         const data = readLocalStorage(KEY_USER_DATA)
         getUsers()
         getUsersModal()
      //getUser();
      }catch(e){
         console.log(e)
      }
   }, [pageData,perPageData])

   async function getUsers() {
      setOpen(true)
      const resp = await userService.getUserPage(perPageData,pageData);
      console.log(resp)

      setDataUser(resp.data.data)
      setOpen(false)
      setTotal(resp.data.total)
   }

   async function getUsersModal() {
      const resp = await userService.getUser();
      // const arrayUser = []
      //     resp.data.map((value,i)=>{
      //       let rol = ''
      //       if(value.idtipousuario == 2){
      //          rol = 'Administrador'
      //       }else if(value.idtipousuario == 3){
      //          rol = 'Supervisor'
      //       }else if(value.idtipousuario == 4){
      //          rol = 'Vendedor'
      //       }
      //       // resp.data[i] = {...value, rol:rol}
      //       arrayUser.push({...value, rol:rol});
      //    })
      //    console.log(arrayUser)
      //const userSales = resp.data.filter(data => data.idtipousuario == 4)
      setDataUserModal(resp.data)

    }

   const PerPage = async (data) =>{

      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) =>{

      setPageData(data+1)
   }

   const getSearchUser = async (search) => {
      try{
         const resp= await userService.getUserSearch(search);
         // const arrayUser = []
         //  resp.data.map((value,i)=>{
         //    let rol = ''
         //    if(value.idtipousuario == 2){
         //       rol = 'Administrador'
         //    }else if(value.idtipousuario == 3){
         //       rol = 'Supervisor'
         //    }else if(value.idtipousuario == 4){
         //       rol = 'Vendedor'
         //    }
         //    // resp.data[i] = {...value, rol:rol}
         //    arrayUser.push({...value, rol:rol});
         // })
         // console.log(arrayUser)
      //const userSales = resp.data.filter(data => data.idtipousuario == 4)
      //setDataUserModal(arrayUser)
         setDataUser(resp.data);

       }catch(e){
          console.log(e)
       }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  getSearchUser(value)
               }
               if(value.length==0)
               {
                   getUsers();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };


   const deleteUsuario = async () =>
   {
      if (Dialog.confirm == true) {
         const resp = await userService.deleteUser(Dialog.userId);
         if (resp.data) {
            const data = readLocalStorage(KEY_USER_DATA)
            getUsers()
         }
      }
   }
   const idDelete = async (dataUser) => {
      const userId = dataUser.idusuario;
      if (dataUser.action == 'ResendPassword') {
         // console.log(dataUser)
         const resp = await userService.ResendPassword(userId);
         if(resp)
         {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: 'Envío exitoso',
            }))
         }
      }else
      {
         setDialog(prev => ({ ...prev, message: `¿Esta seguro en eliminar al Usuario ${dataUser.nombre}?`,userId:userId,open: true, confirm:true}));
      }
   };
   // async function getUser() {
   //    const resp = await userService.getUser();
   //    console.log(resp)
   //    //setDataUser(resp.data)
   // }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            
              <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <Grid container flexDirection='row' spacing={2}>
               <Grid item md={3} container>
                  <MenuLateral />
               </Grid>
               <Grid item md={9}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #3F9BCC ', mt: 3 }}
               >
                  <Grid container sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }}>
                     {/* <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                           Cargando Data!
                        </Alert>
                     </Snackbar> */}
                     <ConfirmDialog
                        open={Dialog.open}
                        title={Dialog.title}
                        message={Dialog.message}
                        onConfirm={() => deleteUsuario()}
                        onClose={() => setDialog(prev => ({ ...prev, open: false }))}
                     />

                     <Grid container rowSpacing={2}>
                        <Grid item container xs={6} rowSpacing={2} >
                           <Grid item xs={12}>
                              <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>{`Mis Usuarios`}</Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <Button variant='contained'
                              sx={{background:'#bf6c00', color:'#fff', '&:hover':{
                                 bgcolor:'#bf6c00'
                                }}}
                              onClick={()=>{setOpenChangeParticipacion(true)}}>Actualizar Participación</Button>
                              <Button variant='contained'
                               sx={{background:'#155172',ml:2, color:'#fff','&:hover':{
                                 bgcolor:'#155172'
                                }
                              }}
                              component={Link} to={ROUTE_USER_CREATE}>Agregar</Button>
                           </Grid>
                        </Grid>
                        <Grid item xs={6} >
                           <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="search"
                              value={search}
                              onChange={handleInput}
                              placeholder="Buscar"
                              type="search"
                              id="password"
                              autoComplete="current-password"
                              size="small"
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${Total? `${Total}` : `0`} Registros encontrados`}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} >
                           <TableData
                              header={header}
                              data={dataUser}
                              action={dataAction}
                              RecuperarData={idDelete}
                              total={Total? Total:0}
                              perPage={PerPage}
                              page={page} />
                        </Grid>
                     </Grid>

                  </Grid>
               </Grid>
            </Grid>
         </Container>
         <ModalChangeParticipacion
         open={openChangeParticipacion}
         setOpen={setOpenChangeParticipacion}
         data={dataUserModal}
         />
      </Protected>
   )
};
