import {DetailCampaignGeneralView as DetailCampaignGeneral} from './DetailCampaignGeneral';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetDataCampana } from '@/redux/actions';

// const mapStateToProps = (state) => ({...state})

const mapStateToProps = ({ CampanaReducer }) => ({
   $store: {
     CampanaReducer,
   },
 });
 const mapDispatchToProps = (dispatch) => ({
   $action: bindActionCreators(
     {
       actionSetDataCampana: actionSetDataCampana
     },
     dispatch
   ),
 });


//export default connect(mapStateToProps)(withRouter(Home));

//export const LoginView: any = connect(mapStateToProps,NotificationsAction)(withRouter(Home));

export const DetailCampaignGeneralView: any = compose(
   withRouter,
   connect(mapStateToProps, mapDispatchToProps)
)(DetailCampaignGeneral);
