import { Typography } from "@mui/material"

export const DatosClienteFacturacion: React.FC<any> = (props): JSX.Element => {

    const { dataArriendo } = props;

    return (
        <div style={{ minHeight: "200px", background: "#fff", padding: "20px", borderRadius: "8px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
            <Typography variant="h4" sx={{ color: "#155172", fontWeight: "bold" }}>
                Datos de Facturación
            </Typography>
            <div style={{ marginTop: "20px" }}>
                <Typography>
                    <strong>RUT:</strong> {dataArriendo?.rut_cliente_facturacion}
                </Typography>
                <Typography>
                    <strong>Nombre:</strong> {dataArriendo?.nombre_rz_facturacion}
                </Typography>
                <Typography>
                    <strong>Giro:</strong> {dataArriendo?.giro_facturacion}
                </Typography>
                <Typography>
                    <strong>Email:</strong> {dataArriendo?.email_facturacion}
                </Typography>
            </div>
        </div>
    )
}