import { cotizacionRepository } from "../repositories/Cotizacion.repository";

export const cotizacionService = {
   cotizacionProducto,
   consultaDocumento,
   cotizacionesCRM,
   cotizacionesChangeUser,
   cotizacionesChangeEstado,
   cotizacionMail,
   cotizacionInsert,
   getCotizaciones,
   getCotizacionByDetalleCampana
};

function cotizacionProducto(token: any, cotizacion:any) {
   return cotizacionRepository.cotizacionProducto(token, cotizacion);
}

function consultaDocumento(token: any, documento:any) {
   return cotizacionRepository.consultaDocumento(token, documento);
}

function cotizacionesCRM(cotizations) {
   return cotizacionRepository.cotizacionesCRM(cotizations);
}

function cotizacionesChangeEstado(folio, idestado) {
   return cotizacionRepository.cotizacionesChangeEstado(folio, idestado);
}

function cotizacionesChangeUser(folio, iduser) {
   return cotizacionRepository.cotizacionesChangeUser(folio, iduser);
}

function cotizacionMail(data, mail){
   return cotizacionRepository.cotizacionMail(data, mail);
}

function cotizacionInsert(cotizacion){
   return cotizacionRepository.cotizacionInsert(cotizacion);
}

function getCotizaciones(iddetallecampana){
   return cotizacionRepository.getCotizaciones(iddetallecampana)
}

function getCotizacionByDetalleCampana(iddetallecampana){
   return cotizacionRepository.getCotizacionByDetalleCampana(iddetallecampana)
}


