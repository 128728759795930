import { SpinnerGrow } from "@/components/common/Spinner";
import { TableData } from "@/components/common/Table";
import { Protected } from "@/components/layout/Protected";
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { Alert, Backdrop, Container, Grid, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const header = [
    { name: 'cod_generado_pago', label: 'Codigo del Sitema', width: '' },
    { name: 'cod_pago', label: 'Codigo pago adjunto', width: '' },
    { name: 'img_pago', label: 'Imagen adjunta', width: '' },
    { name: 'monto', label: 'Monto', width: '' },
    { name: 'fecha_pago', label: 'Fecha de pago', width: '' },
    { name: 'status_pay_name', label: 'Estado', width: '' },
 ];

 const dataAction = [
    { name: 'aceptar', color: 'success' },
    { name: 'rechazar', color: 'success'},
 ]

export const PagosArriendosView: React.FC<any> = (props): JSX.Element => {

    const data = props?.location?.state;

    const [open, setOpen] = useState<boolean>(false);
    const [dataPagos, setDataPagos] = useState<any>(null);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
     })
     const [perPageData, setPerPageData] = useState<number>(5);
     const [pageData, setPageData] = useState<number>(1)
     const [Total, setTotal] = useState<any>(0)
  


     useEffect(()=> {
        getArriendos();
     },[])

     async function getArriendos() {
        setOpen(true);
        const response = await ArriendosService.listarPagosByArriendo(data?.id);
        console.log(response)
        if(!!response?.status){
            setDataPagos(response?.data?.cobranzas)
        }else{
           setSnackBarConfig({...snackBarConfig, open:true, message: response?.message, severity: 'error'})
        }
        setOpen(false);
     }

     const confirmarPago = async (idpago) => {
        const response = await ArriendosService.confirmarPago(idpago);
        if(!!response){
            await getArriendos();
        }
    }

     const recuperarData = (data) => {
        switch ( data.action ) {
           case 'aceptar':
                confirmarPago(data?.id)
              return;
           case 'rechazar':

              return;
        }
      
     }

     const PerPage = async (data) =>{
        setPerPageData(data)
        setPageData(0)
     }
  
     const page = async (data) =>{
        setPageData(data+1)
     }
  
    
    return (
        <>
        <Protected>
          <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container maxWidth='xl'>
         <Grid container justifyContent='space-between' spacing={3}>
               <Grid item xs={5} container alignItems="center">
               <Typography  sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}} > Pagos</Typography>
               </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
               <TableData
                  header={header}
                  data={dataPagos?dataPagos:[]}
                  action={dataAction}
                  RecuperarData={recuperarData}
                  total={Total? Total:0}
                  perPage={PerPage}
                  page={page}
                  />
            </Grid>
         </Container>
      </Protected>
        </>
    )
};