import React, { useEffect, useState } from 'react';
import { Grid, TextField, Divider, Autocomplete, Dialog, DialogTitle, Modal, Snackbar, Alert, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';
import { TableData } from '@/components/common/Table';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';


type ModalProps = {
    open: boolean,
    setOpen: any,
    userTop:any
 }

export const ModalUserSalesTop: React.FC<ModalProps> = ({
    open,
    setOpen,
    userTop,
}): JSX.Element => {

    const [dataDetalle, setDataDetalle] = useState<any>([]);
    const [filter, setFilter] = useState(null);
    // useEffect(() => {
    //     setDataDetalle(getData)
    // }, [getData])
    const emails = ['username@gmail.com', 'user02@gmail.com'];
    return (
        <div>

            <Modal
                open={open}
                onClose={() => { setOpen(false) }}
            >
                <Box className='Modal' sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-65%, -70%)',
                    width: 300,
                    borderRadius: '20px',
                    boxShadow: 24,
                    p: 1,
                }}>
                    <DialogTitle sx={{ textAlign: 'center' }}>Ranking de usuarios por ventas del mes</DialogTitle>
                    <Divider/>
                    <Divider/>
                    <List sx={{ pt: 0 }}>
                        <ListItem  >
                            <ListItemAvatar sx={{ mr: 12 }}>
                                {'N° ventas'}
                            </ListItemAvatar>
                            <ListItemAvatar >
                                {'Vendedor'}
                            </ListItemAvatar>
                            {/* <ListItemText primary={'Vendedor'} /> */}
                        </ListItem>
                        <Divider/>
                        <Divider/>
                        {userTop.map((user, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar sx={{ mr: 12 }}>
                                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                        {/* <PersonIcon /> */}
                                        {user.cont_cliente}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={user.nombre} />
                            </ListItem>

                        ))}
                    </List>
                </Box>
            </Modal>
        </div>

    );
}
