import { ArriendosService } from "@/service/services/Arriendos.service.";
import { useEffect, useState } from "react";
import { Doughnut } from 'react-chartjs-2';

export const DoughnutEstadoGeneral: React.FC<any> = (props): JSX.Element => {
    
    const {setOpen, setSnackBarConfig, snackBarConfig } = props
    const [data, setData] = useState<any>([]);

   
    useEffect(()=>{
        getDashboardEstadoGeneral();
    },[])

    const getDashboardEstadoGeneral = async() => {
        setOpen(true);
        const response = await ArriendosService.getDashboardEstadoGeneral();
        if (!!response?.status) {
           setData(response?.data)
        } else {
           setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
     }

    return (
    <>
        <Doughnut data={data} width={42}
                           height={45} />
    </>
 )
}