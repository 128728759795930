import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Card,
    Typography,
    Divider,
    Avatar,
    CircularProgress,
    Checkbox,
    Tooltip,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { Props } from './Bitacora.type'
import { DeleteIcon, TaskIcon } from "@/toolbox/constants/icons";
import { taskService } from '@/service/services/Task.service';
import { green, pink } from '@mui/material/colors';
import CallIcon from '@mui/icons-material/Call';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ArticleIcon from '@mui/icons-material/Article';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircleIcon from '@mui/icons-material/Circle';

export const CardBitacora: React.FC<Props> = (props: Props): JSX.Element => {
    return (
        <Grid container sx={{ mb: 2 }}>
            <Grid item>
                <Card sx={{ ml: 2, mr: 2 }}>
                    <Grid
                        container
                        flexDirection="row"
                        spacing={2}
                        sx={{ p: 3 }}
                    >
                        <Grid item xs={2} md={1} justifyItems="right">
                            <ArticleIcon color='secondary' />
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em' }}>
                                Bitacora
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em' }} align="right">
                                Fecha: {dateFormat(props.data?.created_at)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h5" sx={{ color: '#155172', fontWeight: '540', fontSize: '1.2em', textTransform: 'uppercase' }}>
                                {props.data?.idproducto == null ?
                                    'Sin producto' : props.data.producto
                                }
                            </Typography>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {(props.data?.etiquetas).lenth === 0 || props.data?.etiquetas == "Sin etiquetas"
                                    ? 'Sin etiquetas'
                                    : (props.data.etiquetas).map((value, i) => {
                                        return (
                                            <ListItem>
                                                <CircleIcon color='secondary' fontSize="small" sx={{marginRight:'5px'}} />
                                                <ListItemText primary={value} />
                                            </ListItem>
                                        )
                                     })
                                }
                            </List>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} md={12} container >
                            <Grid item container direction="row" xs={12} md={12}>
                                <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em', }}>
                                    Observaciones
                                </Typography>
                                <Grid container direction="row" item xs={12} md={12}>
                                    <Typography sx={{ fontWeight: '400', fontSize: '1.0em', textTransform: 'lowercase' }}>{props.data?.observaciones}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );

}