import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Enterprise.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, Container, Divider, Grid, InputLabel, TextField, Typography, Button, Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import { ModalCreateContact } from '@/components/common/Modal';
import { TableData } from '@/components/common/Table';
import { TabsComponent } from '@/components/common/Tabs/index';
import { ROUTE_ENTITY_CREATE, ROUTE_ENTITY_UPDATE, ROUTE_ENTITY_USERS } from '@/toolbox/constants/route-map';
import { contactService } from '@/service/services/Contact.service';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { entityService } from '@/service/services/Entity.service';
import { MenuLateral } from '@/components/common/LateralMenu';
import { SpinnerGrow } from '@/components/common/Spinner';
import AddIcon from '@mui/icons-material/Add';
import { ModalAddEntity } from '@/components/common/Modal/ModalAddEntity';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TOKEN_SOFTNET } from '@/toolbox/constants/local-storage';

export const EnterpriseView: React.FC<Props> = ({ }): JSX.Element => {
   const header = [
      // { name: 'idempresacliente', label: 'ID', width: '' },
      { name: 'rut_empresa', label: 'RUT', width: '12%' },
      { name: 'nombre_rz', label: 'Razon Social', width: '20%' },
      { name: 'giro', label: 'Giro', width: '' },
      { name: 'direccion', label: 'Direccion', width: '' },
      { name: 'telefono', label: 'Telefono', width: '14%' }]
   const dataAction = [
      { name: 'edit', color: 'primary', route: ROUTE_ENTITY_UPDATE },
      { name: 'view', color: 'success', route: ROUTE_ENTITY_UPDATE },
      { name: 'delete', color: 'secondary', route: null },
      { name: 'listUser', color: 'secondary', route: ROUTE_ENTITY_USERS }
   ]
   const [open, setOpen] = useState(true);
   const [openAddEntity, setOpenAddEntity] = useState(false);
   const [dataEntity, setDataEntity] = useState<any>([])
   const [entitySoftnet,setEntitySoftnet]= useState<any>([]);
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      entityUserId: null,
      message: `¿Desea eliminar al usuario ---?`
   })

   useEffect(() => {
      getEntity();
     // Data();
   }, [])

   async function getEntity() {
      setOpen(true)
      const resp = await entityService.getEntity()
      console.log('RESP', resp.data)
      // const dataEmpresa= resp.data.filter(entity => entity.idempresacliente > 1)
      setDataEntity(resp.data)
      setOpen(false)
   }
   const deleteEmpresa = async () => {
      if (Dialog.confirm == true) {
         const resp = await entityService.deleteEntity(Dialog.entityUserId);

         if (resp.data) {
            getEntity();
         }
      }
   }
   const idDelete = async (dataEntity) => {
      const entityUserId = dataEntity.idempresacliente;
      setDialog(prev => ({ ...prev, message: `¿Esta seguro en eliminar a la Empresa ${dataEntity.nombre_rz}?`, entityUserId: entityUserId, open: true, confirm: true }));

   };

//    const Data = async() => {
//       const Token = readLocalStorage(KEY_TOKEN_SOFTNET);
//      const entity= await entityService.dataEmpresaSoftnet(Token)
//      setEntitySoftnet(entity)
//      console.log(entity);
//   }

   //const 


   const dataCreateEntity = async() =>{

   }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               onConfirm={() => deleteEmpresa()}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />
            <Grid container flexDirection='row' spacing={2}>
               {/* <Grid item md={3} container>
            <MenuLateral/>
         </Grid> */}
               <Grid item md={12}
               // sx={{width: '100%' ,bgcolor: 'background.paper',height:'77vh',overflowX:'hidden', overflowY:'scroll',borderRadius:'0.5rem', border:'0.5px solid #eff3f5',borderLeft: '5px solid #1976D2 ', mt:3}}
               >
                  <Grid container sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }} >
                     {/* <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
               <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                  Cargando Data!
               </Alert>
            </Snackbar> */}
                     <Grid container rowSpacing={2}>
                        <Grid item container xs={6} rowSpacing={2} >
                           <Grid item xs={12}>
                              <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>Empresas</Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <Button variant='contained'
                                 sx={{
                                    background: '#bf6c00', color: '#fff', mr: 2, '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                                 startIcon={<AddIcon fontSize='large' />}
                                 component={Link} to={ROUTE_ENTITY_CREATE}>Agregar</Button>
                           </Grid>
                        </Grid>
                        <Grid item xs={6} >
                           <TextField
                              margin="normal"
                              required
                              fullWidth
                              sx={{ bgcolor: '#E3F4FD' }}
                              name="text"
                              placeholder="Buscar"
                              type="search"
                              id="password"
                              autoComplete="current-password"
                              size="small"
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${dataEntity.length ? `${dataEntity.length}` : `0`} Registros encontrados`}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} >
                           <TableData
                              header={header}
                              data={dataEntity}
                              action={dataAction}
                              RecuperarData={idDelete} />
                        </Grid>
                     </Grid>
                  </Grid>
                  <ModalAddEntity
                  open={openAddEntity}
                  setOpen={setOpenAddEntity}
                  dataEntity={entitySoftnet}
                  />
               </Grid>
            </Grid>
         </Container>
      </Protected>
   )
};
