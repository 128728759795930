import {ruleRepository} from "../repositories/Rule.repository";

export const ruleService= {
   getRule,
   getRulePage,
   getSearchRule,
   getDataInitial,
   createRule,
   getRuleType,
   updateRule,
   deleteRule,
   searchDateBussinessRule,
   searchBussinessRule
};

async function getRule () {
   const rule= await ruleRepository.getRule();
   return rule;
}
async function getRulePage (perPage, page) {
   const rule= await ruleRepository.getRulePage(perPage, page);
   return rule;
}
async function getRuleType (){
   const rule = await ruleRepository.getTypeRule()
   return rule;
}
async function getSearchRule (search) {
   const product= await ruleRepository.getSearchRule(search);

   return product
}
async function getDataInitial () {
   const dataInitial= await ruleRepository.getDataInitial();
   console.log(dataInitial)
   return dataInitial;
}
async function createRule(rule) {
   const postRule = await ruleRepository.createRule(rule);
   return postRule
}
async function updateRule(id, dataRule) {
   return await ruleRepository.updateRule(id, dataRule)
}
async function deleteRule(id) {
   return await ruleRepository.deleteRule(id)
}
async function searchDateBussinessRule (dataRule) {
   return await ruleRepository.searchDateBussinessRule(dataRule);
}
async function searchBussinessRule (dataRule) {
   return await ruleRepository.searchBussinessRule(dataRule);
}
