import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog, Container, InputLabel } from '@mui/material';
import { Paper, Box, Chip, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import { accionService } from '@/service/services/Accion.service';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { ConfirmDialog } from '../DialogConfirm';
import { indigo } from '@mui/material/colors';
import { TableData } from '../Table';
import { TabsComponent } from '../Tabs';
import { campaingService } from '@/service/services/Campaing.service';
import { entityService } from '@/service/services/Entity.service';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { styled } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const header = [
   { name: 'rut_cliente', label: 'Rut', width: '100px' },
   { name: 'nombre_rz', label: 'Razon Social', width: '150px' },
   // { name: 'cto_celular', label: 'Celular', width: '100px' }
];
const ListItem = styled('li')(({ theme }) => ({
   margin: theme.spacing(0.5),
}));

type ModalProps = {
   open: boolean,
   setOpen: any,
   action?: any,
   dataCampaing?: any,
   refreshData?: () => void,
}

export const ModalAddProspectosCampaing: React.FC<ModalProps> = (
   props: ModalProps
): JSX.Element => {

   const [open, setOpen] = useState(false)
   const [detalle, setDetalle] = useState<any>([]);
   const [valueForm, setValueForm] = useState<number>(0)
   const [search, setSearch] = useState<any>('');
   const [data, setData] = useState<any>([]);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [clients, setClients] = useState<any>([]);
   const [chipData, setChipData] = useState([]);
   const [dataClients, setDataClients] = useState([]);
   const [selectClients, setSelectClients] = useState<any>({});
   const [vendedor, setVendedor] = React.useState([]);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   const dataAction = [
      { name: 'select', color: 'primary' },
   ]

   async function searchResultados(texto) {

      const data = {
         term: texto,
      }
      const resp = await campaingService.getClientnoCampaign(props.dataCampaing.id, data)
      setData(resp.data ? resp.data : [])
   }

   const handleDelete = (chipToDelete) => () => {
      if (search) {
         getClient();
      } else {
         data.splice(0, 0, chipToDelete)
      }

      const clientsdelete = clients.filter((row) => row.idcliente !== chipToDelete.idcliente);
      setClients(clientsdelete);
      setChipData((chips) => chips.filter((chip) => chip.idcliente !== chipToDelete.idcliente));
   };
   const [iterador, setiterador] = React.useState(0);

   const setValue = (iterador, valorEntrante) => {
      if (search) {
         setSearch('')
         getClient();
      }
      let valuepos = 0;
      clients.map((value) => {
         if (value.idcliente == valorEntrante.idcliente) {
            valuepos = 1;
         }
      }
      )
      if (valuepos == 0) {
         setData((data) => data.filter((value) => value.idcliente !== valorEntrante.idcliente));
         setiterador(iterador + 1);
         setClients([...clients, valorEntrante]);
         setChipData(
            [
               ...chipData,
               valorEntrante
            ]
         );
      } else {
         valuepos = 0;
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: 'No se puede agregar usuarios iguales',
         }));
      }
   }

   useEffect(() => {
      getClient();
      getVendedor();
   }, [])

   async function getVendedor() {
      const resp = await entityService.getVendedor()
      if (resp.data) {
         setVendedor(resp.data)
      }
   }

   const addClient = async (e) => {
      const IdsClients = [];
      clients.map((row) => {
         console.log(row.idcliente)
         IdsClients.push(row.idcliente)
      })

      const data = {
         idcampana: props.dataCampaing.id,
         idclientes: JSON.stringify(IdsClients).replace(/\\n/g, ''),
         idusuario: selectClients ? selectClients.idusuario : ''
      }
      const resp = await campaingService.addClientCampaign(data)
      if (resp.data.error) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp.data.error,
         }));
      } else {
         props.setOpen(false)
         setChipData([]);
         setClients([]);
         props.refreshData();
      }
   }

   const setDataCliente = (data) => {
      setValue(iterador, data)
   }

   async function getClient() {
      const data = {
         term: '',
      }
      const resp = await campaingService.getClientnoCampaign(props.dataCampaing.id, data)
      setData(resp.data ? resp.data : [])
   }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if (value.length > 2) {
                  searchResultados(value)
               }
               if (value.length == 0) {
                  getClient()
               }
               setSearch(value);

               break;
            default:
               break;
         }
      } catch (e) {
         console.log(e)
      }
   };

   const bodyModal = (
      <>
         <Grid item xs={12} mt={2}>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Container maxWidth='xl' sx={{ mt: 1, mb: 2, maxHeight: 600, overflow: 'auto' }}>
               <Grid container spacing={1} >
                  <Grid item xs={6}>
                     <InputLabel >Filtrar Resultados</InputLabel>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="search"
                        value={search}
                        sx={{ bgcolor: '#E3F4FD' }}
                        onChange={handleInput}
                        placeholder="Introduce un Nombre o Rut Cliente"
                        type="search"
                        id="password"
                        autoComplete="current-password"
                        size="small"
                     />
                  </Grid>
                  {dataUser.user.role !== ROLE_SUPERVISOR &&
                     <Grid item xs={6} container alignContent={'end'} sx={{ mb: 1 }}>
                        <Autocomplete
                           // multiple
                           id="tags-outlined"
                           options={vendedor}
                           getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                           //defaultValue={[valueProComprados]}
                           size={'small'}
                           fullWidth
                           sx={{ bgcolor: '#fff' }}
                           // sx={{maxWidth:'28vw'}}
                           value={selectClients}
                           onChange={(e, newValue) => {
                              if(newValue)
                              {
                                 setSelectClients(newValue);
                              }
                           }}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="Asignar Vendedor"
                                 // error={error === "procomprados" ? true : false}
                                 placeholder=""
                                 fullWidth
                              />
                           )}
                        />
                     </Grid>

                  }
               </Grid>
               <Grid item xs={12}
                  sx={{
                     display: 'flex',
                     flexWrap: 'wrap',
                     listStyle: 'none',
                     p: 0.5,
                     m: 0,
                  }}
                  component="ul"
               >
                  {chipData.map((data, i) => {
                     return (
                        <ListItem key={i}>
                           <Chip
                              // icon={icon}
                              color="primary"
                              variant="outlined"
                              label={data.nombre_rz}
                              onDelete={data.nombre_rz === data.nombre_rz ? handleDelete(data) : undefined}
                           />
                        </ListItem>
                     );
                  })}
               </Grid>
               <Grid container >
                  <TableData
                     header={header}
                     data={data}
                     // total={Total.clients? Total.clients:0}
                     action={dataAction}
                     // perPage={PerPage}
                     // page={page}
                     RecuperarData={setDataCliente}
                  />

               </Grid>
            </Container>
            <Grid item container
               direction="row"
               justifyContent="end"
               alignItems="center">
               <Box sx={{ position: 'relative', mr: 3 }}>
                  <Button
                     variant='contained'
                     onClick={addClient}
                     // disabled={loading2}
                     startIcon={<SaveIcon />}
                     // disableElevation
                     sx={{
                        background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                           bgcolor: '#155172'
                        }
                     }}
                  >
                     <span>Guardar</span>
                  </Button>
               </Box>
            </Grid>
         </Grid>
      </>
   )
   return (
      <div>
         <Modal
            open={props.open}
            onClose={() => { props.setOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '550px',
               bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p: 1,
            }}>

               {bodyModal}
            </Box>

         </Modal>
      </div>
   );
}
