import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, MenuItem, Autocomplete, Select, InputLabel, FormControl, IconButton, Grid, Divider, Checkbox, InputAdornment, Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material'
import Modal from '@mui/material/Modal';
import './Modal.sass'
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { mailService } from '@/service/services/Mail.service';
import { moneyFormat } from '@/toolbox/helpers/money.helper';

type ModalProps = {
   open: boolean,
   setOpen: any,
   dataCotizacion?: any,
   dataDetailCampaign?:any
}
export const ModalCotizacionMail: React.FC<ModalProps> = ({
   open,
   setOpen,
   dataCotizacion,
   dataDetailCampaign
}): JSX.Element => {

    console.log(dataCotizacion)
    console.log(dataCotizacion[0]?.Detalle)
   const EnviarMailCotizacion = async () => {
        const resp = await mailService.sendMailCotizacion(dataCotizacion);
        console.log(resp)
   }
   
   const tablaSelected =
      <>
         <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
               <TableHead>
                  <TableRow sx={{ bgcolor: '#3f9bcc' }}>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} width={'15%'}> </TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'15%'}>Codigo</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'25%'}>Nombre</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'20%'}>Precio</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'25%'}>Total</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" width={'5em'}></TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {(dataCotizacion[0]?.Detalle || []).map((row, i) => (
                     row != null &&
                     <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     >
                        <TableCell component="th" scope="row">
                           {row.cantidad}
                        </TableCell>
                        <TableCell component="th" scope="row">
                           {row.codigo}
                        </TableCell>
                        <TableCell align="left">{row.nombre}</TableCell>
                        {/* <TableCell align="right">{moneyFormat(row.precio)}</TableCell> */}
                        {/* <TableCell align="right">{moneyFormat(row.precio*row.cantidad)}</TableCell> */}
                        <TableCell align="left">
                        </TableCell>
                     </TableRow>
                  ))}
                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell align="right"><Typography sx={{fontWeight:500, fontSize:'1.2em'}}>Monto Neto</Typography></TableCell>
                     <TableCell align="right">{}</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell align="right"><Typography sx={{fontWeight:500, fontSize:'1.2em'}}>IVA</Typography></TableCell>
                     <TableCell align="right">{}</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell></TableCell>
                     <TableCell align="right"><Typography sx={{fontWeight:500, fontSize:'1.2em'}}>Total</Typography></TableCell>
                     <TableCell align="right">{}</TableCell>
                  </TableRow>
               </TableBody>
            </Table>
         </TableContainer>
      </>

   const bodyModal = <>
      <Box className='Modal' sx={{ width: '870px' }}>
         <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em', my:2 }} variant="h5" component="h1" textAlign='center' color='primary'>
            {/* {props.CampaignEdit?'Editar Campaña':'Crear Campaña'} */} Generar Cotización
         </Typography>
         <Box component='form'>
            <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} >
               <Grid item md={5} container flexDirection='row'>
                  <Typography > <span style={{ color: '#3F9BCC', fontSize: '1.2em', textDecoration: 'bold', fontWeight: 700 }}><strong>CLIENTE:</strong></span> <span> {dataDetailCampaign.nombre_rz}</span></Typography>
               </Grid>
               <Grid item md={3} container spacing={2} justifyContent='right' alignItems='end' sx={{ mr: 1 }}>
                  bodega
               </Grid>
               <Grid item md={3} container spacing={2} justifyContent='right' alignItems='end'>
                  pago
               </Grid>
               <Grid item xs={12} mt={2}>
                  {tablaSelected}
               </Grid>
            </Grid>
            <Divider />
            <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
               <Grid item >
                  <Button
                     variant='contained'
                     color='error'
                     //disabled={loading}
                     onClick={() => { setOpen(false); }}
                     startIcon={<CancelIcon />}
                     sx={{
                        background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                           bgcolor: '#bf6c00'
                        }
                     }}
                  >
                     Cancelar
                  </Button>
               </Grid>
               <Grid item >
                  <Button
                     variant='contained'
                     color='error'
                     //disabled={loading}
                     onClick={() => { EnviarMailCotizacion()}}
                     startIcon={<SaveIcon />}
                     sx={{
                        background: '#155172', color: '#fff', mt: '10px', '&:hover': {
                           bgcolor: '#155172'
                        }
                     }}
                  >
                     Confirmar
                  </Button>
               </Grid>
             

            </Grid>
         </Box>
      </Box>
   </>
   return (
      <div>
         <Modal
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            {bodyModal}
         </Modal>
         {/* <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar> */}
      </div>
   );
}
