import React, { useEffect, useState } from 'react';
import { Grid, TextField, Divider, Autocomplete, Dialog, DialogTitle, Modal, Snackbar, Alert, Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';
import { Props } from "./ModalDetail.type";
import { TableData } from '@/components/common/Table';
import { entityService } from '@/service/services/Entity.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET } from '@/toolbox/constants/local-storage';
import { ubigeoRepository } from '@/service/repositories/Ubigeo.repository';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const header = [
   { name: 'rut', label: 'Rut', width: '120px' },
   { name: 'nombre_rz', label: 'Razon Social', width: '' },
   { name: 'giro', label: 'Giro', width: '' },]

const Action = [
   { name: 'select', color: 'success', route: null },
]
type ModalProps = {
   open: boolean,
   setOpen: any,
   dataEntity: any,
}



export const ModalAddEntity: React.FC<ModalProps> = ({
   open,
   setOpen,
   dataEntity,
}): JSX.Element => {

   const [entitySoftnet, setEntitySoftnet] = useState<any>([]);
   const [valueEntity, setValueEntity] = useState<any>();
   const [pushData, setPushData] = useState<any>([]);
   const [status, setStatus] = useState<boolean>(false);
   // useEffect(() => {
   //    console.log(dataEntity);
   //  //  Data();
   // }, [])

   const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 10,
      borderRadius: 5,
      width: 850,
      [`&.${linearProgressClasses.colorPrimary}`]: {
         backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      },
      [`& .${linearProgressClasses.bar}`]: {
         borderRadius: 5,
         backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
      },
   }));

   const Data = async (rut) => {
      const Token = readLocalStorage(KEY_SOFTNET);
      const entity = await entityService.dataEmpresaSoftnet(Token, rut)
      //setEntitySoftnet(entity);
      if (entity.rut) {
         pushData.push({ ...entity });
         setEntitySoftnet(pushData);
      }
      // console.log(entity);
   }

   const NewData = async (data) => {
      let idcomuna = 343;
      if (data.direccion.length > 0) {
         const resp = await ubigeoRepository.getComunaByName(data.direccion[0].comuna);
         idcomuna = resp.data.comuna;
      }
      dataEntity({
         rut_empresa: data.rut,
         // idtiporubro: '',
         propietario: '',
         nombre_rz: data.nombre_rz,
         nombre_fantasia: data.nombre_fantasia,
         giro: data.giro,
         telefono: data.telefono,
         // fax: '',
         // prospecto: '',
         // observacion: '',
         // extranjero: '',
         // web: '',
         direccion: data.direccion.length > 0 ? data.direccion[0].direccion : '',
         idcomuna: idcomuna,
         action: 'select'
      })
      setOpen(false)
   }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if (value.length == 10) {
                  setStatus(false)
                  Data(value);
               }
               if (value.length !== 10) {
                  setStatus(true)
                  setPushData([]);
                  setEntitySoftnet(pushData);
               }
               if (value == '') {
                  setStatus(false)
                  setPushData([]);
                  setEntitySoftnet(pushData);
               }
               break;
            default:
               break;
         }
      } catch (e) {
         console.log(e)
      }
   };
   return (
      <div>
         <Modal
            open={open}
            onClose={() => {
               setOpen(false)
             }}
         >
            <Box className='Modal' sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 850,
               minHeight: 350,
               borderRadius: '20px',
               bgcolor: 'background.paper',
               boxShadow: 24,
               p: 3,
               background: '#F5F5F5'
            }}>
               <Typography style={{ fontSize: "18px", textAlign: 'center' }}>Buscar Empresa</Typography>
               <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ mt: 3 }}>
                  <span>Ingrese el rut de la empresa</span>
                  <br /><br />
                  <TextField
                     fullWidth
                     InputLabelProps={{ shrink: true }}
                     sx={{ bgcolor: '#fff', mb: 2 }}
                     size="small"
                     //label="Ejm: 71453722-2"
                     placeholder='Ejm: 71453722-2'
                     id="search"
                     name="search"
                     type="search"
                     autoFocus
                     value={valueEntity}
                     onChange={handleInput}
                  />


                  {/* {entitySoftnet.map((value,i)=>{
                  return (<li>{value}</li>)
                  })} */}

                  {
                     entitySoftnet.length > 0 &&
                     (
                        <TableData
                           header={header}
                           data={entitySoftnet}
                           action={Action}
                           RecuperarData={NewData}
                        />
                     )
                  }
                  {
                     status &&(
                        <Box sx={{ pt: 8, width: 850 }} >
                           <LinearProgress sx={{
                              height: 10,
                              borderRadius: 5,
                           }} />
                        </Box>
                     )
                     //  :
                     //    (
                     //       <Grid
                     //          container
                     //          direction="row"
                     //          justifyContent="center"
                     //          alignItems="center"
                     //          sx={{mt:4}}
                     //       >
                     //           <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>Sin Registros</Typography>
                     //       </Grid>
                     //    )
                  }
               </Grid>
            </Box>
         </Modal>
      </div>

   );
}
