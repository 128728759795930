import React, { useState, useMemo, useRef, useEffect } from 'react';
import { InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, Box, Divider, Typography, FormControl, Select, MenuItem } from '@mui/material'
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, VisibilityIcon, VisibilityOffIcon } from "@toolbox/constants/icons";
import { Icon } from '@components/common/Icon';
import User from "@assets/img/user.png";
import { Link } from 'react-router-dom';
import { indigo } from '@mui/material/colors';
import { useHistory } from 'react-router-dom'
import { Props } from './FormAdminUser.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { ROUTE_ENTITY } from '@toolbox/constants/route-map';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BadgeIcon from '@mui/icons-material/Badge';

const getPassword = () => {
   var chars = "+@_0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
   var password = '';
   for (let i = 0; i < 8; i++) {
      password += chars.trim().charAt(Math.floor(Math.random() * chars.length));
   }
   return password;
}

export const FormAdminUser: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ user_nick: false, email: false })
   const [showPassword, setShowPassword] = useState<boolean>(false)

   useEffect(() => {
      console.log(props.valueAdminUser)
      console.log(props.valueEntity)
      if (props.valueAdminUser.action) {
         if (props.valueAdminUser.action == 'view') {
            setIsDisabled(true);
         }
      } else {
         props.setValueAdminUser(prev => ({ ...prev, idtipousuario: 2, password: getPassword(), textError: '' }));
      }

   }, [])

   const rules = useMemo(() => ({
      string_lowecase: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      string_required: [
         VALIDATORS.REQUIRED,
      ],
      phone: [
         VALIDATORS.REQUIRED,
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.PHONE,
      ],
      email: [
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'nombre':
            props.setValueAdminUser(prev => ({ ...prev, nombre: value }));
            break;
         case 'user_nick':
            props.setValueAdminUser(prev => ({ ...prev, user_nick: value }));
            break;
         case 'email':
            if ((/^[\w@.-]{0,86}$/).test(value)) {
               props.setValueAdminUser(prev => ({ ...prev, email: value }));
            }
            props.setValueAdminUser(prev => ({ ...prev, email: value }));
            break;
         case 'idtipousuario':
            props.setValueAdminUser(prev => ({ ...prev, idtipousuario: value }))
            break;
         case 'telefono':
            props.setValueAdminUser(prev => ({ ...prev, telefono: value }))
            break;
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               props.setValueAdminUser(prev => ({ ...prev, password: value }));
            }
            break;
         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      try {
         //const respEmail = await userService.verifyEmail(props.valueAdminUser.email);
         const respUsernick = await userService.verifyUsernick(props.valueAdminUser.user_nick);
         const dataAdminUser = {
            nombre: props.valueAdminUser.nombre,
            user_nick: props.valueAdminUser.user_nick,
            email_admin: props.valueAdminUser.email,
            idtipousuario: props.valueAdminUser.idtipousuario,
            user_telefono: props.valueAdminUser.telefono,
            password: props.valueAdminUser.password,
         }
         let dataEntity;
         let resp;
         if (props.valueAdminUser.action == 'edit') {
            console.log(props.valueEntity);
            dataEntity = {...props.valueEntity, ...dataAdminUser }
            console.log(dataEntity);
            resp = await entityService.updateEntity(props.valueAdminUser.idempresacliente, dataEntity)
         }
         else {
            if (respUsernick.data == false) {
               if (validate()) {
                  //dataEntity = { ...props.valueEntity, ...props.valueContactEntity, ...dataAdminUser };
                  dataEntity = { ...props.valueEntity, ...dataAdminUser };
                  console.log(dataEntity)
                  resp = await entityService.createEntity(dataEntity);
               }
            }
            else {
               setEstado({ user_nick: respUsernick.data, email: true })

               setOpenModal(true);
            }
         }
         if (!!resp.data) {
            history.push(ROUTE_ENTITY);
         }
      }
      catch (error) {
         // setDataEntity(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueAdminUser(prev => ({ ...prev, [name]: newVal }));
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
      // setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   return (
      <Container maxWidth="xl">
         <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={`e-mb-lg`}>
               <Grid item xs={6}>
                  <Input
                     ref={ref => inputRefs.current[0] = ref}
                     height={35}
                     name="nombre"
                     prependInnerAdornment={
                        <InputAdornment position="end">
                        <PersonIcon />
                       </InputAdornment>
                       }
                     placeholder="Nombre"
                     //value={data.nombre}
                     value={props.valueAdminUser.nombre}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#eeeff1"
                     rules={rules.string_required}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={6}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     height={35}
                     name="user_nick"
                     prependInnerAdornment={
                        <InputAdornment position="end">
                        <AccountCircleIcon />
                       </InputAdornment>
                       }
                     placeholder="User_Nick"
                     //value={data.user_nick}
                     value={props.valueAdminUser.user_nick}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#eeeff1"
                     rules={rules.string_lowecase}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={props.valueAdminUser?.action == 'edit' ? true : isDisabled}
                  />
               </Grid>
               <Grid item xs={6}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     height={35}
                     name="email"
                     prependInnerAdornment={
                        <InputAdornment position="end">
                        <EmailIcon />
                       </InputAdornment>
                       }
                     placeholder="Correo Electrónico"
                     //value={data.email}
                     value={props.valueAdminUser.email}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#eeeff1"
                     rules={rules.email}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={props.valueAdminUser?.action == 'edit' ? true : isDisabled}
                  />
               </Grid>
               <Grid item xs={6}>
                  <Input
                     ref={ref => inputRefs.current[3] = ref}
                     height={35}
                     name="telefono"
                     type="text"
                     prependInnerAdornment={
                        <InputAdornment position="end">
                        <LocalPhoneIcon />
                       </InputAdornment>
                       }
                     placeholder="Telefono"
                     //value={data.telefono}
                     value={props.valueAdminUser.telefono}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#eeeff1"
                     rules={rules.phone}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={!props.valueAdminUser.action ? 6 : 12}>
                  <FormControl fullWidth >
                     <Select
                        id="idtipousuario"
                        sx={{ bgcolor: '#EEEFF1' }}
                        onChange={handleInput}
                        disabled
                        //onBlur={handleInputBlur}
                        value={props.valueAdminUser.idtipousuario}
                        name='idtipousuario'
                        size='small'
                        startAdornment={
                           <InputAdornment position="start">
                         <BadgeIcon />
                        </InputAdornment>
                        }
                     >
                        <MenuItem value={1}>SUPERADMIN</MenuItem>
                        <MenuItem value={2}>ADMINISTRADOR</MenuItem>
                     </Select>
                  </FormControl>
               </Grid>
               {/* <Grid item xs={6}>
               <Input
                  ref={ref => inputRefs.current[6] = ref}
                  height={35}
                  name="idtipousuario"
                  type="text"
                  disabled

                  placeholder="Tipo Usuario"
                  //value={data.idtipousuario}
                  value={props.valueAdminUser.idtipousuario}
                  onChange={handleInput}
                  onBlur={handleInputBlur}
                  backgroundColor="#eeeff1"
                  rules={rules.string_required}
                  disableElevation
                  validateOnBlur
                  dense
               />
            </Grid> */}

               {!props.valueAdminUser.action && (
                  <Grid item xs={6}>
                     <Input
                        ref={ref => inputRefs.current[4] = ref}

                        height={35}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        //placeholder="Ingrese la contraseña"
                        //value={data.password}
                        value={props.valueAdminUser.password}
                        onChange={handleInput}
                        onBlur={handleInputBlur}
                        backgroundColor="#eeeff1"
                        rules={rules.password}
                        disabled
                        appendAdornment={
                           <InputAdornment position="end">
                              <IconButton
                                 disabled
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword}
                                 onMouseDown={handleMouseDownPassword}
                              >
                                 {showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                              </IconButton>
                           </InputAdornment>
                        }
                        disableElevation
                        validateOnBlur
                        dense
                     />
                  </Grid>
               )}
               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
                  >
                  <Grid item >
                     <Button
                        variant='contained'
                        onClick={props.handleBack}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon />}
                     >
                        <span>Volver</span>
                     </Button>
                  </Grid>

                  <Grid item >
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={props.valueAdminUser.action == 'view' ? true : loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{!props.valueAdminUser.action ? 'Guardar' : props.valueAdminUser.action == 'edit' ? 'Editar' : 'Detalle'}</span>
                        }
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -80%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={User}
                  ></Box>
               </Grid>
               <Divider />
               <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {`El ${estado.user_nick ? 'User Nick, ' : ''}${estado.email ? 'Email,' : ''} ya esta tomado por otro usuario`}
               </Typography>
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
