import { Protected } from '@/components/layout/Protected';
import React, { useEffect, useState } from 'react';
import { Props } from './ClientDocuments.type'
import { Container, Divider, Grid, InputLabel, FormControlLabel, MenuItem, FormHelperText, FormControl, Select, TextField, Typography, Button, Avatar, Autocomplete, Checkbox, Backdrop, CircularProgress, IconButton } from '@mui/material';
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableData } from '@/components/common/Table';
import { ROUTE_DETALLE_CLIENTS_DOC } from '@/toolbox/constants/route-map';
import { bypassService } from '@/service/services/Bypass.service';
import { useHistory } from 'react-router-dom';

export const ClientDocuments: React.FC<Props> = ({ }): JSX.Element => {

   const header = [
      { name: 'rut', label: 'RUT', width: '' },
      { name: 'nombre_rz', label: 'Nombre', width: '' },
      { name: 'prospecto', label: 'Tipo', width: '' },
      { name: 'telefono', label: 'Telefono', width: '' },
      { name: 'email', label: 'Correo', width: '' },
      { name: 'direccion', label: 'Dirección', width: '' },
   ];
   const dataAction = [
      { name: 'view', color: 'primary' },
   ]

   const dataInitial = [
      { id: 1, nombre: 'Luis', telefono: '978654321', correo: 'lf.ramsdhfbsd@gmail.com', rut_cliente: '78451245-9' }
   ]

   const [dataClients, setDataClients] = useState<any>([]);
   const [openView, setOpenView] = useState<boolean>(false)
   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0)
   const history = useHistory()

   useEffect(() => {
      getClientDocuments()
   }, [])

   const getClientDocuments = async () => {
      setOpenView(true)
      const resp = await bypassService.getClientDocuments()
      setDataClients(resp.data)
      setOpenView(false)
   }

   const RecuperarData = (data) => {
      console.log(data)
      history.push(`clientes-doc/${data.id}`, data)
   }

   const PerPage = async (data) => {

      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) => {

      setPageData(data + 1)
   }

   const getSearchContacts = async (search) => {
      try{
         setOpenView(true)
         const resp= await bypassService.getSearchClientDocuments(search);
         setDataClients(resp.data)
         setOpenView(false)
       }catch(e){
          console.log(e)
       }
   }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if (value.length > 2) {
                  getSearchContacts(value)
               }
               if (value.length == 0) {
                  getClientDocuments();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      } catch (e) {
         console.log(e)
      }
   }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Grid container flexDirection='row' spacing={2} justifyContent='space-between' sx={{ mb: 4 }}>
            <Grid item md={7}>
               <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>Prospectos Documentos</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={1} >
            <Grid item xs={12}>
               <Typography variant="body2" sx={{ mt: '20px' }} >
                  {/* {`${Total.clients ? `${Total.clients}` : `0`} Registros encontrados`} */}
               </Typography>
            </Grid>
            <Grid item xs={12}>
               <InputLabel >Filtrar Resultados</InputLabel>
               <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="search"
                  value={search}
                  sx={{ bgcolor: '#E3F4FD' }}
                  onChange={handleInput}
                  placeholder="Introduce un Nombre o Rut Cliente"
                  type="search"
                  id="password"
                  autoComplete="current-password"
                  size="small"
               />
            </Grid>
         </Grid>
         <TableData
            header={header}
            data={dataClients}
            action={dataAction}
            perPage={PerPage}
            RecuperarData={RecuperarData}
            page={page}
            total={Total ? Total : 0} />
      </Protected>
   )
}