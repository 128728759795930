
import { accionRepository } from "../repositories/Accion.repository";

export const accionService= {
   getAccionData,
   getAccion,
   createAccion,
   updateAction,
   getActionById,
   actionCompleted,
   deleteAccion,
   getActionByProjection,
   getActionByProgress,
   actionProgress
};

async function getAccionData () {
   const accion= await accionRepository.getAccionData();
   return accion;
}

async function getActionByProjection (idproyeccion) {
   const accion= await accionRepository.getActionByProjection(idproyeccion);
   return accion;
}

async function getActionByProgress (idactionprogreso) {
   const accion= await accionRepository.getActionByProgress(idactionprogreso);
   return accion;
}

async function getAccion () {
    const accion= await accionRepository.getAccion();
    return accion;
 }

 async function getActionById (idaction:number) {
   const accion= await accionRepository.getActionById(idaction);
   return accion;
}

async function updateAction (id, dataAccion) {
   const accion= await accionRepository.updateAction(id,dataAccion);
   return accion;
}
async function createAccion (dataAccion) {
   const accion= await accionRepository.createAccion(dataAccion);
   return accion;
}

async function actionCompleted ( id: number, data: any) {
   const configuration= await accionRepository.actionCompleted(id, data);
   return configuration
}

function deleteAccion(id) {
   return accionRepository.deleteAccion(id)
}

async function actionProgress ( id: number, data: any) {
   const configuration= await accionRepository.actionProgress(id, data);
   return configuration
}

