import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormEntity.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";
import { entityService } from '@/service/services/Entity.service';
import { ubigeoService } from '@/service/services/Ubigeo.service';
import Entity from "@assets/img/entity.png";
import './style.sass'
import RuleIcon from '@mui/icons-material/Rule';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArticleIcon from '@mui/icons-material/Article';
import FlagIcon from '@mui/icons-material/Flag';
import { borderColor, width } from '@mui/system';
import Person from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ROUTE_ENTITY } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { ModalAddEntity } from '@/components/common/Modal/ModalAddEntity';

export const FormEntity: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [typeRubro, setTypeRubro] = useState<any>([]);
   const [idpais, setIdPais] = useState(null);
   const [idregion, setIdRegion] = useState(null);
   const [idprovincia, setIdProvincia] = useState(null);
   const [idcomuna, setIdComuna] = useState(null);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })
   const [ubigeo, setUbigeo] = useState<any>({ pais: [], region: [], provincia: [], comuna: [] });

   const [openAddEntity, setOpenAddEntity] = useState(false);
   const [entitySoftnet,setEntitySoftnet]= useState<any>([]);

   const rules = useMemo(() => ({
      rutempresa: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      razonsocial: [
         VALIDATORS.REQUIRED,

      ],
      phone: [
         VALIDATORS.REQUIRED,
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.PHONE,
      ],
      url: [
         VALIDATORS.REQUIRED,
         VALIDATORS.WITHOUT_SPACE,
         VALIDATORS.URL,
      ],
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      nombre: [
         VALIDATORS.REQUIRED,
      ],
      user_nick: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      administrador: [
         VALIDATORS.REQUIRED,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
      credenciales: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      propietario : [
         VALIDATORS.REQUIRED,
         VALIDATORS.PROPIETARIO,
      ],
      direccion: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ADDRESS.CHARACTER
      ]
   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_empresa':
            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut : function (rutCompleto) {
                  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                     return false;
                  var tmp 	= rutCompleto.split('-');
                  var digv	= tmp[1];
                  var rut 	= tmp[0];
                  if ( digv == 'K' ) digv = 'k' ;
                  return (Fn.dv(rut) == digv );
               },
               dv : function(T){
                  var M=0,S=1;
                  for(;T;T=Math.floor(T/10))
                     S=(S+T%10*(9-M++%6))%11;
                  return S?S-1:'k';
               }
            }

            var foo =value.split("-").join("")
            if(foo.length >0 && foo.length<10){
               foo= foo.match(new RegExp('.{1,8}', 'g')).join("-");
               props.setValueEntity(prev => ({ ...prev, rut_empresa: foo}))
            }else if(foo.length == 0){
               props.setValueEntity(prev => ({ ...prev, rut_empresa: ""}))
            }
           // props.setValueEntity(prev => ({ ...prev, rut_empresa: value }));
            break;
         case 'idtiporubro':
            props.setValueEntity(prev => ({ ...prev, idtiporubro: value }))
            break;
         case 'telefono':
            props.setValueEntity(prev => ({ ...prev, telefono: value }))
            break;
         case 'propietario':
            props.setValueEntity(prev => ({ ...prev, propietario: value }));
            break;
         case 'nombre_rz':
            props.setValueEntity(prev => ({ ...prev, nombre_rz: value }));
            break;
         case 'nombre_fantasia':
            props.setValueEntity(prev => ({ ...prev, nombre_fantasia: value }));
            break;
         case 'giro':
            props.setValueEntity(prev => ({ ...prev, giro: value }));
            break;
         case 'fax':
            props.setValueEntity(prev => ({ ...prev, fax: value }));
            break;
         case 'prospecto':
            props.setValueEntity(prev => ({ ...prev, prospecto: value }));
            break;
         case 'observacion':
            props.setValueEntity(prev => ({ ...prev, observacion: value }));
            break;
         case 'extranjero':
            props.setValueEntity(prev => ({ ...prev, extranjero: value }));
            break;
         case 'web':
            props.setValueEntity(prev => ({ ...prev, web: value }));
            break;
         case 'direccion':
            props.setValueEntity(prev => ({ ...prev, direccion: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueEntity(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      try {
         const respNombre_rz = await entityService.verifyRazonSocial(props.valueEntity.nombre_rz);
         const respRut = await entityService.verifyRut(props.valueEntity.rut_empresa);
         const dataEnterprise = {
            rut_empresa: props.valueEntity.rut_empresa,
            // idtiporubro: props.valueEntity.idtiporubro,
            telefono: props.valueEntity.telefono,
            propietario: props.valueEntity.propietario,
            nombre_rz: props.valueEntity.nombre_rz,
            nombre_fantasia: props.valueEntity.nombre_fantasia,
            giro: props.valueEntity.giro,
            // fax: props.valueEntity.fax,
            // prospecto: props.valueEntity.prospecto,
            // observacion: props.valueEntity.observacion,
            // extranjero: props.valueEntity.extranjero,
            // web: props.valueEntity.web,
            direccion: props.valueEntity.direccion,
            idcomuna: idcomuna.id
         }
         if (props.valueEntity.action == 'edit') {
            props.setValueEntity({...dataEnterprise,action:'edit'});
            props.handleNext();
         }
         else if (props.valueEntity.action == 'view') {
            props.handleNext();
         }
         else {
            if (respRut.data == false && respNombre_rz.data == false) {
               if (validate()) {

                  props.setValueEntity(prev => ({ ...prev, ...dataEnterprise }));
                  props.handleNext();
               }
            }
            else {
               setEstado({ rut: respRut.data, nombre_rz: respNombre_rz.data })
               setOpenModal(true);
            }
         }
      }
      catch (error) {
         // setDataEntity(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {
         // setLoading(false);
      }
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   useEffect(() => {
      if (props.valueEntity.action) {
         if (props.valueEntity.action == 'view') {

            setIsDisabled(true);
         }
         getDataUbigeo(props.valueEntity.idcomuna);
      } else {
         if (props.valueEntity.idcomuna !== "") {
            getDataUbigeo(props.valueEntity.idcomuna);
         }
         getPais();
      }
      getDataInitial();
   }, [])

   const onSelectPais = (pais) => {
      if (pais) {
         if (pais.id != null || pais.id != idpais.id) {
            setIdRegion(null)
            setUbigeo(prev => ({ ...prev, region: [], provincia: [], comuna: [] }));
            setIdProvincia(null)
            setIdComuna(null)
         }
         setIdPais(pais)
      }
   }
   const onSelectRegion = (region) => {
      if (region) {
         // setError("")
         if (region.id != null || region.id != idregion.id) {
            setUbigeo(prev => ({ ...prev, provincia: [], comuna: [] }));
            setIdProvincia(null)
            setIdComuna(null)
         }
         setIdRegion(region)
      }
   }
   const onSelectProvincia = (provincia) => {
      if (provincia) {
         if (provincia.id != null || provincia.id != idprovincia.id) {
            setUbigeo(prev => ({ ...prev, comuna: [] }));
            setIdComuna(null)
         }
         setIdProvincia(provincia)
      }
   }
   const onSelectComuna = (idcomuna) => {
      if (idcomuna) {
         //setError("")
         setIdComuna(idcomuna)
      }
   }
   async function getDataInitial() {
      const resp = await entityService.getDataInitial();
      setTypeRubro(resp.data.entry_type);
   }
   async function getPais() {
      const resp = await ubigeoService.getPais();
      setUbigeo(prev => ({ ...prev, pais: resp.data.pais }));
   }
   async function getRegion(idPais: number) {
      const resp = await ubigeoService.getRegion(idPais);
      setUbigeo(prev => ({ ...prev, region: resp.data.region }));
   }
   async function getProvincia(idregion: number) {
      const resp = await ubigeoService.getProvincia(idregion);
      setUbigeo(prev => ({ ...prev, provincia: resp.data.province }));
   }
   async function getComuna(idprovincia: number) {
      const resp = await ubigeoService.getComuna(idprovincia);
      setUbigeo(prev => ({ ...prev, comuna: resp.data.comuna }));
   }
   async function getDataUbigeo(id) {
      const resp = await ubigeoService.getUbigeo(id);

      getPais();
      setIdPais({ id: resp.data[0].idpais, pais: resp.data[0].pais })
      getRegion(resp.data[0].idpais)
      setIdRegion({ id: resp.data[0].idregion, region: resp.data[0].region })
      getProvincia(resp.data[0].idregion)
      setIdProvincia({ id: resp.data[0].idprovincia, provincia: resp.data[0].provincia })
      getComuna(resp.data[0].idprovincia)
      setIdComuna({ id: resp.data[0].idcomuna, comuna: resp.data[0].comuna })
   }

   return (
      <Container maxWidth="xl">
         <form onSubmit={handleSubmit}>
            <Grid container spacing={3} className={`e-mb-lg`}>
               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                     <InputLabel id="demo-simple-select-label">Tipo de Rubro </InputLabel>
                     <Select
                        id="idtiporubro"
                        sx={{ bgcolor: '#F3F3F3' }}
                        onChange={handleInput}
                        startAdornment={
                           <InputAdornment position="start">
                         <BusinessIcon />
                        </InputAdornment>
                        }
                        //onBlur={handleInputBlur}
                        //value={data.idtiporubro}
                        placeholder='Tipo de Rubro'
                        label='Tipo de Rubro '
                        value={props.valueEntity.idtiporubro}
                        name='idtiporubro'
                        size='small'
                        disabled={isDisabled}
                     >
                        {typeRubro.map((option, i) => {
                           return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                        })
                        }
                     </Select>
                  </FormControl>
               </Grid> */}
               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Prospecto </InputLabel>
                     <Select
                        id='prospecto'
                        name='prospecto'
                        sx={{ bgcolor: '#F3F3F3' }}
                        label="Prospecto"
                        onChange={handleInput}
                        //value={data.prospecto}
                        value={props.valueEntity.prospecto}
                        size='small'
                        placeholder='Prospecto'
                        startAdornment={
                           <InputAdornment position="start">
                         <ArticleIcon />
                        </InputAdornment>
                        }
                        disabled={isDisabled}>
                        <MenuItem key={1} value={1}>{"SI"}</MenuItem>
                        <MenuItem key={2} value={0}>{"NO"}</MenuItem>
                     </Select>
                  </FormControl>
               </Grid> */}
               {/* <Grid item xs={4}>
                  <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Extranjero </InputLabel>
                     <Select
                        id='Extranjero'
                        name='extranjero'
                        sx={{ bgcolor: '#F3F3F3' }}
                        label="Extranjero"
                        placeholder='Extranjero'
                        onChange={handleInput}
                        // value={data.extranjero}
                        startAdornment={
                           <InputAdornment position="start">
                         <FlagIcon />
                        </InputAdornment>
                        }
                        value={props.valueEntity.extranjero}
                        size='small'
                        disabled={isDisabled}>
                        <MenuItem key={1} value={1}>{"SI"}</MenuItem>
                        <MenuItem key={2} value={0}>{"NO"}</MenuItem>
                     </Select>
                  </FormControl>
               </Grid> */}
               <Grid item xs={4}>
                  <Input
                    className='Input'
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut_empresa"
                     type='text'
                     placeholder="Rut Empresa"
                     //value={data.rut_empresa}
                     value={props.valueEntity.rut_empresa}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.rutempresa}
                     disableElevation
                     validateOnBlur
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     name="propietario"
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <PersonIcon />
                    </InputAdornment>
                    }
                     height={35}
                     placeholder="Propietario"
                     //value={data.propietario}
                     value={props.valueEntity.propietario}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     //rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="nombre_rz"
                     placeholder="Razon Social"
                     //value={data.nombre_rz}
                     value={props.valueEntity.nombre_rz}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[3] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessCenterIcon />
                    </InputAdornment>
                    }
                     className='Input'
                     height={35}
                     name="nombre_fantasia"
                     placeholder="Nombre Fantasia"
                     //value={data.nombre_fantasia}
                     value={props.valueEntity.nombre_fantasia}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     //rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[4] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <CorporateFareIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="giro"
                     placeholder="Giro"
                     //value={data.giro}
                     value={props.valueEntity.giro}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[6] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <FaxIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="fax"
                     placeholder="Fax"
                     //value={data.fax}
                     value={props.valueEntity.fax}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[7] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <SpeakerNotesIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="observacion"
                     placeholder="Observacion"
                     //value={data.observacion}
                     value={props.valueEntity.observacion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[8] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LanguageIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="web"
                     placeholder="Web"
                     //value={data.web}
                     value={props.valueEntity.web}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.url}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[10] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LocalPhoneIcon />
                    </InputAdornment>
                    }
                    className='input'
                     height={35}
                     name="telefono"
                     placeholder="Telefono Empresa"
                     //value={data.telefono}
                     value={props.valueEntity.telefono}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     //rules={rules.phone}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[9] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <HomeWorkIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="direccion"
                     placeholder="Direccion"
                     //value={data.direccion}
                     value={props.valueEntity.direccion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     //rules={rules.direccion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  {/* <small>País</small> */}
                  <Autocomplete
                     disabled={isDisabled}
                     autoComplete
                     size='small'
                     placeholder='Pais'

                     includeInputInList
                     value={idpais}
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, pais) => {
                        pais && getRegion(pais.id);
                        onSelectPais(pais)
                     }}
                     id="idpais"
                     options={ubigeo.pais}
                     getOptionLabel={(option) => option.pais}
                     renderInput={(params) => <TextField {...params} label='País'
                  />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disabled={isDisabled}
                     value={idregion}
                     size='small'
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, region) => {
                        region && getProvincia(region.id);
                        onSelectRegion(region)
                     }}
                     id="idregion"
                     options={ubigeo.region && ubigeo.region}
                     getOptionLabel={(option) => option.region ? option.region : ""}
                     renderInput={(params) => <TextField {...params} label='Región'

                     />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disabled={isDisabled}
                     value={idprovincia}
                     size='small'
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, provincia) => {
                        provincia && getComuna(provincia.id);
                        onSelectProvincia(provincia)
                     }}
                     id="idprovincia"
                     options={ubigeo.provincia && ubigeo.provincia}
                     getOptionLabel={(option) => option.provincia ? option.provincia : ""}
                     renderInput={(params) => <TextField {...params} label='Provincia'/>}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disabled={isDisabled}
                     value={idcomuna}
                     size='small'
                     sx={{ bgcolor: '#F3F3F3' }}
                     onChange={(e, comuna) => {
                        onSelectComuna(comuna)
                     }}
                     id="idcomuna"
                     options={ubigeo.comuna && ubigeo.comuna}
                     getOptionLabel={(option) => option.comuna ? option.comuna : ""}
                     renderInput={(params) => <TextField {...params} label='Comuna'/>}
                  />
               </Grid>
               <Grid item container
                  xs={4}
                 justifyContent='right'
                 spacing={10}
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        //size='small'
                        component={Link}
                        to={ROUTE_ENTITY}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Volver</span>
                        }
                     </Button>

                     </Grid>

                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        //size='small'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        endIcon={<ArrowForwardIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -70%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {` ${estado.rut ? ' El Rut, ' : ''}${estado.nombre_rz ? 'Razon Social,' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography>
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
