import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Backdrop, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { ModalProspectoRapido } from './ModalProspectoRapido';
import { propsToClassKey } from '@mui/styles';
import { mailService } from '@/service/services/Mail.service';
import { ModalCampaignCorreo } from './ModalCampaignCorreo';
import { SpinnerGrow } from '../Spinner';
import { productService } from '@/service/services/Product.service';
import LanguageIcon from '@mui/icons-material/Language';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data:any,
    tipo?:any,
    dataCampana?:any,
    dataProductos?:any
}


export const ModalPromocionEdit: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props.dataProductos)
     const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
    const [openVinculo, setOpenVinculo] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);
    const [statusEdit, setStatusEdit] = useState<boolean>(false);
   
    const dataInitial = {
        titulo:'PROMOCIÓN DE PRODUCTOS',
        img_principal:'https://jtihfi.stripocdn.email/content/guids/CABINET_39f0292a03183db7b2537a7ee2d5a2cd/images/illustration_3.png',
        frase_principal:'PRODUCTOS PENSADOS EN TI!',
        btn_principal:'COMPRAR AHORA',
        url_principal:'',
        array_productos:props.dataProductos
    }

    const [dataLanding, setDataLanding] = useState<any>(dataInitial);

    const [openValidation,setOpenValidation] = useState<boolean>(false);
    const getUltimaPlantillaCampaña = async(idcampana, tipo) => {
        const resp:any = await mailService.getUltimaPlantillaCampaña(idcampana, tipo);
        return resp;
    }

    const VerifyDetailCampana = async(iddetallecampana, tipo) => {
        const resp:any = await mailService.getVerifyPlantillaDetalleCampana(iddetallecampana, tipo)
        return resp;
    }

    const getPlantilla = async( functionUltimaCampana, functionVerifyDetailCampana, funcOpen ) =>{
       
        setOpen(true)
        const respVerifyCampana = await functionVerifyDetailCampana(props.data.iddetallecampaña, 'promocion');
        console.log(respVerifyCampana);
        if(respVerifyCampana.data){
            setDataLanding({...respVerifyCampana.data, codigo_promocional:'', array_productos:props.dataProductos})
           setOpenValidation(true)
        } else{
            const respUltimaCampana = await functionUltimaCampana(props.data.idcampaña, 'promocion');
            console.log(respUltimaCampana)
            if(respUltimaCampana.data){
                setDataLanding(respUltimaCampana.data);
            }else{
                setDataLanding(dataInitial);
            }
        }  
        funcOpen(false)      
    }

    useEffect(()=>{

        if(props.tipo == 'detalle'){
            getPlantilla(getUltimaPlantillaCampaña, VerifyDetailCampana, setOpen);
        }

        if(props.tipo == 'hilo_correo'){
            setDataLanding({...props.data, array_productos: JSON.parse(props.data.array_productos) });
        }
       
    },[])


    const EnviarPromocion = async(tipo) => {
        setDataLanding({...dataLanding, mail:'lf.ramirezmendoza@gmail.com'})

        if(!dataLanding.titulo){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un título para generar el envío'})
        }
        if(!dataLanding.frase_principal){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un frase para generar el envío'})
        }
        if(!dataLanding.btn_principal){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un botón para generar el envío'})
        }
        if(!dataLanding.url_principal){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar una URL de redirección para el botón para generar el envío'})
        }
        const data = new FormData();
        data.append('titulo', dataLanding.titulo);
        data.append('img_principal', dataLanding.img_principal);
        data.append('frase_principal', dataLanding.frase_principal);
        data.append('btn_principal', dataLanding.btn_principal);
        data.append('array_productos',JSON.stringify(dataLanding.array_productos));
        data.append('url_principal', dataLanding.url_principal);
        data.append('fecha_validez','');
        data.append('mail', 'lf.ramirezmendoza@gmail.com');
        data.append('idcliente', props.data.idcliente );
        data.append('iddetallecampananegocioclientes', props.data.iddetallecampaña);
        data.append('tipo','promocion')

        if(tipo == 'individual'){
            setOpen(true)
            const resp:any = await mailService.sendLandingMail(data);
            if(resp?.data){
                setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'El correo promocional ha sido enviado con éxito'})
                setOpen(false)
            }else{
                setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message:'Ups! ha ocurrido con el envio de correo, intentelo mas tarde'})
                setOpen(false)
            }
        }

        if(tipo == 'masivo'){
            setOpen(true)
            const resp:any = await mailService.sendLandingMailMasivo(data)
            if(resp?.data){
                setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'El correo promocional ha sido enviado con éxito'})
                setOpen(false)
            }else{
                setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message:'Ups! ha ocurrido con el envio de correo, intentelo mas tarde'})
                setOpen(false)
            }
        }
       
        
    }

    const handleChange = (e) =>{
        console.log(e)
        const name = e.target.name;
        console.log(name)
        switch(name){
            case 'titulo':
                setDataLanding({...dataLanding, titulo: e.target.value});
                e.target.value
                break;
            case 'frase_principal':
                setDataLanding({...dataLanding, frase_principal: e.target.value});
                e.target.value
                break;
            case 'btn_principal':
                setDataLanding({ ...dataLanding, btn_principal: e.target.value });
                e.target.value
                break;
            case 'url_principal':
                setDataLanding({ ...dataLanding, url_principal: e.target.value });
                e.target.value
                break;
        }
    }
    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Personaliza el envio de promoción de productos por correo`}
            </Typography>
           { props.tipo !== 'hilo_correo' && <Grid container xs={12} justifyContent={'right'} spacing={2} mt={1} >
            <Button 
             sx={{
                background: '#E4980D', minWidth:'100px', color: '#fff', mr:2, fontWeight: '700', '&:hover': {
                    bgcolor: '#E4980D'
                }
            }}
            onClick={()=>{setStatusEdit(!statusEdit)}}>{statusEdit?'Ver':'Editar'}</Button>
            <Button 
             sx={{
                background: '#5C68E2', minWidth:'100px', color: '#fff', mr:2, fontWeight: '700', '&:hover': {
                    bgcolor: '#5C68E2'
                }
            }}
            onClick={()=>{props.tipo == 'detalle'?EnviarPromocion('individual'): EnviarPromocion('masivo')}}>{props.tipo == 'detalle'?'Enviar':'Enviar Masivo'}</Button>
            </Grid>}
            <Grid container justifyContent={'center'} paddingX={8} mt={3} maxHeight={'70vh'} sx={{overflowY:'scroll'}}>
                {statusEdit ?

                    <Grid item container xs={12} sx={{ background: '#5C68E2' }}>
                        <h2 style={{ marginTop: '0', padding:'30px', color: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <TextField
                            name={'titulo'}
                            fullWidth
                            value={dataLanding.titulo}
                            onChange={(e)=>{handleChange(e)}}
                            sx={{width:'480px', background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                           placeholder={'Introduce el nombre de la empresa'}
                            type='input'
                        /></h2>
                       
                    </Grid>
                    :
                    <Grid item container xs={12} sx={{ background: '#5C68E2' }}>
                        <h2 style={{ marginTop: '0', padding:'30px', color: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            {dataLanding.titulo}</h2>
                    </Grid>

                }

                {
                    statusEdit?
                    <Grid item container xs={12} sx={{ background: '#fff', padding:'20px' }} textAlign='center' alignContent={'center'}>
                    <img width={'300px'} style={{marginLeft:'140px'}} src={dataLanding.img_principal}></img>
                    </Grid>:
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff', padding:'20px' }} textAlign='center' alignContent={'center'}>
                    <img width={'300px'} style={{marginLeft:'140px'}} src="https://jtihfi.stripocdn.email/content/guids/CABINET_39f0292a03183db7b2537a7ee2d5a2cd/images/illustration_3.png"></img>
                    </Grid>
                    
                    </> 
                    
                }

                {
                    statusEdit?
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center' justifyContent={'center'}>
                        <h1 style={{ textAlign:'center', marginTop: '0', paddingLeft:'10px',lineHeight:'70px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '70px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                           <TextField
                           type='input'
                           sx={{width:'500px'}}
                           name='frase_principal'
                           onChange={(e)=>{handleChange(e)}}
                           value={dataLanding.frase_principal}/></h1>
                    </Grid>:
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center' justifyContent={'center'}>
                        <h1 style={{ marginTop: '0', paddingLeft:'10px',lineHeight:'55px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '56px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        {dataLanding.frase_principal}</h1>
                    </Grid>
                    </> 
                    
                }
                
                {
                    statusEdit?
                    <Grid>

                    </Grid>:
                    <>
                    
                    </> 
                    
                }
                {
                    statusEdit?
                    <Grid item container xs={12} sx={{bgcolor:'#fff', paddingTop:'25px'}} justifyContent='center'>
                        <a
                            style={{
                                background: '#5C68E2', color: '#fff', 
                            }}
                        ><TextField
                        type='input'
                        name='btn_principal'
                        onChange={(e)=>{handleChange(e)}}
                        value={dataLanding.btn_principal}/>
                        </a>
                        <IconButton onClick={()=>{setOpenVinculo(!openVinculo)}}>
                        <LanguageIcon />
                        </IconButton>
                       
                        {
                            openVinculo && <TextField
                            type='input'
                            name='url_principal'
                            value={dataLanding.url_principal}
                            placeholder='Introduce un hipervinculo para el botón'
                            onChange={(e)=>handleChange(e)}/>
                        }
                    </Grid>:
                    <Grid item container xs={12} sx={{bgcolor:'#fff', paddingTop:'25px'}} justifyContent='center'>
                        <a href={dataLanding.url_principal} target='__blank' style={{textDecoration:'none'}}>
                        <Button
                            sx={{
                                background: '#5C68E2', color: '#fff', fontWeight: '700', '&:hover': {
                                    bgcolor: '#5C68E2'
                                }
                            }}
                            variant='contained'
                            onClick={() => { }}
                        >{dataLanding.btn_principal}
                        </Button>
                        </a>
                    </Grid>
                }
                {
                    statusEdit ?
                    <Grid item container xs={12} sx={{ bgcolor: '#fff', paddingTop: '25px' }} justifyContent='center'>
                    {(dataLanding.array_productos || []).map((value,i)=>{
                      return(
                        <>
                            <Grid container>
                                <Grid item md={6}>
                                <img width={'240px'} src="https://thumbs.dreamstime.com/b/vector-del-icono-carro-de-compras-137284641.jpg"></img>
                                </Grid>
                                
                                <Grid item md={6} container textAlign={'center'}>
                                      <table width="100%" role="presentation">
                                          <tr>
                                              <td align="center" ><h3 style={{ marginTop: '0', lineHeight: '24px', padding: '20px', paddingBottom:'0px', color: '#333333', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}
                                              >{value.nombre}</h3></td>
                                          </tr>
                                          {/* <tr>
                                              <td align="center" ><p style={{ marginTop: '0', lineHeight: '18px', padding: '10px', paddingBottom:'0px', color: '#333333', fontSize: '12px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}
                                               >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&nbsp;.</p></td>
                                          </tr> */}
                                          <tr>
                                              <td align="center" ><h2 style={{ marginTop: '0', lineHeight: '31px', padding: '10px', color: '#333333', fontSize: '26px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>{value.precio}&nbsp;&nbsp;<span style={{color:"#999999"}}><s style={{textDecoration:"line-through"}}></s></span></h2></td>
                                          </tr>
                                          <tr>

                                          <Button
                                                  sx={{
                                                      background: '#fff', color: '#5C68E2', fontWeight: '700', '&:hover': {
                                                          bgcolor: '#fff'
                                                      }
                                                  }}
                                                  variant='outlined'
                                                  onClick={() => { }}
                                              >{'AÑADIR AL CARRRITO'}
                                              </Button>
                                         </tr>
                                      </table>
                                </Grid>
                            </Grid>
                        </>
                      )
                    })}
                    </Grid> :
                        <Grid item container xs={12} sx={{ bgcolor: '#fff', paddingTop: '25px' }} justifyContent='center'>
                        {(dataLanding.array_productos || []).map((value,i)=>{
                          return(
                            <>
                                <Grid container>
                                <Grid item md={6}>
                                <img width={'240px'} src="https://thumbs.dreamstime.com/b/vector-del-icono-carro-de-compras-137284641.jpg"></img>
                                </Grid>
                                    
                                    <Grid item md={6} container textAlign={'center'}>
                                          <table width="100%" role="presentation">
                                              <tr>
                                                  <td align="center" ><h3 style={{ marginTop: '0', lineHeight: '24px', padding: '20px', paddingBottom:'0px', color: '#333333', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}
                                                  >{value.nombre}</h3></td>
                                              </tr>
                                              {/* <tr>
                                                  <td align="center" ><p style={{ marginTop: '0', lineHeight: '18px', padding: '10px', paddingBottom:'0px', color: '#333333', fontSize: '12px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}
                                                   >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&nbsp;.</p></td>
                                              </tr> */}
                                              <tr>
                                                  <td align="center" ><h2 style={{ marginTop: '0', lineHeight: '31px', padding: '10px', color: '#333333', fontSize: '26px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>$ {value.precio}&nbsp;&nbsp;<span style={{color:"#999999"}}><s style={{textDecoration:"line-through"}}></s></span></h2></td>
                                              </tr>
                                              <tr>
                                              <a href={dataLanding.url_principal} target='__blank' style={{textDecoration:'none'}}>
                                              <Button
                                                      sx={{
                                                          background: '#fff', color: '#5C68E2', fontWeight: '700', '&:hover': {
                                                              bgcolor: '#fff'
                                                          }
                                                      }}
                                                      variant='outlined'
                                                      onClick={() => { }}
                                                  >{'AÑADIR AL CARRRITO'}
                                                  </Button>
                                              </a>
                                             </tr>
                                          </table>
                                    </Grid>
                                </Grid>
                            </>
                          )
                        })}
                        </Grid>
                }
                <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>

                    <h6 style={{ marginTop: '0', lineHeight: '21px', padding: '30px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <strong>{'Desarrollado por Softnet SPA'}</strong>
                    </h6>
                    <h6 style={{ marginTop: '0', lineHeight: '21px', padding: '30px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <strong><a href='www.softnet.cl' target='_blank'>{'softnet.cl'}</a></strong></h6>

                </Grid>
            </Grid>
        </Box> 
    )

    const BodyEnvioExistente = (
        <>
         <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '1.3em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Hemos encontrado una promoción existente para ${props.data.nombre_rz} dentro de la Campaña, ¿Está seguro de volver a enviar?`}
            </Typography>
            <Grid container justifyContent={'center'} spacing={2} mt={2}>
                <Button
                    sx={{
                        background: '#E4980D', color: '#fff', fontWeight: '700', mr:3, '&:hover': {
                            bgcolor: '#E4980D'
                        }
                    }}
                    variant='contained'
                    onClick={() => { setOpenValidation(false); setDataLanding({});props.setOpen(false); }}
                >{'Salir'}
                </Button>
                <Button
                    sx={{
                        background: '#5C68E2', color: '#fff', fontWeight: '700', '&:hover': {
                            bgcolor: '#5C68E2'
                        }
                    }}
                    variant='contained'
                    onClick={() => { setOpenValidation(false);}}
                >{'Continuar'}
                </Button>
            </Grid>
        </Box> 
        </>
    )


    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
                sx={{width:'120%', left:'-10%'}}
            >
                    <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <SpinnerGrow />
                            <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                        </Grid>
                    </Backdrop>
                    {
                       open? <div>hola</div>: openValidation ? BodyEnvioExistente :bodyModal

                    }
                </>
            </Modal>
           
        </div>
    );
}
