
import { campaingRepository } from "../repositories/Campaing.repository";

export const campaingService= {
   getCampaing,
   getDataCampaing,
   createCampaign,
   updateCampaign,
   deleteCampaign,
   dataClientesByProduct,
   getDataCampaingDetails,
   detailsCampaignTrello,
   detailsCampaignLeadsTrello,
   getInputsDetailsCampaignLeads,
   updateUserSalesDetail,
   getCampaingPipelines,
   updatePipelinesDetails,
   createDetailsCampaign,
   updateDetailsCampaignLeadsUser,
   updateDetailsCampaignLeadsPipelines,
   getDetailsCampaignLeads,
   getIdParameter,
   detailsCampaignClient,
   changeStatusDeshabilitado,
   searchDateCampaign,
   searchResultados,
   getClientnoCampaign,
   addClientCampaign,
   translateClient,
   codProduct,
   getCampaignTraslate,
   getCampanaProspecto,
   getCampanaGeneral,
   createCampanaGeneral,
   getChannel,
   updateCampanaGeneral,
   updateVendedorCampanaGeneral,
   searchCodProduct,
   createDetCampaignProduct,
   generateAutomaticCampaign,
   getDetailCampanaleadsById,
   updatePipelinesDetailsAndPrice,
   getCountPipelines,
   getCampaingById
};

async function getCampaing () {
   const campaing= await campaingRepository.getCampaing();
   //console.log(campaing)
   return campaing
}
async function getCampaingById ($IDCAMPANA) {
   const campaing= await campaingRepository.getCampaingById($IDCAMPANA);
   return campaing
}
async function getCampaingPipelines () {
   const campaing= await campaingRepository.getDataCampaingPipelines();
   return campaing
}
async function getDataCampaing () {
   const campaingData= await campaingRepository.getDataCampaing();

   return campaingData
}
async function generateAutomaticCampaign () {
   const campaingData= await campaingRepository.generateAutomaticCampaign();

   return campaingData
}
async function getDataCampaingDetails (id) {
   const campaingDataDetail= await campaingRepository.getDataCampaingDetails(id);
   return campaingDataDetail
}
async function getIdParameter (id) {
   const campaingDataDetail= await campaingRepository.getIdParameter(id);
   return campaingDataDetail
}
async function getDetailsCampaignLeads () {
   const campaingDataDetail= await campaingRepository.getDetailsCampaignLeads();
   return campaingDataDetail
}
async function getInputsDetailsCampaignLeads (form_cliente) {
   const campaingDataDetail= await campaingRepository.getInputsDetailsCampaignLeads(form_cliente);
   return campaingDataDetail
}
async function updateDetailsCampaignLeadsUser (id: number, usuario) {
   const campaingDataDetail= await campaingRepository.updateDetailsCampaignLeadsUser(id, usuario);
   return campaingDataDetail
}
async function updateDetailsCampaignLeadsPipelines (id: number, pipelines) {
   const campaingDataDetail= await campaingRepository.updateDetailsCampaignLeadsPipelines(id , pipelines);
   return campaingDataDetail
}

async function detailsCampaignTrello (id) {
   const campaingDataDetail= await campaingRepository.detailsCampaignTrello(id);
   return campaingDataDetail
}
async function detailsCampaignLeadsTrello (id) {
   const campaingDataDetailLeads= await campaingRepository.detailsCampaignLeadsTrello(id);
   return campaingDataDetailLeads
}
async function createCampaign(campaign) {
   const postCampaign = await campaingRepository.createCampaign(campaign);
   return postCampaign
}
async function createDetailsCampaign(idcampananegocio,name,form_cliente) {
   const postCampaignDetail = await campaingRepository.createDetailsCampaignLeads(idcampananegocio,name,form_cliente);
   return postCampaignDetail
}
async function updateCampaign(id, dataCampaign) {
   return await campaingRepository.updateCampaign(id, dataCampaign)
}
async function updateUserSalesDetail(idDetails, dataCampaign) {
   return await campaingRepository.updateUserSalesCampaign(idDetails, dataCampaign)
}
async function updatePipelinesDetailsAndPrice(idDetails, dataCampaign) {
   return await campaingRepository.updatePipelinesDetailsAndPrice(idDetails, dataCampaign)
}
async function updatePipelinesDetails(idDetails, dataCampaign) {
   return await campaingRepository.updatePipelinesDetailsCampaign(idDetails, dataCampaign)
}
async function deleteCampaign(id) {
   return await campaingRepository.deleteCampaign(id)
}
async function detailsCampaignClient(id) {
   return await campaingRepository.detailsCampaignClient(id)
}
function dataClientesByProduct( codProduct, rango) {
   return campaingRepository.dataClientesByProduct(codProduct, rango);
}
async function changeStatusDeshabilitado () {
   return await campaingRepository.changeStatusDeshabilitado();
}
async function searchDateCampaign (dataCampaign) {
   return await campaingRepository.searchDateCampaign(dataCampaign);
}
async function searchResultados (dataCampaign) {
   return await campaingRepository.searchResultados(dataCampaign);
}
async function getClientnoCampaign (id, dataCampaign) {
   return await campaingRepository.getClientnoCampaign(id, dataCampaign);
}
async function addClientCampaign (dataCampaign) {
   return await campaingRepository.addClientCampaign(dataCampaign);
}
async function translateClient (dataCampaign) {
   return await campaingRepository.translateClient(dataCampaign);
}
async function codProduct (code) {
   return await campaingRepository.codProduct(code);
}
async function getCampaignTraslate (id) {
   return await campaingRepository.getCampaignTraslate(id);
}
async function getCampanaProspecto (id) {
   return await campaingRepository.getCampanaProspecto(id);
}
async function getCampanaGeneral (id) {
   return await campaingRepository.getCampanaGeneral(id);
}
async function getDetailCampanaleadsById (id) {
   return await campaingRepository.getDetailCampanaleadsById(id);
}
async function createCampanaGeneral (dataCampaign) {
   return await campaingRepository.createCampanaGeneral(dataCampaign);
}
async function getChannel () {
   return await campaingRepository.getChannel();
}
async function updateCampanaGeneral (id, data) {
   return await campaingRepository.updateCampanaGeneral(id, data);
}
async function updateVendedorCampanaGeneral (id, data) {
   return await campaingRepository.updateVendedorCampanaGeneral(id, data);
}
async function searchCodProduct (code) {
   return await campaingRepository.searchCodProduct(code);
}
async function createDetCampaignProduct (data) {
   return await campaingRepository.createDetCampaignProduct(data);
}
async function getCountPipelines (id) {
   return await campaingRepository.getCountPipelines(id);
}
