import { http } from '../http/http';
import { API_URL_BASE } from '@/toolbox/defaults/app';

export const RecoveryEmailRepository = {
   sendMailRecovery: async (usuario, rut) => {
      const resp = await http.post(`${API_URL_BASE}/v1/recoveryAccount`,{
         user: usuario,
         rut: rut
      })
      return resp
   },
   sendMailNewPassword: async (id, contraseña) => {
      const resp = await http.post(`${API_URL_BASE}/v1/recoveryAccount/changePassword`,{
         id: id,
         password: contraseña
      })
      return resp
   }
}
