import { SpinnerGrow } from "@/components/common/Spinner";
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import {
    Alert,
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Divider,
    Drawer,
    FormControl,
    FormLabel,
    Grid,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";

const dataPaymentWay = [{ id: 1, name: 'Efectivo' }, { id: 2, name: 'Cheque' }, { id: 3, name: 'Transferencia' }, { id: 4, name: '30 DIAS' }, { id: 5, name: 'Redcompra' }]

export const DrawerDevolucionProductos: React.FC<any> = (props): JSX.Element => {
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const rut_empresa = dataUser?.user?.rut_empresa;

    const { openDrawer, setOpenDrawer, itemsSelected, getArriendo, data } = props;
    const [open, setOpen] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })

    const [paymentImage, setPaymentImage] = useState(null);
    const [codePay, setCodePay] = useState(null)
    const [paymentWay, setPaymentWay] = useState<any>({});


    console.log(itemsSelected)
    const [devoluciones, setDevoluciones] = useState(
        itemsSelected.map((item) => ({ id: item.id, 
            cantidad_actual: item.cantidad_actual,
            cantidad: item.cantidad_actual, 
            producto: item.producto, 
            servicio: item.servicio,
            servicio_devolucion: item.servicio_devolucion,
            fechaInicio: item.fechaInicio,
            fechaFin: item.fechaFin,
            dias_transcurridos: item.dias_transcurridos,
            precio_dia: item?.precio_dia,
            precio_garantia: item?.precio_garantia
         }))
    );

    const handleCantidad = (productId, value) => {
        if(value)
        setDevoluciones((prevDevoluciones) =>
            prevDevoluciones.map((devolucion) =>
                devolucion.id === productId  && value > 0 && value <= devolucion.cantidad_actual? { ...devolucion, cantidad: value } : devolucion
            )
        );
    };

    const confirmarDevoluciones = async () => {
        setOpen(true)
        console.log(devoluciones)

        const formData = new FormData();
        
        formData.append('cod_pago', codePay);
        formData.append('paymentImage', paymentImage);
        formData.append('idarriendo', data?.id);
        formData.append('rut_empresa', rut_empresa);
        formData.append('devoluciones', JSON.stringify(devoluciones))
        formData.append('totalDevolucion', totalDevolucion)
        if(totalDevolucion > 0 && !!!paymentWay){
            setOpen(false)
            return setSnackBarConfig({...snackBarConfig, open:true, message:'Debe de elegir una forma de pago', severity:'error'})
        }
        formData.append('idpayment_way', paymentWay.id)

       
        const registerArriendo = await ArriendosService.confirmarDevolucion(formData);
        if (!!registerArriendo?.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'success' })
            await getArriendo(data?.id);
            setOpenDrawer(false)
        } else {
            setSnackBarConfig({ ...snackBarConfig, open: true, message: registerArriendo?.message, severity: 'error' })
        }
        setOpen(false)

    };

    const totalDevolucion = devoluciones.reduce((total, devolucion) => {
        const item = itemsSelected.find((item) => item.id === devolucion.id);
        console.log(item)
        return total + devolucion.cantidad * item?.deuda_actual/item?.cantidad_actual;
    }, 0);

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
            <Box
                sx={{
                    width: "450px",
                    padding: "20px",
                    paddingX: "40px",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                    Devolución de Productos
                </Typography>

                <div style={{
                    borderBottom: '3px solid #155172', // Línea inferior gruesa
                    borderTop: '3px solid #155172', // Línea superior gruesa solo para el primer elemento
                    padding: '10px',
                    marginBottom: '10px',
                    height: '400px',
                    minWidth: '400px',
                    overflowY: 'auto',
                    margin: '0 auto', // Centrar el contenedor en el drawer
                }}>
                    {itemsSelected.map((item, index) => (
                        <div key={item.id} style={{
                            borderBottom: index < itemsSelected.length - 1 ? '1px solid #ddd' : 'none', // Línea delgada entre elementos
                            paddingBottom: '10px',
                            paddingTop: '5px',
                            marginBottom: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}>
                            <>
                                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000' }}>
                                    {`(${item?.servicio_devolucion?.codigo}) ${item?.servicio_devolucion?.nombre}`}
                                </Typography>

                                <Typography sx={{ color: '#606060' }}>
                                    {`${item?.dias_transcurridos} días pendientes de pago`}
                                </Typography>

                                <Typography sx={{ color: '#000' }}>
                                    {` $ ${(item?.deuda_actual/item?.cantidad_actual)*(devoluciones.find((devolucion) => devolucion.id === item.id)?.cantidad )} CLP`}
                                </Typography>

                                <div style={{ display: 'flex', alignItems: 'center', borderRadius: '4px', overflow: 'hidden' }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleCantidad(item.id, devoluciones.find((devolucion) => devolucion.id === item.id)?.cantidad - 1)}
                                        sx={{
                                            color: '#000', bgcolor: '#fff', minWidth: '30px', padding: '6px 12px', fontSize: '20px', '&:hover': {
                                                bgcolor: '#fff'
                                            }
                                        }}
                                    >
                                        -
                                    </Button>
                                    <Typography sx={{ margin: '0 8px', minWidth: '20px', textAlign: 'center', fontSize: '16px' }}>
                                        {devoluciones.find((devolucion) => devolucion.id === item.id)?.cantidad || 0}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleCantidad(item.id, devoluciones.find((devolucion) => devolucion.id === item.id)?.cantidad + 1)}
                                        sx={{
                                            color: '#000', bgcolor: '#fff', minWidth: '30px', padding: '6px 12px', fontSize: '20px', '&:hover': {
                                                bgcolor: '#fff'
                                            }
                                        }}
                                    >
                                        +
                                    </Button>
                                </div>
                            </>
                        </div>
                    ))}
                </div>
                {
                    totalDevolucion != 0 &&
                    <>
                    <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#000', marginTop: "20px", marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${totalDevolucion} CLP`}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000',marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>IVA</strong>
                    {` $ ${totalDevolucion*0.19} CLP`}
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '20px', color: '#000',marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                    <strong>Total</strong>
                    {` $ ${totalDevolucion + (totalDevolucion*0.19)} CLP`}
                </Typography>
                <Typography sx={{ color: '#333', marginBottom: "10px" }}>
                    Se hará efectiva la facturación por devolución, por lo cual de manera opcional puede adjuntar un número de pago e imagen.
                </Typography>
                {/* <Grid item xs={12}> */}
                    <FormLabel>¿Qué forma de pago utilizará? </FormLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-company"
                        value={paymentWay}
                        options={dataPaymentWay || []}
                        getOptionLabel={(option) => option.name || ""}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        fullWidth
                        renderInput={(params) => <TextField {...params} size="small"
                        />}
                        onChange={(e, newValue) => {
                            setPaymentWay(newValue);
                        }}
                    />
                {/* </Grid> */}
 
                <input type="file" accept="image/*" 
                    onChange={(e) => { setPaymentImage(e.target.files[0])}}
                />

                <FormControl sx={{ marginTop: "10px" }}>
                    <TextField
                        id="cod_pago"
                        type="text"
                        multiline
                        name="cod_pago"
                        onChange={(e)=>setCodePay(e.target.value)}
                        fullWidth
                        size="small"
                        placeholder="Ingrese el número de pago como referencia"
                    />
                </FormControl>

                    </>
                }
                
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        marginTop: "20px",
                        backgroundColor: "#155172",
                        color: "white",
                        padding: '20px',
                        position: 'relative',
                        overflow: 'hidden',
                        '&:hover': {
                            background: 'linear-gradient(to right, rgba(21, 81, 114, 0.8) 100%, transparent 50%)',
                        }
                    }}
                    onClick={() => confirmarDevoluciones()}
                >
                    Confirmar Devolución
                </Button>

            </Box>
        </Drawer>

    );
};
