import { TableData } from "@/components/common/Table";
import { Protected } from "@/components/layout/Protected"
import { ArriendosService } from "@/service/services/Arriendos.service.";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Backdrop, Button, Chip, Container, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { DrawerDetailInventory } from "./DrawerDetailInventory";

export const InventoryProducts: React.FC<any> = (props): JSX.Element => {

    const [open, setOpen] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })

    const [perPageData, setPerPageData] = useState<number>(5);
    const [pageData, setPageData] = useState<number>(1)
    const [Total, setTotal] = useState<any>(0)
 
    const userData = readLocalStorage(KEY_USER_DATA);
    const idempresa = userData?.user?.idempresacliente;
 
    const PerPage = async (data) => {
        setPerPageData(data)
        setPageData(0)
     }
  
     const page = async (data) => {
        setPageData(data + 1)
     }

   const [dataProductosBusqueda, setDataProductosBusqueda] = useState<any>([])

   const [openDrawerDetail, setOpenDrawerDetail] = useState<boolean>(false)
     const [itemSelected, setItemSelected] = useState<any>(null)
    
   useEffect(() => {
    try {
       getProductosArriendos();
    } catch (e) {
       console.log(e)
    }
 }, [pageData, perPageData])

    const getProductosArriendos = async (search = '') => {
        setOpen(true);
        const response = await ArriendosService.listarProductsArriendo(search, idempresa);
  
        if (!!response?.status) {
           setDataProductosBusqueda(response?.data)
        } else {
           setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
     }

     const formatNumber = (value) => new Intl.NumberFormat().format(value);
     const getAvailabilityStatus = (available) => (
        <Chip
           label={available ? 'Disponible' : 'Arrendado'}
           color={available ? 'success' : 'error'}
           size="small"
        />
     )
  
     const getAvailabilityBar = (disponibles, arrendados) => {
        const total = disponibles + arrendados;
  
        return (
           <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                 style={{
                    width: `${(disponibles / total) * 200}%`,
                    height: '20px',
                    backgroundColor: '#4CAF50', // Verde para disponibles
                    borderRadius: '4px 0 0 4px',
                 }}
              />
              <div
                 style={{
                    width: `${(arrendados / total) * 200}%`,
                    height: '20px',
                    backgroundColor: '#F44336', // Rojo para arrendados
                    borderRadius: '0 4px 4px 0',
                 }}
              />
              <span style={{ marginLeft: '5px' }}>{formatNumber(disponibles)}</span>
              <span style={{ marginLeft: '5px' }}>{' / '}</span>
              <span style={{ marginLeft: '5px' }}>{formatNumber(arrendados)}</span>
           </div>
        );
     };

     const openDrawer = ( productItem) => {
         setOpenDrawerDetail(true)
         setItemSelected(productItem)
     }

    return (
        <Protected>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            ></Backdrop>
            <div>
                <Container maxWidth='xl'>
                    <Grid container justifyContent='space-between' spacing={3}>
                    <Typography sx={{ color: '#155172', margin: '30px' ,fontWeight: '700', fontSize: '2.8em' }} >Inventario de productos</Typography>
                    
                    </Grid>
                    <Grid container sx={{ mt: 1 }}>
                    {
                  dataProductosBusqueda.length > 0 ?
                     <TableContainer component={Paper} elevation={3} style={{ borderRadius: '10px', overflow: 'hidden' }}>
                        <Table>
                           <TableHead sx={{ bgcolor: '#3f9bcc' }}>
                              <TableRow>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }} >Código</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Nombre del Producto</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Stock</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Disponibles / Arrendados</TableCell>
                                 <TableCell align="center" style={{ fontWeight: 'bold', color: 'white', fontSize: '1.1em' }}>Estado</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {dataProductosBusqueda.map((product) => (
                                 <TableRow key={product.id}>
                                    <TableCell align="center">{product.codigo}</TableCell>
                                    <TableCell align="center">{product.nombre_producto}</TableCell>
                                    <TableCell align="center">
                                       <Button onClick={()=>openDrawer(product)}>
                                          {formatNumber(product.stock)}
                                       </Button>
                                    </TableCell>
                                    <TableCell align="center">{getAvailabilityBar(formatNumber(product.productos_disponibles), formatNumber(product.productos_arrendados))}</TableCell>
                                    <TableCell align="center">
                                       {getAvailabilityStatus(product.productos_disponibles > 0)}
                                       {product.productos_disponibles > 0 ? <TrendingUpIcon style={{ marginLeft: '5px', color: 'green' }} /> : <TrendingDownIcon style={{ marginLeft: '5px', color: 'red' }} />}
                                    </TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     :
                     <div style={{ height: 280, width:1700, marginTop:'30px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#E9E7E3' }}>
                        <Typography variant='h5'>No se encontraron resultados.</Typography>
                     </div>
               }
               </Grid>
                </Container>
                {
                  openDrawerDetail && 
                  <DrawerDetailInventory
                  openDrawer={openDrawerDetail}
                  setOpenDrawer={setOpenDrawerDetail}
                  data={itemSelected}
                  />

                }
            </div>
        </Protected>
    )
}
