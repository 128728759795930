import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { Protected } from '@components/layout/Protected';
import { Props } from './Programmation.type';
import { Alert, Backdrop, Button, CircularProgress, Container, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import { LeaveIcon } from "@toolbox/constants/icons";
import { ModalAddAction } from "@/components/common/Modal/ModalAddAction";
import { ModalAddTaskProjection } from "@/components/common/Modal/ModalAddTaskProjection";
import { dateFormat, dateFormatTime } from "@/toolbox/helpers/date.helper";
import { ModalDetailsAction } from "@/components/common/Modal/ModalDetailAction";
import { accionService } from "@/service/services/Accion.service";
import { ConfirmDialog } from "@/components/common/DialogConfirm";
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import { SpinnerGrow, SpinnerLoad } from "@/components/common/Spinner";
import { proyeccionService } from "@/service/services/Proyeccion.service";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import moment from "moment";
import AddIcon from '@mui/icons-material/Add';
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN, ROLE_ADMIN_PROYECCION, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";

export const ProgrammationView: React.FC<Props> = ({ }): JSX.Element => {
   const [actions, setActions] = useState<any>([]);
   const [actionEdit, setActionEdit] = useState(null);
   const [open, setOpen] = useState(false);
   const [openLoading, setOpenLoading] = useState(false);
   const [openLoadingDetails, setOpenLoadingDetails] = useState(false);
   const [openNewProyeccion, setOpenNewProyeccion] = useState(false);
   const [openDetailsEvent, setOpenDetailsEvent] = useState(false);
   const [sentDateData, setSentDateData] = useState(null);
   const [listProjection, setListProjection] = useState<any>();
   const [openView, setOpenView] = useState(false);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      actionId: null,
      message: `¿Desea eliminar la accion ---?`
   })
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'warning',
      message: 'Error',
      autoHideDuration: 1000,
   })
   const [dataProyeccion, setDataProyeccion] = useState<any>([])

   useEffect(() => {
      acciones()
      getProyeccion();
   }, [])

   const acciones = async () => {
      setOpenLoading(true)
      const resp = await accionService.getAccion();
      console.log(resp.data)
      setActions(resp.data);
      setOpenLoading(false)
   }

   async function getProyeccion() {
      setOpenLoading(true);
      const resp = await proyeccionService.getProyeccion();
      setDataProyeccion(resp.data);
      setOpenLoading(false);
   }

   async function getActionByProjection(idProyeccion) {
      setOpenLoading(true);
      const resp = await accionService.getActionByProjection(idProyeccion);
      if (resp.data == null) {
         setActions([])
      } else {
         setActions(resp.data);
      }
      //getProyeccion()
      setOpenLoading(false);
   }

   const RenderProjection = () => {
      getProyeccion();
   }

   const PosicionEvent = async (id, tipotarea) => {
      const pos = parseInt(id);
      setOpenLoadingDetails(true);
      const resp = await accionService.getActionById(pos);
      setOpenLoadingDetails(false);
      const data = resp.data[0];
      let updateAction = {
         ...data,
         fecha_inicio: dateFormat(data.fecha_inicio),
         fecha_fin: dateFormat(data.fecha_fin)
      }
      setActionEdit(updateAction);
      setOpenDetailsEvent(true)
   };

   const actionAdd = async (action) => {

      if (action.idfrecuencia) {

         let FechaFinalProyeccion;
         setOpenLoadingDetails(true);

         if (action.proyeccion) {
            FechaFinalProyeccion = moment(action.proyeccion.fecha_fin).format('YYYY-MM-DD')

         } else {
            FechaFinalProyeccion = moment().add(2, 'M').format('YYYY-MM-DD')
         }

         let FechaInicialAccion = moment(action.fecha_inicio).format('YYYY-MM-DD')
         let FechaFinalAction = moment(action.fecha_fin).format('YYYY-MM-DD')


         let resp;

         while (FechaFinalAction < FechaFinalProyeccion) {

            resp = await accionService.createAccion(action);
            // if (resp.data) {
            //    const changedAction = [resp.data, ...actions];
            //    setActions(changedAction);

            // }
            if (action.idfrecuencia == 1) {
               FechaFinalAction = moment(FechaFinalAction).add(1, 'w').format('YYYY-MM-DD')
               FechaInicialAccion = moment(FechaInicialAccion).add(1, 'w').format('YYYY-MM-DD')
            }
            if (action.idfrecuencia == 2) {
               FechaFinalAction = moment(FechaFinalAction).add(4, 'd').format('YYYY-MM-DD')
               FechaInicialAccion = moment(FechaInicialAccion).add(4, 'd').format('YYYY-MM-DD')

            }
            if (action.idfrecuencia == 3) {
               FechaFinalAction = moment(FechaFinalAction).add(1, 'M').format('YYYY-MM-DD')
               FechaInicialAccion = moment(FechaInicialAccion).add(1, 'M').format('YYYY-MM-DD')

            }
            if (action.idfrecuencia == 4) {
               FechaFinalAction = moment(FechaFinalAction).add(15, 'd').format('YYYY-MM-DD')
               FechaInicialAccion = moment(FechaInicialAccion).add(15, 'd').format('YYYY-MM-DD')

            }

            if (action.hora_inicio === "00:00" && action.hora_fin === '00:00') {
               action.fecha_inicio = FechaInicialAccion;
               action.fecha_fin = FechaFinalAction;
            } else {
               action.fecha_inicio = `${FechaInicialAccion} ${action.hora_inicio}`;
               action.fecha_fin = `${FechaInicialAccion} ${action.hora_inicio}`;
            }


         }
         setOpen(false);
         acciones()
         setOpenLoadingDetails(false);

      } else {
         setOpenLoadingDetails(true);
         const resp = await accionService.createAccion(action);
         if (resp.data) {
            const changedAction = [resp.data, ...actions];
            setActions(changedAction);
            setOpen(false);
         }
         setOpenLoadingDetails(false);
      }

   };

   const actionUpdate = async (actionEdit) => {
      setOpenLoadingDetails(true);
      const resp = await accionService.updateAction(actionEdit.id, actionEdit);
      if (resp.data) {
         const changedActions = actions.map((action) =>
            action.id === actionEdit.id ? actionEdit : action
         );
         setActions(changedActions);
         setOpen(false);
      }
      setOpenLoadingDetails(false);
   };

   const actionDelete = (data) => {
      const actionId = data.id;
      setDialog(prev => ({
         ...prev,
         message: `¿Desea eliminar la accion ${data.asunto}?`,
         actionId: actionId, open: true, confirm: true
      }));
   }

   const deleteAccion = async () => {
      if (Dialog.confirm == true) {
         const resp = await accionService.deleteAccion(Dialog.actionId);
         if (resp.data) {
            acciones();
         }
      }
   }

   // const contactId = async (data) => {
   //    const contactId = data.idcliente;
   //    setDialog(prev => ({ ...prev, message: `¿Desea eliminar al contacto ${data.nombre_rz} con Rut ${data.rut_cliente}?`,contactId:contactId,open: true, confirm:true}));
   // };

   const handleDateSelect = (selectInfo) => {
      var now = moment().format('YYYY-MM-DD')
      let FechaInicial = moment(selectInfo.start).format('YYYY-MM-DD')

      if (now <= FechaInicial) {
         setOpen(true);
         const datadate = {
            fecha_inicio: dateFormat(selectInfo.start),
            hora_inicio: dateFormatTime(selectInfo.start),
            fecha_fin: dateFormat(selectInfo.end),
            hora_fin: dateFormatTime(selectInfo.end),
         };
         setSentDateData(datadate);
      }else
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "solo se puede crear acciones actuales",
         }));
      }
   };

   const handleEventClick = (clickInfo) => {
      PosicionEvent(clickInfo.event.id, clickInfo.event.title);
   };

   const listaProyecciones = dataProyeccion?.map((value) => {
      return (
         <Grid >
            <Button
               startIcon={<AccountTreeIcon sx={{ color: '#007EA7' }} />}
               variant='text'
               sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em', mt: 1 }}
               onClick={() => {
                  getActionByProjection(value.idproyeccion)
               }}

            >
               {value.nombre}
            </Button>
         </Grid>


      )

   })
   const VistaButtonAddProyeccion = () => {
      switch (dataUser.user.role) {
         case ROLE_ADMIN:
            return (
               <Grid item>
                  <Grid item>
                  <Button variant="contained"
                     color="primary"
                     startIcon={<AddIcon />}
                     sx={{
                        background: '#E4980D', color: '#fff', '&:hover': {
                           bgcolor: '#E4980D'
                        }
                     }}
                     onClick={() => { setOpenNewProyeccion(true) }}>CREAR PROYECCIÓN</Button>
                     </Grid>
                  <Grid item mt={6} sx={{ width: '220px' }}>
                     <Button
                        startIcon={<AccountTreeIcon sx={{ color: '#007EA7' }} />}
                        variant='text'
                        sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em' }}
                        onClick={() => {
                           acciones()
                        }}
                     >
                        {'Todos'}
                     </Button>
                     {listaProyecciones}
                  </Grid>
                  </Grid>
            )
         case ROLE_ADMIN_PROYECCION:
            return (
               <Grid item>
                  <Grid item>
                  <Button variant="contained"
                     color="primary"
                     startIcon={<AddIcon />}
                     sx={{
                        background: '#E4980D', color: '#fff', '&:hover': {
                           bgcolor: '#E4980D'
                        }
                     }}
                     onClick={() => { setOpenNewProyeccion(true) }}>CREAR PROYECCIÓN</Button>
                     </Grid>
                  <Grid item mt={6} sx={{ width: '220px' }}>
                     <Button
                        startIcon={<AccountTreeIcon sx={{ color: '#007EA7' }} />}
                        variant='text'
                        sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em' }}
                        onClick={() => {
                           acciones()
                        }}
                     >
                        {'Todos'}
                     </Button>
                     {listaProyecciones}
                  </Grid>
                  </Grid>
            )
      }
   }


   return (
      <Protected>
         <ConfirmDialog
            open={Dialog.open}
            title={Dialog.title}
            message={Dialog.message}
            onConfirm={() => deleteAccion()}
            onClose={() => setDialog(prev => ({ ...prev, open: false }))}
         />

         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoading}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoadingDetails}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerLoad />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth="xl" sx={{ mt: 1, mb: 2 }}>
            <Grid container>
               <Grid item container direction="column" xs={12} lg={3}>
                  { VistaButtonAddProyeccion() }
               </Grid>
               <Grid item xs={12} lg={dataUser.user.role == ROLE_ADMIN || dataUser.user.role == ROLE_ADMIN_PROYECCION ? 9 : 12} >
                  <FullCalendar
                     height={570}
                     initialView="dayGridMonth"
                     plugins={[
                        timeGridPlugin,
                        dayGridPlugin,
                        listPlugin,
                        interactionPlugin,
                        bootstrapPlugin,
                     ]}
                     headerToolbar={{
                        left: "title",
                        right:
                           "prev,next,today,dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                     }}
                     buttonText={{
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Dia",
                        list: "Lista",
                     }}
                     locale={"es-us"}
                     themeSystem="Cerulean"
                     allDayText="Todo el día"
                     events={actions.map((action, i) => {
                        return {
                           id: action.id,
                           title: action.asunto,
                           start: action.hora_inicio == "00:00:00" ? dateFormat(action.fecha_inicio) : action.fecha_inicio,
                           end: action.hora_fin == "00:00:00" ? dateFormat(action.fecha_fin) : action.fecha_fin,
                           color: action.color,
                        };
                     })}
                     editable={true}
                     droppable={true}
                     selectable={true}
                     select={handleDateSelect}
                     eventClick={handleEventClick}
                     // eventLimit={true}
                     navLinks={true}
                     handleWindowResize={true}
                  />
               </Grid>
            </Grid>
         </Container>
         <ModalAddAction
            actionAdd={actionAdd}
            open={open}
            setOpen={setOpen}
            // actionEdit={actionEdit}
            // setActionEdit={setActionEdit}
            // actionUpdate={actionUpdate}
            sentDateData={sentDateData}
            setSentDateData={setSentDateData}
         />

         <ModalAddTaskProjection
            openNewCampaing={openNewProyeccion}
            setOpenNewCampaing={setOpenNewProyeccion}
            renderProjection={RenderProjection}
         />

         <ModalDetailsAction
            openDetailsEvent={openDetailsEvent}
            setOpenDetailsEvent={setOpenDetailsEvent}
            actionEdit={actionEdit}
            setActionEdit={setActionEdit}
            actionUpdate={actionUpdate}
            actionDelete={actionDelete}
         />
      </Protected>
   );
}
