import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, StepIconProps } from '@mui/material'

import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Icon } from '@components/common/Icon';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";

import { FormCreateEnterprise, FormCreateUser } from '@components/common/Form';
import { Props } from './SteperAccount.type';
import { Protected } from '@/components/layout/Protected';
import { useHistory } from 'react-router-dom'
import { ROUTE_ACCOUNT } from '@/toolbox/constants/route-map';
import "./Steper.sass";
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import { entityService } from '@/service/services/Entity.service';
import { MenuLateral } from '@/components/common/LateralMenu';
import { FormEntity } from './components/FormEntity';
import { FormContactEntity } from './components/FormContactEntity';
import { FormAdminUser } from './components/FormAdminUser';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ModalAddEntity } from '@/components/common/Modal/ModalAddEntity';

const steps = [{name:'Empresa',icon:null},
// {name:'Contacto Empresa',icon:null},
{name:'Usuario',icon:null}];

export const SteperAccount: React.FC<Props> = (props: Props): JSX.Element => {
   const history = useHistory();
   const [activeStep, setActiveStep] = useState(-1);
   const [skipped, setSkipped] = useState(new Set());
   const [error, setError] = useState(null);
   const [openAddEntity, setOpenAddEntity] = useState(false);
   const [entitySoftnet,setEntitySoftnet]= useState<any>([]);
   const [valueEntity, setValueEntity] = useState({
      rut_empresa: '',
      // idtiporubro: '',
      propietario: '',
      nombre_rz: "",
      nombre_fantasia: "",
      giro: "",
      telefono: '',
      // fax: '',
      // prospecto: '',
      // observacion: '',
      // extranjero: '',
      // web: '',
      direccion: '',
      idcomuna: ''
   });

   const [valueContactEntity, setValueContactEntity] = useState({
      nombre_cto: '',
      cargo_cto: '',
      email_cto: '',
      telefono_cto: '',
      celular_cto: '',
      cobranza_cto: '',
   });
   const [valueAdminUser, setValueAdminUser] = useState({
      idtipousuario: '',
      nombre: '',
      user_nick: '',
      email: '',
      telefono: '',
      password: '',
   });


   useEffect(() => {
      setActiveStep(0)
      if (props.entity !== undefined) {
         setValueEntity(props.entity);
        // setValueContactEntity({...props.entity,...valueEntity})
         //setValueContactEntity(props.entity);
        console.log('entre');
        console.log(props.entity);
       // console.log(props.entity.idempresacliente);
         getAdminUser(props.entity.idempresacliente)
      }
   }, [])

   async function getAdminUser(id: number) {
      console.log('id',id)
      const resp = await entityService.getEntityUsers(id);
      console.log(resp);
      const dataUsuarioAdmin = resp.data.filter(userAdmin => userAdmin.idtipousuario == 1 ||userAdmin.idtipousuario == 2)
      console.log(dataUsuarioAdmin);
      setValueAdminUser({ ...dataUsuarioAdmin[0], action: props.entity.action });
   }
   //metodos para manipular el stepp
   const isStepSkipped = (step) => {
      return skipped.has(step);
   };

   const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
         newSkipped = new Set(newSkipped.values());
         newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };
   //opcional color steper
   const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage:
            'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage:
            'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
      },
    }));

    const ColorlibStepIconRoot = styled('div')<{
      ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      ...(ownerState.active && {
        backgroundImage:
          'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(ownerState.completed && {
        backgroundImage:
          'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
      }),
    }));

    function ColorlibStepIcon(props: StepIconProps) {
      const { active, completed, className } = props;

      const icons: { [index: string]: React.ReactElement } = {
        1: <ApartmentIcon />,
        2: <GroupAddIcon />,
        3: <PersonAddAltIcon />,
      };

      return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
          {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
      );
    }
   //validacion para mostrar los 2 tipos de formularios en el stepper
   const mostrarFormulario = () => {
      if (activeStep === 0) {
         return (
            <FormEntity
               handleNext={handleNext}
               valueEntity={valueEntity}
               setValueEntity={setValueEntity}
            />
         )
      }
      else if (activeStep === 1) {
         return (
            <FormAdminUser
            handleNext={handleNext}
            handleBack={handleBack}
            valueEntity={valueEntity}
            valueAdminUser={valueAdminUser}
           // valueContactEntity={valueContactEntity}
            setValueAdminUser={setValueAdminUser}
         />
            // <FormContactEntity
            //    handleNext={handleNext}
            //    handleBack={handleBack}
            //    valueContactEntity={valueContactEntity}
            //    setValueContactEntity={setValueContactEntity}
            // />
         )
      }
      // else if (activeStep === 2) {
      //    return (
      //       <FormAdminUser
      //          handleNext={handleNext}
      //          handleBack={handleBack}
      //          valueEntity={valueEntity}
      //          valueAdminUser={valueAdminUser}
      //          valueContactEntity={valueContactEntity}
      //          setValueAdminUser={setValueAdminUser}
      //       />
      //    )
      // }
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container flexDirection='row' spacing={2}>
               {/* <Grid item md={3} container>
                  <MenuLateral />
               </Grid> */}
               <Grid item md={12}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #1976D2 ', mt: 3 }}
               >
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                 {activeStep==0 && <Grid item xs={12}>
                   <button style={{background:'#199BD1',borderRadius:'5px',border:'1px solid #1987D2',color:'#fff'}} onClick={()=>{setOpenAddEntity(true)}}>Agregar Datos de la Empresa desde Erp</button>
                </Grid>}
                     <Grid item xs={12}>
                        <Box>
                           <Box sx={{ mb: 2 }}>
                              <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                 {steps.map((label, index) => {
                                    return (
                                       <Step key={index}>
                                          <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                       </Step>
                                    );
                                 })}
                              </Stepper>
                           </Box>
                           {activeStep === steps.length ? (
                              <div>FINISH</div>
                              //VALIDAR QUE EL ENVIO DE DATOS
                              // history.push(ROUTE_ACCOUNT)
                           ) : (
                              mostrarFormulario()
                           )}
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
            <ModalAddEntity
                  open={openAddEntity}
                  setOpen={setOpenAddEntity}
                  dataEntity={setValueEntity}
                  />
         </Container>
      </Protected>
   );
}
