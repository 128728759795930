import React, { useState, useMemo, useRef, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog, InputAdornment } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';

import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import PersonIcon from '@mui/icons-material/Person';
import './Modal.css'
import './Modal.sass'
import EmailIcon from '@mui/icons-material/Email';
import { Input } from '../Input';
import { VALIDATORS } from '@/toolbox/helpers/validation-rules';
import { InputRef } from '../Input/InputInterfaces';

type ModalProps = {
   open: boolean,
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalSentPassword: React.FC<ModalProps> = (
   props: ModalProps
): JSX.Element => {
   //console.log(props.open)
   const [error, setError] = useState(null);
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [data, setData] = useState({
      email:'',
      textError: '',
   });


   const rules = useMemo(() => ({
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
   }), []);
   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      //console.log(value)
      switch (name) {
         case 'email':
            if ((/^[\w@.-]{0,86}$/).test(value)) {
               setData(prev => ({ ...prev, email: value }));
            }
         break;
            //setData(prev => ({ ...prev, email: value }));
      }
   }
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()) {
         //console.log(data.email)
      }
   }

   return (
      <div>

         <Modal
            open={props.open}
            onClose={() => { props.setOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '350px',
               bgcolor: 'background.paper', background: '#F5F5F5', borderRadius: 2, boxShadow: 24
            }}>

               <Box >
                  <div className="modal-header">
                     <PersonIcon />
                     <Typography style={{ fontSize: "18px", marginLeft: "5px" }}>Recuperar Cuenta</Typography>
                  </div>
                  <form onSubmit={handleSubmit}>
                     <Grid className="modal-body">
                        <Grid
                           container
                           direction="row"
                           justifyContent="flex-end"
                           alignItems="center">
                           <Grid xs={12}  >
                              <Typography className='modal-tittle' >La información será enviada a este correo electrónico</Typography>
                           </Grid>
                           <Grid xs={12} >
                           <Input
                     ref={ref => inputRefs.current[0] = ref}
                     placeholder="Email Contacto"
                     height={35}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <EmailIcon />
                    </InputAdornment>
                    }
                     name="email"
                     //placeholder="Email Contacto"
                     value={data.email}
                     onChange={handleInputChange}
                     onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.email}
                     disableElevation
                     validateOnBlur
                     dense
                  />
                           </Grid>
                        </Grid>

                     </Grid>
                     <Divider style={{ marginBottom: "2vh" }} />
                     <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
                        <Button type={"button"} sx={{ borderRadius: '15px' }} size="small" >
                           <span style={{ color: "gray" }}>Cancelar</span>
                        </Button>
                        <Grid xs={1} />
                        <Button onClick={handleSubmit}sx={{ mr: 2, borderRadius: '15px', backgroundColor: "#1976D2", }} variant="contained" size="small" type='submit'>
                           Enviar
                        </Button>
                     </Grid>
                  </form>
               </Box>
            </Box>

         </Modal>
      </div>
   );
}
