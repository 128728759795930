import {
   ROUTE_HOME,
   ROUTE_CAMPAIGN,
   ROUTE_CONTACT,
   ROUTE_SEETING,
   ROUTE_ACTIVIDADES,
   ROUTE_RULE,
   ROUTE_PRODUCT,
   ROUTE_PERFIL,
   ROUTE_ENTITY,
   ROUTE_USER,
   ROUTE_SINCRONIZACION,
   ROUTE_CONFIGURACION,
   ROUTE_PROGRAMATION,
   ROUTE_NEGOCIOS,
   ROUTE_ACCOUNT,
   ROUTE_PROYECCION,
   ROUTE_COTIZACION_INTERNA,
   ROUTE_AREA_NEGOCIO,
   ROUTE_CLIENTS_DOC,
   ROUTE_CORREOS,
   ROUTE_ARCHIVADOS,
   ROUTE_DESTACADOS,
   ROUTE_CORREOS_IMAP,
   ROUTE_ARRIENDOS,
   ROUTE_ONBOARDING_ARRIENDOS,
   ROUTE_SERVICE
   //ROUTE_USERS,
} from "../constants/route-map";

export const STATIC_ROUTES_ARRIENDO = [
   // {
   //    "name": "Productos",
   //    "icon": "EvaluateIcon",
   //    "moduloId": 4,
   //    "route": ROUTE_PRODUCT,
   //    "isMenu": true,
   //    "route-navigators": [
   //    ],
   //  },
    {
      "name": "Servicios",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_SERVICE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Cliente",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_CONTACT,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Arriendos",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_ARRIENDOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Onboarding",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_ONBOARDING_ARRIENDOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Mi Cuenta",
      "icon": "UserIcon",
      "moduloId": 3,
      "route": ROUTE_ACCOUNT,
      "isMenu": true,
      "onClick":"handleProfileAccountOpen",
      "route-navigators": [
         ROUTE_PERFIL,
         ROUTE_SINCRONIZACION,
         ROUTE_CONFIGURACION
      ],
      "subMenu": [
         {
            "name": "Perfil",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_PERFIL,
         },
         {
            "name": "Sincronizacion",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_SINCRONIZACION,
         },
         {
            "name": "Configuracion",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_CONFIGURACION,
         }
      ]
    },
]

export const STATIC_ROUTERS = [
    {
      "name": "Inicio",
      "icon": "EvaluateIcon",
      "moduloId": 1,
      "route": ROUTE_HOME,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Actividades",
      "icon": "EvaluateIcon",
      "moduloId": 2,
      "route": ROUTE_ACTIVIDADES,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Programación",
      "icon": "EvaluateIcon",
      "moduloId": 3,
      "route": ROUTE_PROGRAMATION,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Progreso",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_PROYECCION,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Cliente",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_CONTACT,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Documentos",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_CLIENTS_DOC,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Negocios",
      "icon": "UserIcon",
      "moduloId": 3,
      "route": ROUTE_NEGOCIOS,
      "isMenu": true,
      "onClick":"handleProfileMenuOpen",
      "route-navigators": [
        // ROUTE_USERS_REGISTER,
      ],
      "subMenu": [
         {
            "name": "Campañas",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_CAMPAIGN,
         },
         {
            "name": "Reglas de Negocio",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_RULE,
         }
      ]
    },

    {
      "name": "Productos",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_PRODUCT,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Servicios",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_SERVICE,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Arriendos",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_ARRIENDOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Onboarding",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_ONBOARDING_ARRIENDOS,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Cotización",
      "icon": "EvaluateIcon",
      "moduloId": 3,
      "route": ROUTE_COTIZACION_INTERNA,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Empresas",
      "icon": "EvaluateIcon",
      "moduloId": 4,
      "route": ROUTE_ENTITY,
      "isMenu": true,
      "route-navigators": [
      ],
    },
    {
      "name": "Correos",
      "icon": "UserIcon",
      "moduloId": 3,
      "route": ROUTE_CORREOS_IMAP,
      "isMenu": true,
      "onClick":"handleProfileCorreoOpen",
      "route-navigators": [
         ROUTE_CORREOS,
         ROUTE_DESTACADOS,
         ROUTE_ARCHIVADOS
      ],
      "subMenu": [
         {
            "name": "Correos Entrantes",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_CORREOS,
         },
         {
            "name": "Correos Destacados",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_DESTACADOS,
         },
         {
            "name": "Correos Archivados",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_ARCHIVADOS,
         }
      ]
    },
    {
      "name": "Mi Cuenta",
      "icon": "UserIcon",
      "moduloId": 3,
      "route": ROUTE_ACCOUNT,
      "isMenu": true,
      "onClick":"handleProfileAccountOpen",
      "route-navigators": [
         ROUTE_PERFIL,
         ROUTE_ENTITY,
         ROUTE_USER,
         ROUTE_PROYECCION,
         ROUTE_SINCRONIZACION,
         ROUTE_CONFIGURACION
      ],
      "subMenu": [
         {
            "name": "Perfil",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_PERFIL,
         },
         {
            "name": "Empresas",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_ENTITY,
         },
         {
            "name": "Usuarios",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_USER,
         },
         {
            "name": "Area Negocio",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_AREA_NEGOCIO,
         },
         {
            "name": "Proyeccion",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_PROYECCION,
         },
         {
            "name": "Sincronizacion",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_SINCRONIZACION,
         },
         {
            "name": "Configuracion",
            "icon": "UserIcon",
            "isMenu": true,
            "route": ROUTE_CONFIGURACION,
         }
      ]
    },
   //  {
   //    "name": "Configuración",
   //    "icon": "SettingIcon",
   //    "moduloId": 4,
   //    "route": ROUTE_SEETING,
   //    "isMenu": true,
   //    "route-navigators": [
   //    //   ROUTE_SEETING_REGISTER_PERIOD
   //    ],
   //    "subMenu": [
   //      {
   //        "name": "Configuración",
   //        "icon": "SettingIcon",
   //        "page": {
   //          "compare": null,
   //          "displayName": "Connect(Component)"
   //        },
   //        "isMenu": true,
   //        "route": ROUTE_SEETING,
   //        "actions": {
   //          "actionInsert": true,
   //          "actionUpdate": true,
   //          "actionDelete": true,
   //          "actionSearch": true
   //        }
   //      }
   //    ]
   //  },
   //  {
   //    "name": "Vistas Contactos",
   //    "icon": "ViewUserIcon",
   //    "moduloId": 5,
   //    "route": ROUTE_CONTACT,
   //    "isMenu": true,
   //    "route-navigators": [

   //    ],
   //    "subMenu": [
   //       {
   //          "name": "Evaluación",
   //          "icon": "ViewUserIcon",
   //          "page": {
   //             "compare": null,
   //             "displayName": "Connect(Component)"
   //          },
   //          "isMenu": true,
   //          "route": ROUTE_CAMPAIGN,
   //          "actions": {
   //             "actionInsert": true,
   //             "actionUpdate": true,
   //             "actionDelete": true,
   //             "actionSearch": true
   //          }
   //       }
   //    ]
   // },
]
