import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { Products } from '../models/Product';
import { ProductsDTO } from '../http/dto/ProductDTO';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';

export const productRepository = {
   getProduct: async (perPage, page): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<ProductsDTO>(`${API_URL_BASE}/v1/product?per_page=${perPage}&page=${page}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = product
      return {
         data,
         // (data||[]).map((dt) => ({
         //    nombre: dt.nombre,
         //    codigo: dt.codigo,
         //    precio_oferta: dt.precio_oferta,
         //    sub_familia:dt.sub_familia,
         //    familia:dt.familia,
         //    stock:dt.stock
         // })),
         error,
         message
      };
   },
   getProductService: async (perPage, page): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;

      let rutaPage = `${API_URL_BASE}/v1/product/service?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`;

      if(perPage && page) {
          rutaPage = rutaPage + '&per_page=${perPage}&page=${page}'
      }
      const product = await http.get<ProductsDTO>(rutaPage )

      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },
   getDataProduct: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<ProductsDTO>(`${API_URL_BASE}/v1/product?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },
   getProductChildrenForId: async (id): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<any>(`${API_URL_BASE}/v1/relacionProductos/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },
   getProductChildrenForIDS: async (products, tipo): Promise<any> => {
      const product = await http.post<any>(`${API_URL_BASE}/v1/relacionProductos/tipo`,{
         products: products,
         tipo: tipo,
   })
      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },
   getProductChildren: async (perPage, page): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<ProductsDTO>(`${API_URL_BASE}/v1/product/children/1?per_page=${perPage}&page=${page}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },
   getSearchProduct: async (search: string): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<ProductsDTO>(`${API_URL_BASE}/v1/product?search=${search}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = product
      return {
         data,
         // (data||[]).map((dt) => ({
         //    nombre: dt.nombre,
         //    codigo: dt.codigo,
         //    precio_oferta: dt.precio_oferta,
         //    sub_familia:dt.sub_familia,
         //    familia:dt.familia,
         //    stock:dt.stock
         // })),
         error,
         message
      };
   },
   getSearchService: async (search: string): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<ProductsDTO>(`${API_URL_BASE}/v1/product/service?search=${search}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )

      const {data,error,message} = product
      return {
         data,
         // (data||[]).map((dt) => ({
         //    nombre: dt.nombre,
         //    codigo: dt.codigo,
         //    precio_oferta: dt.precio_oferta,
         //    sub_familia:dt.sub_familia,
         //    familia:dt.familia,
         //    stock:dt.stock
         // })),
         error,
         message
      };
   },
   getProductType: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const product = await http.post<ProductsDTO>(`${API_URL_BASE}/v1/product/tipoProducto?rut_empresa=${rut_empresa}` )
      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },

   getProductRelationsInterval: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.get<ProductsDTO>(`${API_URL_BASE}/v1/relacionProductos/list/intervalo?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },

   postGenerateRelationsProducts: async (id,productosHijos): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const product = await http.post<ProductsDTO>(`${API_URL_BASE}/v1/relacionProductos/${id}`,{
         productosHijos : productosHijos,
         id_empresa:id_empresa
      })
      const {data,error,message} = product
      return {
         data,
         error,
         message
      };
   },

   deleteRelationsProduct: async (id: number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/relacionProductos/${id}`)
      return resp;
   },

   searchProduct: async (term): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/product/searchProduct?term=${term}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp;
   },

   createBitacora: async (dataBitacora): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/bitacora`, {
         idcliente: dataBitacora.idcliente,
         idusuario: dataBitacora.idusuario,
         id_empresa: id_empresa,
         observaciones: dataBitacora.observaciones,
         etiquetas: dataBitacora.etiquetas,
      })
      return resp;
   },

   getProductCampaign: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/product/getProductCampaign?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp;
   },//ssj

   getProductByCampaign:async (id): Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/product/getProductByCampaign?idcampana=${id}`)
      return resp;
   },

   enviarCotizacion,
   getBodega,
   getVendedor,
   getPago,
   getAreaNegocio
}

function enviarCotizacion(token, cotizacion){
   return fetch('https://api2.softnet.cl/cotizacion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
      body: JSON.stringify(cotizacion),
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}

function getBodega(token){
   return fetch('https://api2.softnet.cl/bodega', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}

function getVendedor(token){
   return fetch('https://api2.softnet.cl/vendedores', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}

function getPago(token){
   return fetch('https://api2.softnet.cl/formaPago', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}

function getAreaNegocio(token){
   return fetch('https://api2.softnet.cl/areaNegocio', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}
