import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Product.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, InputLabel, TextField, Typography, Button, linearProgressClasses, IconButton, Backdrop, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { productService } from '@/service/services/Product.service';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ROUTE_PRODUCT } from '@/toolbox/constants/route-map';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SpinnerGrow } from '@/components/common/Spinner';

const header = [
   { name: 'codigo', label: 'Código', width: '' },
   { name: 'nombre', label: 'Nombre', width: '', evento: true },
   { name: 'precio', label: 'Precio', width: '', money: true }
];
const dataAction = [
   { name: 'select', color: 'success', route: null },
]

export const CreateProductView: React.FC<Props> = (props: Props): JSX.Element => {

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [dataProductChildren, setDataProductChildren] = useState<any>();
   const [productChildrenSelected, setProductChildrenSelected] = useState<any>([]);
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [busqueda, setBusqueda] = useState<any>("");
   const [open, setOpen] = useState<boolean>(false)
   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0);
   const [renderTabla, setRenderTabla] = useState<boolean>(false);

   const [tiporelacion, setTiporelacion] = useState<any>({});
   const [idIntervalo, setIdIntervalo] = useState<any>({});
   const [utilidad, setUtilidad] = useState<any>({});
   const [limite, setLimite] = useState<any>({})
   const [dataIntervalo, setDataIntervalo] = useState<any>([])
   const dataTipoRelacion = [
      { id: '1', nombre: 'Complementario' },
      { id: '2', nombre: 'Mantencion' },
      { id: '3', nombre: 'Arriendo diario'},
      { id: '4', nombre: 'Arriendo mensual'},
   ]
   // const dataIntervalo  = [
   //    { id:'1', descripcion: '3 meses'},
   //    { id:'2', descripcion: '6 meses'},
   //    { id:'3', descripcion: '9 meses'},
   //    { id:'4', descripcion: '12 meses'},
   //    { id:'5', descripcion: '18 meses'},
   //    { id:'6', descripcion: '24 meses'},
   // ]
   useEffect(() => {
      if (props.product !== undefined) {
           setOpen(true)
            getProductChildrens();
            getProductChildrenForID();
            getProductRelationsInterval()
      } else {
      }
   }, [pageData, perPageData])

   async function getProductRelationsInterval(){
      const resp = await productService.getProductRelationsInterval()
      setDataIntervalo(resp.data)
   }

   async function getProductChildrenForID(){
      const resp = await productService.getProductChildrenForId(props.product.id)
      setProductChildrenSelected(resp.data)
   }
   async function getProductChildrens() {
      const resp = await productService.getProductChildren(perPageData, pageData);
      setDataProductChildren(resp.data.products.data)
      setTotal(resp.data.products.total)
      setOpen(false)
   }

   const PerPage = async (data) => {
      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) => {
      setPageData(data + 1)
   }

   const getSearchProduct = async (search) => {
      try {
         const resp = await productService.searchProduct(search);
         setDataProductChildren(resp.data);
      } catch (e) {
         console.log(e)
      }
   };

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if (value.length > 3) {
                  getSearchProduct(value)
               }
               if (value.length == 0) {
                  getProductChildrens();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      } catch (e) {
         console.log(e)
      }
   };

   const SeleccionarProduct = (data) => {
      let productosSelecionados = productChildrenSelected;

      const productExist = productosSelecionados.filter(product => product.codigo == data.codigo)
      if (productExist.length > 0) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: 'El producto ya ha sido agregado',
         }));
      } else {
         const producto = {
            ...data, tipoRelacion: '', idintervalo:'' ,limite: '',
         }
         productosSelecionados.push(producto)
         setProductChildrenSelected(productosSelecionados);
         setRenderTabla(!renderTabla)
      }
   }

   const EliminarRelacion = async(row,i) => {
      if(row.idrelacion){
      //consumo endpoint
         let productosSelecionados = productChildrenSelected;
         productosSelecionados.splice(i,1)
         tiporelacion[row.codigo] = ''
         utilidad[row.codigo] = ''
         limite[row.codigo] = ''
         idIntervalo[row.codigo] = ''
         setProductChildrenSelected(productosSelecionados)
         setRenderTabla(!renderTabla)
         await productService.deleteRelationsProduct(row.idrelacion);
      }else{
      //elimino del array
      tiporelacion[row.codigo] = ''
      utilidad[row.codigo] = ''
      limite[row.codigo] = ''
      idIntervalo[row.codigo] = ''
      let productosSelecionados = productChildrenSelected;
      productosSelecionados.splice(i,1)
      setProductChildrenSelected(productosSelecionados)
      setRenderTabla(!renderTabla)
      }
   }

   const handleChangeSelect = (e) => {
      const changedFormValuesUser = {
         ...tiporelacion,
         [e.target.name]: e.target.value
      }
      setTiporelacion(changedFormValuesUser)

      productChildrenSelected.map((value, i) => {
         if (value.codigo == e.target.name) {
            if(e.target.value == '1'){
               limite[value.codigo] = ''
               idIntervalo[value.codigo] = ''
            }
            const producto = {
               ...value, tipoRelacion: e.target.value,
                limite:e.target.value == '1'?'': value.limite,
                idintervalo:e.target.value == '1'?'': value.idintervalo
            }
            return productChildrenSelected.splice(i, 1, producto)
         }
      })

   }
   const handleChangeSelectIntervalo = (e) => {
      const changedFormValuesUser = {
         ...idIntervalo,
         [e.target.name]: e.target.value
      }
      setIdIntervalo(changedFormValuesUser)

      productChildrenSelected.map((value, i) => {
         if (value.codigo == e.target.name) {
            const producto = {
               ...value, idintervalo: e.target.value
            }
            return productChildrenSelected.splice(i, 1, producto)
         }
      })
   }

   const handleInputUtilidad = (e) => {
      const changedFormValuesUser = {
         ...utilidad,
         [e.target.name]: e.target.value
      }
      setUtilidad(changedFormValuesUser)

      productChildrenSelected.map((value, i) => {
         if (value.codigo == e.target.name) {
            const producto = {
               ...value, utilidad: e.target.value
            }
            return productChildrenSelected.splice(i, 1, producto)
         }
      })
   }

   const handleInputLimite = (e) => {
      const changedFormValuesUser = {
         ...limite,
         [e.target.name]: e.target.value
      }
      setLimite(changedFormValuesUser)

      productChildrenSelected.map((value, i) => {
         if (value.codigo == e.target.name) {
            const producto = {
               ...value, limite: e.target.value
            }
            return productChildrenSelected.splice(i, 1, producto)
         }
      })
   }

   const generateRelations = async() => {
      setOpen(true)
      if(productChildrenSelected.length > 0){
         const proSinRelacion = productChildrenSelected.find(value => value.tipoRelacion == '');
         const proMantencionsinIntervalo = productChildrenSelected.find(value => value.tipoRelacion == '2' && value.idintervalo == '');
         const proVecesMantencion = productChildrenSelected.find( value => value.limite < 0)

         if(proSinRelacion || proMantencionsinIntervalo || proVecesMantencion){
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'warning',
               message: proMantencionsinIntervalo?'Al seleecionar tipo Mantencion, necesitamos nos indique un intervalo de frecuencia':proVecesMantencion?'El número de veces a repetir no puede ser negativo':'Para poder guardar,todos los productos necesitan la existencia de una relación',
            }));
            setOpen(false)
            return false
         }
         const productosHijos = JSON.stringify(productChildrenSelected);
         console.log(productChildrenSelected)
         const IDproductoPadre = props.product.id
         const resp = await productService.postGenerateRelationsProducts(IDproductoPadre,productosHijos);
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Los productos han sido relacionados con éxito',
         }));
         getProductChildrenForID();
         setOpen(false)
      }else{
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: 'Para poder guardar, necesitas agregar productos',
         }));
         setOpen(false)
      }
   }
   const tablaSelected =
      <>
         <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
               <TableHead sx={{ bgcolor: '#3f9bcc' }}>
                  <TableRow >
                 
                     <TableCell  sx={{color:'white', position:'sticky', left:0, background: '#3f9bcc'}} align="left" ></TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} > Código</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' , minWidth:'100px'}} align="left" >Nombre</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" >Tipo</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" >Intervalo</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" >Veces</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em' }} align="left" >Uso</TableCell>
                     <TableCell sx={{ color: 'white', fontSize: '1.1em', minWidth:'100px' }} align="left" >Precio</TableCell>
                     
                  </TableRow>
               </TableHead>
               <TableBody>
                  {(productChildrenSelected || []).map((row,i) => (
                     <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                     >
                        <TableCell align="left" sx={{position:'sticky', left:0, background: '#fff' }}>
                           <IconButton onClick={()=>{
                              EliminarRelacion(row,i)
                           }}>
                              <HighlightOffIcon />
                           </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                           {row.codigo}
                        </TableCell>
                        <TableCell align="left">{row.nombre}</TableCell>
                        <TableCell align="left">
                           <Select
                              labelId=""
                              id="tiporelacion"
                              sx={{ bgcolor: '#fff', minWidth:'130px' }}
                              size="small"
                              fullWidth
                              placeholder='Seleccione'
                              name={row.codigo}
                              value={row.tipoRelacion? row.tipoRelacion:tiporelacion[row.codigo]}
                              onChange={handleChangeSelect}
                           >
                              {dataTipoRelacion.map((option, i) => {
                                 return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                              })
                              }
                           </Select>
                        </TableCell>
                        <TableCell align={tiporelacion[row.codigo] == '2' || row.tipoRelacion == '2'?"left":"center"}>
                        {tiporelacion[row.codigo] == '2' || row.tipoRelacion == '2'?
                           <Select
                              labelId=""
                              id="intervalo"
                              sx={{ bgcolor: '#fff', minWidth:'100px'}}
                              size="small"
                              fullWidth
                              name={row.codigo}
                              value={row.idintervalo? row.idintervalo:idIntervalo[row.codigo]}
                              onChange={handleChangeSelectIntervalo}
                           >
                              {(dataIntervalo || []).map((option, i) => {
                                 return <MenuItem key={i} value={option.id}>{option.descripcion}</MenuItem>
                              })
                              }
                           </Select>:<> -- </>}
                        </TableCell>
                        <TableCell align={tiporelacion[row.codigo] == '2' || row.tipoRelacion == '2'?"left":"center"}>
                        {tiporelacion[row.codigo] == '2' || row.tipoRelacion == '2'?
                           <TextField
                           required
                           fullWidth
                           size='small'
                           type={'number'}
                           // min={'1'}
                           sx={{ bgcolor: '#fff' }}
                           name={row.codigo}
                           value={row.limite? row.limite:limite[row.limite]}
                           onChange={handleInputLimite}

                           />:<> --</>}
                        </TableCell>
                        <TableCell align="left">
                           <TextField
                              margin="normal"
                              required
                              fullWidth
                              name={row.codigo}
                              maxRows={4}
                              multiline={true}
                              value={row.utilidad?row.utilidad:utilidad[row.codigo]}
                              sx={{ bgcolor: '#E3F4FD',minWidth:'150px' }}
                              onChange={handleInputUtilidad}
                              placeholder="Introduce una breve descripción"
                              type="text"
                              id="utilidad"
                              size="small"
                           />
                        </TableCell>
                        <TableCell align="left">{moneyFormat(row.precio)}</TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </>

   return (
      <Protected>
         <Grid container sx={{ mt: 5, mb: 2 }}>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
               open={open}
            >
               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow />
                  <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
               </Grid>
            </Backdrop>
            <Grid container justifyContent='space-between' spacing={2}>
               <Grid item md={8} container flexDirection='row'>
                  <IconButton component={Link} to={ROUTE_PRODUCT} sx={{ mr: 2 }}>
                     <ArrowBackIcon fill="black" />
                  </IconButton>
                  <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '2.5em' }} >
                     {props.product.nombre}
                  </Typography>
               </Grid>
               <Grid item md={4} container justifyContent='right' alignItems='end'>
                  <Button variant='contained'
                     sx={{
                        background: '#E4980D', color: '#fff', '&:hover': {
                           bgcolor: '#E4980D'
                        }
                     }}
                     onClick={() => {
                        generateRelations()
                     }}>Guardar Cambios</Button>
               </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 4 }} />
            <Grid container spacing={2}>
               <Grid item xs={5}>
                  <Grid container spacing={1} >
                     <Grid item xs={12}>
                        {/* <InputLabel >Filtrar Resultados</InputLabel> */}
                        <TextField
                           margin="normal"
                           required
                           fullWidth
                           name="search"
                           value={search}
                           onChange={handleInput}
                           placeholder="Introduce un Nombre o Código Producto"
                           type="search"
                           id="password"
                           autoComplete="current-password"
                           size="small"
                        />
                     </Grid>
                  </Grid>
                  <TableData
                     header={header}
                     data={dataProductChildren ? dataProductChildren : []}
                     action={null}
                     total={Total ? Total : 0}
                     perPage={PerPage}
                     page={page}
                     RecuperarData={SeleccionarProduct}
                  />
               </Grid>

               <Grid item xs={7}>
                  <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em', mb: 2, mt: 2 }} >
                     Relaciones con otros productos
                  </Typography>
                  {renderTabla ? tablaSelected : tablaSelected}
               </Grid>
            </Grid>
         </Grid>
      </Protected>
   )
};
