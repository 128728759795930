import { http } from '../http/http';
import { API_URL_BASE,API_URL_BASE_FRONT,API_URL_BYPASS, API_URL_SOFTNET } from '@toolbox/defaults/app';
import { CampaingDTO } from '../http/dto/CampaingDTO';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BYPASS, KEY_SOFTNET, KEY_TOKEN_SOFTNET,KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROUTE_LEADS_FORM_CAMPAING } from '@/toolbox/constants/route-map';

export const byPassRepository = {

   getChannel,
   LoginByPass,
   postCrearCampaña,
   postCrearForm,
   postCrearEmpresa,
   postListaByCampaign,
   getInputs,
   getUrlsLeads,

   //APIS DE DOCUMENTS
   getTypeDocumentForClient: async (id): Promise<any> => {
    const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      console.log(id)
    const resp = await http.get<any>(`${API_URL_BASE}/v1/documento/typeDocumento/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
    console.log(resp)
    return resp
 },
  getClientDocuments: async (): Promise<any> => {
    const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
    const resp = await http.get<any>(`${API_URL_BASE}/v1/documento?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
    return resp
  },
  getClientDocumentsURL: async (id): Promise<any> => {
    const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
    const resp = await http.get<any>(`${API_URL_BASE}/v1/documento/mostrarURLs/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
    return resp
  },
 saveDocumento: async (data) : Promise<any> => {
  const resp= await http.post<any>(`${API_URL_BASE}/v1/documento`,data)
  return {
     status: resp.status,
     message: resp.message,
     data: resp.data
  };
  },
  saveDocumentoPDFeditable: async (data) : Promise<any> => {
    const resp= await http.post<any>(`${API_URL_BASE}/v1/documento/situacion-estado`,data)
    return {
       status: resp.status,
       message: resp.message,
       data: resp.data
    };
  },

  getSearchClientDocuments: async (search): Promise<any> => {
    const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
    const resp = await http.get<any>(`${API_URL_BASE}/v1/documento/searchClient/${search}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
    return resp
  },

  SendTemplateLeads: async (data) : Promise<any> => {
   const resp= await http.post<any>(`${API_URL_BASE}/v1/ws-template/sendtemplate`,data)
   return {
      status: resp.status,
      message: resp.message,
      data: resp
   };
 },

}



function getChannel(){
   const token = readLocalStorage(KEY_BYPASS);
   return fetch(`${API_URL_BYPASS}/api/channel`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }

 async function getInputs(){
   const token:any = await LoginByPass('aaa','aa','aa');
   const TokenBypass=token.token.original.access_token;
   return fetch(`${API_URL_BYPASS}/api/inputs`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${TokenBypass}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }

 function getUrlsLeads(idParameter){
   const token = readLocalStorage(KEY_BYPASS);

   return fetch(`${API_URL_BYPASS}/api/campanas?idCampaign=${idParameter}`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }


 function postCrearCampaña(name,idinputs,idchannel,idEmpresa, nameUri, DOMAIN_URL){
   const token = readLocalStorage(KEY_BYPASS);
   const url = `${DOMAIN_URL}${nameUri}`
   return fetch(`${API_URL_BYPASS}/api/parametros?name=${name}&idinputs=${idinputs}&idchannel=${idchannel}&url=${url}?&idEmpresa=${idEmpresa}`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }


 function postCrearForm(idParameter,channel,urlParameters){
   const token = readLocalStorage(KEY_BYPASS);

   return fetch(`${API_URL_BYPASS}/api/formularios?idParameter=${idParameter}&channel=${channel}${urlParameters}`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }

 function postListaByCampaign(idCampaign){
   const token = readLocalStorage(KEY_BYPASS);

   return fetch(`${API_URL_BYPASS}/api/formularios/${idCampaign}`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }

 function postCrearEmpresa(nameID){
   const token = readLocalStorage(KEY_BYPASS);

   return fetch(`${API_URL_BYPASS}/api/insertEmpresa?name=${nameID}&url=${API_URL_BYPASS}/`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
     },
   })
     .then(inputs => inputs.json())
     .then(inputs => {
       return inputs;
     })
     .catch(error => {
       return false;
     })
 }

 function LoginByPass(rut, user_nick, password){

   return fetch(`${API_URL_BYPASS}/api/login?rut=81263asd-k&user_nick=usuario&password=demo`, {
     method: 'POST',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
     },
   })
     .then(resp => resp.json())
     .then(resp => {
       return resp;
     })
     .catch(error => {
       return false;
     })
 }




