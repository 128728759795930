import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog, Container, InputLabel } from '@mui/material';
import { Paper, Box, Chip, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { TableData } from '../Table';
import { campaingService } from '@/service/services/Campaing.service';
import { entityService } from '@/service/services/Entity.service';
import { productService } from '@/service/services/Product.service';
import { ROLE_ADMIN, ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { styled } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_DETAIL_CLIENTS_TASK, ROUTE_DETAILCAMPAIGNLEADSCLIENT } from '@/toolbox/constants/route-map';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';

const header = [
    { name: 'nombre', label: 'Rut' },
    { name: 'fecha_creacion', label: 'Fecha de creación' },
    { name: 'fecha_cierre', label: 'Fecha de Cierre' },
    { name: 'tipo_campana', label: 'Tipo' }
];
const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

type ModalProps = {
    openModal: boolean,
    setOpenModal: any,
    action?: any,
    dataClient?: any,
    formValue?: any,
    channel?: any
}

export const ModalSearchProduct: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {

    const [open, setOpen] = useState(false)
    const [data, setData] = useState<any>([]);
    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const [selectClients, setSelectClients] = useState<any>({});
    const [selectProducts, setSelectProducts] = useState<any>({});
    const [vendedor, setVendedor] = React.useState([]);
    const [product, setProduct] = useState([])
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
    const [error, setError] = useState<string>("");
    const history = useHistory();
    const dataAction = [
        { name: 'select', color: 'primary' },
    ]

    useEffect(() => {
        getVendedor()
        getProduct()
    }, [])

    async function getVendedor() {
        const resp = await entityService.getVendedor()
        if (resp.data) {
            setVendedor(resp.data)
        }
    }

    async function getProduct() {
        const resp = await productService.getProductCampaign()
        if (resp) {
            setProduct(resp.data)
        }
        console.log(resp.data)
    }

    async function getCampana(product) {
        if (product) {
            const resp = await campaingService.searchCodProduct(product['codigo'])
            if (resp) {
                setData(resp.data)
            }
        }
    }

    const RecuperarData = async (data) => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
        const id = data.id;
        if (data.action == 'select') {
            if (JSON.stringify(selectClients)=='{}') { return setError("vendedor"); }
            else {
                let usuario
                if(selectClients != ''){
                    usuario = selectClients.idusuario
                } else {
                    usuario = ''
                }

                const dataCampaing = {
                    idcampana: id,
                    tipo: data.tipo,
                    idcliente: props.dataClient,
                    idusuario: usuario,
                    idproducto: selectProducts.id,
                  //   RUT: props.formValue.rut,
                  //   Telefono: props.formValue.telefono,
                  //   Correo: props.formValue.correo,
                  //   Nombre: props.formValue.nombre,
                    channel: props.channel,
                    id_empresa:id_empresa
                }
                console.log(dataCampaing)
                const resp = await campaingService.createDetCampaignProduct(dataCampaing)
                if(resp){
                    if(resp.data?.status){
                        props.setOpenModal(false)
                        if(data.tipo == 0){
                            history.push(ROUTE_DETAIL_CLIENTS_TASK, resp.data.detalle[0])
                        } else if(data.tipo == 1){
                            history.push(ROUTE_DETAILCAMPAIGNLEADSCLIENT, JSON.stringify(resp.data.cliente))
                        }
                    } else {
                        setSnackBarConfig(prev => ({
                            ...prev,
                            open: true,
                            severity: 'error',
                            message: resp.data.message,
                         }));
                    }
                }
            }
        }
    };

    const bodyModal = (
        <>
            <Grid item xs={12} mt={2}>
                <Snackbar
                    open={snackBarConfig.open}
                    autoHideDuration={snackBarConfig.autoHideDuration}
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        severity={snackBarConfig.severity}
                        variant="filled"
                    >
                        {snackBarConfig.message}
                    </Alert>
                </Snackbar>
                <Container maxWidth='xl' sx={{ mt: 1, mb: 2, maxHeight: 600, overflow: 'auto' }}>
                    <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC',mt:1, mb:3, fontWeight:'700', fontSize:'1.6em'}}>BUSCAR PRODUCTO</Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Grid container spacing={1} >
                        <Grid item xs={6} container alignContent={'end'} sx={{ mb: 1 }}>
                            <Autocomplete
                                // multiple
                                id="tags-outlined"
                                options={product}
                                getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                                size={'small'}
                                fullWidth
                                sx={{ bgcolor: '#fff' }}
                                value={selectProducts}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelectProducts(newValue)
                                        getCampana(newValue)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Productos"
                                        // error={error === "procomprados" ? true : false}
                                        placeholder="Introduce un nombre del producto"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        {dataUser.user.role !== ROLE_SUPERVISOR || dataUser.user.role !== ROLE_ADMIN
                        ?   <Grid item xs={6} container alignContent={'end'} sx={{ mb: 1 }}>
                                <Autocomplete
                                    // multiple
                                    id="tags-outlined"
                                    options={vendedor}
                                    getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                                    size={'small'}
                                    fullWidth
                                    sx={{ bgcolor: '#fff' }}
                                    value={selectClients}
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setSelectClients(newValue)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Asignar Vendedor"
                                            error={error === "vendedor" ? true : false}
                                            placeholder="Elige un vendedor"
                                            fullWidth
                                        />
                                    )}
                                />
                                {error=='vendedor'?<span style={{color:'#d32f2f',fontSize:'0.75rem'}}>Vendedor es un campo requerido</span>:<></>}
                            </Grid>
                        : setSelectClients('')
                    }
                    </Grid>
                    <Grid container >
                        <TableData
                            header={header}
                            data={data}
                            action={dataAction}
                            RecuperarData={RecuperarData}
                        />
                    </Grid>
                </Container>
                <Grid container direction='row' sx={{ mt: 0, mb: 1}} justifyContent='flex-end' alignContent='center' >
                    <Grid item >
                        <Button
                            sx={{
                                background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                                    bgcolor: '#bf6c00'
                                }
                            }}
                            color='error'
                            onClick={() => {
                                props.setOpenModal(false)
                            }}
                            endIcon={<CancelIcon />}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
    return (
        <div>
            <Modal
                open={props.openModal}
                onClose={() => { props.setOpenModal(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='Modal' sx={{width:'800px'}}>
                    <Box>
                    {bodyModal}
                    </Box>
                </Box>

            </Modal>
        </div>
    );
}
