import { MenuLateral } from '@/components/common/LateralMenu/LateralMenu';
import { Protected } from '@/components/layout/Protected';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Avatar, Backdrop, Button, Checkbox, CircularProgress, Container, Grid, IconButton, InputAdornment, ListItemAvatar, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Props } from './Perfil.type';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Link } from 'react-router-dom';
import { ModalCambiarContraseña } from "@/components/common/Modal/ModalCambiarContraseña";
import { SpinnerGrow } from '@/components/common/Spinner';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';

export const PerfilView : React.FC<Props> = ({

}) : JSX.Element => {
   const [open, setOpen] = useState<boolean>(false);
   const [openModal, setOpenModal] = useState<boolean>(false);
   const data = readLocalStorage(KEY_USER_DATA)

   return(
   <Protected>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
             <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
      <Container maxWidth='xl' sx={{mt:1}}>
      <Grid container flexDirection='row' spacing={2}>
         {data.user.role === ROLE_ADMIN &&
         <Grid item md={3} container>
            <MenuLateral/>
         </Grid>
         }
         <Grid item md={data.user.role === ROLE_ADMIN?9: 12}
               sx={{width: data.user.role === ROLE_SUPER_ADMIN ||  data.user.role === ROLE_VENDEDOR?'100%':'80%' ,bgcolor: 'background.paper',height:{xs:'67vh',md:'77vh'},overflowX:'hidden', overflowY:'scroll',borderRadius:'0.5rem', border:'0.5px solid #eff3f5',borderLeft: '5px solid #3F9BCC ', mt:3,ml:{xs:1, md:0}}}
         >
               <Grid container  spacing={3} sx={{mt:1, ml:5}}  >
                  <Grid item xs={7} >
                        <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Perfil</Typography>
                  </Grid>
                  <Grid item xs={3} >
                        <Button
                        sx={{background:'#bf6c00',mt:2, color:'#fff', '&:hover':{
                           bgcolor:'#bf6c00'
                          }}}
                        onClick={()=>{setOpenModal(true)}} variant='contained' >Cambiar contraseña</Button>
                  </Grid>
               </Grid>
               <Grid container spacing={4} sx={{mt:1, ml:5}}>
                  <Grid item lg={5} >
                     <Typography>Nombre</Typography>
                     <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={ data.user.nombre}
                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                     />
                  </Grid>
                  <Grid item lg={5} >
                     <Typography>NIK Usuario</Typography>
                     <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={data.user.nick_usuario}
                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BadgeIcon />
                          </InputAdornment>
                        ),
                      }}
                        />
                  </Grid>
                  <Grid item lg={5} >
                     <Typography>Email</Typography>
                     <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={data.user.email}
                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                        />
                  </Grid>
                  <Grid item lg={5} >
                     <Typography>Celular</Typography>
                     <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={data.user.telefono}
                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneAndroidIcon />
                          </InputAdornment>
                        ),
                      }}
                        />
                  </Grid>
                  <Grid item lg={5} >
                     <Typography>RUT</Typography>
                     <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={data.user.rut_empresa}
                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BadgeIcon />
                          </InputAdornment>
                        ),
                      }}
                        />
                  </Grid>
                  <Grid item lg={5} >
                     <Typography>Rol</Typography>
                     <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={data.user.role}
                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AssignmentIndIcon />
                          </InputAdornment>
                        ),
                      }}
                      />
                  </Grid>
               </Grid>
         </Grid>
      </Grid>
      </Container>
      <ModalCambiarContraseña
      open={openModal}
      setOpen={setOpenModal}/>
   </Protected>
)
   }
