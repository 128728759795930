import React, {useEffect, useState} from 'react';
import { Button, Grid, TextField, Backdrop, CircularProgress, IconButton,InputAdornment, Snackbar, Box, AppBar, Toolbar, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import logoLogin from "@assets/img/logo-login.jpg";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authenticationService } from '@/service/services/Authentication.service';
import { useHistory } from 'react-router-dom'
import { ROUTE_DOCUMENTOS, ROUTE_REGISTRAR_USER_DOC } from '@/toolbox/constants/route-map';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_DOCUMENTS } from '@/toolbox/constants/local-storage';
import { Icon } from '@components/common/Icon';

const theme = createTheme();

export const LoginDocumentsView: React.FC<any> = ({ }): JSX.Element => {

    const history = useHistory();
    const [error, setError] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
    })

    const [dataLogin, setDataLogin] = useState<any>([]);
    const {rut_cliente, password} = dataLogin;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    useEffect(()=>{
      const dataUserDoc = readLocalStorage(KEY_DOCUMENTS)
      console.log(dataUserDoc)
      if(dataUserDoc){
         history.push(ROUTE_DOCUMENTOS)
      }

    },[])

    const handleInputChange = (e) => {

      const name = e.target.name;
      const value = e.target.value;

      switch (name) {
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               setDataLogin(prev => ({ ...prev, password: value, textError: '' }));
            }
            break;
         case 'rut_cliente':
            //setVerify(false)
            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut: function (rutCompleto) {
                  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                     return false;
                  var tmp = rutCompleto.split('-');
                  var digv = tmp[1];
                  var rut = tmp[0];
                  if (digv == 'K') digv = 'k';
                  return (Fn.dv(rut) == digv);
               },
               dv: function (T) {
                  var M = 0, S = 1;
                  for (; T; T = Math.floor(T / 10))
                     S = (S + T % 10 * (9 - M++ % 6)) % 11;
                  return S ? S - 1 : 'k';
               }
            }

            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setDataLogin(prev => ({ ...prev, rut_cliente: foo, textError: '' }))
            } else if (foo.length == 0) {
               setDataLogin(prev => ({ ...prev, rut_cliente: "", textError: '' }))
            }

            break;
         default:
            break;
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const {rut_cliente, password} = dataLogin;

      if(!rut_cliente){
         setLoading(false);
        return setError('rut');
      }

      if(!password){
         setLoading(false);
         return setError('password');
      }

      const resp = await authenticationService.loginDocuments(rut_cliente, password)
      
      if(resp?.data?.message){
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: resp?.data?.message,
         }));
      }else{
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Usuario Logeado con éxito',
         }));
         saveLocalStorage(KEY_DOCUMENTS,resp?.data)
         history.push(ROUTE_DOCUMENTOS)
      }

      setLoading(false)
   }

   return (
      <ThemeProvider theme={theme}>
         <Grid container component="main" sx={{ height: '100vh' }}>
               <CssBaseline />
               <Grid
                  item
                  xs={false}
                  sm={6}
                  md={7}
                  sx={{
                     backgroundImage: `url(${logoLogin})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                  }}
               />
               <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square >
                  <Box
                     sx={{
                        my: 25,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                  }}>

                  <Grid sx={{ border: '1px solid #155172', borderTop: '8px solid #155172', borderRadius: '7px', width: '80%' }}>
                     <form className="LoginForm" onSubmit={handleSubmit}>
                        <Grid container spacing={2} className={`e-mb-lg`}>
                           <Grid item xs={12}>
                              <Typography textAlign={'center'} sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em' }} >Sesión de Usuario</Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <TextField
                                 fullWidth
                                 error={error === "rut" ? true : false}
                                 helperText={error === "rut" ? " El rut es un campo requerido" : ""}
                                 size="small"
                                 id="rut_cliente"
                                 placeholder="Ingrese su rut cliente"
                                 sx={{ bgcolor: '#fff' }}
                                 name="rut_cliente"
                                 type="text"
                                 value={rut_cliente}
                                 onChange={handleInputChange}
                              />
                           </Grid>
                           <Grid item xs={12}>
                           <TextField
                                 fullWidth
                                 error={error === "password" ? true : false}
                                 helperText={error === "password" ? "La contraseña es un campo requerido" : ""}
                                 size="small"
                                 id="password"
                                 placeholder="Ingrese su contraseña"
                                 sx={{ bgcolor: '#fff' }}
                                 name="password"
                                 type={showPassword?"text":"password"}
                                 InputProps={{
                                    endAdornment: (
                                       <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={()=>{setShowPassword(!showPassword)}}
                                       >
                                          {showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                       </IconButton>
                                    ),
                                 }}
                                 value={password}
                                 onChange={handleInputChange}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                       fullWidth={true}
                                       type='submit'
                                       variant='contained'
                                       onClick={handleSubmit}
                                       disabled={loading}
                                       sx={{
                                          background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
                                             bgcolor: '#E4980D'
                                          }
                                       }}
                                       disableElevation
                                    >
                                       <span>Iniciar sesión</span>
                                    </Button>
                                    {loading && (
                                       <CircularProgress
                                          size={24}
                                          sx={{
                                             position: 'absolute',
                                             top: '50%',
                                             left: '50%',
                                             marginTop: '-12px',
                                             marginLeft: '-12px',
                                          }}
                                       />
                                    )}
                                 </Box>
                              </Grid>
                              <Grid item xs={12}>
                              <Box sx={{ m: 1, mt:0, position: 'relative' }}>
                                    <Button
                                       fullWidth={true}
                                       variant='contained' 
                                       onClick={()=>{history.push(ROUTE_REGISTRAR_USER_DOC)}}
                                       disabled={loading}
                                       sx={{
                                          background: '#0E74FF', color: '#fff', fontWeight: '700', '&:hover': {
                                             bgcolor: '#0E74FF'
                                          }
                                       }}
                                       disableElevation
                                    >
                                       <span>Registrate</span>
                                    </Button>
                                 </Box>
                              </Grid>
                        </Grid>
                     </form>
                  </Grid>
                  <Snackbar
                        open={snackBarConfig.open}
                        autoHideDuration={snackBarConfig.autoHideDuration}
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     >
                        <Alert
                           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                           severity={snackBarConfig.severity}
                           variant="filled"
                        >
                           {snackBarConfig.message}
                        </Alert>
                     </Snackbar>
                  </Box>
               </Grid>
         </Grid>
      </ThemeProvider>
   )
}
