import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import { Protected } from '@components/layout/Protected';
import { Props } from './SItuacionEstado.type';
import { TableData } from '@/components/common/Table';
import { Button, Grid, TextField, Backdrop, CircularProgress,Avatar, MenuItem, Select, InputLabel, FormControl, FormHelperText, Snackbar, Box, AppBar, Toolbar, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import img_pdf from "@assets/img/ico_pdf.png";
import { API_URL_BASE } from '@/toolbox/defaults/app';
import img_archive from "@assets/img/ico_archive.png";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import { KEY_DOCUMENTS } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { ConfirmDialog } from '@/components/common/DialogConfirm';
import { bypassService } from '@/service/services/Bypass.service';

export const SituacionEstadoView: React.FC<Props> = ({ }): JSX.Element => {

   const dataDocumentos = readLocalStorage(KEY_DOCUMENTS) || [];
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
    })
    const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Guardar cambios',
      confirm: false,
      contactId: null,
      message: `Desea subir el PDF?`
   })
    const [activeFiles, setActiveFiles] = useState<any>({
      file: false,
      data: null
    })

    function processFile(event) {
      if(event.target.id== 'file'){
         setActiveFiles(prev => ({ ...prev, file: true, data: event.target.files[0] }))
      }
   }

   const ValidateSaveDocument = () => {
      
      if (activeFiles) {
         setDialog(prev => ({ ...prev, message: 'Seguro que desea Guardar?', open: true, confirm: true }));
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: "Agregue el documento PDF ",
         }));
      }
   }

   const SaveDocumentos = async() => {
      const rut = readLocalStorage(KEY_DOCUMENTS)
      const formData = new FormData();
      formData.append('documento', activeFiles.data || '');
      formData.append('id_login_documento', dataDocumentos?dataDocumentos.id:'')
      formData.append('rut', dataDocumentos.rut)//mandar del local storage
      const resp = await bypassService.saveDocumentoPDFeditable(formData);  
   }

   return (
      <Box sx={{p:6}}>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               onConfirm={() => SaveDocumentos()}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />
         <Grid container spacing={2}>
               <Grid item container flexDirection='row' spacing={2} justifyContent='space-between' mb={3}>
                   <Grid item xs={8}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>{`Situación - Estado`}</Typography>
                   </Grid>
                   <Grid item xs={4} alignContent='flex-start' justifyContent={'end'}>
                  <Button variant='contained' sx={{background:'#E4980D', color:'#fff', '&:hover':{
                        bgcolor:'#E4980D'
                       }}} onClick={() => { ValidateSaveDocument() }}>Guardar</Button>
                  </Grid>
               </Grid>
               <Grid item container flexDirection='row'>
                   <Avatar sx={{ bgcolor: '#007EA7', mr: 2, mb:2 }}>1</Avatar>
                   <Typography variant='h5'>Descarga el PDF Editable de Situacion - Estado</Typography>
                   <Grid item xs={12} container flexDirection='row' justifyContent={'center'} style={{ padding: "0px 5px", position: 'relative' }}>
                           <Button component="label" style={{ width: "20%", border: '3px solid #8AA8B8' }}>
                              <Grid item xs={12}>
                              <a href={`${API_URL_BASE}/public/documento/1652808264-Estado Situacion y Solic de Productos v1 EDITABLE.pdf`} target='_blank'>
                                 <Grid item container flexDirection='row' justifyContent={'center'}>
                                     <Box
                                       component="img"
                                       style={{
                                          height: 100,
                                          width: 100,
                                       }}
                                       src={img_pdf}
                                    />

                                 </Grid>
                                 <span style={{ fontSize: '11px', textTransform: 'none', textAlign: 'center', color: '#155172' }}>
                                    Descargar
                                 </span>
                                 </a>
                              </Grid>
                           </Button>
                        </Grid>
               </Grid>
               <Grid item container flexDirection='row'>
                   <Avatar sx={{ bgcolor: '#007EA7', mr: 2, mb:2 }}>2</Avatar>
                   <Typography variant='h5'>Sube el PDF Situacion - Estado</Typography>
                   <Grid item xs={12} container flexDirection='row' justifyContent={'center'} style={{ padding: "0px 5px", position: 'relative' }}>
                           <Button component="label" style={{ width: "20%", border: '3px solid #8AA8B8' }}>
                              <Grid item xs={12}>
                                 <Grid item container flexDirection='row' justifyContent={'center'}>
                                     <Box
                                       component="img"
                                       style={{
                                          height: 100,
                                          width: 100,
                                       }}
                                       src={activeFiles.file? img_pdf : img_archive}
                                    />
                                 </Grid>
                                 {!activeFiles.file && <span style={{ fontSize: '11px', textTransform: 'none', textAlign: 'center', color: '#155172' }}>
                                    Adjunte un archivo aquí
                                 </span>}
                            </Grid>
                              {!activeFiles.file && <input id='file' type="file" accept="application/pdf" onChange={processFile} style={{ display: 'none' }} />}
                           </Button>
                           {activeFiles.file &&
                              (<Tooltip title="Eliminar Documento" placement="right-start">
                                 <HighlightOffIcon color='warning' sx={{ position: 'absolute', top: '3%', right: '45%' }}
                                    onClick={() => {
                                       setActiveFiles(prev => ({ ...prev, file: false, data: null }))
                                    }} />
                              </Tooltip>)
                           }
                        </Grid>
               </Grid>
         </Grid>
      </Box>
   )
}
