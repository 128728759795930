import { http } from '../http/http';
import { LoginDTO, LogoutDTO, UserDTO } from '../http/dto/AuthenticationDTO';
import { Login, Logout, Authentication } from '../models/Authentication';
import { API_URL_BASE, API_URL_SOFTNET } from '@/toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const authenticationRepository = {
   login: async (user_nick: string, rut_empresa: string, password: string, from:string): Promise<Login>=> {
      const resp = await http.post<LoginDTO>(`${API_URL_BASE}/v1/auth/login`, {
         user_nick: user_nick,
         rut_empresa: rut_empresa,
         password: password,
         from: from
      })
      return {
         error: resp.error,
         status: resp.status,
         message: resp.message,
         data: !!resp.data ? {
               token: resp.data?.access_token || '',
               expires_in: resp.data?.expires_in || 0,
               token_type: resp.data?.token_type || '',
               message: resp.data?.message || '',
               token_softnet: resp.data?.token_softnet || '',
               rut_empresa: resp.data?.rut_empresa || '',
         } : undefined
      }
   },
   loginSoftnet: async (username: string, rut: string, password: string): Promise<any>=> {
      const resp = await http.post<any>(`https://api2.softnet.cl/login`, {
         username: username,
         rut: rut,
         password: password
      })
      return resp;
      // {
      //    error: resp.error,
      //    status: resp.status,
      //    message: resp.message,
      //    token: resp.token
      // }
   },
   validateContraseña: async (password): Promise<any>=>{
      const resp = await http.post<any>(`${API_URL_BASE}/v1/user/verify-password`,{
            password : password
      })
      return resp
   },
   changePassword: async (password): Promise<any>=>{
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/user?`,{
            password : password
      })
      return resp
   },
   logout: async (): Promise<Logout>=> {
      const resp = await http.post<LogoutDTO>(`${API_URL_BASE}/v1/auth/sign-out`)
      console.log(resp)
      return {
         error: resp?.error,
         status: resp?.status,
         message: resp?.message
      }
   },
   profile: async (access_token: string, rut_company): Promise<Authentication>=> {
      const resp = await http.get<UserDTO>(`${API_URL_BASE}/v1/auth/me/${rut_company}`)
      return {
         user: {
            idusuario: resp.data?.idusuario,
            idempresacliente: resp.data?.idempresacliente,
            email: resp.data?.email,
            nombre: resp.data?.nombre,
            password: resp.data?.password,
            idtipousuario: resp.data?.idtipousuario,
            telefono: resp.data?.telefono,
            rut_empresa: rut_company,
            role: resp.data?.role,
            main_redirect: '/',
            nick_usuario: resp.data?.user_nick
         },
         error: resp.error,
         token: access_token
      }
   },

   loginCotizacion: async (username: string, rut: string, password: string): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_SOFTNET}/login`, {
         username: username,
         rut: rut,
         password: password
      })
      return resp;
      // {
      //    error: resp.error,
      //    status: resp.status,
      //    message: resp.message,
      //    token: resp.token
      // }
   },
   deviceToken: async (token): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/device-token`, {
         token:token
      })
      return resp;
   },
   loginDocuments: async (rut_cliente, password): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/documento/login`, {
         rut: rut_cliente,
         password: password
      })
      return resp;
   },
   registerDocuments: async (dataUser): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/documento/createClient`,
         dataUser
      )
      return resp
   },
   searchRut: async (rut): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/documento/searchRut/${rut}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },
   searchRutEmpresa: async (rut): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/documento/searchRutEmpresa/${rut}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },
   dataUser,
   dataEmpresa,

}

function dataUser(token){
   return fetch(`${API_URL_SOFTNET}/datoUsuario`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
   })
     .then(datosUsuarios => datosUsuarios.json())
     .then(datosUsuarios => {

       localStorage.setItem('dataUser', JSON.stringify(datosUsuarios[0]))
       return datosUsuarios[0];
     })
     .catch(error => {
       return false;
     })
 }

 function dataEmpresa(token){
   return fetch(`${API_URL_SOFTNET}/datoEmpresa`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
   })
     .then(datosEmpresa => datosEmpresa.json())
     .then(datosEmpresa => {

       return datosEmpresa[0];
     })
     .catch(error => {
       return false;
     })
 }
