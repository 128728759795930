import { Box, Button, Drawer, FormControl, Grid, IconButton, TextField, Typography } from "@mui/material"
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailIcon from '@mui/icons-material/Mail';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export const DrawerDetailExpirationBar: React.FC<any> = (props): JSX.Element => {

    const { openDrawer, setOpenDrawer, itemSelected } = props;

    const dataPendientes = [
        { id: 1, cod_arriendo: '2849234829', fecha_arriendo: '20-11-2023', name_cliente: 'Aldair Gonzales', rut_cliente: '34534534-3', dia_inpago: 31, total_deuda: '300000' },
        { id: 2, cod_arriendo: '3948239482', fecha_arriendo: '12-11-2023', name_cliente: 'María Rodríguez', rut_cliente: '98765432-1', dia_inpago: 39, total_deuda: '450000' },
        { id: 3, cod_arriendo: '8574928347', fecha_arriendo: '03-11-2023', name_cliente: 'Juan Pérez', rut_cliente: '11223344-5', dia_inpago: 49, total_deuda: '200000' },
        { id: 4, cod_arriendo: '2384723948', fecha_arriendo: '04-11-2023', name_cliente: 'Karla Fernández', rut_cliente: '55667788-9', dia_inpago: 47, total_deuda: '600000' },
        { id: 5, cod_arriendo: '3847593847', fecha_arriendo: '15-11-2023', name_cliente: 'Pedro Sánchez', rut_cliente: '99887766-4', dia_inpago: 36, total_deuda: '750000' },
        { id: 6, cod_arriendo: '5938475938', fecha_arriendo: '06-11-2023', name_cliente: 'Laura García', rut_cliente: '11221122-0', dia_inpago: 45, total_deuda: '400000' },
        { id: 7, cod_arriendo: '1029384756', fecha_arriendo: '17-11-2023', name_cliente: 'Miguel Martínez', rut_cliente: '33445566-7', dia_inpago: 35, total_deuda: '550000' },
        { id: 8, cod_arriendo: '5678901234', fecha_arriendo: '18-11-2023', name_cliente: 'Ana López', rut_cliente: '99887766-1', dia_inpago: 34, total_deuda: '350000' },
        { id: 9, cod_arriendo: '4567890123', fecha_arriendo: '19-11-2023', name_cliente: 'Carlos Herrera', rut_cliente: '11223344-8', dia_inpago: 32, total_deuda: '500000' },
        { id: 10, cod_arriendo: '7890123456', fecha_arriendo: '03-11-2023', name_cliente: 'Sofía Torres', rut_cliente: '77889900-2', dia_inpago: 48, total_deuda: '650000' },
    ];

    const [widthDrawer, setWidthDrawer] = useState<string>('500px')

    const viewDetail = () => {
        setWidthDrawer('1000px')
    }

    return (
        <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Box sx={{
                width: widthDrawer,
                display: "flex",
                flexDirection: "row",
                padding: 0
            }} >
                <Box
                    sx={{
                        width: "450px",
                        padding: "20px",
                        paddingX: "40px",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "12px",
                        backgroundColor: "#fff",
                    }}
                >
                    <Typography variant="h4" sx={{ marginBottom: "20px", color: "#155172", fontWeight: 700 }}>
                        {itemSelected}
                    </Typography>

                    <Grid mb={3} ml={2}>
                        <Typography fontSize={18}> 80 arriendos encontrados</Typography>
                        <Typography fontSize={18}>$ 30000000 CLP pendientes de cobro</Typography>
                        <Button>Enviar mail recordatorio de pago</Button>
                        <Button>Ver ejemplo de mail</Button>
                    </Grid>

                    <div style={{
                        borderBottom: '3px solid #155172', // Línea inferior gruesa
                        borderTop: '3px solid #155172', // Línea superior gruesa solo para el primer elemento
                        padding: '10px',
                        marginBottom: '10px',
                        height: '580px',
                        width: '400px',
                        overflowY: 'auto',
                        margin: '0 auto', // Centrar el contenedor en el drawer
                    }}>
                        {dataPendientes.map((item, index) => (
                            <div key={item.id} style={{
                                borderBottom: index < dataPendientes.length - 1 ? '1px solid #ddd' : 'none', // Línea delgada entre elementos
                                paddingBottom: '10px',
                                paddingTop: '5px',
                                marginBottom: '10px',
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }}>
                                <>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#000' }}>
                                                {`# ${item?.cod_arriendo}`}
                                            </Typography>

                                            <Typography sx={{ color: '#606060' }}>
                                                {`(${item?.rut_cliente}) ${item?.name_cliente}`}
                                            </Typography>

                                            <Typography sx={{ color: '#606060' }}>
                                                {`${item?.dia_inpago} días pendientes de pago`}
                                            </Typography>

                                            <Typography sx={{ color: '#000' }}>
                                                {` $ ${item?.total_deuda} CLP`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => viewDetail()}>
                                                <VisibilityIcon />
                                            </IconButton>
                                            <IconButton>
                                                <MailIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>


                                </>
                            </div>
                        ))}
                    </div>
                </Box>
                {
                    widthDrawer == '1000px' &&
                    <Box
                        sx={{
                            width: "450px",
                            padding: "20px",
                            paddingX: "40px",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "12px",
                            backgroundColor: "#fff",
                        }}
                    >
                        <Grid container justifyContent='flex-end'>
                            <IconButton size="large">
                                <CloseIcon onClick={() => setWidthDrawer('500px')} />
                            </IconButton>
                        </Grid>


                    </Box>
                }

            </Box>

        </Drawer >
    )
}