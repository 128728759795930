import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { Protected } from '@components/layout/Protected';
import { Props } from './Supervisor.type';
import { Alert, Typography, Backdrop, Button, Autocomplete, TextField, CircularProgress, Container, Grid, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { ModalAddTask } from "@/components/common/Modal/ModalAddTask";
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import GroupIcon from '@mui/icons-material/Group';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import moment from "moment";
import AddIcon from '@mui/icons-material/Add';
import { SpinnerGrow, SpinnerLoad } from "@/components/common/Spinner";
import { ConfirmDialog } from "@/components/common/DialogConfirm";
import { supervisorService } from "@/service/services/Supervisor.service";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { entityService } from "@/service/services/Entity.service";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const SupervisorView: React.FC<Props> = ({ }): JSX.Element => {

    const [openMove, setOpenMove] = React.useState(false);
    const [openShare, setOpenShare] = React.useState(false);
    const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
    const [openView, setOpenView] = useState(false);
    const [openView2, setOpenView2] = useState(false);
    const [select, setSelect] = useState('0');
    const [Vendedores, setVendedores] = useState<any>([]);
    const [vendedor, setVendedor] = useState<any>('')
    const [supervisores, setSupervisores] = useState<any>({})
    const [selectSupervisor, setSelectSupevisor] = useState<any>([])
    const [tasks, setTasks] = useState<any>([]);
    const [error, setError] = useState(null);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'warning',
        message: 'Error',
        autoHideDuration: 1000,
    })
    const [Dialog2, setDialog2] = useState<any>({
        open: false,
        title: 'Eliminar',
        confirm: false,
        actionId: null,
        message: `¿Desea eliminar la accion ---?`
    })

    const handleChange = (event: SelectChangeEvent) => {
        setSelect(event.target.value);
    };

    useEffect(() => {
        getVendedor()
        seguimientoVendedores()
        getSupervisor()
    }, [])

    async function getSupervisor() {
        try {
            const resp = await entityService.getSupervisor();
            setSupervisores(resp.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function getVendedor() {
        try {
            const resp = await supervisorService.getVendedor();
            console.log(resp.data)
            setVendedores(resp.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function seguimientoVendedor(idVendedor) {
        try {
            setOpenView(true);
            const resp = await supervisorService.seguimientoVendedor(idVendedor)
            if (select == '0') {
                setTasks(resp ? resp.data.Tareas : []);
                console.log(resp.data.Tareas)
            } else {
                setTasks(resp ? resp.data.Ventas : []);
                console.log(resp.data.Ventas)
            }
            setOpenView(false);
        } catch (e) {
            console.log(e)
        }
    }

    async function seguimientoVendedores() {
        try {
            setOpenView(true);
            const resp = await supervisorService.seguimientoVendedores()
            if (select == '0') {
                setTasks(resp ? resp.data.Tareas : []);
                console.log(resp.data.Tareas)
            } else {
                setTasks(resp ? resp.data.Ventas : []);
                console.log(resp.data.Ventas)
            }
            setOpenView(false);
        } catch (e) {
            console.log(e)
        }
    }

    const listVendedores = Vendedores?.map((value, i) => {
        return (
            <Grid key={i}>
                <Button
                    startIcon={<PersonOutlineIcon sx={{ color: '#007EA7' }} />}
                    variant='text'
                    sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em', mt: 1 }}
                    onClick={() => {
                        seguimientoVendedor(value.idvendedor)
                    }}
                >
                    {value.nombre_vendedor}
                </Button>
                {dataUser.user.role == ROLE_SUPERVISOR ?
                (<>
                    <Button onClick={() => { setVendedor(value.idvendedor), setOpenShare(true) }}>
                        <SafetyDividerIcon />
                    </Button>
                    <Button onClick={() => { setVendedor(value.idvendedor), setOpenMove(true) }}>
                        <CompareArrowsIcon />
                    </Button>
                </>)
                : ''}
            </Grid>
        )
    })

    const handleSaveMove = async () => {
        if (selectSupervisor.length === 0) { return setError("supervisor"); }
        else {
            const share = {
                supervisorEmisor: dataUser.user.idusuario,
                supervisorReceptor: selectSupervisor.idusuario,
                idvendedor: vendedor
            }
            const resp = await supervisorService.transferirVendedores(share)
            if(resp){
                if(resp.data.message == 'Transferencia exitosa'){
                    setVendedor('')
                    setSelectSupevisor([])
                    getVendedor()
                    seguimientoVendedores()
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: resp.data.message,
                     }));
                } else {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'error',
                        message: resp.data.message,
                     }));
                }
                setOpenMove(false)
            }
            setError(null)
        }
    };

    const handleCloseMove = () => {
        setVendedor('')
        setSelectSupevisor([])
        setError(null)
        setOpenMove(false);
    };

    const handleSaveShare = async () => {
        if (selectSupervisor.length === 0) { return setError("supervisor"); }
        else {
            const share = {
                supervisorEmisor: dataUser.user.idusuario,
                supervisorReceptor: selectSupervisor.idusuario,
                idvendedor: vendedor
            }
            const resp = await supervisorService.compartirVendedores(share)
            if(resp){
                if(resp.data.message == 'Vendedor compartido con exito'){
                    setVendedor('')
                    setSelectSupevisor([])
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'success',
                        message: resp.data.message,
                     }));
                } else {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'error',
                        message: resp.data.message,
                     }));
                }
                setOpenShare(false)
            }
            setError(null)
        }
    };

    const handleCloseShare = () => {
        setVendedor('')
        setSelectSupevisor({})
        setError(null)
        setOpenShare(false);
    };

    return (
        <Protected>
            <ConfirmDialog
                open={Dialog2.open}
                title={Dialog2.title}
                message={Dialog2.message}
                //   onConfirm={() => deleteAccion()}
                onClose={() => setDialog2(prev => ({ ...prev, open: false }))}
            />
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openView}
            >
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <SpinnerGrow />
                    <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                </Grid>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openView2}
            >
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <SpinnerLoad />
                    <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                </Grid>
            </Backdrop>
            <Container maxWidth="xl" sx={{ mb: 2 }}>
                <Grid container >
                    <Grid item container direction="column" xs={12} lg={3}>
                        <Grid item mt={6} sx={{ width: '220px' }}>
                            <FormControl fullWidth>
                                <InputLabel>Ver por</InputLabel>
                                <Select
                                    value={select}
                                    label="Seleccione"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>Tareas</MenuItem>
                                    <MenuItem value={1}>Ventas</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {dataUser.user.role == ROLE_VENDEDOR ? '' : <Grid item mt={6} sx={{ width: '220px' }}>
                            <Button
                                startIcon={<GroupIcon sx={{ color: '#007EA7' }} />}
                                variant='text'
                                sx={{ color: '#155172', fontWeight: '700', fontSize: '0.97em' }}
                                onClick={() => {
                                    // getFeeds();
                                }}
                            >
                                {<span >Todos</span>}
                            </Button>
                            {listVendedores}
                        </Grid>}
                    </Grid>

                    <Grid item xs={12} lg={dataUser.user.role == ROLE_ADMIN || dataUser.user.role == ROLE_SUPERVISOR ? 9 : 12} >
                        <FullCalendar
                            height={570}
                            initialView="listMonth"
                            plugins={[
                                timeGridPlugin,
                                dayGridPlugin,
                                listPlugin,
                                interactionPlugin,
                                bootstrapPlugin,
                            ]}
                            headerToolbar={{
                                left: "title",
                                right:
                                    `prev,next,today,dayGridMonth,timeGridWeek,timeGridDay,listMonth`

                            }}
                            buttonText={{
                                today: "Hoy",
                                month: "Mes",
                                week: "Semana",
                                day: "Dia",
                                list: "Lista",
                            }}
                            locale={"es-us"}
                            themeSystem="Cerulean"
                            allDayText="Todo el día"
                            events={tasks.map((task, i) => {
                                // console.log(dateFormatTime(task.fechacreacion) == "00:00" ? dateFormat(task.fechacreacion) : task.fechacreacion)
                                return {
                                    id: task.id,
                                    title: `${task.tipo} - ${task.nombre_cliente ? task.nombre_cliente : task.form_cliente} `,
                                    start: dateFormatTime(task.fechacreacion) == "00:00" ? dateFormat(task.fechacreacion) : task.fechacreacion,
                                    end: dateFormatTime(task.fechacierre) == "00:00" ? dateFormat(task.fechacierre) : task.fechacierre,
                                    color: task.color,
                                    icon: <GroupIcon />,
                                };
                            })}
                            //editable={true}
                            droppable={true}
                            selectable={true}
                            // select={handleDateSelect}
                            // eventClick={handleEventClick}
                            // eventLimit={true}
                            navLinks={true}
                            handleWindowResize={true}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={openShare}
                onClose={handleCloseShare}
                fullWidth
                >
                <DialogTitle>
                    COMPARTIR VENDEDOR
                </DialogTitle>
                {/* <DialogContent>
                    <InputLabel >Supervisor</InputLabel>
                </DialogContent> */}
                <DialogContent>
                    <Autocomplete
                        id="tags-outlined"
                        options={supervisores}
                        getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                        size={'small'}
                        fullWidth
                        sx={{ bgcolor: '#fff' }}
                        value={selectSupervisor}
                        onChange={(e, newValue) => {
                            setSelectSupevisor(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={error === "supervisor" ? true : false}
                                helperText={error === "supervisor" ? "El supervisor es un campo requerido" : ""}
                                label="Seleccionar un supervisor"
                                placeholder=""
                                fullWidth
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseShare}>Cancel</Button>
                    <Button onClick={handleSaveShare}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openMove} onClose={handleCloseMove} fullWidth>
                <DialogTitle>
                    TRANSFERIR VENDEDOR
                </DialogTitle>
                <DialogContent>
                    <InputLabel >Supervisor</InputLabel>
                    <Autocomplete
                        id="tags-outlined"
                        options={supervisores}
                        getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                        size={'small'}
                        fullWidth
                        sx={{ bgcolor: '#fff' }}
                        value={selectSupervisor}
                        onChange={(e, newValue) => {
                            setSelectSupevisor(newValue);
                            // setError(false)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // error={error === "bodega" ? true : false}
                                // helperText={error === "bodega" ? "El bodega es un campo requerido" : ""}
                                label="Seleccionar un vendedor"
                                placeholder=""
                                fullWidth
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMove}>Cancel</Button>
                    <Button onClick={handleSaveMove}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            {/* <ModalAddTask
              taskAdd={taskAdd}
              open={open}
              setOpen={setOpen}
              //taskEdit={taskEdit}
              //setTaskEdit={setTaskEdit}
              //taskUpdate={taskUpdate}
              sentDateData={sentDateData}
              setSentDateData={setSentDateData}
           />
           <ModalNewCampaign
              open={openNewCampaing}
              setOpen={setOpenNewCampaing}
              dataRule={dataRule}
           />
           <ModalDetailsEvent
              openDetailsEvent={openDetailsEvent}
              setOpenDetailsEvent={setOpenDetailsEvent}
              taskEdit={taskEdit}
              setTaskEdit={setTaskEdit}
              taskUpdate={taskUpdate}
              taskDelete={taskDelete} /> */}
        </Protected>
    );
}