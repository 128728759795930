import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const AreaNegocioRepository = {
   getArea: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/companyArea?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
         data,
         error,
         message
      }
   },

   getAreaPage: async (perPage, page): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/companyArea?per_page=${perPage}&page=${page}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const {data,error,message} = resp
      return {
         data,
         error,
         message
      };
   },

   getAreaSearch: async (term: string, perPage, page): Promise<any> => {
      const user = await http.get<any>(`${API_URL_BASE}/v1/companyArea/search/${term}?per_page=${perPage}&page=${page}` )
      const {data,error,message} = user
      return {
         data,
         error,
         message
      };
   },

   createArea: async (descripcion) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/companyArea`, {
         descripcion: descripcion,
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   updateArea: async (id: number, descripcion) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/companyArea/${id}`, {
         descripcion: descripcion,
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   deleteArea: async (id: number) : Promise<any> => {
      const resp = await http.delete(`${API_URL_BASE}/v1/companyArea/${id}`)
      return resp;
   },

   createTrabajador: async (dataUser) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/user`, {
         idtipousuario: dataUser.idtipousuario,
         nombre: dataUser.nombre,
         user_nick: dataUser.user_nick,
         email: dataUser.email_admin,
         password: dataUser.password,
         telefono: dataUser.telefono,
         idarea: dataUser.idarea,
         idempresacliente: dataUser.idempresacliente,
      })
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   getUsersByArea: async (idarea:number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const area = await http.get<any>(`${API_URL_BASE}/v1/proyeccion/areas?idareas=${idarea}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = area;
      return {
         data,
         error,
         message
      }
   },
}
