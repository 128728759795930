import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';


export const TaskRepository = {
   getTask: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/task?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   createTask: async (task): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/task`, {
         creador: task.creador,
         tipo: task.tipotarea,
         prioridad: task.prioridad,
         vendedor: task.vendedor,
         contacto: task.contacto,
         fechainicio: task.fechainicio,
         fechafin: task.fechafin,
         horainicio: task.horainicio,
         horafin: task.horafin,
         nota:task.nota,
         received:task.received?task.received:'0'
      })
      return {
        // error: resp.error,
        resp
         // status: resp.status,
         // message: resp.message,
         // data: resp.data
      };
   },
   createFeeds: async (feeds): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/feeds`, {
         propietario: feeds.propietario,
         idtipofeeds: feeds.idtipofeeds,
         contenido: '-',
         fechacreacion: feeds.fechacreacion,
         fechacierre: feeds.fechacierre,
         idcliente: feeds.idcliente,
         form_cliente: feeds.form_cliente,
         idcampananegocio: feeds.idcampananegocio,
         idfrecuencia:feeds.idfrecuencia,
         comentarios:feeds.comentarios,
         idprioridad:feeds.idprioridad,
         idusuario: feeds.idusuario,
         received: feeds.received?feeds.received:'0',
         id_empresa:id_empresa
      })
      return {
        error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   getFeeds: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/user?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },

   filterFeeds: async (ids_tipo_feeds, completed): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/filter?completed=${completed}&ids_tipo_feeds=${ids_tipo_feeds}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },

   getFeedsByUsers: async (idusuario,ids_tipo_feeds,completed): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/iduser?iduser=${idusuario}&completed=${completed}&ids_tipo_feeds=${ids_tipo_feeds}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   getCampaingbyCliente: async (id: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/getCampaign/${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   getFeedsData: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/data?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   EditFeeds: async (feeds): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.patch<any>(`${API_URL_BASE}/v1/feeds/${feeds.id}`,{
         propietario: feeds.propietario,
         idtipofeeds: feeds.idtipofeeds,
         contenido: '--',
         fechacreacion: feeds.fechacreacion,
         fechacierre: feeds.fechacierre,
         form_cliente: feeds.form_cliente,
         idcliente: feeds.idcliente,
         idcampananegocio: feeds.idcampananegocio,
         idfrecuencia:feeds.idfrecuencia,
         comentarios:feeds.comentarios,
         idprioridad:feeds.idprioridad,
         idusuario: feeds.idusuario,
         id_empresa:id_empresa
      } )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   DeleteFeeds: async(feeds): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/feeds/${feeds.id}`)
      const { data,error,message} = resp
      return {
         data,
         error,
         message
      };
   },
   getFeedsById: async (id): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/idfeeds?idfeeds=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   getFeedsByClient: async (id): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/idcliente?idcliente=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },

   getFeedsByClientAndCampaign: async (value): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/detailsLeads?idcliente=${value.idcliente}&idcampananegocio=${value.idcampananegocio}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },

   getFeedsCallByClient: async (id): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/call/idcliente?idcliente=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   getFeedsCallByForm_Client: async (form_cliente): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/call/form_cliente?form_cliente=${form_cliente}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      const { data,error,message} = task
      return {
         data,
         error,
         message
      };
   },
   getFeedsByClientLead: async (data): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      console.log(data);
      const task = await http.get<any>(`${API_URL_BASE}/v1/feeds/formcliente?form_cliente=${data.form_cliente}&idcampananegocio=${data.idcampananegocio}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}` )
      return task;
   },
   feedsCompleted: async ( id: number, data: any) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const feedsCompleted = await http.patch<any>(`${API_URL_BASE}/v1/feeds/completed/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`, {
         completed: data,
      });

       return feedsCompleted;
   },

   feedsComentario: async ( id: number, data: any) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const feedsComentario = await http.patch<any>(`${API_URL_BASE}/v1/feeds/updateComment/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`, {
         comentarios: data,
      });
       return feedsComentario;
   },

   clienteOrForm: async (feeds): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/leads/clienteOrForm?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`, {
         form_cliente: feeds.form_cliente,
         idcliente: feeds.idcliente,
         campana: feeds.campana,
      })
      return {
        error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   deleteFeed: async (id: number): Promise<any> => {
      const task = await http.delete<any>(`${API_URL_BASE}/v1/feeds/${id}` )
      return task;

   },





}
