import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Divider, Checkbox, InputAdornment, Backdrop, CircularProgress, Container } from '@mui/material'
import { DownloadIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import CircleIcon from '@mui/icons-material/Circle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { ModalAddAction } from "@/components/common/Modal/ModalAddAction";

import './Modal.sass'
import { accionService } from '@/service/services/Accion.service';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { TableData } from '../Table';

type ModalProps = {
   open :boolean,
   setOpen : any,
   dataComprados: any,
   dataComplementarios: any,
   dataRelacionados:any
}
export const ModalDetailRule: React.FC<ModalProps> = ({
   open,
   setOpen,
   dataComprados,
   dataComplementarios,
   dataRelacionados

}): JSX.Element => {

   const [value, setValue] = React.useState('1');

   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
     setValue(newValue);
   };


   const headerComprados = [
      { name: 'codigo', label: 'Codigo', width: '' },
      { name: 'nombre', label: 'Producto', width: '' }
      ]

   const headerComplmentarios = [
      { name: 'codigo', label: 'Codigo', width: '' },
      { name: 'nombre', label: 'Producto', width: '' }]

      const headerRelacionados = [
         { name: 'codigo', label: 'Codigo', width: '' },
         { name: 'nombre', label: 'Producto', width: '' }]



   const labels = [{ label: 'Productos Comprados' }, { label: 'Productos Complementarios' }, {label: 'Productos Relacionados'}]
   // const dataAction = [
   //    { name: 'view', color: 'success', route: ROUTE_CONTACT_UPDATE },
   //    //{ name: 'edit', color: 'primary', route: ROUTE_CONTACT_UPDATE },
   //    { name: 'delete', color: 'secondary', route: null }
   // ]

   const [taskcompleteda, setTaskCompletada] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const timer = React.useRef<number>();
   //  const handleInputChange = (e) => {
   //  }
   // useEffect(() => {
   //    console.log('holaaaa')
   //    if (actionEdit?.completed == '1') {
   //       setTaskCompletada(true)
   //       console.log(true)
   //    }
   // }, [])
//console.log(dataComplementarios)
//console.log(dataComprados)

   return (
      <>
         {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
         >
            <CircularProgress color="inherit" />
         </Backdrop> */}
         <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => { setOpen(false)}}
         >
            <Box sx={{
               position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '550px',
               bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24
            }}>
               <Box>

               <TabContext value={value}>
               <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                     <Tab label="Productos Comprados" value="1"  sx={{maxWidth:'34%'}}/>
                     <Tab label="Productos Complementarios" value="2" sx={{maxWidth:'33%'}}/>
                     <Tab label="Productos Relacionados" value="3" sx={{maxWidth:'34%'}}/>
                  </TabList>
               </Box>
               <TabPanel value="1">
                  <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              {/* <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${dataClient.length ? `${dataClient.length}` : `0`} Registros encontrados`}
                              </Typography> */}
                           </Grid>

                        </Grid>
                        <Grid container >
                           <TableData
                              header={headerComprados}
                              data={dataComprados}
                              action={null}
                               />
                        </Grid>
                     </Container>
               </TabPanel>
               <TabPanel value="2">
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              {/* <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${dataEntity.length ? `${dataEntity.length}` : `0`} Registros encontrados`}
                              </Typography> */}
                           </Grid>

                        </Grid>
                        <Grid container >
                           <TableData
                              header={headerComplmentarios}
                              data={dataComplementarios}
                              action={null}/>
                        </Grid>
                     </Container>

               </TabPanel>
               <TabPanel value="3">
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              {/* <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${dataEntity.length ? `${dataEntity.length}` : `0`} Registros encontrados`}
                              </Typography> */}
                           </Grid>

                        </Grid>
                        <Grid container >
                           <TableData
                              header={headerRelacionados}
                              data={dataRelacionados}
                              action={null}/>
                        </Grid>
                     </Container>

               </TabPanel>
               </TabContext>

               </Box>
            </Box>
         </Modal>

      </>
   );
}
