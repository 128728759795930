import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Divider, Checkbox, InputAdornment, Backdrop, CircularProgress } from '@mui/material'
import { DownloadIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import CircleIcon from '@mui/icons-material/Circle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { ModalAddTask } from "@/components/common/Modal/ModalAddTask";
import { useHistory } from 'react-router-dom'

import './Modal.sass'
import { taskService } from '@/service/services/Task.service';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import { ROUTE_CONTACT_UPDATE } from '@/toolbox/constants/route-map';

const initialFormValues = {
   tipo: "",
   prioridad: "",
   cliente: "",
   fechainicio: "",
   fechafin: "",
   nota: "",
}

const TypeTask = [
   { id: 1, title: 'Llamada' },
   { id: 2, title: 'Correo' },
   { id: 4, title: 'Otro' }
];
type ModalProps = {
   openDetailsEvent: boolean,
   setOpenDetailsEvent: any,
   taskEdit: any,
   setTaskEdit: any,
   taskUpdate: ({ }) => void,
   sentDateData?: any,
   setSentDateData?: any,
   taskDelete: any
}
export const ModalDetailsEvent: React.FC<ModalProps> = ({
   openDetailsEvent,
   setOpenDetailsEvent,
   taskEdit,
   setTaskEdit,
   taskUpdate,
   sentDateData,
   setSentDateData,
   taskDelete
}): JSX.Element => {
   const [taskcompleteda, setTaskCompletada] = useState<boolean>(false);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [open, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const timer = React.useRef<number>();
   const history = useHistory();
   //  const handleInputChange = (e) => {
   //  }
   useEffect(() => {
      console.log({
         contact:
         {
            ...taskEdit,
            action: 'view'
         }
      });
      const completed = taskEdit?.completed ? taskEdit.completed : 0;
      if (completed == 1) {
         setTaskCompletada(true);
      } else {
         setTaskCompletada(false);
      }
   }, [taskEdit])

   // const completarTarea = () => {
   //    setTaskCompletada(!taskcompleteda)
   //    if (!loading) {
   //       setLoading(true);
   //       timer.current = window.setTimeout(() => {
   //         setLoading(false);
   //       }, 2000);
   //     }
   // }
   const completarTarea = async () => {

      const id = taskEdit?.id;
      let estado;
      if (!loading) {
         setLoading(true);

         timer.current = window.setTimeout(() => {

            setLoading(false);
         }, 2000);
      }

      if (taskcompleteda == true) {
         const resp = await taskService.feedsCompleted(id, 0);
         //console.log(resp)
      }
      else if (taskcompleteda == false) {
         const resp = await taskService.feedsCompleted(id, 1);
         // console.log(resp)
      }
      setTaskCompletada(!taskcompleteda)
   }

   return (
      <>
         {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
         >
            <CircularProgress color="inherit" />
         </Backdrop> */}
         <Modal
            open={openDetailsEvent}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => { setOpenDetailsEvent(false) }}
         >
            <Box sx={{
               position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '480px',
               bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24
            }}>
               <Box>
                  <Grid container direction="row" spacing={2} sx={{ p: 4 }}>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                     >
                        <Grid sx={{ mr: 1 }}>
                           {
                              taskEdit?.tipo == 'Llamada' ?
                                 <PhoneIcon color='primary' />
                                 : taskEdit?.tipo == 'Correo' ?
                                    <EmailIcon color='error' />
                                    : taskEdit?.tipo == 'Reunion' ?
                                       <VideocamIcon />
                                       :
                                       <AssignmentIcon />
                           }
                        </Grid>
                        <Grid>
                           {taskEdit?.tipo}
                        </Grid>
                     </Grid>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ pt: 2 }}
                     >
                        <DateRangeIcon color='success' sx={{ mr: 1 }} />
                        <Grid >
                           {`Desde: ${taskEdit?.fechacreacion}`}
                        </Grid>

                     </Grid>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ pt: 2 }}
                     >
                        <DateRangeIcon color='success' sx={{ mr: 1 }} />
                        <Grid >
                           {`Hasta: ${taskEdit?.fechacierre}`}
                        </Grid>
                     </Grid>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ pt: 2 }}
                     >
                        <Grid item xs={1} sx={{ mr: 1 }}>
                           <NoteAltRoundedIcon color="secondary" />
                        </Grid>
                        <Grid item xs={9} sx={{ pt: 1 }}>
                           {taskEdit?.comentarios}
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}>
                     <Divider />
                     <Divider />
                  </Grid>
                  <Grid item container
                     xs={12}
                     direction="row"
                     justifyContent="space-between"
                     alignItems="center"
                     sx={{ pl: 2, pr: 2 }}>
                     <Grid>
                        <span><strong>Completada</strong></span>
                        {!loading ? (
                           <Checkbox defaultChecked checked={taskcompleteda ? true : false} onClick={() => { completarTarea() }} color="success" />
                        ) : (<CircularProgress
                           sx={{ ml: 1 }}
                           color="success"
                           size={17}
                        />)}
                     </Grid>
                     <Grid>
                        <Button
                           onClick={() => { setOpen(true) }}
                           sx={{
                              background: '#fff', width: '100px', color: '#52A6E7', border: 'none', '&:hover': {
                                 bgcolor: '#fff', border: '1px solid #52A6E7'
                              }
                           }}
                        >
                           Editar
                        </Button>
                        {dataUser.user.role == ROLE_ADMIN &&
                           <Button onClick={() => { taskDelete(taskEdit), setOpenDetailsEvent(false) }}
                              sx={{
                                 background: '#fff', width: '100px', color: '#bf6c00', border: 'none', '&:hover': {
                                    bgcolor: '#fff', border: '1px solid #bf6c00'
                                 }
                              }}>
                              Eliminar
                           </Button>}
                        {taskEdit?.idcliente && <Button
                        fullWidth
                           onClick={() => {
                              history.push(ROUTE_CONTACT_UPDATE, {
                                    ...taskEdit,
                                    action: 'view'
                              });
                           }}
                           sx={{
                              background: '#fff', width: '100px', color: '#52A6E7', border: 'none', '&:hover': {
                                 bgcolor: '#fff', border: '1px solid #52A6E7'
                              }
                           }}
                        >
                           Más detalles
                        </Button>}
                     </Grid>
                  </Grid>

               </Box>
            </Box>
         </Modal>
         <ModalAddTask
            open={open}
            setOpen={setOpen}
            taskEdit={taskEdit}
            setTaskEdit={setTaskEdit}
            taskUpdate={taskUpdate}
            setOpenDetailsEvent={setOpenDetailsEvent}
         />
      </>
   );
}
