import  React,{useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider,FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, IconButton, Tooltip, Snackbar, Alert} from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecommendIcon from '@mui/icons-material/Recommend';
import { ROUTE_CAMPAIGN } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { LeadsFormService } from '@/service/services/LeadsForm.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



type ModalProps = {
  open: boolean,
  setOpen:any,
  data?:any,
  idCampana?:any,
  dataImage?:any,
  direccionamiento?: boolean,
//   setData?:any
}




export const ModalPreviewEmailing:React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element =>{

   const [status, setStatus] = useState<boolean>(false);
   const [data, setData] = useState<any>({
      labelButton: props.dataImage.label_button,
      uriRedireccion: props.dataImage.url_brochure
   });
   const history = useHistory();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'primary',
      message: 'Error',
      autoHideDuration: 9000,
   })
   console.log(props.dataImage);
   // const [dataImage, setDataImage] = useState<any>();
   useEffect(()=>{
      setData({
         labelButton: props.dataImage.label_button?props.dataImage.label_button:'MÁS INFORMACIÓN',
         uriRedireccion: props.dataImage.url_brochure?props.dataImage.url_brochure:''
      })

      // setSnackBarConfig(prev => ({
      //    ...prev,
      //    open: true,
      //    message: "PREVISUALIZACIÓN EMAILING",
      // }));
   },[])

   const validateHeaderComponent = () =>{
      // if(!sectionHeader.textos.titulo.contenido)
      // {
      //      return  `url(${API_URL_BASE}/${sectionHeader.logo_header.ruta})`;
      // }
      // else if(sectionHeader.textos.titulo.contenido)
      // {
      //    return  `linear-gradient(${sectionHeader.background}),url(${API_URL_BASE}/${sectionHeader.logo_header.ruta})`;
      // }
      if(props.dataImage)
      {
         return `url(${API_URL_BASE}/${props.dataImage.header})`;
      }
      return null
   }

   const saveInfoEmailing = (info) =>{
      console.log(info);
     if(info)
     {
      const res = LeadsFormService.editUriBrochure(props.idCampana, info);
      if(res)
      {
         setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         severity: 'success',
         message: "se editó correctamente",
      }));
      }
     }
   }

   const handleDireccionamiento = () =>{
      if(props.direccionamiento)
      {
        history.push(ROUTE_CAMPAIGN);
      }else{
        props.setOpen(false);
      }
    }
  const bodyModal = (
   <Grid
   container
   direction="row"
>
   <Grid item xs={12} sx={{ background:'#fff' }}>
     <Grid container justifyContent={'center'} paddingX={8} mt={3} maxHeight={'70vh'} sx={{overflowY:'scroll'}}>
     <Grid item lg={12} container >
         <Box id='body' style={{ padding: '1px',background:'#fff', marginTop:'30px'}}>
          {props.dataImage.header &&  <div id='Header'>
               <Box id='header' >
                  <div>
                     {/* <div style={{
                        // backgroundImage: `linear-gradient(${valueHeader.color_fondo_disfuminado}),url(${saveImageHeaderFondo.path})`,
                        backgroundImage: validateHeaderComponent(),
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '52vh',
                        position: 'relative',
                     }}>
                        <div style={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'center',
                           alignItems: 'center',
                           height: '50vh',
                        }}>
                           <div>

                                  {saveImageHeader.preview}
                           </div>
                           <div>
                                 <p style={{ color: valueHeader.color_slogan, fontSize: '30px', fontWeight: 700, textAlign: 'center' }}>
                                    <strong>{valueHeader.slogan}</strong>
                                 </p>
                           </div>
                        </div>
                     </div> */}
                        <img alt="" height="auto" src={`${API_URL_BASE}/${props.dataImage.header}`}
                           style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                           title="" width="600" />
                  </div>
               </Box>
            </div>}
           {props.dataImage.seccion && <Box id='section' >
               <div id='Seccion' style={{ padding: '20px 60px' }}>
                  <Grid item container direction={'row'} justifyContent="center" alignItems="center" >
                     <Grid item xs={12}>
                       <p style={{ fontSize: '1.0rem', paddingBottom: '10px', fontWeight: 600, textAlign:'center', fontFamily:'Open Sans, Helvetica, Arial, sans-serif'}}>
                       {props.dataImage.titleseccion}
                        </p>
                         <p style={{ fontSize: '0.8rem', paddingBottom: '10px', textAlign:'center',fontFamily:'Open Sans, Helvetica, Arial, sans-serif'}}>
                         {props.dataImage.descriptionseccion}
                        </p>
                     </Grid>
                     <Grid item container justifyContent="center" alignItems="center" xs={12}  >
                        <img
                        // width={350}
                        // height={230}
                        style={{ width: "100%" }}
                        src={`${API_URL_BASE}/${props.dataImage.seccion}`}
                        />
                     </Grid>
                  </Grid>
               </div>
            </Box>}
           {props.dataImage.ubicacion && <div id="Ubicacion">
               <Box id='ubicacion' justifyContent='center'>
                  <div style={{marginBottom:'20px'}}>
                    {props.dataImage.titleubicacion && <p style={{ fontSize: '15px',  paddingBottom: '10px', fontWeight: 600, textAlign: 'center' }}>
                     {props.dataImage.titleubicacion}
                     </p>}

                     {props.dataImage.descriptionubicacion && <p style={{ fontSize: '13px',  paddingBottom: '10px', textAlign: 'center' }}>
                      {props.dataImage.descriptionubicacion}
                     </p>}

                     <img className="lp-image-react w-9de1ee12-0b7d-f4de-b9d8-cd3bf31ecfe7 css-1rd798i lazyload"
                        src={`${API_URL_BASE}/${props.dataImage.ubicacion}`}
                        data-image-upload-source="builder3" alt="" style={{ width: "100%" }} />
                     {/* {saveImageUbicacion.preview} */}
                  </div>
               </Box>
            </div>}
           {props.dataImage.main && <div id="Main">
                       <Box id='main'  >
                          <Grid container spacing={2} justifyContent="center" alignItems="center" >
                             <Grid item container xs={6} justifyContent="center" alignItems="center" >
                                <div >
                                   <img src={`${API_URL_BASE}/${props.dataImage.main}`} alt='' width="260px" height='180px' />
                                </div>
                                {/* <img src={`${API_URL_BASE}/${sectionMain.img_main0.ruta}`} alt='' width="260px" height='180px' /> */}
                             </Grid>
                             <Grid item container xs={6} justifyContent="center" alignItems="center" >
                                <div >
                                   <img src={`${API_URL_BASE}/${props.dataImage.main1}`} alt='' width="260px" height='180px' />
                                </div>
                             </Grid>
                             <Grid item container xs={6} justifyContent="center" alignItems="center" >
                                <div style={{ paddingBottom: '20px' }}>
                                   <img src={`${API_URL_BASE}/${props.dataImage.main2}`} alt='' width="260px" height='180px' />
                                </div>
                             </Grid>
                             <Grid item container xs={6} justifyContent="center" alignItems="center" >
                                <div style={{ paddingBottom: '20px' }}>
                                   <img src={`${API_URL_BASE}/${props.dataImage.main3}`} alt='' width="260px" height='180px' />
                                </div>
                             </Grid>
                          </Grid>
                       </Box>
            </div>}

            <div id="Contactar">
               <Grid container justifyContent={'center'} alignItems='center' sx={{mt:3, mb:8}}>
                  {status?
                   <Button variant='contained' sx={{borderRadius:'15px'}}>
                   {data.labelButton}
                </Button>
                :
                 <Grid>
                 <input
                 // fullWidth
                 style={{padding:'12px',borderRadius:'15px',
                 background:'#1565C0',border:'none',color:'#fff', 
                 textAlign:'center',textDecoration:'none',fontFamily:'Open Sans, Helvetica, Arial, sans-serif'}}
                 id="comentarios"
               //   placeholder='CONTACTAR'
                 name="comentarios"
                 value={data.labelButton}
                 onChange={(e) => setData(prev=>({...prev,labelButton:e.target.value}))}
              />
               <TextField
                 // fullWidth
                 sx={{ bgcolor: '#fff',ml:2 ,fontFamily:'Open Sans, Helvetica, Arial, sans-serif'}}
                 size="small"
                 id="comentarios"
                 inputProps={{
                    maxLength: 200,
                 }}
                 placeholder='https://crm.kyte.cl'
                 name="comentarios"
                 value={data.uriRedireccion}
                 onChange={(e) => setData(prev=>({...prev,uriRedireccion:e.target.value}))}
              />
              </Grid>
                }

               {status?<Tooltip title="Editar nombre y redirección del botón">
               <EditIcon
                  color="success"
                  sx={{cursor:'pointer',ml:2}}
                  onClick={() => { setStatus(!status)}}>
               </EditIcon>
               </Tooltip>:
               <Tooltip title="Actualizar Cambios">
               <SendAndArchiveIcon
                  color="secondary"
                  sx={{cursor:'pointer',ml:2}}
                  onClick={() => { setStatus(!status)}}>
               </SendAndArchiveIcon>
               </Tooltip>
               }
                {status && <Tooltip title="Guardar Cambios">
               <SaveIcon
                  color="primary"
                  sx={{cursor:'pointer',ml:2}}
                  onClick={() => { saveInfoEmailing(data)}}>
               </SaveIcon>
               </Tooltip>
               }
               </Grid>
            </div>
         
         </Box>
      </Grid>
      
     </Grid>
   {props.direccionamiento && <Grid container direction='row'  
            sx={{ background:'#155172', p:2 }} justifyContent='center' alignItems='center' >
          <Grid item >
            <Button
              variant='contained'
              color='primary'
              onClick={() => {handleDireccionamiento()}}
              sx={{background:'#fff',color:'#155172',fontWeight: '700',  '&:hover':{
              bgcolor:'#fff',
             }}}
              endIcon={<ArrowRightIcon fontSize='large'/>}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>}
   </Grid>
   <Snackbar
      open={snackBarConfig.open}
      autoHideDuration={snackBarConfig.autoHideDuration}
      onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
   >
      <Alert
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         severity={snackBarConfig.severity}
         variant="filled"
      >
         {snackBarConfig.message}
      </Alert>
   </Snackbar>
</Grid>
  )

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
            props.setOpen(false)
        }}
        sx={{width:'110%', left:'-5%'}}
      >
         <Box className='Modal'>
         <Typography id="modal-modal-title" 
         sx={{ background:'#155172', color: '#fff', fontWeight: '700', fontSize: '2em', mb:3,p:2 }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Vista de envío por emailing`}
         </Typography>
         {bodyModal}
         </Box>

      </Modal>
    </div>
  );
}
