import React, { useEffect, useState } from 'react';
import { Grid, TextField, Divider, Autocomplete, Dialog, DialogTitle, Modal, Snackbar, Alert, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { contactService } from '@/service/services/Contact.service';

const header = [
   { name: 'VlrCodigo', label: 'Código', width: '120px' },
   { name: 'NmbItem', label: 'Nombre', width: '' },
   { name: 'QtyItem', label: 'Cantidad', width: '' },
   { name: 'PrcItem', label: 'Precio', width: '100px' },
   { name: 'MontoItem', label: 'Monto', width: '100px' },]


export const ModalDetalleCompra: React.FC<any> = ({
   open,
   setOpen,
   idCompra
}): JSX.Element => {
      const [dataDetalle, setDataDetalle] = useState<any>([]);
//    const [filter, setFilter] = useState(null);
const getDataDetalle = async (idcompra) =>{
   const resp = await contactService.getComprasbyClientDetalle(idcompra);
   setDataDetalle(resp.data);
}
   useEffect(() => {
      getDataDetalle(idCompra);
      console.log(idCompra)
   }, [idCompra])

  
   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 850,
      borderRadius: '20px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 3,
      background: '#F5F5F5'
   };

   return (
      <div>

         <Modal
            open={open}
            onClose={()=>{setOpen(false)}}
         >
            <Box className='Modal' sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 850,
               borderRadius: '20px',
               bgcolor: 'background.paper',
               boxShadow: 24,
               p: 3,
               background: '#F5F5F5'
            }}>
            <Typography style={{ fontSize: "28px",textAlign:'center'}}>Detalle de la Compra</Typography>
               <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{mt:3}}>
                  <TableData
                     header={header}
                     data={dataDetalle}
                     action={null}
                  />
               </Grid>

            </Box>
         </Modal>
      </div>

   );
}
