import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import {
   readLocalStorage,
   removeLocalStorage,
   saveLocalStorage
} from '@helpers/local-storage-helper';
import { byPassRepository } from "../repositories/Bypass.repository";
import {
   KEY_ARRAY_MY_MENU,
   KEY_BYPASS,
   KEY_EMPRESA,
   KEY_NAVEGACION_SUB_MODULO,
   KEY_OBJECT_MY_MENU,
   KEY_SOFTNET,
   KEY_TOKEN_KYTE,
   KEY_TOKEN_SOFTNET,
   KEY_TOOGLE_MENU,
   KEY_USER_DATA
} from '@constants/local-storage';
import { APP_AUTHORIZE_KEY } from '@defaults/app';
import { STATIC_ROUTERS, STATIC_ROUTES_ARRIENDO } from '@defaults/static-routers';
import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROUTES_FOR_SUPER_ADMIN, ROUTES_FOR_ADMIN, ROLE_SUPERVISOR, ROUTES_FOR_SUPERVISOR, ROLE_VENDEDOR, ROUTES_FOR_VENDEDOR, ROLE_TRABAJADOR, ROUTES_FOR_TRABAJADOR, ROLE_ADMIN_PROYECCION, ROUTES_FOR_ADMIN_PROYECCION} from '@defaults/static-roles';
import { MAIN_REDIRECT_FOR_SUPER_ADMIN, MAIN_REDIRECT_FOR_ADMIN} from '@defaults/static-roles';
import { removeAuthCookie, setAuthCookie, readAuthCookie } from '@helpers/cookie.helper';
import { authenticationRepository } from '../repositories/Authentication.repository';
import { Authentication } from '../models/Authentication';
import { ROUTE_ACTIVIDADES, ROUTE_PROGRAMATION } from '@/toolbox/constants/route-map';

const currentUserSubject = new BehaviorSubject<Authentication | null>(init());

export const authenticationService = {
   login,
   logout,
   end,
   loginFake,
   loginCotizacion,
   validatePassword,
   ChangePassword,
   loginDocuments,
   registerDocuments,
   searchRut,
   searchRutEmpresa,
   authCookie: readAuthCookie,
   currentUser: currentUserSubject.asObservable(),
   get currentUserValue () { return currentUserSubject.value }
};

function init() {
   const auth: Authentication = readLocalStorage(KEY_USER_DATA);
   axios.defaults.headers.common[APP_AUTHORIZE_KEY] = auth?.token || '';
   return auth;
}
function end() {
   removeLocalStorage(KEY_USER_DATA);
   removeLocalStorage(KEY_ARRAY_MY_MENU);
   removeLocalStorage(KEY_OBJECT_MY_MENU);
   removeLocalStorage(KEY_TOOGLE_MENU);
   removeAuthCookie();
   axios.defaults.headers.common[APP_AUTHORIZE_KEY] = null;
   currentUserSubject.next(null);
}

async function loginDocuments(rut_cliente, password) : Promise<any> {
   return await authenticationRepository.loginDocuments(rut_cliente, password)
}

async function registerDocuments(dataClient) : Promise<any> {
   return await authenticationRepository.registerDocuments(dataClient)
}

async function searchRut(rut) : Promise<any> {
   return await authenticationRepository.searchRut(rut)
}

async function searchRutEmpresa(rut) : Promise<any> {
   return await authenticationRepository.searchRutEmpresa(rut)
}

async function loginFake(username: string, password: string,rut: string) : Promise<any> {
   return await authenticationRepository.loginSoftnet(username,rut,password)
}

function createExpireToken( s: number ): Date {
   let now = new Date();
   let time = now.getTime();
   var expireTime = time + 1000*s;
   now.setTime(expireTime);
   return now
}

async function validatePassword(password){
   const resp = await authenticationRepository.validateContraseña(password)
}

async function ChangePassword(password){
   const resp = await authenticationRepository.changePassword(password)
}

async function login(user_nick: string, rut_empresa: string, password: string, from:string, tokenNotify:string) : Promise<Authentication> {
   try {
      const login = await authenticationRepository.login(user_nick, rut_empresa, password, from);

      if (!!login.error) {
         return {
            user  : null,
            token : '',
            error : login.error
         }
      }
      console.log(login.data?.token_softnet)
      const token_softnet = `${login.data?.token_softnet|| ''}`;
      const access_token = `${login.data?.token_type || ''} ${login.data?.token || ''}`;
      const rut_company = `${login.data?.rut_empresa|| ''}`;
      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;

      const auth = await authenticationRepository.profile(access_token,rut_company);
      // const Notify = await authenticationRepository.deviceToken(tokenNotify);
      // console.log(Notify);
      const expire_time = login.data?.expires_in ? createExpireToken(login.data?.expires_in) : 0;

      if (!!auth.error) {
         return {
            user  : null,
            token : '',
            error : auth.error
         }
      }
      let role: string = auth.user.role || '';
      let routesRules: any = [],
      mainRedirect: any,
      modules = [];
      let submodules = {};

      if(role == ROLE_ADMIN){
         routesRules = ROUTES_FOR_ADMIN;
         mainRedirect = ROUTE_ACTIVIDADES;
      }
      else if(role == ROLE_SUPERVISOR){

         routesRules = ROUTES_FOR_SUPERVISOR;
         mainRedirect = ROUTE_ACTIVIDADES;
      }
      else if(role == ROLE_SUPER_ADMIN){
         routesRules = ROUTES_FOR_SUPER_ADMIN;
         mainRedirect = ROUTE_PROGRAMATION;
      }
      else if(role == ROLE_VENDEDOR){
         routesRules = ROUTES_FOR_VENDEDOR;
         mainRedirect = ROUTE_ACTIVIDADES;
      }
      else if(role == ROLE_TRABAJADOR){
         routesRules = ROUTES_FOR_TRABAJADOR;
         mainRedirect = ROUTE_PROGRAMATION;
      }
      else if(role == ROLE_ADMIN_PROYECCION){
         routesRules = ROUTES_FOR_ADMIN_PROYECCION;
         mainRedirect = ROUTE_PROGRAMATION;
      }

      auth.user.main_redirect = mainRedirect;

      let arrayRoutes:any = STATIC_ROUTERS
      console.log(rut_company+'a')
      if(rut_company == '50290070-6') {
         console.log(arrayRoutes)
         arrayRoutes = STATIC_ROUTES_ARRIENDO
      }
      console.log(arrayRoutes)
      arrayRoutes.forEach( module => {      
         routesRules.forEach((route: {module: string, navigators: []}) => {
            if (route.module === module.route) {
               modules.push({
                  ...module,
                  "route-navigators": module["route-navigators"]
               })
               if(module.route=="/cuenta")
               {
                  submodules={
                     "route_navigators":  module["route-navigators"]
                  }
               }
            }
         })
      });

      saveLocalStorage(KEY_SOFTNET, token_softnet)
      saveLocalStorage(KEY_ARRAY_MY_MENU, modules);
      saveLocalStorage(KEY_NAVEGACION_SUB_MODULO, submodules);
      saveLocalStorage(KEY_USER_DATA, auth);
      saveLocalStorage(KEY_TOOGLE_MENU, true);
      saveLocalStorage(KEY_TOKEN_KYTE,access_token)

      if(!!login.data.token) {
         setAuthCookie(access_token, undefined);
         setAuthCookie(access_token, expire_time === 0 ? undefined: { expires: expire_time });
         currentUserSubject.next(auth);
      }
      const authResponse = {...auth, data: login.data};
      return authResponse;

   } catch (e) {
      return {
         user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}

async function loginCotizacion(username: string, password: string,rut: string) : Promise<any> {
   try {
      const login = await authenticationRepository.loginCotizacion(username,rut,password);

      if (!!login.message) {
         return {
            user  : null,
            token : '',
            message : login.message
         }
      }

      const access_token = `${login.token || ''}`;

      axios.defaults.headers.common[APP_AUTHORIZE_KEY] = access_token;
       //const auth = await authenticationRepository.profile(access_token);
       //console.log(auth)
      // const expire_time = login.data?.expires_in ? createExpireToken(login.data?.expires_in) : 0;
      //  const loyverse = await authenticationRepository.loginLoverse(username,rut,password)
      //  if(loyverse.message==='Empresa integrada'){
      //    console.log(loyverse)
      //  }

         const auth = await authenticationRepository.dataUser(access_token);
         const empresa = await authenticationRepository.dataEmpresa(access_token);
      const parms = {
         key: "azureservicemap",
         usuario: username,
         clave: password,
         rut: rut,
      }
      if (!!auth.mensaje) {

         // return {
         //    user  : null,
         //    token : '',
         //    mensaje : auth.mensaje
         // }
      }
      //auth.user.main_redirect = mainRedirect;
      saveLocalStorage(KEY_TOKEN_SOFTNET,access_token);
      saveLocalStorage(KEY_USER_DATA, auth);
      saveLocalStorage(KEY_EMPRESA,empresa);
      saveLocalStorage(KEY_TOOGLE_MENU, true);
      setAuthCookie(access_token, undefined);
     // setAuthCookie(access_token, expire_time === 0 ? undefined: { expires: expire_time });

      if(!!login.token) {
         currentUserSubject.next(login);
      }

         const authResponse = {...auth, data: login };

      return authResponse;

   } catch (e) {
      return {
         user: null, token: '', error: { code: 0, message: 'Error en obtener permisos' }
      }
   }
}


async function logout() {
   const rpta = await authenticationRepository.logout();
   if (!rpta.error) {
      end();
   }
   return rpta;
}
