import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, StepIconProps } from '@mui/material'

import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Icon } from '@components/common/Icon';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";

import { FormCreateEnterprise, FormCreateUser } from '@components/common/Form';
import { Props } from './SteperContact.type';
import { Protected } from '@/components/layout/Protected';
import { useHistory } from 'react-router-dom'
import { ROUTE_ACCOUNT } from '@/toolbox/constants/route-map';
import "./Steper.sass";
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import { entityService } from '@/service/services/Entity.service';
import { MenuLateral } from '@/components/common/LateralMenu';
import { FormContact } from './components/FormContact';
import { FormContact2 } from './components/FormContact2';
import { FormContactUbigeo } from './components/FormContactUbigeo';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const steps = [{name:'Prospecto',icon:null}, {name:'Datos del contacto',icon:null}, {name:'Ubigeo',icon:null}];

export const SteperContact: React.FC<Props> = (props: Props): JSX.Element => {
   const history = useHistory();
   const [activeStep, setActiveStep] = useState(-1);
   const [skipped, setSkipped] = useState(new Set());
   const [error, setError] = useState(null);
   const [valueContact, setValueContact] = useState({
      rut_cliente: '',
      idtipopersona: '',
      idtiporubro: '',
      nombre_rz: '',
      nombre_fantasia: '',
      giro: '',
      prospecto: '',
      observacion: '',
      extranjero: '',
      // cto_cargo: '',
      cto_cobranza: '',
      fax: '',
      web: '',
   });

   const [valueContact2, setValueContact2] = useState({
      cto_nombre: '',
      cto_cargo: '',
      cto_email: '',
      cto_telefono: '',
      cto_celular: '',

   });
   const [valueContactUbigeo, setValuevalueContactUbigeo] = useState({
      direccion: '',
      idcomuna: '',
   });


   useEffect(() => {
      setActiveStep(0)
      if (props.contact !== undefined) {
         setValueContact(props.contact);
         //setValueContactEntity({...props.entity,...valueEntity})
         setValueContact2(props.contact);

      }
   }, [])


   //metodos para manipular el stepp
   const isStepSkipped = (step) => {
      return skipped.has(step);
   };

   const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
         newSkipped = new Set(newSkipped.values());
         newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };
   //opcional color steper
   const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage:
            'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage:
            'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
      },
    }));

    const ColorlibStepIconRoot = styled('div')<{
      ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      ...(ownerState.active && {
        backgroundImage:
          'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(ownerState.completed && {
        backgroundImage:
          'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
      }),
    }));

    function ColorlibStepIcon(props: StepIconProps) {
      const { active, completed, className } = props;

      const icons: { [index: string]: React.ReactElement } = {
        1: <PersonOutlineIcon />,
        2: <GroupAddIcon />,
        3: <LocationOnIcon />,
      };

      return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
          {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
      );
    }
   //validacion para mostrar los 2 tipos de formularios en el stepper
   const mostrarFormulario = () => {
      if (activeStep === 0) {
         return (
            <FormContact
               handleNext={handleNext}
               valueContact={valueContact}
               setValueContact={setValueContact}
            />
         )
      }
      else if (activeStep === 1) {
         return (
            <FormContact2
               handleNext={handleNext}
               handleBack={handleBack}
               valueContact2={valueContact2}
               setValueContact2={setValueContact2}
            />
         )
      }
      else if (activeStep === 2) {
         return (
            <FormContactUbigeo
               handleNext={handleNext}
               handleBack={handleBack}
               valueContact={valueContact}
               valueContactUbigeo={valueContactUbigeo}
               valueContact2={valueContact2}
               setValueContactUbigeo={setValuevalueContactUbigeo}
            />
         )
      }
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container flexDirection='row' spacing={2} mt={3}>

            <Grid container direction="row" justifyContent="center" alignItems="center">
                     <Grid item xs={12}>
                        <Box>
                           <Box sx={{ mb: 2 }}>
                              <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                 {steps.map((label, index) => {
                                    return (
                                       <Step key={index}>
                                          <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                       </Step>
                                    );
                                 })}
                              </Stepper>
                           </Box>
                           {activeStep === steps.length ? (
                              <div>FINISH</div>
                              //VALIDAR QUE EL ENVIO DE DATOS
                              // history.push(ROUTE_ACCOUNT)
                           ) : (
                              mostrarFormulario()
                           )}
                        </Box>
                     </Grid>
                  </Grid>
            </Grid>
         </Container>
      </Protected>
   );
}
