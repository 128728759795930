import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { InputAdornment, IconButton, Step, StepLabel, Button, Card, CircularProgress, Grid, CssBaseline, Container, Modal, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, MenuItem, responsiveFontSizes, TextField } from '@mui/material'
import { ArrowBackIcon, ArrowDownIcon, SaveIcon, VisibilityIcon, VisibilityOffIcon } from "@toolbox/constants/icons";
import { Icon } from '@components/common/Icon';
import User from "@assets/img/user.png";
import { Link, useHistory } from 'react-router-dom'
import * as qs from 'qs'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { ROUTE_ENTITY, ROUTE_LOGIN, ROUTE_USER } from '@toolbox/constants/route-map';
import { MenuLateral } from '@/components/common/LateralMenu';
import ConfirmEmail from "@assets/img/confirm-email.png";
import ErrorConfirmEmail from "@assets/img/error-confirm-email.jpg";
import { ConfirmationmailService } from '@/service/services/Confirmation.service';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { RecoveryEmailRepository } from '@/service/repositories/Recovery.repository';
import { RecoverymailService } from '@/service/services/Recovery.service';

export const RecoveryEmailView: React.FC<any> = (props): JSX.Element => {
   const history = useHistory();

   const [resp, setResp] = useState<any>(null)
   const [idValue, setidValue] = useState<any>('')

   const {id, user} = props.location && qs.parse(props.location.search.slice(1, -1));

   const dataInitial = {
      repit_password:'',
      new_password:'',
      textError: '',
      showPassword: false
   }

   const [openNew, setOpenNew] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [verify, setVerify] = useState<boolean>(false);
   const [error, setError] = useState(null);
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [data, setData] = useState(dataInitial);

   useEffect(()=>{

      if(user&& id){
        var decodeUser;
         var decodeId;
        var decodePassword;

        try {
           const password = 'hola'
          decodeUser = atob(user);
          decodeId = atob(id);
           decodePassword = btoa(password);
           setidValue(decodeId)

        // authSubmit(decodeUser,decodeRutEmpresa,decodePassword);
        } catch (error){
         }
       }else{
        //  if(session && session.token){
        //    props.history.push("/inicio");
        //  }else{
            props.history.push("/");
        //  }
       }
     }, []);

     const handleClickShowPassword = () => {
      setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   const rules = useMemo(() => ({
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      repit_password: [
         VALIDATORS.PASSWORD.EQUALS,
      ],
      new_password: [
         VALIDATORS.PASSWORD.SIZE
      ]
   }), []);
   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      //console.log(value)
      switch (name) {
         case 'new_password':
               setData(prev => ({ ...prev, new_password: value }));
               break;
         case 'repit_password':
               setData(prev => ({ ...prev, repit_password: value }));
                break;
            //setData(prev => ({ ...prev, email: value }));
         default:
               break;
      }
   }
   const handleInputBlur = (event: any) => {

      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }

   // const RespConfirmEmail = async () =>{

   //    let resp=true
   //     //let resp = await ConfirmationmailService.sendMailConfirmation(emailValue);

   //     if(resp){
   //       setResp(true)
   //     }else{
   //        setResp(false)
   //     }

   // }


   const handleSubmit = async(e)=>{
      e.preventDefault();

      let resp = true
      if(data.repit_password==data.new_password && data.repit_password!='' && data.new_password!=''){

         const resp:any = await RecoverymailService.sendMailNewPassword(idValue,data.repit_password)
         if(resp?.data.message.status==true){
            setSnackBarConfig({...snackBarConfig , open: true, severity:'info', message: resp.data.message.descripcion })

         }else{
            setSnackBarConfig({ ...snackBarConfig , open: true, severity:'error', message: resp.data.message.descripcion })

         }
        //
      //   if(snackBarConfig.open==false){
      //    history.push(ROUTE_LOGIN)
      //   }
         setInterval(()=>{history.push(ROUTE_LOGIN)},6000)
      }
   }

   return (
      <>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{mt:8}}
         >
         <Box  sx={{ border:'2px solid #6F6F6F', borderRadius:'3px'}}>
                  <form onSubmit={handleSubmit}>
                     <Grid className="modal-body" sx={{maxWidth:{lg:'30vw',md:'40vw', xs:'75vw'}}}>
                        <Grid
                           container
                           direction="row"
                           justifyContent="flex-end"
                           alignItems="center">
                           <Grid xs={12}  >
                              <Typography className='modal-tittle' sx={{fontWeight:700, color:'#1976D2'}} >Reestablece tu contraseña</Typography>
                           </Grid>
                           <Grid container spacing={2} >
                                   <Grid item xs={12}>
                                       <Input
                                             ref={ref => inputRefs.current[0] = ref}
                                             placeholder="Ingrese su nueva contraseña"
                                             type={data.showPassword ? 'text' : 'password'}
                                             height={35}
                                             prependInnerAdornment={
                                             <InputAdornment position="end">
                                             <VpnKeyIcon />
                                          </InputAdornment>
                                          }
                                          appendAdornment={
                                             <InputAdornment position="end">
                                                <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                >
                                                   {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                                </IconButton>
                                             </InputAdornment>
                                          }
                                             name="new_password"
                                             value={data.new_password}
                                             onChange={handleInputChange}
                                             onBlur={handleInputBlur}
                                             backgroundColor="#F3F3F3"
                                             // rules={rules.new_password}
                                             disableElevation
                                             validateOnBlur
                                             dense
                                          />
                                   </Grid>
                                   <Grid item xs={12}>
                                       <Input
                                             ref={ref => inputRefs.current[1] = ref}
                                             placeholder="Repita su nueva contraseña"
                                             type={data.showPassword ? 'text' : 'password'}
                                             height={35}
                                             prependInnerAdornment={
                                             <InputAdornment position="end">
                                             <VpnKeyIcon />
                                          </InputAdornment>
                                          }
                                          appendAdornment={
                                             <InputAdornment position="end">
                                                <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                >
                                                   {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                                </IconButton>
                                             </InputAdornment>
                                          }
                                             name="repit_password"
                                             value={data.repit_password}
                                             onChange={handleInputChange}
                                             onBlur={handleInputBlur}
                                             backgroundColor="#F3F3F3"
                                             rules={data.repit_password!==data.new_password?rules.repit_password:[]}
                                             disableElevation
                                             validateOnBlur
                                             dense
                                          />
                                   </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Divider style={{ marginBottom: "2vh" }} />
                     <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 2 }} justifyContent='flex-end' alignContent='center' >
                        <Button type={"button"} sx={{ borderRadius: '15px' }} size="small" component={Link} to={ROUTE_LOGIN}>
                           <span style={{ color: "gray" }}>Cancelar</span>
                        </Button>
                        <Grid xs={1} />

                            <Button onClick={handleSubmit}
                            sx={{ mr: 2, borderRadius: '15px', backgroundColor: "#1976D2", }}
                             variant="contained" size="small" type='submit'>
                              Enviar
                           </Button>
                        </Grid>
                  </form>
               </Box>
         </Grid>
      </>
      )
};
