import React, { useState, useMemo, useRef, useEffect, Suspense } from 'react';
import { Props } from './LeadsFormCampaingEdit.type';
import { InputAdornment, IconButton, Step, StepLabel, Button, Card, CircularProgress, Grid, Container, useTheme, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, MenuItem, responsiveFontSizes, TextField, useMediaQuery, Autocomplete, Chip, Stepper, StepButton, Backdrop } from '@mui/material'
import { bypassService } from '@/service/services/Bypass.service';
import { LeadsFormService } from '@/service/services/LeadsForm.service';
import { saveLocalStorage, readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BYPASS } from '@/toolbox/constants/local-storage';
import * as qs from 'qs'
import { campaingService } from '@/service/services/Campaing.service';
import { mailService } from '@/service/services/Mail.service';
import './index.css'
import { API_URL_BASE } from '@/toolbox/defaults/app';
import { SpinnerGrow } from '@/components/common/Spinner';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import LinkIcon from '@mui/icons-material/Link';
import { InputBase } from "@mui/material";
import {useHistory} from 'react-router-dom'
import PublishIcon from '@mui/icons-material/Publish';

const steps = ['Header', 'Seccion', 'Ubicacion', 'Formulario', 'Video', 'Main', 'Footer'];
export const LeadsFormCampaingEditView: React.FC<Props> = (props: any): JSX.Element => {

   const [openInput, setOpenInput] = useState<boolean>(false)
   const [newTitle, setNewTitle] = useState<any>();
   const history= useHistory();
   const [openInputDescription, setOpenInputDescription] = useState<boolean>(false)
   const [newTitleDescription, setNewTitleDescription] = useState<any>();

   const [inputsLeads, setInputsLeads] = useState<any>();
   const [loading, setLoading] = useState<boolean>(false)
   const [open, setOpen] = useState<boolean>(false)
   const [loadingbutton, setLoadingButton] = useState<boolean>(false)
   const [disabled, setDisabled] = useState<boolean>(false)
   const [formValues, setFormValues] = useState<any>([]);
   const [valueCorreo, setValueCorreo] = useState<any>()
   const [respMail, setRespMail] = useState<boolean>(false)
   const [dataLeadsInputs, setDataLeadsInput] = useState<any>([{}])
   const [renderM, setRenderM] = useState<boolean>(true)
   const [openVisualizar, setOpenVisualizar] = useState<boolean>(false)
   const [values, setValues] = useState<any>({
      idinputs: [],
      channel: '',
      idParameter: 0,
      name: 0
   })

   const { idParameter, name, channel, idinputs } = props.location && qs.parse(props.location.search.slice(1, -1));

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'primary',
      message: 'Error',
      autoHideDuration: 7000,
   })



   const [valueHeader, setValueHeader] = useState<any>({
      slogan: '',
      color_fondo_disfuminado: 'rgba(1, 41, 64, 0.43), rgba(1, 41, 64, 0.43)',
      color_slogan: '#fff',
      white_img_fondo: 512,
      height_img_fondo: 341,
      white_img: 438,
      height_img: 428,
   })

   const [valueSection, setValueSection] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#155172',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 300
   })

   const [valueUbicacion, setValueUbicacion] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#fff',
      color_title: '',
      color_description: '',
      white_img: 1300,
      height_img: 1000,
      url: ''
   })

   const [valueMain, setValueMain] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })
   const [valueMain2, setValueMain2] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })
   const [valueMain3, setValueMain3] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })
   const [valueMain4, setValueMain4] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })

   const [saveImageHeaderFondo, setSaveImageHeaderFondo] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });

   const [saveImageHeader, setSaveImageHeader] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });

   const [saveImageSection, setSaveImageSection] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });

   const [saveImageUbicacion, setSaveImageUbicacion] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });

   const [saveImageMain, setSaveImageMain] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain2, setSaveImageMain2] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain3, setSaveImageMain3] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain4, setSaveImageMain4] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });

   const changePathHeader = (e, value) => {
      if (value == 1) {
         const file = e.target.files[0];
         if (!file) {
            return;
         }
         let src, preview, type = file.type;

         if (type == "image/bmp") {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
            }));
            return;
         }
         console.log(file)
         if (/^image\/\S+$/.test(type)) {
            console.log('entre')
            src = URL.createObjectURL(file)
            preview = <img src={src} alt='' width={`${valueHeader.white_img_fondo / 2}`} height={`${valueHeader.height_img_fondo / 2}`} />
         }
         setSaveImageHeaderFondo(prev => ({ ...prev, data: file, path: src, preview: preview }))

      }
      else {
         const file = e.target.files[0];
         if (!file) {
            return;
         }
         let src, preview, type = file.type;
         if (type == "image/bmp") {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
            }));
            return;
         }
         // Coincide con la cadena que comienza con image /
         if (/^image\/\S+$/.test(type)) {
            src = URL.createObjectURL(file)
            preview = <img src={src} alt='' width={`${valueHeader.white_img / 2}`} height={`${valueHeader.height_img / 2}`} />
         }
         setSaveImageHeader(prev => ({ ...prev, data: file, path: src, preview: preview }))
      }
   }

   ///OBTENER ARCHIVOS DE LAS SECCIONES
   const changePathSection = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if (type == "image/bmp") {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueSection.white_img / 2}`} height={`${valueSection.height_img / 2}`} />
      }
      setSaveImageSection(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }

   ///OBTENER ARCHIVOS DE LAS UBICACION
   const changePathUbicacion = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if (type == "image/bmp") {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueUbicacion.white_img / 2}`} height={`${valueUbicacion.height_img / 2}`} />
      }
      setSaveImageUbicacion(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }

   //OBTENER ARCHIVOS EN MAIN 1234

   const changePathMain = (e) => {
      const file = e.target.files[0];
      console.log(file)
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if (type == "image/bmp") {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain.white_img / 2}`} height={`${valueMain.height_img / 2}`} />
      }
      setSaveImageMain(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathMain2 = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if (type == "image/bmp") {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain2.white_img / 2}`} height={`${valueMain2.height_img / 2}`} />
      }
      setSaveImageMain2(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathMain3 = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if (type == "image/bmp") {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain3.white_img / 2}`} height={`${valueMain3.height_img / 2}`} />
      }
      setSaveImageMain3(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathMain4 = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if (type == "image/bmp") {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain4.white_img / 2}`} height={`${valueMain4.height_img / 2}`} />
      }
      setSaveImageMain4(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }

   //    useEffect(()=>{

   //   if(!!idParameter && !!channel &&  !!idinputs && renderM) {
   //    //getFormTemplate(name);
   //          var decodeChannel;
   //          var decodeIdInputs;
   //         var decodePassword;

   //         try {
   //            const password = 'hola'

   //            decodeChannel = atob(channel);
   //           decodeIdInputs = idinputs;
   //           decodePassword = btoa(password);

   //           const decodeArray = decodeIdInputs.split("-")

   //             let arrayInputs = []

   //             decodeArray.map((value)=>{
   //             arrayInputs.push(atob(value))
   //            })



   //            setValues( prev => (
   //              { ...prev,
   //                idinputs: arrayInputs,
   //                channel: decodeChannel,
   //                idParameter: idParameter,
   //                name: name
   //               }
   //            ))

   //         // authSubmit(decodeUser,decodeRutEmpresa,decodePassword);
   //         } catch (error){
   //            console.log(error)
   //          }
   //        }else{
   //         //  if(session && session.token){
   //         //    props.history.push("/inicio");
   //         //  }else{

   //         //  }
   //        }
   //        loginBypass();
   //        getInputs();
   //      }, [props.location]);


   useEffect(() => {
      getFormTemplate(props.location.pathname.split("/")[2]);
   }, [])


   const loginBypass = async () => {
      const bypass = await bypassService.loginByPass('dsf', 'sdf', 'sfd');


      saveLocalStorage(KEY_BYPASS, bypass.token.original.access_token)

   }

   async function getInputs() {
      setLoading(true)
      const resp = await bypassService.getInputs()

      setDataLeadsInput(resp)
      setLoading(false)
   }

   const [seccionesHabilitadas, setSeccionesHabilitadas] = useState<any>({
      Header: 0,
      Main: 0,
      Ubicacion: 0,
      Formulario: 0,
      Footer: 0,
      Main2: 0,
      Hipervinculo: 0
   });

   const [fixedOptions, setFixedOptions] = useState<any>()
   const [seletInputs, setSelectInputs] = useState<any>([
      { id: 1, name: 'Nombre' }, { id: 2, name: 'Correo' }, { id: 3, name: 'Telefono' },
   ])
   const [sectionHeader, setSectionHeader] = useState<any>(
      {
         logo_header: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         img_header: {
            ruta: '',
            width: "",
            heigth: ""
         },
         textos: {
            titulo: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionSeccion, setSectionSeccion] = useState<any>(
      {
         img_main: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         textos: {
            titulo: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
            descripcion: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionUbicacion, setSectionUbicacion] = useState<any>(
      {
         img_ubicacion: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         hipervinculo: {
            contenido: ''
         },
         textos: {
            titulo: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
            descripcion: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionFormulario, setSectionFormulario] = useState<any>(
      {
         img_formulario: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         textos: {
            titulo: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
            descripcion: {
               idTexto: '',
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionVideo, setSectionVideo] = useState<any>(
      {
         hipervinculo: {
            idTexto: '',
            contenido: '',
            color: "",
            size: ""
         },
         background: ''
      })
   const [sectionMain, setSectionMain] = useState<any>(
      {
         img_main0: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         img_main1: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         img_main2: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         img_main3: {
            idImagen: '',
            ruta: '',
            width: "",
            heigth: ""
         },
         background: ''
      })


   const handleForm = async (e) => {
      e.preventDefault();
      // setLoadingButton(true)
      // setDisabled(true)
      // const arrayForm = Object.values(formValues)

      // let ruta = ''

      // arrayForm.map((value) => {
      //    ruta = ruta + "&" + value
      // })
      // console.log(ruta)
      // const resp = await bypassService.postCrearForm(values.idParameter, values.channel, ruta)
      // const insert = await campaingService.createDetailsCampaign(parseInt(values.idParameter), parseInt(values.name), resp.idForm)
      // // console.log(insert.message)
      // // console.log(insert)
      // // if(insert.message=='Formulario ingresado con exito')
      // // {
      // setLoadingButton(false)
      // // }
      // const mail: any = await mailService.sendEmailLeads(parseInt(values.name), valueCorreo)
      // //   console.log(mail)

      // if (mail?.data.message) {
      //    setRespMail(true)
      //    setSnackBarConfig(prev => ({
      //       ...prev,
      //       open: true,
      //       message: mail?.data.message,
      //    }));

      // }

   }

   const handleBlur = () => {
      //actualizar title a nuevo title
      setOpenInput(false);
    };

    const handleBlurDescription = () =>{
      setOpenInputDescription(false);
    }

    const closeModEdit= () =>{
      const idCampana = props.location.pathname.split("/")[2];
      // history.push(`/campaña/generales/${idCampana}`,{nombre_campana: props.location.state.nombre_campana,cliente: null});
      const newTabUrl = `/campaña/generales/${idCampana}?state=${false};`;
      window.open(newTabUrl, '_self');
    }

   const SaveEditLanding = async () => {
      setOpenVisualizar(true)
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;

      const idCampana = props.location.pathname.split("/")[2];
      // const rut_empresa = '22222222-2';
      if (saveImageHeaderFondo.preview && sectionHeader.logo_header.ruta) {
         const imagenHeader = new FormData();
         imagenHeader.append('idcampana', idCampana);
         imagenHeader.append('rut_empresa', rut_empresa);
         imagenHeader.append('imagen', saveImageHeaderFondo.data || null);
         const responseHeader = await LeadsFormService.editImage(sectionHeader.logo_header.idImagen, imagenHeader)
      }
      if (saveImageSection.preview && sectionSeccion.img_main.ruta) {
         const imagenSeccion = new FormData();
         imagenSeccion.append('idcampana', idCampana);
         imagenSeccion.append('rut_empresa', rut_empresa);
         imagenSeccion.append('imagen', saveImageSection.data || null);
         const responseSeccion = await LeadsFormService.editImage(sectionSeccion.img_main.idImagen, imagenSeccion)
      }
      if(newTitle)
      {
         const textSeccion = new FormData();
         textSeccion.append('descripcion',newTitle);
         textSeccion.append('color','');
         textSeccion.append('tamaño','');
         const respTextSeccion = await LeadsFormService.editText(sectionSeccion.textos.titulo.idTexto, textSeccion);
      }

      if(newTitleDescription)
      {
         const textSeccionDescripcion = new FormData();
         textSeccionDescripcion.append('descripcion',newTitleDescription);
         textSeccionDescripcion.append('color','');
         textSeccionDescripcion.append('tamaño','');
         const respTextSeccionDescripcion = await LeadsFormService.editText(sectionSeccion.textos.descripcion.idTexto, textSeccionDescripcion);
      }

      if (saveImageUbicacion.preview && sectionUbicacion.img_ubicacion.ruta) {
         const imagenUbicacion = new FormData();
         imagenUbicacion.append('idcampana', idCampana);
         imagenUbicacion.append('rut_empresa', rut_empresa);
         imagenUbicacion.append('imagen', saveImageUbicacion.data || null);
         const responseUbicacion = await LeadsFormService.editImage(sectionUbicacion.img_ubicacion.idImagen, imagenUbicacion)
      }
      if (saveImageMain.preview && sectionMain.img_main0.ruta) {
         const imagenMain = new FormData();
         imagenMain.append('idcampana', idCampana);
         imagenMain.append('rut_empresa', rut_empresa);
         imagenMain.append('imagen', saveImageMain.data || null);
         const responseMain = await LeadsFormService.editImage(sectionMain.img_main0.idImagen, imagenMain)
      }

      if (saveImageMain2.preview && sectionMain.img_main1.ruta) {
         const imagenMain2 = new FormData();
         imagenMain2.append('idcampana', idCampana);
         imagenMain2.append('rut_empresa', rut_empresa);
         imagenMain2.append('imagen', saveImageMain2.data || null);
         const responseMai2 = await LeadsFormService.editImage(sectionMain.img_main1.idImagen, imagenMain2)
      }
      if (saveImageMain3.preview && sectionMain.img_main2.ruta) {
         const imagenMain3 = new FormData();
         imagenMain3.append('idcampana', idCampana);
         imagenMain3.append('rut_empresa', rut_empresa);
         imagenMain3.append('imagen', saveImageMain3.data || null);
         const responseMain3 = await LeadsFormService.editImage(sectionMain.img_main2.idImagen, imagenMain3)
      }
      if (saveImageMain4.preview && sectionMain.img_main3.ruta) {
         const imagenMain4 = new FormData();
         imagenMain4.append('idcampana', idCampana);
         imagenMain4.append('rut_empresa', rut_empresa);
         imagenMain4.append('imagen', saveImageMain4.data || null);
         const responseMain4 = await LeadsFormService.editImage(sectionMain.img_main3.idImagen, imagenMain4)
      }

      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         severity: 'success',
         message: "Se editó correctamente"
      }));
      setOpenVisualizar(false)
   }

   const handleFormValues = (e) => {
      const handleChangeFormValues = {
         ...formValues,
         [e.target.name]: e.target.name + '=' + e.target.value
      }

      if (e.target.name == 'Correo') {
         setValueCorreo(e.target.value)
      }

      setFormValues(handleChangeFormValues)

   }

   let arrayFiltrado = []


   values.idinputs.map((i) => {
      dataLeadsInputs.length > 1 && dataLeadsInputs.map((value) => {

         if (i == value.name) {
            arrayFiltrado.push(value)
         }
      })
   })

   const textFieldForm = (
      arrayFiltrado.map((value) => {
         console.log(value)
         return (
            <Grid item xs={12}>
               <TextField
                  size='small'
                  id={value.id}
                  required
                  //   error={value.name==='RUT' && error=='rut'?true: false}
                  placeholder={`¿Cuál es tu ${value.name}?`}
                  // sx={{bgcolor:'#E3F4FD'}}
                  name={value.name}
                  //   value={nombre}
                  fullWidth
                  onChange={handleFormValues}
               />
            </Grid>
         )
      })
   )


   // useEffect(() => {
   //    getInputs();
   //    getFormTemplate(12);
   // }, [])

   // async function getInputs() {
   //    const resp = await bypassService.getInputs()
   //    setDataLeadsInput(resp)
   //    const fixed = resp.filter(data => data.name == 'nombre' || data.name == 'correo')
   //    setFixedOptions(fixed);
   //    setInputsLeads(fixed)
   // }
   //resp.secciones.Formularios.imagenes.img_formulario.ruta
   async function getFormTemplate(id) {
      setOpen(true);
      const resp: any = await LeadsFormService.getFormTemplate(id);
      if (resp?.code === 200) {
         const response = resp?.data?.detail;
      setSeccionesHabilitadas({
         Header: response?.idSecciones.Header,
         Main: response?.idSecciones.Main,
         Ubicacion: response?.idSecciones.Ubicacion,
         Formulario: response?.idSecciones.Formulario,
         Footer: response?.idSecciones.Footer,
         Main2: response?.idSecciones.Main2,
         Hipervinculo: response?.idSecciones.Hipervinculo
      });
      let ImageUbicacion = null;
      let ImageHeader2 = null;
      let ImageFormulario = null;

      if (response?.idSecciones.Ubicacion == 1) {
         if (!(response?.secciones.Ubicacion.imagenes.length == 0)) {
            ImageUbicacion = response?.secciones.Ubicacion.imagenes.img_ubicacion.ruta.replace(/\s/g, "%20") || null;
         }
      }
      if (response?.idSecciones.Header == 1) {
         if ((response?.secciones.Header.imagenes.img_header)) {
            ImageHeader2 = response?.secciones.Header.imagenes.img_header.ruta.replace(/\s/g, "%20") || null;
         }
      }
      if (response?.idSecciones.Formulario == 1) {
         if ((response?.secciones.Formulario.imagenes.img_formulario)) {
            ImageFormulario = response?.secciones.Formulario.imagenes.img_formulario.ruta.replace(/\s/g, "%20") || null;
         }
      }

      setSectionHeader({
         // ruta: response?.idSecciones.Ubicacion==1&& ImageUbicacion,
         // width:response?.idSecciones.Ubicacion==1 && ImageUbicacion&& response?.secciones.Ubicacion.imagenes&&response?.secciones.Ubicacion.imagenes?.img_ubicacion.width||'',
         // heigth:response?.idSecciones.Ubicacion==1&& ImageUbicacion&& response?.secciones.Ubicacion.imagenes&&response?.secciones.Ubicacion.imagenes?.img_ubicacion.heigth||''
         logo_header: {
            idImagen: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.idImagen,
            ruta: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.ruta.replace(/\s/g, "%20"),
            width: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.width,
            heigth: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.heigth
         },
         img_header: {
            ruta: response?.idSecciones.Header == 1 && ImageHeader2,
            width: response?.idSecciones.Header == 1 && ImageHeader2 && response?.secciones.Header.imagenes && response?.secciones.Header.imagenes?.img_header.width || '',
            heigth: response?.idSecciones.Header == 1 && ImageHeader2 && response?.secciones.Header.imagenes && response?.secciones.Header.imagenes?.img_header.heigth || ''
         },
         textos: {
            titulo: {
               idTexto: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.idTexto,
               contenido: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.contenido,
               color: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.color,
               size: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.size,
            },
         },
         background: response?.idSecciones.Header == 1 && response?.secciones.Header.background
      })

      setSectionFormulario({
         // img_formulario: {
         //    ruta: response?.idSecciones.Formulario==1&& (response?.secciones.Formulario.imagenes.img_formulario.ruta).replace(/\s/g,"%20"),
         //    width:response?.idSecciones.Formulario==1&& response?.secciones.Formulario.imagenes.img_formulario.width,
         //    heigth:response?.idSecciones.Formulario==1&& response?.secciones.Formulario.imagenes.img_formulario.heigth
         // },
         img_formulario: {
            ruta: response?.idSecciones.Formulario == 1 && ImageFormulario,
            width: response?.idSecciones.Formulario == 1 && ImageFormulario && response?.secciones.Formulario.imagenes && response?.secciones.Formulario.imagenes.img_formulario.width,
            heigth: response?.idSecciones.Formulario == 1 && ImageFormulario && response?.secciones.Formulario.imagenes && response?.secciones.Formulario.imagenes.img_formulario.heigth
         },
         textos: {
            titulo: {
               idTexto: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.idTexto,
               contenido: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.contenido,
               color: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.color,
               size: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.size,
            },
            descripcion: {
               idTexto: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.idTexto,
               contenido: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.contenido,
               color: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.color,
               size: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.size,
            },
         },
         background: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.background
      })

      setSectionSeccion({
         img_main: {
            idImagen: response?.idSecciones.Main == 1 && response?.secciones.Main.imagenes.img_main.idImagen,
            ruta: response?.idSecciones.Main == 1 && (response?.secciones.Main.imagenes.img_main.ruta).replace(/\s/g, "%20"),
            width: response?.idSecciones.Main == 1 && response?.secciones.Main.imagenes.img_main.width,
            heigth: response?.idSecciones.Main == 1 && response?.secciones.Main.imagenes.img_main.heigth
         },
         textos: {
            titulo: {
               idTexto: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.idTexto,
               contenido: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.contenido,
               color: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.color,
               size: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.size,
            },
            descripcion: {
               idTexto: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.idTexto,
               contenido: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.contenido,
               color: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.color,
               size: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.size,
            },
         },
         background: response?.idSecciones.Main == 1 && response?.secciones.Main.background
      })
      setNewTitle(response?.idSecciones.Main == 1 ? response?.secciones.Main.textos.titulo.contenido:'')
      setNewTitleDescription(response?.idSecciones.Main == 1 ? response?.secciones.Main.textos.descripcion.contenido :'')

      setSectionUbicacion({
         img_ubicacion: {
            idImagen: response?.idSecciones.Ubicacion == 1 && ImageUbicacion && response?.secciones.Ubicacion.imagenes && response?.secciones.Ubicacion.imagenes?.img_ubicacion.idImagen || '',
            ruta: response?.idSecciones.Ubicacion == 1 && ImageUbicacion,
            width: response?.idSecciones.Ubicacion == 1 && ImageUbicacion && response?.secciones.Ubicacion.imagenes && response?.secciones.Ubicacion.imagenes?.img_ubicacion.width || '',
            heigth: response?.idSecciones.Ubicacion == 1 && ImageUbicacion && response?.secciones.Ubicacion.imagenes && response?.secciones.Ubicacion.imagenes?.img_ubicacion.heigth || ''
         },
         hipervinculo: {
            contenido: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.hipervinculo && response?.secciones.Ubicacion.textos.hipervinculo.contenido
         },
         textos: {
            titulo: {
               idTexto: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.idTexto || '',
               contenido: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.contenido || '',
               color: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.color || '',
               size: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.size || '',
            },
            descripcion: {
               idTexto: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.idTexto || '',
               contenido: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.contenido || '',
               color: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.color || '',
               size: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.size || '',
            },
         },
         background: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.background
      })

      setSectionVideo({
         hipervinculo: {
            idTexto: response?.idSecciones.Hipervinculo == 1 && response?.secciones.Hipervinculo.textos.hipervinculo.idTexto,
            contenido: response?.idSecciones.Hipervinculo == 1 && response?.secciones.Hipervinculo.textos.hipervinculo.contenido,
            color: "",
            size: ""
         },
         background: response?.idSecciones.Hipervinculo == 1 && response?.secciones.Hipervinculo.background
      })
      setSectionMain(
         {
            img_main0: {
               idImagen: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main0.idImagen,
               ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main0.ruta).replace(/\s/g, "%20"),
               width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main0.width,
               heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main0.heigth
            },
            img_main1: {
               idImagen: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main1.idImagen,
               ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main1.ruta).replace(/\s/g, "%20"),
               width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main1.width,
               heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main1.heigth
            },
            img_main2: {
               idImagen: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main2.idImagen,
               ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main2.ruta).replace(/\s/g, "%20"),
               width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main2.width,
               heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main2.heigth
            },
            img_main3: {
               idImagen: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main3.idImagen,
               ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main3.ruta).replace(/\s/g, "%20"),
               width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main3.width,
               heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main3.heigth
            },
            background: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.background
         }
      )
      setTimeout(() => {
         setOpen(false);
      }, 2000);
   } else {
      setSnackBarConfig(prev => ({
         ...prev,
         open: true,
         severity: 'error',
         message: resp?.data?.message,
      }));
   }
   }

   const validateHeaderComponent = () => {

      if (!sectionHeader.textos.titulo.contenido) {
         return `url(${API_URL_BASE}/${sectionHeader.logo_header.ruta})`;
      }
      else if (sectionHeader.textos.titulo.contenido) {
         return `linear-gradient(${sectionHeader.background}),url(${API_URL_BASE}/${sectionHeader.logo_header.ruta})`;
      }
   }



   return (
      <Grid
         container
         direction="row"
         justifyContent={'center'}
         sx={{ bgcolor: '#fff' }}
      >
         <div id='contenido'>
            {seccionesHabilitadas.Header == 1 && <table className="" style={{ width: '750px' }} width="750">
               <tr>
                  <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                     <div style={{ background: '#EFE6FF', position: 'relative', backgroundColor: '#EFE6FF', margin: '0px auto', maxWidth: '750px' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                           {/* <PublishIcon/>
                           <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathHeader(e, 1) }}
                              style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                           /> */}
                            <div className="wrapper">
                           <div className="file-upload">
                              <input
                                 type='file'
                                 accept="image/png,image/jpeg"
                                 onChange={(e) => { changePathHeader(e, 1) }}
                              />
                              <p style={{ fontSize: '20px' }}>Reemplazar</p>
                           </div>
                        </div>
                        </div>
                        <table role="presentation"
                           style={{ background: '#ffffff', backgroundColor: '#ffffff', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td
                                    style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '0px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                    <div className="mj-column-per-100 outlook-group-fix"
                                       style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                       <table role="presentation" style={{ verticalAlign: 'top' }}
                                          width="100%">
                                          <tr>
                                             <td
                                                style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                <table role="presentation"
                                                   style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                                   <tbody>
                                                      <tr>
                                                         <td style={{ width: '1200px' }}>
                                                            <div >
                                                               {saveImageHeaderFondo.preview ?

                                                                  <img alt="" height="auto" src={saveImageHeaderFondo.path}
                                                                     style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                     title="" width="600" />
                                                                  :
                                                                  <img alt="" height="auto" src={`${API_URL_BASE}/${sectionHeader.logo_header.ruta}`}
                                                                     style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                     title="" width="600" />
                                                               }
                                                            </div>
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>

                     </div>
                  </td>
               </tr>
            </table>}



            {seccionesHabilitadas.Main == 1 && <table className="" style={{ width: '750px' }} width="750">
               <tr>
                  <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                     <div style={{ background: '#EFE6FF', backgroundColor: '#EFE6FF', margin: '0px auto', maxWidth: '750px' }}>
                        <table role="presentation"
                           style={{ background: '#ffffff', backgroundColor: '#ffffff', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td
                                    style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '0px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                    <div className="mj-column-per-100 outlook-group-fix"
                                       style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                       <table role="presentation" style={{ verticalAlign: 'top' }}
                                          width="100%">
                                          <tr>
                                             <td
                                                style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                <table role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}
                                                >
                                                   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', background: sectionSeccion.background }}>
                                                      <div
                                                         className='letra'
                                                         style={{ fontFamily: 'Open Sans, Helvetica, Arial, sans-serif', fontSize: '13px', lineHeight: '22px', textAlign: 'left', color: '#797e82', padding: '10px' }}>

                                                         {openInput?
                                                         //  <input
                                                         //  onChange={(e) => setNewTitle(e.target.value)}
                                                         //  onBlur={handleBlur}
                                                         //  value={newTitle}
                                                         //  autoFocus
                                                         //  />
                                                            <Grid container
                                                               direction="row"
                                                               justifyContent="center"
                                                               alignItems="center">
                                                               <InputBase
                                                                  style={{
                                                                     fontSize: "1.2rem",
                                                                     color:'#fff',
                                                                     fontWeight: "bold",
                                                                     textAlign: 'center',
                                                                  }}
                                                                  value={newTitle}
                                                                  onChange={(e) => setNewTitle(e.target.value)}
                                                                  onBlur={handleBlur}
                                                                  autoFocus
                                                                  fullWidth
                                                                  // multiline
                                                               />
                                                            </Grid>
                                                          :
                                                         // <h5 style={{
                                                         //    textAlign: 'center',
                                                         //    lineHeight: '18px',
                                                         //    color: '#fff',
                                                         //    marginBottom: '5px'
                                                         // }}
                                                         //    onClick={() => { setOpenInput(true) }}>
                                                         //    {newTitle}
                                                         // </h5>

                                                         <Typography
                                                         sx={{ flexGrow: 1,
                                                            fontSize: "1.2 rem",
                                                            textAlign: 'center',
                                                            color: '#fff',
                                                            fontWeight: "bold",
                                                            marginBottom: '5px'}}
                                                            onClick={() => { setOpenInput(true) }}
                                                         >
                                                         {newTitle}
                                                         </Typography>
                                                      }

                                                        {openInputDescription?
                                                            <Grid container
                                                               direction="row"
                                                               justifyContent="center"
                                                               alignItems="center">
                                                               <InputBase
                                                                  style={{
                                                                     fontSize: "1.2rem",
                                                                     color:'#fff',
                                                                     fontWeight: "bold",
                                                                     textAlign: 'center',
                                                                  }}
                                                                  value={newTitleDescription}
                                                                  onChange={(e) => setNewTitleDescription(e.target.value)}
                                                                  onBlur={handleBlurDescription}
                                                                  autoFocus
                                                                  fullWidth
                                                                  multiline
                                                               />
                                                            </Grid>
                                                          :
                                                         <Typography
                                                         sx={{ flexGrow: 1,
                                                            fontSize: "1.2rem",
                                                            textAlign: 'center',
                                                            color: '#fff',
                                                            fontWeight: "bold",
                                                            marginBottom: '5px'}}
                                                            onClick={() => { setOpenInputDescription(true) }}
                                                         >
                                                         {newTitleDescription}
                                                         </Typography>
                                                      }
                                                         {/* <h6 style={{ textAlign: 'center', lineHeight: '18px', color: '#fff' }}><em>Es un proyecto vanguardista, moderno, con detalles elegantes,
                                                            Emplazado en el corazón de la ciudad de Pucón, a solo pasos del centro gastronómico</em></h6> */}
                                                      </div>
                                                      <div className='text' style={{ position: 'relative', }}>
                                                         <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                                                            <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathSection(e) }}
                                                               style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                            />
                                                         </div>
                                                         {saveImageSection.preview ?
                                                            < img alt="" height="auto" src={saveImageSection.path}
                                                               style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '300px' }} title=""
                                                            //   width="750"
                                                            />
                                                            :
                                                            <img alt="" height="auto" src={`${API_URL_BASE}/${sectionSeccion.img_main.ruta}`}
                                                               style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '300px' }} title=""
                                                            //   width="750"
                                                            />
                                                         }
                                                      </div>
                                                   </div>

                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </td>
               </tr>
            </table>}
            {seccionesHabilitadas.Ubicacion == 1 && <table className="" style={{ width: '750px' }} width="750">
               <tr>
                  <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                     <div style={{ background: '#ffffff', position: 'relative', backgroundColor: '#ffffff', margin: '0px auto', maxWidth: '750px' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                         { !sectionUbicacion.hipervinculo.contenido &&  <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathUbicacion(e) }}
                              style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                           />}
                        </div>
                        <table role="presentation" style={{ background: '#ffffff', backgroundColor: '#ffffff', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td
                                    style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '0px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                    <div className="mj-column-per-100 outlook-group-fix"
                                       style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                       <table role="presentation" style={{ verticalAlign: 'top' }}
                                          width="100%">
                                          <tr>
                                             <td
                                                style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                <table role="presentation"
                                                   style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                                   <tbody>
                                                      <tr >
                                                         <td >
                                                            <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam, rerum neque quidem necessitatibus nemo dolores adipisci rem ratione repellendus perferendis inventore vel, esse consectetur placeat soluta atque impedit molestias doloremque?</h1>
                                                         </td>
                                                         <td style={{ width: '1200px' }}>

                                                            {
                                                            sectionUbicacion.hipervinculo.contenido ?
                                                            <iframe
                                                            src={sectionUbicacion.hipervinculo.contenido}
                                                            width={`650px`}
                                                            height={`500px`}
                                                            style={{ border: "0" }}
                                                            allowFullScreen
                                                            loading="lazy">
                                                            </iframe>
                                                            :
                                                            saveImageUbicacion.preview ?
                                                               <img alt="" height="auto" src={saveImageUbicacion.path}
                                                                  style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                  title="" width="600" />
                                                               :
                                                               <img alt="" height="auto" src={`${API_URL_BASE}/${sectionUbicacion.img_ubicacion.ruta}`}
                                                                  style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                  title="" width="600" />
                                                            }

                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </td>
               </tr>
            </table>}

            {seccionesHabilitadas.Formulario == 1 && <table className="" style={{ width: '750px' }} width="750">
               <tr>
                  <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                     <div style={{ background: '#ffffff', backgroundColor: '#ffffff', margin: '0px auto', maxWidth: '750px' }}>
                        <table role="presentation"
                           style={{ background: '#ffffff', backgroundColor: '#ffffff', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td
                                    style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '0px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                    <div className="mj-column-per-100 outlook-group-fix"
                                       style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                       <table role="presentation" style={{ verticalAlign: 'top' }}
                                          width="100%">
                                          <tr>
                                             <td
                                                style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                <table role="presentation"
                                                   style={{ borderCollapse: 'collapse', borderSpacing: '0px', background: sectionFormulario.background }}>
                                                   <tbody>
                                                      <tr >
                                                         <div id="Formulario">
                                                            <Box id='formulario' component='form' onSubmit={handleForm} sx={{ bgcolor: sectionFormulario.background }} >
                                                               <Grid container direction='row' justifyContent={'center'} alignItems="center" >
                                                                  <Grid item xs={5} sx={{ m: 4 }}>
                                                                     <Grid item xs={12} sx={{
                                                                        padding: '30px',
                                                                        borderRadius: '15px',
                                                                        background: '#fff',
                                                                        width: '100%',
                                                                        border: '0px'
                                                                     }}>
                                                                        <p style={{ fontSize: '15px', color: sectionFormulario.textos.titulo.color, paddingBottom: '20px', fontWeight: 600, textAlign: 'center' }}>
                                                                           {sectionFormulario.textos.titulo.contenido}
                                                                        </p>
                                                                        <p style={{ fontSize: '13px', color: sectionFormulario.textos.descripcion.color, paddingBottom: '20px', textAlign: 'center' }}>
                                                                           {sectionFormulario.textos.descripcion.contenido}
                                                                        </p>

                                                                        <input
                                                                           className='formulario-inputs'
                                                                           disabled
                                                                           placeholder='Nombre'
                                                                           style={{ width: '90%', padding: '10px', marginBottom: '20px', boxShadow: 'rgb(0 0 0 / 4%) 1px 1px 3px', borderRadius: '10px' }}
                                                                        />

                                                                        <input
                                                                           className='formulario-inputs'
                                                                           disabled
                                                                           placeholder='Correo'
                                                                           style={{ width: '90%', padding: '10px', marginBottom: '20px', boxShadow: 'rgb(0 0 0 / 4%) 1px 1px 3px', borderRadius: '10px' }}
                                                                        />

                                                                        <input
                                                                           className='formulario-inputs'
                                                                           disabled
                                                                           placeholder='Telefono'
                                                                           style={{ width: '90%', padding: '10px', marginBottom: '20px', boxShadow: 'rgb(0 0 0 / 4%) 1px 1px 3px', borderRadius: '10px' }}
                                                                        />

                                                                        <div style={{ position: 'relative' }}>
                                                                           <button
                                                                              style={{
                                                                                 width: '102%',
                                                                                 padding: '10px',
                                                                                 fontWeight: 600,
                                                                                 marginBottom: '20px',
                                                                                 borderRadius: '24px', //opcional
                                                                                 background: disabled ? '#bcbcbc' : sectionFormulario.background,
                                                                                 boxShadow: 'rgb(0 0 0 / 4%) 1px 1px 3px',
                                                                                 cursor: ' pointer',
                                                                                 transition: 'border-radius 200ms ease 0s',
                                                                                 height: 'unset',
                                                                                 fontSize: '16px',
                                                                                 border: '0px',
                                                                                 color: 'white'
                                                                              }}
                                                                              disabled={disabled}
                                                                              type='submit'
                                                                           >
                                                                              {respMail ? 'ENVIADO' : 'ENVIAR'}
                                                                           </button>
                                                                           {loadingbutton && (
                                                                              <CircularProgress
                                                                                 size={24}
                                                                                 sx={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    marginTop: '-12px',
                                                                                    marginLeft: '-12px',
                                                                                 }}
                                                                              />
                                                                           )}
                                                                        </div>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                           <img src={`${API_URL_BASE}/${sectionFormulario.img_formulario.ruta}`} alt='' width="200px" />
                                                                        </Box>
                                                                     </Grid>
                                                                  </Grid>
                                                               </Grid>
                                                            </Box>
                                                         </div>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </td>
               </tr>
            </table>}

            {seccionesHabilitadas.Hipervinculo == 1 &&
               <table className="" style={{ width: '750px' }} width="750">
                  <tr>
                     <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                        <div style={{ background: '#ffffff', backgroundColor: '#ffffff', margin: '0px auto', maxWidth: '750px' }}>
                           <table role="presentation"
                              style={{ background: '#ffffff', backgroundColor: '#ffffff', width: '100%' }}>
                              <tbody>
                                 <tr>
                                    <td
                                       style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '0px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                       <div className="mj-column-per-100 outlook-group-fix"
                                          style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                          <table role="presentation" style={{ verticalAlign: 'top' }}
                                             width="100%">
                                             <tr>
                                                <td
                                                   style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                   <table role="presentation"
                                                      style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                                      <tbody>
                                                         <tr>
                                                            <Box id='video' sx={{ bgcolor: '#000' }} >
                                                               <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                  <iframe width="650" height="390"
                                                                    src={sectionVideo?.hipervinculo?.contenido}
                                                                     title="YouTube video player">
                                                                  </iframe>
                                                                  {/* <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathHeader(e, 1) }}
                                                                  style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                               /> */}
                                                                  {/* <button style={{ padding: '8px', cursor: 'pointer', fontWeight:400}}>
                                                                  EDITAR URL
                                                               </button>
                                                               <input
                                                                  style={{
                                                                     border: 'none',
                                                                     background: '#b9bdbf',
                                                                     display: 'block',
                                                                     outline: 'none',
                                                                     textDecoration: 'none',
                                                                     height: 'auto',
                                                                     padding: '10px',
                                                                     width: '100%'
                                                                  }}
                                                               /> */}

                                                               </Grid>
                                                               {/* <LinkIcon/> */}

                                                            </Box>

                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                </td>
                                             </tr>
                                          </table>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </td>
                  </tr>
               </table>}


            {seccionesHabilitadas.Main2 == 1 && <table className="" style={{ width: '750px' }} width="750">
               <tr>
                  <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                     <div style={{ background: '#ffffff', backgroundColor: '#ffffff', margin: '0px auto', maxWidth: '750px' }}>
                        <table role="presentation"
                           style={{ background: '#ffffff', backgroundColor: '#ffffff', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td
                                    style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '40px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                    <div className="mj-column-per-100 outlook-group-fix"
                                       style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                       <table role="presentation" style={{ verticalAlign: 'top' }}
                                          width="100%">
                                          <tr>
                                             <td
                                                style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                <table role="presentation"
                                                   style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                                   <tbody>
                                                      <tr>
                                                         <Box id='main' sx={{ bgcolor: sectionMain.background }} >
                                                            <Grid container spacing={3} justifyContent="center" alignItems="center" >
                                                               <Grid item container xs={6} justifyContent="center" alignItems="center" >
                                                                  <div style={{ position: 'relative' }}>
                                                                     <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                                                                        <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathMain(e) }}
                                                                           style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                        />
                                                                     </div>
                                                                     {saveImageMain.preview ?
                                                                        <img src={saveImageMain.path} alt='' width="260px" height='180px' />
                                                                        :
                                                                        <img src={`${API_URL_BASE}/${sectionMain.img_main0.ruta}`} alt='' width="260px" height='180px' />
                                                                     }
                                                                  </div>
                                                                  {/* <img src={`${API_URL_BASE}/${sectionMain.img_main0.ruta}`} alt='' width="260px" height='180px' /> */}
                                                               </Grid>
                                                               <Grid item container xs={6} justifyContent="center" alignItems="center" >
                                                                  <div style={{ position: 'relative' }}>
                                                                     <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                                                                        <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathMain2(e) }}
                                                                           style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                        />
                                                                     </div>
                                                                     {saveImageMain2.preview ?
                                                                        <img src={saveImageMain2.path} alt='' width="260px" height='180px' />
                                                                        :
                                                                        <img src={`${API_URL_BASE}/${sectionMain.img_main1.ruta}`} alt='' width="260px" height='180px' />
                                                                     }
                                                                  </div>

                                                               </Grid>
                                                               <Grid item container xs={6} justifyContent="center" alignItems="center" sx={{paddingBottom: '20px' }}>
                                                                  <div style={{ position: 'relative' }}>
                                                                     <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                                                                        <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathMain3(e) }}
                                                                           style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                        />
                                                                     </div>
                                                                     {saveImageMain3.preview ?
                                                                        <img src={saveImageMain3.path} alt='' width="260px" height='180px' />
                                                                        :
                                                                        <img src={`${API_URL_BASE}/${sectionMain.img_main2.ruta}`} alt='' width="260px" height='180px' />
                                                                     }                                                                  </div>
                                                               </Grid>
                                                               <Grid item container xs={6} justifyContent="center" alignItems="center" sx={{paddingBottom: '20px' }}>
                                                                  <div style={{ position: 'relative' }}>
                                                                     <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                                                                        <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathMain4(e) }}
                                                                           style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                                        />
                                                                     </div>
                                                                     {saveImageMain4.preview ?
                                                                        <img src={saveImageMain4.path} alt='' width="260px" height='180px' />
                                                                        :
                                                                        <img src={`${API_URL_BASE}/${sectionMain.img_main3.ruta}`} alt='' width="260px" height='180px' />
                                                                     }                                                                  </div>
                                                               </Grid>
                                                            </Grid>
                                                         </Box>
                                                      </tr>
                                                   </tbody>
                                                </table>
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </td>
               </tr>
            </table>}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0px 30px 10px 30px'}}>
               <button style={{ padding: '8px', cursor: 'pointer',
               background:'#1976D2', textDecoration:'none',
               border:'none',color:'#fff' }} onClick={() => {
                  closeModEdit()
                }}>
                  VOLVER
               </button>
               <button style={{ padding: '8px', cursor: 'pointer',
               background:'#1976D2', textDecoration:'none',
               border:'none',color:'#fff' }} onClick={() => { SaveEditLanding() }}>
                  EDITAR LANDING
               </button>
            </div>
         </div>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>

         <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1, bgcolor: '#fff' }}
               open={openVisualizar}
            >
               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{fontSize:'30px',color:'#000'}}><strong>Editando Landing... </strong></h1>
               </Grid>
            </Backdrop>
      </Grid>
   )
};


