import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Divider, Checkbox, InputAdornment, Backdrop, CircularProgress, Typography } from '@mui/material'
import { DownloadIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FeedIcon from '@mui/icons-material/Feed';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import CircleIcon from '@mui/icons-material/Circle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddTaskIcon from '@mui/icons-material/AddTask';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { styled } from "@mui/material/styles";
import { ModalAddAction } from "@/components/common/Modal/ModalAddAction";
import Slider, { SliderThumb } from "@mui/material/Slider";

import './Modal.sass'
import { accionService } from '@/service/services/Accion.service';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';

type ModalProps = {
   openDetailsEvent: boolean,
   setOpenDetailsEvent: any,
   actionEdit: any,
   setActionEdit: any,
   actionUpdate: ({ }) => void,
   sentDateData?: any,
   setSentDateData?: any,
   actionDelete: any
}
export const ModalDetailsAction: React.FC<ModalProps> = ({
   openDetailsEvent,
   setOpenDetailsEvent,
   actionEdit,
   setActionEdit,
   actionUpdate,
   sentDateData,
   setSentDateData,
   actionDelete
}): JSX.Element => {
   const [taskcompleteda, setTaskCompletada] = useState<boolean>(false);
   const [open, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [dataProgreso, setDataProgreso] = useState<any>({
      initialprogreso:0,
      progreso:0,
      comentario:''
   })
   const timer = React.useRef<number>();

   useEffect(() => {
      progresoAccion(actionEdit?.id);
   }, [actionEdit])


   const progresoAccion = async (idaction) => {
      if (idaction !== undefined) {
         const resp = await accionService.getActionByProgress(idaction);
         // setProgreso(resp.data.progreso[0].progresoValor);
         setDataProgreso(prev => ({ ...prev, progreso: resp.data.progreso[0].progresoValor ,initialprogreso: resp.data.progreso[0].progresoValor }));
         if(resp.data.progreso[0].progresoValor==100){
            setTaskCompletada(true)
         }else{
            setTaskCompletada(false)
         }
      }
   }

   const completarTarea = async () => {

      const id=actionEdit?.id;
      let estado;
      if (!loading) {
         setLoading(true);

         timer.current = window.setTimeout(() => {

           setLoading(false);
         }, 2000);
       }

     // console.log(taskcompleteda)
      if(taskcompleteda==true){
         const resp = await accionService.actionCompleted(id,0);
         //console.log(resp)
      }
      else if(taskcompleteda==false){
         const resp = await accionService.actionCompleted(id,1);
        // console.log(resp)
      }

      setTaskCompletada(!taskcompleteda);

      // if(taskcompleteda)
      // {
        // estado=1;
      // }
      // if(!taskcompleteda){
       //  estado=0;
      // }

      //  const resp = await accionService.actionCompleted(id,estado);
      //  console.log(resp)
   }

   const handleProgress = (event: any) => {
      const dataProgress = (event.target.value);
      const name = event.target.name;
      switch(name)
      {
         case 'progreso':
         setDataProgreso(prev => ({ ...prev, progreso: dataProgress }));
         break;
         case 'comentario':
         setDataProgreso(prev => ({ ...prev, comentario: dataProgress }));
         break;
      }
      if(name=='progreso' && dataProgress==100){
         setTaskCompletada(true)
      }else{
         setTaskCompletada(false)
      }
   }

   const AddProgreso = async () =>{
      const resp = await accionService.actionProgress(actionEdit?.id,dataProgreso);

   }
   const handleProgressBlur = async(event: any) => {
      const progress = (event.target.value);
      const name = event.target.name;
      // console.log(name)
      // if(name=='progreso' || name=='comentario')
      // {
      //    //if(dataProgreso.comentario!='' && dataProgreso.progreso!=dataProgreso.initialprogreso){
      //       const resp = await accionService.actionProgress(actionEdit?.id,dataProgreso);
      //       console.log(resp);
      //    //}

      // }
   }

   return (
      <>
         {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
         >
            <CircularProgress color="inherit" />
         </Backdrop> */}
         <Modal
            open={openDetailsEvent}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClose={() => {setOpenDetailsEvent(false),setOpenDetailsEvent(false)
               setActionEdit(null)}
               }
         >
            <Box sx={{
               position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '450px',
               bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24
            }}>
               <Box>
                  <Grid container direction="row" spacing={2} sx={{ p: 4 }}>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                     >
                        <Grid sx={{ mr: 1 }}>
                                 <FeedIcon color='primary' />
                        </Grid>
                        <Grid>
                           {actionEdit?.asunto}
                        </Grid>
                     </Grid>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ pt: 2 }}
                     >
                        <DateRangeIcon color='success' sx={{ mr: 1 }} />
                        <Grid >
                           {`${actionEdit?.fecha_inicio} ${actionEdit?.hora_inicio}  /--/ ${actionEdit?.fecha_fin} ${actionEdit?.hora_fin}`}
                        </Grid>
                     </Grid>
                     {/* <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ pt: 2 }}
                     >
                        <Grid item xs={1} sx={{ mr: 1 }}>
                           <NoteAltRoundedIcon color="secondary" />
                        </Grid>
                        <Grid item xs={9} sx={{ pt: 1 }}>
                           {actionEdit?.nota}
                        </Grid>
                     </Grid> */}

                     <Grid item xs={12}>
                           <Box sx={{ mt: 1 }} />
                           <Typography gutterBottom>Gestionar Progreso</Typography>
                           <PrettoSlider
                              value={dataProgreso.progreso}
                              onChange={handleProgress}
                              name='progreso'
                              onBlur={handleProgressBlur}
                              step={20}
                              marks={marks}
                              min={0}
                              max={100}
                           />
                     </Grid>
                     <Grid item xs={12}>
                     <TextField
                              fullWidth
                              //error={error === "prioridad" ? true : false}
                              //helperText={error === "prioridad" ? " La prioridad es un campo requerido" : ""}
                              sx={{bgcolor:'#fff'}}
                              size="small"
                              id="comentario"
                              label="Comentario"
                              name="comentario"
                              type="text"
                              value={dataProgreso.comentario}
                              onChange={handleProgress}
                              onBlur={handleProgressBlur}
                              InputProps={{
                                 startAdornment: (
                                   <InputAdornment position="start">
                                     <NoteAltRoundedIcon />
                                   </InputAdornment>
                                 ),
                               }}
                           />
                     </Grid>
                  </Grid>

                  <Grid item xs={12}>
                     <Divider />
                     <Divider />
                  </Grid>
                     <Grid item container
                        xs={12}
                        height={45}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}>
                     <Grid>
                        <span ><strong>{taskcompleteda?'Completado':'Accion por Completar'}</strong></span>
                        {taskcompleteda&&(
                            !loading ? (
                              <Checkbox defaultChecked checked={taskcompleteda ? true : false}
                            // onClick={() => { completarTarea() }}
                              color="success" />
                           ) : (<CircularProgress
                              sx={{ml:1}}
                              color="success"
                              size={17}
                           />)
                        )}

                     </Grid>
                        <Grid>
                        <Button
                              onClick={() => { AddProgreso(), setOpenDetailsEvent(false)  }}
                           >
                              Guardar
                           </Button>
                           {dataUser.user.role==ROLE_ADMIN && <Button
                              onClick={() => { setOpen(true) }}
                           >
                              Editar
                           </Button>
                           }
                           {dataUser.user.role==ROLE_ADMIN &&  <Button
                           sx={{background:'#fff', width:'100px' ,color:'#bf6c00' , border:'none', '&:hover':{
                              bgcolor:'#fff', border:'1px solid #bf6c00'
                             }}}
                           onClick={() => { actionDelete(actionEdit), setOpenDetailsEvent(false) }} color='error'>
                              Eliminar
                           </Button>}
                        </Grid>
                     </Grid>

               </Box>
            </Box>
         </Modal>
         <ModalAddAction
            open={open}
            setOpen={setOpen}
            actionEdit={actionEdit}
            setActionEdit={setActionEdit}
            actionUpdate={actionUpdate}
            setOpenDetailsEvent={setOpenDetailsEvent}
         />
      </>
   );
}
const marks = [
   {
     value: 0,
     label: "0%"
   },
   {
     value: 20,
     label: "20%"
   },
   {
     value: 40,
     label: "40%"
   },
   {
     value: 60,
     label: "60%"
   },
   {
     value: 80,
     label: "80%"
   },
   {
      value: 100,
      label: "100%"
    }

 ];

const PrettoSlider = styled(Slider)({
   color: "#1678F2",
   height: 8,
   "& .MuiSlider-track": {
     border: "none"
   },
   "& .MuiSlider-thumb": {
     height: 24,
     width: 24,
     backgroundColor: "#fff",
     border: "2px solid currentColor",
     "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
       boxShadow: "inherit"
     },
     "&:before": {
       display: "none"
     }
   },
   "& .MuiSlider-valueLabel": {
     lineHeight: 1.2,
     fontSize: 12,
     background: "unset",
     padding: 0,
     width: 32,
     height: 32,
     borderRadius: "50% 50% 50% 0",
     backgroundColor: "#478de8",
     transformOrigin: "bottom left",
     transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
     "&:before": { display: "none" },
     "&.MuiSlider-valueLabelOpen": {
       transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
     },
     "& > *": {
       transform: "rotate(45deg)"
     }
   }
 });
