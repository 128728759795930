import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '@service/services/Authentication.service';
import { ROUTE_ACTIVIDADES, ROUTE_ENTITY, ROUTE_HOME, ROUTE_LOGIN, ROUTE_PROGRAMATION } from '@toolbox/constants/route-map';
import { ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_SUPER_ADMIN, ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';




export const PrivateRoute = ({ component: Component, ...rest }) => (
   <Route {...rest} render={props => {
      const currentUser = authenticationService.currentUserValue;
      const authCookie  = authenticationService.authCookie();

      if (!currentUser || !authCookie) {
         return <Redirect {...props} to={{ pathname: ROUTE_LOGIN, state: { from: props.location } }} />
      }
      return <Component {...props} />
   }} />
)

export const AuthRoute = ({ component: Component, ...rest }) => (


   <Route {...rest} render={props => {
      const currentUser = authenticationService.currentUserValue;
      const authCookie  = authenticationService.authCookie();
      const dataUser= readLocalStorage(KEY_USER_DATA);


      if (currentUser && authCookie) {
         return(
            <>
            {dataUser.user.role==ROLE_SUPER_ADMIN&&
               <Redirect to={{ pathname: ROUTE_PROGRAMATION, state: { from: props.location } }} />}
            {dataUser.user.role==ROLE_ADMIN&&
               <Redirect to={{ pathname: ROUTE_HOME, state: { from: props.location } }} />}
            {dataUser.user.role==ROLE_SUPERVISOR&&
               <Redirect to={{ pathname: ROUTE_HOME, state: { from: props.location } }} />}
            {dataUser.user.role==ROLE_VENDEDOR&&
               <Redirect to={{ pathname: ROUTE_HOME, state: { from: props.location } }} />}
            </>
         )

      }
      return <Component {...props} />
   }} />
)
