import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { FormControl, Autocomplete, Select, MenuItem, FormHelperText, Container, Divider, Grid, InputLabel, Typography, TextField, Button, Alert, Snackbar, IconButton, Card, Backdrop, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBackIconv2, PencilIcon, CancelIcon, SaveIcon, EditContactIcon } from "@toolbox/constants/icons";
import { contactService } from '@/service/services/Contact.service';
import { ubigeoService } from '@/service/services/Ubigeo.service';
import { useHistory } from 'react-router-dom'
import { ROUTE_CAMPAIGN, ROUTE_CONTACT } from '@toolbox/constants/route-map';
import { DataClients } from '@service/models/Contact'
import { Data } from '@/service/models/User';
import { TabsComponent } from '@/components/common/Tabs';
import { ConfirmDialog } from '@/components/common/DialogConfirm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CardActividad } from '../Create/Contact/components/Card';
import { FormTask } from '../Create/Contact/components/Form';
import { campaingService } from '@/service/services/Campaing.service';
import { taskService } from '@/service/services/Task.service'
import { makeStyles } from "@mui/styles";
import { SpinnerGrow } from '@/components/common/Spinner';
import logokyte from "@assets/img/vacio.png";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ModalDetalleCompra } from "@/components/common/Modal/ModalDetalleCompra";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA, KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ModalAddTaskAndCall } from '@/components/common/Modal';
import moment from 'moment';

const initialFormValuesContact = {
   rut_cliente: '',
   idtipopersona: '',
   idtiporubro: '',
   nombre_rz: '',
   nombre_fantasia: '',
   giro: '',
   fax: '',
   prospecto: '',
   observacion: '',
   extranjero: '',
   web: '',
   direccion: '',
   cto_cargo: '',
   cto_email: '',
   cto_telefono: '',
   cto_celular: '',
   cto_cobranza: '',
}
const labels = [
   { label: 'Tareas' },
   { label: 'Llamadas entrantes' }
]
export const DetailCampaignClientsGeneralView: React.FC<any> = (props): JSX.Element => {

   const dataProps = JSON.parse(props.location.state)
   const history = useHistory();
   const [error, setError] = useState<string>("");
   const [openView, setOpenView] = useState(false);
   const [name, setName] = useState<string>("");
   const [taskContact, setTasksContact] = useState<any>([]);
   const [taskContactCall, setTasksContactCall] = useState<any>([]);
   const [formValues, setFormValues] = useState<any>([]);
   const [isDisabled, setIsDisabled] = useState<boolean>(true);
   const [filter, setFilter] = useState(null);
   const [taskContactNotCompleted, setTasksContactNotCompleted] = useState<any>([{}]);
   const [dataFilter, setDataFilter] = useState<any>([]);
   const [contactos, setContactos] = useState<any>({});
   const [Received, setReceived] = useState(false);
   const [openAddTask, setOpenAddTask] = useState(false);
   const [campaingDataBoolean, setCampaingDataBoolean] = useState(false);
   const [statusLeads, setStatusLeads] = useState(true);
   const [datacontactos, setDataContactos] = useState<any>([]);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      taskId: null,
      message: ``
   })
   const [userSales, setUserSales] = useState<any>({});

   const useStyles = makeStyles({
      customDisable: {
         "& .MuiInputBase-input.Mui-disabled": {
            color: "red !important",
            "-webkit-text-fill-color": "black !important",
            borderColor: "red !important"
         },
      }
   });

   const classes = useStyles();
   useEffect(() => {
      if (dataProps && !dataProps.idcliente && dataProps.form_cliente) {
         getInputs(dataProps)
         getFeedsClientLeads(dataProps)
         getCallsForm_Client(dataProps?.form_cliente)
      }
      if (dataProps && dataProps.idcliente) {
         getFeedsClient(dataProps)
         getCallsClient(dataProps?.idcliente)
      }
      getAsingnadoClient();
   }, [])


   async function getInputs(data) {
      const resp = await campaingService.getInputsDetailsCampaignLeads(data)
      if (resp) {
         const objname = resp.data.inputs.filter(data => data.input == 'Nombre' || 'nombre');
         setName(objname[0].value);
         setFormValues(resp.data.inputs)
      }
      setOpenView(false)
   }

   const getAsingnadoClient = async () => {
      if (dataProps.idcliente) {
         const resp = await contactService.getContatosbyCliente(dataProps.idcliente);
         console.log(resp)
         setContactos(resp.data)
         setDataContactos(resp.data.contactos)
      }
   }
   // async function getFeedsClient(idcliente) {
   //    setOpenView(true)
   //    const resp = await taskService.getFeedsByClient(idcliente)
   //    if (resp) {
   //       console.log(resp)
   //    }
   //    setTasksContact(resp.data?.Completados)
   //    setFilter(0)
   //    setDataFilter(resp.data?.NoCompletados);
   //    setTasksContactNotCompleted(resp.data?.NoCompletados)
   //    setOpenView(false)
   // }
   async function getFeedsClient(data) {
      setOpenView(true)
      const resp = await taskService.getFeedsByClientAndCampaign(data)
      if (resp) {
         console.log(resp)
      }
      setTasksContact(resp.data?.Completados)
      setFilter(0)
      setDataFilter(resp.data?.NoCompletados);
      setTasksContactNotCompleted(resp.data?.NoCompletados)
      setOpenView(false)
   }

   async function getFeedsClientLeads(data) {
      setOpenView(true)
      const resp = await taskService.getFeedsByClientLead(data)
      setTasksContact(resp.tareas?.Completados)
      setFilter(0)
      setDataFilter(resp.tareas?.NoCompletados);
      setTasksContactNotCompleted(resp.tareas?.NoCompletados)
   }

   async function getCallsClient(idcliente) {
      setOpenView(true)
      const resp = await taskService.getFeedsCallByClient(idcliente)
      if (resp) {
         console.log(resp)
         setTasksContactCall(resp.data)
      }
   }

   async function getCallsForm_Client(form_Cliente) {
      setOpenView(true)
      const resp = await taskService.getFeedsCallByForm_Client(form_Cliente)
      if (resp) {
         console.log(resp)
         setTasksContactCall(resp.data)
      }
   }

   const updateFeeds = (data) => {
      const changeFeeds = dataFilter.map((value) =>
         value.id === data.id ? data : value
      );
      setFilter(changeFeeds)
   }

   const idTask = (taskId) => {
      setDialog(prev => ({ ...prev, message: `¿Desea eliminar la tarea?`, open: true, taskId: taskId, confirm: true }));
   }

   const handleTask = (e) => {
      setFilter(e.target.value)
      switch (e.target.value) {
         case 0:
            setDataFilter(taskContactNotCompleted);
            break;
         case 1:
            setDataFilter(taskContact);
            break;
      }
   }

   const taskAdd = async (task) => {
      try {
            if (dataProps.idcliente) {
               const resp = await taskService.createFeeds({
                  idtipofeeds: task.idtipofeeds,
                  idprioridad: task.idprioridad,
                  idusuario: dataProps.idusuario,
                  idcliente: dataProps.idcliente,
                  horainicio: task.horainicio,
                  horafin: task.horafin,
                  comentarios: task.comentarios,
                  idcampananegocio: dataProps.idcampananegocio,
                  form_cliente: dataProps.form_cliente,
                  idfrecuencia: task.idfrecuencia,
                  fechacreacion: task.fechacreacion,
                  fechacierre: task.fechacierre,
                  propietario: task.propietario,
                  received: task.received,
               });
               if (resp.data) {
                  getFeedsClient(dataProps)
                  getCallsClient(dataProps?.idcliente)

               }
            }
            else {
               const resp = await taskService.createFeeds({
                  idtipofeeds: task.idtipofeeds,
                  idprioridad: task.idprioridad,
                  idusuario: dataProps.idusuario,
                  idcliente: '',
                  horainicio: task.horainicio,
                  horafin: task.horafin,
                  comentarios: task.comentarios,
                  idcampananegocio: dataProps.idcampananegocio,
                  form_cliente: dataProps.form_cliente,
                  idfrecuencia: task.idfrecuencia,
                  fechacreacion: task.fechacreacion,
                  fechacierre: task.fechacierre,
                  propietario: task.propietario,
                  received: task.received,
               });
               if (resp.data) {
                  getFeedsClientLeads(dataProps)
                  getCallsForm_Client(dataProps?.form_cliente)
               }
            }
            setOpenAddTask(false);
         setOpenView(false)
      } catch (e) {
         console.log(e)
      }

   };

   const formulario = <form >
      <Grid item container direction="row" spacing={2} >
         {formValues.map((value) => {
            return (<Grid item xs={12} md={12}>
               <TextField
                  size='small'
                  id='cto_email'
                  name='cto_email'
                  label={value.input}
                  fullWidth
                  sx={{ bgcolor: '#E3F4FD' }}
                  error={error === "cto_email" ? true : false}
                  helperText={error === "cto_email" ? "Email es un campo requerido" : ""}
                  value={value.value}
                  className={classes.customDisable}
                  disabled={isDisabled}
               />
            </Grid>)
         })}
      </Grid>
   </form>

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Grid container>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>

            <>
               <Grid container sx={{ mb: 2 }}>
                  <Button
                     startIcon={<ArrowBackIcon />}
                     variant='text'
                     sx={{
                        background: '#E4980D', color: '#fff', '&:hover': {
                           bgcolor: '#E4980D'
                        }
                     }}
                     onClick={() => { history.push(ROUTE_CAMPAIGN) }}
                  >
                     Volver
                  </Button>
               </Grid>
               <Grid container>
                  <Grid
                     item
                     xs={12}
                     md={3}
                     flexDirection="row"
                     spacing={1}
                     sx={{ pl: 4, pr: 2, pb: 2, maxHeight: '73vh', overflowY: 'scroll' }}
                  >
                     <Grid item >
                     </Grid>
                     <Grid item sx={{ mb: 4 }}>
                        <Typography variant="h6" sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>Acerca del Prospecto</Typography>
                     </Grid>

                     {formulario}
                     {
                        dataProps.idcliente && (
                           <div>
                              <Grid item sx={{ mb: 2 }}>
                                 <Typography variant="h6" sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>Cliente Enlazado</Typography>
                              </Grid>
                              <form >
                                 <Grid item container direction="row" spacing={2} >
                                    <Grid item xs={12} md={12}>
                                       <TextField
                                          size='small'
                                          id='rut_cliente'
                                          name='rut_cliente'
                                          label="Rut Cliente"
                                          sx={{ bgcolor: '#E3F4FD' }}
                                          fullWidth
                                          // error={error === "rut_cliente" ? true : false}
                                          // helperText={error === "rut_cliente" ? "Rut cliente es un campo requerido" : ""}
                                          InputLabelProps={{ shrink: true }}
                                          value={contactos.rut_cliente}
                                          // onChange={handleInputChange}
                                          className={classes.customDisable}
                                          disabled={isDisabled}
                                       />
                                    </Grid>
                                   {contactos.nombre_rz && <Grid item xs={12} md={12}>
                                       <TextField
                                          size='small'
                                          id='nombre_rz'
                                          name='nombre_rz'
                                          label="Razón Social"
                                          sx={{ bgcolor: '#E3F4FD' }}
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          value={contactos.nombre_rz}
                                          className={classes.customDisable}
                                          disabled={isDisabled}
                                       />
                                    </Grid >}
                                    {contactos.nombre_fantasia && <Grid item xs={12} md={12}>
                                       <TextField
                                          size='small'
                                          id='nombre_fantasia'
                                          name='nombre_fantasia'
                                          label="Nombre Fantasia"
                                          sx={{ bgcolor: '#E3F4FD' }}
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          value={contactos.nombre_fantasia}
                                          className={classes.customDisable}
                                          disabled={isDisabled}
                                       />
                                    </Grid >}
                                   {contactos.email && <Grid item xs={12} md={12}>
                                       <TextField
                                          size='small'
                                          id='email'
                                          name='email'
                                          label="Email"
                                          sx={{ bgcolor: '#E3F4FD' }}
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          value={contactos.email}
                                          className={classes.customDisable}
                                          disabled={isDisabled}
                                       />
                                    </Grid >
}
                                  {contactos.telefono &&  <Grid item xs={12} md={12}>
                                       <TextField
                                          size='small'
                                          id='telefono'
                                          name='telefono'
                                          label="Telefono"
                                          sx={{ bgcolor: '#E3F4FD' }}
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          value={contactos.telefono}
                                          className={classes.customDisable}
                                          disabled={isDisabled}
                                       />
                                    </Grid >}
                                 </Grid>
                              </form>
                              <div>
                                 <Grid item container direction="row" xs={12} sx={{ mt: 2 }}>
                                    <span style={{ color: '#155172', fontWeight: 540, fontSize: '1.0em', }}>
                                       MIS CONTACTOS
                                    </span>
                                 </Grid>
                                 {datacontactos.length > 0 ? (
                                    datacontactos.map((value, i) => {

                                       return (
                                          <Grid key={i} item xs={12} container sx={{ height: 'auto', mt: 1, p: 1, border: '3px solid #0C879C', borderRadius: '4px' }}>
                                             <Grid item xs={12}>
                                                <Typography sx={{ color: '#155172', fontWeight: '700' }}>
                                                   <span style={{ color: '#155172' }}>{i + 1}-</span>
                                                   {`  ${value.nombre}`}
                                                </Typography>
                                             </Grid>
                                             <Divider />
                                             <Grid item xs={12} >
                                                <Typography> <span style={{ fontWeight: 500, color: '#155172' }}>Email:</span>{` ${value.email}`} </Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                <Typography> <span style={{ fontWeight: 500, color: '#155172' }}>Celular:</span>{` ${value.celular}`}</Typography>
                                             </Grid>
                                             <Grid item xs={12}>
                                                <Typography>
                                                   <span style={{ fontWeight: 500, color: '#155172', }}>Cargo:</span>{` ${value.cargo}`}
                                                </Typography>

                                             </Grid>
                                          </Grid>
                                       )
                                    })
                                 ) : (
                                    <Grid item xs={12} container sx={{ height: 'auto', border: '3px solid #0C879C', borderRadius: '4px' }}>
                                       <Grid item xs={12} >
                                          <Typography> <span style={{ fontWeight: 500, color: '#155172' }}>
                                             SIN CONTACTOS
                                          </span>
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 )}
                              </div>
                           </div>
                        )
                     }
                  </Grid>
                  <Grid item xs={12} md={6} container sx={{ pl: 2, maxHeight: '73vh', overflowY: 'scroll' }}>
                     <TabsComponent
                        color='#fff'
                        labels={labels}
                        childrenOne={
                           taskContact.length != 0 || taskContactNotCompleted.length != 0 ?
                              <div>
                                 <div style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    paddingRight: '15px'

                                 }}>
                                    <div>Filtro</div>
                                    <Select
                                       id="idprioridad"
                                       sx={{ bgcolor: '#fff', width: '200px', ml: 1 }}
                                       size="small"
                                       name="idprioridad"
                                       value={filter}
                                       onChange={handleTask}
                                    >
                                       <MenuItem value={0}>No Completadas</MenuItem>
                                       <MenuItem value={1}>Completadas</MenuItem>
                                    </Select>
                                 </div>
                                 {
                                    dataFilter.map((value, i) => {
                                       return (
                                          <div key={i}>
                                             <CardActividad
                                                data={value}
                                                userSales={userSales}
                                                updateFeeds={updateFeeds}
                                                client={name} />
                                          </div>
                                       )
                                    })
                                 }
                              </div> :
                              <Card sx={{ height: '50vh' }}>
                                 <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    pt={3}
                                    alignItems="center">
                                    <Grid item >
                                       <Box
                                          component="img"
                                          sx={{
                                             height: " 200px"
                                          }}
                                          src={logokyte}>
                                       </Box>
                                    </Grid>
                                    <Grid item >
                                       <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>No existen Tareas pendientes hacia este Cliente.</Typography>
                                    </Grid>

                                 </Grid>
                              </Card>
                        }
                        childrenTwo={
                           taskContactCall.length != 0 ?
                                 taskContactCall.map((value, i) => {
                                 return (
                                    <div key={i}>
                                       <CardActividad data={value} idTask={idTask} />
                                    </div>
                                 )
                              })
                               :
                              <Card sx={{ height: '50vh' }}>
                                 <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    pt={3}
                                    alignItems="center">
                                    <Grid item >
                                       <Box
                                          component="img"
                                          sx={{
                                             height: " 200px"
                                          }}
                                          src={logokyte}>
                                       </Box>
                                    </Grid>
                                    <Grid item >
                                       <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>No existen llamadas entrantes de este Cliente.</Typography>
                                    </Grid>

                                 </Grid>
                              </Card>
                        }
                     ></TabsComponent>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     md={3}
                     container
                     direction="row"
                     spacing={1}
                     sx={{ pl: 4, pr: 2, pb: 2, maxHeight: '73vh', overflowY: 'scroll' }}
                  >

                     <Grid item xs={12} textAlign={'center'}>
                        <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.1rem' }}>Llamadas Entrantes</Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Button size='small'
                           fullWidth
                           sx={{
                              background: '#0C879C', color: '#fff', '&:hover': {
                                 bgcolor: '#0C879C'
                              }
                           }}
                           onClick={() => {
                              setReceived(true)
                              setOpenAddTask(true)
                              setCampaingDataBoolean(true)
                           }}
                        >Agregar</Button>
                     </Grid>
                     <Grid item xs={12}>
                        <span> Ve todas las llamadas con tu cliente desde un solo lugar. Registra datos relevantes de la llamada.</span>
                     </Grid>
                     <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                        <Divider />
                     </Grid>

                     <Grid item xs={12} textAlign={'center'}>
                        <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.1rem' }}>Tareas</Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Button size='small'
                           fullWidth
                           sx={{
                              background: '#E4980D', color: '#fff', '&:hover': {
                                 bgcolor: '#E4980D'
                              }
                           }}
                           onClick={() => {
                              if (dataProps.idusuario) {
                                 setOpenAddTask(true)
                                 setCampaingDataBoolean(true)
                              } else {
                                 setSnackBarConfig(prev => ({
                                    ...prev,
                                    open: true,
                                    severity: 'warning',
                                    message: 'Para crear una tarea se debe asignar un vendedor a este prospecto',
                                 }));
                              }
                           }}
                        >Crear Tareas</Button>
                     </Grid>
                     <Grid item xs={12}>
                        <span>En la seccion de Actividades puedes visualizar todas las tareas pendientes.</span>
                     </Grid>
                     <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                        <Divider />
                     </Grid>
                  </Grid>
               </Grid>
            </>
         </Grid>
         <ModalAddTaskAndCall
            taskAdd={taskAdd}
            open={openAddTask}
            setOpen={setOpenAddTask}
            Received={Received}
            setReceived={setReceived}
            campaingDataBoolean={campaingDataBoolean}
            statusLeads={statusLeads}
         />
      </Protected>
   )
};
