import React, { useEffect, useState } from 'react';
import * as qs from 'qs'

import { Button, TextField, Paper, Typography, CircularProgress, Divider, Grid, Container, createTheme } from '@mui/material';
import { ArriendosService } from '@/service/services/Arriendos.service.';
import { ThemeProvider, makeStyles } from '@mui/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#007bff',
      },
      text: {
        secondary: '#555',
      },
    },
  });

  const paperStyle = {
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const dividerStyle = {
    margin: '20px 0',
    backgroundColor: '#FFC107',
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: '#2196F3',
    color: 'white',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
    border: 'none',
    outline: 'none',
    ':hover': {
      backgroundColor: '#1565c0',
    },
  };


export const PagoArriendosPublic: React.FC<any> = (props: any): JSX.Element => {


    const ruta = props.location.search + '.';
    const { codigo } = props.location && qs.parse(ruta.slice(1, -1));

  const [loading, setLoading] = useState(true);
  const [rentalData, setRentalData] = useState(null);
  const [garantiaData, setGarantiaData] = useState(null)
  const [paymentCode, setPaymentCode] = useState('');
  const [paymentImage, setPaymentImage] = useState(null);
  const [isConfirmation, setIsConfirmation] = useState(false)
  // Función para cargar la información del arriendo basada en el código
  const fetchRentalData = async () => {
    try {
     
    const response = await ArriendosService.listarArriendosByCodePublicMonthly(codigo);
    
        console.log(response)
      setRentalData(response?.data.arriendos[0]);
      setGarantiaData(response?.data?.mensualidad)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching rental data:', error);
      // Manejar el error según tus necesidades
    }
  };

  // Función para confirmar el arriendo con el código de pago y la foto del código de pago
  const confirmRental = async () => {
    try {
      // Lógica para enviar el código de pago y la foto a la API para confirmar el arriendo
      const formData = new FormData();
      formData.append('codigo', codigo);
      formData.append('cod_pago', paymentCode);
      formData.append('img_pago', paymentImage);

      
      const response = await ArriendosService.pagarMensualidad(formData);
      console.log(response)  
      if(!!response?.status){
            console.log(response)
            setIsConfirmation(true)
        }
    } catch (error) {
      console.error('Error confirming rental:', error);
      // Manejar el error según tus necesidades
    }
  };

  useEffect(() => {
    fetchRentalData();
  }, []); // Se ejecuta solo en el montaje inicial


  return (
    <div style={{ padding: '20px' }}>
    {loading ? (
      <CircularProgress />
    ) : (
        !isConfirmation ?
        <Paper elevation={3} style={paperStyle}>
        <Typography variant="h4" gutterBottom style={{ color: '#2196F3' }}>
          Detalles del Arriendo
        </Typography>
        <Divider style={dividerStyle} />
  
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Producto: {rentalData.codigo_producto}
            </Typography>
            <Typography variant="body1">Precio por Día: ${rentalData.precio_dia}</Typography>
            <Typography variant="body1">Cliente: {rentalData.nombre_rz}</Typography>
            <Typography variant="body1">Fecha de Inicio: {rentalData.fecha_arriendo}</Typography>
            <Typography variant="body1">
              Probable Devolución: {rentalData.fecha_probable_devolucion}
            </Typography>
          </Grid>
        </Grid>
  
        <Divider style={dividerStyle} />
  
        <Typography variant="h4" gutterBottom style={{ color: '#2196F3' }}>
          Detalles de Pago
        </Typography>
        <Divider style={dividerStyle} />
  
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Mensualidad a pagar
            </Typography>
            <Typography variant="body1">Monto a pagar: ${garantiaData.monto}</Typography>
            <TextField
              label="Código de Pago"
              variant="outlined"
              margin="normal"
              fullWidth
              value={paymentCode}
              onChange={(e) => setPaymentCode(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setPaymentImage(e.target.files[0])}
              style={{ marginBottom: '10px' }}
            />
          </Grid>
        </Grid>
  
        <Button variant="contained" style={buttonStyle} onClick={confirmRental}>
          Registrar Pago
        </Button>
  
        <Divider style={dividerStyle} />
      </Paper> : 
      <ThemeProvider theme={theme}>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: (theme) => theme.spacing(4),
            textAlign: 'center',
            maxWidth: '600px',
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              marginBottom: (theme) => theme.spacing(2),
              fontWeight: 'bold',
              color: 'primary.main',
            }}
          >
            ¡Gracias por registrar los detalles de pago!
          </Typography>
          <Typography
            sx={{
              color: 'text.secondary',
              marginBottom: (theme) => theme.spacing(3),
            }}
          >
            Hemos recibido los detalles de pago. Tu pago será confirmado pronto.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="#"
            sx={{
              textTransform: 'none',
            }}
          >
            Ver detalles del pago
          </Button>
        </Paper>
      </Container>
    </ThemeProvider>
    )}
  </div>
  );
};

