import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const accionRepository = {
   getAccionData: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const accion = await http.get<any>(`${API_URL_BASE}/v1/accion/data?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = accion;
      return {
         data,
         error,
         message
      }
   },
   getAccion: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const accion = await http.get<any>(`${API_URL_BASE}/v1/accion?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = accion;
      return {
         data,
         error,
         message
      }
   },
   getActionById: async (idaction): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const accion = await http.get<any>(`${API_URL_BASE}/v1/accion/idaction?idaction=${idaction}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = accion;
      return {
         data,
         error,
         message
      }
   },
   getActionByProjection: async (idproyeccion): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const accion = await http.get<any>(`${API_URL_BASE}/v1/accion/idproyeccion?idproyeccion=${idproyeccion}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = accion;
      return {
         data,
         error,
         message
      }
   },
   getActionByProgress: async (idactionprogreso): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const accion = await http.get<any>(`${API_URL_BASE}/v1/accion/idactionprogreso?idactionprogreso=${idactionprogreso}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = accion;
      return {
         data,
         error,
         message
      }
   },
   createAccion: async (dataAccion): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/accion`, {
         asunto: dataAccion.asunto,
         idarea_negocio: dataAccion.idsArea,
         trabajadores:dataAccion.idsTrabajadores,
         // idusuario: idempresacliente.user.idusuario,
         idproyeccion: dataAccion.idproyeccion,
         idhito: dataAccion.idhito,
         hito: dataAccion.hito,
         fecha_inicio: dataAccion.fecha_inicio,
         hora_inicio: dataAccion.hora_inicio,
         fecha_fin:dataAccion.fecha_fin,
         hora_fin: dataAccion.hora_fin,
         idfrecuencia:dataAccion.idfrecuencia,
         idprioridad: dataAccion.idprioridad,
         idempresa:id_empresa,
      })
      return {
         // error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   updateAction: async (id: number, dataAction): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/accion/${id}`, {
         asunto: dataAction.asunto,
         idarea_negocio: dataAction.idsArea,
         trabajadores:dataAction.idsTrabajadores,
         //idusuario: idempresacliente.user.idusuario,
         idproyeccion: dataAction.idproyeccion,
         idhito: dataAction.idhito,
         hito: dataAction.hito,
         fecha_inicio: dataAction.fecha_inicio,
         hora_inicio: dataAction.hora_inicio,
         fecha_fin:dataAction.fecha_fin,
         hora_fin: dataAction.hora_fin,
         idfrecuencia: dataAction.idfrecuencia,
         idprioridad: dataAction.idprioridad,
         id_empresa:id_empresa,
      });

      return resp;
   },
   actionCompleted: async ( id: number, data: any) : Promise<any> => {
      const actionCompleted = await http.patch<any>(`${API_URL_BASE}/v1/accion/${id}`, {
         completed: data,
      });

       return actionCompleted;
   },
   deleteAccion: async (id: number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/accion/${id}`)
      return resp;
   },
   actionProgress: async ( id: number, data: any): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/progreso/${id}`,{
         progreso:data.progreso,
         comentario:data.comentario
      })
      return resp;
   },


}
