import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './ProyeccionDetail.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, Container, Divider, Grid, InputLabel, TextField, Typography, Button, Alert, Snackbar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Backdrop, CircularProgress } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { TabsComponent } from '@/components/common/Tabs/index';
import { Box, width } from '@mui/system';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { contactService } from '@/service/services/Contact.service';
import { Link } from 'react-router-dom';
import { ROUTE_CONTACT_CREATE, ROUTE_CONTACT_UPDATE } from '@/toolbox/constants/route-map';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import Paper from '@mui/material/Paper';
import { MenuLateral } from '@/components/common/LateralMenu';
import { proyeccionService } from '@/service/services/Proyeccion.service';
import { accionService } from '@/service/services/Accion.service';
import { ModalProyectionDetails } from '@/components/common/Modal/ModalProyectionDetails';
import { ModalActionProgress } from '@/components/common/Modal/ModalActionProgress';
import { SpinnerGrow } from '@/components/common/Spinner';
import { styled } from "@mui/material/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";

export const ProyeccionDetailView: React.FC<Props> = (props: Props): JSX.Element => {
   const header = [
      { name: 'action', label: 'Accion', width: '' },
      { name: 'hito', label: 'Hito', width: '' },
      { name: 'progreso', label: 'Progreso', width: '' },
      { name: 'f_reporte', label: 'Fecha de Reporte', width: '' },
      { name: 'comentario', label: 'Comentario', width: '' }
      ];
      const headerArea = [
         { name: 'descripcion', label: 'Descripcion', width: '' }
         ];

   const labels = [{ label: 'Proyección' }, { label: 'Acciones' }]

   const Action = [
      { name: 'view', color: 'success' }
   ]
   const [open, setOpen] = useState(false);
   const [detalleProyeccion, setDetalleProyeccion] = useState<any>([]);
   const [detalleArea, setDetalleArea] = useState<any>([]);
   const [dataProgresoProyeccion, setDataProgresoProyeccion] = useState<any>([]);
   const [actionProgress, setActionProgress] = useState<any>();
   const [openProgress, setOpenProgress] = useState<boolean>(false);



   const RecuperarData = async (data) => {
      const resp = await accionService.getActionByProjection(data.idproyeccion);
      setDetalleProyeccion(resp.data);
      const Areas=[];
      const Areasid=[];
      let sumProgreso=0;

      resp.data.map((value)=>{
         console.log(value)
         sumProgreso=sumProgreso+value.progresoValor;
         value.idarea_negocio.map((row)=>{
            if(!Areasid.includes(row.idarea_negocio))
            {
               Areas.push(row)
               Areasid.push(row.idarea_negocio)
            }
         })
      })
      const PromedioProyeccion=Math.round(sumProgreso/resp.data.length);
      setDataProgresoProyeccion(PromedioProyeccion)
     setDetalleArea(Areas)
   }


const RecuperarDataAction = async(data) =>{


   if(data.action=='view'){

      const resp = await accionService.getActionByProgress(data.id);

      setActionProgress(resp.data);
      setOpenProgress(true)
   }

}
   useEffect(() => {
      RecuperarData(props.data)
   }, [props.data])



   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <Grid container flexDirection='row' spacing={2}>
               {/* <Grid item md={3} container>
                  <MenuLateral/>
               </Grid> */}
               <Grid item md={12}
               // sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #1976D2 ', mt: 3 }}
               >
                  <Grid container >
                     <Grid item xs={12} >
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.5em' }}>Detalle Progreso {props.data.nombre}</Typography>
                     </Grid>
                     <Grid item xs={4} mt={2} sx={{bgcolor:'#F3F3F3',p:1}}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.1em' }}>Areas de Negocio Involucradas en el Proyecto </Typography>
                      <Grid item sx={{mt:3}}>
                      <ul>
                           {
                           detalleArea.map((value,i)=>{
                              return (
                              <li key={i}><Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.0' }}>{i+1}. {value.descripcion}</Typography></li>
                              )
                           })
                           }
                        </ul>
                      </Grid>
                        {/* <TableData
                           header={headerArea}
                           data={detalleArea}
                           action={null}
                        /> */}
                     </Grid>
                     <Grid item xs={7} mt={2} ml={4}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5em', textAlign:'center',mb:4}}>Progreso Total del Proyecto ({dataProgresoProyeccion}%) </Typography>
                     <PrettoSlider
                             value={dataProgresoProyeccion}
                              //onChange={handleProgress}
                              name='progreso'
                             // onBlur={handleProgressBlur}
                              step={20}
                              //disabled
                              marks={marks}
                              min={0}
                              max={100}
                           />
                     </Grid>
                     <Grid item xs={12} mt={2}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5em' }}>Acciones del Proyecto </Typography>
                        <TableData
                           header={header}
                           data={detalleProyeccion}
                           action={Action}
                           RecuperarData={RecuperarDataAction}
                        />
                     </Grid>
                  </Grid>

               </Grid>
            </Grid>
         </Container>
         {/* <ModalActionProgress
         open={openProgress}
         setOpen={setOpenProgress}
         action={actionProgress}
         /> */}
         <ModalProyectionDetails
         open={openProgress}
         setOpen={setOpenProgress}
         action={actionProgress}
         />
      </Protected>
   )
};


const marks = [
   {
     value: 0,
     label: "0%"
   },
   {
      value: 10,
      label: "10%"
    },
    {
      value: 20,
      label: "20%"
    },
   {
     value: 30,
     label: "30%"
   },
   {
     value: 40,
     label: "40%"
   },
   {
      value: 50,
      label: "50%"
    },
   {
     value: 60,
     label: "60%"
   },
   {
      value: 70,
      label: "70%"
    },
   {
     value: 80,
     label: "80%"
   },
   {
      value: 90,
      label: "90%"
    },
   {
      value: 100,
      label: "100%"
    }

 ];

const PrettoSlider = styled(Slider)({
   color: "#1678F2",
   height: 8,
   "& .MuiSlider-track": {
     border: "none"
   },
   "& .MuiSlider-thumb": {
     height: 24,
     width: 24,
     backgroundColor: "#fff",
     border: "2px solid currentColor",
     "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
       boxShadow: "inherit"
     },
     "&:before": {
       display: "none"
     }
   },
   "& .MuiSlider-valueLabel": {
     lineHeight: 1.2,
     fontSize: 12,
     background: "unset",
     padding: 0,
     width: 32,
     height: 32,
     borderRadius: "50% 50% 50% 0",
     backgroundColor: "#478de8",
     transformOrigin: "bottom left",
     transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
     "&:before": { display: "none" },
     "&.MuiSlider-valueLabelOpen": {
       transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
     },
     "& > *": {
       transform: "rotate(45deg)"
     }
   }
 });
