import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const chatRepository = {
   getChats: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/chats?idempresa=${id_empresa}`);
      return resp;
   },

   getChatsVigents: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/chats/vigents?idempresa=${id_empresa}`);
      return resp;
   },

   getChatsByUsers: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/chats/getChatbyUser?idusuario=${id_usuario}`);
      return resp;
   },

   getDetailChats: async (idChat) : Promise<any> => {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/detailChat/${idChat}`);
      return resp;
   },

   sendMessage: async (idChat: number, data) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/detailChat/${idChat}`, {
         ...data,
         idusuario: id_usuario
      });
      return resp;
   },
   sendMessageImage: async (idChat: number, data) : Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/detailChat/${idChat}`, data);
      return resp;
   },

   createParticipantChat: async (data) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/participantsChat`, {
         ...data,
         idusuario: id_usuario
      });
      return resp;
   },

   closeChat: async (data) : Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/chats/close`, {
         ...data,
      });
      return resp;
   },

}

