import { Box, Button, Modal, Paper, Typography } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { ArriendosService } from "@/service/services/Arriendos.service.";

export const ModalGarantiaArriendo: React.FC<any> = ({
    open,
    setOpen,
    data
 }): JSX.Element => {
    console.log(data)

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
     })

    const confirmarGarantia = async (idgarantia) => {
        const response = await ArriendosService.confirmarGarantia(idgarantia);
        if(!!response){
            setOpen(false)
        }
    }

    const rechazarGarantia = async (idgarantia) => {
        const response = await ArriendosService.rechazarGarantia(idgarantia);
        if(!!response){
            setOpen(false)
        }
    }

    const bodyModal = (
        <Box className='Modal' sx={{ padding: '20px', maxWidth: '400px', margin: 'auto', backgroundColor: '#fff' }}>
            
            <Typography variant="h5" sx={{marginBottom: 3}}>Información de la Garantia</Typography>

            <Typography><strong>Estado:</strong> {data?.garantia_status_name}</Typography>
            

            {data?.status_pay != 0 &&
                <>
                    <Typography variant="subtitle1" sx={{ marginBottom: '5px' }}>Número pago: {data?.num_pago_adjunto}</Typography>
                    <Typography>Imagen Adjunta:</Typography>
                    <img src={`http://localhost:8000/${data?.img_pago}`} alt="Rental" style={{ width: '100%', marginBottom: '10px' }} />
                </>
            }

            {data?.status_pay == 1 &&
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        onClick={() => confirmarGarantia(data?.id)}
                        sx={{ marginRight: '10px' }}
                    >
                        Aceptar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<CloseIcon />}
                        onClick={() => rechazarGarantia(data?.id)}
                    >
                        Rechazar
                    </Button>
                </>
            }

        </Box>
    )


    return (
        <div>
          <Modal
            open={open}
            onClose={() => {setOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {bodyModal}
          </Modal>
        </div>
    )
 }