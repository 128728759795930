import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Card,
  Typography,
  Divider,
  Avatar,
  CircularProgress,
  Checkbox,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {Props} from './Card.type'
import { DeleteIcon, TaskIcon } from "@/toolbox/constants/icons";
import { taskService } from '@/service/services/Task.service';
import { green, pink } from '@mui/material/colors';
import CallIcon from '@mui/icons-material/Call';
import CampaignIcon from '@mui/icons-material/Campaign';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export const CardActividad: React.FC<Props> = (props:Props): JSX.Element => {

   const [taskcompleteda, setTaskCompletada] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const [visualizateTextField, setVisualizateTextField] = useState(false)
   const [newContenido, setnewContenido] = useState<any>();
   const timer = React.useRef<number>();

   useEffect(() => {
      const completed=props.data?.completed?props.data.completed:0;
      if(completed==1)
      {
         setTaskCompletada(true);
      }else{
         setTaskCompletada(false);
      }
   }, [props.data])


   const completarTarea = async () => {

      const id=props.data?.id;
      let estado;
      if (!loading) {
         setLoading(true);

         timer.current = window.setTimeout(() => {

           setLoading(false);
         }, 2000);
       }

      if(taskcompleteda==true){
         const resp = await taskService.feedsCompleted(id,0);
         //console.log(resp)
      }
      else if(taskcompleteda==false){
         const resp = await taskService.feedsCompleted(id,1);
        // console.log(resp)
      }
      setTaskCompletada(!taskcompleteda)
   }

   const handleInputChange = (e) => {
      setnewContenido(e.target.value);
   }
  // console.log
   const AddTaskComentario = async () =>{
      console.log(newContenido)
      console.log(props.data.id)
      if(newContenido.trim())
      {
      const resp = await taskService.feedsComentario(props.data.id,newContenido)
      if(resp.data)
      {
         setVisualizateTextField(false)
         setnewContenido('');
      }
      }
   }


  return (
      <Grid container sx={{mb:2}}>
         <Grid item>
            <Card sx={{ ml:2,mr:2}}>
               <Grid
               container
               flexDirection="row"
               spacing={2}
               sx={{ p:3 }}
               >
               <Grid item xs={2} md={1} justifyItems="right">
                  {props.data?.received=='0'?<TaskIcon color='secondary'/>:<CallIcon color='primary'/>}
               </Grid>
               <Grid item xs={10} md={4}>
                  <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em' }}>
                     {props.data?.received == '1'? 'Llamada entrante': 'Tarea'}
                  </Typography>
               </Grid>
               <Grid item xs={12} md={7}>
                  <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em' }} align="right">
                     Fecha: {props.data?.fechacreacion}
                  </Typography>
               </Grid>
               <Grid item xs={12} md={12}>
               <Typography variant="h5" sx={{ color: '#155172', fontWeight: '540', fontSize: '1.2em',textTransform:'uppercase'  }}>{`${props.data?.tipo }`}  {props.data?.received == '1'? ' de ': ' a '} {props.data?.nombre_cliente || props.client}</Typography>
               </Grid>
               {props.data?.nombre_campana && props.data?.nombre_campana !==null&&
               <>
                <Grid item xs={2} md={1}>
                  <Avatar sx={{ bgcolor: "#0C879C" }}>
                     <CampaignIcon />
                  </Avatar>
               </Grid>
               <Grid item xs={10} md={10} sx={{ mt: 1, ml:2 }}>
                  <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '0.9em',textTransform:'uppercase'  }}>
                   Asignado a {props.data?.nombre_campana }
                  </Typography>
               </Grid>
               </>}

               <Grid item xs={2} md={1}>
                  <Avatar sx={{ bgcolor: "#C68200" }}>
                  </Avatar>
               </Grid>
               <Grid item xs={10} md={10} sx={{ mt: 1, ml:2 }}>
                  <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '0.9em',textTransform:'uppercase' }}>
                    {props.data?.received=='1'?
                    'Atendido por ':'Asignado a '
                  }
                   {props.data?.nombre_user }
                  </Typography>
               </Grid>

               <Grid item xs={12}>
                  <Divider/>
               </Grid>
               {/* <Grid item xs={12} md={4} container spacing={2}>
                  <Grid item xs={12} md={12}>
                     <Typography sx={{fontWeight:'bold'}}>
                     Contenido
                     </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                     <Typography>{props.data?.contenido}</Typography>
                  </Grid>
               </Grid> */}
               <Grid item xs={12} md={12} container >
                  <Grid item container   direction="row" xs={12} md={12}>
                     <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em', textTransform:'uppercase'}}>
                     Comentarios
                     </Typography>
                   {props.data.completed=='0'&& <Tooltip title="Registrar Comentario" placement="right-start">
                     <IconButton onClick={()=>{setVisualizateTextField(!visualizateTextField)}} sx={{mt:-1}}>
                     <AddCircleIcon  color="success" fontSize='medium' />
                     </IconButton>
                     </Tooltip>
                     }
                    {props.data.completed=='0'&&  !visualizateTextField &&
                        <Grid container direction="row" item xs={12} md={12}>
                        <Typography sx={{ fontWeight: '400', fontSize: '1.0em',textTransform:'lowercase' }}>{props.data?.comentarios}</Typography>
                     </Grid>
                    }
                  </Grid>
                 {props.data.completed!=='0' &&(
                     <Grid container   direction="row" item xs={12} md={12}>
                     <Typography sx={{ fontWeight: '400', fontSize: '1.0em',textTransform:'lowercase' }}>{props.data?.comentarios}</Typography>
                  </Grid>
                 )}
                { visualizateTextField && (
                    <Grid container direction='row' item xs={12} >
                   <Grid item xs={9}>
                   <TextField
                       fullWidth
                    //   error={error === "comentarios" ? true : false}
                      // helperText={error === "comentarios" ? " La Nota es un campo requerido" : ""}
                       sx={{bgcolor:'#fff'}}
                       size="small"
                       label="Registrar datos de la tarea"
                       id="comentarios"
                       name="comentarios"
                       multiline
                       value={newContenido}
                       onChange={handleInputChange}
                       InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NoteAddIcon />
                            </InputAdornment>
                          ),
                        }}
                    />
                   </Grid>
                   <Grid item xs={3}>
                   <Button onClick={()=>AddTaskComentario()}>
                        Guardar
                    </Button>
                   </Grid>
                 </Grid>
                )}
               </Grid>
               {props.data?.received == '0' && <Grid item xs={12} md={4} container >
                  <Grid item xs={12} md={12}>
                     <Typography sx={{ color: '#155172', fontWeight: '540', fontSize: '1.0em',textTransform:'uppercase' }}>
                     Prioridad
                     </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                     <Typography alignContent='flex-end' sx={{ fontWeight: '540', fontSize: '1.0em' }}>{props.data?.nombre_prioridad}</Typography>
                  </Grid>
               </Grid>}
               <Grid item xs={12}>
                  <Divider/>
               </Grid>
               {props.data?.received == '0' && <Grid item xs={12} container justifyContent="end">
                     <Grid>
                        <span style={{ fontSize: '1.0em',color: '#155172' }}><strong>Completada</strong></span>
                        {!loading ? (
                           <Checkbox defaultChecked checked={taskcompleteda ? true : false} onClick={() => { completarTarea() }} color="success" />
                        ) : (<CircularProgress
                           sx={{ml:1}}
                           color="success"
                           size={17}
                        />)}
                     </Grid>
               </Grid>}
               </Grid>
            </Card>
         </Grid>
      </Grid>

  );
}
