import { mailRepository } from "../repositories/Mail.repository";

export const mailService = {
   sendMail: () => {
      return mailRepository.sendMail();
   },
   sendFeedbackOpenning: (periodId:number) =>{
      return mailRepository.sendFeedbackNotifyOpening(periodId);
   },
   sendFeedbackClosure: (memberFeedbackId: number) => {
      return mailRepository.sendFeedbackNotifyClosure(memberFeedbackId);
   },
   sendEmailLeads: (idCampaign, email) =>{
      return mailRepository.sendEmailLeads(idCampaign, email)
   },
   getCuentaCorreoImap: () =>{
      return mailRepository.getCuentaCorreoImap();
   },
   insertHistorialCorreo: (data) => {
      return mailRepository.insertHistorialCorreo(data);
   },
   getHistorialCorreo: (id_detalle) => {
      return mailRepository.getHistorialCorreo(id_detalle)
   },
   getHistorialCorreoCliente:(id_cliente) => {
      return mailRepository.getHistorialCorreoCliente(id_cliente)
   },
   updateDesarchivar:(id) => {
      return mailRepository.updateDesarchivar(id);
   },
   getCreateOrUpdateCuentaCorreoImap: (data) => {
      return mailRepository.getCreateOrUpdateCuentaCorreoImap(data);
   },
   getCorreosRecibidos: () =>{
      return mailRepository.getCorreosRecibidos();
   },
   getMailEspecificacion: (especificacion) => {
      return mailRepository.getMailEspecificacion(especificacion);
   },
   getDescargarArchivo: (attach, uid) =>{
      return mailRepository.getDescargarArchivo(attach, uid);
   },
   getBuscadorCorreo: (data) =>{
      return mailRepository.getBuscadorCorreo(data);
   },
   getBuscadorCorreoCliente: (data) => {
      return mailRepository.getBuscadorCorreoCliente(data);
   },
   getBuscadorCorreoFecha: (fecha) => {
      return mailRepository.getBuscadorCorreoFecha(fecha);
   },
   getVerifyCLient: (correo) => {
      return mailRepository.getVerifyCLient(correo);
   },
   postProspectoCliente: (data) => {
      return mailRepository.postProspectoCliente(data);
   },
   buscarCampaign: (data) => {
      return mailRepository.buscarCampaign(data);
   },
   enlazarCampana: (data) => {
      return mailRepository.enlazarCampana(data);
   },
   sendMailCotizacion: (data) => {
      return mailRepository.sendMailCotizacion(data);
   },
   sendLandingMail: (data) => {
      return mailRepository.sendLandingMail(data);
   },
   sendLandingMailMasivo: (data) => {
      return mailRepository.sendLandingMailMasivo(data);
   },
   getUltimaPlantillaCampaña: (idcampana, tipo) => {
      return mailRepository.getUltimaPlantillaCampaña(idcampana, tipo);
   },
   getVerifyPlantillaDetalleCampana: (idcampana, tipo) => {
      return mailRepository.getVerifyPlantillaDetalleCampana(idcampana, tipo);
   }
};
