import { LeadsFormView as LeadsForm} from './LeadsForm';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionSetDataCampana } from '@/redux/actions';

const mapStateToProps = ({ CampanaReducer }) => ({
   $store: {
      CampanaReducer,
   },
});
const mapDispatchToProps = (dispatch) => ({
   $action: bindActionCreators(
      {
         actionSetDataCampana: actionSetDataCampana
      },
      dispatch
   ),
});


export const LeadsFormView: any = compose(
   withRouter,
   connect(mapStateToProps, mapDispatchToProps)
)(LeadsForm);
