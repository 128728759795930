import React, { useState, useMemo, useRef, useEffect, Suspense } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Leads.type';
import { InputAdornment, IconButton, Step, StepLabel, Button, Card, CircularProgress, Grid, Container, useTheme, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, MenuItem, responsiveFontSizes, TextField,useMediaQuery } from '@mui/material'
import { ArrowBackIcon, ArrowDownIcon, CancelIcon, SaveIcon, VisibilityIcon, VisibilityOffIcon } from "@toolbox/constants/icons";
import { Icon } from '@components/common/Icon';
import User from "@assets/img/user.png";
import { Link, useHistory } from 'react-router-dom'
import * as qs from 'qs'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { ROUTE_ENTITY, ROUTE_LOGIN, ROUTE_USER } from '@toolbox/constants/route-map';
import { MenuLateral } from '@/components/common/LateralMenu';
import ConfirmEmail from "@assets/img/confirm-email.png";
import ErrorConfirmEmail from "@assets/img/error-confirm-email.jpg";
import { ConfirmationmailService } from '@/service/services/Confirmation.service';
import { bypassService } from '@/service/services/Bypass.service';

import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BYPASS } from '@/toolbox/constants/local-storage';
import SendIcon from '@mui/icons-material/Send';
import { campaingRepository } from '@/service/repositories/Campaing.repository';
import { campaingService } from '@/service/services/Campaing.service';
import { mailService } from '@/service/services/Mail.service';
import { SpinnerGrow } from '@/components/common/Spinner';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import recurso from "@assets/img/Recurso.png";
import logoAguaplanet from '@assets/img/logo-aguaplanet.png'
import recurso2 from "@assets/img/recurso_2.png"
import comp1 from "@assets/img/comp1.png"
import comp2 from "@assets/img/comp2.png"
import comp3 from "@assets/img/comp3.png"

export const LeadsView: React.FC<any> = (props): JSX.Element => {
   const history = useHistory();

   const [resp, setResp] = useState<any>(null)
   const [message, setMessage] = useState<string>('')
   const [error, setError] = useState<any>(null)
   const [dataLeadsInputs,setDataLeadsInput]= useState<any>([{}])

   const [valueCorreo, setValueCorreo] = useState<any>()
   const [formValues, setFormValues] = useState<any>([]);

   const [respMail, setRespMail ] = useState<boolean>(false)
   const [loading, setLoading ] = useState<boolean>(false)

   const [values, setValues] = useState<any>({
      idinputs: [],
      channel: '',
      idParameter: 0,
      name:0
   })

   const {idParameter,name,channel, idinputs} = props.location && qs.parse(props.location.search.slice(1, -1));

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'primary',
      message: 'Error',
      autoHideDuration: 7000,
   })

   useEffect(()=>{

  if(!!idParameter && !!channel &&  !!idinputs) {

         var decodeChannel;
         var decodeIdInputs;
        var decodePassword;

        try {
           const password = 'hola'

           decodeChannel = atob(channel);
          decodeIdInputs = idinputs;
          decodePassword = btoa(password);

          const decodeArray = decodeIdInputs.split("-")

            let arrayInputs = []

            decodeArray.map((value)=>{
            arrayInputs.push(atob(value))
           })



           setValues( prev => (
             { ...prev,  idinputs: arrayInputs,
               channel: decodeChannel,
               idParameter: idParameter,
               name: name
              }
           ))

        // authSubmit(decodeUser,decodeRutEmpresa,decodePassword);
        } catch (error){
           console.log(error)
         }
       }else{
        //  if(session && session.token){
        //    props.history.push("/inicio");
        //  }else{

        //  }
       }
       loginBypass();
       getInputs();
     }, [props.location]);


     const loginBypass = async () =>{
      const bypass = await bypassService.loginByPass('dsf', 'sdf', 'sfd');


      saveLocalStorage(KEY_BYPASS,bypass.token.original.access_token)

   }

     async function getInputs(){
        setLoading(true)
      const resp = await bypassService.getInputs()

      setDataLeadsInput(resp)
      setLoading(false)
   }

   const handleForm = async(e) => {
      e.preventDefault();
     console.log(formValues);
      const arrayForm = Object.values(formValues)

     let ruta=''

     arrayForm.map(( value)=>{
         ruta = ruta+"&"+value
     })

      const resp = await bypassService.postCrearForm(values.idParameter,values.channel,ruta)

      const insert = await campaingService.createDetailsCampaign(parseInt(values.idParameter),parseInt(values.name),resp.idForm)

     const mail:any = await mailService.sendEmailLeads(parseInt(values.name),valueCorreo)
   //   console.log(mail)

      if(mail?.data.message == 'Correo enviado con exito'){
         console.log('hola')
         setRespMail(true)
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Estaremos en contacto muy pronto, le enviamos un correo.",
         }));

      }

   }


   const handleFormValues = (e) => {
      const handleChangeFormValues = {
        ...formValues,
        [e.target.name]: e.target.name+'='+e.target.value
      }

      if(e.target.name=='Correo'){
         setValueCorreo(e.target.value)
      }

      setFormValues(handleChangeFormValues)

    }



    let arrayFiltrado = []


    values.idinputs.map((i)=>{
       dataLeadsInputs.length>1&&dataLeadsInputs.map((value)=>{

         if(i== value.name){
            arrayFiltrado.push(value)
         }
       })
    })




    const textFieldForm = (
      arrayFiltrado.map((value)=>{
         return(<Grid item xs={12}>
            <TextField
              size='small'
              id={value.id}
              required
            //   error={value.name==='RUT' && error=='rut'?true: false}
              placeholder={value.name == 'nombre'? '¿Cuál es tu nombre?': value.name == 'Telefono'? '¿Cuál es tu número de celular?':value.name == 'correo'? '¿Cuál es tu correo electrónico?': value.name}
             // sx={{bgcolor:'#E3F4FD'}}
              name={value.name}
            //   value={nombre}
              fullWidth
             onChange={handleFormValues}
            />
         </Grid>)
      })
    )



   return (
      <Container>
          <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Grid component='form'
            onSubmit={handleForm}
            container
            direction="row"
            sx={{ mt: 5 }}

         >
           { !isMobile? <Grid item lg={12} container justifyContent="center"
                  alignItems="center">
               <Box
                  component="img"
                  sx={{
                     height: " 70px",
                  }}
                  src={logoAguaplanet}>
               </Box>

            </Grid>: <></>}
            <Grid  item xs={12}>
            <Divider sx={{ height:'3px',bgcolor:'#0580EB', mb:2, mt:1}}/>
            </Grid>
            <Grid item xs={12} container flexDirection='row' mb={isMobile?1:4} ml={2}>
               <Typography sx={{ color: '#0580EB', fontWeight: '700', fontSize: isMobile?'1.6em':'2.8em', mb: isMobile?3:1}}>
                  <ArrowForwardIosIcon sx={{ color: '#F8C51C', fontSize: isMobile?'0.9em':'0.8em', mr:1}}/>
                  Consigue tu planta de osmosis inversa
               </Typography>
               <Typography sx={{ color: '#929292', fontWeight: '500', fontSize:  isMobile?'1.15em':'1.5em'}}>
                  Solo tienes que dejarnos tus datos para contactar contigo y además</Typography>
               <Typography sx={{ color: '#0580EB', fontWeight: '500', fontSize: isMobile?'1.15em':'1.5em'}}>recibirás 10% de descuento en accesorios.</Typography>
               </Grid>
           {
              !isMobile?
              <Grid item lg={7}>
              <Box
                 component="img"
                 sx={{
                    height: " 340px",
                    position: 'absolute'
                 }}
                 src={recurso}>
              </Box>
              <Box
                 component="img"
                 sx={{
                    height: " 225px",
                    position:'relative',
                    left: '25%',
                    top:'20%'
                 }}
                 src={recurso2}>
              </Box>
              <Box
                 component="img"
                 sx={{
                    height: " 100px",
                    position:'relative',
                    left: '-32%',
                    top:'20%'
                 }}
                 src={comp1}>
              </Box>
              <Box
                 component="img"
                 sx={{
                    height: " 50px",
                    position:'relative',
                    left: '-46%',
                    top:'-5%'
                 }}
                 src={comp2}>
              </Box>
              <Box
                 component="img"
                 sx={{
                    height: " 150px",
                    position:'relative',
                    left: '-55%',
                    top:'-5%',
                 }}
                 src={comp3}>
              </Box>
           </Grid>:
           <></>
           }
           {! respMail && (<Grid item xs={10} lg={4}>
               <Grid container direction="row" spacing={2} sx={{ mt: isMobile?0:1, mb: 2, ml: isMobile?1:4, mr:isMobile? 1:4 }} >
                  {textFieldForm}
               </Grid>
               <Grid item container justifyContent="center"
                  alignItems="center" sx={{ mt: 1, mb: 2, ml:4, mr:4 }}>
                      <Button
                           variant='contained'
                           type='submit'
                          // endIcon={<SendIcon />}

                           sx={{background:'#0580EB',width:'150px', fontWeight:'700',justifyContent:'center', ml: isMobile? 0:4, color:'#fff', '&:hover':{
                              bgcolor:'#0580EB'
                             }}}
                        >
                           {'Continuar'}
                        </Button>
                  </Grid>
            </Grid>)}
            { isMobile?
            <>
            <Grid item lg={12} mt={3} container justifyContent="center"
                  alignItems="center">
               <Box
                  component="img"
                  sx={{
                     height: " 50px",
                  }}
                  src={logoAguaplanet}>
               </Box>

            </Grid>
            <Grid  item xs={12}>
            <Divider sx={{height:'3px',bgcolor:'#0580EB', mb:2, mt:1}}/>
            </Grid>
            </>
            : <></>}
            {/* <Grid item lg={6} container>
               <Typography sx={{ mt: 1, mb: 2, ml:4, mr:4, color:'#3F9BCC', fontWeight: '700', fontSize: '1.4em'}}>{respMail ? '* Se le envió un correo agradeciéndole, estaremos en contacto muy pronto' : ''} </Typography>
               {respMail ? <></> : <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2, ml:4, mr:4 }} >
                  {textFieldForm}
               </Grid>}

               <Grid item container direction='row' spacing={2} sx={{ mt: 0, mb: 4, ml:4, mr:4  }} justifyContent='flex-end' alignContent='center' >
               <Grid item>
                     {respMail ?
                        <>
                           <Button  sx={{background:'#bf6c00',width:'150px',mt:2, color:'#fff', '&:hover':{
                              bgcolor:'#bf6c00'
                             }}}>
                             <a href='https://crm.kyte.cl/login' style={{textDecoration:'none', color:'white'}}>OK</a>
                           </Button>
                        </> : <Button
                           variant='contained'
                           type='submit'
                           endIcon={<SendIcon />}

                           sx={{background:'#bf6c00',width:'150px',mt:2, color:'#fff', '&:hover':{
                              bgcolor:'#bf6c00'
                             }}}
                        >
                           {'Enviar'}
                        </Button>}
                  </Grid>

               </Grid>
            </Grid>
            <Grid item lg={4}></Grid> */}
         </Grid>

      </Container>
   )
};
