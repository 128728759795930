import { Protected } from '@/components/layout/Protected';
import { Avatar, Button, Checkbox, Container, Grid, IconButton, ListItemAvatar, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Props } from './Notify.type';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { NotifyIcon, UserIcon } from '@/toolbox/constants/icons';
import { notifyService } from '@/service/services/Notify.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import './listItem.sass'
import { ModalAddNotify } from '@/components/common/Modal/ModalAddNotify';
import { userService } from '@/service/services/User.service';
import Pagination from '@mui/material/Pagination';
import { Link } from 'react-router-dom';


export const NotifyView : React.FC<Props> = ({

}) : JSX.Element => {
   const [sent, setSent]=useState<any>([])
   const [received, setReceived]=useState<any>([])
   const [notify, setNotify]=useState<any>([])
   const [open, setOpen] = useState<number>(0)
   const [openNewNotify, setOpenNewNotify] = useState<boolean>(false)
   const [dataUser,setDataUser] = useState<any>([])
   const [page, setPage] = useState <number>(1);
   const [cont, setCont] = useState <number>(1);



   useEffect(() => {
      getNotify();
      // getViewSent();
      // getViewReceived();
      // getDataUser();
   }, [page])

   //  async function getNotify() {
   //     const resp = await notifyService.getNotify();
   //       setNotify(resp.recordatorio.data)
   //     //setDataProduct(resp.data.products);

   //  }

   const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    };

    async function getNotify() {
      const resNotify= await notifyService.getNotiNoVistos(5,page)
      setNotify(resNotify.data.datos)
      setCont(Math.ceil((resNotify.data.cantidad/5)))
      //setDataProduct(resp.data.products);
   }

   //  async function getViewSent() {
   //    const data=readLocalStorage(KEY_USER_DATA)
   //    const resp = await notifyService.getViewSent(data.user.idusuario);
   //    //console.log('enviadoooo',resp.recordatorio.data)
   //    setSent(resp.recordatorio.data);
   // }
   // async function getViewReceived() {
   //    const data=readLocalStorage(KEY_USER_DATA)
   //    const resp = await notifyService.getViewReceived(data.user.idusuario);
   //    setReceived(resp.recordatorio.data);
   // }
   //  async function getDataUser() {
   //     const resp = await userService.getUser()
   //     setDataUser(resp.data)
   //  }

   // const dataList = (open) =>{
   //    if(open ==0){
   //       return received
   //    }else if(open ==1){
   //       return sent
   //    }else if(open ==2){
   //       return notify
   //    }
   // }
   // let  datalist= dataList(open)
return(
   <Protected>
    <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
       <Grid container flexDirection='row' spacing={2}>
         {/* <Grid item md={3} lg={2} container  >
            <Box sx={{ width: '100%',  bgcolor: 'background.paper' }}>
               <Button
               onClick = {()=>{setOpenNewNotify(true)}}
               variant='outlined'>CREAR</Button>
               <nav aria-label="main mailbox folders">
               <List sx={{display:{xs:'flex',md:'block'}}}>
                  <ListItem disablePadding onClick={()=>{setOpen(0)}} sx={{border:'0.5px solid #eff3f5', bgcolor:open==0?'#1976D2':'white'}}>
                     <ListItemButton className='item'>
                     <ListItemIcon>
                        <NotifyIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Recibidos"  sx={{color:open==0?'#fff':'#1976D2'}} />
                     </ListItemButton>
                  </ListItem>
                  <ListItem onClick={()=>{setOpen(1)}} disablePadding sx={{border:'0.5px solid #eff3f5', bgcolor:open==1?'#1976D2':'white'}}>
                     <ListItemButton>
                     <ListItemIcon>
                        <UserIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Enviados"  sx={{color:open==1?'#fff':'#1976D2'}}/>
                     </ListItemButton>
                  </ListItem>
                  <ListItem onClick={()=>{setOpen(2)}} disablePadding sx={{border:'0.5px solid #eff3f5', bgcolor:open==2?'#1976D2':'white'}}>
                     <ListItemButton>
                     <ListItemIcon>
                        <UserIcon/>
                     </ListItemIcon>
                     <ListItemText primary="Todos"  sx={{color:open==2?'#fff':'#1976D2'}}/>
                     </ListItemButton>
                  </ListItem>
               </List>
               </nav>
            </Box>
         </Grid> */}
            <Grid item md={12} lg={12} container >
               <List sx={{ width: '100%', bgcolor: 'background.paper', height: '70vh', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #1976D2 ' }}>
                  {notify.map((value, i) => {
                     return (
                        <>
                           <ListItem
                              key={i}
                              disablePadding
                              sx={{bgcolor:value.status=='1'?'#F5F5F5':'#fff'}}
                              component={Link}
                              to={value.tablaOrigen}
                           >
                              <ListItemButton >
                                 <ListItem alignItems="flex-start">
                                    <ListItemAvatar >
                                       <Avatar sx={{ bgcolor: '#1976D2' }}>{value.emisor.charAt(0)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                       primary={
                                          <React.Fragment>
                                          <Typography
                                             sx={{ display: 'inline' , fontSize:'15px',textDecoration:'none',color:'black'}}
                                          >
                                          {  value.emisor}
                                          </Typography>
                                       </React.Fragment>
                                       }
                                       secondary={
                                          <React.Fragment>
                                             <Typography
                                                sx={{ display: 'inline' , fontSize:'15px',textDecoration:'none'}}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                             >
                                                {value.detalles}
                                             </Typography>
                                             {/* <Typography>
                                                {value.fecha_recibido}
                                             </Typography> */}
                                          </React.Fragment>
                                       }
                                    />
                                    {/* ver que privilegios tiene cada apartado */}
                                    {/* {open == 1 || open == 2 ? (
                                       <>
                                          <Button>Ir</Button>
                                          <Button color='secondary'>Editar</Button>
                                          <Button color='error'>Eliminar</Button>
                                       </>
                                    ) : (
                                       <>
                                          <Button>Ir</Button>
                                          <Button color='error'>Eliminar</Button>
                                       </>
                                    )} */}

                                 </ListItem>
                              </ListItemButton>
                           </ListItem>
                           <Divider variant="inset" component="li" />
                        </>
                     );
                  })}
               </List>
            </Grid>
            <Grid item container justifyContent={'center'}>
            <Pagination count={cont} page={page} onChange={handleChange} color="primary"  size="large"/>
            </Grid>
         </Grid>
   </Container>
   <ModalAddNotify
       openNewNotify={openNewNotify}
       setOpenNewNotify={ setOpenNewNotify}
       dataUser={dataUser}
      />
   </Protected>
)
   }
