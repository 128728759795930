import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, FormControl, Grid, FormControlLabel, Checkbox, Autocomplete, Typography, InputAdornment, FormHelperText, getNativeSelectUtilityClasses } from '@mui/material'
import { DownloadIcon, PencilIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import { Props } from "./ModalAddAction.type";
import WorkIcon from '@mui/icons-material/Work';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AddTaskIcon from '@mui/icons-material/AddTask';
import FeedIcon from '@mui/icons-material/Feed';
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import { proyeccionService } from '@/service/services/Proyeccion.service';
import { accionService } from '@/service/services/Accion.service';
import { AreaNegocioService } from '@/service/services/AreaNegocio.service';

const initialFormValues = {
    asunto: "",
    idarea_negocio: 0,
    hito: "",
    fecha_inicio: "",
    hora_inicio: "",
    fecha_fin: "",
    hora_fin: "",
    idfrecuencia: "",
    idprioridad: 0,
}

export const ModalAddAction: React.FC<Props> = ({
    open,
    actionAdd,
    setOpen,
    actionEdit,
    setActionEdit,
    actionUpdate,
    sentDateData,
    setSentDateData,
    setOpenDetailsEvent
}): JSX.Element => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const { asunto = "", idarea_negocio = "", hito = "", fecha_inicio = "", hora_inicio = "", fecha_fin = "", hora_fin = "", idfrecuencia = "", idprioridad = "", } = formValues;
    const [dataProyeccion, setDataProyeccion] = useState<any>([])
    const [dataHitos, setDataHitos] = useState<any>([])
    const [dataTrabajadores, setDataTrabajadores] = useState<any>([])
    const [idproyeccion, setIdProyeccion] = useState(null);
    const [idhito, setIdHito] = useState(null);
    const [error, setError] = useState(null);
    const [dataInicial, setDataInicial] = useState<any>({
        company_area: [],
        frecuencia: [],
        prioridad: []
    });
    const [disabled,setDisabled] = useState<any>({
        area_negocio:false,
    })

    //Visibilidad de combos
    const [visibleRepeat, setVisibleRepeat] = useState(false);
    const [visibleProyection, setVisibleProyection] = useState(false);
    const [disableIdHito, setDisableIdHito] = useState(true);
    const [areaNegocio, setAreaNegocio] = useState<any>([]);
    const [trabajador, setTrabajador] = useState<any>([]);


    useEffect(() => {
        proyecciondata();
        getProyeccion();
        if (actionEdit) {
            setFormValues(actionEdit);
            const idsArea = []
            actionEdit.idarea_negocio.map((area) => {
               idsArea.push(area.idarea_negocio);
            })
            console.log(idsArea);
             getUsersByArea(JSON.stringify(idsArea).replace(/\\n/g, ''))

            if(actionEdit.idproyeccion!==null)
            {
                setVisibleProyection(true);
                DataProyeccionEdit(actionEdit.idproyeccion,actionEdit.idhito,actionEdit.idarea_negocio,actionEdit.trabajadores);

            }
            if(actionEdit.idfrecuencia!==null)
            {
                setVisibleRepeat(true);
            }
        }
        else {
            setFormValues(initialFormValues);
        }
    }, [actionEdit])

    useEffect(() => {
        getProyeccion();
        proyecciondata();
        if (sentDateData) {
            setFormValues(sentDateData);
        }
        else {
            setFormValues(initialFormValues);
        }
    }, [sentDateData])


    const DataProyeccionEdit=async(idproyeccion,idhito,idarea_negocio,trabajadores)=>{
        const resp = await proyeccionService.getProyeccionById(idproyeccion,idhito);
        setIdProyeccion(resp.data.proyeccion[0]);
        setIdHito(resp.data.hito[0]);
        setAreaNegocio(idarea_negocio)
        setTrabajador(trabajadores)
    }

    const proyecciondata = async () => {
        const dataInicial = await accionService.getAccionData();
        setDataInicial(prev=>({...prev,company_area:dataInicial.data.company_area,prioridad:dataInicial.data.prioridad,proyeccion_type:dataInicial.data.proyeccion_type,frecuencia:dataInicial.data.frecuencia}))
     };

    async function getProyeccion() {
        const resp = await proyeccionService.getProyeccion();
        setDataProyeccion(resp.data);
    }

    async function getUsersByArea(idsArea) {
      const resp = await AreaNegocioService.getUsersByArea(idsArea);
        if (resp?.data?.trabajadores?.length > 0) {
            setDataTrabajadores(resp.data.trabajadores)
        }
      //setDataProyeccion(resp.data);
  }

    async function getHitos(idProyeccion: number) {
        const resp = await proyeccionService.getHitosByProyeccion(idProyeccion);
        setDataHitos(resp.data.hitos);
        setDataInicial(prev=>({...prev,company_area:resp.data.idarea_negocio}))
        //setAreaNegocio(resp.data.idarea_negocio)
         //setFormValues(prev=>({...prev,idarea_negocio:resp.data.idarea_negocio}));
    }

    const onSelectProyeccion = (proyeccion) => {
        if (proyeccion) {
        //    if (proyeccion.id != null || proyeccion.id != idproyeccion.id) {
        //       setIdHito(null)
        //    }
           setIdProyeccion(proyeccion)
        }
     }

     const onSelectHito = (hito) =>{
        if (hito) {
            setIdHito(hito)
         }
     }

    //Función que cambiará visibilidad de checkBox
    const changeCheckRepeat = () => {
        setVisibleRepeat(!visibleRepeat);
    }
    const changeCheckProyection = () => {
        if(!visibleProyection){

            setDisabled({
                area_negocio:true
            })

        }else{
            setDisabled({
                area_negocio:false
            })
            setIdProyeccion(null);
            setIdHito(null)
            setAreaNegocio([]);
            setTrabajador([]);
            setFormValues({...formValues, idarea_negocio:-1})
            //setDataHitos(null);
        }
        // if(visibleProyection)
        // {
        //     setDataHitos(null);
        // }
        setVisibleProyection(!visibleProyection);

    }

    const handleInputChange = (e) => {
        const changedFormValues = {
            ...formValues,
            [e.target.name]: e.target.value
        }
        asunto.trim() !== "" && setError("")
        hito.trim() !== "" && setError("")
        idprioridad !== null && setError("");
      //   idarea_negocio !== null && setError("");
        //   area.trim() !== "" && setError("")
          fecha_inicio.trim() !== "" && setError("")
          fecha_fin.trim() !== "" && setError("")
        // nota.trim() !== "" && setError("")
        setFormValues(changedFormValues);
    }
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const idsArea=[]
        areaNegocio.map((area)=>{
          idsArea.push(area.idarea_negocio);
        })
        const idsTrabajadores=[]
        trabajador.map((user)=>{
           idsTrabajadores.push(user.idusuario);
        })

        if (actionEdit) {
            let editAction={};
            if (formValues.hora_inicio === "00:00" && formValues.hora_fin === '00:00') {
                if(idproyeccion?.id)
                {
                    editAction = {
                        ...formValues,
                        idproyeccion:idproyeccion?idproyeccion.idproyeccion:"",
                        idhito:idhito?idhito.id:"",
                        idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
                        idsTrabajadores:JSON.stringify(idsTrabajadores).replace(/\\n/g, ''),
                    } || {}
                }else{
                    editAction = {
                        ...formValues,
                        idproyeccion:idproyeccion?idproyeccion.idproyeccion:"",
                        idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
                        idsTrabajadores:JSON.stringify(idsTrabajadores).replace(/\\n/g, ''),
                        // idhito:idhito.idhito,
                    } || {}
                }

               ///setOpen(false)
            } else {
                if(idproyeccion?.id)
                {
                    editAction = {
                        ...formValues,
                        fecha_inicio: `${formValues.fecha_inicio} ${formValues.hora_inicio}`,
                        fecha_fin: `${formValues.fecha_fin} ${formValues.hora_fin}`,
                        idproyeccion:idproyeccion?idproyeccion.idproyeccion:"",
                        idhito:idhito?idhito.id:"",
                        idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
                        idsTrabajadores:JSON.stringify(idsTrabajadores).replace(/\\n/g, ''),
                    } || {}
                }else{
                    editAction = {
                        ...formValues,
                        fecha_inicio: `${formValues.fecha_inicio} ${formValues.hora_inicio}`,
                        fecha_fin: `${formValues.fecha_fin} ${formValues.hora_fin}`,
                        idproyeccion:idproyeccion?idproyeccion.idproyeccion:"",
                        idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
                        idsTrabajadores:JSON.stringify(idsTrabajadores).replace(/\\n/g, ''),
                       // idhito:idhito.id,
                    } || {}
                }

            }
            actionUpdate(editAction);
             setActionEdit(null);
             LimpiarFormulario();

        } else {
            // if (!tipotarea.trim()) {
            //     return setError("tipotarea");
            // }
            if (!asunto.trim()) {
                return setError("asunto");
            }
            if (idproyeccion==-1&& visibleProyection==true) {
                return setError("proyeccion");
             }
             if (idhito==-1&& visibleProyection==true) {
                return setError("idhito");
             }
            if (!hito.trim()&& visibleProyection==false) {
                return setError("hito");
            }
            if (idprioridad==0) {
                return setError("idprioridad");
             }
            //  if (idarea_negocio==0) {
            //     return setError("idarea_negocio");
            //  }
            // if (!proyeccion.trim()) {
            //     return setError("proyeccion");
            // }
            // if (!actividad.trim()) {
            //     return setError("actividad");
            // }
            // if (!progreso.trim()) {
            //     return setError("progreso");
            // }


            // if (!prioridad.trim()) {
            //     return setError("prioridad");
            // }
            // if (!area.trim()) {
            //     return setError("cliente");
            // }
            if (!fecha_inicio.trim()) {
                return setError("fecha_inicio");
            }
            if (!fecha_fin.trim()) {
                return setError("fecha_fin");
            }
            // if (!tiporepeticion.trim()) {
            //     return setError("tiporepetion");
            // }
            // if (!comentario.trim()) {
            //     return setError("comentario");
            // }

            // if (!nota.trim()) {
            //     return setError("nota");
            // }
            // else {

            if (formValues.hora_inicio === "00:00" && formValues.hora_fin === '00:00') {

                const values = {
                    ...formValues,
                    idproyeccion:idproyeccion?idproyeccion.idproyeccion:"",
                    idhito:idhito?idhito.id:"",
                    idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
                    idsTrabajadores:JSON.stringify(idsTrabajadores).replace(/\\n/g, ''),
                    proyeccion: idproyeccion?idproyeccion:null
                } || {}
               //console.log(values)
                actionAdd(values);
                setFormValues(initialFormValues);
                LimpiarFormulario()
                setSentDateData(null);
               //setOpen(false)
            } else {
                const values = {
                    ...formValues,
                    fecha_inicio: `${formValues.fecha_inicio} ${formValues.hora_inicio}`,
                    fecha_fin: `${formValues.fecha_fin} ${formValues.hora_fin}`,
                    idproyeccion:idproyeccion?idproyeccion.idproyeccion:"",
                    proyeccion: idproyeccion?idproyeccion:null,
                    idsArea:JSON.stringify(idsArea).replace(/\\n/g, ''),
                    idsTrabajadores:JSON.stringify(idsTrabajadores).replace(/\\n/g, ''),
                    idhito:idhito?idhito.id:""
                } || {}
               actionAdd(values);
                setFormValues(initialFormValues);
                setSentDateData(null);
                LimpiarFormulario()
               //setOpen(false)
            }
            // }
        }
    }

    const LimpiarFormulario = () => {
        setFormValues(initialFormValues);
        setIdProyeccion(null)
        setIdHito(null)
       // setDataHitos(null)
        setVisibleProyection(false)
        setAreaNegocio([])
        setTrabajador([])
        setOpen(false)
        if(actionEdit)
        {
        setOpenDetailsEvent(false)
        }
     }
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='Modal'>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Typography id="modal-modal-title" textAlign='center' sx={{color:'#3F9BCC', fontWeight:'700', fontSize:'2em'}} >
                              {actionEdit ? 'Editar Acción' : 'Crear Acción'}
                        </Typography>
                        <form onSubmit={handleSubmit} >
                            <Grid container direction="row" spacing={2} mt={2}>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        error={error === "asunto" ? true : false}
                                        helperText={error === "asunto" ? " El asunto es un campo requerido" : ""}
                                        size="small"
                                        id="asunto"
                                        placeholder="Detalle Asunto*"
                                        sx={{ bgcolor: '#fff' }}
                                        name="asunto"
                                        type="text"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FeedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={asunto}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={error === "idprioridad" ? true : false}>
                                        <InputLabel id="idprioridad">Prioridad</InputLabel>
                                        <Select
                                            labelId="idprioridad"
                                            id="idprioridad"
                                            sx={{ bgcolor: '#fff' }}
                                            size="small"
                                            fullWidth
                                            name="idprioridad"
                                            value={idprioridad}
                                            label="Prioridad"
                                            onChange={handleInputChange}
                                        >
                                            {dataInicial.prioridad.map((option, i) => {
                                                return <MenuItem key={i} value={option.id}>{option.prioridad}</MenuItem>
                                            })
                                            }
                                        </Select>
                                        <FormHelperText>{error === "idprioridad" ? "Prioridad es un campo requerido" : ""}</FormHelperText>
                                    </FormControl>
                                </Grid>


                                {/* FILA2 */}
                                {/* {
                                    taskEdit ?
                                        (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <TextField

                                                        fullWidth
                                                        error={error === "actividad" ? true : false}
                                                        helperText={error === "actividad" ? " La actividad es un campo requerido" : ""}
                                                        size="small"
                                                        id="actividad"
                                                        placeholder="Actividad*"
                                                        sx={{bgcolor:'#fff'}}
                                                        name="actividad"
                                                        type="text"
                                                        value={''}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField

                                                        fullWidth
                                                        error={error === "hito" ? true : false}
                                                        helperText={error === "hito" ? " El hito es un campo requerido" : ""}
                                                        size="small"
                                                        id="hito"
                                                        placeholder="Hito*"
                                                        sx={{bgcolor:'#fff'}}
                                                        name="hito"
                                                        type="text"
                                                        value={hito}
                                                        InputProps={{
                                                         startAdornment: (
                                                           <InputAdornment position="start">
                                                             <AddTaskIcon />
                                                           </InputAdornment>
                                                         ),
                                                       }}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                            </>
                                        ) : ( */}
                                {/* <> */}
                                <Grid item xs={12} md={1}>
                                    <Checkbox disabled={actionEdit?true:false} checked={visibleProyection ? true : false} onChange={() => changeCheckProyection()} />
                                </Grid>
                                <Grid item xs={2} md={5} >
                                    <Autocomplete
                                        disabled={visibleProyection ? false : true}
                                        value={idproyeccion}
                                        sx={{bgcolor:'#fff'}}
                                        size='small'
                                        onChange={(e, proyeccion: any) => {
                                            // console.log("Proyeccion", proyeccion)
                                            proyeccion && getHitos(proyeccion.idproyeccion)
                                            proyeccion && setDisableIdHito(false)
                                            onSelectProyeccion(proyeccion);
                                        }}
                                        id="idproyeccion"
                                        options={dataProyeccion}
                                        getOptionLabel={(option: any) => option.nombre ? option.nombre : ""}
                                        renderInput={(params) => <TextField {...params} label="Proyecciones" error={error === "proyeccion" && visibleProyection==true? true : false} helperText={error === "proyeccion" && visibleProyection==true? "Proyeccion es un campo requerido" : ""}/>}
                                    />
                                </Grid>

                                {!visibleProyection ? (
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            error={error === "hito" && visibleProyection==false ? true : false}
                                            helperText={error === "hito" && visibleProyection==false? " El hito es un campo requerido" : ""}
                                            size="small"
                                            id="hito"
                                            placeholder="Hito*"
                                            sx={{ bgcolor: '#fff' }}
                                            name="hito"
                                            type="text"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AddTaskIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={hito}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={2} md={6} >
                                        <Autocomplete
                                            disabled={disableIdHito}
                                            value={idhito}
                                            sx={{bgcolor:'#fff'}}
                                            size='small'
                                            onChange={(e, hito) => {
                                                //console.log("hitos", hito)
                                                // provincia && getComuna(provincia.id);
                                                onSelectHito(hito);
                                            }}
                                            id="idhito"
                                            options={dataHitos}
                                            getOptionLabel={(option: any) => option.hito ? option.hito : ""}
                                            renderInput={(params) => <TextField {...params} label="Hitos*" error={error === "idhito" && visibleProyection==true? true : false} helperText={error === "idhito" && visibleProyection==true? "hito es un campo requerido" : ""} />}
                                        />
                                    </Grid>
                                )}
                                {/* </>
                                )
                                } */}

                                {/* Fin FILA 2 */}

                                {/* <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={error === "idarea_negocio" ? true : false}>
                                        <InputLabel id="idarea_negocio">Area Negocio</InputLabel>
                                        <Select
                                            labelId="idarea_negocio"
                                            disabled={disabled.area_negocio}
                                            id="idarea_negocio"
                                            sx={{ bgcolor: '#fff' }}
                                            size="small"
                                            fullWidth
                                            name="idarea_negocio"
                                            value={idarea_negocio}
                                            label="idarea_negocio"
                                            onChange={handleInputChange}
                                        >
                                            {dataInicial.company_area.map((option, i) => {
                                                return <MenuItem key={i} value={option.idarea_negocio}>{option.descripcion}</MenuItem>
                                            })
                                            }
                                        </Select>
                                        <FormHelperText>{error === "idarea_negocio" ? "Area Negocio es un campo requerido" : ""}</FormHelperText>
                                    </FormControl>
                                </Grid> */}

                              <Grid item xs={12} md={12}>
                              <Autocomplete
                                 multiple
                                 id="tags-outlined"
                                 options={dataInicial.company_area}
                                 getOptionLabel={(option: any) => option.descripcion ? option.descripcion : ''}
                                 //defaultValue={[valueProComprados]}
                                 fullWidth
                                // sx={{maxWidth:'28vw'}}
                                sx={{ bgcolor: '#fff' }}
                                 value={areaNegocio}
                                 onChange={(e, newValue) => {
                                    console.log(newValue)
                                    setAreaNegocio(newValue);
                                    const idsArea=[]
                                    newValue.map((area)=>{
                                      idsArea.push(area.idarea_negocio);
                                    })
                                    console.log(idsArea);
                                    if(idsArea.length>0)
                                    {
                                        console.log('idsArea',idsArea)
                                    getUsersByArea(JSON.stringify(idsArea).replace(/\\n/g, ''))
                                    }
                                    // setvalueProComprados(newValue)
                                    // if(newValue){
                                    //    setError("")
                                    // }else{

                                    // }
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Area de negocio"
                                      // error={error === "procomprados" ? true : false}
                                       placeholder=""
                                       fullWidth
                                    />
                                 )}
                              />
                              </Grid>
                              <Grid item xs={12} md={12}>
                              <Autocomplete
                                 multiple
                                 id="tags-outlined"
                                 options={dataTrabajadores || []}
                                 getOptionLabel={(option: any) => option?.nombre ? option.nombre : ''}
                                 //defaultValue={[valueProComprados]}
                                 fullWidth
                                // sx={{maxWidth:'28vw'}}
                                sx={{ bgcolor: '#fff' }}
                                 value={trabajador}
                                 onChange={(e, newValue) => {
                                    console.log(newValue)
                                     if (newValue) {
                                         setTrabajador(newValue);
                                     }
                                    
                                    // setvalueProComprados(newValue)
                                    // if(newValue){
                                    //    setError("")
                                    // }else{

                                    // }
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Trabajadores"
                                      // error={error === "procomprados" ? true : false}
                                       placeholder=""
                                       fullWidth
                                    />
                                 )}
                              />
                              </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField

                                        fullWidth
                                        error={error === "fecha_inicio" ? true : false}
                                        helperText={error === "fecha_inicio" ? " La Fecha de Inicio es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        size="small"
                                        label="Fecha Inicio"
                                        id="fecha_inicio"
                                        sx={{ bgcolor: '#fff' }}
                                        name="fecha_inicio"
                                        type="date"
                                        autoFocus
                                        value={fecha_inicio}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField

                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        label="Hora Inicio"
                                        id="hora_inicio"
                                        sx={{ bgcolor: '#fff' }}
                                        name="hora_inicio"
                                        type="time"
                                        autoFocus
                                        value={hora_inicio}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField

                                        fullWidth
                                        error={error === "fecha_fin" ? true : false}
                                        helperText={error === "fecha_fin" ? " La Fecha de Fin es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        size="small"
                                        id="fecha_fin"
                                        label="Fecha Fin"
                                        sx={{ bgcolor: '#fff' }}
                                        name="fecha_fin"
                                        type="date"
                                        autoFocus
                                        value={fecha_fin}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField

                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        id="hora_fin"
                                        label="Hora Fin "
                                        sx={{ bgcolor: '#fff' }}
                                        name="hora_fin"
                                        type="time"
                                        value={hora_fin}
                                        onChange={handleInputChange}
                                    />
                                </Grid>

                                {/* {
                                   !taskEdit ?
                                        (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <TextField

                                                        fullWidth
                                                        error={error === "progreso" ? true : false}
                                                        helperText={error === "progreso" ? " El progreso es un campo requerido" : ""}
                                                        size="small"
                                                        id="progreso"
                                                        label="Progreso*"
                                                        sx={{bgcolor:'#fff'}}
                                                        name="progreso"
                                                        type="text"
                                                        value={""}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField

                                                        fullWidth
                                                        error={error === "comentario" ? true : false}
                                                        helperText={error === "comentario" ? " El comentario es un campo requerido" : ""}
                                                        size="small"
                                                        id="comentario"
                                                        label="Comentario*"
                                                        sx={{bgcolor:'#fff'}}
                                                        name="comentario"
                                                        type="text"
                                                        value={""}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <> */}
                                {/* <Grid item >
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox checked={visibleRepeat ? true : false} onChange={() => changeCheckRepeat()} />}
                                        label="Repetir"
                                        labelPlacement="end"
                                    />
                                </Grid> */}
                                {/* {visibleRepeat ? (
                                    <Grid item xs={8} >
                                        <FormControl fullWidth>
                                            <InputLabel id="idfrecuencia">Frecuencia</InputLabel>
                                            <Select
                                                labelId="idfrecuencia"
                                                id="idfrecuencia"
                                                sx={{ bgcolor: '#fff' }}
                                                size="small"
                                                error={error === "idfrecuencia" ? true : false}
                                                fullWidth
                                                name="idfrecuencia"
                                                value={idfrecuencia}
                                                label="idfrecuencia"
                                                onChange={handleInputChange}
                                            >
                                                {dataInicial.frecuencia.map((option, i) => {
                                                    return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <Grid item xs={4}></Grid>
                                )} */}
                                {/* </>
                                        )
                                } */}
                                <Grid item xs={12} md={6} >
                                    {actionEdit ? (
                                        <Button
                                            onClick={() => {
                                                setActionEdit(null)
                                                LimpiarFormulario()

                                            }}
                                            variant="contained"
                                            fullWidth
                                            color="error"
                                            startIcon={<CancelIcon />}
                                            sx={{background:'#bf6c00', color:'#fff',mt:'10px', '&:hover':{
                                             bgcolor:'#bf6c00'
                                            }}}
                                        >
                                            Cancelar
                                        </Button>) : (<Button
                                            onClick={() => {
                                                LimpiarFormulario()
                                            }}
                                            variant="contained"
                                            fullWidth
                                            color="error"
                                            startIcon={<CancelIcon />}
                                            sx={{background:'#bf6c00', color:'#fff',mt:'10px', '&:hover':{
                                             bgcolor:'#bf6c00'
                                            }}}
                                        >
                                            Cancelar
                                        </Button>)}

                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        startIcon={actionEdit ? <EditIcon /> : <SaveIcon />}
                                        sx={{background:'#155172', color:'#fff',mt:'10px', '&:hover':{
                                          bgcolor:'#155172'
                                         }}}
                                    >
                                        {actionEdit ? 'Actualizar' : 'Agregar'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
