import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog, Container, InputLabel } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { indigo } from '@mui/material/colors';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_DOCUMENTS, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Link, useHistory } from 'react-router-dom';
import { authenticationService } from '@/service/services/Authentication.service';
import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { ROUTE_DOCUMENTOS } from '@/toolbox/constants/route-map';

type ModalProps = {
    open: boolean,
    setOpen: any,
    dataCliente?: any,
    idRut?: any,
}

const initialFormValues = {
    nombre_rz: '',
    direccion: '',
    telefono: '',
    email: '',
}

export const ModalRegisterDocument: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {

    const history = useHistory();
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
    const [error, setError] = useState(null);
    const [formValues, setFormValues] = useState(initialFormValues);
    const { nombre_rz = "", direccion = "", telefono = "", email = "" } = formValues

    const handleInputChange = (e) => {
        const changedFormValues = {
            ...formValues,
            [e.target.name]: e.target.value
        }
        changedFormValues.nombre_rz.trim() !== "" && setError("");
        changedFormValues.direccion.trim() !== "" && setError("");
        changedFormValues.telefono.trim() !== "" && setError("");
        changedFormValues.email.trim() !== "" && setError("");

        setFormValues(changedFormValues)

    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        console.log(formValues)
        if (!nombre_rz) {
            return setError('rut_cliente');
        }

        const dataCliente = {
            rut: props.dataCliente.rut_cliente,
            password: props.dataCliente.password,
            tipo_prospecto: props.dataCliente.tipo_prospecto,
            idempresa: props.idRut,
            idcliente: null,
            nombre_rz: formValues.nombre_rz,
            direccion: formValues.direccion,
            telefono: formValues.telefono,
            email: formValues.email
        }
        //registro de Cliente
        const resp = await authenticationService.registerDocuments(dataCliente)
        if (resp) {
            props.setOpen(false)

            const resp = await authenticationService.loginDocuments(props.dataCliente.rut_cliente, props.dataCliente.password)

            if(resp?.data?.message){
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'warning',
                    message: resp?.data?.message,
                }));
            }else{
                setSnackBarConfig(prev => ({
                    ...prev,
                    open: true,
                    severity: 'success',
                    message: 'Usuario Logeado con éxito',
                }));
                saveLocalStorage(KEY_DOCUMENTS,resp?.data)
                history.push(ROUTE_DOCUMENTOS)
            }
        }
    }


    const bodyModal = (
        <>
            <Grid item xs={12} mt={2}>
                <form onSubmit={handleSubmitForm}>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 2, maxHeight: 600, overflow: 'auto' }}>
                        <Typography style={{ fontSize: "28px", textAlign: 'center' }}>Complete sus datos </Typography>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Nombre</InputLabel>
                            <TextField
                                size='small'
                                error={error === "nombre_rz" ? true : false}
                                helperText={error === "nombre_rz" ? "Nombre es un campo requerido" : ""}
                                id='nombre_rz'
                                name='nombre_rz'
                                label=""
                                fullWidth
                                value={nombre_rz}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Direccion</InputLabel>
                            <TextField
                                size='small'
                                error={error === "direccion" ? true : false}
                                helperText={error === "direccion" ? "Direccion es un campo requerido" : ""}
                                id='direccion'
                                name='direccion'
                                label=""
                                fullWidth
                                value={direccion}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Telefono</InputLabel>
                            <TextField
                                size='small'
                                // error={error === "telefono" ? true : false}
                                // helperText={error === "telefono" ? "Telefono es un campo requerido" : ""}
                                id='telefono'
                                name='telefono'
                                label=""
                                fullWidth
                                value={telefono}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Correo</InputLabel>
                            <TextField
                                size='small'
                                // error={error === "correo" ? true : false}
                                // helperText={error === "correo" ? "Correo es un campo requerido" : ""}
                                id='email'
                                name='email'
                                label=""
                                fullWidth
                                value={email}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Container>
                    <Grid item container
                        direction="row"
                        justifyContent="end"
                        alignItems="center">
                        <Button
                            onClick={() => {
                                setFormValues(initialFormValues)
                                props.setOpen(false)
                            }}
                            variant="contained"
                            // fullWidth
                            color="error"
                            startIcon={<CancelIcon />}
                            sx={{
                                background: '#bf6c00', color: '#fff', mt: '10px', mr: '10px', '&:hover': { bgcolor: '#bf6c00' }
                            }}>
                            <span>Cancelar</span>
                        </Button>
                        <Button
                            variant='contained'
                            type='submit'
                            startIcon={<SaveIcon />}
                            sx={{
                                background: '#155172', color: '#fff', mt: '10px', mr: '10px', '&:hover': {
                                    bgcolor: '#155172'
                                }
                            }}
                        >
                            <span>Guardar</span>
                        </Button>
                    </Grid>
                </form>
            </Grid>
        </>
    )
    return (
        <div>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                    severity={snackBarConfig.severity}
                    variant="filled"
                >
                    {snackBarConfig.message}
                </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px',
                    bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p: 1,
                }}>
                    {bodyModal}
                </Box>

            </Modal>
        </div>
    );
}