import React, { useRef, useState, useEffect} from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Campaing.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, InputLabel, TextField, Typography, Button,CircularProgress,Backdrop, Snackbar, Alert } from '@mui/material';
import { AcordionUserBussines } from '@/components/common/Acordion';
import { TableData } from '@/components/common/Table';
import { ModalNewCampaign,ModalUserCampaign } from '@/components/common/Modal';
import { ROUTE_CAMPAING_LEADS_FORM, ROUTE_DETAILCAMPAIGN, ROUTE_DETAILCAMPAIGNLEADS,ROUTE_CAMPAIGN_GENERALES} from '@/toolbox/constants/route-map';
import { campaingService } from '@/service/services/Campaing.service';
import { ruleService } from '@/service/services/Rule.service';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BYPASS, KEY_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { bypassService } from '@/service/services/Bypass.service';
import { TabsComponent } from '@/components/common/Tabs';
import { SpinnerGrow } from '@/components/common/Spinner';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import AddIcon from '@mui/icons-material/Add';
import { LeadsFormView } from '@/views/LeadsForm';
import { Link, useHistory } from 'react-router-dom';
import { ConfirmDialog } from '@components/common/DialogConfirm';

export const CampaingView: React.FC<Props> = (props:any): JSX.Element => {
   const header = [
      { name: 'nombre', label: 'Nombre' },
      { name: 'regla', label: 'Regla de Negocio'},
      { name: 'fecha_inicio', label: 'Fecha de Creación' },
      { name: 'fecha_cierre', label: 'Fecha de Cierre' },
      { name: 'cantidad', label: 'Inversión' , money : true},
      { name: 'nombre_status', label: 'Estado' }];

      const headerGenericas = [
         { name: 'nombre', label: 'Nombre' },
         { name: 'fecha_inicio', label: 'Fecha de Creación' },
         // { name: 'fecha_cierre', label: 'Fecha de Cierre' },
         // { name: 'cantidad', label: 'Inversión' , money : true},
         { name: 'nombre_status', label: 'Estado' }];

   const dataActionAdmin = [
      { name: 'edit', color: 'primary' },
      { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGN },
      //{ name: 'add', color: 'primary' }
      { name: 'delete', color: 'secondary', route: null }
   ]
   const dataAction = [
      { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGN },
   ]

   const dataActionLeadsAdmin = [
      { name: 'edit', color: 'primary' },
      { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGNLEADS },
      //{ name: 'add', color: 'primary' }
      { name: 'delete', color: 'secondary', route: null }
   ]
   const dataActionLeads = [
      { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGNLEADS },
   ]

   const dataActionGeneralAdmin = [
      { name: 'edit', color: 'primary' },
      { name: 'view', color: 'success', route: ROUTE_CAMPAIGN_GENERALES},
      //{ name: 'add', color: 'primary' }
      { name: 'delete', color: 'secondary', route: null }
   ]
   const dataActionGeneral = [
      { name: 'view', color: 'success', route: ROUTE_CAMPAIGN_GENERALES },
   ]

   const labels = [{ label: 'POST-VENTA' }, { label: 'PRE-VENTA' }, { label: 'GENERALES' }]
   async function getRule() {
      setOpenView(true)
      const resp = await ruleService.getRule()
      const data = resp?.data;
      setDataRule(data);
      //setOpenView(false)
      //const json = resp.data[0].datosusuario
   }
   const RecuperarData = async (data) => {
console.log(data)
      const id = data.id;
      if (data.action == 'edit') {
         setOpen(true)
         //setFormValues(data)
         setCampaignObtenida(data)
      } else {
      //    setCampaignObten
      setDialog(prev => ({ ...prev, message: `¿Desea eliminar la campaña ${(data.nombre).toLowerCase()}?`,campaignId:id,open: true, confirm:true}));
      //    const resp = await ruleService.deleteRule(id);
      //   let deleteContact = {};
      //   contact && setDialog(prev => ({ ...prev, message: `¿Desea eliminar al contacto ${contact.nombre_rz} con Rut ${contact.rut_cliente}?`, open: true }));
      //   getRule();
      }

   };

      const Update = () =>{

      }
   const history= useHistory();
   const [isDetail, setIsDetail] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [openView, setOpenView] = useState(false);
   const [dataCampaing,setDataCampaing]=useState<any>([])
   const [dataCampaignLeads, setDataCampaignLeads] = useState<any>([])
   const [dataCampaignGeneral, setDataCampaignGeneral] = useState<any>([])
   const [dataRule,setDataRule]=useState<any>([])
   const [vigente, setVigente] = useState(0);
   const [anulado, setAnulado] = useState(0);
   const [vencida, setVencida] = useState(0);
   const [vendedor, setVendedor] = useState<any>();
   const [estadoLeads,setEstadoLeads]= useState<any>({
      vigente:0,
      anulado:0,
      vencida:0
   })
   const [campaignObtenida, setCampaignObtenida] =useState<any>();
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [busqueda, setBusqueda] = useState<any>("");
   const [busquedaLits, setBusquedaLits] = useState<any>("");
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const token = readLocalStorage(KEY_SOFTNET);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 8000,
   })
   const [valueForm, setValueForm] = useState<number>(0)
   // const [campanaIdFormLeads,setCampanaIdFormLeads]
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      campaignId: null,
      message: `¿Desea eliminar al contacto --- con Rut ----?`
   })

   useEffect(()=>{
      changeStatusDeshabilitado();
      getCampaing();
      getRule();
    //();
   },[])


   async function getCampaing() {
      setOpenView(true)
      const resp = await campaingService.getCampaing()
      if(resp){
         setDataCampaing(resp.data.campaign);
         setDataCampaignLeads(resp.data.campaignlits);
         setDataCampaignGeneral(resp.data.campaignGeneral)
         setTablaBusqueda(resp.data)
         estados(resp.data.campaign);
         estadosLeads(resp.data.campaignlits)
         setOpenView(false)
      }
   }

    const RenderCampaign = () => {
       getCampaing();
    }

    async function changeStatusDeshabilitado() {
       const resp = await campaingService.changeStatusDeshabilitado()
    }

    async function searchDateCampaign(date, tipo) {
      if(date){
         const dateCampaing = {
            fecha: date,
            tipo: tipo
         }

          const resp = await campaingService.searchDateCampaign(dateCampaing)
          if(resp){
            setDataCampaing(resp.data.campaign);
            setDataCampaignLeads(resp.data.campaignlits);
            estados(resp.data.campaign);
            estadosLeads(resp.data.campaignlits)
             console.log(resp)
         }
      }
    }

    async function searchResultados(word, tipo) {

      const dateCampaing = {
         tipo: tipo,
         word: word
      }
       if (tipo == 0) {
          setBusqueda(word)
       } else {
          setBusquedaLits(word)
       }
      if(word.length>3)
      {
         const resp = await campaingService.searchResultados(dateCampaing)
         if(resp){
            setDataCampaing(resp.data.campaign);
            setDataCampaignLeads(resp.data.campaignlits);
            estados(resp.data.campaign);
            estadosLeads(resp.data.campaignlits)
            console.log(resp)
         }
      }
      if(word.length==0)
      {
         getCampaing()
      }

    }
   //  const handleChange = (e) =>{

   //    setBusqueda(e.target.value)
   //    filtrar(e.target.value)
   // }


   // const filtrar = (terminoBusqueda) => {
   //    var resultBusqueda = tablaBusqueda.filter((elemento)=>{
   //       if(elemento.nombre.toString().toLowerCase().includes(terminoBusqueda)){
   //          return elemento
   //       }
   //    })
   //    setDataCampaing(resultBusqueda)
   // }



   async function getDataCampaing() {
      const resp = await campaingService.getDataCampaing()
      if(resp){
         setVendedor(resp.data.sales_user);
      }

   }

   const estados = (dataCampaña:any) =>{

      let contVigente = 0;
      let contAnulado = 0;
      let contVencida = 0;

      dataCampaña.map((data) => {
         switch (data.status) {
            case '1':
               contVigente++;
               break;
            case '2':
               contVencida++;
               break;
            case '0':
               contAnulado++;
               break;
         }

      })
      setVigente(contVigente);
      setAnulado(contAnulado);
      setVencida(contVencida);
   }
   const estadosLeads = (dataCampañaLeads:any) =>{

      let contVigente = 0;
      let contAnulado = 0;
      let contVencida = 0;

      dataCampañaLeads.map((data) => {
         switch (data.status) {
            case '1':
               contVigente++;
               break;
            case '2':
               contVencida++;
               break;
            case '0':
               contAnulado++;
               break;
         }

      })
      setEstadoLeads(prev=>({...prev,vigente:contVigente,anulado:contAnulado,vencida:contVencida}))
      // setVigente(contVigente);
      // setAnulado(contAnulado);
      // setVencida(contVencida);
   }

   const ViewAddLeadsForm = () =>
   {
      history.push(ROUTE_CAMPAING_LEADS_FORM)
   }

   const deleteCampaign = async () =>
   {
      try{
         if (Dialog.confirm == true) {
         const resp = await campaingService.deleteCampaign(Dialog.campaignId);
         if (resp.data) {
            getCampaing();
         }
      }
      }catch(e){
         console.log(e)
      }
   }

   return (
      <Protected>
          <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               onConfirm={()=> deleteCampaign()}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
            <Grid container flexDirection='row' justifyContent='space-between'>
               <Grid item md={8}>
                  <Typography  sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Campañas de Negocios</Typography>
               </Grid>
               {dataUser.user.role==ROLE_ADMIN&&<Grid item md={4} container justifyContent='right' alignItems='end'>
                  <Button variant='contained'
                   sx={{background:'#E4980D', color:'#fff', '&:hover':{
                     bgcolor:'#E4980D'
                    }}}
                    endIcon={<AddIcon fontSize='large'/>}
                  onClick={() => {
                     console.log(token.length)
                     // if(token.length>1)
                     // {
                     // setOpen(true)
                     // }else
                     // {
                     // setSnackBarConfig(prev => ({
                     //    ...prev,
                     //    open: true,
                     //    message: 'No Puedes Crear Campañas PostVenta, Debes Cambia tu password al de ERP SOFTNET ',
                     // }));
                     // }
                     setOpen(true)
                  }}>Crear Campaña</Button>
               </Grid>}
            </Grid>
            <Divider sx={{ mt: 2, mb: 3 }} />
            <Grid item xs={12}>
               <TabsComponent
                  labels={labels}
                  setValueForm={setValueForm}
                  childrenOne={
                     <>
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2, ml:3 }}>

                     </Container> <Grid container flexDirection='row' spacing={4} sx={{ mb: 5 }}>
                     <Grid item md={6} container  >
                        <Grid item xs={12}>
                           <span>Buscar desde esta fecha creación en adelante:</span>
                        </Grid>
                        <Grid item xs={12}  mt={1}>
                           <TextField
                              type='date'
                              size='small'
                              sx={{bgcolor:'#E3F4FD'}}
                              fullWidth
                              onChange={(e)=>searchDateCampaign(e.target.value, 0)}
                           />
                        </Grid>
                     </Grid>
                     <Grid item md={6} container >
                        <Grid item xs={12}>
                           <span>Filtrar Resultados:</span>
                        </Grid>
                        <Grid item xs={12} >
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 onChange={(e)=>searchResultados(e.target.value,0)}
                                 sx={{bgcolor:'#E3F4FD'}}
                                 name="text"
                                 value={busqueda}
                                 placeholder="Introduce el nombre de la Campaña"
                                 type="search"
                                 autoComplete="current-password"
                                 size="small"
                              />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid container flexDirection='row' justifyContent='space-around' sx={{ mt: 4, mb: 4 }}>
                     <Grid item xs={3} md={2} container flexDirection='column' alignItems='center'>
                        <Typography variant='h3'>{vigente}</Typography>
                        <Typography variant='h6'>Vigentes</Typography>
                     </Grid>
                     <Grid item xs={3} md={2} container flexDirection='column' alignItems='center'>
                        <Typography variant='h3'>{vencida}</Typography>
                        <Typography variant='h6'>Vencidas</Typography>
                     </Grid>
                     <Grid item xs={3} md={2} container flexDirection='column' alignItems='center'>
                        <Typography variant='h3'>{anulado}</Typography>
                        <Typography variant='h6'>Anuladas</Typography>
                     </Grid>
                  </Grid>
                  <TableData
                     header={header}
                     data={dataCampaing}
                     action={dataUser.user.role==ROLE_ADMIN?dataActionAdmin:dataAction}
                     setAddOpen={setOpen}
                     RecuperarData={RecuperarData} />
                     </>
                  }
                  childrenTwo={
                     <>
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>

                     </Container> <Grid container flexDirection='row' spacing={4} sx={{ mb: 5 }}>
                     <Grid item md={6} container  >
                        <Grid item xs={12}>
                           <span>Buscar desde esta fecha creación en adelante:</span>
                        </Grid>
                        <Grid item xs={12} mt={1} >
                           <TextField
                              type='date'
                              size='small'
                              sx={{bgcolor:'#E3F4FD'}}
                              onChange={(e)=>searchDateCampaign(e.target.value, 1)}
                              fullWidth
                           />
                        </Grid>
                     </Grid>
                     <Grid item md={6} container >
                        <Grid item xs={12}>
                           <span>Filtrar Resultados:</span>
                        </Grid>
                        <Grid item xs={12}>
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 sx={{bgcolor:'#E3F4FD'}}
                                 onChange={(e)=>searchResultados(e.target.value,1)}
                                 name="text"
                                 value={busquedaLits}
                                 placeholder="Introduce el nombre de la campaña"
                                 type="search"
                                 autoComplete="current-password"
                                 size="small"
                              />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid container flexDirection='row' justifyContent='space-around' sx={{ mt: 4, mb: 4 }}>
                     <Grid item xs={3} md={2} container flexDirection='column' alignItems='center'>
                        <Typography variant='h3'>{estadoLeads.vigente}</Typography>
                        <Typography variant='h6'>Vigentes</Typography>
                     </Grid>
                     <Grid item xs={3} md={2} container flexDirection='column' alignItems='center'>
                        <Typography variant='h3'>{estadoLeads.vencida}</Typography>
                        <Typography variant='h6'>Vencidas</Typography>
                     </Grid>
                     <Grid item xs={3} md={2} container flexDirection='column' alignItems='center'>
                        <Typography variant='h3'>{estadoLeads.anulado}</Typography>
                        <Typography variant='h6'>Anuladas</Typography>
                     </Grid>
                  </Grid>
                  <TableData
                     header={header}
                     data={dataCampaignLeads}
                     action={dataUser.user.role==ROLE_ADMIN?dataActionLeadsAdmin:dataActionLeads}
                     setAddOpen={setOpen}
                     RecuperarData={RecuperarData} />
                     </>
                  }
                  childrenThree={
                     <>
                     {/* <Grid container flexDirection='row' spacing={4} sx={{ mb: 5 }}>

                     <Grid item md={6} container  >
                        <Grid item xs={12}>
                           <span>Buscar desde esta fecha creación en adelante:</span>
                        </Grid>
                        <Grid item xs={12} mt={1} >
                           <TextField
                              type='date'
                              size='small'
                              sx={{bgcolor:'#E3F4FD'}}
                              onChange={(e)=>searchDateCampaign(e.target.value, 1)}
                              fullWidth
                           />
                        </Grid>
                     </Grid>

                     <Grid item md={6} container >
                        <Grid item xs={12}>
                           <span>Filtrar Resultados:</span>
                        </Grid>
                        <Grid item xs={12}>
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 sx={{bgcolor:'#E3F4FD'}}
                                 onChange={(e)=>searchResultados(e.target.value,1)}
                                 name="text"
                                 value={busquedaLits}
                                 placeholder="Introduce el nombre de la campaña"
                                 type="search"
                                 autoComplete="current-password"
                                 size="small"
                              />
                        </Grid>
                     </Grid>

                  </Grid> */}

                  <TableData
                     header={headerGenericas}
                     data={dataCampaignGeneral}
                     action={dataUser.user.role==ROLE_ADMIN?dataActionGeneralAdmin:dataActionGeneral}
                     setAddOpen={setOpen}
                     RecuperarData={RecuperarData}
                     />
                     </>
                  }
               />
            </Grid>

         </Container>
             {/* <ModalUserCampaign
            open={open}
            setOpen={setOpen}
            vendedor={vendedor}
            /> */}
            <ModalNewCampaign
            open={open}
            setOpen={setOpen}
            dataRule={dataRule}
            CampaignEdit={campaignObtenida}
            setDataCampaing={setDataCampaing}
            dataCampaing={dataCampaing}
            setCampaingEdit={setCampaignObtenida}
            renderCampaign={RenderCampaign}
            // ViewAddLeadsForm={ViewAddLeadsForm}
            />
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
      </Protected>
   )
};
