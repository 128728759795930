import { RecoveryEmailRepository } from "../repositories/Recovery.repository";

export const RecoverymailService = {
   sendMailRecovery,
   sendMailNewPassword
};

function sendMailRecovery(usuario, rut){
   return RecoveryEmailRepository.sendMailRecovery(usuario, rut);
}

function sendMailNewPassword(id, contraseña){
   return RecoveryEmailRepository.sendMailNewPassword(id, contraseña);
}
