import React, { useState, useEffect } from 'react';
import { Props } from './Brochure.type';
import { InputAdornment, IconButton, Step, StepLabel, Button, Card, CircularProgress, Grid, Container, useTheme, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, responsiveFontSizes, TextField, useMediaQuery, Autocomplete, Chip, Stepper, StepButton, FormControlLabel, Checkbox, Skeleton, InputLabel, Menu, Backdrop } from '@mui/material'
import { bypassService } from '@/service/services/Bypass.service';
import { LeadsFormService } from '@/service/services/LeadsForm.service';
import { TwitterPicker, SwatchesPicker } from 'react-color';
import ImageIcon from '@mui/icons-material/Image';
import Stack from '@mui/material/Stack';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import './index.css'
import SettingsIcon from '@mui/icons-material/Settings';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { ModalViewURL } from '@/components/common/Modal/ModalViewUrl';
import { ModalValidateFormView } from '@/components/common/Modal/ModalValidateFormView';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { DATA_CAMPAING_LEADS } from '@/toolbox/constants/local-storage';
import { SpinnerGrow } from '@/components/common/Spinner';
import { ModalUri } from '@/components/common/Modal/ModalUri';
import { TemplateWsService } from '@/service/services/TemplateWs.service';

const steps = ['Header', 'Seccion', 'Ubicacion','Main'];
export const BrochureView: React.FC<any> = (props: any): JSX.Element => {

   const [seccionesCreadas, setSeccionesCreadas] = useState<any>([]);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const isMenuOpen = Boolean(anchorEl);
   const [openCreate, setOpenCreate] = useState<boolean>(false);
   const [openUri, setOpenUri] = useState<boolean>(false);
   const [dataUri, setDataUri] = useState<any>(false);
   const [idCampana, setIdCampana] = useState<any>();
   const [urlLeads, setUrlLeads] = useState<any>([])
   const [openModalValidate, setOpenModalValidate] = useState<boolean>(false)
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'warning',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Guardar cambios',
      confirm: false,
      contactId: null,
      message: `¿Desea eliminar al contacto --- con Rut ----?`
   })
   const [checkSeccionesSeleccionadas, setCheckSeccionesSeleccionadas] = useState<any>({
      view_Header: false,
      view_Seccion: false,
      view_Ubicacion: false,
      view_Formulario: false,
      view_Video: false,
      view_Main: false,
      view_Footer: false
   });
   const [valueHeader, setValueHeader] = useState<any>({
      slogan: '',
      color_fondo_disfuminado: 'rgba(1, 41, 64, 0.43), rgba(1, 41, 64, 0.43)',
      color_slogan: '#fff',
      white_img_fondo: 512,
      height_img_fondo: 341,
      white_img: 438,
      height_img: 428,
   })
   const [valueSection, setValueSection] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#155172',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 300
   })
   const [valueUbicacion, setValueUbicacion] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#fff',
      color_title: '',
      color_description: '',
      white_img: 1300,
      height_img: 1000,
      url: ''
   })
   const [valueForm, setValueForm] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '',
      color_title: '',
      color_description: '',
      white_img: 430,
      height_img: 230
   })
   const [valueVideo, setValueVideo] = useState<any>({
      color_fondo: '#fff',
      url: ''
   })
   const [valueMainGeneral, setValueMainGeneral] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })

   const [valueMain, setValueMain] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })
   const [valueMain2, setValueMain2] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })
   const [valueMain3, setValueMain3] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })
   const [valueMain4, setValueMain4] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '#F3F3F3',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 330
   })

   const [valueFooter, setValueFooter] = useState<any>({
      title: '',
      description: '',
      img: '',
      color_fondo: '',
      color_title: '',
      color_description: '',
      white_img: 600,
      height_img: 300
   })

   const [checkColorHeader, setCheckColorHeader] = useState<any>({
      slogan_header_estado: false,
      color_fondo_desfuminado_header_estado: false,
   });
   const [checkColorSection, setCheckColorSection] = useState<any>({
      title_section_estado: false,
      description_section_estado: false,
      color_fondo_section_estado: false,
   });

   const [checkColorForm, setCheckColorForm] = useState<any>({
      title_estado: false,
      description_estado: false,
      color_fondo_estado: false,
   });

   const [checkColorUbicacion, setCheckColorUbicacion] = useState<any>({
      title_ubicacion_estado: false,
      description_ubicacion_estado: false,
      color_fondo_ubicacion_estado: false,
   });

   const [validateComponentsHeader, setValidateComponentsHeader] = useState<any>({
      disfuminado:false,
      imglogo:false,
      slogan:false
   })
   const [loading, setLoading] = useState<boolean>(false)
   const [inputsLeads, setInputsLeads] = useState<any>([]);
   const [channelLeads, setChannelLeads] = useState<any>([]);
   const [dataLeadsInput, setDataLeadsInput] = useState<any>([]);
   const [fixedOptions, setFixedOptions] = useState<any>()
   const [activeStep, setActiveStep] = useState(0);
   const [skipped, setSkipped] = useState(new Set());
   const [openLeads, setOpenLeads] = useState<boolean>();
   const [saveImageSection, setSaveImageSection] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageHeader, setSaveImageHeader] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageHeaderFondo, setSaveImageHeaderFondo] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageFormulario, setSaveImageFormulario] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageUbicacion, setSaveImageUbicacion] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain, setSaveImageMain] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain2, setSaveImageMain2] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain3, setSaveImageMain3] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveImageMain4, setSaveImageMain4] = useState<any>({
      name: '',
      path: '',
      preview: null,
      data: null
   });
   const [saveViewForm, setSaveViewForm] = useState<any>([])
   const [seletInputs, setSelectInputs] = useState<any>([])
   const [checkTypeMaps, setCheckTypeMaps] = useState<any>(0)
   const [dataLeadsChannel, setDataLeadsChannel] = useState<any>([])
   const [respEmpresa, setRespEmpresa] = useState<any>();
   const [respCampaign, setrespCampaign] = useState<any>();
   const [respLink, setResLink] = useState<any>([])
   const [validate, setValidate] = useState<any>([])
   const [urlTemplate, setUrlTemplate] = useState<string>('');

   const theme = useTheme();

   const handleForm = async (e) => {
      e.preventDefault();

   }

   const ValidateRequired = () => {
      let contador = 0;
      validate.map((row) => {
         if (row.value == 1) {
            contador++;
         }
      })
      if (contador == 0) {
         return true
      }
      console.log(contador)
      return false;
   }

   useEffect(() => {
      getInputs();
      getChannel();
      getUrlTemplate();
      const data = readLocalStorage(DATA_CAMPAING_LEADS)
      console.log(data)
      setIdCampana(2)
      // setIdCampana(props.$store.CampanaReducer.dataCampana?props.$store.CampanaReducer.dataCampana.id:data.id)
   }, [])

   async function getInputs() {
      const resp = await bypassService.getInputs()
      setDataLeadsInput(resp)
      const fixed = resp.filter(data => data.name == 'Nombre' || data.name == 'Correo')
      setFixedOptions(fixed);
      setInputsLeads(fixed)
   }

   async function getUrlTemplate() {
      const resp = await TemplateWsService.getUrlTemplate()
      if (resp?.code === 200) {
         if (resp?.data?.detail?.url_landing_page) {
            setUrlTemplate(resp?.data?.detail?.url_landing_page)
         }
      }
   }

   async function getChannel() {
      const resp = await bypassService.getChannel()
      setDataLeadsChannel(resp)
   }

   //OBTENER VALOR DE COLOR EN LAS SECCIONES
   const handleFormColorHeader = (color) => {
      if (checkColorHeader.slogan_header_estado == true) {
         setValueHeader(prev => ({ ...prev, color_slogan: color.hex }))
      }
      if (checkColorHeader.color_fondo_desfuminado_header_estado) {
         setValueHeader(prev => ({ ...prev, color_fondo_disfuminado: color.hex }))
      }
   }

   const handleFormColorSection = (color) => {
      if (checkColorSection.title_section_estado == true) {
         setValueSection(prev => ({ ...prev, color_title: color.hex }))
      }
      if (checkColorSection.description_section_estado) {
         setValueSection(prev => ({ ...prev, color_description: color.hex }))
      }
      if (checkColorSection.color_fondo_section_estado) {
         setValueSection(prev => ({ ...prev, color_fondo: color.hex }))
      }
   }

   const handleFormColorFormulario = (color) => {
      console.log(color.hex)
      if (checkColorForm.title_estado == true) {
         setValueForm(prev => ({ ...prev, color_title: color.hex }))
      }
      if (checkColorForm.description_estado) {
         setValueForm(prev => ({ ...prev, color_description: color.hex }))
      }
      if (checkColorForm.color_fondo_estado) {
         console.log(color.hex)
         setValueForm(prev => ({ ...prev, color_fondo: color.hex }))
      }
   }

   const handleFormColorUbicacion = (color) => {
      console.log(color.hex)
      console.log(checkColorUbicacion.color_fondo_ubicacion_estado)
      if (checkColorUbicacion.title_ubicacion_estado == true) {
         setValueUbicacion(prev => ({ ...prev, color_title: color.hex }))
      }
      if (checkColorUbicacion.description_ubicacion_estado) {
         setValueUbicacion(prev => ({ ...prev, color_description: color.hex }))
      }
      if (checkColorUbicacion.color_fondo_ubicacion_estado) {
         setValueUbicacion(prev => ({ ...prev, color_fondo: color.hex }))
      }
   }

   const saveImageApi = async (imagen) => {
      const resp = await LeadsFormService.VisualizarImagen(imagen)
   }

   const handleSelectMaps = (e) => {
      setCheckTypeMaps(e.target.value)
      if (e.target.value == 1) {
         setSaveImageUbicacion(prev => ({
            ...prev, name: '',
            path: '',
            preview: null,
            data: null
         }))
      }
      if (e.target.value == 0) {
         setValueUbicacion(prev => ({ ...prev, url: '' }))
      }
   }

   ///OBTENER ARCHIVOS DE LAS SECCIONES


   ///OBTENER ARCHIVOS DE LAS SECCIONES
   const changePathHeader = (e, value) => {
      if (value == 1) {
         const file = e.target.files[0];
         if (!file) {
            return;
         }
         let src, preview, type = file.type;
         // Coincide con la cadena que comienza con image /
         // if(type!='image/png'|| type !='image/jpeg')
         // {
         //      alert('error')
         // }
         if(type=="image/bmp")
         {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
            }));
            return;
         }
         console.log(file)
         if (/^image\/\S+$/.test(type)) {
            console.log('entre')
            src = URL.createObjectURL(file)
            preview = <img src={src} alt='' width={`${valueHeader.white_img_fondo / 2}`} height={`${valueHeader.height_img_fondo / 2}`} />
         }
         setSaveImageHeaderFondo(prev => ({ ...prev, data: file, path: src, preview: preview }))
      }
      else {
         const file = e.target.files[0];
         if (!file) {
            return;
         }
         let src, preview, type = file.type;
         if(type=="image/bmp")
         {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
            }));
            return;
         }
         // Coincide con la cadena que comienza con image /
         if (/^image\/\S+$/.test(type)) {
            src = URL.createObjectURL(file)
            preview = <img src={src} alt='' width={`${valueHeader.white_img / 2}`} height={`${valueHeader.height_img / 2}`} />
         }
         setSaveImageHeader(prev => ({ ...prev, data: file, path: src, preview: preview }))
      }
   }

   ///OBTENER ARCHIVOS DE LAS SECCIONES
   const changePathSection = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueSection.white_img / 2}`} height={`${valueSection.height_img / 2}`} />
      }
      setSaveImageSection(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePath = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueForm.white_img / 2}`} height={`${valueForm.height_img / 2}`} />
      }
      setSaveImageFormulario(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathMain = (e) => {
      const file = e.target.files[0];
      console.log(file)
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain.white_img / 2}`} height={`${valueMain.height_img / 2}`} />
      }
      setSaveImageMain(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   console.log(saveImageMain)
   const changePathMain2 = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain2.white_img / 2}`} height={`${valueMain2.height_img / 2}`} />
      }
      setSaveImageMain2(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathMain3 = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain3.white_img / 2}`} height={`${valueMain3.height_img / 2}`} />
      }
      setSaveImageMain3(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathMain4 = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueMain4.white_img / 2}`} height={`${valueMain4.height_img / 2}`} />
      }
      setSaveImageMain4(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }
   const changePathUbicacion = (e) => {
      const file = e.target.files[0];
      if (!file) {
         return;
      }
      let src, preview, type = file.type;
      if(type=="image/bmp")
      {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Solo se acepta como tipo de imgen/png-jpg-jpeg"
         }));
         return;
      }
      // Coincide con la cadena que comienza con image /
      if (/^image\/\S+$/.test(type)) {
         src = URL.createObjectURL(file)
         preview = <img src={src} alt='' width={`${valueUbicacion.white_img / 2}`} height={`${valueUbicacion.height_img / 2}`} />
      }
      setSaveImageUbicacion(prev => ({ ...prev, data: file, path: src, preview: preview }))
   }


   //MOSTRAR FORMULARIO POR POSICION EN EL STEPER_
   const mostrarFormulario = () => {
      if (activeStep == 0) {
         return (
            <Grid container sx={{ p: 1 }}>
               <Grid item container direction='row' alignItems={'center'} justifyContent='flex-end'>
                  <span
                     style={{
                        fontWeight: 600,
                        background: '#ccdbe8',
                        padding: '0px 10px'
                     }}
                  > AGREGAR ESTA SECCIÓN <Checkbox checked={checkSeccionesSeleccionadas.view_Header} onChange={(e) => { setCheckSeccionesSeleccionadas(prev => ({ ...prev, view_Header: e.target.checked })) }} /> </span>
               </Grid>
               <form onSubmit={handleSubmitForm} >
                  <Typography
                     sx={{ color: '#155172', paddingBottom: '10px', fontWeight: '700', fontSize: '1.5em', textAlign: 'center' }}>
                     Agregar Información al Inicio de su Template
                  </Typography>
                  <Grid xs={12} >
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>ESLOGAN <span style={{ color: 'red'}}>(opcional)</span></p>
                     <TextField
                        size='small'
                        value={valueHeader.slogan}
                        onChange={(e) => { setValueHeader(prev => ({ ...prev, slogan: e.target.value })) }}
                        fullWidth
                        sx={{ pb: 2 }}
                     />
                  </Grid>
                  {/* <Grid xs={12}>
                  <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>DESCRIPCIÓN</p>
                  <TextField
                     size='small'
                     multiline
                     value={valueSection.description}
                     onChange={(e) => { setValueSection(prev => ({ ...prev, description: e.target.value })) }}
                     fullWidth
                     sx={{ pb: 2 }}
                  />
               </Grid> */}
                  <Grid item container direction={'row'}>
                     <Grid item xs={7}>
                        <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '20px' }}>TEMAS</p>
                        <TwitterPicker
                           color={valueHeader.color_fondo_disfuminado}
                           onChange={handleFormColorHeader}
                           width={250}
                        />
                     </Grid>
                     <Grid item xs={5}>
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorHeader(prev => ({ ...prev, slogan_header_estado: e.target.checked })) }}
                           />}
                           label="Eslogan"
                           labelPlacement="end"
                        />
                     </Grid>
                  </Grid>


                  <Grid xs={12}>
                     <Grid item container direction={'row'} alignItems='center'>
                     <Grid item xs={6} sx={{ p: 1, position: 'relative' }}>
                        <div>
                           <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '7px' }}>IMAGEN FONDO <span style={{ color: 'red'}}>(requerido*)</span></p>
                           <div className="wrapper">
                              <div className="file-upload">
                                 <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathHeader(e, 1) }} />
                                 {saveImageHeaderFondo.preview ? saveImageHeaderFondo.preview :
                                  <p style={{ fontSize: '20px' }}>1349 x 899</p>
                                 }
                              </div>
                           </div>
                        </div>
                        {
                           saveImageHeaderFondo.preview ?
                              <HighlightOffIcon color='primary' sx={{ position: 'absolute', top: '19%', right: '53%' }} onClick={() => {
                                 setSaveImageHeaderFondo(prev => ({
                                    ...prev, name: '',
                                    path: '',
                                    preview: null,
                                    data: null
                                 }))
                              }} /> :
                              (<></>)
                        }
                        </Grid>
                        <Grid item xs={5}>
                         <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setValidateComponentsHeader(prev => ({ ...prev, disfuminado: e.target.checked })) }}
                           />}
                           label="Color Disfuminado"
                           labelPlacement="end"
                        />
                     </Grid>
                     </Grid>
                  </Grid>
                  <Grid xs={12}>
                     <Grid item container direction={'row'} alignItems='center'>
                     <Grid item xs={6} sx={{ p: 1, position: 'relative' }}>
                        <div>
                           <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '7px' }}>IMAGEN <span style={{ color: 'red'}}>(opcional)</span></p>
                           <div className="wrapper">
                              <div className="file-upload">
                                 <input type="file" accept="image/png,image/jpeg" onChange={(e) => { changePathHeader(e, 2) }} />
                                 {saveImageHeader.preview ? saveImageHeader.preview :
                                 <p style={{ fontSize: '20px' }}>438 x 428</p>
                                 }
                              </div>
                           </div>
                        </div>
                        {
                           saveImageHeader.preview ?
                              <HighlightOffIcon color='primary' sx={{ position: 'absolute', top: '19%', right: '53%' }} onClick={() => {
                                 setSaveImageHeader(prev => ({
                                    ...prev, name: '',
                                    path: '',
                                    preview: null,
                                    data: null
                                 }))
                              }} /> :
                              (<></>)
                        }
                        </Grid>
                        {/* <div style={{ marginLeft: '10px' }}>
                           <span style={{ marginRight: '5px' }}> Ancho:</span>
                           <input
                              type={'number'}
                              min={'1'}
                              onChange={(e) => { setValueHeader(prev => ({ ...prev, white_img: e.target.value })) }}
                              value={valueHeader.white_img}
                              style={{ padding: '5px', border: '1px solid #b9bdbf', borderRadius: '4px', width: '50px' }}
                           />
                           <span style={{ marginRight: '40px' }}>px</span>
                           <span style={{ marginRight: '5px' }}>Alto:</span>
                           <input
                              type={'number'}
                              min={'1'}
                              onChange={(e) => { setValueHeader(prev => ({ ...prev, height_img: e.target.value })) }}
                              value={valueHeader.height_img}
                              style={{ padding: '5px', border: '1px solid #b9bdbf', borderRadius: '4px', width: '50px' }}
                           />
                           <span>px</span>
                        </div> */}
                     </Grid>
                  </Grid>
               </form>
               {/* <Button fullWidth variant='contained' color='secondary' sx={{ mt: 2 }} onClick={() => { HandleEditSizeImageHeader() }}>
                  Ejecutar cambios
               </Button> */}
            </Grid>

         )
      }
      else if (activeStep == 1) {
         return (
            <Grid container sx={{ p: 1 }}>
               <Grid item container direction='row' alignItems={'center'} justifyContent='flex-end'
               >
                  <span
                     style={{
                        fontWeight: 600,
                        background: '#ccdbe8',
                        padding: '0px 10px'
                     }}
                  > AGREGAR ESTA SECCIÓN <Checkbox checked={checkSeccionesSeleccionadas.view_Seccion} onChange={(e) => { setCheckSeccionesSeleccionadas(prev => ({ ...prev, view_Seccion: e.target.checked })) }} /> </span>
               </Grid>
               <form onSubmit={handleSubmitForm} >
                  <Typography
                     sx={{ color: '#155172', paddingBottom: '10px', fontWeight: '700', fontSize: '1.5em', textAlign: 'center' }}>
                     Agregar información de su sección principal
                  </Typography>
                  <Grid xs={12} >
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>TÍTULO <span style={{ color: 'red'}}>(requerido*)</span></p>
                     <TextField
                        size='small'
                        value={valueSection.title}
                        onChange={(e) => { setValueSection(prev => ({ ...prev, title: e.target.value })) }}
                        fullWidth
                        sx={{ pb: 2 }}
                     />
                  </Grid>
                  <Grid xs={12}>
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>DESCRIPCIÓN <span style={{ color: 'red'}}>(requerido*)</span></p>
                     <TextField
                        size='small'
                        multiline
                        value={valueSection.description}
                        onChange={(e) => { setValueSection(prev => ({ ...prev, description: e.target.value })) }}
                        fullWidth
                        sx={{ pb: 2 }}
                     />
                  </Grid>
                  <Grid item container direction={'row'}>
                     <Grid item xs={8}>
                        <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '20px' }}>TEMAS</p>
                        <TwitterPicker
                           color={valueSection.color_fondo}
                           onChange={handleFormColorSection}
                           width={250}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorSection(prev => ({ ...prev, title_section_estado: e.target.checked })) }}
                           />}
                           label="Titulo "
                           labelPlacement="end"
                        />
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorSection(prev => ({ ...prev, description_section_estado: e.target.checked })) }}
                           />}
                           label="Descripción "
                           labelPlacement="end"
                        />
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorSection(prev => ({ ...prev, color_fondo_section_estado: e.target.checked })) }}
                           />}
                           label="Fondo"
                           labelPlacement="end"
                        />
                     </Grid>
                  </Grid>
                  <Grid xs={12}>
                     <Grid item container direction={'row'} alignItems='center'>
                        <div>
                           <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '7px' }}>IMAGEN <span style={{ color: 'red'}}>(requerido*)</span></p>
                           <div className="wrapper">
                              <div className="file-upload">
                                 <input type="file" accept="image/png,image/jpeg" onChange={changePathSection} />
                                 {/* <ImageIcon fontSize='large' /> */}
                                 <p style={{ fontSize: '20px' }}>497 x 280</p>
                              </div>
                           </div>
                        </div>
                        {/* <div style={{ marginLeft: '10px' }}>
                           <span style={{ marginRight: '5px' }}> Ancho:</span>
                           <input
                              type={'number'}
                              min={'1'}
                              onChange={(e) => { setValueSection(prev => ({ ...prev, white_img: e.target.value })) }}
                              value={valueSection.white_img}
                              style={{ padding: '5px', border: '1px solid #b9bdbf', borderRadius: '4px', width: '50px' }}
                           />
                           <span style={{ marginRight: '40px' }}>px</span>
                           <span style={{ marginRight: '5px' }}>Alto:</span>
                           <input
                              type={'number'}
                              min={'1'}
                              onChange={(e) => { setValueSection(prev => ({ ...prev, height_img: e.target.value })) }}
                              value={valueSection.height_img}
                              style={{ padding: '5px', border: '1px solid #b9bdbf', borderRadius: '4px', width: '50px' }}
                           />
                           <span>px</span>
                        </div> */}
                     </Grid>
                  </Grid>
               </form>
            </Grid>
         )
      }
      else if (activeStep == 2) {
         return (
            <Grid container sx={{ p: 1 }}>
               <Grid item container direction='row' alignItems={'center'} justifyContent='flex-end'
               >
                  <span
                     style={{
                        fontWeight: 600,
                        background: '#ccdbe8',
                        padding: '0px 10px'
                     }}
                  > AGREGAR ESTA SECCIÓN <Checkbox checked={checkSeccionesSeleccionadas.view_Ubicacion} onChange={(e) => { setCheckSeccionesSeleccionadas(prev => ({ ...prev, view_Ubicacion: e.target.checked })) }} /> </span>
               </Grid>
               <form onSubmit={handleSubmitForm} >
                  <Typography
                     sx={{ color: '#155172', paddingBottom: '10px', fontWeight: '700', fontSize: '1.5em', textAlign: 'center' }}>
                     Agregar Informacion de su Ubicación
                  </Typography>
                  <Grid xs={12} >
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>TÍTULO <span style={{ color: 'red'}}>(requerido*)</span></p>
                     <TextField
                        size='small'
                        value={valueUbicacion.title}
                        onChange={(e) => { setValueUbicacion(prev => ({ ...prev, title: e.target.value })) }}
                        fullWidth
                        sx={{ pb: 2 }}
                     />
                  </Grid>
                  <Grid xs={12}>
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>DESCRIPCIÓN <span style={{ color: 'red'}}>(requerido*)</span></p>
                     <TextField
                        size='small'
                        value={valueUbicacion.description}
                        onChange={(e) => { setValueUbicacion(prev => ({ ...prev, description: e.target.value })) }}
                        fullWidth
                        sx={{ pb: 2 }}
                     />
                  </Grid>
                  <Grid item container direction={'row'}>
                     <Grid item xs={8}>
                        <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '20px' }}>TEMAS</p>
                        <TwitterPicker
                           color={valueUbicacion.color_fondo}
                           onChange={handleFormColorUbicacion}
                           width={250}
                        />
                     </Grid>
                     <Grid item xs={4}>
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorUbicacion(prev => ({ ...prev, title_ubicacion_estado: e.target.checked })) }}
                           />}
                           label="Titulo "
                           labelPlacement="end"
                        />
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorUbicacion(prev => ({ ...prev, description_ubicacion_estado: e.target.checked })) }}
                           />}
                           label="Descripción "
                           labelPlacement="end"
                        />
                        <FormControlLabel
                           value="end"
                           control={<Checkbox
                              onChange={(e) => { setCheckColorUbicacion(prev => ({ ...prev, color_fondo_ubicacion_estado: e.target.checked })) }}
                           />}
                           label="Fondo"
                           labelPlacement="end"
                        />
                     </Grid>
                  </Grid>
                  <Grid xs={12}>
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '7px' }}>MAPA_UBICACIÓN <span style={{ color: 'red'}}>(requerido*)</span></p>
                     <Select
                        //labelId="tipo"
                        id="tipo"
                        sx={{ bgcolor: '#fff' }}
                        size="small"
                        fullWidth
                        name="idtipofeeds"
                        value={checkTypeMaps}
                        //label="Tipo"
                        onChange={(e) => { handleSelectMaps(e) }}
                     >
                        <MenuItem value={0}>IMAGEN MAPS</MenuItem>
                        <MenuItem value={1}>URL GOOGLE MAPS</MenuItem>
                     </Select>
                  </Grid>
                  {checkTypeMaps == 0 ?
                     (
                        <Grid xs={12}>
                           <Grid item container direction={'row'} alignItems='center'>
                              <div>
                                 <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '7px' }}>IMAGEN <span style={{ color: 'red'}}>(requerido*)</span></p>
                                 <div className="wrapper">
                                    <div className="file-upload">
                                       <input type="file" accept="image/png,image/jpeg" onChange={changePathUbicacion} />
                                       {/* <ImageIcon fontSize='large' /> */}
                                       <p style={{ fontSize: '20px' }}>632 x 516</p>
                                    </div>
                                 </div>
                              </div>
                              {/* <div style={{ marginLeft: '10px' }}>
                                 <span style={{ marginRight: '5px' }}> Ancho:</span>
                                 <input
                                    type={'number'}
                                    min={'1'}
                                    onChange={(e) => { setValueUbicacion(prev => ({ ...prev, white_img: e.target.value })) }}
                                    value={valueUbicacion.white_img}
                                    style={{ padding: '5px', border: '1px solid #b9bdbf', borderRadius: '4px', width: '50px' }}
                                 />
                                 <span style={{ marginRight: '40px' }}>px</span>
                                 <span style={{ marginRight: '5px' }}>Alto:</span>
                                 <input
                                    type={'number'}
                                    min={'1'}
                                    onChange={(e) => { setValueUbicacion(prev => ({ ...prev, height_img: e.target.value })) }}
                                    value={valueUbicacion.height_img}
                                    style={{ padding: '5px', border: '1px solid #b9bdbf', borderRadius: '4px', width: '50px' }}
                                 />
                                 <span>px</span>
                              </div> */}
                           </Grid>
                        </Grid>
                     ) :
                     (
                        <Grid xs={12}>
                           <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '20px' }}>URL GOOGLE MAPS </p>
                           <TextField
                              size='small'
                              value={valueUbicacion.url}
                              onChange={(e) => { setValueUbicacion(prev => ({ ...prev, url: e.target.value })) }}
                              fullWidth
                              sx={{ pb: 2 }}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start" sx={{ color: '#00D084' }}>
                                       <AddLocationAltIcon fontSize='large' />
                                    </InputAdornment>
                                 ),
                              }}
                           />
                        </Grid>
                     )}


               </form>
            </Grid>
         )
      }
      else if (activeStep == 3) {
         return (
            <Grid container sx={{ p: 1 }}>
               <Grid item container direction='row' alignItems={'center'} justifyContent='flex-end'
               >
                  <span
                     style={{
                        fontWeight: 600,
                        background: '#ccdbe8',
                        padding: '0px 10px'
                     }}
                  > AGREGAR ESTA SECCIÓN <Checkbox checked={checkSeccionesSeleccionadas.view_Main} onChange={(e) => { setCheckSeccionesSeleccionadas(prev => ({ ...prev, view_Main: e.target.checked })) }} /> </span>
               </Grid>
               <form onSubmit={handleSubmitForm} >
                  <Typography
                     sx={{ color: '#155172', paddingBottom: '10px', fontWeight: '700', fontSize: '1.5em', textAlign: 'center' }}>
                     Agregar Imagenes Multiples
                  </Typography>
                  <Grid xs={12}>
                     <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px' }}>TEMA</p>
                     <SwatchesPicker
                        color={valueMainGeneral.color_fondo}
                        onChange={(e) => { setValueMainGeneral(prev => ({ ...prev, color_fondo: e.hex })) }}
                        width={540}
                        height={300}
                     />
                  </Grid>
                  <Grid xs={12} item container direction={'row'} alignItems='center' justifyContent={'center'} sx={{ pt: 6 }}>
                     <Grid item xs={6} sx={{ p: 1, position: 'relative' }}>
                     <span style={{ color: 'red', fontWeight:500}}>(requerido*)</span>
                        <div className="wrapper">
                           <div className="file-upload">
                              <input type="file" accept="image/png,image/jpeg" onChange={changePathMain} />
                              {saveImageMain.preview ? saveImageMain.preview :
                                 // <ImageIcon fontSize='large' />
                                 <p style={{ fontSize: '20px' }}>632 x 356 </p>
                              }
                           </div>
                        </div>
                        {
                           saveImageMain.preview ?
                              <HighlightOffIcon color='primary' sx={{ position: 'absolute', top: '8%', right: '55%' }} onClick={() => {
                                 setSaveImageMain(prev => ({
                                    ...prev, name: '',
                                    path: '',
                                    preview: null,
                                    data: null
                                 }))
                              }} /> :
                              (<></>)
                        }
                     </Grid>
                     <Grid item xs={6} sx={{ p: 1, pl: 3, position: 'relative' }}>
                     <span style={{ color: 'red', fontWeight:500}}>(requerido*)</span>
                        <div className="wrapper">
                           <div className="file-upload">
                              <input type="file" accept="image/png,image/jpeg" onChange={changePathMain2} />
                              {saveImageMain2.preview ? saveImageMain2.preview :
                                 // <ImageIcon fontSize='large' />
                                 <p style={{ fontSize: '20px' }}>632 x 356</p>
                              }
                           </div>
                        </div>
                        {
                           saveImageMain2.preview ?
                              <HighlightOffIcon color='primary' sx={{ position: 'absolute', top: '10%', right: '48%' }} onClick={() => {
                                 setSaveImageMain2(prev => ({
                                    ...prev, name: '',
                                    path: '',
                                    preview: null,
                                    data: null
                                 }))
                              }} /> :
                              (<></>)
                        }
                     </Grid>
                     <Grid item xs={6} sx={{ p: 1, position: 'relative' }}>
                     <span style={{ color: 'red', fontWeight:500}}>(requerido*)</span>
                        <div className="wrapper">
                           <div className="file-upload">
                              <input type="file" accept="image/png,image/jpeg" onChange={changePathMain3} />
                              {saveImageMain3.preview ? saveImageMain3.preview :
                                 // <ImageIcon fontSize='large' />
                                 <p style={{ fontSize: '20px' }}>632 x 356</p>
                              }
                           </div>
                        </div>
                        {
                           saveImageMain3.preview ?
                              <HighlightOffIcon color='primary' sx={{ position: 'absolute', top: '10%', right: '55%'  }} onClick={() => {
                                 setSaveImageMain3(prev => ({
                                    ...prev, name: '',
                                    path: '',
                                    preview: null,
                                    data: null
                                 }))
                              }} /> :
                              (<></>)
                        }
                     </Grid>
                     <Grid item xs={6} sx={{ p: 1, pl: 3, position: 'relative' }}>
                     <span style={{ color: 'red', fontWeight:500}}>(requerido*)</span>
                        <div className="wrapper">
                           <div className="file-upload">
                              <input type="file" accept="image/png,image/jpeg" onChange={changePathMain4} />
                              {saveImageMain4.preview ? saveImageMain4.preview :
                                 // <ImageIcon fontSize='large' />
                                 <p style={{ fontSize: '20px' }}>632 x 356</p>
                              }
                           </div>
                        </div>
                        {
                           saveImageMain4.preview ?
                              <HighlightOffIcon color='primary' sx={{ position: 'absolute',top: '10%', right: '48%'  }} onClick={() => {
                                 setSaveImageMain4(prev => ({
                                    ...prev, name: '',
                                    path: '',
                                    preview: null,
                                    data: null
                                 }))
                              }} /> :
                              (<></>)
                        }
                     </Grid>
                  </Grid>
               </form>
            </Grid>
         )
      }
   }

   //OBTENER VALORES DE LOS INPUTS
   //Inputs Formularios
   const HandleInputsForm = async () => {
      setSelectInputs(inputsLeads);
      if (saveImageFormulario.data) {
         let file = saveImageFormulario.data;
         let src = URL.createObjectURL(file);
         let preview = <img src={src} alt='' width={`${valueForm.white_img / 2}`} height={`${valueForm.height_img / 2}`} />
         setSaveImageFormulario(prev => ({ ...prev, preview: preview }))
      }
   }

   const HandleEditSizeImageHeader = async () => {
      if (saveImageHeader.data) {
         let file = saveImageHeader.data;
         let src = URL.createObjectURL(file);
         let preview = <img src={src} alt='' width={`${valueHeader.white_img / 2}`} height={`${valueHeader.height_img / 2}`} />
         setSaveImageHeader(prev => ({ ...prev, preview: preview }))
      }
   }
   const HandleEditSizeImageSection = async () => {
      if (saveImageSection.data) {
         let file = saveImageSection.data;
         let src = URL.createObjectURL(file);
         let preview = <img src={src} alt='' width={`${valueSection.white_img / 2}`} height={`${valueSection.height_img / 2}`} />
         setSaveImageSection(prev => ({ ...prev, preview: preview }))
      }
   }

   //Submit Formularios
   const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // const imagen = new FormData();
      // // El campo del archivo aquí está determinado por la API cargada y puede ser otros valores
      // imagen.append('idcampana', ' 2');
      // imagen.append('titulo_formulario', valueForm.title);
      // imagen.append('color_tituloformulario', valueForm.color_title);
      // imagen.append('size_tituloformulario', '15');
      // imagen.append('descripcion_formulario', valueForm.description);
      // imagen.append('color_descripcionformulario', valueForm.color_description);
      // imagen.append('size_descripcionformulario', '13');
      // imagen.append('color_fondo', valueForm.color_fondo);
      // imagen.append('img_formulario', saveImageFormulario.data);
      // imagen.append('img_width', valueForm.white_img);
      // imagen.append('img_height', valueForm.height_img);
      // InsertSectionForm(imagen);
   }

   ///STEPER

   const handleStep = (step: number) => () => {
      setActiveStep(step);
   };

   const viewUbicacion = () => {
      switch (checkTypeMaps) {
         case 0:
            return (
               saveImageUbicacion.preview
            )
            break;
         case 1:
            return (
               <iframe
                  src={valueUbicacion.url}
                  width={`${valueUbicacion.white_img / 2}`} height={`${valueUbicacion.height_img / 2}`} style={{ border: "0" }} allowFullScreen loading="lazy">
               </iframe>
            )
            break;

      }
   }

   const ValidarSeccionesCrear = () => {
      if (checkSeccionesSeleccionadas.view_Header == false &&
         checkSeccionesSeleccionadas.view_Seccion == false &&
         checkSeccionesSeleccionadas.view_Ubicacion == false &&
         checkSeccionesSeleccionadas.view_Formulario == false &&
         checkSeccionesSeleccionadas.view_Video == false &&
         checkSeccionesSeleccionadas.view_Main == false) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "No ha seleccionado ninguna Seccion",
         }));
      } else {
         const mensaje =
            `Se está creando las siguientes secciones:
          (${checkSeccionesSeleccionadas.view_Header ? 'Header' : ''}
          ${checkSeccionesSeleccionadas.view_Seccion ? ', Seccion' : ''}
         ${checkSeccionesSeleccionadas.view_Ubicacion ? ', Ubicacion' : ''}
         ${checkSeccionesSeleccionadas.view_Formulario ? ', Formulario' : ''}
         ${checkSeccionesSeleccionadas.view_Video ? ', Video' : ''}
         ${checkSeccionesSeleccionadas.view_Main ? ', Main' : ''}
          )
         `
         setValidate([])
         setOpenUri(true)
         setDialog(prev => ({ ...prev, message: mensaje, open: true, confirm: true }));
         console.log(validate)
      }

   }

   const selectAll = (e) =>{
      setCheckSeccionesSeleccionadas(prev => ({ ...prev,
          view_Header: e.target.checked,
          view_Seccion: e.target.checked,
          view_Ubicacion: e.target.checked,
          view_Formulario: e.target.checked,
          view_Video: e.target.checked,
          view_Main: e.target.checked,
          view_Footer: e.target.checked
         }))
   }

   const handleCreateTemplateViewForm = async () => {
      let CreateSecciones = [];

      if (Dialog.confirm == true && checkSeccionesSeleccionadas.view_Formulario) {
         if (checkSeccionesSeleccionadas.view_Header == true) {
            // if (valueHeader.slogan == '') {
            //    validate.push({ etiqueta: 'Eslogan', value: 1, canal: 'header' })
            // }
            if (saveImageHeaderFondo.data == null) {
               validate.push({ etiqueta: 'Imagen Fondo Header', value: 1, canal: 'header' })
            }
            // if (saveImageHeader.data == null) {
            //    validate.push({ etiqueta: 'Imagen Logo Header', value: 1, canal: 'header' })
            // }
         }
         if (checkSeccionesSeleccionadas.view_Seccion == true) {
            if (saveImageSection.data == null) {
               validate.push({ etiqueta: 'Imagen Seccion', value: 1, canal: 'seccion' })
            }
         }
         // if (checkSeccionesSeleccionadas.view_Ubicacion == true) {

         //    if (saveImageUbicacion.data == null) {
         //       validate.push({ etiqueta: 'Imagen Ubicacion', value: 1, canal: 'ubicacion' })
         //    }
         //    if (valueUbicacion.url == '') {
         //       validate.push({ etiqueta: 'Url Ubicacion', value: 1, canal: 'ubicacion' })
         //    }
         // }
         if (checkSeccionesSeleccionadas.view_Formulario == true) {
            if (valueForm.title == '') {
               validate.push({ etiqueta: 'Titulo del formulario', value: 1, canal: 'formulario' })
            }
            if (valueForm.description == '') {
               validate.push({ etiqueta: 'Descripcion del formulario', value: 1, canal: 'formulario' })
            }
            // if (saveImageFormulario.data == null){
            //    validate.push({ etiqueta: 'Imagen del formulario', value: 1, canal: 'formulario' })
            // }
            if(!inputsLeads.length)
            {
               validate.push({ etiqueta: 'Campos de entrada de informacion', value: 1, canal: 'formulario' })
            }
            if(!channelLeads.length)
            {
               validate.push({ etiqueta: 'Canales donde se filtrará', value: 1, canal: 'formulario' })
            }
         }
         if (checkSeccionesSeleccionadas.view_Video == true) {
            if (valueVideo.url == '') {
               validate.push({ etiqueta: 'Url Video', value: 1, canal: 'Video' })
            }
         }
         if (checkSeccionesSeleccionadas.view_Main == true) {
            if (saveImageMain.data == null) {
               validate.push({ etiqueta: 'Imagen Main 1', value: 1, canal: 'Main' })
            }
            if (saveImageMain2.data == null) {
               validate.push({ etiqueta: 'Imagen Main 2', value: 1, canal: 'Main' })
            }
            if (saveImageMain3.data == null) {
               validate.push({ etiqueta: 'Imagen Main 3', value: 1, canal: 'Main' })
            }
            if (saveImageMain4.data == null) {
               validate.push({ etiqueta: 'Imagen Main 4', value: 1, canal: 'Main' })
            }
         }

         if (ValidateRequired()) {
            setOpenCreate(true)
            if (checkSeccionesSeleccionadas.view_Header == true) {
               const imagenHeader = new FormData();
               imagenHeader.append('idcampana', idCampana);
               imagenHeader.append('titulo_header', valueHeader.slogan);
               imagenHeader.append('color_header', valueHeader.color_slogan);
               imagenHeader.append('size_header', '15');
               imagenHeader.append('color_fondo', valueHeader.color_fondo_disfuminado);
               imagenHeader.append('logo_width', '1349');
               imagenHeader.append('logo_height', '899');
               imagenHeader.append('img_width', '438');
               imagenHeader.append('img_height', '428');
               imagenHeader.append('logo_header', saveImageHeaderFondo.data || null);
               saveImageHeader.data && imagenHeader.append('img_header', saveImageHeader.data || null);
               const responseHeader: any = await LeadsFormService.InsertSectionHeader(imagenHeader)
               CreateSecciones.push(responseHeader.data.id)
            }
            if (checkSeccionesSeleccionadas.view_Seccion == true) {
               const imagenSeccion = new FormData();
               imagenSeccion.append('idcampana', idCampana);
               imagenSeccion.append('titulo_main', valueSection.title);
               imagenSeccion.append('color_titulomain', valueSection.color_title);
               imagenSeccion.append('size_titulomain', '15');
               imagenSeccion.append('descripcion_main', valueSection.description);
               imagenSeccion.append('color_descripcionmain', valueSection.color_description);
               imagenSeccion.append('size_descripcionmain', '13');
               imagenSeccion.append('color_fondo', valueSection.color_fondo);
               imagenSeccion.append('img_main', saveImageSection.data || null);
               imagenSeccion.append('img_width', '497');
               imagenSeccion.append('img_height', '280');
               const responseSeccion = await LeadsFormService.InsertSectionMain(imagenSeccion)
               CreateSecciones.push(responseSeccion.data.id)
            }
            if (checkSeccionesSeleccionadas.view_Ubicacion == true) {
               const imagenUbicacion = new FormData();
               imagenUbicacion.append('idcampana', idCampana);
               imagenUbicacion.append('titulo_ubicacion', valueUbicacion.title);
               imagenUbicacion.append('color_tituloubicacion', valueUbicacion.color_title);
               imagenUbicacion.append('size_tituloubicacion', '15');
               imagenUbicacion.append('descripcion_ubicacion', valueUbicacion.description);
               imagenUbicacion.append('color_descripcionubicacion', valueUbicacion.color_description);
               imagenUbicacion.append('size_descripcionubicacion', '13');
               imagenUbicacion.append('color_fondo', valueUbicacion.color_fondo);
               imagenUbicacion.append('url_ubicacion', valueUbicacion.url);
               saveImageUbicacion.data && imagenUbicacion.append('img_ubicacion', saveImageUbicacion.data || null);
               imagenUbicacion.append('img_width', '632');
               imagenUbicacion.append('img_height', '516');
               const responseUbicacion = await LeadsFormService.InsertSectionUbicacion(imagenUbicacion)
               CreateSecciones.push(responseUbicacion.data.id)
            }
            if (checkSeccionesSeleccionadas.view_Formulario == true) {
               let arrayInputs = [];
               inputsLeads.map((value) => {
                  arrayInputs.push(value.id)
               })

               let arrayChannel = [];
               channelLeads.map((value) => {
                  arrayChannel.push(value.id)
               })


               const arrayJsonInputs = JSON.stringify(arrayInputs)
               const arrayJsonChannel = JSON.stringify(arrayChannel)
               const bypass = await bypassService.postCrearEmpresa(`${idCampana}`);
               if (bypass) {
                  const resp = await bypassService.postCrearCampaña(`${idCampana}`, arrayJsonInputs, arrayJsonChannel, bypass.id,dataUri?dataUri:'view_leads_form',  urlTemplate);
                  setUrlLeads(resp)
                  if (resp) {
                     setResLink(resp)
                     const imagenFormulario = new FormData();
                     imagenFormulario.append('idcampana', idCampana);
                     imagenFormulario.append('titulo_formulario', valueForm.title);
                     imagenFormulario.append('color_tituloformulario', valueForm.color_title);
                     imagenFormulario.append('size_tituloformulario', '15');
                     imagenFormulario.append('descripcion_formulario', valueForm.description);
                     imagenFormulario.append('color_descripcionformulario', valueForm.color_description);
                     imagenFormulario.append('size_descripcionformulario', '13');
                     imagenFormulario.append('color_fondo', valueForm.color_fondo);
                     saveImageFormulario.data && imagenFormulario.append('img_formulario', saveImageFormulario.data || null);
                     // imagenFormulario.append('img_formulario', saveImageFormulario.data);
                     imagenFormulario.append('img_width', '430');
                     imagenFormulario.append('img_height', '230');
                     const responseFormulario = await LeadsFormService.InsertSectionForm(imagenFormulario)
                     CreateSecciones.push(responseFormulario.data.id)
                  }
               }

            }
            if (checkSeccionesSeleccionadas.view_Video == true) {
               const imagenVideo = new FormData();
               imagenVideo.append('hipervinculo', valueVideo.url);
               imagenVideo.append('color_fondo', valueVideo.color_fondo);
               const responseVideo = await LeadsFormService.InsertSectionVideo(imagenVideo)
               CreateSecciones.push(responseVideo.data.id)
            }
            if (checkSeccionesSeleccionadas.view_Main == true) {
               const imagenMain2 = new FormData();
               imagenMain2.append('idcampana', idCampana);
               imagenMain2.append('color_fondo', valueMainGeneral.color_fondo);
               imagenMain2.append('img_main0', saveImageMain.data);
               imagenMain2.append('img_main0_width', '632');
               imagenMain2.append('img_main0_height', '356');
               imagenMain2.append('img_main1', saveImageMain2.data);
               imagenMain2.append('img_main1_width', '632');
               imagenMain2.append('img_main1_height', '356');
               imagenMain2.append('img_main2', saveImageMain3.data);
               imagenMain2.append('img_main2_width', '632');
               imagenMain2.append('img_main2_height', '356');
               imagenMain2.append('img_main3', saveImageMain4.data);
               imagenMain2.append('img_main3_width', '632');
               imagenMain2.append('img_main3_height', '356');
               const responseMain2 = await LeadsFormService.InsertSectionMain2(imagenMain2)
               CreateSecciones.push(responseMain2.data.id)
            }
            const data = {
               idsecciones: JSON.stringify(CreateSecciones).replace(/\\n/g, ''),
               idcampana: idCampana,
            }
            const response = await LeadsFormService.CreateViewForm(data);
            if (response.data) {
               CreateSecciones = [];
               setOpenCreate(false)
               setOpenLeads(true)
            }
         } else {
            setOpenModalValidate(true)
         }
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Para la generación adecuada de la Vista, necesitamos complete la Sección Formulario "
         }));
      }

   }
   const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   const validateHeaderComponent = () =>{
      if(!validateComponentsHeader.disfuminado)
      {
           return  `url(${saveImageHeaderFondo.path})`;
      }
      else if(validateComponentsHeader.disfuminado)
      {
         return  `linear-gradient(${valueHeader.color_fondo_disfuminado}),url(${saveImageHeaderFondo.path})`;
      }
   }

   const renderMenu = (
      <Menu
         id="fade-menu"
         MenuListProps={{
            'aria-labelledby': 'fade-button',
         }}
         anchorEl={anchorEl}
         open={isMenuOpen}
         onClose={handleMenuClose}
         TransitionComponent={Fade}
      >
         <MenuItem sx={{ color: '#5C6C85', fontWeight: '700', fontSize: '0.95em' }} onClick={handleMenuClose} >Limpiar Todo</MenuItem>
         <MenuItem sx={{ color: '#5C6C85', fontWeight: '700', fontSize: '0.95em' }} onClick={handleMenuClose} >Seleccionar Todo</MenuItem>
      </Menu>
   );

   return (
      <>
         <Grid
            container
            direction="row"
         >
            <Grid item container direction='row' xs={6} sx={{ borderRight: '4px solid #e0dbdb', height: '100vh' }}>
               <Grid item xs={9} sx={{ p: 2, overflowY: 'auto', maxHeight: '100vh', }}>
                  {/* <SettingsIcon color='primary' sx={{ml:'430px'}} onClick={handleProfileMenuOpen}/> */}
                  {mostrarFormulario()}
               </Grid>
               <Grid item container xs={3} direction='column' justifyContent="center" alignItems="center" sx={{ bgcolor: '#f3f3f3', borderLeft: '4px solid #e0dbdb' }}>

                  <Grid item xs={10}>
                  <div style={{marginTop:'15px'}}>
                  <span
                     style={{
                        fontWeight: 600,
                        // background: '#ccdbe8',
                        fontSize:'13px',
                        textAlign:'center'
                     }}
                  > AGREGAR TODAS LAS SECCIONES <Checkbox  onChange={(e) => { selectAll(e)}} /> </span>
                  </div>
                     <Box sx={{ mt: 10,ml:3}}>
                        <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                           {steps.map((label, index) => {
                              const NavId = '#' + label;
                              return (
                                 <Step key={index} >
                                    {
                                       <a href={NavId} style={{ textDecoration: 'none' }}>
                                          <StepButton color="inherit" onClick={handleStep(index)}>
                                             {label}
                                          </StepButton>
                                       </a>
                                    }
                                 </Step>
                              );
                           })}
                        </Stepper>
                     </Box>
                  </Grid>
                  <Grid item xs={2}>
                     <Button variant='contained' sx={{ mt: 2 }} onClick={() => { ValidarSeccionesCrear() }}>
                        GUARDAR CAMBIOS
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={6} sx={{ overflowY: 'scroll', maxHeight: '100vh', bgcolor: '#DEEAFA' }}>
               <Grid item lg={12} container >
                  <Box id='body' style={{ padding: '1px' }}>
                     <div id='Header'>
                        <Box id='header'>
                           <div>
                              <div style={{
                                 backgroundImage: validateHeaderComponent(),
                                 backgroundSize: '100%',
                                 backgroundRepeat: 'no-repeat',
                                 width: '100%',
                                 height: '70vh',
                                 position: 'relative',
                              }}>
                                 <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '50vh',
                                 }}>
                                    <div>
                                           {saveImageHeader.preview}
                                    </div>
                                    <div>
                                          <p style={{ color: valueHeader.color_slogan, fontSize: '30px', fontWeight: 700, textAlign: 'center' }}>
                                             <strong>{valueHeader.slogan}</strong>
                                          </p>
                                    </div>
                                    <div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </Box>
                     </div>
                     <Box id='section' sx={{ bgcolor: valueSection.color_fondo }}>
                        <div id='Seccion' style={{ padding: '20px 40px' }}>
                           <Grid item container direction={'row'} justifyContent="space-around"
                              alignItems="center" >
                              <Grid item xs={5}>
                                 {valueSection.title ? <p style={{ fontSize: '15px', color: valueSection.color_title, paddingBottom: '10px', fontWeight: 600, }}>
                                    {valueSection.title}
                                 </p> :
                                    <Skeleton variant="text" sx={{ mb: 3 }} />
                                 }
                                 {valueSection.description ? <p style={{ fontSize: '13px', color: valueSection.color_description, paddingBottom: '10px', }}>
                                    {valueSection.description}
                                 </p> :
                                    <>
                                       <Skeleton variant="text" sx={{ mb: 1 }} />
                                       <Skeleton variant="text" sx={{ mb: 1 }} />
                                       <Skeleton variant="text" sx={{ mb: 1 }} />
                                       <Skeleton variant="text" sx={{ mb: 1 }} />
                                    </>
                                 }
                              </Grid>
                              <Grid item xs={6} sx={{ ml: 2 }}>
                                 {saveImageSection.preview ? saveImageSection.preview :
                                    <canvas id="canvas" width={`${valueSection.white_img / 2}`} height={`${valueSection.height_img / 2}`} style={{ color: '#000', background: '#B8B9B9' }}>
                                       <span> Tu navegador no admite el elemento &lt;canvas&gt;.</span>
                                    </canvas>}
                              </Grid>
                           </Grid>
                        </div>
                     </Box>
                     <div id="Ubicacion">
                        <Box id='ubicacion' sx={{ bgcolor: valueUbicacion.color_fondo }} justifyContent='center'>
                           <div style={{ padding: '20px 40px' }}>
                              {valueUbicacion.title ? <p style={{ fontSize: '15px', color: valueUbicacion.color_title, paddingBottom: '10px', fontWeight: 600, textAlign: 'center' }}>
                                 {valueUbicacion.title}
                              </p> :
                                 <Skeleton variant="text" sx={{ mb: 2 }} />
                              }

                              {valueUbicacion.description ? <p style={{ fontSize: '13px', color: valueUbicacion.color_description, paddingBottom: '10px', textAlign: 'center' }}>
                                 {valueUbicacion.description}
                              </p> :
                                 <Skeleton variant="text" sx={{ mb: 2 }} />
                              }
                              {saveImageUbicacion.preview || valueUbicacion.url ? viewUbicacion() :
                                 <canvas id="canvas" width={`${valueUbicacion.white_img / 2}`} height={`${valueUbicacion.height_img / 2}`} style={{ color: '#000', background: '#B8B9B9' }}>
                                    <span> Tu navegador no admite el elemento &lt;canvas&gt;.</span>
                                 </canvas>}
                           </div>
                        </Box>
                     </div>
                     <div id="Main">
                        <Box id='main' sx={{ bgcolor: valueMainGeneral.color_fondo }} >
                           <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ pb: 2 }}>
                              <Grid item xs={11} md={5}>
                                 {saveImageMain.preview ? saveImageMain.preview :
                                    <canvas id="canvas" width={`${valueMain.white_img / 2}`} height={`${valueMain.height_img / 2}`} style={{ color: '#000', background: '#B8B9B9' }}>
                                       <span> Tu navegador no admite el elemento &lt;canvas&gt;.</span>
                                    </canvas>}
                              </Grid>
                              <Grid item xs={11} md={5}>
                                 {saveImageMain2.preview ? saveImageMain2.preview :
                                    <canvas id="canvas" width={`${valueMain2.white_img / 2}`} height={`${valueMain2.height_img / 2}`} style={{ color: '#000', background: '#B8B9B9' }}>
                                       <span> Tu navegador no admite el elemento &lt;canvas&gt;.</span>
                                    </canvas>}
                              </Grid>
                              <Grid item xs={11} md={5}>
                                 {saveImageMain3.preview ? saveImageMain3.preview :
                                    <canvas id="canvas" width={`${valueMain3.white_img / 2}`} height={`${valueMain3.height_img / 2}`} style={{ color: '#000', background: '#B8B9B9' }}>
                                       <span> Tu navegador no admite el elemento &lt;canvas&gt;.</span>
                                    </canvas>}
                              </Grid>
                              <Grid item xs={11} md={5}>
                                 {saveImageMain4.preview ? saveImageMain4.preview :
                                    <canvas id="canvas" width={`${valueMain4.white_img / 2}`} height={`${valueMain4.height_img / 2}`} style={{ color: '#000', background: '#B8B9B9' }}>
                                       <span> Tu navegador no admite el elemento &lt;canvas&gt;.</span>
                                    </canvas>}
                              </Grid>
                           </Grid>
                        </Box>
                     </div>
                     <div id="Footer">
                     </div>
                  </Box>
               </Grid>
            </Grid>
            <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               onConfirm={() => handleCreateTemplateViewForm()}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />
            <ModalViewURL
               open={openLeads}
               setOpen={setOpenLeads}
               data={urlLeads}
               direccionamiento={true}
            />
            <ModalValidateFormView
               open={openModalValidate}
               setOpen={setOpenModalValidate}
               data={validate}
               setData={setValidate}
            />
            <ModalUri
               open={openUri}
               setOpen={setOpenUri}
               data={dataUri}
               setData={setDataUri}
            />
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1, bgcolor: '#fff' }}
               open={openCreate}
            >
               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{fontSize:'30px',color:'#000'}}><strong>Generando una nueva Campaña... </strong></h1>
               </Grid>
            </Backdrop>
            {renderMenu}
         </Grid>
      </>
   )
};
