
import { AreaNegocioRepository } from "../repositories/AreaNegocio.repository";

export const AreaNegocioService= {
   getArea,
   getAreaPage,
   createArea,
   updateArea,
   deleteArea,
   getAreaSearch,
   createTrabajador,
   getUsersByArea
};

async function getArea () {
    const resp= await AreaNegocioRepository.getArea();
    return resp;
 }
async function updateArea (id, descripcion) {
   const resp= await AreaNegocioRepository.updateArea(id,descripcion);
   return resp;
}
async function createArea (descripcion) {
   const resp= await AreaNegocioRepository.createArea(descripcion);
   return resp;
}
function deleteArea(id) {
   return AreaNegocioRepository.deleteArea(id)
}

async function getAreaPage(perPage, page) {
   const resp= await AreaNegocioRepository.getAreaPage(perPage, page);
   return resp
}

async function getAreaSearch(term, perPage, page) {
   const resp= await AreaNegocioRepository.getAreaSearch(term,perPage, page);
   return resp
}
async function createTrabajador(dataUser) {
   const resp= await AreaNegocioRepository.createTrabajador(dataUser);
   return resp
}
async function getUsersByArea(idarea) {
   const resp= await AreaNegocioRepository.getUsersByArea(idarea);
   return resp
}

