import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, Autocomplete, FormControl, Grid, FormControlLabel, Checkbox, InputAdornment, Typography, FormHelperText } from '@mui/material'
import { DownloadIcon, PencilIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import { Props } from "./ModalAddTask.type";
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CampaignIcon from '@mui/icons-material/Campaign';
import RepeatIcon from '@mui/icons-material/Repeat';
import './Modal.sass'
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import { campaingService } from '@/service/services/Campaing.service';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { contactService } from '@/service/services/Contact.service';
import { taskService } from '@/service/services/Task.service';
import { formatDate } from '@fullcalendar/react';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';
import { bypassService } from '@/service/services/Bypass.service';
import { ROLE_ADMIN, ROLE_VENDEDOR, ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import LinearProgress from '@mui/material/LinearProgress';
const initialFormValues = {
   id: "",
   idtipofeeds: 0,
   idprioridad: 0,
   idcliente: "",
   fechacreacion: "",
   horainicio: "",
   fechacierre: "",
   horafin: "",
   comentarios: "",
   idcampananegocio: "",
   idfrecuencia: "",
   tipo: ''
}
export const ModalAddTask: React.FC<Props> = ({
   open, taskAdd, setOpen, taskEdit, setTaskEdit, taskUpdate, sentDateData, setSentDateData, setOpenDetailsEvent,
}): JSX.Element => {
   const [formValues, setFormValues] = useState(initialFormValues);
   const [idCliente, setIdCliente] = useState(null);
   const [idasignado, setIdAsignado] = useState(null);
   const { id = "", idtipofeeds = "", tipo = "", idprioridad = "", idcliente = "", idcampananegocio = "", idfrecuencia = "", fechacreacion = "", horainicio = "", fechacierre = "", horafin = "", comentarios = "" } = formValues;
   const data = readLocalStorage(KEY_USER_DATA)
   const [error, setError] = useState(null);
   const [dataUser, setDataUser] = useState<any>([])
   const [visibleClient, setVisibleClient] = useState<boolean>(false);
   const [visibleClientLeads, setVisibleClientLeads] = useState<boolean>(false);
   const [visibleClientGeneral, setVisibleClientGeneral] = useState<boolean>(false);
   const [visibleRepeat, setVisibleRepeat] = useState<boolean>(false);
   const [visibleCampaing, setVisibleCampaing] = useState<boolean>(false);
   const [visibleVendedor, setVisibleVendedor] = useState<boolean>(true);
   const [visibleCliente, setVisibleCliente] = useState<boolean>(true);
   const [visibleProgress, setVisibleProgress] = useState<boolean>(false);
   const [idCampaign, setIdCampaign] = useState(null);
   const [dataCampaign, setDataCampaign] = useState<any>([]);
   const [listCampaing, setListCampaing] = useState<any>([]);
   const [dataClientes, setDataClientes] = useState<any>([]);
   const [dataFrecuencia, setDataFrecuencia] = useState<any>([]);
   const [dataTipoTarea, setDataTipoTarea] = useState<any>([]);
   const [dataPrioridad, setDataPrioridad] = useState<any>([]);
   const [formCliente, setFormCliente] = useState<any>([]);
   const [clienteGeneral, setClienteGeneral] = useState<any>([]);
   const [dataFormClientes, setDataFormClientes] = useState<any>([]);
   const [dataClientesGenerales, setDataClientesGenerales] = useState<any>([]);
   const [dataUsuario] = useLocalStorage(KEY_USER_DATA, undefined);
   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
   const checkedIcon = <CheckBoxIcon fontSize="small" />;
   useEffect(() => {
      getFeedsData();
      getDataUser(data.user.idempresacliente)
      if (taskEdit) {
         taskEdit.idfrecuencia && setVisibleRepeat(true)
         taskEdit.idfrecuencia && setVisibleCampaing(true)
         const dateCreacion = dateFormat(taskEdit.fechacreacion)
         const timeCreacion = dateFormatTime(taskEdit.fechacreacion)
         const dateCierre = dateFormat(taskEdit.fechacierre)
         const timeFin = dateFormatTime(taskEdit.fechacierre)
         setFormValues({
            ...taskEdit, fechacreacion: dateCreacion, horainicio: timeCreacion,
            fechacierre: dateCierre, horafin: timeFin
         });
         if (taskEdit.idcampananegocio) {
            const taskCampaign = dataCampaign.filter(campaign => campaign.id == taskEdit.idcampananegocio);
            taskCampaign.length > 0 && setIdCampaign(taskCampaign[0]);
            getContactandUserByCampaing(taskEdit?.idcampananegocio, taskEdit);
            setVisibleCampaing(true);
         } else {
            const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario);
            taskUsuario.length > 0 && setIdAsignado(taskUsuario[0]);
            const taskClientes = dataClientes.filter(cliente => cliente.idcliente == taskEdit.idcliente);
            taskClientes.length > 0 && setIdCliente(taskClientes[0]);
         }
      }
      else if (sentDateData) {
         setFormValues(sentDateData);
      }
      else {
         setFormValues(initialFormValues);
      }
   }, [taskEdit, sentDateData])

   const getSearchContacts = async (search) => {
      const resp = await contactService.getSearchContacts(search);
      setDataClientes(resp.data ? resp.data.clients : []);
   };

   const eventCampaing = (campaign) => {
      setVisibleProgress(true)
      if (!campaign) {
         getFeedsData();
         setIdAsignado(null);
         setIdCliente(null)
         setVisibleClientLeads(false);
         setVisibleClientGeneral(false)
         getDataUser(data.user.idempresacliente);
      }
      setVisibleProgress(false)
      setIdCampaign(campaign)
      getContactandUserByCampaing(campaign?.id, null);
      setVisibleVendedor(true);
   }

   async function getFeedsData() {
      const resp = await taskService.getFeedsData()
      console.log(resp)
      setDataClientes(resp.data ? resp.data.clients.data : []);
      setDataFrecuencia(resp.data ? resp.data.frecuencia : []);
      setDataCampaign(resp.data ? resp.data.campaing : []);

      setListCampaing(resp.data ? resp.data.campaing : []);
      setDataTipoTarea(resp.data ? resp.data.tipofeeds : []);
      setDataPrioridad(resp.data ? resp.data.prioridad : [])
   }

   async function GetClientAndFormClient(idcampana, iduser) {
      return await contactService.getClientesCampana(idcampana, iduser);
   }

   async function getDataUser(idempresacliente) {
      const resp = await entityService.getEntityUsers(idempresacliente);
      if (resp.message=='OK') {
         const dataUsers = resp.data.filter(user => user.idtipousuario > 3 && user.idtipousuario < 5)
         setDataUser(dataUsers)
      }else
      {
         //error en el proceso de crear una tarea
      }
   }

   async function getContactandUserByCampaing(id, taskEdit) {
      if (id) {

         const CampaignTipo = dataCampaign?.filter(campaign => campaign.id == id)
         if (CampaignTipo[0].tipo == 0 || CampaignTipo[0].tipo == 2) {
            setVisibleClientLeads(false)
            setVisibleClientGeneral(false)
            const resp = await contactService.getContactandUserByCampaing(id);

            setDataUser(resp.data.length > 0 ? resp.data : [])
            if (taskEdit) {
               const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario)
               setIdAsignado(taskUsuario[0])
               let res: any;
               if (taskUsuario.length > 0) {
                  res = await contactService.getClientesCampana(id, taskUsuario[0].idusuario);
               }
               if (res) {
                  setDataClientes(res.data ? res.data : []);
                  const taskClientes = res.data.filter(cliente => cliente.idcliente == taskEdit.idcliente)
                  setIdCliente(taskClientes[0])
               }
            }
         }
         if (CampaignTipo[0]?.tipo == 1) {
            setVisibleClientLeads(true)
            setVisibleClientGeneral(false)
            const resp = await contactService.getContactandUserByCampaing(id);
            setDataUser(resp.data.length > 0 ? resp.data : [])
            if (taskEdit) {
               setVisibleClientLeads(true)
               const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario)
               setIdAsignado(taskUsuario[0])
               let res: any;
               if (taskUsuario.length > 0) {
                  res = await contactService.getClientesCampana(id, taskUsuario[0].idusuario);
               }
               if (res) {
                  setDataFormClientes(res.data ? res.data : [])
                  const taskformClientes = res.data.filter(cliente => cliente.form_cliente == taskEdit.form_cliente)
                  const taskidClientes = res.data.filter(cliente => cliente.idcliente == taskEdit.idcliente)
                  if (taskformClientes.length > 0 && taskformClientes.length > 0) {
                     setFormCliente(taskidClientes[0])
                  }
                  else if (taskformClientes.length > 0) {
                     setFormCliente(taskformClientes[0])
                  } else {
                     setFormCliente(taskidClientes[0])
                  }
               }
            }
         }
         if (CampaignTipo[0]?.tipo == 3) {
            setVisibleClientGeneral(true)
            setVisibleClientLeads(false)
            const resp = await contactService.getContactandUserByCampaing(id);
            if(resp)
            {
               setDataUser(resp.data.length > 0 ? resp.data : [])
            }
            if (taskEdit) {
               setVisibleClientGeneral(true)
               const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario)
               setIdAsignado(taskUsuario[0])
               let res: any;
               if (taskUsuario.length > 0) {
                  res = await contactService.getClientesCampana(id, taskUsuario[0].idusuario);
               }
               if (res) {
                  setDataClientesGenerales(res.data ? res.data : [])
                  const taskformClientes = res.data.filter(cliente => cliente.form_cliente == taskEdit.form_cliente)
                  const taskidClientes = res.data.filter(cliente => cliente.idcliente == taskEdit.idcliente)
                  if (taskformClientes.length > 0 && taskformClientes.length > 0) {
                     setClienteGeneral(taskidClientes[0])
                  }
                  else if (taskformClientes.length > 0) {
                     setClienteGeneral(taskformClientes[0])
                  } else {
                     setClienteGeneral(taskidClientes[0])
                  }
               }
            }
         }
      }
   }

   const getContactandUserAndCampaing = async (id, idusuario) => {
      setVisibleProgress(true)
      const res = await contactService.getClientesCampana(id, idusuario);
      const CampaignTipo = dataCampaign?.filter(campaign => campaign.id == id)
      if (CampaignTipo[0].tipo == 0 || CampaignTipo[0].tipo == 2) {
         setDataClientes(res.data ? res.data : []);
      }
      if (CampaignTipo[0]?.tipo == 1) {
         setDataFormClientes(res.data ? res.data : [])
      }
      if (CampaignTipo[0]?.tipo == 3) {
         setDataClientesGenerales(res.data ? res.data : [])
      }
      setVisibleProgress(false)
      setVisibleCliente(true)
   }

   const handleInputChange = (e) => {
      const changedFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }
      if (e.target.name === 'idcampananegocio') {
         getContactandUserByCampaing(e.target.value, null);
      }
      idtipofeeds !== null && setError("");
      idprioridad !== null && setError("");
      fechacreacion.trim() !== "" && setError("")
      fechacierre.trim() !== "" && setError("")
      comentarios.trim() !== "" && setError("")
      setFormValues(changedFormValues);
   }

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      let newTaks = {};
      let propietario = readLocalStorage(KEY_USER_DATA);
      propietario = propietario.user.rut_empresa;
      if (taskEdit) {
         let editTaks = {};
         if (visibleClientLeads && formCliente) {
            if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
               editTaks = {
                  id: id,
                  tipo: tipo,
                  idtipofeeds: idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: idasignado.idusuario,
                  idcliente: formCliente.idcliente ? formCliente.idcliente : '',
                  form_cliente: formCliente.form_cliente ? formCliente.form_cliente : '',
                  fechacreacion: fechacreacion,
                  fechacierre: fechacierre,
                  comentarios: comentarios,
                  idcampananegocio: idCampaign ? idCampaign.id : "",
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  propietario: propietario,
               } || {}

            } else {
               editTaks = {
                  id: id,
                  tipo: tipo,
                  idtipofeeds: idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: idasignado.idusuario,
                  idcliente: formCliente.idcliente ? formCliente.idcliente : '',
                  form_cliente: formCliente.form_cliente ? formCliente.form_cliente : '',
                  comentarios: comentarios,
                  idcampananegocio: idCampaign ? idCampaign.id : "",
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                  fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                  propietario: propietario,

               } || {}
            }
         }
         else if (visibleClientGeneral && clienteGeneral) {
            if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
               editTaks = {
                  id: id,
                  tipo: tipo,
                  idtipofeeds: idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: idasignado.idusuario,
                  idcliente: clienteGeneral.idcliente ? clienteGeneral.idcliente : '',
                  form_cliente: clienteGeneral.form_cliente ? clienteGeneral.form_cliente : '',
                  fechacreacion: fechacreacion,
                  fechacierre: fechacierre,
                  comentarios: comentarios,
                  idcampananegocio: idCampaign ? idCampaign.id : "",
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  propietario: propietario,
               } || {}

            } else {
               editTaks = {
                  id: id,
                  tipo: tipo,
                  idtipofeeds: idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: idasignado.idusuario,
                  idcliente: clienteGeneral.idcliente ? clienteGeneral.idcliente : '',
                  form_cliente: clienteGeneral.form_cliente ? clienteGeneral.form_cliente : '',
                  comentarios: comentarios,
                  idcampananegocio: idCampaign ? idCampaign.id : "",
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                  fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                  propietario: propietario,

               } || {}
            }
         }
         else {
            if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
               editTaks = {
                  id: id,
                  tipo: tipo,
                  idtipofeeds: idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: idasignado.idusuario,
                  idcliente: idCliente ? idCliente.idcliente : '',
                  fechacreacion: fechacreacion,
                  fechacierre: fechacierre,
                  comentarios: comentarios,
                  idcampananegocio: idCampaign ? idCampaign.id : "",
                  form_cliente: formCliente ? formCliente.codigo : '',
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  propietario: propietario,
               } || {}

            } else {
               editTaks = {
                  id: id,
                  tipo: tipo,
                  idtipofeeds: idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: idasignado.idusuario,
                  idcliente: idCliente ? idCliente.idcliente : '',
                  comentarios: comentarios,
                  idcampananegocio: idCampaign ? idCampaign.id : "",
                  form_cliente: formCliente ? formCliente.codigo : '',
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                  fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                  propietario: propietario,

               } || {}
            }
         }
         taskUpdate(editTaks);
         LimpiarFormulario();
         setTaskEdit(null)
      } else {
         if (idtipofeeds == 0) {
            return setError("idtipofeeds");
         }
         if (idprioridad == 0) {
            return setError("idprioridad");
         }
         if (idasignado == null) {
            return setError("asignado");
         }
         if (visibleClientLeads) {
            if (formCliente == null) {
               return setError("cliente")
            }
         }
         else if (visibleClientGeneral) {
            if (clienteGeneral == null) {
               return setError("cliente")
            }
         }
         else {
            if (idCliente == null) {
               return setError("cliente");
            }
         }
         if (!fechacreacion.trim()) {
            return setError("fechacreacion");
         }
         if (!fechacierre.trim()) {
            return setError("fechacierre");
         }
         if (!comentarios.trim()) {
            return setError("comentarios");
         }
         if (fechacierre.trim() < fechacreacion.trim()) {
            return setError("fechacierremenor");
         }
         else if (fechacierre.trim() == fechacreacion.trim() && formValues.horafin.trim() < formValues.horainicio.trim()) {
            return setError("horafinmenor");
         } else {
            if (visibleClientLeads && formCliente) {
               if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
                  newTaks = {
                     idtipofeeds: idtipofeeds,
                     idprioridad: idprioridad,
                     idusuario: idasignado.idusuario,
                     idcliente: formCliente.idcliente ? formCliente.idcliente : '',
                     form_cliente: formCliente.form_cliente ? formCliente.form_cliente : '',
                     fechacreacion: fechacreacion,
                     horainicio: formValues.horainicio,
                     fechacierre: fechacierre,
                     horafin: formValues.horafin,
                     comentarios: comentarios,
                     idcampananegocio: idCampaign ? idCampaign.id : '',
                     campana: idCampaign,
                     idfrecuencia: idfrecuencia,
                     propietario: propietario
                  } || {}
                  taskAdd(newTaks);
                  LimpiarFormulario();
                  if (sentDateData) {
                     setSentDateData(null);
                  }
               } else {
                  newTaks = {
                     idtipofeeds: idtipofeeds,
                     idprioridad: idprioridad,
                     idusuario: idasignado.idusuario,
                     horainicio: formValues.horainicio,
                     horafin: formValues.horafin,
                     comentarios: comentarios,
                     idcampananegocio: idCampaign ? idCampaign.id : "",
                     idcliente: formCliente.idcliente ? formCliente.idcliente : '',
                     form_cliente: formCliente.form_cliente ? formCliente.form_cliente : '',
                     campana: idCampaign,
                     idfrecuencia: idfrecuencia,
                     fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                     fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                     propietario: propietario
                  } || {}
                  taskAdd(newTaks);
                  LimpiarFormulario();
                  if (sentDateData) {
                     setSentDateData(null);
                  }
               }

            }
            else if (visibleClientGeneral && clienteGeneral) {
               if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
                  newTaks = {
                     idtipofeeds: idtipofeeds,
                     idprioridad: idprioridad,
                     idusuario: idasignado.idusuario,
                     idcliente: clienteGeneral.idcliente ? clienteGeneral.idcliente : '',
                     form_cliente: clienteGeneral.form_cliente ? clienteGeneral.form_cliente : '',
                     fechacreacion: fechacreacion,
                     horainicio: formValues.horainicio,
                     fechacierre: fechacierre,
                     horafin: formValues.horafin,
                     comentarios: comentarios,
                     idcampananegocio: idCampaign ? idCampaign.id : '',
                     campana: idCampaign,
                     idfrecuencia: idfrecuencia,
                     propietario: propietario
                  } || {}
                  taskAdd(newTaks);
                  LimpiarFormulario();
                  if (sentDateData) {
                     setSentDateData(null);
                  }
               } else {
                  newTaks = {
                     idtipofeeds: idtipofeeds,
                     idprioridad: idprioridad,
                     idusuario: idasignado.idusuario,
                     idcliente: clienteGeneral.idcliente ? clienteGeneral.idcliente : '',
                     form_cliente: clienteGeneral.form_cliente ? clienteGeneral.form_cliente : '',
                     horainicio: formValues.horainicio,
                     horafin: formValues.horafin,
                     comentarios: comentarios,
                     idcampananegocio: idCampaign ? idCampaign.id : "",
                     campana: idCampaign,
                     idfrecuencia: idfrecuencia,
                     fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                     fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                     propietario: propietario
                  } || {}
                  taskAdd(newTaks);
                  LimpiarFormulario();
                  if (sentDateData) {
                     setSentDateData(null);
                  }
               }
            }
            else {
               if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
                  newTaks = {
                     idtipofeeds: idtipofeeds,
                     idprioridad: idprioridad,
                     idusuario: idasignado.idusuario,
                     idcliente: idCliente ? idCliente.idcliente : '',
                     form_cliente: formCliente ? formCliente.codigo : '',
                     fechacreacion: fechacreacion,
                     horainicio: formValues.horainicio,
                     fechacierre: fechacierre,
                     horafin: formValues.horafin,
                     comentarios: comentarios,
                     idcampananegocio: idCampaign ? idCampaign.id : '',
                     campana: idCampaign,
                     idfrecuencia: idfrecuencia,
                     propietario: propietario
                  } || {}
                  taskAdd(newTaks);
                  LimpiarFormulario();
                  if (sentDateData) {
                     setSentDateData(null);
                  }
               } else {
                  newTaks = {
                     idtipofeeds: idtipofeeds,
                     idprioridad: idprioridad,
                     idusuario: idasignado.idusuario,
                     idcliente: idCliente ? idCliente.idcliente : '',
                     horainicio: formValues.horainicio,
                     horafin: formValues.horafin,
                     comentarios: comentarios,
                     idcampananegocio: idCampaign ? idCampaign.id : "",
                     form_cliente: formCliente ? formCliente.codigo : '',
                     campana: idCampaign,
                     idfrecuencia: idfrecuencia,
                     fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                     fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                     propietario: propietario
                  } || {}
                  taskAdd(newTaks);
                  LimpiarFormulario();
                  if (sentDateData) {
                     setSentDateData(null);
                  }
               }
            }
         }
      }
   }
   const StatusInputs = (e) => {
      setVisibleCampaing(!visibleCampaing)
      e.target.checked && setVisibleVendedor(false);
      !e.target.checked && setVisibleVendedor(true);
      e.target.checked && setVisibleCliente(false);
      !e.target.checked && setVisibleCliente(true);
      setIdCampaign(null)
      setIdAsignado(null)
      setIdCliente(null)
      setFormCliente(null)
      setClienteGeneral(null)
      setVisibleClientLeads(false)
      setVisibleClientGeneral(false)
      getFeedsData();
   }
   const ValidatorProspecto = () => {
      if (visibleClientLeads && visibleCliente) {
         return (
            <Autocomplete
               autoComplete
               sx={{ bgcolor: '#fff' }}
               size='small'
               includeInputInList
               value={formCliente}
               onChange={(e, form_cliente) => {
                  if (form_cliente) {
                     setFormCliente(form_cliente)
                     setError("");
                  }
               }}
               id="cliente"
               options={dataFormClientes}
               getOptionLabel={(option: any) => (option.nombre || '')}
               renderInput={(params) => <TextField {...params} label="Prospecto*" error={error === "cliente" ? true : false} helperText={error === "cliente" ? "Cliente es un campo requerido" : ""} />}
            />
         )
      }
      else if (visibleClientGeneral && visibleCliente) {
         return (
            <Autocomplete
               autoComplete
               sx={{ bgcolor: '#fff' }}
               size='small'
               includeInputInList
               value={clienteGeneral}
               onChange={(e, value) => {
                  if (value) {
                     setClienteGeneral(value)
                     setError("");
                  }
               }}
               id="cliente"
               options={dataClientesGenerales}
               getOptionLabel={(option: any) => (option.nombre || '')}
               renderInput={(params) => <TextField {...params} label="Prospecto*" error={error === "cliente" ? true : false} helperText={error === "cliente" ? "Cliente es un campo requerido" : ""} />}
            />
         )
      } else if (visibleCliente) {
         return (
            <Autocomplete
               //disabled={isDisabled}
               autoComplete
               sx={{ bgcolor: '#fff' }}
               size='small'
               includeInputInList
               value={idCliente}
               onChange={(e, cliente) => {
                  if (cliente) {
                     setIdCliente(cliente)
                     setError("");
                  }
               }}
               id="cliente"
               options={dataClientes}
               getOptionLabel={(option: any) => (option.nombre_rz || '')}
               renderInput={(params) => <TextField {...params} label="Cliente*" error={error === "cliente" ? true : false} helperText={error === "cliente" ? "Cliente es un campo requerido" : ""} />}
            />
         )
      }
   }
   const LimpiarFormulario = () => {
      setFormValues(initialFormValues);
      setIdCliente(null);
      setIdAsignado(null);
      setIdCampaign(null);
      setFormCliente(null)
      setClienteGeneral(null)
      setVisibleRepeat(false)
      setVisibleCampaing(false)
      setVisibleClientLeads(false)
      setVisibleClientGeneral(false)
      setVisibleCliente(true)
      setVisibleVendedor(true)
      setVisibleProgress(false)
      setError('')
      setOpen(false)
      if (taskEdit) {
         setOpenDetailsEvent(false)
      }
   }
   return (
      <>
         <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box className='Modal' >
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column'
                  }}>
                  <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                     {taskEdit ? 'Editar Tarea' : 'Crear Tarea'}
                  </Typography>
                  <form onSubmit={handleSubmit} >
                     <Grid container direction="row" spacing={2} mt={2}>
                        <Grid item xs={12} md={6}>
                           <FormControl fullWidth error={error === "idtipofeeds" ? true : false}>
                              <InputLabel id="tipo">Tipo</InputLabel>
                              <Select
                                 labelId="tipo"
                                 id="tipo"
                                 sx={{ bgcolor: '#fff' }}
                                 size="small"
                                 fullWidth
                                 name="idtipofeeds"
                                 value={idtipofeeds}
                                 label="Tipo"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    formValues.tipo == 'Llamada' ?
                                       <InputAdornment position='start'>
                                          <PhoneIcon />
                                       </InputAdornment>
                                       : formValues.tipo == 'Correo' ?
                                          <InputAdornment position='start'>
                                             <EmailIcon />
                                          </InputAdornment>
                                          : formValues.tipo == 'Reunion' ?
                                             <InputAdornment position='start'>
                                                <VideocamIcon />
                                             </InputAdornment> :
                                             <InputAdornment position='start'>
                                                <AssignmentIcon />
                                             </InputAdornment>
                                 }
                              >
                                 {dataTipoTarea.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "idtipofeeds" ? "Tipo Tarea es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <FormControl fullWidth error={error === "idprioridad" ? true : false}>
                              <InputLabel id="idprioridad">Prioridad</InputLabel>
                              <Select
                                 labelId="idprioridad"
                                 id="idprioridad"
                                 sx={{ bgcolor: '#fff' }}
                                 size="small"
                                 fullWidth
                                 name="idprioridad"
                                 value={idprioridad}
                                 label="idprioridad*"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    <InputAdornment position="start">
                                       <TimelapseIcon />
                                    </InputAdornment> }
                              >
                                 {dataPrioridad.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.prioridad}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "idprioridad" ? "Prioridad es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>
                        {taskEdit?.idcampananegocio && (<Grid item xs={visibleCampaing ? 6 : 12}>
                           <FormControlLabel
                              value="end"
                              control={<Checkbox checked={visibleCampaing ? true : false} onChange={StatusInputs}
                              />}
                              label="Asignar a una Campaña"
                              labelPlacement="end"
                           />
                        </Grid>)}
                        {taskEdit == null && (<Grid item xs={visibleCampaing ? 6 : 12}>
                           <FormControlLabel
                              value="end"
                              control={<Checkbox checked={visibleCampaing ? true : false} onChange={StatusInputs}
                              />}
                              label="Asignar a una Campaña"
                              labelPlacement="end"
                           />
                        </Grid>)}
                        {visibleCampaing && (
                           <Grid item xs={6} >
                              <Autocomplete
                                 autoComplete
                                 sx={{ bgcolor: '#fff' }}
                                 size='small'
                                 includeInputInList
                                 value={idCampaign}
                                 onChange={(e, campaign) => {
                                    if (campaign) {
                                       eventCampaing(campaign);
                                    } else {
                                       setIdAsignado(null)
                                       setIdCliente(null)
                                       setClienteGeneral(null)
                                       setFormCliente(null)
                                       setVisibleVendedor(false);
                                       setVisibleCliente(false)
                                    }
                                 }}
                                 id="campaign"
                                 options={dataCampaign}
                                 getOptionLabel={(option) => option.nombre}
                                 renderInput={(params) => <TextField {...params} label="Campaña" error={error === "campaign" ? true : false} helperText={error === "campaign" ? "Campaign es un campo requerido" : ""} />}
                              />
                           </Grid>
                        )}
                        {visibleVendedor && (<Grid item xs={12} >
                           <Autocomplete
                              autoComplete
                              sx={{ bgcolor: '#fff' }}
                              size='small'
                              includeInputInList
                              value={idasignado}
                              onChange={(e, asignado) => {
                                 if (asignado) {
                                    setIdAsignado(asignado)
                                    setError("");
                                 }
                                 if (idCampaign && asignado) {
                                    getContactandUserAndCampaing(idCampaign.id, asignado.idusuario)
                                    setError("");
                                 }
                                 else {
                                    setIdCliente(null)
                                    setClienteGeneral(null)
                                    setFormCliente(null)
                                 }
                                 idasignado !== null && setError("");
                              }}
                              id="asignado"
                              options={dataUser}
                              getOptionLabel={(option) => option.nombre}
                              renderInput={(params) => <TextField {...params} label={"Atendido por..*"} error={error === "asignado" ? true : false} helperText={error === "asignado" ? "Vendedor es un campo requerido" : ""} />}
                           />
                        </Grid>)}
                        <Grid item xs={12} >
                           {ValidatorProspecto()}
                        </Grid>
                       {visibleProgress && <Grid item xs={12} >
                           <LinearProgress color="primary" />
                        </Grid>}
                        <Grid item xs={12} md={6} >
                           <TextField
                              fullWidth
                              error={error === "fechacreacion" ? true : false}
                              helperText={error === "fechacreacion" ? " La Fecha de Inicio es un campo requerido" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              sx={{ bgcolor: '#fff' }}
                              size="small"
                              label="Fecha Inicio"
                              id="fechacreacion"
                              name="fechacreacion"
                              type="date"
                              autoFocus
                              value={fechacreacion}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              sx={{ bgcolor: '#fff' }}
                              size="small"
                              label="Hora Inicio"
                              id="horainicio"
                              name="horainicio"
                              type="time"
                              autoFocus
                              value={horainicio}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              fullWidth
                              error={error === "fechacierre" ? true : false}
                              helperText={error === "fechacierre" ? " La Fecha de Fin es un campo requerido" : error === "fechacierremenor" ? "Seleccione una fecha superior" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              sx={{ bgcolor: '#fff' }}
                              size="small"
                              id="fechacierre"
                              label="Fecha Fin"
                              name="fechacierre"
                              type="date"
                              autoFocus
                              value={fechacierre}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              fullWidth
                              error={error === "horafinmenor" ? true : false}
                              helperText={error === "horafinmenor" ? "Seleccione una hora superior" : ""}
                              InputLabelProps={{ shrink: true }}
                              sx={{ bgcolor: '#fff' }}
                              size="small"
                              id="horafin"
                              label="Hora Fin "
                              name="horafin"
                              type="time"
                              value={horafin}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} >
                           <TextField
                              fullWidth
                              error={error === "comentarios" ? true : false}
                              helperText={error === "comentarios" ? " La Nota es un campo requerido" : ""}
                              sx={{ bgcolor: '#fff' }}
                              size="small"
                              label="Nota"
                              id="comentarios"
                              inputProps={{
                                 maxLength: 200,
                              }}
                              name="comentarios"
                              multiline
                              value={comentarios}
                              onChange={handleInputChange}
                              InputProps={{
                                 startAdornment: (
                                    <InputAdornment position="start">
                                       <NoteAddIcon />
                                    </InputAdornment>
                                 ),
                              }}
                           />
                        </Grid>
                       {taskEdit == null && <Grid item xs={visibleRepeat ? 4 : 12}>
                           <FormControlLabel
                              value="end"
                              control={<Checkbox checked={visibleRepeat ? true : false} onClick={() => { setVisibleRepeat(!visibleRepeat) }} />}
                              label="Repetir"
                              labelPlacement="end"
                           />
                        </Grid>}
                        {visibleRepeat && (
                           <Grid item xs={8} >
                              <FormControl fullWidth>
                                 <Grid>
                                    <InputLabel id="tipo">Seleccionar</InputLabel>
                                 </Grid>
                                 <Select
                                    sx={{ bgcolor: '#fff' }}
                                    labelId="idfrecuencia"
                                    id="idfrecuencia"
                                    size="small"
                                    fullWidth
                                    name="idfrecuencia"
                                    value={idfrecuencia}
                                    label="TipoRepeticion"
                                    onChange={handleInputChange}
                                    startAdornment={
                                       <InputAdornment position="start">
                                          <RepeatIcon />
                                       </InputAdornment>
                                    }
                                 >
                                    {dataFrecuencia.map((option, i) => {
                                       return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                    })
                                    }
                                 </Select>
                              </FormControl>
                           </Grid>
                        )}
                        <Grid item xs={12} md={6} >
                           {taskEdit ? (
                              <Button
                                 onClick={() => {
                                    setTaskEdit(null)
                                    setOpen(false)
                                    LimpiarFormulario();
                                 }}
                                 variant="contained"
                                 fullWidth
                                 color="error"
                                 endIcon={<CancelIcon />}
                                 sx={{
                                    background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                              >
                                 Cancelar Edición
                              </Button>) : (<Button
                                 onClick={() => { LimpiarFormulario() }}
                                 variant="contained"
                                 fullWidth
                                 color="error"
                                 endIcon={<CancelIcon />}
                                 sx={{
                                    background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                              >
                                 Cancelar
                              </Button>)}
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              endIcon={taskEdit ? <EditIcon /> : <SaveIcon />}
                              sx={{
                                 background: '#155172', mt: "10px", color: '#fff', '&:hover': {
                                    bgcolor: '#155172'
                                 }
                              }}
                           >
                              {taskEdit ? 'Actualizar' : 'Agregar'}
                           </Button>
                        </Grid>
                     </Grid>
                  </form>
               </Box>
            </Box>
         </Modal>
      </>
   );
}
