import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Rule.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, InputLabel, FormControlLabel, MenuItem, FormHelperText, FormControl , Select, TextField, Typography, Button, Avatar, Autocomplete, Checkbox, Backdrop, CircularProgress, IconButton, Snackbar, Alert } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { ModalNewCampaign } from '@/components/common/Modal';
import { ruleService } from '@/service/services/Rule.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ModalDetailRule } from '@/components/common/Modal/ModalDetailRule';
import { productService } from '@/service/services/Product.service';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import { SpinnerGrow } from '@/components/common/Spinner';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { ModalRule } from './ModalRule/ModalRule';
import { ROUTE_PRODUCT } from '@/toolbox/constants/route-map';
import { Link, useHistory } from 'react-router-dom';
import { campaingService } from '@/service/services/Campaing.service';
import { makeStyles } from "@mui/styles";

const initialFormValues = {
   id: '',
   nombre: '',
   idtipoRegla: '',
   fechainicio: '',
   fechafin: '',
   productoscomprados: '',
   productoscomplementarios: '',
   idempresa: '',
   propietario: '',
   action: ''
}

export const RuleView: React.FC<Props> = ({ }): JSX.Element => {
   const history = useHistory();
   const header = [
      { name: 'nombre', label: 'Nombre', width: '' },
      { name: 'name', label: 'Estrategia Comercial', width: '' },//name del tipo regla
      { name: 'fechainicio', label: 'Fecha de Inicio', width: '' },
      { name: 'fechafin', label: 'Fecha de Fin', width: '' },
      // { name: 'idempresa', label: 'Datos Usuario', width: '90rem' },
      //{ name: 'productoscomprados', label: 'Productos Comprados', width: '' },
      //{ name: 'productoscomplementarios', label: 'Productos Complementarios', width: '' }
   ];
   const dataActionAdmin = [
      { name: 'view', color: 'primary' },
      { name: 'edit', color: 'primary' },
      { name: 'delete', color: 'secondary' },
   ]
   const dataAction = [
      { name: 'view', color: 'primary' },
   ]
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const useStyles = makeStyles({
      customDisable: {
         "& .MuiInputBase-input.Mui-disabled": {
            color: "red !important",
            "-webkit-text-fill-color": "black !important",
            borderColor: "red !important"
         },
      }
   });
   const classes = useStyles();

   const [isDetail, setIsDetail] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [open2, setOpen2] = useState(false);
   const [openProComple, setOpenProComple] = useState(true);
   const [openProRelac, setOpenProRelac] = useState(true);
   const [openDetail, setOpenDetail] = useState(false);
   const [openView, setOpenView] = useState(false);
   const [dataRule, setDataRule] = useState<any>([]);
   const [dataInitial, setDataInitial] = useState<any>([]);
   const [fecha, setFecha] = useState<any>([]);
   const [error, setError] = useState(null);
   const [formValues, setFormValues] = useState(initialFormValues);
   const [dataTipoRegla, setDataTipoRegla] = useState<any>([])
   const [valueProComplementarios, setvalueProComplementarios] = useState<any>();
   const [valueProComprados, setvalueProComprados] = useState<any>();
   const [valueProRelacionados, setvalueProRelacionados] = useState<any>();
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [productRelacionado, setProductRelacionado] = useState<any>([])
   const [productComplementarios,setProductComplementarios] = useState<any>([])
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [busqueda, setBusqueda] = useState<any>("");
   const [openRule,setOpenRule] = useState<boolean>(false)
   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [Total, setTotal] = useState<any>(0)
   const [decisionRelacion, setDecisionRelacion] = useState<number>(0);

   const [dataIntervalo, setDataIntervalo] = useState<any>({})

   const [intervaloSelect, setIntervaloSelect] = useState<any>({})
   const [limiteSelect, setLimiteSelect] = useState<any>({})
   const [ProMantencion, setProMantencion] = useState<any>([])

   const [data, setData] = useState({
      productos:[],
      productoscomprados: [],
      productoscomplementarios: [],
      productosRelacionados: []
   })

   const {
      nombre,
      idtipoRegla,
      fechainicio,
      fechafin,
      productoscomprados,
      productoscomplementarios,
      idempresa,
      propietario,
   } = formValues

   useEffect(() => {
      try{
         getRulePage();
         getProductType();
         getRuleType()
      }catch(e){
         console.log(e)
      }
      // getDataInicial();
   }, [pageData,perPageData])

   async function getRulePage() {
      setOpenView(true)
      const resp = await ruleService.getRulePage(perPageData,pageData)
      const data = resp?.data;

      setDataRule(data.data);
      setTablaBusqueda(data)
      setOpenView(false)
      setTotal(data.total)

   }

   async function searchDate(date) {
     if(date)
     {
      setOpenView(true)
      const dateFecha = {
         fecha: date
      }
      const resp = await ruleService.searchDateBussinessRule(dateFecha)
      console.log(resp)
      setDataRule(resp.data);
      setOpenView(false)

     }
   }

   async function searchNameRules(word) {
      setSearch(word)
      const dateSearchName = {
         word: word
      }
      if(word.length>3)
      {
      setOpenView(true)
      const resp = await ruleService.searchBussinessRule(dateSearchName)
      setDataRule(resp.data);
      setOpenView(false)

       console.log(resp)
      }
      if(word.length==0)
      {
         getRulePage()
      }

   }

   const PerPage = async (data) =>{

      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) =>{

      setPageData(data+1)
   }

   const getSearchRule = async (search) => {
      try{
         const resp= await ruleService.getSearchRule(search);
         setDataRule(resp.data);

       }catch(e){
          console.log(e)
       }
    };

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  getSearchRule(value)
               }
               if(value.length==0)
               {
                  getRulePage();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };

   async function getProductChildrenForIDs(products, tipo){
         const resp = await productService.getProductChildrenForIDS(products,tipo)
         if(resp.data.productos.length > 0){
            if(tipo == 1){
               setProductComplementarios(resp.data.productos)
            }
            if(tipo == 2){
               setProductRelacionado(resp.data.productos)
            }
         }else{
            console.log(decisionRelacion)
            //cuando en el modal presiona saltar (3), cuando no se ha modificado el estado inicial (0)
            if( decisionRelacion == 0 ){
               setOpenRule(true)
            }
         }

   }

   useEffect(()=>{
      // cuando en el modal dice que lo diriga al panel de productos
      if(decisionRelacion == 1){
         const productoRedict = valueProComprados[0];
         console.log(productoRedict)
         history.push(`/producto/relaciones/${productoRedict?.id}`,productoRedict)
      }
      getProductRelationsInterval()
   },[decisionRelacion])

   useEffect(()=>{
      if(idtipoRegla == '1'){
         setProMantencion([])
         setvalueProRelacionados([])
      }

      if(idtipoRegla == '3'){
         setvalueProComplementarios([])
      }

      if(idtipoRegla == '4' || idtipoRegla == '2'){
         setProMantencion([])
         setvalueProRelacionados([])
         setvalueProComplementarios([])
      }

   },[idtipoRegla])

   async function getDataInicial() {
      const resp = await ruleService.getDataInitial()
      setDataInitial(resp.data.product);
   }

   async function getProductRelationsInterval(){
      const resp = await productService.getProductRelationsInterval()
      setDataIntervalo(resp.data)
   }

   async function getProductType() {
      const resp = await productService.getProductType();
      setData(prev => ({
         ...prev,
         productos:resp.data.productos?resp.data.productos:[],
         productoscomprados: resp.data.productosPostVenta?resp.data.productosPostVenta:[],
         productoscomplementarios: resp.data.productosComplementario?resp.data.productosComplementario:[],
         productosRelacionados: resp.data.productosRelacionado? resp.data.productosRelacionado:[]
      }))

      // setvalueProComplementarios(resp.data.productosComplementario);
      // setvalueProComprados(resp.data.productos);
      // setvalueProRelacionados(resp.data.productosRelacionado);
   }

   async function getRuleType () {
      const resp = await ruleService.getRuleType();
      setDataTipoRegla(resp.data)
   }


   const RecuperarData = async (data) => {
      const id = data.id;

      if (data.action == 'edit') {
         setFormValues(data)

         let productoscomprados = data.productoscomprados
         productoscomprados = JSON.parse(productoscomprados)
         setvalueProComprados(productoscomprados);

         let productoscomplementarios = data.productoscomplementarios
         // if(JSON.parse(productoscomplementarios)!==null){
         //    setOpenProComple(true)
         // }
         productoscomplementarios = JSON.parse(productoscomplementarios)==null?[]: JSON.parse(productoscomplementarios)
         setvalueProComplementarios(productoscomplementarios);

         let productosrelacionados = data.productosrelacionados
         // if(JSON.parse(productosrelacionados)!==null){
         //    setOpenProRelac(true)
         // }
         productosrelacionados = JSON.parse(productosrelacionados)==null?[]: JSON.parse(productosrelacionados)
         setvalueProRelacionados(productosrelacionados);


         setOpen2(true)

      }
      else if (data.action == 'view') {

         let productoscomplementarios = data.productoscomplementarios
         productoscomplementarios = JSON.parse(productoscomplementarios)==null?[]: JSON.parse(productoscomplementarios)
         setvalueProComplementarios(productoscomplementarios);

         let productoscomprados = data.productoscomprados
         productoscomprados = JSON.parse(productoscomprados)
         setvalueProComprados(productoscomprados);

         let productosrelacionados = data.productosrelacionados
         productosrelacionados = JSON.parse(productosrelacionados)==null?[]: JSON.parse(productosrelacionados)
         setvalueProRelacionados(productosrelacionados);

         setOpenDetail(true);

      } else {

         const resp = await ruleService.deleteRule(id);

         // let deleteContact = {};
         // contact && setDialog(prev => ({ ...prev, message: `¿Desea eliminar al contacto ${contact.nombre_rz} con Rut ${contact.rut_cliente}?`, open: true }));
         getRulePage();
      }

   };

   const handleFormValues = (e) => {
      const handleChangeFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }
      handleChangeFormValues.nombre.trim() !== "" && setError("");
      handleChangeFormValues.fechainicio.trim() !== "" && setError("");
      handleChangeFormValues.fechafin.trim() !== "" && setError("");
      //handleChangeFormValues.idtiporegla.trim() !== "" && setError("");
      //handleChangeFormValues.productoscomprados.trim() !== "" && setError("");
      // handleChangeFormValues.fechainicio.trim() !== "" && setError("");
      // handleChangeFormValues.fechacierre.trim() !== "" && setError("");
      // handleChangeFormValues.preciooferta.trim() !== "" && setError("");
      //handleChangeFormValues.etapa.trim() !== "" && setError("");
      setFormValues(handleChangeFormValues)
   }

   const handleForm = async (e) => {
      e.preventDefault();
      console.log(valueProComplementarios)
      const rangoFecha = moment(fechafin).diff(moment(fechainicio),'month')
      var now = moment()
      if (!nombre) { return setError("nombre"); }
      if (!idtipoRegla) { return setError("idtiporegla"); }
      console.log(valueProComprados)

      if (!valueProComprados) {  return setError("procomprados");}
      if(valueProComprados.length == 0){return setError("procomprados");}

      if(idtipoRegla == '1' || idtipoRegla == '3'){
         if (!fechainicio) { return setError("fechainicio"); }
         if (!fechafin) {
            return setError("fechafin");
         }
         if (moment(fechainicio) > moment(fechafin)) { return setError("fechamenor") }
         if (moment(fechafin) >= now) {
            return setError('fechamayor')
         }
         if (rangoFecha > 6) { return setError("rango"); }

         if(idtipoRegla == '1'){
            if(!valueProComplementarios){ return setError("ProComplementarios")}
            if(valueProComplementarios.length == 0){ return setError("ProComplementarios")}
         }
         if(idtipoRegla == '3'){
            if(!valueProRelacionados){ return setError("ProRelacionados")}
            if(valueProRelacionados.length == 0){ return setError("ProRelacionados")}
         }
      }
      
      if(error==='') {

         if(idtipoRegla == '3'){
            const proMantencionsinIntervalo = ProMantencion.find(value => value.idintervalo == '');
            const proVecesMantencion = ProMantencion.find( value => value.limite < 0)

            console.log(proMantencionsinIntervalo)
            console.log(proVecesMantencion)
            if(proMantencionsinIntervalo || proVecesMantencion){
               console.log('entre')
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  severity: 'warning',
                  message: proMantencionsinIntervalo?'Al seleecionar tipo Mantencion, necesitamos nos indique un intervalo de frecuencia':proVecesMantencion?'El número de veces a repetir no puede ser negativo':'Para poder guardar,todos los productos necesitan la existencia de una relación',
               }));
               return false
            }
         }

         if(decisionRelacion == 2){
            //genero la relacion
            //validar cuando en postventa no se llena nada
               let productosHijos = valueProComplementarios && valueProComplementarios.length > 0 ? valueProComplementarios: ProMantencion;
                productosHijos.map((value,i)=>{
                   const tipoRelacion = idtipoRegla == '1'? '1':idtipoRegla == '3'? '2':null
                  productosHijos[i] = {...value, tipoRelacion:tipoRelacion, utilidad:''}
                })
                
                productosHijos = JSON.stringify(productosHijos)

               valueProComprados.map(async(value)=>{
                  const IDproductoPadre = value.id;
                  const resp = await productService.postGenerateRelationsProducts(IDproductoPadre,productosHijos);

               })
         }

         let resp;
         const usuario = readLocalStorage(KEY_USER_DATA)

         if (formValues.action == "edit" && error==='') {
         
            const data = {
               ...formValues,
               idempresa: usuario.user.idempresacliente,
               propietario: usuario.user.rut_empresa,
               productoscomplementarios: JSON.stringify(valueProComplementarios),
               productoscomprados: JSON.stringify(valueProComprados),
               productosrelacionados: JSON.stringify(valueProRelacionados)
            }
            console.log(data)
            resp = await ruleService.updateRule(formValues.id, data)
            setFormValues(initialFormValues);
            setOpen2(false)
            getRulePage()
            setvalueProComprados(undefined)
            setvalueProComplementarios(undefined)
            setvalueProRelacionados(undefined)

         }
         else {
            if(idtipoRegla != '2'){
               const respRut = await ObtenerRUT()

               if(respRut.length > 0){
                  const data = {
                     ...formValues,
                     idempresa: usuario.user.idempresacliente,
                     propietario: usuario.user.rut_empresa,
                     productoscomplementarios: JSON.stringify(valueProComplementarios),
                     productoscomprados: JSON.stringify(valueProComprados),
                     productosrelacionados: JSON.stringify(ProMantencion)
                  }

                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     severity: 'success',
                     message: `Búsqueda exitosa, hemos logrado encontrar ${respRut.length} posibles clientes`,
                  }));
                  resp = await ruleService.createRule(data);

                  setFormValues(initialFormValues);
                  setOpen2(false)
                  getRulePage()
                  setvalueProComprados(undefined)
                  setvalueProComplementarios(undefined)
                  setvalueProRelacionados(undefined)
               }else{
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     severity: 'warning',
                     message: 'No se ha encontrado posibles clientes, le recomendamos crear una campaña Preventa o modificar los rangos de fechas de búsqueda',
                  }));
               }
            }else{
               const data = {
                  ...formValues,
                  idempresa: usuario.user.idempresacliente,
                  propietario: usuario.user.rut_empresa,
                  productoscomplementarios: JSON.stringify(valueProComplementarios),
                  productoscomprados: JSON.stringify(valueProComprados),
                  productosrelacionados: valueProRelacionados==undefined||[]?null  : JSON.stringify(ProMantencion)
               }

               resp = await ruleService.createRule(data);

               setFormValues(initialFormValues);
               setOpen2(false)
               getRulePage()
               setvalueProComprados(undefined)
               setvalueProComplementarios(undefined)
               setvalueProRelacionados(undefined)
               setDecisionRelacion(0)
            }
         }
      }

   }

   const handleIntervalo = (e) => {
      const changedFormValues = {
         ...intervaloSelect,
         [e.target.name]: e.target.value
      }
      setIntervaloSelect(changedFormValues)

      ProMantencion.map((value, i) => {
         if (value.codigo == e.target.name) {
            const producto = {
               ...value, idintervalo: e.target.value
            }
            return ProMantencion.splice(i, 1, producto)
         }
      })
   }

   const handleLimite = (e) => {
      const changedFormValues = {
         ...limiteSelect,
         [e.target.name]: e.target.value
      }
      setLimiteSelect(changedFormValues)

      ProMantencion.map((value, i) => {
         if (value.codigo == e.target.name) {
            const producto = {
               ...value, limite: e.target.value
            }
            return ProMantencion.splice(i, 1, producto)
         }
      })
   }

   const ObtenerRUT = async()=>{
      // const reglaSelect: any = regla
      let FechaIncial = moment(fechainicio).format('YYYY-MM-DD')
      let FechaFinal = moment(fechafin).format('YYYY-MM-DD');

      let arrayRuts = [];
      let arrayProducts = []
      let resp;
      let tipo = '';
      if ( valueProRelacionados && valueProComprados ) {
         // const proComprados = JSON.parse(reglaSelect.productosrelacionados)
         valueProComprados.map((value) => {
            arrayProducts.push(value.codigo)
         })
         tipo = 'relacionados'
      }
      else if (valueProComprados && valueProComplementarios) {
         // const proComprados = JSON.parse(reglaSelect.productoscomplementarios)
         valueProComprados.map((value) => {
            arrayProducts.push(value.codigo)
         })
         tipo = 'complementarios'
      }
      else {
         // const proComprados = JSON.parse(reglaSelect.productoscomprados)
         valueProComprados.map((value) => {
            arrayProducts.push(value.codigo)
         })
         tipo = 'comprados'
      }

      if (tipo == 'relacionados' || tipo == 'complementarios' || tipo == 'comprados') {
         while (FechaIncial < FechaFinal) {
            let periodo = moment(FechaIncial).format('YYYY')
            let mes = moment(FechaIncial).format('MM')
            for (let i = 0; i < arrayProducts.length; i++) {
               resp = await campaingService.dataClientesByProduct(arrayProducts[i], {
                  periodo: periodo,
                  mes: mes
               })
               resp.map((value) => {
                  arrayRuts.push(value.rut_c)
               })
            }
            FechaIncial = moment(FechaIncial).add(1, 'M').format('YYYY-MM-DD')
         }

         if (arrayRuts.length == 0) {
            return []
         } else {
            let result = arrayRuts.filter((item, index) => {
               return arrayRuts.indexOf(item) === index;
            })

            return result//clientes encontrados
         }
      } else {
         //ERROR
         return []
      }
    }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
         </Snackbar>
         <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
            <Grid container flexDirection='row' spacing={2} justifyContent='space-between' sx={{ mb: 4 }}>
               <Grid item md={7}>
                  <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Reglas de Negocio</Typography>
               </Grid>
            {dataUser.user.role==ROLE_ADMIN&&<Grid item md={5} container spacing={2} justifyContent='right' alignItems='end'>
                  <Grid item>
                     <Button variant='contained'
                      sx={{background:'#E4980D', color:'#fff', '&:hover':{
                        bgcolor:'#E4980D'
                       }}}
                       startIcon={<AddIcon/>}
                     onClick={() => {
                        setOpen2(!open2)
                        // setOpenProComple(false)
                        // setOpenProRelac(false)
                        setFormValues(initialFormValues)
                        setvalueProComprados(undefined)
                        setvalueProComplementarios(undefined)
                        setvalueProRelacionados(undefined)
                     }}>Nueva Regla</Button>
                  </Grid>
                  <Grid item>
                     <Button variant='contained'
                      sx={{background:'#155172', color:'#fff','&:hover':{
                        bgcolor:'#155172'
                       }
                     }}
                     startIcon={<AddIcon/>}
                     onClick={() => { setOpen(true) }}>Crear Campaña</Button>
                  </Grid>
               </Grid>}
            </Grid>
            {/* <Divider /> */}
            {open2 &&
               (
                  <form onSubmit={handleForm}>
                     <Grid container flexDirection='row' spacing={3} sx={{ mt: 1, mb: 6 }}>
                        <Grid item md={6} container flexDirection='column' spacing={1}>
                           <Grid item container flexDirection='row'>
                              <Avatar sx={{ bgcolor: '#007EA7', mr: 2 }}>1</Avatar>
                              <Typography variant='h5'>Nombre de la Regla</Typography>
                           </Grid>
                           <Grid item >
                              <TextField
                                 fullWidth
                                 size='small'
                                 error={error === "nombre" ? true : false}
                                 helperText={error === "nombre" ? "Nombre es es un campo requerido" : ""}
                                 id="nombre"
                                 label="Nombre"
                                 name='nombre'
                                 value={nombre}
                                 onChange={handleFormValues}
                              />
                           </Grid>
                        </Grid>
                        <Grid item md={6} container flexDirection='column' spacing={1}>
                           <Grid item container flexDirection='row'>
                              <Avatar sx={{ bgcolor: '#007EA7', mr: 2 }}>2</Avatar>
                              <Typography variant='h5'>Estrategia Comercial</Typography>
                           </Grid>
                           <Grid item >
                           <FormControl fullWidth error={error === "idtiporegla" ? true : false}>
                              <InputLabel id="idprioridad">Estrategia Comercial</InputLabel>
                              <Select
                                 labelId="idtiporegla"
                                 id="idtiporegla"
                                 sx={{ bgcolor: '#fff' }}
                                 disabled={formValues.action == "edit"?true:false}
                                 size="small"
                                 fullWidth
                                 name="idtipoRegla"
                                 value={idtipoRegla}
                                 className={classes.customDisable}
                                 label="Estrategia Comercial"
                                 onChange={handleFormValues}
                              >
                                 {dataTipoRegla.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.name}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "idtiporegla" ? "Estrategia Comercial es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                           </Grid>
                        </Grid>
                        {idtipoRegla != ''?<Grid item md={4} container flexDirection='column' spacing={2}>
                           <Grid item container flexDirection='row'>
                              <Grid item md={2}>
                                 <Avatar sx={{ bgcolor: '#007EA7' }}>3</Avatar>
                              </Grid>
                              <Grid item md={8}>
                                 <Typography variant='h5'>Realizar Busqueda de productos</Typography>
                              </Grid>
                              <Grid item md={1}>
                                 {/* <Tooltip title='Realizar filtro por productos Complementarios'>
                                    <IconButton sx={{bgcolor:'#f1f1f1'}} onClick={()=>{
                                        if(openProComple==false){
                                          setOpenProComple(true)
                                          setvalueProComplementarios(undefined)
                                          setvalueProRelacionados(undefined)
                                       }else{
                                          setvalueProComplementarios(undefined)
                                          setvalueProRelacionados(undefined)
                                          setOpenProComple(false)
                                          setOpenProRelac(false)

                                       }
                                    }}>
                                          <AddIcon/>
                                    </IconButton>
                                 </Tooltip> */}
                              </Grid>
                           </Grid>
                           <Grid item >
                              <Autocomplete
                                 multiple
                                 id="tags-outlined"
                                 options={idtipoRegla == '1' || idtipoRegla == '3'?data.productoscomprados:data.productos}
                                 getOptionLabel={(option: any) => option.nombre ? "("+option.codigo+") "+option.nombre : ''}
                                 //defaultValue={[valueProComprados]}
                                 fullWidth
                                 sx={{maxWidth:'28vw'}}
                                 value={valueProComprados}
                                 onChange={(e, newValue) => {setvalueProComprados(newValue)
                                    setError('')
                                    if(newValue && idtipoRegla !='2' && idtipoRegla != '4'){
                                       console.log(newValue)
                                       const jsonValue = JSON.stringify(newValue);
                                       let tipo = 0
                                       if(idtipoRegla == '1'){
                                          tipo = 1
                                       }
                                       if(idtipoRegla == '3'){
                                          tipo = 2
                                       }

                                       if(newValue.length>0){
                                          getProductChildrenForIDs(jsonValue,tipo)
                                       }else{
                                          setProductComplementarios([])
                                          setProductRelacionado([])
                                       }
                                    }

                                    if(!newValue){
                                       setDecisionRelacion(0)
                                    }
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Filtrar por Comprar"
                                       error={error === "procomprados" ? true: false}
                                       placeholder=""
                                       fullWidth
                                    />
                                 )}
                              />
                             {error=='procomprados'?<span style={{color:'#d32f2f',fontSize:'0.75rem'}}>Productos es un campo requerido</span>:<></>}
                           </Grid>
                        </Grid>:<></>}
                        {idtipoRegla == '1' ?
                        <Grid item md={4} container flexDirection='column' spacing={2}>
                           <Grid item container flexDirection='row'>
                              <Grid item md={2}>
                                 <Avatar sx={{ bgcolor: '#007EA7' }}>4</Avatar>
                              </Grid>
                              <Grid item md={8}>
                                 <   Typography variant='h5'>Añadir productos complementarios</Typography>
                              </Grid>
                              <Grid item md={1}>
                                 {/* <Tooltip title='Realizar filtro por productos Relacionados'>
                                    <IconButton sx={{bgcolor:'#f1f1f1'}} onClick={()=>{setOpenProRelac(!openProRelac)
                                    setvalueProRelacionados(undefined)
                                    }}>
                                          <AddIcon/>
                                    </IconButton>
                                 </Tooltip> */}
                              </Grid>
                           </Grid>
                           <Grid item >
                              <Autocomplete
                                 multiple
                                 id="tags-outlined"
                                 sx={{maxWidth:'28vw'}}
                                 options={
                                    productComplementarios.length>0?
                                    productComplementarios
                                    :data.productoscomplementarios}
                                 getOptionLabel={(option: any) => option.nombre ? "("+option.codigo+") "+option.nombre : ''}
                                 filterSelectedOptions
                                 value={valueProComplementarios}
                                 onChange={(e, newValue) => {setvalueProComplementarios(newValue)
                                    setError('')
                                    }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Filtrar Productos Complementarios"
                                       error={error === "complementarios" ? true : false}
                                       placeholder=""

                                    />
                                 )}
                              />
                               {error=='ProComplementarios'?<span style={{color:'#d32f2f',fontSize:'0.75rem'}}>Productos es un campo requerido</span>:<></>}
                           </Grid>
                        </Grid>:<></>}
                       { idtipoRegla == '3'?
                       <Grid item md={4} container flexDirection='column' >
                           <Grid item container flexDirection='row'>
                              <Grid item md={2}>
                                 <Avatar sx={{ bgcolor: '#007EA7' }}>5</Avatar>
                              </Grid>
                              <Grid item md={9}>
                                 <   Typography variant='h5'>Añadir Mantenciones</Typography>
                              </Grid>

                           </Grid>
                           <Grid item sx={{mt:2}}>
                              <Autocomplete
                                 multiple
                                 fullWidth
                                 id="tags-outlined"
                                 sx={{maxWidth:'28vw'}}
                                 options={productRelacionado.length > 0? productRelacionado:
                                    data.productosRelacionados}
                                 getOptionLabel={(option: any) => option.nombre ? option.nombre : ''}
                                 filterSelectedOptions
                                 value={valueProRelacionados}
                                 onChange={(e, newValue) => {setvalueProRelacionados(newValue)
                                    if(newValue){
                                          let arrayValue = []
                                          let value = newValue

                                          value.map((row)=>{
                                             arrayValue.push({...row, idintervalo:row?.idintervalo?row.idintervalo:'', limite:row?.limite?row.limite:''})
                                          })
                                          setProMantencion(arrayValue)
                                          setError('')
                                    }else {
                                       setProMantencion([])
                                    }}}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Filtra productos Relacionados"
                                       error={error === "ProRelacionados" ? true : false}
                                       placeholder=""

                                    />
                                 )}
                              />
                               {error=='ProRelacionados'?<span style={{color:'#d32f2f',fontSize:'0.75rem'}}>Productos es un campo requerido</span>:<></>}
                           </Grid>
                        </Grid>:<></>}
                     {valueProRelacionados && valueProComprados && valueProComprados.length>0 && valueProRelacionados.length > 0?

                        <Grid item md={4} container flexDirection='column' spacing={2}>
                           <Grid item container flexDirection='row'>
                              <Grid item md={2}>
                                 <Avatar sx={{ bgcolor: '#007EA7' }}>5</Avatar>
                              </Grid>
                              <Grid item md={9}>
                                 <   Typography variant='h5'>Frecuencias de Mantenciones</Typography>
                              </Grid>

                           </Grid>
                           <Grid item>
                              {valueProRelacionados.map((value) => {
                                 if(decisionRelacion == 0){

                                    const intervaloValue = dataIntervalo.find( obj => obj.id == value.idintervalo)
                                    return (
                                       <>

                                       <Typography sx={{fontSize:'1.2em', color:'#007EA7'}}>{value.nombre} :</Typography>
                                       <Typography>{value?.limite? `Mantención ${value?.limite} veces cada ${intervaloValue?.descripcion}`:`Mantención cada ${intervaloValue?.descripcion}`}</Typography>
                                          </>
                                    )
                                 }else {
                                          return(<>
                                             <Typography sx={{fontSize:'1.2em', color:'#007EA7'}}>{value.nombre} :</Typography>

                                             <Typography>Mantención
                                                <TextField
                                                sx={{ bgcolor: '#fff', width: '60px' }}
                                                size="small"
                                                type='number'
                                                name={value.codigo}
                                                value={value?.limite ? limiteSelect[value.codigo] : value?.limite}
                                                onChange={handleLimite}
                                                /> veces cada
                                                <Select
                                                size="small"
                                                sx={{ bgcolor: '#fff', width: '150px' }}
                                                name={value.codigo}
                                                value={value.idintervalo ? intervaloSelect[value.codigo] : value.idintervalo}
                                                onChange={handleIntervalo}
                                                >
                                                {dataIntervalo.map((option, i) => {
                                                   return <MenuItem key={i} value={option.id}>{option.descripcion}</MenuItem>
                                                })
                                                }
                                                </Select>
                                             </Typography>

                                          </>)
                                 }

                              })}
                           </Grid>
                        </Grid>

                        : <></>
                     }
                        {idtipoRegla && idtipoRegla != '2'?
                        <>
                        <Grid item md={openProComple?2:3} container flexDirection='column' spacing={2}>
                           <Grid item container flexDirection='row'>
                              <Avatar sx={{ bgcolor: '#007EA7', mr: 1, mb: 1 }}>{openProRelac && openProComple?6:openProComple?5:4}</Avatar>
                              <Typography variant='h5'>Desde</Typography>
                              <TextField
                                 type='date'
                                 size='small'
                                 error={error === "fechainicio" ? true : false}
                                 helperText={error === "fechainicio" ? "fecha es es un campo requerido" : ""}
                                 id="fecha"
                                 fullWidth
                                 name='fechainicio'
                                 value={fechainicio}
                                 onChange={handleFormValues}

                              />
                           </Grid>
                        </Grid>
                        <Grid item md={openProComple?2:3} container flexDirection='column' spacing={2}>
                           <Grid item container flexDirection='row'>
                              <Avatar sx={{ bgcolor: '#007EA7', mr: 1, mb: 1 }}>{openProRelac && openProComple?7:openProComple?6:5}</Avatar>
                              <Typography variant='h5'>Hasta</Typography>
                              <TextField
                                 type='date'
                                 size='small'
                                 error={error === "fechafin" ? true : error=="rango"?true:
                                 error === 'fechamayor'?true: error === 'fechamenor'?true:false}
                                 helperText={error === "fechafin" ? "fechafin es es un campo requerido" :
                                 error==="rango"?"El rango de las fechas no debe exceder los 3 meses":
                                 error==='fechamayor'?'La fechas ha filtrar deben ser menor a la fecha actual':
                                 error==='fechamenor'?'El campo "desde" debe ser menor al campo "hasta"':''}
                                 id="fechafin"
                                 name='fechafin'
                                 fullWidth
                                 value={fechafin}
                                 onChange={handleFormValues}
                              />
                           </Grid>
                        </Grid>
                        </>
                        :<></>}
                        <Grid item md={openProComple&& !openProRelac?12:2} container spacing={1} flexDirection={openProComple && !openProRelac?'row':'column'} alignContent={openProComple && !openProRelac?'end':'center'}>
                           <Grid item xs={12} md={3} lg={1} >
                              <Button variant='text'
                              sx={{background:'#fff', width:'100px' ,color:'#bf6c00' , border:'1px solid #bf6c00', '&:hover':{
                                 bgcolor:'#fff', border:'1px solid #bf6c00'
                                }}}
                              startIcon={<CleaningServicesIcon/>}
                              onClick={()=>{
                                 // setOpenProComple(false)
                                 // setOpenProRelac(false)
                                 setFormValues(initialFormValues)
                                 setvalueProComprados(undefined)
                                 setvalueProComplementarios(undefined)
                                 setvalueProRelacionados(undefined)
                              }}
                              >Limpiar</Button>
                           </Grid>
                           <Grid item xs={12} md={3} lg={1}>
                              <Button variant='text'
                              sx={{background:'#fff',width:'100px' , color:'#3F9BCC' , border:'1px solid #3F9BCC', '&:hover':{
                                 bgcolor:'#fff', border:'1px solid #3F9BCC'
                                }}}
                              startIcon={<AddIcon/>}
                              type='submit'>
                                 {formValues.action == 'edit' ? 'Editar' : 'Crear'}</Button>
                           </Grid>
                        </Grid>
                        {/* <Grid item md={12} container spacing={1} flexDirection='column' alignContent='flex-start'>
                           <Grid item xs={12} md={12} >
                              <FormControlLabel control={<Checkbox />} label="Deseo que la regla se cree automáticamente la siguiente vez" />
                           </Grid>
                        </Grid> */}

                     </Grid>
                  </form>

               )}
            <Divider sx={{ mt: 2, mb: 6 }} />
            <Grid container flexDirection='row' spacing={4} sx={{ mb: 5 }}>
               <Grid item md={6} container spacing={1} >
                  <Grid item xs={12}>
                     <span>Buscar desde esta fecha creación en adelante</span>
                  </Grid>
                  <Grid item xs={12} >
                     <TextField
                        type='date'
                        size='small'
                        fullWidth
                        onChange={(e) => searchDate(e.target.value)}
                     />
                  </Grid>
               </Grid>
               <Grid item md={6} container >
                  <Grid item xs={12}>
                     <span>Filtrar Resultados:</span>
                  </Grid>
                  <Grid item xs={12}>
                  <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="search"
                        value={search}
                        onChange={(e)=>searchNameRules(e.target.value)}
                        placeholder="Introduce el nombre de una regla"
                        type="search"
                        id="password"
                        autoComplete="current-password"
                        size="small"
                     />
                  </Grid>
               </Grid>
            </Grid>
            <TableData
               header={header}
               data={dataRule}
               action={dataUser.user.role==ROLE_ADMIN?dataActionAdmin:dataAction}
               RecuperarData={RecuperarData}
               perPage={PerPage}
               page={page}
               total={Total? Total:0}  />
         </Container>
         <ModalNewCampaign
            open={open}
            setOpen={setOpen}
            dataRule={dataRule} />
         <ModalDetailRule
            setOpen={setOpenDetail}
            open={openDetail}
            dataComplementarios={valueProComplementarios}
            dataComprados={valueProComprados}
            dataRelacionados={valueProRelacionados}
         />
         <ModalRule
         open={openRule}
         setOpen={setOpenRule}
         setDecision={setDecisionRelacion}/>
      </Protected>
   )
};



