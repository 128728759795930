import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import { Protected } from '@components/layout/Protected';
import { Props } from './Perfil.type';
import { TableData } from '@/components/common/Table';
import { Button, Grid, TextField, Backdrop, CircularProgress, MenuItem, Select, InputLabel, FormControl, FormHelperText, Snackbar, Box, AppBar, Toolbar, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_DOCUMENTS } from '@/toolbox/constants/local-storage';

export const PerfilDocumentsView: React.FC<Props> = ({ }): JSX.Element => {

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
    })

    let data = readLocalStorage(KEY_DOCUMENTS);
    if(data?.cliente == null){
       data = data?.idloginUser
    }else{
       data = data?.cliente
    }
    const [dataPerfil, setDataPerfil] = useState(data);
    const {direccion, email, telefono, nombre_rz} = dataPerfil
    
   return (
      <Box sx={{p:6}}>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Grid container spacing={2}>
               <Grid item xs={12}>
                   <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>{`Perfil`}</Typography>
               </Grid>
               <Grid item xs={6}>
                  <Typography>Nombre</Typography>
                  <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={nombre_rz}
                     />
               </Grid>
               <Grid item xs={6}>
               <Typography>Email</Typography>
                  <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={email}
                     />
               </Grid>
               <Grid item xs={6}>
               <Typography>Teléfono</Typography>
                  <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={telefono}
                     />
               </Grid>
               <Grid item xs={6}>
               <Typography>Dirección</Typography>
                  <TextField
                     fullWidth
                     sx={{bgcolor:'#E3F4FD'}}
                     disabled
                     size="small"
                     type="text"
                     value={direccion}
                     />
               </Grid>
         </Grid>
      </Box>
   )
}
