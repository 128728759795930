import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, Chip, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecommendIcon from '@mui/icons-material/Recommend';
import { ROUTE_CAMPAIGN } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { LeadsFormService } from '@/service/services/LeadsForm.service';
import { API_URL_BASE, API_URL_BASE_FRONT } from '@/toolbox/defaults/app';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { bypassService } from '@/service/services/Bypass.service';
import { TemplateWsService } from '@/service/services/TemplateWs.service';

type ModalProps = {
   open: boolean,
   setOpen: any,
   data?: any,
   idCampana?: any,
   dataImage?: any,
   direccionamiento?: boolean,
   //   setData?:any
}




export const ModalCopyTemplate: React.FC<ModalProps> = (
   props: ModalProps
): JSX.Element => {

   const [status, setStatus] = useState<boolean>(false);
   const [dataFormView, setDataFormView] = useState<any>([]);
   const [inputsLeads, setInputsLeads] = useState<any>([]);
   const [fixedOptions, setFixedOptions] = useState<any>()
   const [dataLeadsChannel, setDataLeadsChannel] = useState<any>([])
   const [channelLeads, setChannelLeads] = useState<any>([]);
   const [statusSelectView, setStatusSelectView] = useState<any>(0);
   const [saveDataSelect, setSaveDataSelect] = useState<any>();
   const [urlTemplate, setUrlTemplate] = useState<string>('');
   const [dataLeadsInput, setDataLeadsInput] = useState<any>([]);
   const history = useHistory();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'primary',
      message: 'Error',
      autoHideDuration: 9000,
   })
   const [URI, setURI] = useState<any>('view_leads_form');

   useEffect(() => {
      listFormView();
      getInputs();
      getChannel();
      getUrlTemplate();
   }, [])



   async function getInputs() {
      const resp = await bypassService.getInputs()
      setDataLeadsInput(resp)
      const fixed = resp.filter(data => data.name == 'Nombre' || data.name == 'Correo')
      setFixedOptions(fixed);
      setInputsLeads(fixed)
   }

   async function getChannel() {
      const resp = await bypassService.getChannel()
      setDataLeadsChannel(resp)
   }

   async function getUrlTemplate() {
      const resp = await TemplateWsService.getUrlTemplate()
      if (resp?.code === 200) {
         if (resp?.data?.detail?.url_landing_page) {
            setUrlTemplate(resp?.data?.detail?.url_landing_page)
         }
      }
   }


   const listFormView = async () => {
      const resp = await LeadsFormService.listFormView();
      if (resp) {
         setDataFormView(resp.data);
      }
   }

   const handleDireccionamiento = async () => {
      if(!inputsLeads.length)
      {
         // validate.push({ etiqueta: 'Campos de entrada de informacion', value: 1, canal: 'formulario' })
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: "Los campos de entrada de informacion es requerido"
         }));
      }
      if(!channelLeads.length)
      {
         // validate.push({ etiqueta: 'Canales donde se filtrará', value: 1, canal: 'formulario' })
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'warning',
            message: "Los canales donde se filtrará es requerido"
         }));
      }
      else
      {
         const value = saveDataSelect;
         if (value) {
            const resp: any = await LeadsFormService.copyForm(value.id, props.idCampana);
            if (resp && resp.data) {
               history.push(`/view_edit_form/${props.idCampana}`, { nombre_campana: value.nombre_campana });
            }
         }

         let arrayInputs = [];
         inputsLeads.map((value) => {
            arrayInputs.push(value.id)
         })

         let arrayChannel = [];
         channelLeads.map((value) => {
            arrayChannel.push(value.id)
         })


         const arrayJsonInputs = JSON.stringify(arrayInputs)
         const arrayJsonChannel = JSON.stringify(arrayChannel)
         const bypass = await bypassService.postCrearEmpresa(`${props.idCampana}`);

         if (bypass) {
            const resp = await bypassService.postCrearCampaña(`${props.idCampana}`, arrayJsonInputs, arrayJsonChannel, bypass.id,
            URI?URI:'view_leads_form', urlTemplate
            );
         }
      }


   }


   const vistaBypass = (
      <Grid
         container
      >
         <Grid xs={12}>
            <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '15px' }}>CAMPOS DE ENTRADA DE INFORMACIÓN</p>
            <Autocomplete
               multiple
               fullWidth
               id="tags-outlined"
               size='small'
               sx={{ background: '#fff' }}
               options={dataLeadsInput}
               getOptionLabel={(option: any) => option.name ? option.name : ''}
               filterSelectedOptions
               value={inputsLeads}
               onChange={(e, newValue) =>
                  setInputsLeads([
                     ...fixedOptions,
                     ...newValue.filter((value) => fixedOptions.indexOf(value) === -1)
                  ])}
               renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                     <Chip
                        key={index}
                        label={option.name}
                        {...getTagProps({ index })}
                        disabled={fixedOptions.indexOf(option) !== -1}
                     />
                  ))
               }
               renderInput={(params) => (
                  <TextField
                     {...params}
                     //label="Ingrese los campos para el formulario"
                     placeholder=""

                  />
               )}
            />
         </Grid>
         <Grid xs={12}>
            <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '15px' }}>CANALES DONDE SE FILTRARA</p>
            <Autocomplete
               multiple
               fullWidth
               id="tags-outlined"
               size='small'
               sx={{ background: '#fff' }}
               options={dataLeadsChannel}
               getOptionLabel={(option: any) => option.name ? option.name : ''}
               filterSelectedOptions
               value={channelLeads}
               onChange={(e, newValue) =>
                  setChannelLeads(newValue)}
               renderInput={(params) => (
                  <TextField
                     {...params}
                  />
               )}
            />
         </Grid>

         <Grid xs={12}>
            <p style={{ color: '#155172', fontWeight: 500, paddingBottom: '7px', paddingTop: '15px' }}>PERSONALIZAR URL LEADS</p>
            <Grid item container direction="row" justifyContent={'center'} alignItems='center' sx={{ mt: 1, mb: 2 }} >
            <span style={{background:'#fff', padding:'12px', borderRadius:'10px'}}>{`${urlTemplate}`}</span>
            <TextField
               sx={{ bgcolor: '#fff' }}
               size="small"
               id="comentarios"
               inputProps={{
                  maxLength: 200,
               }}
               name="comentarios"
               value={URI}
               onChange={(e)=>setURI(e.target.value)}
            />
      </Grid>
         </Grid>



         <Grid container justifyContent='space-between' sx={{ mt: 2 }}>
            <Button variant='contained' sx={{ bgcolor: '#E4980D' }} size="small" onClick={() => { setStatusSelectView(0) }}>Volver</Button>
            <Button variant='contained' sx={{ bgcolor: '#007EA7' }} size="small" onClick={() => { handleDireccionamiento() }}>Generar</Button>
            {/* <Button variant='contained' size="small" onClick={() => handleDireccionamiento(value)}>Duplicar</Button> */}
         </Grid>
      </Grid>
   )


   const bodyModal = (
      <Grid
         container
         direction="row"
         justifyContent='center'
      >
         {dataFormView.map((value, i) => {
            return (
               <Grid key={i} item xs={6} sx={{ p: 1 }}>
                  <Card sx={{ maxWidth: 345 }}>
                     <CardMedia
                        component="img"
                        height="120"
                        image={`${API_URL_BASE}/${value.ruta_imagen}`}
                        alt="img"
                     />
                     <CardContent>
                        <p style={{ textTransform: 'uppercase' }}>
                           {value.nombre_campana}
                        </p>

                     </CardContent>
                     <CardActions>
                        <Grid container justifyContent='space-between'>
                           {/* <Button variant='contained' color='success' size="small">Previsualizar</Button> */}
                           <Button variant='contained' size="small"
                              onClick={() => {
                                 setStatusSelectView(1)
                                 setSaveDataSelect(value)
                              }}
                           >Duplicar
                           </Button>
                        </Grid>
                     </CardActions>
                  </Card>
               </Grid>
            )
         })}


      </Grid>
   )

   return (
      <div>
         <Modal
            open={props.open}
            onClose={() => {
               props.setOpen(false)
            }}
            sx={{ width: '120%', left: '-5%' }}
         >
            <Box className='Modal'>
               {statusSelectView == 0 ? <Typography id="modal-modal-title"
                  sx={{ background: '#155172', color: '#fff', fontWeight: '700', fontSize: '2em', mb: 3, p: 2 }} variant="h5" component="h1" textAlign='center' color='primary'>
                  {`Seleccione un template`}
               </Typography> :
                  <Typography id="modal-modal-title"
                     sx={{ background: '#155172', color: '#fff', fontWeight: '700', fontSize: '2em', mb: 3, p: 2 }} variant="h5" component="h1" textAlign='center' color='primary'>
                     {`Generar Urls`}
                  </Typography>
               }

               {
                  statusSelectView == 0 ? bodyModal : vistaBypass
               }
               {/* {bodyModal} */}
               {/* {vistaBypass} */}
               <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
            </Box>

         </Modal>
      </div>
   );
}
