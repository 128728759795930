import { supervisorRepository } from "../repositories/Supervisor.respository";

export const supervisorService = {
    getVendedor,
    seguimientoVendedor,
    seguimientoVendedores,
    compartirVendedores,
    transferirVendedores,
    recalcularPartipacion
}
function getVendedor() {
    return supervisorRepository.getVendedor()
}

function seguimientoVendedor(id) {
    return supervisorRepository.seguimientoVendedor(id)
}

function seguimientoVendedores() {
    return supervisorRepository.seguimientoVendedores()
}

function compartirVendedores(dataVendedor) {
    return supervisorRepository.compartirVendedores(dataVendedor)
}

function transferirVendedores(dataVendedor) {
    return supervisorRepository.transferirVendedores(dataVendedor)
}

function recalcularPartipacion(data) {
    return supervisorRepository.recalcularPartipacion(data)
}