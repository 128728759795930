import  React,{useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider,FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete,Backdrop} from '@mui/material';
import { Box } from '@mui/system';
import {SaveIcon,CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';



type ModalProps = {
  open: boolean,
  setOpen:any,
  setDetailSelect:any,
  detailSelect:any,
  RecuperarData: ({}) => void,
  dataRule?:any,
  CampaignEdit?:any,
  setCampaingEdit?:any,
  source?:any
}
export const ModalChangeDetailCampaign:React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element =>{

   const initialFormValues ={
      nombre:''
   }

  const [error, setError] = useState(null);

  const [dataUser, setDataUser] = useState<any>([])
  const [idasignado, setIdAsignado] = useState(null);
  const [openCarga, setOpenCarga] = useState(false);

  const [dataPipelines, setDataPipelines] = useState<any>([]);
  const [idpipelines, setIdPipeplines] = useState<any>(null);
   const [formValues, setFormValues] = useState<any>({})

   const [dataUserStorage] = useLocalStorage(KEY_USER_DATA, undefined);

   const {
    nombre ,

  } = formValues


  useEffect(() => {
   const data = readLocalStorage(KEY_USER_DATA)
   if(props.open==true){
      getDataUser(data.user.idempresacliente)
      getDataPipelines();
   }

   }, [props.open])

  //  useEffect(() => {
  //   console.log(props.detailSelect);

  //   }, [dataUser, props.open])
  // obtengo los productos y seteo un array de ruts

  const handleFormValues = (e) => {
    const handleChangeFormValues = {
      ...formValues,
      [e.target.name]: e.target.value
    }
    //handleChangeFormValues.nombre.trim() !== "" && setError("");

    setFormValues(handleChangeFormValues)

  }

  const handleForm = async (e) => {
    e.preventDefault();


   //    if (!nombre) {return setError("nombre");}

   //    else
   //    {

   //       // resp = await campaingService.createCampaign(data);
   //       // setFormValues(initialFormValues);
   //       // props.setOpen(false);
   //   }

   const data ={
      idvendedor: idasignado==null?'':idasignado.idusuario,
      nombrevendedor: idasignado==null?'':idasignado.nombre,
      iddetallecampaña: props.detailSelect.iddetallecampaña?props.detailSelect.iddetallecampaña:props.detailSelect.id,
      folio: props.detailSelect.folio,
      parseiddetallecampaña: parseInt(props.detailSelect.iddetallecampaña),
      idpipelines: idpipelines==null?'':idpipelines.id
   }

   props.RecuperarData(data)

   setIdAsignado(null);
   setIdPipeplines(null);

  }

   async function getDataUser(idempresacliente) {
      const resp = await entityService.getEntityUsers(idempresacliente);
      const dataUsers = resp.data.filter(user => user.idtipousuario == 4)
      setDataUser(dataUsers)
      const usuarioObj = dataUsers.find(usuario => usuario.nombre == props.detailSelect.nombre_usuario);
        setIdAsignado(usuarioObj);
   }

   async function getDataPipelines() {
      const resp = await campaingService.getCampaingPipelines();
      setDataPipelines(resp.data)
   }


  const bodyModal = (
    <Box className='Modal'>
       <Box component='form' onSubmit={handleForm}>

         {dataUserStorage.user.role==ROLE_ADMIN&&
         <>
         <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' color='primary'>
         <strong style={{fontWeight:600}}>Asignar a otro Vendedor</strong>
         </Typography>

        <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} >
          <Grid item xs={12}>
            <Autocomplete
               //disabled={isDisabled}
               autoComplete
               sx={{bgcolor:'#fff'}}
               size='small'
               includeInputInList
               value={ idasignado }
               onChange={(e, asignado) => {
                  setIdAsignado(asignado)
                  // DataUsarioCliente(asignado.idusuario)
               }}
               id="asignado"
               options={dataUser}
               getOptionLabel={(option) => option.nombre}
               renderInput={(params) => <TextField {...params} label="Asignado a..*" error={error === "pais" ? true : false} helperText={error === "pais" ? "Pais es un campo requerido" : ""} />}
            />
          </Grid>
         </Grid>
         </>}


         { props.source=='trello'?(<></>
        ):(<>
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' color='primary'>
         <strong style={{fontWeight:600}}>Actualizar Etapas</strong>
         </Typography>
        <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} >
          <Grid item xs={12}>
             <Autocomplete
               //disabled={isDisabled}
               autoComplete
               sx={{bgcolor:'#fff'}}
               size='small'
               includeInputInList
               value={idpipelines}
               onChange={(e, asignado) => {
                  setIdPipeplines(asignado)
                  // DataUsarioCliente(asignado.idusuario)
               }}
               id="asignado"
               options={dataPipelines}
               getOptionLabel={(option) => option.nombre}
               renderInput={(params) => <TextField {...params} label="Etapas" error={error === "pais" ? true : false} helperText={error === "pais" ? "Pais es un campo requerido" : ""} />}

            />
          </Grid>
        </Grid>
        </>)}
        <Divider />
        <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
          <Grid item >
            <Button
              variant='contained'
              color='error'
              onClick={() => {props.setOpen(false);

               //setFormValues(initialFormValues);
            }}
              endIcon={<CancelIcon/>}
            >
              Cancelar
            </Button>
          </Grid>

            <Grid item>
            <Button
              variant='contained'
              type='submit'
              endIcon={<EditIcon/>}
            >
              Actualizar
            </Button>
          </Grid>

        </Grid>
      </Box>
    </Box>
  )



  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {props.setOpen(false)

         //setFormValues(initialFormValues);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        {bodyModal}


      </Modal>
    </div>
  );
}
