import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import {DownloadIcon,CloseCircleIcon, CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { campaingService } from '@/service/services/Campaing.service';

const initialFormValuesContact = {
  precio:'',
  cantidad:'',
}
type ModalProps = {
  open: boolean,
  setOpen:any,
  idDetailCampaing:any,
  destination:any
}

export const ModalAddPriceAndCount: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element =>{
  const [error, setError] = useState(null);
  const [formValuesContact, setFormValuesContact] = useState(initialFormValuesContact)
  const [idComuna,setIdComuna] = useState(-1);
  const {
     precio,
    cantidad,
  } = formValuesContact
  const handleInputChange = (e) => {
    const changeFormValuesContact = {
      ...formValuesContact,
      [e.target.name]: e.target.value
    }
    changeFormValuesContact.precio.trim() !== "" && setError("");
    changeFormValuesContact.cantidad.trim() !== "" && setError("");
 
    setFormValuesContact(changeFormValuesContact)
  }
  const handleSubmitContact = async(e) => {
    e.preventDefault();
    if (!cantidad.trim()) { return setError("cantidad"); }
    if (!precio.trim()) { return setError("precio"); }
    else {
      const dataCampaing = {
        precio: precio,
        cantidad: cantidad,
        pipeline:5
      }
      if(props.destination==0)
      {
        const resp = await campaingService.updatePipelinesDetailsAndPrice(props.idDetailCampaing,dataCampaing) 
      }else
      {
        const resp = await campaingService.updateDetailsCampaignLeadsPipelines(props.idDetailCampaing,dataCampaing)
      }
      props.setOpen(false);
    }

  }
 
  const bodyModal = (
    <Box className='Modal'>
      <Typography id="modal-modal-title" variant="h6" component="h1" textAlign='center'>
        <strong>Agregar Informacion de la venta</strong>
      </Typography>
      <Box component='form' onSubmit={handleSubmitContact} >
        <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
          <Grid item xs={12} md={6}>
            <TextField
              size='small'
              error={error === "cantidad" ? true : false}
              helperText={error === "cantidad" ? "Giro es es un campo requerido" : ""}
              id='cantidad'
              name='cantidad'
              label="Cantidad"
              type={'number'}
              InputProps={{
                inputProps:{min:0}
              }}
              fullWidth
              value={cantidad}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              size='small'
              error={error === "precio" ? true : false}
              helperText={error === "precio" ? "Precio es es un campo requerido" : ""}
              id='precio'
              name='precio'
              label="Precio Neto"
              fullWidth
              value={precio}
              onChange={handleInputChange}
            />
          </Grid>
         
        <Grid container direction='row'  sx={{ mt: 2}} justifyContent='flex-end' alignContent='center' >
          <Grid item>
            <Button
              variant='contained'
              type='submit'
              endIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
        </Grid>
      </Box>
    </Box>
  )
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
}
