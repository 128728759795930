import React, { useState, useEffect } from 'react';
import {Box,Button,Modal,TextField,MenuItem,Select,InputLabel,FormControl,Grid, Typography} from '@mui/material'
import { DownloadIcon, CloseCircleIcon} from "@toolbox/constants/icons";
import './Modal.sass'
import { notifyService } from '@/service/services/Notify.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROUTE_NOTIFY } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import { contactService } from '@/service/services/Contact.service';
import EditIcon from '@mui/icons-material/Edit';

const initialFormValues = {
   nombre: "",
    email: "",
    telefono: "",
    celular: "",
    cargo: "",
}

type ModalProps = {
    open: boolean,
    setOpen: any,
    dataContact?: any,
    setDataContact?: any,
    setValueContact?: any,
    ValueContact?: any,
    updateContact?:({}) => void,
}

export const ModalAddContact: React.FC<ModalProps> = ({
    open,
    setOpen,
    dataContact,
    setDataContact,
    setValueContact,
    ValueContact,
    updateContact
}): JSX.Element => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const { nombre="",email="", telefono="", celular="", cargo="" } = formValues;
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log(ValueContact)
        if (ValueContact) {
            setFormValues(ValueContact)
        }else{
            setFormValues(initialFormValues)
        }
    }, [open])

    const handleInputChange = (e) => {
        const changedFormValues = {
            ...formValues,
            [e.target.name]: e.target.value
        }
        nombre.trim()!==""&&setError("")
        email.trim()!==""&&setError("")
        // telefono.trim()!==""&&setError("")
        celular.trim()!==""&&setError("")
        // cargo.trim()!==""&&setError("")
        setFormValues(changedFormValues);
    }
    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!nombre.trim()) {
            return setError("nombre");
        }
        if (!email.trim()) {
            return setError("email");
        }
        // if (!telefono.trim()) {
        //     return setError("telefono");
        //  }
        if (!celular.trim()) {
            return setError("celular");
        }
        if(ValueContact)
        {
            const values = {
                ...formValues,
                idcliente: dataContact?.idcliente
            } || {}
            setFormValues(initialFormValues);
            updateContact(values)

        }
        else {
            const values = {
                ...formValues,
                idcliente:dataContact?.idcliente
            } || {}
            setFormValues(initialFormValues);
            setDataContact(values)
            setOpen(false)
        }

    }
    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '450px',
                    bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p:4,borderRadius:7
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <Typography sx={{mb:2,fontWeight:100}}>
                            {ValueContact ? 'EDITAR CONTACTO' : 'AGREGAR CONTACTO'}
                        </Typography>
                        <form onSubmit={handleSubmit} >
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "nombre" ? true : false}
                                        helperText={error === "nombre" ? " La nombre es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        size="small"
                                        id="nombre"
                                        label="Nombre"
                                        name="nombre"
                                        type="text"
                                        value={nombre}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "email" ? true : false}
                                        helperText={error === "email" ? " El Email es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        size="small"
                                        id="email"
                                        label="Email"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        // error={error === "telefono" ? true : false}
                                        // helperText={error === "telefono" ? " El telefono  es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        label="Telefono"
                                        id="telefono"
                                        name="telefono"
                                        autoFocus
                                        value={telefono}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        error={error === "celular" ? true : false}
                                        helperText={error === "celular" ? " El celular es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true, required: true }}
                                        size="small"
                                        label="Celular"
                                        id="celular"
                                        name="celular"
                                        value={celular}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        // error={error === "cargo" ? true : false}
                                        // helperText={error === "cargo" ? " El cargo es un campo requerido" : ""}
                                        InputLabelProps={{ shrink: true}}
                                        size="small"
                                        label="Cargo"
                                        id="cargo"
                                        name="cargo"
                                        multiline
                                        value={cargo}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    {ValueContact ? (
                                        <Button
                                        onClick={() => {
                                            setOpen(false)
                                            setFormValues(initialFormValues)
                                            setValueContact({})
                                        }}
                                        variant="contained"
                                        fullWidth
                                        color="error"
                                        startIcon={<CloseCircleIcon />}
                                        sx={{
                                            background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {bgcolor: '#bf6c00'}}}>
                                        Cancel
                                    </Button>
                                    ) :
                                    (
                                        <Button
                                        onClick={() => {
                                            setOpen(false)
                                            setFormValues(initialFormValues)
                                             setValueContact({})
                                        }}
                                        variant="contained"
                                        fullWidth
                                        color="error"
                                        startIcon={<CloseCircleIcon />}
                                        sx={{
                                            background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {bgcolor: '#bf6c00'}}}>
                                        Cancel
                                    </Button>
                                    )}


                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        startIcon={ValueContact ? <EditIcon /> : <DownloadIcon />}
                                        // startIcon={<DownloadIcon />}
                                        sx={{background:'#155172', mt: "10px",color:'#fff', '&:hover':{
                                            bgcolor:'#155172'
                                           }}}
                                    >
                                        {ValueContact ? 'Actualizar' : 'Agregar'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
