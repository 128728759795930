import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText, Backdrop } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { ModalProspectoRapido } from './ModalProspectoRapido';
import { propsToClassKey } from '@mui/styles';
import { mailService } from '@/service/services/Mail.service';
import { ModalCampaignCorreo } from './ModalCampaignCorreo';
import { ModalCuponEdit } from './ModalCuponEdit';
import { ModalCotizacionEdit } from './ModalCotizacionEdit';
import { ModalPromocionEdit } from './ModalPromocionEdit';
import { cotizacionService } from '@/service/services/Cotizacion.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { SpinnerGrow } from '../Spinner';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data?:any,
    tipo?:any,
    dataCampana?:any,
    dataProductos?:any
}


export const ModalOptionsCotizacion: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props.dataProductos)
    const userData = readLocalStorage(KEY_USER_DATA);
    const tokenSft = readLocalStorage(KEY_SOFTNET)
    const [open, setOpen] = useState<boolean>(false)
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   const [openModalCotizacionEdit, setOpenModalCotizacionEdit] = useState<boolean>(false);
   const [openModalCuponEdit, setOpenModalCuponEdit] = useState<boolean>(false);
   const [openModalPromocionEdit, setOpenModalPromocionEdit] = useState<boolean>(false);
   const [ocultarCotizacion, setOcultarCotizacion] = useState<boolean>(false);
   const [dataCotizacion, setDataCotizacion] = useState<any>({})

    const getCotizacion = async (funcOpen) => {
        funcOpen(true)
        const resp = await cotizacionService.getCotizacionByDetalleCampana(props.data.iddetallecampaña);
        if (resp.data) {
            let rut_empresa = userData.user.rut_empresa.replace(/ /g, "")
            const respDoc = await cotizacionService.consultaDocumento(tokenSft, {
                folio: resp.data.folio,
                tipo: resp.data.tipo_doc,
                rut: rut_empresa,
            });
            funcOpen(false)
            return setDataCotizacion(respDoc)
        } else {
            funcOpen(false)
            setOcultarCotizacion(true)
            return false
        }
    }
    
    useEffect(()=>{
        if(props.open && props.tipo=='detalle'){
            getCotizacion(setOpen)
        }   
    },[])

    const validarListCotizaciones = () =>{
        if(dataCotizacion){
            setOpenModalCotizacionEdit(true);
        }else{
            setSnackBarConfig({...snackBarConfig, open:true, message:'No podemos enviar el mail, sino ha generado una cotización'})}
    }
    //  const respGet = await funcGetData();

    // if(respGet){
    //     setSnackBarConfig({...snackBarConfig, open:true, message:'No podemos enviar el mail, sino ha generado una cotización'})
    // }else{
    //      funcSetDataCotizacion(respGet)
    //      setTimeout(()=>{
    //         funcOpen(true)
    //      }, 1000)
         
   // }
    
    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', mb:2, fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Elige que deseas realizar`}
            </Typography>
            {props.tipo == 'detalle' && !ocultarCotizacion && <ListItem>
                <ListItemButton sx={{ borderRadius: '2px solid #fff', bgcolor:'#5C68E2', color:'#fff', '&:hover': {color:'black'} }} onClick={() => {  validarListCotizaciones()  }}>
                    <ListItemText
                        primary={
                            <Typography>{ 'Personalizar y Enviar Cotización'}</Typography>
                        }
                    ></ListItemText>
                </ListItemButton>
            </ListItem>}
            <ListItem>
                <ListItemButton sx={{ borderRadius: '2px solid #fff', bgcolor:'#E4980D', color:'#fff' , '&:hover': {color:'black'}}} onClick={() => { setOpenModalCuponEdit(true) }}>
                    <ListItemText
                        primary={
                            <Typography>{ 'Personalizar y Enviar Cupón de Descuento'}</Typography>
                        }
                    ></ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton sx={{ borderRadius: '2px solid #fff', bgcolor:'#3f9bcc', color:'#fff' , '&:hover': {color:'black'}}} onClick={() => {  setOpenModalPromocionEdit(true)}}>
                    <ListItemText
                        primary={
                            <Typography>{ 'Personalizar y Enviar Promoción de Productos'}</Typography>
                        }
                    ></ListItemText>
                </ListItemButton>
            </ListItem>
        </Box> 
    )

    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
            >
                <>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <SpinnerGrow />
                            <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                        </Grid>
                    </Backdrop>
                {bodyModal}
                </>
                    
            </Modal>
           {
            openModalCotizacionEdit && 
           <ModalCotizacionEdit
           open={openModalCotizacionEdit}
           setOpen={setOpenModalCotizacionEdit}
           data={props.data}
           dataCotizacion={dataCotizacion}/>
           }
           {
            openModalCuponEdit && 
            <ModalCuponEdit
            open={openModalCuponEdit}
            setOpen={setOpenModalCuponEdit}
            data={props.data}
            tipo={props.tipo}
            dataCampana = {props.dataCampana}
            />
           }
           {
            openModalPromocionEdit && 
            <ModalPromocionEdit
            open={openModalPromocionEdit}
            setOpen={setOpenModalPromocionEdit}
            data={props.data}
            tipo={props.tipo}
            dataCampana = {props.dataCampana}
            dataProductos = {props.dataProductos}/>
           }
        </div>
    );
}
