import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, Autocomplete, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { Box, spacing } from '@mui/system';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import './Modal.sass'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import CampaignIcon from '@mui/icons-material/Campaign';

import { entityService } from '@/service/services/Entity.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecommendIcon from '@mui/icons-material/Recommend';
import { userService } from '@/service/services/User.service';
import PercentIcon from '@mui/icons-material/Percent';
import { configurationService } from '@/service/services/Configuration.service';



type ModalProps = {
  open: boolean,
  setOpen: any,
   data?:any,
}
export const ModalChangeParticipacion: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element => {


  const initialFormValues = {
    nombre: ''
  }


  const [dataUserStorage] = useLocalStorage(KEY_USER_DATA, undefined);
  const [dataUser, setDataUser] = useState([])
  const [data, setData] = useState([]);
  const [estado, setEstado] = useState<boolean>(false);
  const [snackBarConfig, setSnackBarConfig] = useState<any>({
    open: false,
    severity: 'success',
    message: 'éxito',
    autoHideDuration: 3000,
 })

  useEffect(() => {
    const data = readLocalStorage(KEY_USER_DATA)
    if (props.open == true) {
      getUsers()
    }

  }, [props.open])

  async function getUsers() {
    //const resp = await userService.getUser();

    const userSales = props.data.filter(data => data.idtipousuario == 4)
    setDataUser(userSales)

    userSales.map((row) => {
      data.push({ [`name`]: `${row.idusuario}`, ['value']: row.participacion });
    })

  }

  const handleFormValues = (e, i) => {
    switch (e.target.name) {
      case data[i].name:
        setData([...data, data[i].value = e.target.value]);
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ArrayNewParticipacion = [];
    let Porcentaje = 0;
    dataUser.map((row, i) => {
      Porcentaje = Porcentaje + parseInt(data[i].value);
      ArrayNewParticipacion.push(data[i]);
    })
    if (Porcentaje == 100) {
      setEstado(false);
      //console.log(JSON.stringify(a))
      const resp = await userService.updateParticipationUsers(JSON.stringify(ArrayNewParticipacion))
      if(resp.data)
      {
        setSnackBarConfig(prev => ({
          ...prev,
          open: true,
          message: resp.data,
       }));
      }

    } else {
      setEstado(true);
    }



  }
  const body = (
    <>
      {
        dataUser.map((value, i) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              key={i}
              sx={{p:1}}
            >
               <Grid  item xs={6}>
                <Typography sx={{ color: '#155172', fontWeight:'700', fontSize:'1.4em' }}>{value.nombre} :</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type='number'
                  InputProps={{
                    inputProps:{min:0,max:100},
                    endAdornment:(
                      <InputAdornment position="start">
                         <PercentIcon />
                      </InputAdornment>
                      ),
                }}
                  name={data[i] ? data[i].name : `${value.idusuario}`}
                  value={data[i] ? data[i].value : value.participacion}
                  size='small'
                  sx={{ background: '#fff' }}
                  onChange={(e) => { handleFormValues(e, i) }}
                />
              </Grid>
            </Grid>
          )
        })
      }
    </>
  )

  const bodyModal = (
    <Box className='Modal'>
      <Box >
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC',mt:1, fontWeight:'700', fontSize:'2em'}}>
          Actualizar Participación
        </Typography>
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC',mt:1, fontWeight:'700', fontSize:'1.2em'}}>
         ( Usuarios Vendedores)
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 2 }} justifyContent='center'>
            <Grid item xs={12} container spacing={2} sx={{ ml: 2, mt: 1 }}>
              {body}
            </Grid>
            <Grid item >
              {estado && <span style={{color:'red'}}>La suma de los procentajes deben ser igual a 100%</span>}
            </Grid>
          </Grid>
          <Divider />
          <Grid container direction='row' spacing={2} sx={{ mt: 0, mb: 1 }} justifyContent='flex-end' alignContent='center' >
            <Grid item >
              <Button
                  sx={{
                    background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                       bgcolor: '#bf6c00'
                    }
                 }}
                color='error'
                onClick={() => {
                  props.setOpen(false);
                }}
                endIcon={<CancelIcon />}
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button
                 sx={{background:'#155172', mt: "10px",color:'#fff', '&:hover':{
                  bgcolor:'#155172'
                 }}}
                type='submit'
                endIcon={<EditIcon />}
              >
                Actualizar
              </Button>
            </Grid>

          </Grid>
        </form>
      </Box>
    </Box>
  )



  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false)

        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
      <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
