
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './DetailCampaignGeneral.type';
import { FormControl, Autocomplete, Select, MenuItem, FormHelperText, Container, Divider, Grid, InputLabel, Typography, TextField, Button, Alert, Snackbar, IconButton, Card, Backdrop, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBackIconv2, PencilIcon, CancelIcon, SaveIcon, EditContactIcon } from "@toolbox/constants/icons";
import { contactService } from '@/service/services/Contact.service';
import { ubigeoService } from '@/service/services/Ubigeo.service';
import { ROUTE_CAMPAIGN, ROUTE_CAMPAING_LEADS_FORM, ROUTE_CONTACT_UPDATE, ROUTE_CORREOS, ROUTE_DETAILCAMPAIGNGENERALCLIENT } from '@/toolbox/constants/route-map';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { DataClients } from '@service/models/Contact'
import { Data } from '@/service/models/User';
import { TabsComponent } from '@/components/common/Tabs';
import { ConfirmDialog } from '@/components/common/DialogConfirm';//desahuevate jimmi santi
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CardActividad } from '../Create/Contact/components/Card';
import { FormTask } from '../Create/Contact/components/Form';
import { campaingService } from '@/service/services/Campaing.service';
import { taskService } from '@/service/services/Task.service'
import { makeStyles } from "@mui/styles";
import { SpinnerGrow } from '@/components/common/Spinner';
import logokyte from "@assets/img/vacio.png";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ModalDetalleCompra } from "@/components/common/Modal/ModalDetalleCompra";
import { ModalTomarNota } from '@/components/common/Modal/ModalTomarApuntes';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { DATA_CAMPAING_LEADS, KEY_EMPRESA, KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ModalAddTaskAndCall } from '@/components/common/Modal';
import moment from 'moment';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { TableData } from '@/components/common/Table';
import { bypassService } from '@/service/services/Bypass.service';
import { ModalViewURL } from '@/components/common/Modal/ModalViewUrl';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Link } from 'react-router-dom';
import { ModalSearchProduct } from '@/components/common/Modal/ModalSearchProduct';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ModalAsignarClient } from '@/components/common/Modal/ModalAsignarClient';
import { ModalMoveClient } from '@/components/common/Modal/ModalMoveClient';
import { ModalVerCorreo } from '@/components/common/Modal/ModalVerCorreo';

import { ModalValidateTrasferClientCampaign } from '@/components/common/Modal/ModalValidateTrasferClientCampaign';
import { LeadsFormService } from '@/service/services/LeadsForm.service';
import { ModalPreviewEmailing } from '@/components/common/Modal/ModalPreviewEmailing';
import { ModalChangeDetailCampaign } from '@/components/common/Modal/ModalChangeDetailCampaing';
import * as qs from 'qs'

const header = [{ name: 'nombre_rz', label: 'Prospecto', width: '' },
// {name:'nombre_cliente', label:'Cliente Enlazado',width:''},
{ name: 'nombre_usuario', label: 'Vendedor Asignad', width: '' },
{ name: 'nombre_pipelines', label: 'Etapa', width: '' }];

const dataAction = [
   { name: 'edit', color: 'primary' },
   { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGNGENERALCLIENT },
   { name: 'move', color: 'danger' },
   { name: 'nota', color: 'info' },
   { name: 'asignar', color: 'success' },
   { name: 'sendMail', color: 'success' },
]

const dataActionCorreo = [
   // { name: 'edit', color: 'primary' },
   { name: 'view', color: 'success', route: ROUTE_DETAILCAMPAIGNGENERALCLIENT },
   { name: 'move', color: 'danger' },
   { name: 'nota', color: 'info' },
   { name: 'asignar', color: 'success', },
   { name: 'hilo_correo', color: 'sucess' },
   { name: 'correo', color: 'sucess' }
]

type Campaign = {
   id: number,
   nombre: string,
   prospecto_idcanal?: string,
   meta?: number |null,
   cantidad?: number |null,
   fecha_inicio?: string,
   fecha_cierre?: string,
   rut_propietario?: string,
   productos_estimados?: number |null,
   utilidad_estimada?: number| null,
   idregla?: number | null,
   idempresa?: number,
   campana_anterior?: number | null,
   campana_padre?: number | null,
   tipo?: string,
   status?: string
}

export const DetailCampaignGeneralView: React.FC<any> = (props): JSX.Element => {

   const history = useHistory();
   const params:any =  useParams();
   const location = useLocation();
   const {id: ID_CAMPANA } = params;
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [dataDetailCampaing, setDataDetailCampaing] = useState<any>([]);
   const [cita, setCita] = useState<any>(0);
   const [calificado, setCalificado] = useState<any>(0);
   const [contrato, setContrato] = useState<any>(0);
   const [ganado, setGanado] = useState<any>(0);
   const [perdido, setPerdido] = useState<any>(0);
   const [presentacion, setPresentacion] = useState<any>(0);
   const [busqueda, setBusqueda] = useState<any>("");
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [openModal, setOpenModal] = useState(false)
   const [openModalProduct, setOpenModalProduct] = useState(false)
   const [cliente, setCliente] = useState([])
   const [urlLeads, setUrlLeads] = useState<any>([])
   const [openLeads, setOpenLeads] = useState<boolean>(false);
   const [campana, setCampana] = useState([])
   const [estado, setEstado] = useState(false);
   const [openDialog, setOpenDialog] = useState<boolean>(false);
   const [formValue, setFormValue] = useState<any>([])
   const [channel, setChannel] = useState<any>('')
   const [openAsing, setOpenAsing] = useState(false)
   const [DataAsing, setDataAsing] = useState({})
   const [openModalMove, setOpenModalMove] = useState(false);
   const [dataModal, setDataModal] = useState<any>([])
   const [campaign, setCampaign] = React.useState([]);
   const [viewForm, setViewForm] = useState<boolean>(false);
   const [viewFormActive, setViewFormActive] = useState<boolean>(false);
   const [dataPipelines, setDataPipelines] = useState<any>([]);
   const [open, setOpen] = useState(false);
   const [openPreview, setOpenPreview] = useState(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 8000,
   })
   const [sendEmailUnique, setSendEmailUnique] = useState<any>([]);
   const [contadorPipelines, setContadorPipelines] = useState<any>([]);

   const [detailSelect, setDetailSelect] = useState<any>([])
   const [openChangeUser, setOpenChangeUser] = useState(false);
   const { idcliente:ID_CLIENTE, idchannel:CHANNEL_CAMPANA, state:STATE} = location && qs.parse(location.search.slice(1, -1));

   //agrego las state para preview
   const [seccionesCreadasEmailing, setSeccionesCreadasEmailing] = useState<any>({
      header: '',
      seccion: '',
      titleseccion: '',
      descriptionseccion: '',
      ubicacion: '',
      titleubicacion: '',
      descriptionubicacion: '',
      main: '',
      main1: '',
      main2: '',
      main3: '',
      url_brochure: '',
      label_button: '',
   });

   const [selectCampaign, setSelectCampaign ] = useState<Campaign>({
      id: 0,
      nombre:''
   })


   const handleChange = (e) => {
      setBusqueda(e.target.value)
      filtrar(e.target.value)
   }
   const [correoSelect, setCorreoSelect] = useState<any>({})
   const [openCorreo, setOpenCorreo] = useState<boolean>(false)


   useEffect(() => {
      ID_CAMPANA && getCampaingById()
      estados();
      detalleCampañaGenerales();
      getDataPipelines();
      ListImagePreview();

   }, [])

   async function getCampaingById() {
         const resp = await campaingService.getCampaingById(ID_CAMPANA);
         if (resp?.code === 200) {
            if(resp?.data?.detail){
               setSelectCampaign(resp?.data?.detail);
               if (STATE.toLowerCase() === 'true' && ID_CLIENTE) {
                  setCliente(ID_CLIENTE)
                  if(resp?.data?.detail?.nombre != 'Prospectos Correos Entrantes') setOpenDialog(true)
               }
            }
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
   }

   async function getDataPipelines() {
      const resp = await campaingService.getCampaingPipelines();
      setDataPipelines(resp.data)
   }

   async function ListImagePreview() {
      const validate = await LeadsFormService.validateFormView(ID_CAMPANA);
      if (validate.data) {
         setViewFormActive(true)
         const resp: any = await LeadsFormService.getFormTemplate(ID_CAMPANA);
         if (resp?.code === 200) {
            const detail = resp?.data?.detail;
            let ImageUbicacion = null;
            if (detail && detail?.idSecciones.Ubicacion == 1) {
               if (!(detail?.secciones.Ubicacion.imagenes.length == 0)) {
                  ImageUbicacion = detail?.secciones.Ubicacion.imagenes.img_ubicacion.ruta.replace(/\s/g, "%20") || null;
               }
            }
            console.log(detail);
            setSeccionesCreadasEmailing({
               header: detail?.idSecciones.Header == 1 && detail?.secciones.Header.imagenes.logo_header.ruta.replace(/\s/g, "%20"),
               seccion: detail?.idSecciones.Main == 1 && (detail?.secciones.Main.imagenes.img_main.ruta).replace(/\s/g, "%20"),
               titleseccion: detail?.idSecciones.Main == 1 && detail?.secciones.Main.textos.titulo.contenido,
               descriptionseccion: detail?.idSecciones.Main == 1 && detail?.secciones.Main.textos.descripcion.contenido,
               ubicacion: detail?.idSecciones.Ubicacion == 1 && ImageUbicacion,
               titleubicacion: detail?.idSecciones.Ubicacion == 1 && detail?.secciones.Ubicacion.textos.lenght > 0 && detail?.secciones.Ubicacion.textos.titulo.lenght > 0 && detail?.secciones.Ubicacion.textos.titulo.contenido || '',
               descriptionubicacion: detail?.idSecciones.Ubicacion == 1 && detail?.secciones.Ubicacion.textos.lenght > 0 && detail?.secciones.Ubicacion.textos.descripcion.lenght > 0 && detail?.secciones.Ubicacion.textos.descripcion.contenido || '',
               main: detail?.idSecciones.Main2 == 1 && (detail?.secciones.Main2.imagenes.img_main0.ruta).replace(/\s/g, "%20"),
               main1: detail?.idSecciones.Main2 == 1 && (detail?.secciones.Main2.imagenes.img_main1.ruta).replace(/\s/g, "%20"),
               main2: detail?.idSecciones.Main2 == 1 && (detail?.secciones.Main2.imagenes.img_main2.ruta).replace(/\s/g, "%20"),
               main3: detail?.idSecciones.Main2 == 1 && (detail?.secciones.Main2.imagenes.img_main3.ruta).replace(/\s/g, "%20"),
               url_brochure: detail?.url_brochure,
               label_button: detail?.label_button,
            })
         }

      }
   }

   const detalleParameterGenerales = async (idParameter, data, DataForm, DataClient) => {
      const respParameter = await bypassService.postListaByCampaign(idParameter);
      if (respParameter) {
         const Array = [];
         respParameter.map((value) => {
            DataForm.map((item) => {
               console.log(item);
               if (value.codigo == item.form_cliente) {
                  console.log(item)
                  console.log(value)
                  let objeto = {
                     form_cliente: value.codigo,
                     idpipelines: item.idpipelines,
                     idusuario: item.idusuario,
                     nombre_usuario: item.nombre_usuario,
                     email: item.email,
                     // nombre_cliente: <Box><Typography>{'Nombre'} : {item.nombre_rz}</Typography><Typography>{'Rut Cliente'} : {item.rut_cliente}</Typography></Box>,
                     idcampananegocio: item.idcampananegocio,
                     idcliente: item.idcliente,
                     iddetallecampaña: item.id,
                     nombre_pipelines: item.nombre_pipelines,
                     campaña: value.campaña,
                     canal: value.canal,
                     mailForm: value.inputs.map((value) => {
                        if (value.input == 'Correo') {
                           return value;
                        }
                     }),
                     nombre_rz: value.inputs.map((value) => {
                        return (
                           <>
                              <Typography>{value.input} : {value.value}</Typography>
                           </>
                        )

                     })
                  }
                  Array.push(objeto)
               }
            })
         })
         console.log(Array)
         const newArray = Array.concat(DataClient)
         console.log(newArray)
         setDataDetailCampaing(newArray)
      }
   }

   const detalleCampañaGenerales = async () => {
      const resp = await campaingService.getCampanaGeneral(ID_CAMPANA)//`${idcampaña}-KYTE`
      const contador = await campaingService.getCountPipelines(ID_CAMPANA)
      setContadorPipelines(contador.data);
      if (resp) {
         setCampana(ID_CAMPANA)
         let idParameter = null;
         let DataForm = [];
         let DataClient = [];
         resp.data.map((value) => {
            if (value.idparameter && !value.idcliente) {
               idParameter = value.idparameter;
               DataForm.push(value);
            } else {
               DataClient.push(value)
            }
         })
         if (idParameter) {
            detalleParameterGenerales(idParameter, resp.data, DataForm, DataClient);
         } else {
            setDataDetailCampaing(resp.data)
         }
      }

      const urls = await bypassService.getUrlsLeads(`${ID_CAMPANA}`);
      if (urls) {
         // console.log(urls)
         setUrlLeads(urls.channels)
         // ListImagePreview();
      } else {
         setViewForm(true)
      }

   }

   const RecuperarChangeDetail = async (data) => {
      const dataVendedor = {
         idusuario: data.idvendedor,
         iddetallecampaña: data.iddetallecampaña,
         idpipelines: data.idpipelines
      }
      try {
         // if (data.idvendedor !== '') {

         //    const resp = await campaingService.updateDetailsCampaignLeadsUser(data.iddetallecampaña, data.idvendedor)

         // }
         // if (data.idpipelines !== '') {
         //    const resp = await campaingService.updateDetailsCampaignLeadsPipelines(data.iddetallecampaña, data.idpipelines)

         // }
         const resp = await campaingService.updateVendedorCampanaGeneral(ID_CAMPANA, dataVendedor);
         if (resp.data) {
            detalleCampañaGenerales()
            setOpenChangeUser(false)
         }

      } catch (e) {
         console.log(e)
      }
   };

   const DataProsectoAsing = (data) => {
      setDataAsing(data)
      setOpenAsing(true)
   }
   let contCita = 0;
   let contCalificado = 0;
   let contPresentacion = 0;
   let contContrato = 0;
   let contGanado = 0;
   let contPerdido = 0;

   const estados = () => {
      // let contCita = 0;
      // let contCalificado = 0;
      // let contPresentacion = 0;
      // let contContrato = 0;
      // let contGanado = 0;
      // let contPerdido = 0;
      dataDetailCampaing.length && dataDetailCampaing.map((data) => {
         switch (data.idpipelines) {
            case 1:
               contCita++;
               break;
            case 2:
               contCalificado++;
               break;
            case 3:
               contPresentacion++;
               break;
            case 4:
               contContrato++;
               break;
            case 5:
               contGanado++;
               break;
            case 6:
               contGanado++;
               break;

         }
      })
      setCita(contCita);//hdkjdksjd



      setCalificado(contCalificado)
      setPresentacion(contPresentacion)
      setContrato(contContrato)
      setGanado(contGanado)
      setPerdido(contPerdido)
   }

   const RecuperarData = async (data) => {
      console.log(data);
      if (data.action == 'edit') {
         setOpenChangeUser(true)
         setDetailSelect(data)
      } else if (data.action == 'nota') {
         setCliente(data.idcliente)
         setCampana(data.idcampaña)
         setEstado(false)
         setOpenModal(true)
      } else if (data.action == 'move') {
         console.log(data)
         if (!data.idcliente) {
            // setOpen(true);
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'warning',
               message: 'No se puede transferir cliente si antes no se asigna a un prospecto rápido',
            }))
         }
         else {
            getCampaignPost(data.idcampananegocio)
            setDataModal(data)
            setOpenModalMove(true)
         }

      }
      else if (data.action == 'sendMail') {
         console.log(data)
         if (data.email) {
            // setSendEmailUnique(data.email)
            GenarateBrochure(data.email, data.id, data.idcliente)
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: 'Envio exitoso',
            }))
         }
         else if (data.mailForm) {
            data.mailForm.map((item) => {
               if (item && item.input == 'Correo') {
                  GenarateBrochure(item.value, data.id, data.idcliente)
                  setSnackBarConfig(prev => ({
                     ...prev,
                     open: true,
                     severity: 'success',
                     message: 'Envío exitoso',
                  }))
                  // setSendEmailUnique(item.value)
               }
            })
         }
         else if (data.action == 'hilo_correo') {
            history.push(ROUTE_CORREOS, data.email)
         } else if (data.action == 'correo') {
            console.log(data)
            setCorreoSelect(JSON.parse(data.data_correo)); setOpenCorreo(true);
         }
         else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: 'El propsecto no cuenta con un correo',
            }))
         }
      }
   }

   async function getCampaignPost(value) {

      try {
         const resp = await campaingService.getCampaignTraslate(value)
         if (resp) {
            setCampaign(resp.data)
         }
      } catch (e) {
         console.log(e)
      }

   }

   const filtrar = (terminoBusqueda) => {
      var resultBusqueda = tablaBusqueda.filter((elemento) => {
         if (elemento.nombre_rz.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())
            || elemento.nombre_usuario.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())
            || elemento.nombre_pipelines.toString().toLowerCase().includes(terminoBusqueda.toString().toLowerCase())) {
            return elemento
         }
      })
      setDataDetailCampaing(resultBusqueda)
   }

   const handleClose = () => {
      setOpenDialog(false)
   }
   const handleNo = () => {
      setOpenDialog(false)
      setEstado(true)
      setOpenModal(true)
   }

   const handleYes = () => {
      setOpenDialog(false)
      // setFormValue(props.location.state.formValue)
      setChannel(CHANNEL_CAMPANA)
      setOpenModalProduct(true)
   }

   const GenarateBrochure = async (email,id_detallecampana, idcliente) => {
      // console.log(id_detallecampana)
      const resp = await LeadsFormService.generateBrochureEmail(ID_CAMPANA, email, id_detallecampana, idcliente)
      if (resp) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'success',
            message: 'Envio Exitoso',
         }))
      }
   }

   return (
      <Protected>
         <Grid item md={9} container flexDirection='row'>
            <IconButton component={Link} to={ROUTE_CAMPAIGN} sx={{ mr: 2 }}>
               <ArrowBackIcon fill="black" />
            </IconButton>
            <Typography variant='h4' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.8em' }} >
               { selectCampaign?.nombre || ''}
            </Typography>
            {!viewForm && <IconButton sx={{ mr: 2 }} onClick={() => { setOpenLeads(true) }}>
               <TravelExploreIcon sx={{ color: '#bf6c00' }} />
            </IconButton>}
         </Grid>
         <Grid item md={12} container spacing={2} justifyContent='right' alignItems='end'>
            {
               !viewFormActive && viewForm ? <Button
                  sx={{
                     background: '#155172', color: '#fff', mr: 2, '&:hover': {
                        bgcolor: '#155172'
                     }
                  }}
                  onClick={() => {
                     saveLocalStorage(DATA_CAMPAING_LEADS, { id: campana });
                     props.$action.actionSetDataCampana({ id: campana })
                     //  props.ViewAddLeadsForm();
                     history.push(ROUTE_CAMPAING_LEADS_FORM)
                  }}
               >
                  Generar Formulario
               </Button> : <></>
            }
             {/* {
               viewForm ? <Button
                  sx={{
                     background: '#155172', color: '#fff', mr: 2, '&:hover': {
                        bgcolor: '#155172'
                     }
                  }}
                  onClick={() => {
                     // saveLocalStorage(DATA_CAMPAING_LEADS, { id: campana });
                     // props.$action.actionSetDataCampana({ id: campana })
                     // //  props.ViewAddLeadsForm();
                     // history.push(ROUTE_CAMPAING_LEADS_FORM)
                  }}
               >
                  Generar Urls
               </Button> : <></>
            } */}
            {
               viewFormActive && <Button
                  sx={{
                     background: '#155172', color: '#fff', mr: 2, '&:hover': {
                        bgcolor: '#155172'
                     }
                  }}
                  onClick={() => {
                     // GenarateBrochure('aldair1999.26@gmail.com');
                     setOpenPreview(true)
                  }}
               >
                  Editar direccionamiento por email
               </Button>
            }

            {
               viewFormActive && <Button
                  sx={{
                     background: '#155172', color: '#fff', mr: 2, '&:hover': {
                        bgcolor: '#155172'
                     }
                  }}
                  onClick={() => {
                     history.push(`/view_edit_form/${ID_CAMPANA}`, {nombre_campana: props?.location?.state?.nombre_campana});
                  }}
               >
                  Editar Landing
               </Button>
            }
         </Grid>
         {dataUser.user.role !== ROLE_SUPERVISOR && <> <Grid container flexDirection='row' justifyContent='space-around' spacing={2} sx={{ mt: 2, mb: 4 }}>
            {
               contadorPipelines.length && contadorPipelines.map((value, i) => {

                  switch (value.id) {
                     case 1:
                        return (
                           <Grid key={i} item xs={4} md={2} container flexDirection='column' alignItems='center'>
                              <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{value.cont}</Typography>
                              <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{value.name}</Typography>
                           </Grid>
                        )
                     case 2:
                        return (
                           <Grid key={i} item xs={4} md={2} container flexDirection='column' alignItems='center'>
                              <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{value.cont}</Typography>
                              <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{value.name}</Typography>
                           </Grid>
                        )
                     case 3:
                        return (
                           <Grid key={i} item xs={4} md={2} container flexDirection='column' alignItems='center'>
                              <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{value.cont}</Typography>
                              <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{value.name}</Typography>
                           </Grid>
                        )
                     case 4:

                        return (
                           <Grid key={i} item xs={4} md={2} container flexDirection='column' alignItems='center'>
                              <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{value.cont}</Typography>
                              <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{value.name}</Typography>
                           </Grid>
                        )
                     case 5:

                        return (
                           <Grid key={i} item xs={4} md={2} container flexDirection='column' alignItems='center'>
                              <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{value.cont}</Typography>
                              <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{value.name}</Typography>
                           </Grid>
                        )
                     case 6:

                        return (
                           <Grid key={i} item xs={4} md={2} container flexDirection='column' alignItems='center'>
                              <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.4em' }}>{value.cont}</Typography>
                              <Typography variant='h6' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{value.name}</Typography>
                           </Grid>
                        )
                  }
               })}
         </Grid>
            <Divider sx={{ mt: 5, mb: 5 }} />
         </>
         }
         <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item md={12}>
               <Typography variant='h5' sx={{ color: '#155172', fontWeight: '700', fontSize: '1.2em' }}>{dataDetailCampaing.length} Clientes encontrados</Typography>
               <span style={{ color: '#155172', fontSize: '1em' }}>Filtrar Resultados:</span>
            </Grid>
            <Grid item md={12}>
               <TextField
                  type='search'
                  size='small'
                  onChange={handleChange}
                  name="text"
                  value={busqueda}
                  fullWidth
               />
            </Grid>
         </Grid>
         <TableData
            header={header}
            data={dataDetailCampaing}
            action={props?.location?.state?.nombre_campana != 'Prospectos Correos Entrantes' ? dataAction : dataActionCorreo}
            RecuperarData={RecuperarData}
            Recuperarid={DataProsectoAsing} />
         <ModalViewURL
            open={openLeads}
            setOpen={setOpenLeads}
            data={urlLeads}
         />
         <ModalTomarNota
            open={openModal}
            setOpen={setOpenModal}
            data={cliente}
            campana={campana}
            estado={estado}
         />
         <ModalSearchProduct
            openModal={openModalProduct}
            setOpenModal={setOpenModalProduct}
            dataClient={cliente}
            formValue={formValue}
            channel={channel}
         />
         <ModalAsignarClient
            open={openAsing}
            setOpen={setOpenAsing}
            DataAsing={DataAsing}
         />
         <ModalMoveClient
            open={openModalMove}
            setOpen={setOpenModalMove}
            data={dataModal}
            dataListCampaing={campaign}
            refreshData={detalleCampañaGenerales}
         />
         <ModalChangeDetailCampaign
            open={openChangeUser}
            setOpen={setOpenChangeUser}
            detailSelect={detailSelect}
            setDetailSelect={setDetailSelect}
            RecuperarData={RecuperarChangeDetail}
         />
         <Dialog
            open={openDialog}
            onClose={handleClose}
         >
            <DialogTitle sx={{ mb: 2 }}>{""}</DialogTitle>
            <DialogContent>
               <DialogContentText id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '1.4em' }}>
                  ¿El cliente que está siendo atendido conoce el producto?
               </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
               <Button
                  sx={{
                     background: '#E4980D', mt: "10px", color: '#fff', '&:hover': {
                        bgcolor: '#E4980D'
                     }
                  }}
                  fullWidth
                  style={{ textTransform: "inherit" }}
                  onClick={handleNo}>NO, abrir notas para guardar lo más importante de la atención del cliente</Button>
               <Button
                  sx={{
                     background: '#0C879C', mt: "10px", color: '#fff', '&:hover': {
                        bgcolor: '#0C879C'
                     }
                  }}
                  fullWidth
                  style={{ textTransform: "inherit" }}
                  onClick={handleYes}>SI, buscar el producto solicitado en una de mis campañas activas</Button>
            </DialogActions>
         </Dialog>
         <ModalValidateTrasferClientCampaign
            open={open}
            setOpen={setOpen}
         />
         <ModalPreviewEmailing
            open={openPreview}
            data={urlLeads}
            setOpen={setOpenPreview}
            dataImage={seccionesCreadasEmailing}
            idCampana={ID_CAMPANA}
         />
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         {
            openCorreo && <ModalVerCorreo
               setOpen={setOpenCorreo}
               open={openCorreo}
               data={correoSelect}
               setData={setCorreoSelect}
            />}
      </Protected>
   )
};
