import React, { useState } from "react";
// import ChatAvatar from "./ChatAvatar";
import GetAppIcon from '@mui/icons-material/GetApp';
import moment from 'moment';
// import { Markup } from 'interweave';
import { Button, Divider } from "@mui/material";
import { API_URL_BASE } from '@/toolbox/defaults/app';

const urlTextToHTML = (txt) => {
   txt = txt.replace('</', '&lt;/');
   txt = txt.replace('<', '&lt;');
   txt = txt.replace('>', '&gt;');
   return txt.replace(/(https?:\/\/(www\.[\w\.\-]+\.[a-z]{2,}[\/\w\.\-]*|[^www\.][\w\.\-]+\.[a-z]{2,}[\/\w\.\-]*))/gi, function (url) {
      return `<a href='` + url + `' target="_blank">` + url + `</a>`;
   });
}

const ChatItem = props => {

   const [open, setOpen] = useState(false);
   const [showResendMessage, setShowResendMessage] = useState(false)

   const handleClickOpen = () => {
      setOpen((prev) => !prev);
   }


   const handleResendMessage = () => {
      setShowResendMessage(true);
   }

   const { isMyMessage, msg, image, imageUpload, user, file, hour, codeWS, sendHeader, sendFooter, imgHeader, referenceObj } = props;
   console.log('codeWS', codeWS)
   const hourChat = moment(hour).format('LT') || "00:00";
   const lines = msg ? msg.split('\n') : [];
   const linesReferences = referenceObj?.message ? referenceObj?.message.split('\n') : [];
   return (
      <div
         style={{ animationDelay: `0.8s` }}
         className={`chat-item ${isMyMessage ? "me" : "other"}`}
      >
         <div className="chat-item-content">
            <div className="chat-meta">
               <span>{isMyMessage ? "Yo" : user}</span>
               <span>{hourChat}</span>
            </div>
            {referenceObj === null ?
               <div className="chat-msg" style={{ color: codeWS != '1'? 'red' : '#000' }}>
                  {imgHeader && (
                     <>
                        <a href={`${API_URL_BASE}/${imgHeader}`} target="_blank">
                           <img style={{ maxWidth: '100%', height: 'auto', display: imgHeader ? "flex" : "none" }} src={`${API_URL_BASE}/${imgHeader}`} />
                        </a>
                        <br />
                     </>
                  )
                  }
                  {sendHeader && (
                     <><p>{sendHeader}</p> </>
                  )
                  }
                  {msg && (
                     lines.map((line) => (
                        <span key={line}>
                           {line}
                           <br />
                        </span>
                     ))
                  )
                  }
                  {sendFooter && (
                     <> <br /><p>{sendFooter}</p>  <br /></>
                  )
                  }
               </div>
               :
               <div className="chat-msg" style={{ color: codeWS != '1'? 'red' : '#000' }}>
                  {referenceObj?.imgHeader && (
                     <>
                        <a href={`${API_URL_BASE}/${referenceObj?.imgHeader}`} target="_blank">
                           <img style={{ maxWidth: '100%', height: 'auto', display: referenceObj?.imgHeader ? "flex" : "none" }} src={`${API_URL_BASE}/${referenceObj?.imgHeader}`} />
                        </a>
                        <br />
                     </>
                  )
                  }
                  {referenceObj?.sendHeader && (
                     <><p style={{color:'#f1f1f1'}}>{referenceObj?.sendHeader}</p> </>
                  )
                  }
                  {referenceObj?.message && (
                     linesReferences.map((line) => (
                        <span key={line} style={{color:'#f1f1f1'}}>
                           {line}
                           <br />
                        </span>
                     ))
                  )
                  }
                  {referenceObj?.sendFooter && (
                     <> <br /><p style={{color:'#f1f1f1'}}>{referenceObj?.sendFooter}</p>  <br /></>
                  )
                  }
                  <div style={{paddingTop:10, paddingBottom:10}}><Divider/>
                  <Divider/></div>
                  {imgHeader && (
                     <>
                       <br />
                        <a href={`${API_URL_BASE}/${imgHeader}`} target="_blank">
                           <img style={{ maxWidth: '100%', height: 'auto', display: imgHeader ? "flex" : "none" }} src={`${API_URL_BASE}/${imgHeader}`} />
                        </a>
                        <br />
                     </>
                  )
                  }
                  {sendHeader && (
                     <><p>{sendHeader}</p> </>
                  )
                  }
                  {msg && (
                     lines.map((line) => (
                        <span key={line}>
                           {line}
                           <br />
                        </span>
                     ))
                  )
                  }
                  {sendFooter && (
                     <> <br /><p>{sendFooter}</p>  <br /></>
                  )
                  }
               </div>

            }


         </div>
         {/* <ChatAvatar isOnline="active" image={image} /> */}
      </div>
   );
}

export default ChatItem;
