import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Container, InputLabel } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { indigo } from '@mui/material/colors';
import { ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { bypassService } from '@/service/services/Bypass.service';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { Link, useHistory } from 'react-router-dom';
import { ModalSearchProduct } from '@/components/common/Modal/ModalSearchProduct';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { ModalCampaignCorreo } from './ModalCampaignCorreo';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { mailService } from '@/service/services/Mail.service';

type ModalProps = {
    open: boolean,
    setOpen: any,
    setOpenAnt?:any,
    // dataProspecto?: any,
    // dataListCampaing?: any,
    refreshData?: () => void,
    dataCorreo?:any,
    setDecision?:any
}

const initialFormValues = {
    rut: '',
    nombre: '',
    telefono: '',
    correo: '',
}

export const ModalProspectoRapido: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    console.log(props)
    console.log(props.dataCorreo?.from.mail);
    console.log(props.dataCorreo?.from.personal);

    const history = useHistory();
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openModalCampaignCorreo, setOpenModalCampaignCorreo] = useState<boolean>(false)
    const [prospectoRut, setProspectoRut] = useState<any>({})
    const [canal, setCanal] = useState<any>([])
    const [selectCanal, setSelectCanal] = React.useState<any>([])
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'Error',
        autoHideDuration: 5000,
    })
    const [error, setError] = useState(null);
    const [formValues, setFormValues] = useState(initialFormValues);
    const { rut = "", nombre = "", telefono = "", correo = "" } = formValues
       useEffect(()=>{
        if(props.dataCorreo){
            const initialFormValuesCorreo = {
                rut: '',
                nombre: props.dataCorreo?.from.personal,
                telefono: '',
                correo: props.dataCorreo?.from.mail,
            }
            setFormValues(initialFormValuesCorreo)
        }
    },[])
const handleInputChange = (e) => {
        const changedFormValues = {
            ...formValues,
            [e.target.name]: e.target.value
        }
        changedFormValues.rut.trim() !== "" && setError("");
        changedFormValues.nombre.trim() !== "" && setError("");
        changedFormValues.telefono.trim() !== "" && setError("");
        changedFormValues.correo.trim() !== "" && setError("");

        setFormValues(changedFormValues)

    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        if (!props.dataCorreo && selectCanal.length === 0) { return setError("canal") }
        // if (!formValues.rut.trim()) {return setError("rut")}
        if (!nombre.trim()) { return setError("nombre") }
        // if (!telefono.trim()) {return setError("telefono");}
        // if (!correo.trim()) {return setError("correo");}
        else {
            //Obtengo el idCampaña
            if(props.dataCorreo){
                const respClient = await contactService.createClientProspecto(formValues)
                if (respClient) {
                    if(!respClient.data.status){
                        setSnackBarConfig(prev => ({
                            ...prev,
                            open: true,
                            severity: 'error',
                            message: respClient.data.error,
                        }));
                    }else{
                        const dataProspecto = {
                            id_cliente: respClient.data.cliente.idcliente,
                            rut_cliente: null,
                            data_correo: JSON.stringify(props.dataCorreo).replace(/\\n/g, ''),
                            uid_correo: props.dataCorreo.UID,
                            correo: props.dataCorreo.mail
                        }
                        console.log()
                        const resProspecto: any = await mailService.postProspectoCliente(dataProspecto);
                        setProspectoRut(respClient.data.cliente)
                        setOpenModalCampaignCorreo(true)
                    }
                    return true;
                }
            }
            let campana = []
            const respCanal = await campaingService.getCampanaProspecto(selectCanal['id'])
            console.log(respCanal)
            if (respCanal) {
                campana = respCanal.data
                console.log(campana[0]['id'])
            }
            //Creo al cliente
            let client = [];
            const respClient = await contactService.createClientProspecto(formValues)
            if (respClient) {
                if (!respClient.data.status) {
                    setSnackBarConfig(prev => ({
                        ...prev,
                        open: true,
                        severity: 'error',
                        message: respClient.data.error,
                    }));
                } else {
                    //Campana Negocio General
                    const dataUser = readLocalStorage(KEY_USER_DATA);
                    const id_empresa = dataUser?.user?.idempresacliente;
                    client = respClient.data.cliente
                    const dataClient = {
                        idcampananegocio: campana[0]['id'],
                        productos: null,
                        idparameter: null,
                        form_cliente: null,
                        observacion: 'Ninguna',
                        idcliente: client['idcliente'],
                        asign_automatic:true,
                        id_empresa:id_empresa
                    }
                    const respCampana = await campaingService.createCampanaGeneral(dataClient)
                    if (respCampana) {
                        setFormValues(initialFormValues)
                        setSelectCanal([])
                        props.setOpen(false)
                        // history.push(`/campaña/generales/${campana[0]['id']}`, { cliente: respCampana.data.idcliente, nombre_campana: respCampana.data.nombre_campana, formValue: formValues, channel: selectCanal['id'] })
                        const newTabUrl = `/campaña/generales/${campana[0]['id']}?idcliente=${respCampana.data.idcliente}&idchannel=${selectCanal['id']}&state=${true};`;
                        window.open(newTabUrl, '_blank');
                     }
                }
            } else {
                console.log('error')
            }
        }
    }

    useEffect(() => {
        getChannelBypass();
    }, [])

    const getChannelBypass = async () => {
        try {
            const resp = await campaingService.getChannel()
            if (resp) {
                setCanal(resp.data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const bodyModal = (
        <Box className='Modal'>
           <Box>
           <form onSubmit={handleSubmitForm}>
                    <Container maxWidth='xl' sx={{ mt: 1, mb: 2, maxHeight: 600, overflow: 'auto' }}>
                        <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>Prospecto</Typography>
                       {! props.dataCorreo && <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Canales</InputLabel>
                            <Autocomplete
                                id="tags-outlined"
                                options={canal}
                                getOptionLabel={(option: any) => option.name ? option.name : ''}
                                size={'small'}
                                fullWidth
                                sx={{ bgcolor: '#fff' }}
                                value={selectCanal}
                                onChange={(e, newValue) => {
                                    setSelectCanal(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={error === "canal" ? true : false}
                                        helperText={error === "canal" ? "El canal es un campo requerido" : ""}
                                        label="Seleccionar Canal"
                                        placeholder=""
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>}
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Rut</InputLabel>
                            <TextField
                                size='small'
                                sx={{ bgcolor: '#fff' }}
                                error={error === "rut" ? true : false}
                                helperText={error === "rut" ? "Rut es es un campo requerido" : ""}
                                id='rut'
                                name='rut'
                                label="Ingrese Rut"
                                fullWidth
                                value={rut}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Nombre</InputLabel>
                            <TextField
                                size='small'
                                sx={{ bgcolor: '#fff' }}
                                error={error === "nombre" ? true : false}
                                helperText={error === "nombre" ? "Nombre es es un campo requerido" : ""}
                                id='nombre'
                                name='nombre'
                                label="Ingrese Nombre"
                                fullWidth
                                value={nombre}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Telefono</InputLabel>
                            <TextField
                                size='small'
                                sx={{ bgcolor: '#fff' }}
                                // error={error === "telefono" ? true : false}
                                // helperText={error === "telefono" ? "Telefono es es un campo requerido" : ""}
                                id='telefono'
                                name='telefono'
                                label="Ingrese Telefono"
                                fullWidth
                                value={telefono}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                            <InputLabel >Correo</InputLabel>
                            <TextField
                                size='small'
                                sx={{ bgcolor: '#fff' }}
                                // error={error === "correo" ? true : false}
                                // helperText={error === "correo" ? "Correo es es un campo requerido" : ""}
                                id='correo'
                                name='correo'
                                label="Ingrese correo"
                                fullWidth
                                value={correo}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Container>
                    <Grid item container
                        direction="row"
                        justifyContent="end"
                        alignItems="center">
                        <Button
                            onClick={() => {
                                if(props.dataCorreo){
                                    props.setOpen(false);
                                    props.setDecision(0)
                                }else{
                                    setFormValues(initialFormValues)
                                    setSelectCanal([])
                                    props.setOpen(false)
                                }

                            }}
                            variant="contained"
                            // fullWidth
                            color="error"
                            startIcon={<CancelIcon />}
                            sx={{
                                background: '#bf6c00', color: '#fff', mt: '10px', mr: '10px', '&:hover': { bgcolor: '#bf6c00' }
                            }}>
                            <span>{props.dataCorreo ? 'Regresar':'Cancelar'}</span>
                        </Button>
                        <Button
                            variant='contained'
                            type='submit'
                            //   onClick={handleSubmitForm}
                            startIcon={<SaveIcon />}
                            sx={{
                                background: '#155172', color: '#fff', mt: '10px', mr: '10px', '&:hover': {
                                    bgcolor: '#155172'
                                }
                            }}
                        >
                            <span>{props.dataCorreo ? 'Siguiente':'Guardar'}</span>
                        </Button>
                    </Grid>
                </form>
           </Box>
        </Box>
    )
    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    {bodyModal}
            </Modal>
            {
            openModalCampaignCorreo && <ModalCampaignCorreo
            open={openModalCampaignCorreo}
            setOpen={setOpenModalCampaignCorreo}
            data={prospectoRut}
            setData={setProspectoRut}
            setOpenAnt = {props.setOpen}
            setOpenPreAnt = {props.setOpenAnt}
            dataCorreo ={props.dataCorreo}
            />
           }
        </div>
    );
}
