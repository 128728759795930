import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';

import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { ConfirmDialog } from '../DialogConfirm';
import { indigo } from '@mui/material/colors';

const dataUser = [
    'Luis Fernando Ramirez',
    'Aldair Gonzales Quichiz',
    'Lincoln Moreno Garcilazo',
    'Giovanna Villanueva Rodriguez',
    'Jordan Chinga Ramos'
]
/*CODIGO PROPIO*/
function createData(action, hito, advance, comment, dateReport) {
    return { action, hito, advance, comment, dateReport };
}
const rows = [
    createData('Acción 4', 'hito 4', '25%', 'comentario 4', '24/10/2019'),
    createData('Acción 5', 'hito 5', 'progreso 5', 'comentario 5', '25/10/2020'),
    createData('Acción 6', 'hito 6', 'progreso 6', 'comentario 6', '19/04/2021')
];

/*FIN CODIGO PROPIO*/
type ModalProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const ModalActionDetails: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {
    const [error, setError] = useState(null);
    const [value, setValue] = useState<string[]>(dataUser);
    const [open, setOpen] = useState(false)
    const handleInputChange = (e, newValue) => {
        setValue(newValue);
    }
    const handleSubmitContact = (e) => {
        e.preventDefault();
        //console.log('value', value)
        setValue([])
        props.setOpen(false);

    }
    const onClose = () => {
        setOpen(false)
    }
    const bodyModal = (
        <>
        <Grid mb={2} >
            <Typography variant='h5'>Más información de Acciones</Typography>
        </Grid>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow style={{backgroundColor:indigo.A700}}>
                        <TableCell style={{ color: 'white'}}>Acciones</TableCell>
                        <TableCell style={{ color: 'white'}} align="right">Hito</TableCell>
                        <TableCell style={{ color: 'white'}} align="right">Avance</TableCell>
                        <TableCell style={{ color: 'white'}} align="right">Comentario</TableCell>
                        <TableCell style={{color: 'white'}} align="right">Fecha de reporte</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.action}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.action}
                            </TableCell>
                            <TableCell align="right">{row.hito}</TableCell>
                            <TableCell align="right">{row.advance}</TableCell>
                            <TableCell align="right">{row.comment}</TableCell>
                            <TableCell align="right">{row.dateReport}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
    return (
        <div>

            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'auto',
                    bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p: 3
                }}>

                    {bodyModal}
                </Box>

            </Modal>
            <ConfirmDialog
                open={open}
                title={'Desea Asignar de Manera Automática?'}
                onConfirm={onClose}
                onClose={onClose}
            />
        </div>
    );
}
