import { Paper, Button, IconButton, Card, Typography, Avatar, Grid } from "@mui/material";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from '@mui/styles';
import { campaingService } from "@/service/services/Campaing.service";
import { ModalChangeDetailCampaign } from "@/components/common/Modal/ModalChangeDetailCampaing";


const TrelloCard = ({ card, index, pipelines }) => {
  const classes = useStyle();
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detailSelect, setDetailSelect] = useState<any>([]);


  const RecuperarChangeDetail = async(data) => {

   if(data.idvendedor!=='') {

   const resp = await campaingService.updateDetailsCampaignLeadsUser(data.parseiddetallecampaña,data.idvendedor)

   if(resp.data){
      //getDetalleCampaing();
      pipelines()
      setOpenModal(false)
   }}
};

    //  const nomb_formclient= card.inputs.filter(data => data.input == 'Nombre' || 'nombre');
  return (
    <>
      <Draggable draggableId={card.iddetallecampaña} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <Paper
              className={classes.trelloCard}
            >
               <Grid container justifyContent='space-between'>
                     <Grid item xs={9}>
                        <Typography> 
                          {card.inputs.map((value)=>{
                           if(value.input=='Nombre' || value.input=='nombre'){
                              return value.value
                           }
                        })

                        }</Typography>
                     </Grid>
                     <Grid item>
                       <Avatar sizes='small' sx={{background:'#1976D2'}}
                       onClick={()=>{setOpenModal(true)
                        setDetailSelect(card)
                     }}>
                        {card.nombre_user==null?'':card.nombre_user.charAt(0)}</Avatar>
                     </Grid>
               </Grid>

              {/* {card.nombre_usuario} */}

            </Paper>
          </div>
        )}
      </Draggable>
      <ModalChangeDetailCampaign
         open={openModal}
         setOpen={setOpenModal}
         detailSelect={detailSelect}
         setDetailSelect={setDetailSelect}
         RecuperarData={RecuperarChangeDetail}
         source='trello'
         />
    </>
  );
};

export default TrelloCard;

const useStyle = makeStyles((theme) => ({
  trelloCard: {
    padding: 10,
    margin: 10,
  },
}));
