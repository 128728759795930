import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './TemplateWs.type';
import { Alert, Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputBase, InputLabel, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TemplateWsService } from '@/service/services/TemplateWs.service';
import { TableData } from '@/components/common/Table';
import { ModalAddVariablesTemplateWs } from '@/components/common/Modal/ModalAddVariablesTemplateWs';


const header = [
   { name: 'name', label: 'Nombre', width: '' },
   { name: 'category', label: 'Categoria', width: '' },
   { name: 'language', label: 'Lenguaje', width: '' },
   { name: 'statusWs', label: 'Estado en Whatsaap', width: '' },
]
const dataAction = [
   { name: 'WS_template', color: 'success', route: null },
]

type SnackBar = {
   open: boolean,
   severity: any,
   message: string,
   autoHideDuration: number,
}

type Templates = {
   id: number,
   idTemplateWs: string,
   name: string,
   selectHeader: string,
   language: string,
   category: string,
   components: string,
   textHeader: string | null,
   variablesHeader: string | null,
   inputValuesHeader: string | null,
   imageHeader: string | null,
   textBody: string,
   variablesBody: string,
   inputValuesBody: string,
   textFooter: string | null,
   arrayButtons : string | null,
   idempresa: number,
   status: string | null,
   created_at: string | null,
   updated_at: string | null,
   statusWs: string
}

type VariableDataHeader = {
   [key: string]: string;
};
type VariableDataBody = {
   [key: string]: string;
};
type OpenData = {
   status: boolean,
   data: Templates
}


export const TemplateWsView: React.FC<Props> = (props: Props): JSX.Element => {

   const dataUser = readLocalStorage(KEY_USER_DATA);
   const [snackBarConfig, setSnackBarConfig] = useState<SnackBar>({
      open: false,
      severity: 'success',
      message: 'éxito',
      autoHideDuration: 3000,
   })
   const [rows, setRows] = useState<Templates []>([])
   const [openData, setOpenData] = useState<OpenData>({
      status: false,
      data: {
         id: 0,
         idTemplateWs: '',
         name: '',
         selectHeader: '',
         language: '',
         category: '',
         components: '',
         textHeader: '',
         variablesHeader: '',
         inputValuesHeader: '',
         imageHeader: '',
         textBody: '',
         variablesBody: '',
         inputValuesBody: '',
         textFooter: '',
         arrayButtons : '',
         idempresa: 0,
         status: '',
         created_at: '',
         updated_at: '',
         statusWs: ''
      }
   });

   useEffect(() => {
      getTemplateWs();
   }, [])

   async function getTemplateWs() {
      try {
         const resp = await TemplateWsService.getTemplateWs()
         if (resp?.code === 200) {
            resp?.data?.detail && setRows(resp?.data?.detail)
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      } catch (e) {
         console.log(e)
      }
   }

   const RecuperarData = async (template: Templates) => {
      console.log('template', template)
      if(template?.statusWs !== 'APPROVED'){
         return setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: `El template tiene un estado ${template?.statusWs}`,
         }));
      }
      let VariableDataHeader = [];
      let VariableDataBody = [];
      if (template?.variablesHeader) {
         VariableDataHeader = JSON.parse(template?.variablesHeader);
      }
      if (template?.variablesBody) {
         VariableDataBody = JSON.parse(template?.variablesBody);
      }
      setOpenData({status: true, data: template})

   };

   const handleCreateTemplateWs = () => {
      const newTabUrl = `/template-ws/create`;
      window.open(newTabUrl, '_self');
   }

   const syncTemplateWs = async () => {
      try {
         const resp = await TemplateWsService.syncTemplate()
         if (resp?.code === 200) {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'success',
               message: resp?.data?.message,
            }));
            getTemplateWs();
         } else {
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp?.data?.message,
            }));
         }
      } catch (e) {
         console.log(e)
      }
   }

   return (
      <Protected>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Grid container>
            <Grid container justifyContent='space-between'  alignItems='center' spacing={2} mb={1}>
               <Grid item xs={4}>
                  <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>Mis Templates</Typography>
               </Grid>

               <Grid item container justifyContent='space-around' xs={5}>
               <Button variant='contained' onClick={()=>{syncTemplateWs()}}>Sincronizar Template Ws</Button>
                  <Button variant='contained' onClick={()=>{handleCreateTemplateWs()}}>Crear Template Ws</Button>
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                  <TableData
                     header={header}
                     data={rows}
                     action={dataAction}
                     RecuperarData={RecuperarData}
                     />
               </Container>
            </Grid>
         </Grid>
         <ModalAddVariablesTemplateWs openData={openData} setOpenData={setOpenData}/>
      </Protected>
   )
};
