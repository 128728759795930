import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import { Protected } from '@components/layout/Protected';
import { Props } from './CotizacionesInterna.type';
import { TableData } from '@/components/common/Table';
import { Button, Grid, TextField, Backdrop, CircularProgress, MenuItem, Select, InputLabel, FormControl, FormHelperText, Snackbar, Box, AppBar, Toolbar, Typography, Divider, Container, Autocomplete } from '@mui/material';
import { cotizacionService } from '@/service/services/Cotizacion.service';
import { readLocalStorage, removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA, KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_TOOGLE_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import { CSVLink } from "react-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ModalDetail } from '@/components/common/Modal/ModalDetail';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import moment from 'moment'
import { ROUTE_TRELLO_COTIZACIONES } from '@/toolbox/constants/route-map';
import { TrelloCotizacionesView } from '../Trello_cotizaciones';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { SpinnerGrow } from '@/components/common/Spinner';
import { productService } from '@/service/services/Product.service';

export const CotizacionesInternasView: React.FC<Props> = ({ }): JSX.Element => {
   const header = [
      { name: 'folio', label: 'Folio', width: '' },
      { name: 'rut_cliente', label: 'RUT Cliente', width: '100px' },
      { name: 'razon_social', label: 'Razon Social', width: '' },
      { name: 'rut_vendedor', label: 'RUT Vendedor', width: ''},
      { name: 'nombre_vendedor', label: 'Vendedor', width: '' },
      { name: 'fecha', label: 'Fecha', width: '100px' },
      { name: 'estado', label: 'Estado', width: '' },
      { name: 'valor_iva', label: 'IVA', width: '100px' },
      { name: 'valor_neto', label: 'Valor Neto', width: '100px' },
      { name: 'valor_total', label: 'Valor Total', width: '110px' }]
   const headers = [
      { label: 'Folio', key: "folio" },
      { label: 'Rut Cliente', key: "rut_cliente" },
      { label: 'Razon Social', key: "razon_social" },
      { label: 'Fecha', key: "fecha" },
      { label: 'Estado', key: "estado" },
      { label: 'Vendedor', key:"nombre_usuario"},
      { label: 'IVA', key: "valor_iva" },
      { label: 'Valor Neto', key: "valor_neto" },
      { label: 'Valor Total', key: "valor_total" }
   ]
   const dataAction = [
      { name: 'view', color: 'success', route: null },
      ]

   const CodigosProducto = [
      'CLA-100-23-CH',
      'CLA-100-22-CH',
      'CLA-RO750HR-ECO',
      'CLA-RO150HR-ECO',
      'PROD-PLT-125LPH',
      'PROD-PLS-125LPH',
      'PROD-PLT-750LPH',
      'PROD-PLS-750LPH',
      'PROD-PLT-1500LPH',
      'PROD-PLS-1500LPH',
   ];


   const history= useHistory();
   const [product, setProduct] = useState<any>([]);
   const [productTrello, setProductTrello] = useState<any>([])
   const [productFiltrados, setProductFiltrados] = useState<any>([])
   const [open, setOpen] = useState<boolean>(false);
   const [openTrello, setOpenTrello] = useState<boolean>(false);
   const [openFilter, setOpenFilter] = useState<boolean>(false);
   const [openFilterEstado, setOpenFilterEstado] = useState<boolean>(false);
   const [error, setError] = useState(null);
   const [filter, setFilter] = useState(null);
   const [filterEstado, setFilterEstado] = useState(null);
   const [filterFechas, setFilterFechas] = useState(0);
   const [openModalDetalle, setOpenModalDetalle] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const [openLoad, setOpenLoad] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [dataProducts, setDataProducts] = useState([]);
   const dataInitial =
      {
         codigo: null,
         filtrofechas: null,
         periodoInicial: null,
         mesInicial: null,
         periodoFinal: null,
         mesFinal: null,
         length: null
      }

   const [data, setData] = useState(dataInitial);
   const [dataDetalleCotizacion, setDataDetalleCotizacion] = useState<any>({});

   const [codigoProductoSelect, setCodigoProductoSelect] = useState(null);
   const getProducts = async () => {
      setOpen(true)
      const resp = await productService.getDataProducts()
      console.log(resp)
      setDataProducts(resp.data.products)
      setOpen(false)
   }

   useEffect(()=>{
      getProducts()
   },[])
   const setDataCotizacionProducto = async (data) => {
      if(data.action==='view'){
         setOpen(true)
         const Token = readLocalStorage(KEY_SOFTNET);
         const Empresa = readLocalStorage(KEY_USER_DATA);
         const ConsultaDocumento = await cotizacionService.consultaDocumento(Token, {
            rut: Empresa.user.rut_empresa,
            tipo: data.tipo_doc,
            folio: data.folio
         })
         setOpen(false)
         DetalleCotizacion(ConsultaDocumento)
         setOpenModalDetalle(true)
      }else{
         alert('DELETE')
      }

   }

   const nextPage = (toroute, row) => {

      history.push(toroute, row)

      };

   const DetalleCotizacion = (ConsultaDocumento) => {
      const ArrayDetalleCotizacion = [];
      ConsultaDocumento?.Detalle.map((value) => {
         ArrayDetalleCotizacion.push({ ...value, PrcItem: moneyFormat(value.PrcItem), MontoItem: moneyFormat(value.MontoItem) })
      })
      setDataDetalleCotizacion(ArrayDetalleCotizacion)
   }
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      codigoProductoSelect !== null && setError("")
      data.periodoInicial !== "" && setError("")

      switch (name) {
         case 'codigo':
            setData(prev => ({ ...prev, codigo: value }));


            break;
         case 'fechainicio':
            setData(prev => ({ ...prev, periodoInicial: value }));

            break;
         case 'fechafin':
            setData(prev => ({ ...prev, periodoFinal: value }));

            break;
         case 'filtrofechas':
               setData(prev => ({ ...prev, periodoFinal: value }));

               break;
         default:
            break;
      }
   }


   const handleSubmit = async (e) => {
      e.preventDefault();
      if (!codigoProductoSelect) {
         return setError("codigo");
      }

      else {
         const Token = readLocalStorage(KEY_SOFTNET)
         setProduct([])
         let alertValidado = null
         let dataPeriodoFinal=null
         let ProductosCotizados: any = [];

         let yearFinal =null
         let monthFinal =null

         let yearInitial = null
         let monthInitial = null

         let yearActual = null
         let monthActual = null

         let dataPeriodoInicial =null

         if(filterFechas==0){
            yearActual = new Date().getFullYear();

            setLoading(true)
            setOpen(true)

            for (let i = 1; i <= 12; i++) {
               const resp = await cotizacionService.cotizacionProducto(Token, {
                  codigo: codigoProductoSelect.codigo,
                  periodo: yearActual,
                  mes: `${i}`
                  //`${contador}`
               })
               resp.map((value) => {
                  ProductosCotizados.push({
                     ...value, valor_iva: moneyFormat(value.valor_iva), valor_neto: moneyFormat(value.valor_neto),
                     valor_total: moneyFormat(value.valor_total)
                  })
               })
               //contador++;

            }


         }
         else if(filterFechas==1){
            yearActual = new Date().getFullYear();
            monthInitial= new Date().getMonth()+1;

            const resp = await cotizacionService.cotizacionProducto(Token, {
               codigo: codigoProductoSelect.codigo,
               periodo: yearActual,
               mes: monthInitial
            })

            resp.map((value) => {
               ProductosCotizados.push({
                  ...value, valor_iva: moneyFormat(value.valor_iva), valor_neto: moneyFormat(value.valor_neto),
                  valor_total: moneyFormat(value.valor_total)
               })
            })

         }
         else if(filterFechas==2){
            if(data.periodoInicial!==null){


            dataPeriodoInicial = new Date(data.periodoInicial)
            yearInitial = dataPeriodoInicial.getFullYear();
            monthInitial = dataPeriodoInicial.getMonth() + 2;

            let MesInicial = monthInitial === 13 ? 1 : monthInitial;
            let AñoInicial = monthInitial === 13 ? yearInitial + 1 : yearInitial;
            //let contador = MesInicial;

            const resp = await cotizacionService.cotizacionProducto(Token, {
                     codigo: codigoProductoSelect.codigo,
                     periodo: AñoInicial,
                     mes: MesInicial
                  })

                  resp.map((value) => {
                     ProductosCotizados.push({
                        ...value, valor_iva: moneyFormat(value.valor_iva), valor_neto: moneyFormat(value.valor_neto),
                        valor_total: moneyFormat(value.valor_total)
                     })
                  })



            }
            else{
               setError('mes')
            }
         }
         else if(filterFechas==3){
            if(data.periodoFinal!==null&&data.periodoInicial!==null){
               dataPeriodoFinal = moment(data.periodoFinal).format('YYYY-MM-DD');

               yearFinal = moment(dataPeriodoFinal).format('YYYY');
               monthFinal = moment(dataPeriodoFinal).format('MM');

               dataPeriodoInicial = moment(data.periodoInicial).format('YYYY-MM-DD');
               yearInitial =  moment(dataPeriodoInicial).format('YYYY');
               monthInitial =  moment(dataPeriodoInicial).format('MM');

                     setLoading(true)
                     setOpen(true)
                     if(dataPeriodoInicial>=dataPeriodoFinal){
                        setSnackBarConfig(prev => ({ ...prev, open: true, message: 'Rango de Fechas incorrecto' }))
                     }

                     while(dataPeriodoInicial<dataPeriodoFinal){

                        let periodo = moment(dataPeriodoInicial).format('YYYY')
                        let mes = moment(dataPeriodoInicial).format('MM')
                        const resp = await cotizacionService.cotizacionProducto(Token, {
                                       codigo: codigoProductoSelect.codigo,
                                       periodo: periodo,
                                       mes: mes
                        })
                        resp.map((value) => {
                           ProductosCotizados.push({
                              ...value, valor_iva: moneyFormat(value.valor_iva), valor_neto: moneyFormat(value.valor_neto),
                              valor_total: moneyFormat(value.valor_total)
                           })
                        })
                        dataPeriodoInicial = moment(dataPeriodoInicial).add(1,'M').format('YYYY-MM-DD')

                     }
            }
            else{
               if(!data.periodoFinal){
                  setError('fechafin')
               }
               if(!data.periodoInicial){
                  setError('fechainicio')
               }
            }
         }
         else if(filterFechas==4){

            if(data.periodoFinal!==null&&data.periodoInicial!==null){
               dataPeriodoFinal = moment(data.periodoFinal).format('YYYY-MM-DD');

               yearFinal = moment(dataPeriodoFinal).format('YYYY');
               monthFinal = moment(dataPeriodoFinal).format('MM');

               dataPeriodoInicial = moment(data.periodoInicial).format('YYYY-MM-DD');
               yearInitial =  moment(dataPeriodoInicial).format('YYYY');
               monthInitial =  moment(dataPeriodoInicial).format('MM');

                     setLoading(true)
                     setOpen(true)
                     if(dataPeriodoInicial>=dataPeriodoFinal){
                        setSnackBarConfig(prev => ({ ...prev, open: true, message: 'Rango de Fechas incorrecto' }))
                     }
                     while(dataPeriodoInicial<dataPeriodoFinal){

                        let periodo = moment(dataPeriodoInicial).format('YYYY')
                        let mes = moment(dataPeriodoInicial).format('MM')
                        const resp = await cotizacionService.cotizacionProducto(Token, {
                                       codigo: codigoProductoSelect.codigo,
                                       periodo: periodo,
                                       mes: mes
                        })
                        resp.map((value) => {
                           ProductosCotizados.push({
                              ...value, valor_iva: moneyFormat(value.valor_iva), valor_neto: moneyFormat(value.valor_neto),
                              valor_total: moneyFormat(value.valor_total)
                           })
                        })
                        dataPeriodoInicial = moment(dataPeriodoInicial).add(1,'M').format('YYYY-MM-DD')

                     }

                     ProductosCotizados = ProductosCotizados.filter(n => n.fecha > dataPeriodoInicial && n.fecha < dataPeriodoFinal)


            }
            else{
               if(!data.periodoFinal){
                  setError('fechafin')
               }
               if(!data.periodoInicial){
                  setError('fechainicio')
               }
            }
         }
         let arrayJson = JSON.stringify(ProductosCotizados).replace(/\\n/g, '')

         if(ProductosCotizados.length==0)
         {
            setLoading(false)
            setOpen(false)
            setOpenTrello(false)
             return '';
         }
         const cotizacionescrm = await cotizacionService.cotizacionesCRM(arrayJson);

         setProduct(cotizacionescrm.data.tabla)
         setProductTrello(cotizacionescrm.data.trello)
         setFilter(0)
         const ProductsFilter0 = cotizacionescrm.data.tabla.filter(product =>product.estado_cotizacion=== 1 )
            setProductFiltrados(ProductsFilter0)
         setFilterEstado(0)
         setOpenFilter(true)
         setOpenFilterEstado(true)
         setLoading(false)
         setOpen(false)


      }
   }

   const handleChangeFilter = (e) => {
      setFilter(e.target.value)
      let newArray;
      const pos = e.target.value;
      switch (pos) {
         case 0:
            newArray = productFiltrados.sort(function (a, b) {
               if (a.fecha > b.fecha) {
                  return 1;
               }
               if (a.fecha < b.fecha) {
                  return -1;
               }
               return 0;
            })
            setProductFiltrados(newArray)
            break;
         case 1:
            setProductFiltrados(productFiltrados.reverse((a, b) => a.fecha > b.fecha))
            break;

      }
   }

   const handleChangeFilterEstado = (e) => {
      setFilterEstado(e.target.value)
      const pos = e.target.value;

      switch (pos) {
         case 0:
            const ProductsFilter0 = product.filter(product => product.estado_cotizacion=== 1 )
            setProductFiltrados(ProductsFilter0)
            setFilter(0)
            break;
         case 1:
            const ProductsFilter1 = product.filter(product => product.estado_cotizacion=== 2 )

            setProductFiltrados(ProductsFilter1)
            setFilter(0)
            break;
         case 2:
            const ProductsFilter2 = product.filter(product => product.estado_cotizacion=== 3 )
            setProductFiltrados(ProductsFilter2)
            setFilter(0)
            break;
         case 3:
            const ProductsFilter3 = product.filter(product => product.estado_cotizacion=== 4 )
            setProductFiltrados(ProductsFilter3)
            setFilter(0)
            break;
      }
   }

   const handleChangeFilterFechas = (e) => {
      setFilterEstado(e.target.value)
      const pos = e.target.value;

      switch (pos) {
         case 0:
               setFilterFechas(0)
               setError('')

               break;
         case 1:
               setFilterFechas(1)
               setError('')

               break;
         case 2:
               setFilterFechas(2)
               setError('')

               break;
         case 3:
               setFilterFechas(3)
               setError('')

               break;
         case 4:
               setFilterFechas(4)
               setError('')

               break;
      }
   }

   const CambioDataTable=(data)=>{

      const cambioName = product.map((producto) =>producto.folio === data.folio? data : producto)

      setProduct(cambioName);
      setFilter(0)
      const ProductsFilter0 = cambioName.filter(product =>product.estado_cotizacion=== 1 )
      setProductFiltrados(ProductsFilter0)
      setFilterEstado(0)
       //setProductFiltrados(cambioName);
   }
   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
          <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
         {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop> */}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item>
                        <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}} >Mis Cotizaciones</Typography>
                    </Grid>
                    {/* <Grid item>
                        <Button variant='outlined'>VISTA TRELLO</Button>
                    </Grid> */}
                </Grid>
                <Divider
                    sx={{ mt: 2 , mb:2}} />
         <Grid>
            <form onSubmit={handleSubmit} >
               <Grid item container
                  direction="row"
                  justifyContent="center"
                  alignItems="center" spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={2} sx={{ mt: 1 }}>
                  <Grid >
                     Seleccione un Producto:
                  </Grid>
                     {/* <FormControl variant="standard" fullWidth sx={{ mt:1, minWidth: 100 }}  error={error === "codigo"}>
                        <Select
                           sx={{ bgcolor: '#fff' }}
                           id="codigo"
                           placeholder='Seleccione un Producto'
                           fullWidth
                           label='Código Producto*'
                           name="codigo"
                           onChange={handleInput}
                        >
                           {CodigosProducto.map((option, i) => {
                              return <MenuItem key={i} value={option}>{option}</MenuItem>
                           })
                           }
                        </Select>
                        {error === "codigo" && <FormHelperText>Código campo requerido</FormHelperText>}
                     </FormControl> */}
                     <Autocomplete
                        autoComplete
                        fullWidth
                        sx={{ bgcolor: '#fff' }}
                        size='small'
                        includeInputInList
                        value={codigoProductoSelect}
                        onChange={(e, products) => {
                           console.log(e)
                          console.log(products)
                          setCodigoProductoSelect(products)
                        }}
                        id="productss"
                        options={dataProducts}
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) => <TextField {...params}  error={error === "codigo" ? true : false} helperText={error === "producto" ? "Producto es un campo requerido" : ""} />}
                     />
                  </Grid>

                  <Grid item xs={12} md={2} sx={{mt:1}}>
                  <Grid >
                     Filtrar Fecha por:
                  </Grid>
                  <FormControl variant="standard" fullWidth sx={{ mt:1, minWidth: 100 }}>
                        <Select
                           id="filtros-fechas"
                           name='filtrofechas'
                           value={filterFechas}
                           onChange={handleChangeFilterFechas}
                           label="Age"
                        >
                           <MenuItem value={0}>Este Año</MenuItem>
                           <MenuItem value={1}>Este Mes</MenuItem>
                           <MenuItem value={2}>Por Mes</MenuItem>
                           <MenuItem value={3}>Rango de Mes</MenuItem>
                           <MenuItem value={4}>Rango de Fechas</MenuItem>
                        </Select>
                        {error === "filtrofechas" && <FormHelperText>El filtro es un campo requerido</FormHelperText>}
                     </FormControl>
                  </Grid>
                  {filterFechas==2||filterFechas==3||filterFechas==4?(
                     <>
                     <Grid item xs={12} md={3}>
                     <TextField
                        margin="normal"
                        error={(error === "fechainicio" || error==='mes')? true : false}
                        helperText={error === "fechainicio" ? "Fecha Inicial campo requerido" : (error === "mes"?'Mes es un campo requerido':'')}
                        InputLabelProps={{ shrink: true, required: true }}
                        fullWidth
                        size= 'medium'
                        name="fechainicio"
                        placeholder="Fecha Inicio"
                        type={filterFechas==4?"date":"month"}
                        id="fechainicio"
                        label="Fecha Inicio"
                        autoFocus
                        //value={prioridad}
                        onChange={handleInput}
                        autoComplete="current-password"
                     />
                  </Grid>
                     </>
                  ):(<></>)}
                  {
                     filterFechas==3||filterFechas==4?
                     (
                        <>
                        <Grid item xs={12} md={3} >
                           <TextField
                              margin="normal"
                              error={error === "fechafin" ? true : false}
                              helperText={error === "fechafin" ? "Fecha Fin campo requerido" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              fullWidth
                              size= 'medium'
                              name="fechafin"
                              placeholder="Fecha Fin"
                              type={filterFechas==4?"date":"month"}
                              id="fechafin"
                              label="Fecha Fin"
                              autoFocus
                              //value={prioridad}
                              onChange={handleInput}
                              autoComplete="current-password"
                           />
                        </Grid>
                        </>
                     ):(<></>)
                  }

                  <Grid item xs={12} md={1} sx={{ mt: 1 }}>
                  <Box sx={{position: 'relative' }}>
                  <Button
                  fullWidth
                     type='submit'
                     size='large'
                     variant='contained'
                     onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                     sx={{background:'#bf6c00', color:'#fff', '&:hover':{
                        bgcolor:'#bf6c00'
                       }}}
                  >
                     <span>Aplicar</span>
                  </Button>
                  {loading && (
                     <CircularProgress
                        size={24}
                        sx={{
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           marginTop: '-12px',
                           marginLeft: '-12px',
                        }}
                     />
                  )}
               </Box>
                  </Grid>
               </Grid>
            </form>

               <Grid container>
                  <Grid item >
                   <Button variant='outlined' onClick={()=>{
                     if(openFilter){
                        setOpenTrello(false)
                     }

                  }}>
                   <TableChartIcon/>
                   </Button>
                  </Grid>
                  <Grid item>
                  <Button variant='outlined' onClick={()=>{
                     if(openFilter){
                        setOpenTrello(true)
                     }
                     }}  >
                     <TableRowsIcon/>
                   </Button>
                  </Grid>
               </Grid>
            {/* <Grid item xs={12} >
               <TableData
                  header={header}
                  data={productFiltrados}
                  action={dataAction}
                  RecuperarData={setDataCotizacionProducto}
               />
            </Grid> */}
          {openTrello?(
               <TrelloCotizacionesView
               Data={productTrello}
               open={openTrello}
               CambioDataTable={CambioDataTable}
               setOpen={setOpenTrello}/>
          ):
          (
             <>
          <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{mb:2}}>
                  <Grid >
                     Filtro:
                  </Grid>
                  <Grid >
                     <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                        <Select
                           labelId="demo-simple-select-standard-label"
                           id="demo-simple-select-standard"
                           value={filter}
                           onChange={handleChangeFilter}
                           label="Age"
                        >
                           <MenuItem value={0}>Asc</MenuItem>
                           <MenuItem value={1}>Desc</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid sx={{ml:2}}>
                     Estado:
                  </Grid>
                  <Grid >
                     <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                        <Select
                           labelId="demo-simple-select-standard-label"
                           id="demo-simple-select-standard"
                           value={filterEstado}
                           onChange={handleChangeFilterEstado}
                           label="Age"
                        >
                           <MenuItem value={0}>Generados/Abiertas</MenuItem>
                           <MenuItem value={1}>Adjudica C/VN</MenuItem>
                           <MenuItem value={2}>Anulada</MenuItem>
                           <MenuItem value={3}>Facturada</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid sx={{ ml: 2, mt: 2 }}>
                     <CSVLink data={productFiltrados} headers={headers} filename={`CotizacionProducto${codigoProductoSelect}.csv`} separator={";"}>
                        <FileDownloadIcon color='success' />
                     </CSVLink>
                  </Grid>
                  {/* <Grid item>
                        <Button variant='outlined' onClick={()=>{setProductTrello(productTrello)}}>VISTA TRELLO</Button>
                    </Grid> */}
               </Grid>
               <Grid item xs={12} >
            <TableData
               header={header}
               data={productFiltrados}
               action={dataAction}
               RecuperarData={setDataCotizacionProducto}
            />
          </Grid>
          </>
          )}
         </Grid>
         <ModalDetail
            open={openModalDetalle}
            setOpen={setOpenModalDetalle}
            getData={dataDetalleCotizacion}
         />
         </Container>
      </Protected>
   )
}
