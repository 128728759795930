
import { LeadsFormRepository } from "../repositories/LeadsForm.repository";

export const LeadsFormService= {
   VisualizarImagen,
   InsertSectionHeader,
   InsertSectionMain,
   InsertSectionUbicacion,
   InsertSectionForm,
   InsertSectionVideo,
   InsertSectionMain2,
   CreateViewForm,
   getFormTemplate,
   generateBrochureEmail,
   editUriBrochure,
   validateFormView,
   editImage,
   editText,
   listFormView,
   copyForm,
   HistoryViewLanding
};

async function VisualizarImagen (imagen) {
    const resp= await LeadsFormRepository.VisualizarImagen(imagen);
    return resp;
 }
 async function InsertSectionHeader(imagen) {
   const resp= await LeadsFormRepository.InsertSectionHeader(imagen);
   return resp;
}
 async function InsertSectionMain(imagen) {
   const resp= await LeadsFormRepository.InsertSectionMain(imagen);
   return resp;
}
 async function InsertSectionUbicacion (imagen) {
   const resp= await LeadsFormRepository.InsertSectionUbicacion(imagen);
   return resp;
}
 async function InsertSectionForm (imagen) {
   const resp= await LeadsFormRepository.InsertSectionForm(imagen);
   return resp;
}
async function InsertSectionVideo (imagen) {
   const resp= await LeadsFormRepository.InsertSectionVideo(imagen);
   return resp;
}
async function InsertSectionMain2 (imagen) {
   const resp= await LeadsFormRepository.InsertSectionMain2(imagen);
   return resp;
}

async function CreateViewForm (data) {
   const resp= await LeadsFormRepository.CreateViewForm(data);
   return resp;
}
async function getFormTemplate(id) {
   const resp= await LeadsFormRepository.getFormTemplate(id);
   return resp;
}
async function generateBrochureEmail(id, email, id_detallecampana, idcliente) {
   const resp = await LeadsFormRepository.generateBrochureEmail(id, email, id_detallecampana, idcliente);
   return resp;
}

async function editUriBrochure(id, data) {
   const resp= await LeadsFormRepository.editUriBrochure(id, data);
   return resp;
}

async function validateFormView(id) {
   const resp= await LeadsFormRepository.validateFormView(id);
   return resp;
}

async function editImage(id, data) {
   const resp= await LeadsFormRepository.editImage(id, data);
   return resp;
}

async function editText(id, data) {
   const resp= await LeadsFormRepository.editText(id, data);
   return resp;
}

async function listFormView() {
   const resp= await LeadsFormRepository.listFormView();
   return resp;
}

async function copyForm(id, data) {
   const resp= await LeadsFormRepository.copyForm(id, data);
   return resp;
}

async function HistoryViewLanding(data) {
   const resp= await LeadsFormRepository.HistoryViewLanding(data);
   return resp;
}


