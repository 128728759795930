import { chatRepository } from "../repositories/Chat.repository";

export const chatService= {
   getChats,
   getChatsVigents,
   getChatsByUsers,
   getDetailChats,
   sendMessage,
   sendMessageImage,
   createParticipantChat,
   closeChat
};

async function getChats() {
   const resp= await chatRepository.getChats();
   return resp;
}

async function getChatsVigents() {
   const resp= await chatRepository.getChatsVigents();
   return resp;
}

async function getChatsByUsers() {
   const resp= await chatRepository.getChatsByUsers();
   return resp;
}

async function getDetailChats(idChat) {
   const resp= await chatRepository.getDetailChats(idChat);
   return resp;
}

async function sendMessage(idChat, data) {
   const resp= await chatRepository.sendMessage(idChat, data);
   return resp;
}


async function sendMessageImage(idChat, data) {
   const resp= await chatRepository.sendMessageImage(idChat, data);
   return resp;
}

async function createParticipantChat(data) {
   const resp= await chatRepository.createParticipantChat( data);
   return resp;
}

async function closeChat(data) {
   const resp= await chatRepository.closeChat( data);
   return resp;
}

