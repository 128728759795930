import React, { useState, useMemo, useRef, useEffect, Suspense } from 'react';
import { Props } from './LeadsFormCampaing.type';
import { InputAdornment, IconButton, Step, StepLabel, Button, Card, CircularProgress, Grid, Container, useTheme, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, MenuItem, responsiveFontSizes, TextField, useMediaQuery, Autocomplete, Chip, Stepper, StepButton, Backdrop } from '@mui/material'
import { bypassService } from '@/service/services/Bypass.service';
import { LeadsFormService } from '@/service/services/LeadsForm.service';
import { saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BYPASS } from '@/toolbox/constants/local-storage';
import * as qs from 'qs'
import { campaingService } from '@/service/services/Campaing.service';
import { mailService } from '@/service/services/Mail.service';
import './index.css'
import { API_URL_BASE } from '@/toolbox/defaults/app';
import { SpinnerGrow } from '@/components/common/Spinner';
import { Nfound } from './Nfound';

interface CountryType {
   code: string;
   label: string;
   phone: string;
   suggested?: boolean | null;
 }

const steps = ['Header', 'Seccion', 'Ubicacion', 'Formulario', 'Video', 'Main', 'Footer'];
export const LeadsFormCampaingView: React.FC<Props> = (props: any): JSX.Element => {

   const [inputsLeads, setInputsLeads] = useState<any>();
   const [notCampaign, setNotCampaign] = useState<boolean>(false)
   const [loading, setLoading] = useState<boolean>(false)
   const [open, setOpen] = useState<boolean>(false)
   const [loadingbutton, setLoadingButton] = useState<boolean>(false)
   const [disabled, setDisabled] = useState<boolean>(false)
   const [formValues, setFormValues] = useState<any>([]);
   const [valueCorreo, setValueCorreo] = useState<any>()
   const [respMail, setRespMail] = useState<boolean>(false)
   const [dataLeadsInputs, setDataLeadsInput] = useState<any>([{}])
   const [renderM, setRenderM] = useState<boolean>(true)
   const [values, setValues] = useState<any>({
      idinputs: [],
      channel: '',
      idParameter: 0,
      name: 0
   })
   const [valueCountry, setValueCountry] = useState<CountryType>({
      code: '',
      label: '',
      phone: '',
      suggested: null
    })

   const { idParameter, name, channel, idinputs } = props.location && qs.parse(props.location.search.slice(1, -1));

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'primary',
      message: 'Error',
      autoHideDuration: 7000,
   })

   useEffect(() => {

      if (!!idParameter && !!channel && renderM) {
         getFormTemplate(name);
         var decodeChannel;
         var decodeIdInputs;
         var decodePassword;

         try {
            const password = 'hola'

            decodeChannel = atob(channel);
            if (idinputs) decodeIdInputs = idinputs;
            decodePassword = btoa(password);
            let decodeArray = [];
            if (decodeIdInputs) decodeArray = decodeIdInputs.split("-")

            let arrayInputs = []

            decodeArray.map((value) => {
               arrayInputs.push(atob(value))
            })

            console.log('arrayInputs', arrayInputs)

            setValues(prev => (
               {
                  ...prev,
                  idinputs: arrayInputs,
                  channel: decodeChannel,
                  idParameter: idParameter,
                  name: name
               }
            ))

            // authSubmit(decodeUser,decodeRutEmpresa,decodePassword);
         } catch (error) {
            console.log(error)
         }
      } else {
         //  if(session && session.token){
         //    props.history.push("/inicio");
         //  }else{

         //  }
      }
      loginBypass();
      getInputs();
   }, [props.location]);


   const loginBypass = async () => {
      const bypass = await bypassService.loginByPass('dsf', 'sdf', 'sfd');


      saveLocalStorage(KEY_BYPASS, bypass.token.original.access_token)

   }

   async function getInputs() {
      setLoading(true)
      const resp = await bypassService.getInputs()
      setDataLeadsInput(resp)
      setLoading(false)
   }

   const [seccionesHabilitadas, setSeccionesHabilitadas] = useState<any>({
      Header: 0,
      Main: 0,
      Ubicacion: 0,
      Formulario: 0,
      Footer: 0,
      Main2: 0,
      Hipervinculo: 0
   });

   const [ dataWS, setDataWs ] = useState({
      status: '0',
      idtemplateWs: 0,
      idempresa:0,
      phoneE: ''
   })

   const [fixedOptions, setFixedOptions] = useState<any>()
   const [seletInputs, setSelectInputs] = useState<any>([
      { id: 1, name: 'Nombre' }, { id: 2, name: 'Correo' }, { id: 3, name: 'Telefono' },
   ])
   const [sectionHeader, setSectionHeader] = useState<any>(
      {
         logo_header: {
            ruta: '',
            width: "",
            heigth: ""
         },
         img_header: {
            ruta: '',
            width: "",
            heigth: ""
         },
         textos: {
            titulo: {
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionSeccion, setSectionSeccion] = useState<any>(
      {
         img_main: {
            ruta: '',
            width: "",
            heigth: ""
         },
         textos: {
            titulo: {
               contenido: "",
               color: "",
               size: ""
            },
            descripcion: {
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionUbicacion, setSectionUbicacion] = useState<any>(
      {
         img_ubicacion: {
            ruta: '',
            width: "",
            heigth: ""
         },
         hipervinculo: {
            contenido: ''
         },
         textos: {
            titulo: {
               contenido: "",
               color: "",
               size: ""
            },
            descripcion: {
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionFormulario, setSectionFormulario] = useState<any>(
      {
         img_formulario: {
            ruta: '',
            width: "",
            heigth: ""
         },
         textos: {
            titulo: {
               contenido: "",
               color: "",
               size: ""
            },
            descripcion: {
               contenido: "",
               color: "",
               size: ""
            },
         },
         background: ''
      })
   const [sectionVideo, setSectionVideo] = useState<any>(
      {
         hipervinculo: {
            contenido: '',
            color: "",
            size: ""
         },
         background: ''
      })
   const [sectionMain, setSectionMain] = useState<any>(
      {
         img_main0: {
            ruta: '',
            width: "",
            heigth: ""
         },
         img_main1: {
            ruta: '',
            width: "",
            heigth: ""
         },
         img_main2: {
            ruta: '',
            width: "",
            heigth: ""
         },
         img_main3: {
            ruta: '',
            width: "",
            heigth: ""
         },
         background: ''
      })


   const handleForm = async (e) => {
      e.preventDefault();
      setLoadingButton(true)
      setDisabled(true)
      if(dataWS?.status === '0'){
         const arrayForm = Object.values(formValues)

      let ruta = ''

      arrayForm.map((value) => {
         ruta = ruta + "&" + value
      })
      console.log(ruta)
      const resp = await bypassService.postCrearForm(values.idParameter, values.channel, ruta)
      const insert = await campaingService.createDetailsCampaign(parseInt(values.idParameter), parseInt(values.name), resp.idForm)
      // console.log(insert.message)
      // console.log(insert)
      // if(insert.message=='Formulario ingresado con exito')
      // {
      setLoadingButton(false)
      // }
      const mail: any = await mailService.sendEmailLeads(parseInt(values.name), valueCorreo)
      if (mail?.data.message) {
         setRespMail(true)
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: mail?.data.message,
         }));

      }
      }else{
         // const arrayForm = Object.values(formValues)
         // let ruta = ''
         // arrayForm.map((value:any) => {
         //    const index = value.indexOf('=');
         //    const palabraTelefono = index !== -1 ? value.substring(0, index) : null;
         //    if(palabraTelefono == 'Telefono'){
         //       const match = value.match(/\d+/);
         //       const telefono = match ? match[0] : null;
         //       ruta = telefono
         //    }
         // })
         // const phone= valueCountry?.phone + '' + ruta;
         // const data = {
         //    idempresa: dataWS?.idempresa,
         //    idtemplate: dataWS?.idtemplateWs,
         //    phone: phone
         // }
         // const resp_template = await bypassService.SendTemplateLeads(data);
         // if(resp_template?.data?.code === 200){
         //    setSnackBarConfig(prev => ({
         //       ...prev,
         //       open: true,
         //       message: resp_template?.data?.data.message,
         //    }));
         // }else{
         //    setSnackBarConfig(prev => ({
         //       ...prev,
         //       open: true,
         //       severity: 'error',
         //       message: resp_template?.data?.data.message,
         //    }));
         // }
         console.log('dataWS?.phoneE',dataWS?.phoneE)
         var phoneNumber = dataWS?.phoneE ? dataWS?.phoneE :'56990416839'; // Reemplaza con el número de teléfono al que deseas enviar el mensaje
         var message = "Hola, quiero hacerte una pregunta"; // Puedes personalizar el mensaje

         // Formatea la URL de WhatsApp
         var whatsappUrl = "https://wa.me/" + phoneNumber + "?text=" + encodeURIComponent(message);

         // Abre la URL en una nueva ventana o pestaña
         window.open(whatsappUrl, "_blank");
        }
        setLoadingButton(false)

   }

   const handleFormValues = (e) => {
      const handleChangeFormValues = {
         ...formValues,
         [e.target.name]: e.target.name + '=' + e.target.value
      }

      if (e.target.name == 'Correo') {
         setValueCorreo(e.target.value)
      }

      setFormValues(handleChangeFormValues)

   }

   let arrayFiltrado = []


   values.idinputs.map((i) => {
      dataLeadsInputs.length > 1 && dataLeadsInputs.map((value) => {
         if (i == value.name) {
            arrayFiltrado.push(value)
         }
      })
   })

   const textFieldForm = (
      arrayFiltrado.map((value) => {
         return (
            <Grid item xs={12}>
               <TextField
                  size='small'
                  id={value.id}
                  required={value.name}
                  //   error={value.name==='RUT' && error=='rut'?true: false}
                  placeholder={`¿Cuál es tu ${value.name}?`}
                  // sx={{bgcolor:'#E3F4FD'}}
                  name={value.name}
                  //   value={nombre}
                  fullWidth
                  onChange={handleFormValues}
               />
            </Grid>
         )
      })
   )

   const isMobile2 = () => {
      return window.innerWidth <= 768; // Cambia este valor según tus necesidades
   }

   // useEffect(() => {
   //    getInputs();
   //    getFormTemplate(12);
   // }, [])

   // async function getInputs() {
   //    const resp = await bypassService.getInputs()
   //    setDataLeadsInput(resp)
   //    const fixed = resp.filter(data => data.name == 'nombre' || data.name == 'correo')
   //    setFixedOptions(fixed);
   //    setInputsLeads(fixed)
   // }
   //resp.secciones.Formularios.imagenes.img_formulario.ruta
   async function getFormTemplate(id) {
      setOpen(true);
      const resp: any = await LeadsFormService.getFormTemplate(id);
      if (resp?.code === 200) {
         const response = resp?.data?.detail;
         setDataWs({
            status: response?.status_Ws,
            idtemplateWs: response?.idtemplate,
            idempresa: response?.idempresa,
            phoneE: response?.phoneE
         })
         setSeccionesHabilitadas({
            Header: response?.idSecciones?.Header,
            Main: response?.idSecciones.Main,
            Ubicacion: response?.idSecciones.Ubicacion,
            Formulario: response?.idSecciones.Formulario,
            Footer: response?.idSecciones.Footer,
            Main2: response?.idSecciones.Main2,
            Hipervinculo: response?.idSecciones.Hipervinculo
         });
         let ImageUbicacion = null;
         let ImageHeader2 = null;
         let ImageFormulario = null;

         if (response?.idSecciones.Ubicacion == 1) {
            if (!(response?.secciones.Ubicacion.imagenes.length == 0)) {
               ImageUbicacion = response?.secciones.Ubicacion.imagenes.img_ubicacion.ruta.replace(/\s/g, "%20") || null;
            }
         }
         if (response?.idSecciones.Header == 1) {
            if ((response?.secciones.Header.imagenes.img_header)) {
               ImageHeader2 = response?.secciones.Header.imagenes.img_header.ruta.replace(/\s/g, "%20") || null;
            }
         }
         if (response?.idSecciones.Formulario == 1) {
            if ((response?.secciones.Formulario.imagenes.img_formulario)) {
               ImageFormulario = response?.secciones.Formulario.imagenes.img_formulario.ruta.replace(/\s/g, "%20") || null;
            }
         }

         setSectionHeader({
            // ruta: response?.idSecciones.Ubicacion==1&& ImageUbicacion,
            // width:response?.idSecciones.Ubicacion==1 && ImageUbicacion&& response?.secciones.Ubicacion.imagenes&&response?.secciones.Ubicacion.imagenes?.img_ubicacion.width||'',
            // heigth:response?.idSecciones.Ubicacion==1&& ImageUbicacion&& response?.secciones.Ubicacion.imagenes&&response?.secciones.Ubicacion.imagenes?.img_ubicacion.heigth||''
            logo_header: {
               ruta: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.ruta.replace(/\s/g, "%20"),
               width: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.width,
               heigth: response?.idSecciones.Header == 1 && response?.secciones.Header.imagenes.logo_header.heigth
            },
            img_header: {
               ruta: response?.idSecciones.Header == 1 && ImageHeader2,
               width: response?.idSecciones.Header == 1 && ImageHeader2 && response?.secciones.Header.imagenes && response?.secciones.Header.imagenes?.img_header.width || '',
               heigth: response?.idSecciones.Header == 1 && ImageHeader2 && response?.secciones.Header.imagenes && response?.secciones.Header.imagenes?.img_header.heigth || ''
            },
            textos: {
               titulo: {
                  contenido: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.contenido,
                  color: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.color,
                  size: response?.idSecciones.Header == 1 && response?.secciones.Header.textos.titulo.size,
               },
            },
            background: response?.idSecciones.Header == 1 && response?.secciones.Header.background
         })

         setSectionFormulario({
            // img_formulario: {
            //    ruta: response?.idSecciones.Formulario==1&& (response?.secciones.Formulario.imagenes.img_formulario.ruta).replace(/\s/g,"%20"),
            //    width:response?.idSecciones.Formulario==1&& response?.secciones.Formulario.imagenes.img_formulario.width,
            //    heigth:response?.idSecciones.Formulario==1&& response?.secciones.Formulario.imagenes.img_formulario.heigth
            // },
            img_formulario: {
               ruta: response?.idSecciones.Formulario == 1 && ImageFormulario,
               width: response?.idSecciones.Formulario == 1 && ImageFormulario && response?.secciones.Formulario.imagenes && response?.secciones.Formulario.imagenes.img_formulario.width,
               heigth: response?.idSecciones.Formulario == 1 && ImageFormulario && response?.secciones.Formulario.imagenes && response?.secciones.Formulario.imagenes.img_formulario.heigth
            },
            textos: {
               titulo: {
                  contenido: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.contenido,
                  color: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.color,
                  size: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.titulo.size,
               },
               descripcion: {
                  contenido: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.contenido,
                  color: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.color,
                  size: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.textos.descripcion.size,
               },
            },
            background: response?.idSecciones.Formulario == 1 && response?.secciones.Formulario.background
         })

         setSectionSeccion({
            img_main: {
               ruta: response?.idSecciones.Main == 1 && (response?.secciones.Main.imagenes.img_main.ruta).replace(/\s/g, "%20"),
               width: response?.idSecciones.Main == 1 && response?.secciones.Main.imagenes.img_main.width,
               heigth: response?.idSecciones.Main == 1 && response?.secciones.Main.imagenes.img_main.heigth
            },
            textos: {
               titulo: {
                  contenido: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.contenido,
                  color: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.color,
                  size: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.titulo.size,
               },
               descripcion: {
                  contenido: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.contenido,
                  color: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.color,
                  size: response?.idSecciones.Main == 1 && response?.secciones.Main.textos.descripcion.size,
               },
            },
            background: response?.idSecciones.Main == 1 && response?.secciones.Main.background
         })
         setSectionUbicacion({
            img_ubicacion: {
               ruta: response?.idSecciones.Ubicacion == 1 && ImageUbicacion,
               width: response?.idSecciones.Ubicacion == 1 && ImageUbicacion && response?.secciones.Ubicacion.imagenes && response?.secciones.Ubicacion.imagenes?.img_ubicacion.width || '',
               heigth: response?.idSecciones.Ubicacion == 1 && ImageUbicacion && response?.secciones.Ubicacion.imagenes && response?.secciones.Ubicacion.imagenes?.img_ubicacion.heigth || ''
            },
            hipervinculo: {
               contenido: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.hipervinculo && response?.secciones.Ubicacion.textos.hipervinculo.contenido
            },
            textos: {
               titulo: {
                  contenido: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.contenido || '',
                  color: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.color || '',
                  size: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.lenght > 0 && response?.secciones.Ubicacion.textos.titulo.size || '',
               },
               descripcion: {
                  contenido: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.contenido || '',
                  color: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.color || '',
                  size: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.textos.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.lenght > 0 && response?.secciones.Ubicacion.textos.descripcion.size || '',
               },
            },
            background: response?.idSecciones.Ubicacion == 1 && response?.secciones.Ubicacion.background
         })

         setSectionVideo({
            hipervinculo: {
               contenido: response?.idSecciones.Hipervinculo == 1 && response?.secciones.Hipervinculo.textos.hipervinculo.contenido,
               color: "",
               size: ""
            },
            background: response?.idSecciones.Hipervinculo == 1 && response?.secciones.Hipervinculo.background
         })
         setSectionMain(
            {
               img_main0: {
                  ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main0.ruta).replace(/\s/g, "%20"),
                  width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main0.width,
                  heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main0.heigth
               },
               img_main1: {
                  ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main1.ruta).replace(/\s/g, "%20"),
                  width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main1.width,
                  heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main1.heigth
               },
               img_main2: {
                  ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main2.ruta).replace(/\s/g, "%20"),
                  width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main2.width,
                  heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main2.heigth
               },
               img_main3: {
                  ruta: response?.idSecciones.Main2 == 1 && (response?.secciones.Main2.imagenes.img_main3.ruta).replace(/\s/g, "%20"),
                  width: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main3.width,
                  heigth: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.imagenes.img_main3.heigth
               },
               background: response?.idSecciones.Main2 == 1 && response?.secciones.Main2.background
            }
         )
         setTimeout(() => {
            setOpen(false);
         }, 2000);
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            severity: 'error',
            message: resp?.data?.message,
         }));
         setNotCampaign(true)
      }
   }

   const validateHeaderComponent = () => {
      console.log(`${API_URL_BASE}/${sectionHeader.logo_header.ruta}`);
      if (!sectionHeader.textos.titulo.contenido) {
         return `url(${API_URL_BASE}/${sectionHeader.logo_header.ruta})`;
      }
      else if (sectionHeader.textos.titulo.contenido) {
         return `linear-gradient(${sectionHeader.background}),url(${API_URL_BASE}/${sectionHeader.logo_header.ruta})`;
      }
   }

   const width = isMobile2() ? '300px' : '1120px';
   const height = isMobile2() ? '300px' : '600px';

   const width2 = isMobile2() ? '300px' : '1120px';
   const height2 = isMobile2() ? '300px' : '500px';

   const valideRequired = (value) => {
      if (dataWS?.status === '0' && value.name == 'Nombre') {
        return true;
      }
      if (dataWS?.status === '1' && value.name == 'Telefono') {
        return true;
      }
      return false;
    };


   return (

      <>
         {
            notCampaign ? <Nfound /> :
               <div id='contenido' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  {seccionesHabilitadas.Header == 1 &&
                     <table style={{ width: '100%' }}>
                        <tbody>
                           <tr>
                              <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                                 <div style={{ background: '#EFE6FF', margin: '0px auto' }}>
                                    <table role="presentation" style={{ background: '#ffffff', width: '100%' }}>
                                       <tbody>
                                          <tr>
                                             <td style={{ fontSize: '0px', wordBreak: 'break-word' }}>
                                                <img alt="" height="auto" src={`${API_URL_BASE}/${sectionHeader.logo_header.ruta}`}
                                                   style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }}
                                                   title="" />
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  }

                  {seccionesHabilitadas.Main == 1 &&
                     <Grid container style={{ width: '100%' }}>
                        <Grid item xs={12}>
                           <div style={{ background: sectionSeccion.background, margin: '0 auto', maxWidth: '100%' }}>
                              <Grid container>
                                 <Grid item container xs={12} md={6} alignItems={'center'}>
                                    <div className='letra' style={{ fontFamily: 'Open Sans, Helvetica, Arial, sans-serif', fontSize: '13px', lineHeight: '22px', textAlign: 'center', color: '#797e82', padding: '10px', whiteSpace: 'normal' }}>
                                       <Typography
                                          sx={{
                                             fontSize: theme.breakpoints.down('md') ? "2rem" : "2.5rem",
                                             textAlign: 'center',
                                             color: sectionSeccion.textos.titulo.color,
                                             fontWeight: "bold",
                                             marginBottom: '5px',
                                             padding: '10px',
                                             overflow: 'hidden',
                                             textOverflow: 'ellipsis',
                                             display: '-webkit-box',
                                             WebkitBoxOrient: 'vertical',
                                             WebkitLineClamp: 3
                                          }}
                                       >
                                          {sectionSeccion.textos.titulo.contenido}
                                       </Typography>
                                       <Typography
                                          sx={{
                                             fontSize: theme.breakpoints.down('md') ? "1.5rem" : "1.2rem",
                                             textAlign: 'center',
                                             color: sectionSeccion.textos.descripcion.color,
                                             fontWeight: "bold",
                                             marginBottom: '5px'
                                          }}
                                       >
                                          {sectionSeccion.textos.descripcion.contenido}
                                       </Typography>
                                    </div>
                                 </Grid>
                                 <Grid item xs={12} md={6}>
                                    <div className='text' style={{ textAlign: 'right' }}>
                                       <img alt="" height="auto" src={`${API_URL_BASE}/${sectionSeccion.img_main.ruta}`}
                                          style={{ border: 'none', display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%' }} title=""
                                       />
                                    </div>
                                 </Grid>
                              </Grid>
                           </div>
                        </Grid>
                     </Grid>
                  }

                  {/* {seccionesHabilitadas.Ubicacion == 1  &&
            <Grid container style={{ width: '100%' }}>
            <Grid item xs={12}>
               <div style={{ background: sectionUbicacion.background, margin: '0 auto', maxWidth: '100%' }}>
                  <Grid container>
                     <Grid item container xs={12} md={12} justifyContent={'center'} alignItems={'center'}>
                        <div className='letra' style={{ fontFamily: 'Open Sans, Helvetica, Arial, sans-serif', fontSize: '13px', lineHeight: '22px', textAlign: 'center', color: '#797e82', padding: '10px', whiteSpace: 'normal'}}>
                           <Typography
                              sx={{
                                 fontSize: theme.breakpoints.down('md') ? "2rem" : "2.5rem",
                                 textAlign: 'center',
                                 color: sectionUbicacion.textos.titulo.color,
                                 fontWeight: "bold",
                                 marginBottom: '5px',
                                 padding: '10px',
                                 overflow: 'hidden',
                                 textOverflow: 'ellipsis',
                                 display: '-webkit-box',
                                 WebkitBoxOrient: 'vertical',
                                 WebkitLineClamp: 3
                              }}
                           >
                              {sectionUbicacion.textos.titulo.contenido}sss
                           </Typography>
                           <Typography
                              sx={{
                                 fontSize: theme.breakpoints.down('md') ? "1.5rem" : "1.2rem",
                                 textAlign: 'center',
                                 color: sectionUbicacion.textos.descripcion.color,
                                 fontWeight: "bold",
                                 marginBottom: '5px'
                              }}
                           >
                              {sectionUbicacion.textos.descripcion.contenido}sss
                           </Typography>
                        </div>
                     </Grid>
                  </Grid>
               </div>
            </Grid>
         </Grid>
         } */}

                  {seccionesHabilitadas.Ubicacion == 1 && <table className="" style={{ width: '100%' }}>
                     <tbody>
                        <tr>
                           <td style={{ lineHeight: '0px', fontSize: '0px' }}>
                              <div style={{ background: sectionUbicacion.background, backgroundColor: sectionUbicacion.background, margin: '0px auto' }}>
                                 <table role="presentation" style={{ background: sectionUbicacion.background, backgroundColor: sectionUbicacion.background, width: '100%' }}>
                                    <tbody>
                                       <tr>
                                          <td style={{ direction: 'ltr', fontSize: '0px', padding: '20px 0', paddingBottom: '0px', paddingTop: '0px', textAlign: 'center', verticalAlign: 'top' }}>
                                             <div className="mj-column-per-100 outlook-group-fix" style={{ fontSize: '13px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%' }}>
                                                <table role="presentation" style={{ verticalAlign: 'top' }} width="100%">
                                                   <tr>
                                                      <td style={{ fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingRight: '50px', paddingBottom: '0px', paddingLeft: '50px', wordBreak: 'break-word' }}>
                                                         <table role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%' }}>
                                                            <tbody>
                                                               <tr>
                                                                  <td align='center'>
                                                                     {
                                                                        sectionUbicacion.hipervinculo.contenido ? (
                                                                           <iframe
                                                                              src={sectionUbicacion.hipervinculo.contenido}
                                                                              width="100%"
                                                                              height={height}
                                                                              style={{ border: "0" }}
                                                                              allowFullScreen
                                                                              loading="lazy"
                                                                           />
                                                                        ) : (
                                                                           <img
                                                                              alt=""
                                                                              height='auto'
                                                                              width='100%'
                                                                              src={`${API_URL_BASE}/${sectionUbicacion.img_ubicacion.ruta}`}
                                                                              style={{
                                                                                 border: 'none',
                                                                                 display: 'block',
                                                                                 outline: 'none',
                                                                                 textDecoration: 'none',
                                                                              }}
                                                                              title=""
                                                                           />
                                                                        )
                                                                     }
                                                                  </td>
                                                               </tr>
                                                            </tbody>
                                                         </table>
                                                      </td>
                                                   </tr>
                                                </table>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  }

                  {seccionesHabilitadas.Formulario == 1 &&
                     <Grid container justifyContent="center" sx={{ width: '100%', backgroundColor: sectionFormulario.background, height: '100vh' }}>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '30px', textAlign: 'center', borderRadius: '15px', backgroundColor: '#fff', flex: 1, marginTop: '20px', marginBottom: '20px' }}>
                           <p className='p_title_form' style={{ color: sectionFormulario.textos.titulo.color }}>
                              {sectionFormulario.textos.titulo.contenido}
                           </p>
                           <p className='p_descripcion_form' style={{ color: sectionFormulario.textos.descripcion.color }}>
                              {sectionFormulario.textos.descripcion.contenido}
                           </p>

                           <form id="Formulario" onSubmit={handleForm} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginTop: '20px', marginBottom: '20px' }}>
                              {arrayFiltrado.map((value: any, _) => {
                                 if (value?.name == 'Telefono') {
                                    return (
                                       <Grid container item xs={12} direction='row' justifyContent={'center'} alignItems={'center'} >
                                          <Grid item xs={12} md={6}>
                                             <Typography>Código</Typography>
                                             <Autocomplete
                                                size='small'
                                                id="country-select-demo"
                                                value={valueCountry}
                                                onChange={(_, newValue: any) => { setValueCountry(newValue) }}
                                                options={countriesNumber}
                                                autoHighlight
                                                getOptionLabel={(option) => (option.label && (option.label + ' +' + option?.phone)) || ''}
                                                renderOption={(props, option) => (
                                                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                      <img
                                                         loading="lazy"
                                                         width="20"
                                                         src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                                                         srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                                                         alt=""
                                                      />
                                                      {option.label} ({option.code}) +{option?.phone}
                                                   </Box>
                                                )}
                                                renderInput={(params) => (
                                                   <TextField
                                                      {...params}
                                                      label=""
                                                      required
                                                      inputProps={{
                                                         ...params.inputProps,
                                                         autoComplete: 'new-password',
                                                      }}
                                                   />
                                                )}
                                             />
                                          </Grid>
                                          <Grid item xs={12} md={6} >
                                             <Typography>N° telefono</Typography>
                                             <TextField
                                                key={value.id}
                                                size='small'
                                                id={value.id}
                                                // label={value.name == 'Nombre' ? '¿Cuál es tu nombre?' : value.name == 'Telefono' ? '¿Cuál es tu número de celular?' : value.name == 'Correo' ? '¿Cuál es tu correo electrónico?' : value.name}
                                                name={value.name}
                                                required={valideRequired(value)}
                                                onChange={handleFormValues}
                                                fullWidth
                                             />
                                          </Grid>
                                       </Grid>
                                    )
                                 } else {
                                    return (
                                       <Grid item xs={12} >
                                          <Typography>{value.name == 'Nombre' ? '¿Cuál es tu nombre?' : value.name == 'Telefono' ? '¿Cuál es tu número de celular?' : value.name == 'Correo' ? '¿Cuál es tu correo electrónico?' : value.name}</Typography>
                                          <TextField
                                             key={value.id}
                                             id={value.id}
                                             size='small'
                                             // label={value.name == 'Nombre' ? '¿Cuál es tu nombre?' : value.name == 'Telefono' ? '¿Cuál es tu número de celular?' : value.name == 'Correo' ? '¿Cuál es tu correo electrónico?' : value.name}
                                             name={value.name}
                                             required={valideRequired(value)}
                                             onChange={handleFormValues}
                                             variant="outlined"
                                             margin="normal"
                                             fullWidth
                                          />
                                       </Grid>

                                    )
                                 }
                              })}
                              <Button
                              type="submit"
                              disabled={disabled}
                              color='warning'
                              fullWidth
                              size="medium"
                              variant="contained"
                              // className='btn_naranja'
                              sx={{ textTransform: 'none',  marginTop: '20px',
                              borderRadius: '24px',
                              backgroundColor: disabled ? '#bcbcbc' : sectionFormulario.textos.titulo.color,
                              color: '#fff',
                              '&:hover': {
                                 backgroundColor: disabled ? '#bcbcbc' : sectionFormulario.textos.titulo.color,
                              },}}
                            >
                              {
                                loadingbutton ?
                                  <CircularProgress size={22} />
                                  :
                                  dataWS?.status === '1' ? 'CONTACTAR POR WHATSAPP' : respMail ? 'ENVIADO' : 'ENVIAR'
                                 }
                            </Button>
                              {loadingbutton && (
                                 <CircularProgress
                                    size={24}
                                    sx={{
                                       position: 'absolute',
                                       top: '50%',
                                       left: '50%',
                                       marginTop: '-12px',
                                       marginLeft: '-12px',
                                    }}
                                 />
                              )}
                           </form>
                           <Grid container xs={12} sx={{ mt: 2 }} justifyContent="center" alignItems="center">
                              <div>
                                 <img src={`${API_URL_BASE}/${sectionFormulario?.img_formulario?.ruta}`} alt='' style={{ maxWidth: '300px', height: 'auto' }} />
                              </div>
                           </Grid>
                        </Grid>
                     </Grid>

                  }

                  {seccionesHabilitadas.Hipervinculo == 1 &&
                     <Grid container justifyContent="center" sx={{ width: '100%', backgroundColor: sectionVideo?.background, margin: '0px auto' }}>
                        <Grid item xs={12} md={10} lg={8} sx={{ padding: '20px', textAlign: 'center' }}>
                           <Box id='video' sx={{ bgcolor: '#000', position: 'relative', paddingTop: '56.25%' /* Aspect ratio 16:9 */ }}>
                              <iframe
                                 width="100%"
                                 height="100%"
                                 src={sectionVideo?.hipervinculo?.contenido}
                                 title="YouTube video player"
                                 style={{ position: 'absolute', top: 0, left: 0 }}
                              ></iframe>
                           </Box>
                        </Grid>
                     </Grid>}


                  {seccionesHabilitadas.Main2 == 1 &&
                     <Grid container justifyContent="center" sx={{ width: '100%', backgroundColor: sectionMain.background, margin: '0px auto' }}>
                        <Grid item xs={12} md={10} lg={8} sx={{ padding: '20px', textAlign: 'center' }}>
                           <Box id='main' sx={{ bgcolor: sectionMain.background }}>
                              <Grid container spacing={2} justifyContent="center" alignItems="center">
                                 <Grid item container xs={12} md={6} justifyContent="center" alignItems="center">
                                    <div>
                                       <img src={`${API_URL_BASE}/${sectionMain.img_main0.ruta}`} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
                                    </div>
                                 </Grid>
                                 <Grid item container xs={12} md={6} justifyContent="center" alignItems="center">
                                    <div>
                                       <img src={`${API_URL_BASE}/${sectionMain.img_main1.ruta}`} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
                                    </div>
                                 </Grid>
                                 <Grid item container xs={12} md={6} justifyContent="center" alignItems="center">
                                    <div style={{ paddingBottom: '20px' }}>
                                       <img src={`${API_URL_BASE}/${sectionMain.img_main2.ruta}`} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
                                    </div>
                                 </Grid>
                                 <Grid item container xs={12} md={6} justifyContent="center" alignItems="center">
                                    <div style={{ paddingBottom: '20px' }}>
                                       <img src={`${API_URL_BASE}/${sectionMain.img_main3.ruta}`} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
                                    </div>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                     </Grid>}


               </div>
         }
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      </>
   )
};


const countriesNumber: CountryType[] = [
   { code: 'AD', label: 'Andorra', phone: '376' },
   {
     code: 'AE',
     label: 'United Arab Emirates',
     phone: '971',
   },
   { code: 'AF', label: 'Afghanistan', phone: '93' },
   {
     code: 'AG',
     label: 'Antigua and Barbuda',
     phone: '1-268',
   },
   { code: 'AI', label: 'Anguilla', phone: '1-264' },
   { code: 'AL', label: 'Albania', phone: '355' },
   { code: 'AM', label: 'Armenia', phone: '374' },
   { code: 'AO', label: 'Angola', phone: '244' },
   { code: 'AQ', label: 'Antarctica', phone: '672' },
   { code: 'AR', label: 'Argentina', phone: '54' },
   { code: 'AS', label: 'American Samoa', phone: '1-684' },
   { code: 'AT', label: 'Austria', phone: '43' },
   {
     code: 'AU',
     label: 'Australia',
     phone: '61',
     suggested: true,
   },
   { code: 'AW', label: 'Aruba', phone: '297' },
   { code: 'AX', label: 'Alland Islands', phone: '358' },
   { code: 'AZ', label: 'Azerbaijan', phone: '994' },
   {
     code: 'BA',
     label: 'Bosnia and Herzegovina',
     phone: '387',
   },
   { code: 'BB', label: 'Barbados', phone: '1-246' },
   { code: 'BD', label: 'Bangladesh', phone: '880' },
   { code: 'BE', label: 'Belgium', phone: '32' },
   { code: 'BF', label: 'Burkina Faso', phone: '226' },
   { code: 'BG', label: 'Bulgaria', phone: '359' },
   { code: 'BH', label: 'Bahrain', phone: '973' },
   { code: 'BI', label: 'Burundi', phone: '257' },
   { code: 'BJ', label: 'Benin', phone: '229' },
   { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
   { code: 'BM', label: 'Bermuda', phone: '1-441' },
   { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
   { code: 'BO', label: 'Bolivia', phone: '591' },
   { code: 'BR', label: 'Brazil', phone: '55' },
   { code: 'BS', label: 'Bahamas', phone: '1-242' },
   { code: 'BT', label: 'Bhutan', phone: '975' },
   { code: 'BV', label: 'Bouvet Island', phone: '47' },
   { code: 'BW', label: 'Botswana', phone: '267' },
   { code: 'BY', label: 'Belarus', phone: '375' },
   { code: 'BZ', label: 'Belize', phone: '501' },
   {
     code: 'CA',
     label: 'Canada',
     phone: '1',
     suggested: true,
   },
   {
     code: 'CC',
     label: 'Cocos (Keeling) Islands',
     phone: '61',
   },
   {
     code: 'CD',
     label: 'Congo, Democratic Republic of the',
     phone: '243',
   },
   {
     code: 'CF',
     label: 'Central African Republic',
     phone: '236',
   },
   {
     code: 'CG',
     label: 'Congo, Republic of the',
     phone: '242',
   },
   { code: 'CH', label: 'Switzerland', phone: '41' },
   { code: 'CI', label: 'Cote d\'Ivoire', phone: '225' },
   { code: 'CK', label: 'Cook Islands', phone: '682' },
   { code: 'CL', label: 'Chile', phone: '56' },
   { code: 'CM', label: 'Cameroon', phone: '237' },
   { code: 'CN', label: 'China', phone: '86' },
   { code: 'CO', label: 'Colombia', phone: '57' },
   { code: 'CR', label: 'Costa Rica', phone: '506' },
   { code: 'CU', label: 'Cuba', phone: '53' },
   { code: 'CV', label: 'Cape Verde', phone: '238' },
   { code: 'CW', label: 'Curacao', phone: '599' },
   { code: 'CX', label: 'Christmas Island', phone: '61' },
   { code: 'CY', label: 'Cyprus', phone: '357' },
   { code: 'CZ', label: 'Czech Republic', phone: '420' },
   {
     code: 'DE',
     label: 'Germany',
     phone: '49',
     suggested: true,
   },
   { code: 'DJ', label: 'Djibouti', phone: '253' },
   { code: 'DK', label: 'Denmark', phone: '45' },
   { code: 'DM', label: 'Dominica', phone: '1-767' },
   {
     code: 'DO',
     label: 'Dominican Republic',
     phone: '1-809',
   },
   { code: 'DZ', label: 'Algeria', phone: '213' },
   { code: 'EC', label: 'Ecuador', phone: '593' },
   { code: 'EE', label: 'Estonia', phone: '372' },
   { code: 'EG', label: 'Egypt', phone: '20' },
   { code: 'EH', label: 'Western Sahara', phone: '212' },
   { code: 'ER', label: 'Eritrea', phone: '291' },
   { code: 'ES', label: 'Spain', phone: '34' },
   { code: 'ET', label: 'Ethiopia', phone: '251' },
   { code: 'FI', label: 'Finland', phone: '358' },
   { code: 'FJ', label: 'Fiji', phone: '679' },
   {
     code: 'FK',
     label: 'Falkland Islands (Malvinas)',
     phone: '500',
   },
   {
     code: 'FM',
     label: 'Micronesia, Federated States of',
     phone: '691',
   },
   { code: 'FO', label: 'Faroe Islands', phone: '298' },
   {
     code: 'FR',
     label: 'France',
     phone: '33',
     suggested: true,
   },
   { code: 'GA', label: 'Gabon', phone: '241' },
   { code: 'GB', label: 'United Kingdom', phone: '44' },
   { code: 'GD', label: 'Grenada', phone: '1-473' },
   { code: 'GE', label: 'Georgia', phone: '995' },
   { code: 'GF', label: 'French Guiana', phone: '594' },
   { code: 'GG', label: 'Guernsey', phone: '44' },
   { code: 'GH', label: 'Ghana', phone: '233' },
   { code: 'GI', label: 'Gibraltar', phone: '350' },
   { code: 'GL', label: 'Greenland', phone: '299' },
   { code: 'GM', label: 'Gambia', phone: '220' },
   { code: 'GN', label: 'Guinea', phone: '224' },
   { code: 'GP', label: 'Guadeloupe', phone: '590' },
   { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
   { code: 'GR', label: 'Greece', phone: '30' },
   {
     code: 'GS',
     label: 'South Georgia and the South Sandwich Islands',
     phone: '500',
   },
   { code: 'GT', label: 'Guatemala', phone: '502' },
   { code: 'GU', label: 'Guam', phone: '1-671' },
   { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
   { code: 'GY', label: 'Guyana', phone: '592' },
   { code: 'HK', label: 'Hong Kong', phone: '852' },
   {
     code: 'HM',
     label: 'Heard Island and McDonald Islands',
     phone: '672',
   },
   { code: 'HN', label: 'Honduras', phone: '504' },
   { code: 'HR', label: 'Croatia', phone: '385' },
   { code: 'HT', label: 'Haiti', phone: '509' },
   { code: 'HU', label: 'Hungary', phone: '36' },
   { code: 'ID', label: 'Indonesia', phone: '62' },
   { code: 'IE', label: 'Ireland', phone: '353' },
   { code: 'IL', label: 'Israel', phone: '972' },
   { code: 'IM', label: 'Isle of Man', phone: '44' },
   { code: 'IN', label: 'India', phone: '91' },
   {
     code: 'IO',
     label: 'British Indian Ocean Territory',
     phone: '246',
   },
   { code: 'IQ', label: 'Iraq', phone: '964' },
   {
     code: 'IR',
     label: 'Iran, Islamic Republic of',
     phone: '98',
   },
   { code: 'IS', label: 'Iceland', phone: '354' },
   { code: 'IT', label: 'Italy', phone: '39' },
   { code: 'JE', label: 'Jersey', phone: '44' },
   { code: 'JM', label: 'Jamaica', phone: '1-876' },
   { code: 'JO', label: 'Jordan', phone: '962' },
   {
     code: 'JP',
     label: 'Japan',
     phone: '81',
     suggested: true,
   },
   { code: 'KE', label: 'Kenya', phone: '254' },
   { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
   { code: 'KH', label: 'Cambodia', phone: '855' },
   { code: 'KI', label: 'Kiribati', phone: '686' },
   { code: 'KM', label: 'Comoros', phone: '269' },
   {
     code: 'KN',
     label: 'Saint Kitts and Nevis',
     phone: '1-869',
   },
   {
     code: 'KP',
     label: 'Korea, Democratic People\'s Republic of',
     phone: '850',
   },
   { code: 'KR', label: 'Korea, Republic of', phone: '82' },
   { code: 'KW', label: 'Kuwait', phone: '965' },
   { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
   { code: 'KZ', label: 'Kazakhstan', phone: '7' },
   {
     code: 'LA',
     label: 'Lao People\'s Democratic Republic',
     phone: '856',
   },
   { code: 'LB', label: 'Lebanon', phone: '961' },
   { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
   { code: 'LI', label: 'Liechtenstein', phone: '423' },
   { code: 'LK', label: 'Sri Lanka', phone: '94' },
   { code: 'LR', label: 'Liberia', phone: '231' },
   { code: 'LS', label: 'Lesotho', phone: '266' },
   { code: 'LT', label: 'Lithuania', phone: '370' },
   { code: 'LU', label: 'Luxembourg', phone: '352' },
   { code: 'LV', label: 'Latvia', phone: '371' },
   { code: 'LY', label: 'Libya', phone: '218' },
   { code: 'MA', label: 'Morocco', phone: '212' },
   { code: 'MC', label: 'Monaco', phone: '377' },
   {
     code: 'MD',
     label: 'Moldova, Republic of',
     phone: '373',
   },
   { code: 'ME', label: 'Montenegro', phone: '382' },
   {
     code: 'MF',
     label: 'Saint Martin (French part)',
     phone: '590',
   },
   { code: 'MG', label: 'Madagascar', phone: '261' },
   { code: 'MH', label: 'Marshall Islands', phone: '692' },
   {
     code: 'MK',
     label: 'Macedonia, the Former Yugoslav Republic of',
     phone: '389',
   },
   { code: 'ML', label: 'Mali', phone: '223' },
   { code: 'MM', label: 'Myanmar', phone: '95' },
   { code: 'MN', label: 'Mongolia', phone: '976' },
   { code: 'MO', label: 'Macao', phone: '853' },
   {
     code: 'MP',
     label: 'Northern Mariana Islands',
     phone: '1-670',
   },
   { code: 'MQ', label: 'Martinique', phone: '596' },
   { code: 'MR', label: 'Mauritania', phone: '222' },
   { code: 'MS', label: 'Montserrat', phone: '1-664' },
   { code: 'MT', label: 'Malta', phone: '356' },
   { code: 'MU', label: 'Mauritius', phone: '230' },
   { code: 'MV', label: 'Maldives', phone: '960' },
   { code: 'MW', label: 'Malawi', phone: '265' },
   { code: 'MX', label: 'Mexico', phone: '52' },
   { code: 'MY', label: 'Malaysia', phone: '60' },
   { code: 'MZ', label: 'Mozambique', phone: '258' },
   { code: 'NA', label: 'Namibia', phone: '264' },
   { code: 'NC', label: 'New Caledonia', phone: '687' },
   { code: 'NE', label: 'Niger', phone: '227' },
   { code: 'NF', label: 'Norfolk Island', phone: '672' },
   { code: 'NG', label: 'Nigeria', phone: '234' },
   { code: 'NI', label: 'Nicaragua', phone: '505' },
   { code: 'NL', label: 'Netherlands', phone: '31' },
   { code: 'NO', label: 'Norway', phone: '47' },
   { code: 'NP', label: 'Nepal', phone: '977' },
   { code: 'NR', label: 'Nauru', phone: '674' },
   { code: 'NU', label: 'Niue', phone: '683' },
   { code: 'NZ', label: 'New Zealand', phone: '64' },
   { code: 'OM', label: 'Oman', phone: '968' },
   { code: 'PA', label: 'Panama', phone: '507' },
   { code: 'PE', label: 'Peru', phone: '51' },
   { code: 'PF', label: 'French Polynesia', phone: '689' },
   { code: 'PG', label: 'Papua New Guinea', phone: '675' },
   { code: 'PH', label: 'Philippines', phone: '63' },
   { code: 'PK', label: 'Pakistan', phone: '92' },
   { code: 'PL', label: 'Poland', phone: '48' },
   {
     code: 'PM',
     label: 'Saint Pierre and Miquelon',
     phone: '508',
   },
   { code: 'PN', label: 'Pitcairn', phone: '870' },
   { code: 'PR', label: 'Puerto Rico', phone: '1' },
   {
     code: 'PS',
     label: 'Palestine, State of',
     phone: '970',
   },
   { code: 'PT', label: 'Portugal', phone: '351' },
   { code: 'PW', label: 'Palau', phone: '680' },
   { code: 'PY', label: 'Paraguay', phone: '595' },
   { code: 'QA', label: 'Qatar', phone: '974' },
   { code: 'RE', label: 'Reunion', phone: '262' },
   { code: 'RO', label: 'Romania', phone: '40' },
   { code: 'RS', label: 'Serbia', phone: '381' },
   { code: 'RU', label: 'Russian Federation', phone: '7' },
   { code: 'RW', label: 'Rwanda', phone: '250' },
   { code: 'SA', label: 'Saudi Arabia', phone: '966' },
   { code: 'SB', label: 'Solomon Islands', phone: '677' },
   { code: 'SC', label: 'Seychelles', phone: '248' },
   { code: 'SD', label: 'Sudan', phone: '249' },
   { code: 'SE', label: 'Sweden', phone: '46' },
   { code: 'SG', label: 'Singapore', phone: '65' },
   { code: 'SH', label: 'Saint Helena', phone: '290' },
   { code: 'SI', label: 'Slovenia', phone: '386' },
   {
     code: 'SJ',
     label: 'Svalbard and Jan Mayen',
     phone: '47',
   },
   { code: 'SK', label: 'Slovakia', phone: '421' },
   { code: 'SL', label: 'Sierra Leone', phone: '232' },
   { code: 'SM', label: 'San Marino', phone: '378' },
   { code: 'SN', label: 'Senegal', phone: '221' },
   { code: 'SO', label: 'Somalia', phone: '252' },
   { code: 'SR', label: 'Suriname', phone: '597' },
   { code: 'SS', label: 'South Sudan', phone: '211' },
   {
     code: 'ST',
     label: 'Sao Tome and Principe',
     phone: '239',
   },
   { code: 'SV', label: 'El Salvador', phone: '503' },
   {
     code: 'SX',
     label: 'Sint Maarten (Dutch part)',
     phone: '1-721',
   },
   {
     code: 'SY',
     label: 'Syrian Arab Republic',
     phone: '963',
   },
   { code: 'SZ', label: 'Swaziland', phone: '268' },
   {
     code: 'TC',
     label: 'Turks and Caicos Islands',
     phone: '1-649',
   },
   { code: 'TD', label: 'Chad', phone: '235' },
   {
     code: 'TF',
     label: 'French Southern Territories',
     phone: '262',
   },
   { code: 'TG', label: 'Togo', phone: '228' },
   { code: 'TH', label: 'Thailand', phone: '66' },
   { code: 'TJ', label: 'Tajikistan', phone: '992' },
   { code: 'TK', label: 'Tokelau', phone: '690' },
   { code: 'TL', label: 'Timor-Leste', phone: '670' },
   { code: 'TM', label: 'Turkmenistan', phone: '993' },
   { code: 'TN', label: 'Tunisia', phone: '216' },
   { code: 'TO', label: 'Tonga', phone: '676' },
   { code: 'TR', label: 'Turkey', phone: '90' },
   {
     code: 'TT',
     label: 'Trinidad and Tobago',
     phone: '1-868',
   },
   { code: 'TV', label: 'Tuvalu', phone: '688' },
   {
     code: 'TW',
     label: 'Taiwan, Republic of China',
     phone: '886',
   },
   {
     code: 'TZ',
     label: 'United Republic of Tanzania',
     phone: '255',
   },
   { code: 'UA', label: 'Ukraine', phone: '380' },
   { code: 'UG', label: 'Uganda', phone: '256' },
   {
     code: 'US',
     label: 'United States',
     phone: '1',
     suggested: true,
   },
   { code: 'UY', label: 'Uruguay', phone: '598' },
   { code: 'UZ', label: 'Uzbekistan', phone: '998' },
   {
     code: 'VA',
     label: 'Holy See (Vatican City State)',
     phone: '379',
   },
   {
     code: 'VC',
     label: 'Saint Vincent and the Grenadines',
     phone: '1-784',
   },
   { code: 'VE', label: 'Venezuela', phone: '58' },
   {
     code: 'VG',
     label: 'British Virgin Islands',
     phone: '1-284',
   },
   {
     code: 'VI',
     label: 'US Virgin Islands',
     phone: '1-340',
   },
   { code: 'VN', label: 'Vietnam', phone: '84' },
   { code: 'VU', label: 'Vanuatu', phone: '678' },
   { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
   { code: 'WS', label: 'Samoa', phone: '685' },
   { code: 'XK', label: 'Kosovo', phone: '383' },
   { code: 'YE', label: 'Yemen', phone: '967' },
   { code: 'YT', label: 'Mayotte', phone: '262' },
   { code: 'ZA', label: 'South Africa', phone: '27' },
   { code: 'ZM', label: 'Zambia', phone: '260' },
   { code: 'ZW', label: 'Zimbabwe', phone: '263' },
 ]
