import React, { useState,useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, Autocomplete, Dialog, Container } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, ThemeProvider, Button } from '@mui/material';
import { accionService } from '@/service/services/Accion.service';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import './Modal.sass'
import { ConfirmDialog } from '../DialogConfirm';
import { indigo } from '@mui/material/colors';
import { TableData } from '../Table';
import { TabsComponent } from '../Tabs';

// const header = [
//    { name: 'action', label: 'Accion', width: '' },
//    { name: 'hito', label: 'Hito', width: '' },
//    { name: 'progreso', label: 'Progreso', width: '' },
//    { name: 'f_reporte', label: 'Fecha de Reporte', width: '' },
//    { name: 'comentario', label: 'Comentario', width: '' }
//    ];
const header = [
   // { name: 'action', label: 'Accion', width: '' },
   // { name: 'hito', label: 'Hito', width: '' },
   { name: 'progreso', label: 'Progreso', width: '100px' },
   { name: 'f_reporte', label: 'Fecha de Reporte', width: '200px' },
   { name: 'comentario', label: 'Comentario', width: '200px' },
   ];

   const header1 = [
      { name: 'nombre', label: 'Trabajador', width: '100px' },
      { name: 'area_negocio', label: 'Area', width: '100px' },
      // { name: 'email', label: 'Email', width: '200px' },
      // { name: 'telefono', label: 'Telefono', width: '200px' },
      ];
   const labels = [{ label: 'Detalle Accion' }, { label: 'Trabajadores Asingados' }]

type ModalProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    action:any,
}

export const ModalProyectionDetails: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {

   const [open, setOpen] = useState(false)
   const [detalle, setDetalle] = useState<any>([]);
   const [valueForm, setValueForm] = useState<number>(0)

   // useEffect(() => {
   //    setDetalle(props.action.progreso)
   // }, [props.action])
console.log(props.action?.progreso[0].trabajadores)
    const bodyModal = (
        <>
        {/* <Grid mb={2} >
            <Typography variant='h5'>Más información de Proyecciones</Typography>
        </Grid>

        <TableData
         header={header}
         data={detalle}
         action={null}
        /> */}
        <Grid item xs={12} mt={2}>
               <TabsComponent
                  labels={labels}
                  setValueForm={setValueForm}
                  childrenOne={
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>

                        <Grid container >
                           <TableData
                              header={header}
                              data={props.action?.progreso}
                              action={null} />
                        </Grid>
                     </Container>
                  }
                  childrenTwo={
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container >
                           <TableData
                              header={header1}
                              data={props.action?.progreso[0].trabajadores}
                              action={null}/>
                        </Grid>
                     </Container>
                  }
               />
               </Grid>
        </>
    )
    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'auto',
                    bgcolor: 'background.paper', border: '19px solid primary', boxShadow: 24, p: 3,maxHeight:600
                }}>

                    {bodyModal}
                </Box>

            </Modal>
        </div>
    );
}
