import { configurationRepository } from "../repositories/Configuration.repository";

// export const productService = {
//    getProduct: () => {
//       return productRepository.getProduct()
//    },
// };


export const configurationService= {
   getConfiguration,
   updateConfiguration,
   getConfigCampana,
   updateStatusConfigCampana,
   updateConfigCampana,
   changeStatusCampaingGenerales,
   ListStatusCampaingGenerales,
   asignacionSupervisor,
   changeAsignacion,
   statusAsignacion,
   getSettingWsEntity,
   updateSettingWsEntity
};

async function getConfiguration () {
   const configuration= await configurationRepository.getConfiguration();

   return configuration
}
async function getConfigCampana () {
   const ConfigCampana= await configurationRepository.getConfigCampana();
   return ConfigCampana
}

async function updateConfigCampana (id, data) {
   const ConfigCampana= await configurationRepository.updateConfigCampana(id,data);
   return ConfigCampana
}

async function updateStatusConfigCampana (id, status) {
   const estado = await configurationRepository.changeStatusConfigCampana(id,status);
   return estado
}

async function updateConfiguration (id, data) {
   const configuration= await configurationRepository.updateConfiguration(id,data);

   return configuration
}

async function changeStatusCampaingGenerales (data) {
   const resp= await configurationRepository.changeStatusCampaingGenerales(data);
   return resp;
}

async function ListStatusCampaingGenerales() {
   const resp= await configurationRepository.ListStatusCampaingGenerales();
   return resp;
}

async function asignacionSupervisor(dataSupervisor) {
   const resp= await configurationRepository.asignacionSupervisor(dataSupervisor);
   return resp;
}

async function changeAsignacion(asignacion) {
   const resp= await configurationRepository.changeAsignacion(asignacion);
   return resp;
}

async function statusAsignacion() {
   const resp= await configurationRepository.statusAsignacion();
   return resp;
}

async function getSettingWsEntity() {
   const resp= await configurationRepository.getSettingWsEntity();
   return resp;
}

async function updateSettingWsEntity(data) {
   const resp= await configurationRepository.updateSettingWsEntity(data);
   return resp;
}
