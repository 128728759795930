import React, { useState, useEffect, useRef} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Backdrop, Divider, Autocomplete, Container, InputLabel, Select, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Paper, Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, createTheme, IconButton, Button, Snackbar, Alert } from '@mui/material';
import './Modal.sass'
import { ModalProspectoRapido } from './ModalProspectoRapido';
import { propsToClassKey } from '@mui/styles';
import { mailService } from '@/service/services/Mail.service';
import { ModalCampaignCorreo } from './ModalCampaignCorreo';
import { SpinnerGrow } from '../Spinner';
import LanguageIcon from '@mui/icons-material/Language';

type ModalProps = {
    open: boolean,
    setOpen: any,
    data:any,
    tipo?:any,
    dataCampana?:any
}


export const ModalCuponEdit: React.FC<ModalProps> = (
    props: ModalProps
): JSX.Element => {

    console.log(props)
     const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })

   const [openVinculo, setOpenVinculo] = useState<boolean>(false);
     const [open, setOpen] = useState<boolean>(false);

    const [statusEdit, setStatusEdit] = useState<boolean>(false);
    const dataInitial = {
        titulo: 'CUPÓN DE DESCUENTO',
        img_principal:'https://jtihfi.stripocdn.email/content/guids/CABINET_37653718160e384c1b6ac6f5fb44958e/images/86381617879516269.png',
        frase_principal:'Nuestras Mejores Ofertas!',
        descuento:25,
        descripcion: 'Porque te creemos parte de nosotros,y agradecemos tu confianza en la marca te ofrecemos este 25 % descuento valido en nuestros establecimientos y a traves de nuestras plataformas de contacto.',
        codigo_promocional:'FGH-123-VBN',
        btn_principal :'IR A COMPRAR',
        url_principal:''
    }

    const [dataLanding, setDataLanding] = useState<any>(dataInitial)
    const [openValidation,setOpenValidation] = useState<boolean>(false);
    const getUltimaPlantillaCampaña = async(idcampana, tipo) => {
        const resp:any = await mailService.getUltimaPlantillaCampaña(idcampana, tipo);
        return resp;
    }

    const VerifyDetailCampana = async(iddetallecampana, tipo) => {
        const resp:any = await mailService.getVerifyPlantillaDetalleCampana(iddetallecampana, tipo)
        return resp;
    }

    const getPlantilla = async( functionUltimaCampana, functionVerifyDetailCampana, funcOpen ) =>{
       
        setOpen(true)
        const respVerifyCampana = await functionVerifyDetailCampana(props.data.iddetallecampaña, 'cupon');
        console.log(respVerifyCampana);
        if(respVerifyCampana.data){
            setDataLanding({...respVerifyCampana.data, codigo_promocional:''})
           setOpenValidation(true)
        } else{
            const respUltimaCampana = await functionUltimaCampana(props.data.idcampaña, 'cupon');
            console.log(respUltimaCampana)
            if(respUltimaCampana.data){
                setDataLanding(respUltimaCampana.data);
            }else{
                setDataLanding(dataInitial);
            }
        }  
        funcOpen(false)      
    }

    useEffect(()=>{
        if(props.tipo == 'hilo_correo'){
            setDataLanding(props.data);
         
        }else{
            getPlantilla(getUltimaPlantillaCampaña, VerifyDetailCampana, setOpen);
        }
    },[])

    // const EnviarCuponMasivo = async() => {
    //     const data = new FormData();
    //     data.append('titulo', dataLanding.titulo);
    //     data.append('img_principal', dataLanding.img_principal);
    //     data.append('frase_principal', dataLanding.frase_principal);
    //     data.append('btn_principal', dataLanding.btn_principal);
    //     data.append('descuento','');
    //     data.append('descripcion',dataLanding.descripcion);
    //     data.append('codigo_promocional', dataLanding.codigo_promocional);
    //     data.append('fecha_validez','');
    //     data.append('idcampana', props.dataCampana.id);
    //     data.append('tipo','cupon');
    //     data.append('mail', 'lf.ramirezmendoza@gmail.com');
        
    //     console.log(data);
    //     const resp:any = await mailService.sendLandingMailMasivo(data);

    // }

    const EnviarCupon = async(tipo) => {
        setDataLanding({...dataLanding, mail:'lf.ramirezmendoza@gmail.com'})
        
        if(!dataLanding.titulo){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un título para generar el envío'})
        }
        if(!dataLanding.frase_principal){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un frase para generar el envío'})
        }
        if(!dataLanding.btn_principal){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un botón para generar el envío'})
        }
        if(!dataLanding.url_principal){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar una URL de redirección para el botón para generar el envío'})
        }
        if(!dataLanding.descuento){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar una porcentaje de descuento para generar el envío'})
        }
        if(!dataLanding.codigo_promocional){
            return setSnackBarConfig({...snackBarConfig, open:true, severity:'warning' , message:'Es necesario ingresar un código promocional para generar el envío'})
        }

        
        const dataCupon = new FormData();
        dataCupon.append('titulo', dataLanding.titulo);
        dataCupon.append('img_principal', dataLanding.img_principal);
        dataCupon.append('frase_principal', dataLanding.frase_principal);
        dataCupon.append('url_principal', dataLanding.url_principal);
        dataCupon.append('btn_principal', dataLanding.btn_principal);
        dataCupon.append('descuento',dataLanding.descuento);
        dataCupon.append('descripcion',dataLanding.descripcion);
        dataCupon.append('codigo_promocional', dataLanding.codigo_promocional);
        dataCupon.append('fecha_validez','dhdhds');
        dataCupon.append('mail', 'lf.ramirezmendoza@gmail.com');
        dataCupon.append('idcliente', props.data.idcliente );
        dataCupon.append('iddetallecampananegocioclientes', props.data.iddetallecampaña);
        dataCupon.append('tipo','cupon');
        dataCupon.append('idcampana', props.dataCampana.id);

        setOpen(true)
        if(tipo == 'individual'){
            const resp:any = await mailService.sendLandingMail(dataCupon);

            if(resp?.data){
                setOpen(false)
                setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'El cupon promocional ha sido enviado con éxito'})
            }else{
                setOpen(false)
                setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message:'Ups! ha ocurrido con el envio de correo, intentelo mas tarde'})
            }
        }
        
        if(tipo== 'masivo'){

            const resp:any = await mailService.sendLandingMailMasivo(dataCupon);

            if(resp?.data){
                setOpen(false)
                setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message:'El cupon promocional ha sido enviado con éxito'})
            }else{
                setOpen(false)
                setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message:'Ups! ha ocurrido con el envio de correo, intentelo mas tarde'})
            }
        }

    }

    const handleChange = (e) =>{
        console.log(e)
        const name = e.target.name;
        console.log(name)
        switch(name){
            case 'titulo':
                setDataLanding({...dataLanding, titulo: e.target.value});
                e.target.value
                break;
            case 'frase_principal':
                setDataLanding({...dataLanding, frase_principal: e.target.value});
                e.target.value
                break;
            case 'descuento':
                setDataLanding({ ...dataLanding, descuento: e.target.value });
                e.target.value
                break;
            case 'descripcion':
                setDataLanding({ ...dataLanding, descripcion: e.target.value });
                e.target.value
                break;
            case 'codigo_promocional':
                setDataLanding({ ...dataLanding, codigo_promocional: e.target.value });
                e.target.value
                break;
            case 'btn_principal':
                setDataLanding({ ...dataLanding, btn_principal: e.target.value });
                e.target.value
                break;
            case 'url_principal':
                setDataLanding({ ...dataLanding, url_principal: e.target.value });
                e.target.value
                break;
        }
    }

    //cuerpo del modal
    const bodyModal = (
        <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {props.tipo == 'hilo_correo'? 'Cupón Enviado':`Personaliza el envio de cupón por correo`}
            </Typography>
            { props.tipo !== 'hilo_correo' &&  <Grid container justifyContent={'right'} spacing={2} mt={2}>
           <Button 
             sx={{
                background: '#E4980D', width:'100px', color: '#fff', mr:2, fontWeight: '700', '&:hover': {
                    bgcolor: '#E4980D'
                }
            }}
            onClick={()=>{setStatusEdit(!statusEdit)}}>{statusEdit?'Ver':'Editar'}</Button>
            <Button 
             sx={{
                background: '#5C68E2', width:'100px', color: '#fff', mr:2, fontWeight: '700', '&:hover': {
                    bgcolor: '#5C68E2'
                }
            }}
            onClick={()=>{props.tipo == 'detalle'?EnviarCupon('individual'): EnviarCupon('masivo')}}>{props.tipo == 'detalle'? 'Enviar': 'Enviar Masivo'}</Button>
            </Grid>}
            
            <Grid container justifyContent={'center'} paddingX={8} mt={3} maxHeight={'70vh'} sx={{overflowY:'scroll'}}>
                {statusEdit ?

                    <Grid item container xs={12} sx={{ background: '#5C68E2' }}>
                        <h2 style={{ marginTop: '0', padding:'30px', color: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <TextField
                            name={'titulo'}
                            fullWidth
                            value={dataLanding.titulo}
                            onChange={(e)=>{handleChange(e)}}
                            sx={{width:'480px', background: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                            placeholder={'Introduce el nombre de la empresa o título'}
                            type='input'
                        /></h2>
                       
                    </Grid>
                    :
                    <Grid item container xs={12} sx={{ background: '#5C68E2' }}>
                        <h2 style={{ marginTop: '0', padding:'30px', color: '#fff', fontSize: '36px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            {dataLanding.titulo}</h2>
                    </Grid>

                }

                {
                    statusEdit?
                    <Grid item container xs={12} sx={{ background: '#fff', padding:'20px' }} textAlign='center' justifyContent={'center'}>
                    <img width={'300px'} style={{marginLeft:'10px'}} src={dataLanding.img_principal}
                     height={'300px'}></img>
                    </Grid>:
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff', padding:'20px' }} textAlign='center' justifyContent={'center'}>
                    <img width={'300px'} style={{marginLeft:'10px'}} src={dataLanding.img_principal}
                     height={'300px'}></img>
                    </Grid>
                    
                    </> 
                    
                }

                {
                    statusEdit?
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center' justifyContent={'center'}>
                    <TextField 
                          type='input'
                          sx={{ width:'400px',marginTop: '0',lineHeight:'20px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif'}}
                          name='frase_principal'
                          onChange={(e)=>handleChange(e)}
                          value={dataLanding.frase_principal}
                          placeholder='Introduce un frase'/>
                        
                    </Grid>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center' justifyContent={'center'}>
                        <h1 style={{ marginTop: '0',lineHeight:'70px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '70px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                           <TextField
                           type='number'
                           sx={{width:'100px'}}
                           name='descuento'
                           onChange={(e)=>{handleChange(e)}}
                           value={dataLanding.descuento}/> % DESCUENTO</h1>
                    </Grid>
                    </>:
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center' justifyContent={'center'}>
                        <h3 style={{ marginTop: '0', paddingLeft:'10px' ,lineHeight:'20px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '20px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        {dataLanding.frase_principal}</h3>
                    </Grid>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center' justifyContent={'center'}>
                        <h1 style={{ marginTop: '0', paddingLeft:'10px',lineHeight:'70px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '70px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                            &nbsp;{dataLanding.descuento}% DESCUENTO</h1>
                    </Grid>
                    </> 
                    
                }
                
                {
                    statusEdit?
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>
                        <TextField
                        type='input'
                        fullWidth
                        value={dataLanding.descripcion}
                        onChange={(e)=>handleChange(e)}
                        multiline={true}
                        name='descripcion'
                        sx={{marginTop: '0', paddingLeft:'25px', paddingRight:'25px'}}/>
                  </Grid>:
                    <>
                    <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>
                        <h2 style={{ marginTop: '0', paddingLeft:'25px', paddingRight:'25px' ,lineHeight:'24px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        {dataLanding.descripcion} </h2>    </Grid>
                    </> 
                    
                }
                {
                    statusEdit?
                    <Grid item container xs={12} sx={{bgcolor:'#fff'}} justifyContent='center' pt={3}>
                        <table width="80%" 
                        style={{borderCollapse:'separate',borderSpacing:'0px',border:'2px dashed #cccccc', borderRadius:'5px'}}>
                            <tr>
                                <td align='center'>
                                <h2 style={{ marginTop: '0', paddingLeft:'25px', paddingRight:'25px' ,lineHeight:'31px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '26px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                                    Tu código promocional</h2>
                                </td>

                            </tr>
                            <tr>
                               <td align='center'>
                               <h2 style={{ marginTop: '0', paddingLeft:'25px', paddingRight:'25px' ,lineHeight:'31px', paddingTop:'15px', paddingBottom:'20px', color: '#5C68E2', fontSize: '46px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                                <TextField
                                type='input'
                                name='codigo_promocional'
                                onChange={(e)=>handleChange(e)}
                                value={dataLanding.codigo_promocional}/> </h2></td>                                     
                            </tr>
                        </table>
                   </Grid>:
                    <>
                    <Grid item container xs={12} sx={{bgcolor:'#fff'}} justifyContent='center'>
                        <table width="80%" 
                        style={{borderCollapse:'separate',borderSpacing:'0px',border:'2px dashed #cccccc', borderRadius:'5px'}}>
                            <tr>
                                <td align='center'>
                                <h2 style={{ marginTop: '0', paddingLeft:'25px', paddingRight:'25px' ,lineHeight:'31px', paddingTop:'15px', paddingBottom:'20px', color: '#333333', fontSize: '26px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                                    Tu código promocional</h2>
                                </td>

                            </tr>
                            <tr>
                               <td align='center'>
                               <h2 style={{ marginTop: '0', paddingLeft:'25px', paddingRight:'25px' ,lineHeight:'31px', paddingTop:'15px', paddingBottom:'20px', color: '#5C68E2', fontSize: '46px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                               {dataLanding.codigo_promocional}</h2></td>                                     
                            </tr>
                        </table>
                   </Grid>
                    </> 
                    
                }
                {
                    statusEdit?
                    <Grid item container xs={12} sx={{bgcolor:'#fff', paddingTop:'25px'}} justifyContent='center'>
                        <a
                            style={{
                                background: '#5C68E2', color: '#fff'
                            }}
                        ><TextField type='input' name={'btn_principal'} onChange={(e)=>handleChange(e)} value={dataLanding.btn_principal}/>
                        </a>
                        <IconButton onClick={()=>{setOpenVinculo(!openVinculo)}}>
                        <LanguageIcon />
                        </IconButton>
                       
                        {
                            openVinculo && <TextField
                            type='input'
                            name='url_principal'
                            value={dataLanding.url_principal}
                            placeholder='Introduce un hipervinculo para el botón'
                            onChange={(e)=>handleChange(e)}/>
                        }
                    </Grid>:
                    <Grid item container xs={12} sx={{bgcolor:'#fff', paddingTop:'25px'}} justifyContent='center'>
                        <a href={dataLanding.url_principal} target='__blank' style={{textDecoration:'none'}}>
                        <Button
                            sx={{
                                background: '#5C68E2', color: '#fff', fontWeight: '700', '&:hover': {
                                    bgcolor: '#5C68E2'
                                }
                            }}
                            variant='contained'
                            onClick={() => { }}
                        >{dataLanding.btn_principal}
                        </Button>
                        </a>
                    </Grid>
                }
                <Grid item container xs={12} sx={{ background: '#fff' }} textAlign='center'>

                    <h6 style={{ marginTop: '0', lineHeight: '21px', padding: '30px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <strong>{'Desarrollado por Softnet SPA'}</strong>
                    </h6>
                    <h6 style={{ marginTop: '0', lineHeight: '21px', padding: '30px', color: '#333333', fontSize: '14px', fontWeight: 'bold', fontFamily: 'open sans, helvetica neue, helvetica, arial, sans-serif' }}>
                        <strong><a href='www.softnet.cl' target='_blank'>{'softnet.cl'}</a></strong></h6>

                </Grid>
            </Grid>
        </Box> 
    )


    const BodyEnvioExistente = (
        <>
         <Box className='Modal' p={4}>
            <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '1.3em' }} variant="h5" component="h1" textAlign='center' color='primary'>
                {`Hemos encontrado un cupón existente para ${props.data.nombre_rz} dentro de la Campaña, ¿Está seguro de volver a enviar?`}
            </Typography>
            <Grid container justifyContent={'center'} spacing={2} mt={2}>
                <Button
                    sx={{
                        background: '#E4980D', color: '#fff', fontWeight: '700', mr:3, '&:hover': {
                            bgcolor: '#E4980D'
                        }
                    }}
                    variant='contained'
                    onClick={() => { setOpenValidation(false); setDataLanding({});props.setOpen(false); }}
                >{'Salir'}
                </Button>
                <Button
                    sx={{
                        background: '#5C68E2', color: '#fff', fontWeight: '700', '&:hover': {
                            bgcolor: '#5C68E2'
                        }
                    }}
                    variant='contained'
                    onClick={() => { setOpenValidation(false);}}
                >{'Continuar'}
                </Button>
            </Grid>
        </Box> 
        </>
    )

    return (
        <div>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Modal
                open={props.open}
                onClose={() => { props.setOpen(false);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
                sx={{width:'120%', left:'-10%'}}
            >
                <>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <SpinnerGrow/>
                    <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
                </Grid>
            </Backdrop>
            
            {!open && openValidation ? BodyEnvioExistente : bodyModal}
                </>
                    
            </Modal>
           
        </div>
    );
}
