import { ArriendosService } from "@/service/services/Arriendos.service.";
import { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';

export const LineBarFacturadorDevengados: React.FC<any> = (props): JSX.Element => {
    
    const {setOpen, setSnackBarConfig, snackBarConfig } = props
    const [dataLine, setDataLine] = useState<any>([]);

    const optionsLine = {
        scales: {
           y: {
              beginAtZero: true,
           },
        },
     };
     
    useEffect(()=>{
        getDashboardFacturacionDevengados();
    },[])

    const getDashboardFacturacionDevengados = async() => {
        setOpen(true);
        const response = await ArriendosService.getDashboardFacturacionDevengados();
        if (!!response?.status) {
           setDataLine(response?.data)
        } else {
           setSnackBarConfig({ ...snackBarConfig, open: true, message: response?.message, severity: 'error' })
        }
        setOpen(false);
     }

    return (
    <>
        <Line data={dataLine} options={optionsLine} />

    </>
 )
}