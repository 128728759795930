import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Contact.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, Container, Divider, Grid, InputLabel, TextField, Typography, Button, Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { TabsComponent } from '@/components/common/Tabs/index';
import { Box, width } from '@mui/system';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { contactService } from '@/service/services/Contact.service';
import { Link } from 'react-router-dom';
import { ROUTE_CONTACT_CREATE, ROUTE_CONTACT_UPDATE, ROUTE_CREATE_CONTACT } from '@/toolbox/constants/route-map';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { ClienteSoftnetService } from '@/service/api/services/ClienteSoftnet.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { sincronizacionService } from '@/service/services/Sincronizacion.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { ROLE_ADMIN, ROLE_VENDEDOR, ROLE_SUPERVISOR } from '@/toolbox/defaults/static-roles';
import { SpinnerGrow } from '@/components/common/Spinner';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import { ModalProspectoRapido } from '@/components/common/Modal/ModalProspectoRapido';
import {ModalTomarNota} from '@/components/common/Modal/ModalTomarApuntes';

export const ContactView: React.FC<Props> = (props: Props): JSX.Element => {

   const header = [
      { name: 'rut_cliente', label: 'Rut', width: '' },
      { name: 'nombre_rz', label: 'Razon Social', width: '' },
      { name: 'giro', label: 'Giro', width: '' },
      { name: 'telefono', label: 'Telefono', width: '' },
   ]
   const labels = [{ label: 'Persona Natural' }, { label: 'Persona Jurídica' }]
   const dataActionAdmin = [
      { name: 'view', color: 'success', route: ROUTE_CONTACT_UPDATE },
      //{ name: 'edit', color: 'primary', route: ROUTE_CONTACT_UPDATE },
      { name: 'delete', color: 'secondary', route: null }
   ]
   const dataAction = [
      { name: 'view', color: 'success', route: ROUTE_CONTACT_UPDATE },
   ]
   const [open, setOpen] = useState(false);
   const [openSync, setOpenSync] = useState(false);
   const [dataClient, setDataClient] = useState<any>([])
   const [dataEntity, setDataEntity] = useState<any>([])
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [Total, setTotal] = useState<any>({
      entity:0,
      clients:0
   })
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      contactId: null,
      message: `¿Desea eliminar al contacto --- con Rut ----?`
   })
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [search, setSearch] = useState<any>('');
   const [valueForm, setValueForm] = useState<number>(0)
   const token = readLocalStorage(KEY_TOKEN_SOFTNET);
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1)
   const [openModal, setOpenModal] = useState<boolean>(false);

   const [openNota, setOpenNota] = useState<boolean>(false);

   useEffect(() => {
     try{
        getContact();
      }catch(e){
         console.log(e)
      }
   }, [pageData,perPageData])

   async function getContact() {
     try{
      setOpen(true)
      const resp = await contactService.getContact(perPageData,pageData)

      setTotal(prev=>({...prev,clients:resp.data.total_clients, entity:resp.data.total_entity}))
      setDataClient(resp.data.clients);
      setDataEntity(resp.data.entities);
      // const respContactErp= await ClienteSoftnetService.getCliente(token)
      // console.log(respContactErp)
      // setDataClient(respContactErp);
      setOpen(false)
      }catch(e){
         console.log(e)
      }
   }

   const PerPage = async (data) =>{

      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) =>{

      setPageData(data+1)
   }


      const deleteContact = async () =>
      {
         try{
            if (Dialog.confirm == true) {
            const resp = await contactService.deleteContact(Dialog.contactId);
            if (resp.data) {
               getContact();
            }
         }
         }catch(e){
            console.log(e)
         }
      }

      const contactId = async (data) => {
         try{
            const contactId = data.idcliente;
            setDialog(prev => ({ ...prev, message: `¿Desea eliminar al contacto ${data.nombre_rz} con Rut ${data.rut_cliente}?`,contactId:contactId,open: true, confirm:true}));
        }catch(e){
            console.log(e)
         }
      };



      const getSearchContacts = async (search) => {
        try{ const resp= await contactService.getSearchContacts(search);
         setDataClient(resp.data.clients);
         setDataEntity(resp.data.entities);

         }catch(e){
            console.log(e)
         }
      };

      const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  getSearchContacts(value)
               }
               if(value.length==0)
               {
                  getContact();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };

      const SyncClient = async () => {
         setOpenSync(true);
        try{
           const respSync = await sincronizacionService.postSync();
         if (respSync.data) {
            const resp = await sincronizacionService.SyncClient();
            if(resp.data.status)
            {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  severity: 'success',
                  message: resp.data.descripcion,
               }));
               getContact();

            }
            if (!resp.data.status) {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  severity: 'error',
                  message: resp.data.descripcion,
               }));
            }
         }
         setOpenSync(false);

      }catch(e){
            console.log(e)
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: 'Error al Sincronizar Clientes',
            }));
            setOpenSync(false);
         }
      }

      console.log(dataUser.user.rut_empresa)
   return (
      <Protected>
          <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1, bgcolor: '#000' }}
               open={openSync}
            >

               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{fontSize:'30px'}}><strong>Sincronizando...</strong></h1>
               </Grid>

            </Backdrop>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Grid container>
            <ConfirmDialog
               open={Dialog.open}
               title={Dialog.title}
               message={Dialog.message}
               onConfirm={()=> deleteContact()}
               onClose={() => setDialog(prev => ({ ...prev, open: false }))}
            />

            <Grid container justifyContent='space-between' spacing={2} mb={1}>
               <Grid item xs={4}>
                  <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}}>Mis Clientes</Typography>
               </Grid>
               <Grid item xs={8}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{mb:3}}
                  alignItems="center">
                    <Grid item>
                        {dataUser.user.role==ROLE_ADMIN&&<Button variant='contained'
                        sx={{background:'#E4980D', color:'#fff', '&:hover':{
                           bgcolor:'#E4980D'
                        }}}
                        endIcon={<SyncIcon fontSize='large'/>}
                        onClick={()=>{SyncClient()}}>Sincronizar</Button>}
                    </Grid>
                    {/* <Grid item>
                        {dataUser.user.role==ROLE_ADMIN&&<Button variant='contained'
                        sx={{background:'#E4980D', color:'#fff', '&:hover':{
                           bgcolor:'#E4980D'
                        }}}
                        endIcon={<SyncIcon fontSize='large'/>}
                        onClick={()=>{setOpenNota(true)}}>Tomar nota</Button>}
                    </Grid> */}

                    {/* <Grid item>
                       
                        {(dataUser.user.role==ROLE_ADMIN || dataUser.user.role==ROLE_VENDEDOR || dataUser.user.role==ROLE_SUPERVISOR) ?
                        <Button variant='contained'
                        sx={{background:'#0C879C', color:'#fff','&:hover':{
                           bgcolor:'#0C879C'
                        }
                        }}
                        endIcon={<AddIcon fontSize='large'/>}
                        component={Link} to={ROUTE_CREATE_CONTACT}>Crear Prospecto</Button>
                        : <Grid></Grid>}
                    </Grid> */}

                    {
                     dataUser.user.rut_empresa != '50290070-6' &&
<Grid item>
                    {(dataUser.user.role==ROLE_ADMIN 
                    || dataUser.user.role==ROLE_VENDEDOR || 
                    dataUser.user.role==ROLE_SUPERVISOR 

                    ) ?
                       <Button variant='contained'
                           onClick={() => { setOpenModal(true)}}
                           sx={{background:'#0E74FF', color:'#fff','&:hover':{
                              bgcolor:'#0E74FF'}
                           }}
                           endIcon={<AddIcon fontSize='large'/>}>
                           Prospecto Rápido
                        </Button>
                        : <Grid></Grid>}
                    </Grid>
                    }
                    
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <TabsComponent
                  labels={labels}
                  setValueForm={setValueForm}
                  childrenOne={
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${Total.clients ? `${Total.clients}` : `0`} Registros encontrados`}
                              </Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <InputLabel >Filtrar Resultados</InputLabel>
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 name="search"
                                 value={search}
                                 sx={{bgcolor:'#E3F4FD'}}
                                 onChange={handleInput}
                                 placeholder="Introduce un Nombre o Rut Cliente"
                                 type="search"
                                 id="password"
                                 autoComplete="current-password"
                                 size="small"
                              />
                           </Grid>
                        </Grid>
                        <Grid container >
                           <TableData
                              header={header}
                              data={dataClient?dataClient:[]}
                              total={Total.clients? Total.clients:0}
                              action={dataUser.user.rut_empresa == '50290070-6' ? [] :dataUser.user.role==ROLE_ADMIN?dataActionAdmin:dataAction}
                              disabledTextAction = {dataUser.user.rut_empresa == '50290070-6' ? true : false}
                              perPage={PerPage}
                              page={page}
                              RecuperarData={contactId} />
                        </Grid>
                     </Container>
                  }
                  childrenTwo={
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Grid container spacing={1} >
                           <Grid item xs={12}>
                              <Typography variant="body2" sx={{ mt: '20px' }} >
                              {`${Total.entity ? `${Total.entity}` : `0`} Registros encontrados`}
                              </Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <InputLabel >Filtrar Resultados</InputLabel>
                              <TextField
                                 margin="normal"
                                 required
                                 fullWidth
                                 name="search"
                                 sx={{bgcolor:'#E3F4FD'}}
                                 value={search}
                                 onChange={handleInput}
                                 placeholder="Introduce un Nombre o Rut Cliente"
                                 type="search"
                                 id="password"
                                 autoComplete="current-password"
                                 size="small"
                              />
                           </Grid>
                        </Grid>
                        <Grid container >
                           <TableData
                              header={header}
                              data={dataEntity?dataEntity:[]}
                              total={Total.entity? Total.entity:0}
                              action={ dataUser.user.rut_empresa == '50290070-6' ? [] : (dataUser.user.role==ROLE_ADMIN?dataActionAdmin:dataAction)}
                              perPage={PerPage}
                              disabledTextAction = {dataUser.user.rut_empresa == '50290070-6' ? true : false}

                              page={page}
                              RecuperarData={contactId}
                               />
                        </Grid>
                     </Container>
                  }
               />
            </Grid>
         </Grid>
         <ModalProspectoRapido
            open={openModal}
            setOpen={setOpenModal}
            // dataCampaing={props.location.state}
            refreshData={getContact}
         />
         <ModalTomarNota
         open={openNota}
         setOpen={setOpenNota}
         />
      </Protected>
   )
};
