import { Box, Button, Grid, Modal, TextField } from '@mui/material';
import React from 'react';
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";

const ModalUploadImage = props => {
    const { open, handleClose, uploadImage, sendMessage } = props;

    const [comentary, setComentary] = React.useState("");

    const onChangeComentary = (event) => {
      setComentary(event.target.value);
    }

   //  const handleSendMessage =  () =>{
   //    sendMessage(comentary)
   //  }


    const bodyModal = (
      <Box className='Modal'>
          <Grid container>
             <Grid item xs={12}>
                <img height="350px" width="100%" src={uploadImage} />
             </Grid>

             {/* <Grid item xs={12} style={{ marginTop: "20px" }}>
                <TextField
                   id="name"
                   type="text"
                   fullWidth
                   autoFocus
                   placeholder="Añadir un comentario..."
                   value={comentary}
                   onChange={onChangeComentary}
                   onKeyPress={event => { event.key === 'Enter' && handleSendMessage() }}
                />
             </Grid> */}
          </Grid>
          <Grid item container
                  direction="row"
                  justifyContent="end"
                  alignItems="center">
                  <Button
                     onClick={() => { handleClose()}}
                     variant="contained"
                     color="error"
                     startIcon={<CancelIcon />}
                     sx={{
                        background: '#bf6c00', color: '#fff', mt: '10px', mr: '10px', '&:hover': { bgcolor: '#bf6c00' }
                     }}>
                     <span>{'Cancelar'}</span>
                  </Button>
                  <Button
                     variant='contained'
                     onClick={() => { sendMessage()}}
                     startIcon={<SaveIcon />}
                     sx={{
                        background: '#155172', color: '#fff', mt: '10px', mr: '10px', '&:hover': {
                           bgcolor: '#155172'
                        }
                     }}
                  >
                     <span>{'Enviar'}</span>
                  </Button>
               </Grid>
      </Box>
   )

    return (
      <div>
      <Modal
         open={open}
         onClose={() => { handleClose()}}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         {bodyModal}
      </Modal>
   </div>
    )
};

export default ModalUploadImage;
