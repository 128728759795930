import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Container, InputLabel, Snackbar, Alert } from '@mui/material';
import { Box, Button } from '@mui/material';
import './Modal.sass'
import { CancelIcon, SaveIcon } from "@toolbox/constants/icons";
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { useHistory } from 'react-router-dom';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';

type Chats = {
   id: number,
   id_ws_prospect: number,
   ws_p_telefono: string,
   idchat_type: number,
   chatTypeName: string,
   status: string,
   idClient?: number | null,
   rutClient?: string | null,
   nameRzClient?: string | null,
   nameContact?: string | null
}

type ModalProps = {
   open: boolean,
   setOpen: any,
   phone?: Chats,
   refreshData?: () => void,
}

const initialFormValues = {
   rut: '',
   nombre: '',
   telefono: '',
   correo: '',
}

export const ModalProspectoRapidoWS: React.FC<ModalProps> = (
   props: ModalProps
): JSX.Element => {
   const { phone, open, setOpen, refreshData } = props;
   const history = useHistory();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [error, setError] = useState(null);
   const [formValues, setFormValues] = useState(initialFormValues);
   const { rut = "", nombre = "", telefono = "", correo = "" } = formValues


   const handleInputChange = (e) => {
      const changedFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }
      // changedFormValues.rut.trim() !== "" && setError("");
      // changedFormValues.nombre.trim() !== "" && setError("");
      // changedFormValues.telefono.trim() !== "" && setError("");
      // changedFormValues.correo.trim() !== "" && setError("");

      setFormValues(changedFormValues)

   }

   const handleSubmitForm = async (e) => {
      e.preventDefault()
      if (!nombre.trim()) { return setError("nombre") }
      else {
         //Obtengo el idCampaña
         // let campana = []
         // const canalWS = 6;//es 6 para whatssap
         // const respCanal = await campaingService.getCampanaProspecto(canalWS)
         // console.log(respCanal)
         // if (respCanal) {
         //    campana = respCanal.data
         //    console.log(campana[0]['id'])
         // }
         //Creo al cliente
         let client = null;
         // const dataUser = readLocalStorage(KEY_USER_DATA);
         // const id_empresa = dataUser?.user?.idempresacliente;
        if(!phone?.idClient){
         const respClient = await contactService.createClientProspecto(formValues)
         if (respClient) {
            if (!respClient.data.status) {
               setSnackBarConfig(prev => ({
                  ...prev,
                  open: true,
                  severity: 'error',
                  message: respClient.data.error,
               }));
            } else {
               //Campana Negocio General
               refreshData();
               client = respClient.data.cliente
            }
         } else {
            console.log('error')
         }
        }

         if (client) {
            setOpen(false)
         }

         // const dataClient = {
         //    idcampananegocio: campana[0]['id'],
         //    productos: null,
         //    idparameter: null,
         //    form_cliente: null,
         //    observacion: 'Ninguna',
         //    idcliente: client ? client['idcliente'] :  phone?.idClient,
         //    idusuario: dataUser?.user?.idusuario,
         //    // asign_automatic: true,
         //    id_empresa: id_empresa
         // }
         // const respCampana = await campaingService.createCampanaGeneral(dataClient)
         // if (respCampana) {
         //    setFormValues(initialFormValues)
         //    setOpen(false)
         //    // history.push(`/campaña/generales/${campana[0]['id']}`, { cliente: respCampana.data.idcliente, nombre_campana: respCampana.data.nombre_campana, formValue: formValues, channel: canalWS })
         //    const newTabUrl = `/campaña/generales/${campana[0]['id']}?idcliente=${respCampana.data.idcliente}&idchannel=${canalWS}&state=${true};`;
         //    window.open(newTabUrl, '_blank');
         // }
      }
   }

   useEffect(() => {
      phone && setFormValues(prev => ({ ...prev, telefono: phone?.ws_p_telefono, rut: phone?.rutClient, nombre: phone?.nameRzClient }))
   }, [phone])


   const bodyModal = (
      <Box className='Modal'>
         <Box>
            <form onSubmit={handleSubmitForm}>
               <Container maxWidth='xl' sx={{ mt: 1, mb: 2, maxHeight: 600, overflow: 'auto' }}>
                  <Typography id="modal-modal-title" sx={{ color: '#3F9BCC', fontWeight: '700', fontSize: '2em' }} variant="h5" component="h1" textAlign='center' color='primary'>Prospecto</Typography>
                  <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Rut</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        // error={error === "rut" ? true : false}
                        // helperText={error === "rut" ? "Rut es es un campo requerido" : ""}
                        id='rut'
                        name='rut'
                        label="Ingrese Rut"
                        fullWidth
                        value={rut}
                        onChange={handleInputChange}
                     />
                  </Grid>
                  <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Nombre*</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        // error={error === "nombre" ? true : false}
                        // helperText={error === "nombre" ? "Nombre es es un campo requerido" : ""}
                        required
                        id='nombre'
                        name='nombre'
                        label="Ingrese Nombre*"
                        fullWidth
                        value={nombre}
                        onChange={handleInputChange}
                     />
                  </Grid>
                  <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Telefono</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        // error={error === "telefono" ? true : false}
                        // helperText={error === "telefono" ? "Telefono es es un campo requerido" : ""}
                        id='telefono'
                        name='telefono'
                        label="Ingrese Telefono"
                        required
                        disabled
                        fullWidth
                        value={telefono}
                        onChange={handleInputChange}
                     />
                  </Grid>
                  <Grid item xs={12} container alignContent={'end'} sx={{ mb: 1 }}>
                     <InputLabel >Correo</InputLabel>
                     <TextField
                        size='small'
                        sx={{ bgcolor: '#fff' }}
                        // error={error === "correo" ? true : false}
                        // helperText={error === "correo" ? "Correo es es un campo requerido" : ""}
                        id='correo'
                        name='correo'
                        label="Ingrese correo"
                        fullWidth
                        value={correo}
                        onChange={handleInputChange}
                     />
                  </Grid>
               </Container>
               <Grid item container
                  direction="row"
                  justifyContent="end"
                  alignItems="center">
                  <Button
                     onClick={() => {
                        setFormValues(initialFormValues)
                        setOpen(false)
                     }}
                     variant="contained"
                     color="error"
                     startIcon={<CancelIcon />}
                     sx={{
                        background: '#bf6c00', color: '#fff', mt: '10px', mr: '10px', '&:hover': { bgcolor: '#bf6c00' }
                     }}>
                     <span>{'Cancelar'}</span>
                  </Button>
                  <Button
                     variant='contained'
                     type='submit'
                     startIcon={<SaveIcon />}
                     sx={{
                        background: '#155172', color: '#fff', mt: '10px', mr: '10px', '&:hover': {
                           bgcolor: '#155172'
                        }
                     }}
                  >
                     <span>{'Guardar'}</span>
                  </Button>
               </Grid>
            </form>
         </Box>
      </Box>
   )
   return (
      <div>
         <Modal
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            {bodyModal}
         </Modal>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig?.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      </div>
   );
}
