
import { sincronizacionRepository } from "../repositories/Sincronizacion.repository";

export const sincronizacionService= {
   getModulosToSync,
   SyncProduct,
   SyncClient,
   postSync,
   getSyncList

};

async function getModulosToSync () {
   const accion= await sincronizacionRepository.getModulosToSync();
   return accion;
}
async function SyncProduct () {
   const accion= await sincronizacionRepository.getSyncProduct();
   return accion;
}
async function SyncClient () {
   const accion= await sincronizacionRepository.getSyncCliente();
   return accion;
}

async function postSync () {
   const accion= await sincronizacionRepository.postAsync();
   return accion;
}

async function getSyncList () {
   const getSyncList= await sincronizacionRepository.getSyncList();
   return getSyncList;
}

