import React, { useEffect, useRef, useState } from 'react';

import { Protected } from '@components/layout/Protected';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import Card from "@mui/material/Card";
import { Props } from './Home.type'
import Contactos from "@assets/img/clientes.png";
import Productos from "@assets/img/productos.png";
import Negocios from "@assets/img/negocios.png";
import Vacio from "@assets/img/vacio.png";
import Typography from "@mui/material/Typography";
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { ROUTE_ACTIVIDADES, ROUTE_CONTACT, ROUTE_PRODUCT } from '@/toolbox/constants/route-map';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_ARRAY_MY_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { SpinnerGrow } from '@/components/common/Spinner';
import { Chart } from "react-google-charts";
import { reportingService } from '@/service/services/Reporting.service';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';
import { campaingService } from '@/service/services/Campaing.service';
import { moneyFormatInt } from '@/toolbox/helpers/money.helper';
import logokyte from "@assets/img/vacio.png";
import { ModalUserSalesTop } from '@/components/common/Modal/ModalUserSalesTop';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { ArriendosView } from '../Arriendos/Arriendos';
//import { findUserByUserName } from 'services/actions/UserAction'; llamar a la accion
/*DATA GRAFICO MULTIBARRA */

// const data = [
//    ["Campañas Leads", "Facebook", "Landing Page", "Instragram"],
//    ["Campañas Leads 1", 1000, 400, 200],
//    ["Campañas Leads 2", 1170, 460, 250],
//    ["Campañas Leads 3", 660, 1120, 300],
//    ["Campañas Leads 4", 1030, 540, 350],
// ];



const options = {
   chart: {
      title: "",
      subtitle: "",
      is3D: true
   },
   vAxis: {
      minValue: 1
    },
};

/*DATA GRAFICO DONA */


const optionsDona = {
   title: "",
   pieHole: 0.6,
   legend: {
      position: "right",
      alignment: "center",
      textStyle: {
         color: "233238",
         fontSize: 14
      }
   },
  //  tooltip: {
  //     showColorCode: true
  //  },
   chartArea: {
      left: 0,
      top: 0,
      width: "100%",
      height: "80%"
   },
   fontName: "Roboto"
};

export const HomeView: React.FC<Props> = (props:any): JSX.Element => {

   const dataUser = readLocalStorage(KEY_USER_DATA);
   const rut_empresa = dataUser?.user?.rut_empresa;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [anchorElLeads, setAnchorElLeads] = React.useState(null);
  const isMenuOpenLeads = Boolean(anchorElLeads);

  const [anchorElLeadsChannel, setAnchorElLeadsChannel] = React.useState(null);
  const isMenuOpenLeadsChannel = Boolean(anchorElLeadsChannel);

  const [dataListCampaign, setDataListCampaign] = useState<any>([]);
  const [dataListCampaignLeads, setDataListCampaignLeads] = useState<any>([]);

   const [dataReporting, setDataReporting] = useState<any>([]);
   const [dataReportingLead, setDataReportingLead] = useState<any>([]);
   const [dataReportingLeadByChannel, setDataReportingLeadByChannel] = useState<any>([]);

   const [userSales, setUserSales] = useState<any>([{}]);
   const [campaignWin, setCampaignWin] = useState<any>([]);
   const [ganancia, setGanancia] = useState<any>([]);
   const [gananciaMes, setGananciaMes] = useState<any>([]);

   const [vacio, setVacio] = useState<boolean>(false);
   const [vacioLeads, setVacioLeads] = useState<boolean>(false);

   const [openView, setOpenView] = useState(false);
   const [open, setOpen] = useState<boolean>(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpenLeads = (event) => {
    setAnchorElLeads(event.currentTarget);
  };

  const handleProfileMenuOpenLeadsChannel = (event) => {
   setAnchorElLeadsChannel(event.currentTarget);
 };


  const handleMenuClose = async(id) => {
    console.log(typeof(id));
    if(typeof(id)=='number'){
       if(id==0){
         const resp = await reportingService.getReportingCampanas();
         setDataReporting(resp);
       }else{
         const resp = await reportingService.getReportingCampanasById(id);
         setDataReporting(resp);
       }

      setAnchorEl(null);

    }else{
      setAnchorEl(null);
    }

  };

  const handleMenuCloseLeads = async(id) => {
    console.log(id);
    if(typeof(id)=='number'){
       if(id==0){
         const resp = await reportingService.getReportingCampanasLeads();
         setDataReportingLead(resp);
       }else{
         const resp = await reportingService.getReportingCampanasLeadsById(id);
         setDataReportingLead(resp);
       }
      setAnchorElLeads(null);

    }else{
      setAnchorElLeads(null);
    }

  };

  const handleMenuCloseLeadsChannel = async(id) => {
   console.log(id);
   if(typeof(id)=='number'){
      if(id==0){
        const resp = await reportingService.getReportingLeadsChannel();
        setDataReportingLeadByChannel(resp);
      }else{
         const resp = await reportingService.getReportingLeadsChannelById(id);
        setDataReportingLeadByChannel(resp);
      }
     setAnchorElLeadsChannel(null);

   }else{
     setAnchorElLeadsChannel(null);
   }

 };

   useEffect(() => {

      getCampañasLeadsByChannel();
      getCampaing();
      getUserSalesForMonth();
      getCampaignWin();
      getCampaignGanancias();
      getCampañas();
      getCampañasLeads();
      generateAutomaticCampaign();
      // console.log(props)
      // props.$action.actionSetListNotification([1,2,3,4])
    /// props.NotificationsAction();
   }, [])

   async function generateAutomaticCampaign(){
      const resp = await campaingService.generateAutomaticCampaign();
   }

   async function getCampaing() {

    const resp = await campaingService.getCampaing();
      if(resp){
         setDataListCampaign(resp.data.campaign);
         setDataListCampaignLeads(resp.data.campaignlits);
      }

 }
   async function getCampaignGanancias() {
   const resp = await reportingService.getCampaignGanancias();
   if(resp){
      console.log(resp);
      setGanancia(resp.total);
      setGananciaMes(resp.totalMes);
   }
}
   async function getUserSalesForMonth() {
      const resp = await reportingService.getUserSalesForMonth();
      console.log(resp)
     // setUserSales(resp?resp:[]);
   }
   async function getCampaignWin() {
      const resp = await reportingService.getCampaignWin();
      if(resp){
         setCampaignWin(resp);
      }
   }
   async function getCampañas() {
      const resp = await reportingService.getReportingCampanas();
      let contador=0;
      if(resp){
         resp.map((value,i)=>{
           value.map((row,index)=>{
              if(typeof row =='number')
              {
                 contador=contador+row;
              }
           })
         })
         setVacio(contador>0?false:true)
         setDataReporting(resp);
      }
   }

   async function getCampañasLeads() {
      const resp = await reportingService.getReportingCampanasLeads();
      let contador=0;
      if(resp){
         resp.map((value,i)=>{
            value.map((row,index)=>{
               if(typeof row =='number')
               {
                  contador=contador+row;
               }
            })
         })
         setVacioLeads(contador>0?false:true)
         setDataReportingLead(resp);
         setOpenView(false)
      }
   }

   async function getCampañasLeadsByChannel() {
      setOpenView(true);
      const resp = await reportingService.getReportingLeadsChannel();
      if(resp){
         setDataReportingLeadByChannel(resp);
      }
   }

   const renderMenu = (
    <Menu
       id="fade-menu"
       MenuListProps={{
          'aria-labelledby': 'fade-button',
       }}
       anchorEl={anchorEl}
       open={isMenuOpen}
       onClose={handleMenuClose}
       TransitionComponent={Fade}
    >
       <MenuItem sx={{color:'#5C6C85', fontWeight:'700', fontSize:'0.95em'}} onClick={()=>handleMenuClose(0)} >TODOS</MenuItem>
      {dataListCampaign.map((value, i)=>{
            return(
              <MenuItem key={i} sx={{color:'#5C6C85', fontWeight:'700', fontSize:'0.95em'}} onClick={()=>handleMenuClose(value.id)} >{value.nombre}</MenuItem>
            )
      })}

    </Menu>
 );

 const renderMenuLeads = (
  <Menu
     id="fade-menu"
     MenuListProps={{
        'aria-labelledby': 'fade-button',
     }}
     anchorEl={anchorElLeads}
     open={isMenuOpenLeads}
     onClose={handleMenuCloseLeads}
     TransitionComponent={Fade}
  >
     <MenuItem sx={{color:'#5C6C85', fontWeight:'700', fontSize:'0.95em'}} onClick={()=>handleMenuCloseLeads(0)} >TODOS</MenuItem>
    {dataListCampaignLeads.map((value, i)=>{
          return(
            <MenuItem key={i} sx={{color:'#5C6C85', fontWeight:'700', fontSize:'0.95em'}} onClick={()=>handleMenuCloseLeads(value.id)} >{value.nombre}</MenuItem>
          )
    })}

  </Menu>
);

const renderMenuLeadsChannel = (
   <Menu
      id="fade-menu"
      MenuListProps={{
         'aria-labelledby': 'fade-button',
      }}
      anchorEl={anchorElLeadsChannel}
      open={isMenuOpenLeadsChannel}
      onClose={handleMenuCloseLeadsChannel}
      TransitionComponent={Fade}
   >
      <MenuItem sx={{color:'#5C6C85', fontWeight:'700', fontSize:'0.95em'}} onClick={()=>handleMenuCloseLeadsChannel(0)} >TODOS</MenuItem>
     {dataListCampaignLeads.map((value, i)=>{
           return(
             <MenuItem key={i} sx={{color:'#5C6C85', fontWeight:'700', fontSize:'0.95em'}} onClick={()=>handleMenuCloseLeadsChannel(value.id)} >{value.nombre}</MenuItem>
           )
     })}

   </Menu>
 );

   return (
      <>
      {
         rut_empresa == '50290070-6' ? 
         <ArriendosView/>
         :
         <Protected>

         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container>

            <Grid container spacing={2}>
               <Grid item md={12} >
                  <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }}>Dashboard</Typography>
               </Grid>
               <Grid item md={4}>
                  <Card sx={{ minWidth: 275, height: 150, border: '3px solid #007EA7', pl: 2, pr: 2 }}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.1em', mt: 2 }}>Total de ventas</Typography>
                        <Typography sx={{ color:'#E4980D', fontWeight: '700', fontSize: '0.8em', mb: 2, mt: 1 }}>
                           {ganancia == 0? '$ 0 CLP' : moneyFormatInt(ganancia)+' '+'CLP'}
                        </Typography>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.1em', mt: 2 }}>Total de ventas del Mes</Typography>
                        <Typography sx={{ color:'#E4980D', fontWeight: '700', fontSize: '0.8em', mb: 2, mt: 1 }}>
                        {gananciaMes == 0? '$ 0 CLP' : moneyFormatInt(gananciaMes)+' '+'CLP'}
                        </Typography>

                  </Card>
               </Grid>
               <Grid item md={4}>
                  <Card sx={{ minWidth: 275, height: 150, border: '3px solid #007EA7', pl: 2, pr: 2,position:'relative' }}>
                     <div style={{position:'absolute', left:'340px'}} onClick={()=>{setOpen(true)}}>
                        <FactCheckIcon/>
                     </div>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em', mt: 2 }}>Vendedor del mes</Typography>
                     <Typography sx={{ color:'#E4980D', fontWeight: '700', fontSize: '1.6em', mt: 1 }}>{userSales.length>0?userSales[0].nombre:'No existe Registros'}</Typography>
                     <span style={{color:'#155172'}}>{userSales.length>0?userSales[0].cont_cliente:0} ventas logradas</span>
                  </Card>
               </Grid>
               <Grid item md={4}>
                  <Card sx={{ minWidth: 275, height: 150, border: '3px solid #007EA7', pl: 2, pr: 2 }}>
                     <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em', mt: 2 }}>N° ventas ganadas</Typography>
                     <Typography sx={{ color:'#E4980D', fontWeight: '700', fontSize: '1.6em', mt: 1 }}>{campaignWin.total_campanas_ganadas}/{campaignWin.total_campanas} </Typography>
                     <span style={{color:'#155172'}}>posibles ventas</span>
                  </Card>
               </Grid>


               <Grid item md={6} >
                  <Grid container>
                      <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em', mb: 2, mt: 3 }}>{dataReportingLead[0]}</Typography>
                      <IconButton sx={{height:'40px', mt:2}} onClick={handleProfileMenuOpenLeads}>
                          <KeyboardArrowDownIcon/>
                      </IconButton>
                   </Grid >
                   {vacioLeads?(
                    <>
                  <Grid container
                     direction="row"

                     alignItems="center">
                     <Grid item >
                        <Box
                           component="img"
                           sx={{
                              height: " 180px"
                           }}
                           src={logokyte}>
                        </Box>
                     </Grid>
                     <Grid item >
                        <Typography sx={{ color:'#E4980D', fontWeight: '700', fontSize: '1.2rem' }}>No existen Campañas activas.</Typography>
                     </Grid>

                  </Grid>
                    </>
                 ):(
                    <>
                  <Chart
                     chartType="PieChart"
                     width="100%"
                     height="300px"
                     data={dataReportingLead}
                     options={optionsDona}
                  />
                    </>
                 ) }

               </Grid>

               <Grid item md={6} mt={3} >
               <Grid container>
                  <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em', mb: 2 }}>{dataReportingLeadByChannel[1]? dataReportingLeadByChannel[1][0] : 'Campañas Leads por Canales' }</Typography>
                  <IconButton sx={{height:'40px'}} onClick={handleProfileMenuOpenLeadsChannel}>
                          <KeyboardArrowDownIcon/>
                      </IconButton>
                   </Grid >
                  <Chart
                     chartType="Bar"
                     width="100%"
                     height="350px"
                     data={dataReportingLeadByChannel}
                     options={options}
                  />
               </Grid>

               <Grid item md={6} >
                 <Grid container>
                   <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em', mb: 2, mt: 3 }}>{dataReporting[0]}</Typography>
                   <IconButton sx={{height:'40px', mt:2}} onClick={handleProfileMenuOpen}>
                      <KeyboardArrowDownIcon/>
                   </IconButton>
                 </Grid>
                 {vacio?(
                    <>
                  <Grid container
                     direction="row"

                     alignItems="center">
                     <Grid item >
                        <Box
                           component="img"
                           sx={{
                              height: " 180px"
                           }}
                           src={logokyte}>
                        </Box>
                     </Grid>
                     <Grid item >
                        <Typography sx={{ color:'#E4980D', fontWeight: '700', fontSize: '1.2rem' }}>No existen Campañas activas.</Typography>
                     </Grid>

                  </Grid>
                    </>
                 ):(
                    <>
                  <Chart
                     chartType="PieChart"
                     width="100%"
                     height="300px"
                     data={dataReporting}
                     options={optionsDona}
                  />
                    </>
                 ) }

               </Grid>

            </Grid>
         </Container>
         {renderMenu}
         {renderMenuLeads}
         {renderMenuLeadsChannel}
         <ModalUserSalesTop
               open={open}
               setOpen={setOpen}
               userTop={userSales}
            />
      </Protected>
      }
     </> 
   )
};

