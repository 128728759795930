import React, { useRef, useState, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './EnterpriseUser.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, Container, Divider, Grid, InputLabel, TextField, Typography, Button, Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import { ModalCreateContact } from '@/components/common/Modal';
import { TableData } from '@/components/common/Table';
import { TabsComponent } from '@/components/common/Tabs/index';
import { ROUTE_ENTITY_CREATE, ROUTE_ENTITY_USERS } from '@/toolbox/constants/route-map';
import { contactService } from '@/service/services/Contact.service';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '@components/common/DialogConfirm';
import { entityService } from '@/service/services/Entity.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import userReducer from '@/redux/reducers/user.reducer';
import { MenuLateral } from '@/components/common/LateralMenu';

export const EnterpriseUserView: React.FC<Props> = (props: Props): JSX.Element => {
   const header = [
      { name: 'idusuario', label: 'ID', width: '' },
      { name: 'nombre', label: 'Nombre', width: '' },
      { name: 'email', label: 'Email', width: '' },
      { name: 'telefono', label: 'Telefono', width: '' },
      { name: 'user_nick', label: 'UserNick', width: '' }]
   const dataAction = [
      { name: 'view', color: 'success', route: null },
      { name: 'edit', color: 'primary', route: null },
      { name: 'delete', color: 'secondary', route: null }
   ]
   const [open, setOpen] = useState(true);
   const [dataEntityUser, setDataEntityUser] = useState<any>([])

   useEffect(() => {
      const data = readLocalStorage(KEY_USER_DATA)
      if (props.user !== undefined) {
         getEntityUsers(props.user.idempresacliente)
      } else {
         getEntityUsers(data.user.idempresacliente)
      }
   }, [])

   async function getEntityUsers(idempresacliente) {
      setOpen(true)
      const resp = await entityService.getEntityUsers(idempresacliente);
      setDataEntityUser(resp.data)
      setOpen(false)
   }

   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <CircularProgress color="inherit" />
         </Backdrop>
         <Container maxWidth='xl' sx={{ mt: 1 }}>
            <Grid container flexDirection='row' spacing={2}>
               {/* <Grid item md={3} container>
                  <MenuLateral />
               </Grid> */}
               <Grid item md={12}
                  sx={{ width: '100%', bgcolor: 'background.paper', height: '77vh', overflowX: 'hidden', overflowY: 'scroll', borderRadius: '0.5rem', border: '0.5px solid #eff3f5', borderLeft: '5px solid #1976D2 ', mt: 3 }}
               >
                  <Grid container sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }}>
                     {/* <Snackbar open={open} autoHideDuration={3000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                        <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                           Cargando Data!
                        </Alert>
                     </Snackbar> */}
                     <Grid container rowSpacing={1}>
                        <Grid item container xs={6} rowSpacing={2} >
                           <Grid item xs={12}>
                              {props.user ? (
                                 <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.5em'}}>{`USUARIOS DE ${props.user?.nombre_rz}`}</Typography>
                              ) :
                                 <Typography sx={{color:'#155172', fontWeight:'700', fontSize:'2.5em'}}>{`MIS USUARIOS`}</Typography>}
                           </Grid>
                        </Grid>
                        <Grid item xs={12} >
                           <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="text"
                              placeholder="Buscar"
                              type="search"
                              id="password"
                              autoComplete="current-password"
                              size="small"
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="body2" sx={{ mt: '20px' }} >
                           {`${dataEntityUser.length ? `${dataEntityUser.length}` : `0`} Registros encontrados`}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} >
                           <TableData
                              header={header}
                              data={dataEntityUser}
                              action={null} />
                        </Grid>
                     </Grid>
                  </Grid>

               </Grid>
            </Grid>
         </Container>
      </Protected>
   )
};
