import React, { useEffect, useState } from 'react';
import { FormHelperText, Select, InputLabel, FormControl, Button, Grid, TextField, Backdrop, CircularProgress, IconButton, MenuItem, InputAdornment, Snackbar, Box, AppBar, Toolbar, Typography, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import logoLogin from "@assets/img/logo-login.jpg";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authenticationService } from '@/service/services/Authentication.service';
import { useHistory } from 'react-router-dom'
import { ROUTE_DOCUMENTOS } from '@/toolbox/constants/route-map';
import { readLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_DOCUMENTS } from '@/toolbox/constants/local-storage';
import { Icon } from '@components/common/Icon';
import { Props } from './Registro.type'
import { ModalRegisterDocument } from '@/components/common/Modal/ModalRegisterUserDocument';

const theme = createTheme();
const dataTipoProspecto = [
   { id: 1, nombre: 'DEPENDIENTES' },
   { id: 2, nombre: 'INDEPENDIENTES' },
   { id: 3, nombre: 'RENTISTAS' },
   { id: 4, nombre: 'SOCIOS EMPRESAS' }
]

export const RegistroView: React.FC<Props> = ({ }): JSX.Element => {

   const history = useHistory();

   const [open, setOpen] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [error, setError] = useState<any>();
   const [showPassword, setShowPassword] = useState<boolean>(false);
   const [showPasswordRepit, setShowPasswordRepit] = useState<boolean>(false);
   const [formRegister, setFormRegister] = useState<any>(false);
   const { rut_cliente, rut_empresa, password, tipo_prospecto, repit_password } = formRegister
   const [idRut, setIdRut] = useState<any>('')
   const [errorRut, setErrorRut] = useState<any>('')
   //construye un modal para pedir el nombre correo y los datos que pida la bd mientras valido esto
   //Okey :3
   useEffect(() => {

   }, [])

   const handleInputBlur = async (e) => {
      const name = e.target.name;
      const value = e.target.value;

      const resp = await authenticationService.searchRutEmpresa(value)
      if(resp){
         console.log(resp);
         if(resp.data.status){
            setIdRut(resp.data.idempresa)
            setErrorRut('')
         } else {
            setErrorRut('El rut no es válido')
            return setError('rut_empresa');
         }
      }
   }

   const handleInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      var Fn = {
         // Valida el rut con su cadena completa "XXXXXXXX-X"
         validaRut: function (rutCompleto) {
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
               return false;
            var tmp = rutCompleto.split('-');
            var digv = tmp[1];
            var rut = tmp[0];
            if (digv == 'K') digv = 'k';
            return (Fn.dv(rut) == digv);
         },
         dv: function (T) {
            var M = 0, S = 1;
            for (; T; T = Math.floor(T / 10))
               S = (S + T % 10 * (9 - M++ % 6)) % 11;
            return S ? S - 1 : 'k';
         }
      }

      switch (name) {
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               setFormRegister(prev => ({ ...prev, password: value }));
            }
            break;
         case 'repit_password':
            if (/^.{0,15}$/.test(value)) {
               setFormRegister(prev => ({ ...prev, repit_password: value }));
            }
            break;
         case 'tipo_prospecto':
            setFormRegister(prev => ({ ...prev, tipo_prospecto: value }))
            break;
         case 'rut_cliente':
            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setFormRegister(prev => ({ ...prev, rut_cliente: foo }))
            } else if (foo.length == 0) {
               setFormRegister(prev => ({ ...prev, rut_cliente: "" }))
            }

            break;
         case 'rut_empresa':
            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setFormRegister(prev => ({ ...prev, rut_empresa: foo }))
            } else if (foo.length == 0) {
               setFormRegister(prev => ({ ...prev, rut_empresa: "" }))
            }

            break;
         default:
            break;
      }
   }

   const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(formRegister)
      if (!rut_cliente) {
         return setError('rut_cliente');
      }
      if (!tipo_prospecto) {
         return setError('tipo_prospecto');
      }
      if (!rut_empresa) {
         setErrorRut('El rut es un campo requerido')
         return setError('rut_empresa');
      }
      if (!password) {
         return setError('password');
      }
      if (!repit_password) {
         return setError('repit_password');
      }
      if (repit_password !== password) {
         return setError('coincidencia');
      }

      console.log(formRegister)

      const resp = await authenticationService.searchRut(rut_cliente)
      if(resp){
         console.log(resp)
         if (resp.data.status == 0) {
            console.log(resp.data.message)
            setSnackBarConfig(prev => ({
               ...prev,
               open: true,
               severity: 'error',
               message: resp.data.message
            }));
         } else if (resp.data.status == 1) {
            setOpen(true)
         } else if(resp.data.status == 2) {
            const dataCliente = {
               rut: formRegister.rut_cliente,
               password: formRegister.password,
               tipo_prospecto: formRegister.tipo_prospecto,
               idempresa: idRut,
               idcliente: resp.data.message
              }
            //registro de Cliente
            const respClient = await authenticationService.registerDocuments(dataCliente)
            if(respClient){
               const respAuth = await authenticationService.loginDocuments(formRegister.rut_cliente, formRegister.password)

               if(respAuth?.data?.message){
                   setSnackBarConfig(prev => ({
                       ...prev,
                       open: true,
                       severity: 'warning',
                       message: respAuth?.data?.message,
                   }));
               }else{
                   setSnackBarConfig(prev => ({
                       ...prev,
                       open: true,
                       severity: 'success',
                       message: 'Usuario Logeado con éxito',
                   }));
                   saveLocalStorage(KEY_DOCUMENTS,respAuth?.data)
                   history.push(ROUTE_DOCUMENTOS)
               }
            }
         }
      }
   }

   return (
      <>
         <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
               <CssBaseline />
               <Grid
                  item
                  xs={false}
                  sm={6}
                  md={7}
                  sx={{
                     backgroundImage: `url(${logoLogin})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                  }}
               />
               <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square >
                  <Box
                     sx={{
                        my: 15,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                     }}>

                     <Grid sx={{ border: '1px solid #155172', borderTop: '8px solid #155172', borderRadius: '7px', width: '80%' }}>
                        <form className="LoginForm" onSubmit={handleSubmit}>
                           <Grid container spacing={2} className={`e-mb-lg`}>
                              <Grid item xs={12}>
                                 <Typography textAlign={'center'} sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em' }} >Registro de Usuario</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                 <TextField
                                    fullWidth
                                    error={error === "rut_cliente" ? true : false}
                                    helperText={error === "rut_cliente" ? " El rut es un campo requerido" : ""}
                                    size="small"
                                    id="rut_cliente"
                                    placeholder="Ingrese su rut cliente"
                                    sx={{ bgcolor: '#fff' }}
                                    name="rut_cliente"
                                    type="text"
                                    value={rut_cliente}
                                    onChange={handleInputChange}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <FormControl fullWidth error={error === "tipoProspecto" ? true : false}>
                                    <InputLabel sx={{ color: '#ccc' }} id="idprospecto"> Tipo de Prospecto</InputLabel>
                                    <Select
                                       labelId="tipo_prospecto"
                                       id="tipo_prospecto"
                                       size="small"
                                       fullWidth
                                       name="tipo_prospecto"
                                       value={tipo_prospecto}
                                       label="Tipo de Prospecto"
                                       onChange={handleInputChange}
                                    >
                                       {dataTipoProspecto.map((option, i) => {
                                          return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                       })
                                       }
                                    </Select>
                                    <FormHelperText>{error === "idprioridad" ? "Prioridad es un campo requerido" : ""}</FormHelperText>
                                 </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                 <TextField
                                    fullWidth
                                    error={error === "rut_empresa" ? true : false}
                                    helperText={error === "rut_empresa" ? errorRut : ""}
                                    size="small"
                                    id="rut_empresa"
                                    placeholder="Ingrese el rut de la empresa a asociar"
                                    sx={{ bgcolor: '#fff' }}
                                    name="rut_empresa"
                                    type="text"
                                    value={rut_empresa}
                                    onChange={handleInputChange}
                                    onBlur={handleInputBlur}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <TextField
                                    fullWidth
                                    error={error === "password" ? true : false}
                                    helperText={error === "password" ? "La contraseña es un campo requerido" : ""}
                                    size="small"
                                    id="password"
                                    placeholder="Ingrese su contraseña"
                                    sx={{ bgcolor: '#fff' }}
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                       endAdornment: (
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={() => { setShowPassword(!showPassword) }}
                                          >
                                             {showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                          </IconButton>
                                       ),
                                    }}
                                    value={password}
                                    onChange={handleInputChange}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <TextField
                                    fullWidth
                                    error={error === "repit_password" ? true : false}
                                    helperText={error === "repit_password" ? "Debe repitar la contraseña" : ""}
                                    size="small"
                                    id="repit_password"
                                    placeholder="Repita su contraseña"
                                    sx={{ bgcolor: '#fff' }}
                                    name="repit_password"
                                    type={showPasswordRepit ? "text" : "password"}
                                    InputProps={{
                                       endAdornment: (
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={() => { setShowPasswordRepit(!showPasswordRepit) }}
                                          >
                                             {showPasswordRepit ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
                                          </IconButton>
                                       ),
                                    }}
                                    value={repit_password}
                                    onChange={handleInputChange}
                                 />
                              </Grid>
                              <Grid item xs={12}>
                                 <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                       fullWidth={true}
                                       type='submit'
                                       variant='contained'
                                       disabled={loading}
                                       sx={{
                                          background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
                                             bgcolor: '#E4980D'
                                          }
                                       }}
                                       disableElevation
                                    >
                                       <span>Registrese</span>
                                    </Button>
                                    {loading && (
                                       <CircularProgress
                                          size={24}
                                          sx={{
                                             position: 'absolute',
                                             top: '50%',
                                             left: '50%',
                                             marginTop: '-12px',
                                             marginLeft: '-12px',
                                          }}
                                       />
                                    )}
                                 </Box>
                              </Grid>
                           </Grid>
                        </form>
                     </Grid>
                     <Snackbar
                        open={snackBarConfig.open}
                        autoHideDuration={snackBarConfig.autoHideDuration}
                        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                     >
                        <Alert
                           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                           severity={snackBarConfig.severity}
                           variant="filled"
                        >
                           {snackBarConfig.message}
                        </Alert>
                     </Snackbar>
                  </Box>
               </Grid>
            </Grid>
         </ThemeProvider>
         <ModalRegisterDocument
            open={open}
            setOpen={setOpen}
            dataCliente={formRegister}
            idRut={idRut}
         />
      </>
   )
}
