import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Contact.type';
//import { Loading } from "@components/common/Loading";
import { FormControl, Autocomplete, Select, MenuItem, FormHelperText, Container, Divider, Grid, InputLabel, Typography, TextField, Button, Alert, Snackbar, IconButton, Card, Backdrop, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBackIconv2, PencilIcon, CancelIcon, SaveIcon, EditContactIcon } from "@toolbox/constants/icons";
import { contactService } from '@/service/services/Contact.service';
import { ubigeoService } from '@/service/services/Ubigeo.service';
import { taskService } from '@/service/services/Task.service';
import { useHistory } from 'react-router-dom'
import { ROUTE_CONTACT } from '@toolbox/constants/route-map';
import { DataClients } from '@service/models/Contact'
import { Data } from '@/service/models/User';
import { TabsComponent } from '@/components/common/Tabs';
import { CardActividad } from './components/Card';
import { FormTask } from './components/Form';
import { ConfirmDialog } from '@/components/common/DialogConfirm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from "@mui/styles";
import { SpinnerGrow } from '@/components/common/Spinner';
import logokyte from "@assets/img/vacio.png";
import { ModalAddTaskAndCall } from "@/components/common/Modal/ModalAddTaskAndCall";
import { ModalDetalleCompra } from "@/components/common/Modal/ModalDetalleCompra";
import moment from "moment";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_EMPRESA, KEY_SOFTNET, KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ModalAddContact } from '@/components/common/Modal/ModalAddContact';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { bitacoraService } from '@/service/services/Bitacora.service';
import { CardBitacora } from './components/Bitacora';
import { ModalTomarNota } from '@/components/common/Modal/ModalTomarApuntes';
import { ModalCorreoEnviados } from '@/components/common/Modal/ModalCorreosEnviados';

const initialFormValuesContact = {
   rut_cliente: '',
   idtipopersona: '',
   idtiporubro: '',
   nombre_rz: '',
   nombre_fantasia: '',
   giro: '',
   fax: '',
   prospecto: '',
   observacion: '',
   extranjero: '',
   web: '',
   telefono: '',
   email: '',
   direccion: '',
   cto_nombre: '',
   cto_cargo: '',
   cto_email: '',
   cto_telefono: '',
   cto_celular: '',
   cto_cobranza: '',
   is_prospecto: '',
}
const labels = [{ label: 'Tareas' }
   , { label: 'Llamadas entrantes' }
   , { label: 'Bitacora' }
]

export const CreateContactView: React.FC<Props> = (props: Props): JSX.Element => {
   const history = useHistory();
   const [openView, setOpenView] = useState(false);
   const [openAddTask, setOpenAddTask] = useState(false);
   const [contactos, setContactos] = useState<any>([]);
   const [IdCliente, setIdCliente] = useState<number>(props.contact.idcliente);
   const [sentDateData, setSentDateData] = useState(
      {
         id: "",
         idtipofeeds: 0,
         idprioridad: 0,
         idcliente: "",
         fechacreacion: moment().format('YYYY-MM-DD'),
         horainicio: moment().format('HH:mm'),
         fechacierre: moment().format('YYYY-MM-DD'),
         horafin: moment().add(30, 'm').format('HH:mm'),
         comentarios: "",
         idcampananegocio: "",
         idfrecuencia: "",
         tipo: ''
      }
   )
   const [openModalDetalleCompra, setOpenModalDetalleCompra] = useState(false);
   const [comprasSync, setComprasSync] = useState<any>([{}]);
   const [compraSelect, setCompraSelect] = useState(null);
   const [Received, setReceived] = useState(false);
   const [idpais, setIdPais] = useState(null);
   const [idregion, setIdRegion] = useState(null);
   const [idprovincia, setIdProvincia] = useState(null);
   const [idcomuna, setIdComuna] = useState(null);
   const [ubigeo, setUbigeo] = useState<any>({ pais: [], region: [], provincia: [], comuna: [] });
   const [error, setError] = useState<string>("");
   const [taskContact, setTasksContact] = useState<any>([{}]);
   const [taskContactNotCompleted, setTasksContactNotCompleted] = useState<any>([{}]);
   const [taskContactCall, setTasksContactCall] = useState<any>([{}]);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [filter, setFilter] = useState(null);
   const [openHiloCorreo, setOpenHiloCorreo] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [dataFilter, setDataFilter] = useState<any>([]);
   const [Dialog, setDialog] = useState<any>({
      open: false,
      title: 'Eliminar',
      confirm: false,
      taskId: null,
      message: `¿Desea eliminar al contacto ALDAIR con Rut 22222?`
   })
   const [confirmDelete, setConfirmDelete] = useState(false)
   const [typeRubro, setTypeRubro] = useState<any>([]);
   const [typePersona, setTypePersona] = useState<any>([]);
   const [formValuesContact, setFormValuesContact] = useState<any>(initialFormValuesContact);
   const { rut_cliente, idtipopersona, idtiporubro, nombre_rz, nombre_fantasia
      , giro, fax, prospecto, observacion, extranjero, web, direccion, telefono, email, is_prospecto, cto_nombre, cto_cargo, cto_email,
      cto_telefono, cto_celular, cto_cobranza } = formValuesContact

   const [OpenAddContact, setOpenAddContact] = useState(false);
   const [ValueContact, setValueContact] = useState({});
   const [openModalNotas, setOpenModalNotas] = useState(false);
   const [estado, setEstado] = useState(false)
   const useStyles = makeStyles({
      customDisable: {
         "& .MuiInputBase-input.Mui-disabled": {
            color: "red !important",
            "-webkit-text-fill-color": "black !important",
            borderColor: "red !important"
         },
      }
   });
   const classes = useStyles();
   const [bitacora, setBitacora] = useState<any>([{}]);
   useEffect(() => {
      getDataInitial();
      console.log(props.contact)
      getContatosbyCliente(props.contact.idcliente);
      if (props.contact.action) {
         if (props.contact.action == 'view') {
            setIsDisabled(true);
            getFeedsByClient(props.contact.idcliente);
            getFeedsCallByClient(props.contact.idcliente);
            getComprasByCliente(props.contact.idcliente);
            getBitacora(props.contact.idcliente);
         }
         // setFormValuesContact(props.contact)//se cambió, ahora dentro de na api res --> getContatosbyCliente
         props.contact.idcomuna && getDataUbigeo(props.contact.idcomuna)
         getPais();
      } else {

         // getPais();
      }
   }, [])

   async function getBitacora(id) {
      const resp = await bitacoraService.getBitacora(id);
      if(resp){
         setBitacora(resp.data)
      }
   }

   const onSelectPais = (pais) => {
      if (pais) {
         setError("")
         if (pais.id != null || pais.id != idpais.id) {
            setIdRegion(null)
            setUbigeo(prev => ({ ...prev, region: [], provincia: [], comuna: [] }));
            setIdProvincia(null)
            setIdComuna(null)
         }
         setIdPais(pais)
      }
   }
   const onSelectRegion = (region) => {
      if (region) {
         setError("")
         if (region.id != null || region.id != idregion.id) {
            setUbigeo(prev => ({ ...prev, provincia: [], comuna: [] }));
            setIdProvincia(null)
            setIdComuna(null)
         }
         setIdRegion(region)
      }
   }
   const onSelectProvincia = (provincia) => {
      if (provincia) {
         if (provincia.id != null || provincia.id != idprovincia.id) {
            setUbigeo(prev => ({ ...prev, comuna: [] }));
            setIdComuna(null)
         }
         setIdProvincia(provincia)
      }
   }
   const onSelectComuna = (idcomuna) => {
      if (idcomuna) {
         setError("")
         setIdComuna(idcomuna)
      }
   }
   async function getContatosbyCliente(idcliente) {
      const resp = await contactService.getContatosbyCliente(idcliente);
      if(resp)
      {
      setFormValuesContact(resp.data)
      setContactos(resp.data.contactos)
      }
   }
   async function getDataInitial() {
      const resp = await contactService.getDataInitial();
      setTypeRubro(resp.data.entry_type);
      setTypePersona(resp.data.person_type);
   }
   //kent
   async function getCampaingbyCliente() {
      const resp = await contactService.getCampaingbyCliente(props.contact.idcliente);
   }

   async function getFeedsByClient(id) {
      setOpenView(true)
      const resp = await taskService.getFeedsByClient(id);
      setFilter(0)
      setDataFilter(resp.data?.NoCompletados);
      setTasksContact(resp.data?.Completados)
      setTasksContactNotCompleted(resp.data?.NoCompletados)
      setOpenView(false)
   }
   async function getFeedsCallByClient(id) {
      const resp = await taskService.getFeedsCallByClient(id);
      setTasksContactCall(resp.data)
   }

   async function setDataContact(data) {
      const resp = await contactService.createContactbyClient(data)
      const ChangeContact = [
         ...contactos,
         resp.data
      ]
      setContactos(ChangeContact)
      if (resp.data) {
         getContatosbyCliente(props.contact.idcliente);
         setSnackBarConfig(prev => ({
            ...prev,
            severity: 'success',
            open: true,
            message: 'Contacto creado con éxito',
         }));
      }
   }

   const updateContact = async (data) => {
      const resp = await contactService.updateContactbyClient(data.id, data);
      setOpenAddContact(false)
      setValueContact({})
      if (resp.data) {
         getContatosbyCliente(props.contact.idcliente);
         setSnackBarConfig(prev => ({
            ...prev,
            severity: 'success',
            open: true,
            message: 'Contacto actualizado con éxito',
         }));
      }
   }

   const deleteContactbyClient = async () => {
      try {
         if (Dialog.confirm == true) {
            const resp = await contactService.deleteContactbyClient(Dialog.contactId);
            if (resp.data) {
               getContatosbyCliente(props.contact.idcliente);
               setSnackBarConfig(prev => ({
                  ...prev,
                  severity: 'success',
                  open: true,
                  message: 'Contacto eliminado con éxito',
               }));
            }
         }
      } catch (e) {
         console.log(e)
      }
   }

   const contactId = async (data) => {
      try {
         const contactId = data.id;
         setDialog(prev => ({ ...prev, message: `¿Desea eliminar al contacto ${data.nombre}?`, contactId: contactId, open: true, confirm: true }));
      } catch (e) {
         console.log(e)
      }
   };

   async function getDataUbigeo(idComuna: number) {
      const resp = await ubigeoService.getUbigeo(idComuna);
      getPais();
      setIdPais({ id: resp.data[0].idpais, pais: resp.data[0].pais })
      getRegion(resp.data[0].idpais)
      setIdRegion({ id: resp.data[0].idregion, region: resp.data[0].region })
      getProvincia(resp.data[0].idregion)
      setIdProvincia({ id: resp.data[0].idprovincia, provincia: resp.data[0].provincia })
      getComuna(resp.data[0].idprovincia)
      setIdComuna({ id: resp.data[0].idcomuna, comuna: resp.data[0].comuna })
   }
   async function getPais() {
      const resp = await ubigeoService.getPais();
      setUbigeo(prev => ({ ...prev, pais: resp.data?.pais }));
   }
   async function getRegion(idPais: number) {
      const resp = await ubigeoService.getRegion(idPais);
      setUbigeo(prev => ({ ...prev, region: resp.data?.region }));
   }
   async function getProvincia(idregion: number) {
      const resp = await ubigeoService.getProvincia(idregion);

      setUbigeo(prev => ({ ...prev, provincia: resp.data?.province }));
   }
   async function getComuna(idprovincia: number) {
      const resp = await ubigeoService.getComuna(idprovincia);

      setUbigeo(prev => ({ ...prev, comuna: resp.data?.comuna }));
   }
   const handleInputChange = (e) => {
      const changeFormValuesContact = {
         ...formValuesContact,
         [e.target.name]: e.target.value,
      }
      // changeFormValuesContact.idtiporubro !== null && setError("");
      // changeFormValuesContact.rut_cliente.trim() !== "" && setError("");
      // changeFormValuesContact.nombre_rz.trim() !== "" && setError("");
      // changeFormValuesContact.nombre_fantasia.trim() !== "" && setError("");
      // changeFormValuesContact.giro.trim() !== "" && setError("");
      // changeFormValuesContact.fax.trim() !== "" && setError("");
      // changeFormValuesContact.prospecto !== null && setError("");
      // changeFormValuesContact.observacion.trim() !== "" && setError("");
      // changeFormValuesContact.extranjero !== null && setError("");
      // changeFormValuesContact.web.trim() !== "" && setError("");
      // changeFormValuesContact.direccion.trim() !== "" && setError("");
      // changeFormValuesContact.cto_cargo.trim() !== "" && setError("");
      // changeFormValuesContact.cto_email.trim() !== "" && setError("");
      // changeFormValuesContact.cto_telefono.trim() !== "" && setError("");
      // changeFormValuesContact.cto_celular.trim() !== "" && setError("");
      // changeFormValuesContact.cto_cobranza.trim() !== "" && setError("");
      setFormValuesContact(changeFormValuesContact)
   }
   const handleSubmitContact = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // if (idtiporubro === 0) { return setError("idtiporubro"); }
      // if (!rut_cliente.trim()) { return setError("rut_cliente"); }
      // if (!nombre_rz.trim()) { return setError("nombre_rz"); }
      // if (!nombre_fantasia.trim()) { return setError("nombre_fantasia"); }
      // if (!giro.trim()) { return setError("giro"); }
      // if (!fax.trim()) { return setError("fax"); }
      // if (!prospecto === null) { return setError("prospecto"); }
      // if (!observacion.trim()) { return setError("observacion"); }
      // if (!extranjero === null) { return setError("extranjero"); }
      // if (!web.trim()) { return setError("web"); }
      // if (!direccion.trim()) { return setError("direccion"); }
      // // if (!cto_cargo.trim()) { return setError("cto_cargo"); }
      // // if (!cto_email.trim()) { return setError("cto_email"); }
      // // if (!cto_telefono.trim()) { return setError("cto_telefono"); }
      // // if (!cto_celular.trim()) { return setError("cto_celular"); }
      // // if (!cto_cobranza.trim()) { return setError("cto_cobranza"); }
      // if (idpais === null) { return setError("pais") }
      // if (idregion === null) { return setError("region") }
      // if (idprovincia === null) { return setError("provincia") }
      // if (idcomuna === null) { return setError("comuna") }
      // else {
         const dataContact = {
            rut_cliente: rut_cliente,
            nombre_rz: nombre_rz,
            nombre_fantasia: nombre_fantasia,
            giro: giro,
            // fax: fax,
            prospecto: prospecto,
            // observacion: observacion,
            // extranjero: extranjero,
            // web: web,
            direccion: direccion,
            telefono: telefono,
            email: email,
            id_comuna: idcomuna.id,
            // id_tipopersona: idtipopersona,
            // id_tiporubro: idtiporubro,

            // cto_cargo: cto_cargo,
            // cto_email: cto_email,
            // cto_telefono: cto_telefono,
            // cto_celular: cto_celular,
            // cto_cobranza: cto_cobranza
         }
         console.log(dataContact);
         // if (idtipopersona === "") {
         //    const respRut = await contactService.verifyRut(rut_cliente);

         //    if (respRut.data == false) {
         //       let resp;
         //       if (props.contact) {

         //          resp = await contactService.updateContact(props.contact.idcliente, dataContact);
         //       } else {
         //          resp = await contactService.createContact(dataContact);

         //       }

         //       if (!!resp.data) {
         //          history.push(ROUTE_CONTACT);
         //       }
         //    }
         //    else {
         //       setSnackBarConfig(prev => ({
         //          ...prev,
         //          open: true,
         //          message: 'El rut de la empresa que ingreso ya está ocupado'
         //       }))
         //    }
         // } else {
            // let resp;
            // if (props.contact) {

            //    resp = await contactService.updateContact(props.contact.idcliente, dataContact);
            //    if (!!resp.data) {
            //       setIsDisabled(true)
            //       setSnackBarConfig(prev => ({
            //                   ...prev,
            //                   open: true,
            //                   severity: 'success',
            //                   message: 'Actualización exitosa'
            //                }))
            //    }
            // }
            //  else {
            //    resp = await contactService.createContact(dataContact);

            //    if (!!resp.data) {
            //       history.push(ROUTE_CONTACT);
            //    }
            // }


         // }
      // }
   }
   const taskAdd = async (task) => {

      try {
         //setOpenView2(true);
         if (task.idfrecuencia) {

            let FechaFinalCampaign;


            if (task.campana) {
               FechaFinalCampaign = moment(task.campana.fecha_cierre).format('YYYY-MM-DD')

            } else {
               FechaFinalCampaign = moment('2022-04-04').format('YYYY-MM-DD')

            }

            let FechaInicialAccion = moment(task.fechacreacion).format('YYYY-MM-DD')
            let FechaFinalAction = moment(task.fechacierre).format('YYYY-MM-DD')


            let resp;

            while (FechaFinalAction < FechaFinalCampaign) {

               resp = await taskService.createFeeds(task);
               // if (resp.data) {
               //    const changedAction = [resp.data, ...actions];
               //    setActions(changedAction);

               // }
               if (task.idfrecuencia == 1) {
                  FechaFinalAction = moment(FechaFinalAction).add(1, 'w').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(1, 'w').format('YYYY-MM-DD')
               }
               if (task.idfrecuencia == 2) {
                  FechaFinalAction = moment(FechaFinalAction).add(4, 'd').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(4, 'd').format('YYYY-MM-DD')

               }
               if (task.idfrecuencia == 3) {
                  FechaFinalAction = moment(FechaFinalAction).add(1, 'M').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(1, 'M').format('YYYY-MM-DD')

               }
               if (task.idfrecuencia == 4) {
                  FechaFinalAction = moment(FechaFinalAction).add(15, 'd').format('YYYY-MM-DD')
                  FechaInicialAccion = moment(FechaInicialAccion).add(15, 'd').format('YYYY-MM-DD')

               }

               if (task.horainicio === "00:00" && task.horafin === '00:00') {
                  task.fechacreacion = FechaInicialAccion;
                  task.fechacierre = FechaFinalAction;
               } else {
                  task.fechacreacion = `${FechaInicialAccion} ${task.horainicio}`;
                  task.fechacierre = `${FechaFinalAction} ${task.horafin}`;
               }


            }
            setOpenAddTask(false);
            //setOpenView2(false);
            getFeedsByClient(props.contact.idcliente);
         } else {//props.contact.idcliente
            const resp = await taskService.createFeeds({
               idtipofeeds: task.idtipofeeds,
               idprioridad: task.idprioridad,
               idusuario: task.idusuario,
               idcliente: props.contact.idcliente,
               horainicio: task.horainicio,
               horafin: task.horafin,
               comentarios: task.comentarios,
               idcampananegocio: task.idcampananegocio,
               form_cliente: task.form_cliente,
               campana: task.campana,
               idfrecuencia: task.idfrecuencia,
               fechacreacion: task.fechacreacion,
               fechacierre: task.fechacierre,
               propietario: task.propietario,
               received: task.received,
            });
            if (resp.data) {
               getFeedsByClient(props.contact.idcliente);
               getFeedsCallByClient(props.contact.idcliente);
               setOpenAddTask(false);
               //setOpenView2(false);
            }
         }


      } catch (e) {
         console.log(e)
      }

   };


   const SincronizarCompras = async () => {
      setOpenView(true);
      const resp: any = await contactService.postCompraDetalleByContact(props.contact.idcliente);
      if (resp.status == 200) {
         getComprasByCliente(props.contact.idcliente);
         setOpenView(false);
         setSnackBarConfig(prev => ({
            ...prev,
            severity: 'success',
            open: true,
            message: 'Compras Sincronizadas con éxito'
         }))
         setOpenView(false)
      } else {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: resp.message
         }))
         setOpenView(false)
      }

   }

   const getComprasByCliente = async (id_cliente) => {
      const resp = await contactService.getComprasbyClient(id_cliente);
      setComprasSync(resp.data);

   }

   const deleteTaskContact = () => {
      if (Dialog.confirm == true) {
         console.log('REGISTRO ELIMINADO')
         // const changeTask = taskContact.filter(task => task.id !== Dialog.taskId);
         // setTasksContact(changeTask)
      }
   }

   const handleTask = (e) => {
      setFilter(e.target.value)
      switch (e.target.value) {
         case 0:
            setDataFilter(taskContactNotCompleted);
            break;
         case 1:
            setDataFilter(taskContact);
            break;
         // const [taskContact
         // const [taskContactNotCompleted,
      }
   }

   const idTask = (taskId) => {
      setDialog(prev => ({ ...prev, message: `¿Desea eliminar la tarea?`, open: true, taskId: taskId, confirm: true }));
   }
   const formularioPrincipal = <form
   //onSubmit={handleSubmitContact}
   >
      <Grid container direction="row" spacing={2} >
         {/* <Grid item
            xs={12} md={props.contact == undefined ? 4 : 12}>
            <FormControl fullWidth >
               <InputLabel id="demo-simple-select-label" >Tipo de Persona</InputLabel>
               <Select
                  label="Tipo de Persona"
                  id="idtipopersona"
                  sx={{ bgcolor: '#E3F4FD' }}
                  onChange={handleInputChange}
                  className={classes.customDisable}
                  disabled={isDisabled}
                  value={idtipopersona}
                  name='idtipopersona'
                  size='small'
               >
                  {typePersona.map((option, i) => {
                     return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                  })
                  }
               </Select>
            </FormControl>
         </Grid> */}
         {/* <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <FormControl fullWidth error={error === "idtiporubro" ? true : false}>
               <InputLabel id="demo-simple-select-label">Tipo de Rubro</InputLabel>
               <Select
                  label="Tipo de Rubro"
                  id="idtiporubro"
                  sx={{ bgcolor: '#E3F4FD' }}
                  onChange={handleInputChange}
                  className={classes.customDisable}
                  disabled={isDisabled}
                  value={idtiporubro}
                  name='idtiporubro'
                  size='small'
               >
                  {typeRubro.map((option, i) => {
                     return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
                  })
                  }
               </Select>
               <FormHelperText>{error === "idtiporubro" ? "Tipo Rubro es un campo requerido" : ""}</FormHelperText>
            </FormControl>
         </Grid> */}
         <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='rut_cliente'
               name='rut_cliente'
               label="Rut Cliente"
               sx={{ bgcolor: '#E3F4FD' }}
               fullWidth
               error={error === "rut_cliente" ? true : false}
               helperText={error === "rut_cliente" ? "Rut cliente es un campo requerido" : ""}
               value={rut_cliente}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>
         <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='nombre_rz'
               name='nombre_rz'
               label="Razón Social"
               sx={{ bgcolor: '#E3F4FD' }}
               fullWidth
               error={error === "nombre_rz" ? true : false}
               helperText={error === "nombre_rz" ? "Razón Social es un campo requerido" : ""}
               value={nombre_rz}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid >
         {nombre_fantasia && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='nombre_fantasia'
               name='nombre_fantasia'
               label="Nombre Fantasia"
               sx={{ bgcolor: '#E3F4FD' }}
               fullWidth
               error={error === "nombre_fantasia" ? true : false}
               helperText={error === "nombre_fantasia" ? "Nombre Fantasia es un campo requerido" : ""}
               value={nombre_fantasia}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid >}
         <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='giro'
               name='giro'
               label="Giro/Profesión"
               sx={{ bgcolor: '#E3F4FD' }}
               fullWidth
               error={error === "giro" ? true : false}
               helperText={error === "giro" ? "Giro es un campo requerido" : ""}
               value={giro}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>
         {telefono && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
            <TextField
               size='small'
               id='telefono'
               name='telefono'
               label="Telefono"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               // error={error === "fax" ? true : false}
               // helperText={error === "fax" ? "Fax es un campo requerido" : ""}
               value={telefono}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {email && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
            <TextField
               size='small'
               id='email'
               name='email'
               label="Email"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               // error={error === "fax" ? true : false}
               // helperText={error === "fax" ? "Fax es un campo requerido" : ""}
               value={email}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {/* {prospecto && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <FormControl fullWidth error={error === "prospecto" ? true : false}>
               <InputLabel id="demo-simple-select-label">Prospecto</InputLabel>
               <Select
                  id='prospecto'
                  name='prospecto'
                  label="Prospecto"
                  // onChange={handleInputChange}
                  className={classes.customDisable}
                  disabled={isDisabled}
                  sx={{ bgcolor: '#E3F4FD' }}
                  value={prospecto}
                  size='small'>
                  <MenuItem key={1} value={1}>{"SI"}</MenuItem>
                  <MenuItem key={2} value={0}>{"NO"}</MenuItem>
               </Select>
               <FormHelperText>{error === "prospecto" ? "Prospecto es un campo requerido" : ""}</FormHelperText>
            </FormControl>
         </Grid>} */}
         {/* {observacion && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
            <TextField
               size='small'
               id='observacion'
               name='observacion'
               label="Observacion"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "observacion" ? true : false}
               helperText={error === "observacion" ? "Observación es un campo requerido" : ""}
               value={observacion}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {extranjero && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <FormControl fullWidth error={error === "extranjero" ? true : false}>
               <InputLabel id="demo-simple-select-label">Extranjero</InputLabel>
               <Select
                  id='extranjero'
                  name='extranjero'
                  label="Extranjero"
                  onChange={handleInputChange}
                  className={classes.customDisable}
                  sx={{ bgcolor: '#E3F4FD' }}
                  disabled={isDisabled}
                  value={extranjero}
                  size='small'>
                  <MenuItem key={1} value={1}>{"SI"}</MenuItem>
                  <MenuItem key={2} value={0}>{"NO"}</MenuItem>
               </Select>
               <FormHelperText>{error === "extranjero" ? "Extranjero es un campo requerido" : ""}</FormHelperText>
            </FormControl>
         </Grid>} */}
         {web && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
            <TextField
               size='small'
               id='web'
               name='web'
               label="Web"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "web" ? true : false}
               helperText={error === "web" ? "Web es un campo requerido" : ""}
               value={web}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {direccion && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='direccion'
               name='direccion'
               label="Dirección*"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "direccion" ? true : false}
               helperText={error === "direccion" ? "Dirección es un campo requerido" : ""}
               value={direccion}
               // onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {/* {cto_nombre && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='cto_nombre'
               name='cto_nombre'
               label="Nombre contacto"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "cto_nombre" ? true : false}
               helperText={error === "cto_nombre" ? "Nombre es un campo requerido" : ""}
               value={cto_nombre}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {cto_cargo && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='cto_cargo'
               name='cto_cargo'
               label="Cargo contacto"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "cto_cargo" ? true : false}
               helperText={error === "cto_cargo" ? "Cargo es un campo requerido" : ""}
               value={cto_cargo}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}

         {cto_email && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='cto_email'
               name='cto_email'
               label="Email contacto"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "cto_email" ? true : false}
               helperText={error === "cto_email" ? "Email es un campo requerido" : ""}
               value={cto_email}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {cto_telefono && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='cto_telefono'
               name='cto_telefono'
               label="Teléfono contacto"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "cto_telefono" ? true : false}
               helperText={error === "cto_telefono" ? "Telefono es un campo requerido" : ""}
               value={cto_telefono}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
         {cto_celular && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
            <TextField
               size='small'
               id='cto_celular'
               name='cto_celular'
               label="Celular contacto"
               fullWidth
               sx={{ bgcolor: '#E3F4FD' }}
               error={error === "cto_celular" ? true : false}
               helperText={error === "cto_celular" ? "Celular es un campo requerido" : ""}
               value={cto_celular}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>} */}

         {/* {cto_cobranza && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <TextField
               size='small'
               id='cto_cobranza'
               name='cto_cobranza'
               label="Cobranza"
               sx={{ bgcolor: '#E3F4FD' }}
               fullWidth
               error={error === "cto_cobranza" ? true : false}
               helperText={error === "cto_cobranza" ? "Cobranza es un campo requerido" : ""}
               value={cto_cobranza}
               onChange={handleInputChange}
               className={classes.customDisable}
               disabled={isDisabled}
            />
         </Grid>}
          */}
         {idpais && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <Autocomplete
               className={classes.customDisable}
               disabled={isDisabled}
               autoComplete
               size='small'
               includeInputInList
               value={idpais}
               sx={{ bgcolor: '#E3F4FD' }}
               onChange={(e, pais) => {
                  pais && getRegion(pais.id);
                  onSelectPais(pais)
               }}
               id="idpais"
               options={ubigeo.pais}
               getOptionLabel={(option) => option.pais}
               renderInput={(params) => <TextField {...params} label="Pais*" error={error === "pais" ? true : false} helperText={error === "pais" ? "Pais es un campo requerido" : ""} />}
            />
         </Grid>}
         {idregion && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <Autocomplete
               className={classes.customDisable}
               disabled={isDisabled}
               value={idregion}
               size='small'
               sx={{ bgcolor: '#E3F4FD' }}
               onChange={(e, region) => {
                  region && getProvincia(region.id);
                  onSelectRegion(region)
               }}
               id="idregion"
               options={ubigeo.region && ubigeo.region}
               getOptionLabel={(option) => option.region ? option.region : ""}
               renderInput={(params) => <TextField {...params} label="Region*" error={error === "region" ? true : false} helperText={error === "region" ? "Region es un campo requerido" : ""} />}
            />
         </Grid>}
         {idprovincia && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <Autocomplete
               className={classes.customDisable}
               disabled={isDisabled}
               value={idprovincia}
               size='small'
               sx={{ bgcolor: '#E3F4FD' }}
               onChange={(e, provincia) => {
                  provincia && getComuna(provincia.id);
                  onSelectProvincia(provincia)
               }}
               id="idprovincia"
               options={ubigeo.provincia && ubigeo.provincia}
               getOptionLabel={(option) => option.provincia ? option.provincia : ""}
               renderInput={(params) => <TextField {...params} label="Provincia*" error={error === "provincia" ? true : false} helperText={error === "provincia" ? "Provincia es un campo requerido" : ""} />}
            />
         </Grid>}
         {idcomuna && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
            <Autocomplete
               className={classes.customDisable}
               disabled={isDisabled}
               value={idcomuna}
               size='small'
               sx={{ bgcolor: '#E3F4FD' }}
               onChange={(e, comuna) => {
                  onSelectComuna(comuna)
               }}
               id="idcomuna"
               options={ubigeo.comuna && ubigeo.comuna}
               getOptionLabel={(option) => option.comuna ? option.comuna : ""}
               renderInput={(params) => <TextField {...params} label="Comuna*" error={error === "comuna" ? true : false} helperText={error === "comuna" ? "Comuna es un campo requerido" : ""} />}
            />
         </Grid>}


         {/* {isDisabled == false &&
            <Grid container item xs={12} md={12} spacing={1} justifyContent='right'  >
               <Grid item xs={12} md={props.contact == undefined ? 2 : 6}>
                  <Button
                     fullWidth
                     variant='contained'
                     color='error'
                     endIcon={props.contact == undefined ? <CancelIcon /> : ''}
                     onClick={() => {
                        if (props.contact == undefined) {
                           history.push(ROUTE_CONTACT)
                        } else {
                           setFormValuesContact(props.contact);
                           setIsDisabled(true);
                        }
                     }}
                  >
                     Cancelar
                  </Button>
               </Grid>
               <Grid item xs={12} md={props.contact == undefined ? 2 : 6}>
                  <Button
                     fullWidth
                     variant='contained'
                     endIcon={props.contact == undefined ? <SaveIcon /> : ''}
                     type='submit'>
                     {props.contact == undefined ? "Guardar" : "Actualizar"}
                  </Button>
               </Grid>
            </Grid>
         } */}
      </Grid>
   </form>

const formulario = <form onSubmit={handleSubmitContact} >
<Grid container direction="row" spacing={2} >
   {/* <Grid item
      xs={12} md={props.contact == undefined ? 4 : 12}>
      <FormControl fullWidth >
         <InputLabel id="demo-simple-select-label" >Tipo de Persona</InputLabel>
         <Select
            label="Tipo de Persona"
            id="idtipopersona"
            sx={{ bgcolor: '#E3F4FD' }}
            onChange={handleInputChange}
            className={classes.customDisable}
            disabled={isDisabled}
            value={idtipopersona}
            name='idtipopersona'
            size='small'
         >
            {typePersona.map((option, i) => {
               return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
            })
            }
         </Select>
      </FormControl>
   </Grid> */}
   {/* <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <FormControl fullWidth error={error === "idtiporubro" ? true : false}>
         <InputLabel id="demo-simple-select-label">Tipo de Rubro</InputLabel>
         <Select
            label="Tipo de Rubro"
            id="idtiporubro"
            sx={{ bgcolor: '#E3F4FD' }}
            onChange={handleInputChange}
            className={classes.customDisable}
            disabled={isDisabled}
            value={idtiporubro}
            name='idtiporubro'
            size='small'
         >
            {typeRubro.map((option, i) => {
               return <MenuItem key={i} value={option.id}>{option.concepto}</MenuItem>
            })
            }
         </Select>
         <FormHelperText>{error === "idtiporubro" ? "Tipo Rubro es un campo requerido" : ""}</FormHelperText>
      </FormControl>
   </Grid> */}
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='rut_cliente'
         name='rut_cliente'
         label="Rut Cliente"
         sx={{ bgcolor: '#E3F4FD' }}
         fullWidth
         error={error === "rut_cliente" ? true : false}
         helperText={error === "rut_cliente" ? "Rut cliente es un campo requerido" : ""}
         value={rut_cliente}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='nombre_rz'
         name='nombre_rz'
         label="Razón Social"
         sx={{ bgcolor: '#E3F4FD' }}
         fullWidth
         error={error === "nombre_rz" ? true : false}
         helperText={error === "nombre_rz" ? "Razón Social es un campo requerido" : ""}
         value={nombre_rz}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid >
    <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='nombre_fantasia'
         name='nombre_fantasia'
         label="Nombre Fantasia"
         sx={{ bgcolor: '#E3F4FD' }}
         fullWidth
         error={error === "nombre_fantasia" ? true : false}
         helperText={error === "nombre_fantasia" ? "Nombre Fantasia es un campo requerido" : ""}
         value={nombre_fantasia}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid >
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='giro'
         name='giro'
         label="Giro"
         sx={{ bgcolor: '#E3F4FD' }}
         fullWidth
         error={error === "giro" ? true : false}
         helperText={error === "giro" ? "Giro es un campo requerido" : ""}
         value={giro}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>
    <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
      <TextField
         size='small'
         id='telefono'
         name='telefono'
         label="Telefono"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         // error={error === "fax" ? true : false}
         // helperText={error === "fax" ? "Fax es un campo requerido" : ""}
         value={telefono}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>
    <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
      <TextField
         size='small'
         id='email'
         name='email'
         label="Email"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         // error={error === "fax" ? true : false}
         // helperText={error === "fax" ? "Fax es un campo requerido" : ""}
         value={email}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>
    <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <FormControl fullWidth error={error === "prospecto" ? true : false}>
         <InputLabel id="demo-simple-select-label">Prospecto</InputLabel>
         <Select
            id='prospecto'
            name='prospecto'
            label="Prospecto"
            onChange={handleInputChange}
            className={classes.customDisable}
            disabled={isDisabled}
            sx={{ bgcolor: '#E3F4FD' }}
            value={prospecto}
            size='small'>
            <MenuItem key={1} value={'1'}>{"SI"}</MenuItem>
            <MenuItem key={2} value={'0'}>{"NO"}</MenuItem>
         </Select>
         <FormHelperText>{error === "prospecto" ? "Prospecto es un campo requerido" : ""}</FormHelperText>
      </FormControl>
   </Grid>
   {/* {observacion && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
      <TextField
         size='small'
         id='observacion'
         name='observacion'
         label="Observacion"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "observacion" ? true : false}
         helperText={error === "observacion" ? "Observación es un campo requerido" : ""}
         value={observacion}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>}
   {extranjero && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <FormControl fullWidth error={error === "extranjero" ? true : false}>
         <InputLabel id="demo-simple-select-label">Extranjero</InputLabel>
         <Select
            id='extranjero'
            name='extranjero'
            label="Extranjero"
            onChange={handleInputChange}
            className={classes.customDisable}
            sx={{ bgcolor: '#E3F4FD' }}
            disabled={isDisabled}
            value={extranjero}
            size='small'>
            <MenuItem key={1} value={1}>{"SI"}</MenuItem>
            <MenuItem key={2} value={0}>{"NO"}</MenuItem>
         </Select>
         <FormHelperText>{error === "extranjero" ? "Extranjero es un campo requerido" : ""}</FormHelperText>
      </FormControl>
   </Grid>} */}
    {/* <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
      <TextField
         size='small'
         id='web'
         name='web'
         label="Web"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "web" ? true : false}
         helperText={error === "web" ? "Web es un campo requerido" : ""}
         value={web}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid> */}
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='direccion'
         name='direccion'
         label="Dirección*"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "direccion" ? true : false}
         helperText={error === "direccion" ? "Dirección es un campo requerido" : ""}
         value={direccion}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>
   {/* {cto_nombre && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='cto_nombre'
         name='cto_nombre'
         label="Nombre contacto"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "cto_nombre" ? true : false}
         helperText={error === "cto_nombre" ? "Nombre es un campo requerido" : ""}
         value={cto_nombre}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>}
   {cto_cargo && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='cto_cargo'
         name='cto_cargo'
         label="Cargo contacto"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "cto_cargo" ? true : false}
         helperText={error === "cto_cargo" ? "Cargo es un campo requerido" : ""}
         value={cto_cargo}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>}

   {cto_email && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='cto_email'
         name='cto_email'
         label="Email contacto"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "cto_email" ? true : false}
         helperText={error === "cto_email" ? "Email es un campo requerido" : ""}
         value={cto_email}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>}
   {cto_telefono && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='cto_telefono'
         name='cto_telefono'
         label="Teléfono contacto"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "cto_telefono" ? true : false}
         helperText={error === "cto_telefono" ? "Telefono es un campo requerido" : ""}
         value={cto_telefono}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>}
   {cto_celular && <Grid item xs={12} md={props.contact == undefined ? 4 : 12} >
      <TextField
         size='small'
         id='cto_celular'
         name='cto_celular'
         label="Celular contacto"
         fullWidth
         sx={{ bgcolor: '#E3F4FD' }}
         error={error === "cto_celular" ? true : false}
         helperText={error === "cto_celular" ? "Celular es un campo requerido" : ""}
         value={cto_celular}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>} */}

   {/* {cto_cobranza && <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <TextField
         size='small'
         id='cto_cobranza'
         name='cto_cobranza'
         label="Cobranza"
         sx={{ bgcolor: '#E3F4FD' }}
         fullWidth
         error={error === "cto_cobranza" ? true : false}
         helperText={error === "cto_cobranza" ? "Cobranza es un campo requerido" : ""}
         value={cto_cobranza}
         onChange={handleInputChange}
         className={classes.customDisable}
         disabled={isDisabled}
      />
   </Grid>}
    */}
    <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <Autocomplete
         className={classes.customDisable}
         disabled={isDisabled}
         autoComplete
         size='small'
         includeInputInList
         value={idpais}
         sx={{ bgcolor: '#E3F4FD' }}
         onChange={(e, pais) => {
            pais && getRegion(pais.id);
            onSelectPais(pais)
         }}
         id="idpais"
         options={ubigeo.pais}
         getOptionLabel={(option) => option.pais}
         renderInput={(params) => <TextField {...params} label="Pais*" error={error === "pais" ? true : false} helperText={error === "pais" ? "Pais es un campo requerido" : ""} />}
      />
   </Grid>
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <Autocomplete
         className={classes.customDisable}
         disabled={isDisabled}
         value={idregion}
         size='small'
         sx={{ bgcolor: '#E3F4FD' }}
         onChange={(e, region) => {
            region && getProvincia(region.id);
            onSelectRegion(region)
         }}
         id="idregion"
         options={ubigeo.region && ubigeo.region}
         getOptionLabel={(option) => option.region ? option.region : ""}
         renderInput={(params) => <TextField {...params} label="Region*" error={error === "region" ? true : false} helperText={error === "region" ? "Region es un campo requerido" : ""} />}
      />
   </Grid>
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <Autocomplete
         className={classes.customDisable}
         disabled={isDisabled}
         value={idprovincia}
         size='small'
         sx={{ bgcolor: '#E3F4FD' }}
         onChange={(e, provincia) => {
            provincia && getComuna(provincia.id);
            onSelectProvincia(provincia)
         }}
         id="idprovincia"
         options={ubigeo.provincia && ubigeo.provincia}
         getOptionLabel={(option) => option.provincia ? option.provincia : ""}
         renderInput={(params) => <TextField {...params} label="Provincia*" error={error === "provincia" ? true : false} helperText={error === "provincia" ? "Provincia es un campo requerido" : ""} />}
      />
   </Grid>
   <Grid item xs={12} md={props.contact == undefined ? 4 : 12}>
      <Autocomplete
         className={classes.customDisable}
         disabled={isDisabled}
         value={idcomuna}
         size='small'
         sx={{ bgcolor: '#E3F4FD' }}
         onChange={(e, comuna) => {
            onSelectComuna(comuna)
         }}
         id="idcomuna"
         options={ubigeo.comuna && ubigeo.comuna}
         getOptionLabel={(option) => option.comuna ? option.comuna : ""}
         renderInput={(params) => <TextField {...params} label="Comuna*" error={error === "comuna" ? true : false} helperText={error === "comuna" ? "Comuna es un campo requerido" : ""} />}
      />
   </Grid>


   {isDisabled == false &&
      <Grid container item xs={12} md={12} spacing={1} justifyContent='right'  >
         <Grid item xs={12} md={props.contact == undefined ? 2 : 6}>
            <Button
               fullWidth
               variant='contained'
               color='error'
               endIcon={props.contact == undefined ? <CancelIcon /> : ''}
               onClick={() => {
                  if (props.contact == undefined) {
                     history.push(ROUTE_CONTACT)
                  } else {
                     // setFormValuesContact(props.contact);
                     getContatosbyCliente(props.contact.idcliente);
                     setIsDisabled(true);
                  }
               }}
            >
               Cancelar
            </Button>
         </Grid>
         <Grid item xs={12} md={props.contact == undefined ? 2 : 6}>
            <Button
               fullWidth
               variant='contained'
               endIcon={props.contact == undefined ? <SaveIcon /> : ''}
               type='submit'>
               {props.contact == undefined ? "Guardar" : "Actualizar"}
            </Button>
         </Grid>
      </Grid>
   }
</Grid>
</form>




   return (
      <Protected>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Grid container>
            <Snackbar
               open={snackBarConfig.open}
               autoHideDuration={snackBarConfig.autoHideDuration}
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
               <Alert
                  onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                  severity={snackBarConfig.severity}
                  variant="filled"
               >
                  {snackBarConfig.message}
               </Alert>
            </Snackbar>
            {props.contact == undefined ?
               <>
                  <Grid item xs={12}>
                     <Container maxWidth='xl' sx={{ mt: 1, mb: 2 }}>
                        <Typography variant='h4' sx={{ mb: 2 }}> {props.contact == undefined ? "Crear Contacto" : !isDisabled ? "Editar Contacto" : "Detalle Contacto"}</Typography>
                        {isDisabled ? formularioPrincipal : formulario}

                     </Container>
                  </Grid>
               </>
               :
               <>
                  <Grid container sx={{ mb: 2 }}>
                     <Button
                        startIcon={<ArrowBackIcon />}
                        variant='text'
                        onClick={() => { history.push(ROUTE_CONTACT) }}
                        sx={{
                           background: '#E4980D', color: '#fff', '&:hover': {
                              bgcolor: '#E4980D'
                           }
                        }}
                     >
                        Volver
                     </Button>
                  </Grid>
                  <Grid container>
                     <Grid
                        item xs={12} md={3} container flexDirection="row" spacing={1} sx={{ pl: 4, pr: 2, pb: 2, maxHeight: '73vh', overflowY: 'scroll' }}>
                      <Grid item container flexDirection="row" justifyContent='center' alignItems='center'>
                      <Grid item xs={2} sx={{cursor:'pointer'}}>
                      <Tooltip title="Editar contacto" placement="right-start">
                           {/* <IconButton
                           onClick={()=>{setIsDisabled(!isDisabled)}}> */}
                            <EditContactIcon  fontSize='large' onClick={()=>{setIsDisabled(!isDisabled)}}/>
                           {/* </IconButton> */}
                     </Tooltip>
                        </Grid>
                        <Grid item xs={10}>
                           <Typography variant="h6" sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>
                              {is_prospecto == '1' ? 'Acerca del Prospecto' : 'Acerca del Cliente'}
                           </Typography>
                        </Grid>
                      </Grid>

                        {isDisabled ? formularioPrincipal : formulario}
                        <Grid item container direction="row" xs={12}>
                           <span style={{ color: '#155172', fontWeight: 540, fontSize: '1.0em', }}>
                              MIS CONTACTOS
                           </span>
                           <Tooltip title="Agregar nuevo contacto" placement="right-start">
                              {/* <IconButton onClick={() => { setOpenAddContact(true) }} sx={{ mt: -1 }}> */}
                              <div style={{ marginTop: '-5px',cursor:'pointer' }}>
                                 <AddCircleIcon color="success" fontSize='medium' onClick={() => { setValueContact(null), setOpenAddContact(true) }} />
                              </div>
                              {/* </IconButton> */}
                           </Tooltip>
                        </Grid>

                        {contactos.length > 0 ? (
                           contactos.map((value, i) => {

                              return (
                                 <Grid key={i} item xs={12} container sx={{ height: 'auto', mt: 1, border: '3px solid #0C879C', borderRadius: '4px' }}>
                                    <Grid item xs={12}>
                                       <Typography sx={{ color: '#155172', fontWeight: '700' }}>
                                          <span style={{ color: '#155172' }}>{i + 1}-</span>
                                          {`  ${value.nombre}`}
                                       </Typography>
                                    </Grid>
                                    <Divider />
                                    <Grid item xs={12} >
                                       <Typography> <span style={{ fontWeight: 500, color: '#155172' }}>Email:</span>{` ${value.email}`} </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                       <Typography> <span style={{ fontWeight: 500, color: '#155172' }}>Celular:</span>{` ${value.celular}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                       <Typography>
                                          <span style={{ fontWeight: 500, color: '#155172', }}>Cargo:</span>{` ${value.cargo}`}
                                          <Grid style={{ float: 'right' }}>
                                             <EditIcon
                                                color="success"
                                                onClick={() => { setValueContact(value), setOpenAddContact(true) }}>
                                             </EditIcon>
                                             <DeleteIcon
                                                color="error"
                                                onClick={() => { contactId(value) }}>
                                             </DeleteIcon>
                                          </Grid>
                                       </Typography>

                                    </Grid>
                                 </Grid>
                              )
                           })
                        ) : (
                           <Grid item xs={12} container sx={{ height: 'auto', border: '3px solid #0C879C', borderRadius: '4px' }}>
                              <Grid item xs={12} >
                                 <Typography> <span style={{ fontWeight: 500, color: '#155172' }}>
                                    SIN CONTACTOS
                                 </span>
                                 </Typography>
                              </Grid>
                           </Grid>
                        )}


                     </Grid>
                     <Grid item xs={12} md={6} container sx={{ pl: 2, maxHeight: '73vh', overflowY: 'scroll' }}>
                        <TabsComponent
                           color='#F1F1F1'
                           labels={labels}
                           childrenOne={
                              taskContact.length != 0 || taskContactNotCompleted.length != 0 ?
                                 <div>
                                    <div style={{
                                       display: 'flex',
                                       justifyContent: 'end',
                                       alignItems: 'center',
                                       paddingRight: '15px'

                                    }}>
                                       <div>Filtro</div>
                                       <Select
                                          id="idprioridad"
                                          sx={{ bgcolor: '#fff', width: '200px', ml: 1 }}
                                          size="small"
                                          name="idprioridad"
                                          value={filter}
                                          onChange={handleTask}
                                       >
                                          <MenuItem value={0}>No Completadas</MenuItem>
                                          <MenuItem value={1}>Completadas</MenuItem>
                                       </Select>
                                    </div>
                                    {
                                       dataFilter.map((value, i) => {
                                          return (
                                             <div key={i}>
                                                <CardActividad data={value} idTask={idTask} />
                                             </div>
                                          )
                                       })
                                    }
                                 </div> :
                                 <Card sx={{ height: '50vh' }}>
                                    <Grid container
                                       direction="row"
                                       justifyContent="center"
                                       pt={3}
                                       alignItems="center">
                                       <Grid item >
                                          <Box
                                             component="img"
                                             sx={{
                                                height: " 200px"
                                             }}
                                             src={logokyte}>
                                          </Box>
                                       </Grid>
                                       <Grid item >
                                          <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>No existen Tareas pendientes hacia este Cliente.</Typography>
                                       </Grid>

                                    </Grid>
                                 </Card>
                           }
                           childrenTwo={
                              taskContactCall.length != 0 ?
                                 taskContactCall.map((value, i) => {
                                    return (
                                       <div key={i}>
                                          <CardActividad data={value} idTask={idTask} />
                                       </div>
                                    )
                                 }) :
                                 <Card sx={{ height: '50vh' }}>
                                    <Grid container
                                       direction="row"
                                       justifyContent="center"
                                       pt={3}
                                       alignItems="center">
                                       <Grid item >
                                          <Box
                                             component="img"
                                             sx={{
                                                height: " 200px"
                                             }}
                                             src={logokyte}>
                                          </Box>
                                       </Grid>
                                       <Grid item >
                                          <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.5rem' }}>No existen llamadas entrantes de este Cliente.</Typography>
                                       </Grid>

                                    </Grid>
                                 </Card>
                           }
                           childrenThree={
                              bitacora.length != 0 || bitacora ?
                              bitacora.map((value, i) => {
                                    return (
                                       <div key={i}>
                                          <CardBitacora data={value} />
                                       </div>
                                    )
                                 }) :
                                 <Card sx={{ height: '50vh' }}>
                                    <Grid container
                                       direction="row"
                                       justifyContent="center"
                                       pt={3}
                                       alignItems="center">
                                       <Grid item >
                                          <Box
                                             component="img"
                                             sx={{
                                                height: " 200px"
                                             }}
                                             src={logokyte}>
                                          </Box>
                                       </Grid>
                                       <Grid item >
                                          <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.8rem' }}>No existen bitacoras de este Cliente.</Typography>
                                       </Grid>

                                    </Grid>
                                 </Card>
                           }
                        ></TabsComponent>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     md={3}
                     container
                     direction="row"
                     spacing={1}
                     sx={{ pl: 4, pr: 2, pb: 2, maxHeight: '73vh', overflowY: 'scroll' }}
                  >
                     <Grid item xs={12} textAlign={'center'}>
                        <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.0rem' }}>Llamadas Entrantes</Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Button size='small'
                           fullWidth
                           sx={{
                              background: '#0C879C', color: '#fff', '&:hover': {
                                 bgcolor: '#0C879C'
                              }
                           }}
                           onClick={() => {
                              setReceived(true)
                              setOpenAddTask(true)
                           }}
                        >Agregar</Button>
                     </Grid>
                     <Grid item xs={12}>
                        <span> Ve todas las llamadas con tu cliente desde un solo lugar. Registra datos relevantes de la llamada.</span>
                     </Grid>
                     <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                        <Divider />
                     </Grid>
                     <Grid item xs={12} textAlign={'center'}>
                           <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.0rem' }}>Historial de Correos</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Button size='small'
                              fullWidth
                              sx={{
                                 background: '#0C879C', color: '#fff', '&:hover': {
                                    bgcolor: '#0C879C'
                                 }
                              }}
                              onClick={() => {
                                 setOpenHiloCorreo(true)
                              }}
                           >Ver Hilo de Correos</Button>
                        </Grid>
                        {/* <Grid item xs={12}>
                           <span> Ve todas las llamadas con tu cliente desde un solo lugar. Registra datos relevantes de la llamada.</span>
                        </Grid> */}
                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                           <Divider />
                        </Grid>
                        <Grid item xs={12} textAlign={'center'}>
                           <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.0rem' }}>Notas rápidas</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Button size='small'
                              fullWidth
                              sx={{
                                 background: '#0C879C', color: '#fff', '&:hover': {
                                    bgcolor: '#0C879C'
                                 }
                              }}
                              onClick={() => {
                                 setEstado(false)
                                 setOpenModalNotas(true)
                              }}
                           >Crear notas</Button>
                        </Grid>
                        {/* <Grid item xs={12}>
                           <span> Ve todas las llamadas con tu cliente desde un solo lugar. Registra datos relevantes de la llamada.</span>
                        </Grid> */}
                        <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                           <Divider />
                        </Grid>
                     <Grid item xs={12} textAlign={'center'}>
                        <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '1.0rem' }}>Tareas</Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <Button size='small'
                           fullWidth
                           sx={{
                              background: '#E4980D', color: '#fff', '&:hover': {
                                 bgcolor: '#E4980D'
                              }
                           }}
                           onClick={() => { setOpenAddTask(true) }}
                        >Crear Tareas</Button>
                     </Grid>
                     <Grid item xs={12}>
                        <span>En la seccion de Actividades puedes visualizar todas las tareas pendientes.</span>
                     </Grid>
                     <Grid item xs={12} mb={2}>
                        <Button size='small'
                           fullWidth
                           sx={{
                              background: '#0C879C', color: '#fff', '&:hover': {
                                 bgcolor: '#0C879C'
                              }
                           }}
                           onClick={() => { SincronizarCompras() }}
                        >Sincronizar Compras</Button>
                     </Grid>
                     {comprasSync.length >= 1 &&
                        <Grid item xs={12} container sx={{ overflow: 'scroll', height: '35vh', padding: 2 }}>
                           {

                              comprasSync.map((compra) => {
                                 var fecha = moment(compra.fecha)
                                 return (
                                    <>
                                       <Grid item xs={12} container sx={{ height: 'auto', mt: 2, p: 2, border: '3px solid #0C879C', borderRadius: '4px' }}>
                                          <Grid item xs={12}>
                                             <Typography sx={{ color: '#155172', fontWeight: '700' }}>{fecha.format('LL')}</Typography>
                                          </Grid>
                                          <Divider />
                                          <Grid item xs={12} >
                                             <Typography> <strong>Folio:</strong> {compra.folio}</Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                             <Typography> <strong>Total:</strong> $ {compra.valor_total}</Typography>
                                          </Grid>
                                          <Button size='small'
                                             fullWidth
                                             sx={{
                                                background: '#E4980D', color: '#fff', '&:hover': {
                                                   bgcolor: '#E4980D'
                                                }
                                             }}
                                             onClick={() => {
                                                setOpenModalDetalleCompra(true)
                                                setCompraSelect(compra.idcompra)
                                             }
                                             }
                                          >Ver detalles</Button>
                                       </Grid>
                                    </>
                                 )

                              })
                           }

                        </Grid>
                     }
                  </Grid>
               </Grid>
               </>
            }
         <ConfirmDialog
            open={Dialog.open}
            title={Dialog.title}
            message={Dialog.message}
            onConfirm={() => { deleteContactbyClient() }}
            onClose={() => setDialog(prev => ({ ...prev, open: false }))}
         />
         <ModalAddTaskAndCall
            taskAdd={taskAdd}
            open={openAddTask}
            setOpen={setOpenAddTask}
            Received={Received}
            setReceived={setReceived}
            IdCliente={IdCliente}
            //taskEdit={taskEdit}
            //setTaskEdit={setTaskEdit}
            //taskUpdate={taskUpdate}
            sentDateData={sentDateData}
            setSentDateData={setSentDateData}
         />
         <ModalDetalleCompra
            open={openModalDetalleCompra}
            setOpen={setOpenModalDetalleCompra}
            idCompra={compraSelect}
         />
         {
            openHiloCorreo &&
            <ModalCorreoEnviados
            open={openHiloCorreo}
            setOpen={setOpenHiloCorreo}
            id_cliente = {props.contact.idcliente}
            />
         }
         <ModalAddContact
            open={OpenAddContact}
            setOpen={setOpenAddContact}
            dataContact={props.contact}
            ValueContact={ValueContact}
            setDataContact={setDataContact}
            setValueContact={setValueContact}
            updateContact={updateContact}
         />
         <ModalTomarNota
            open={openModalNotas}
            setOpen={setOpenModalNotas}
            data={props.contact.idcliente}
            // campana={campana}
            estado={estado}
            getContact={getBitacora}
         />
      </Grid>
      </Protected >
   )

};
