import { MenuLateral } from './components/MenuLateral'
import { Protected } from '@/components/layout/Protected';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { Alert, Avatar, Backdrop, Button, Checkbox, CircularProgress, Container, Divider, Grid, IconButton, Input, InputAdornment, ListItem, ListItemAvatar, ListItemButton, ListItemText, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Props } from './CorreosEntrantes.type';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Link } from 'react-router-dom';
import { ModalCambiarContraseña } from "@/components/common/Modal/ModalCambiarContraseña";
import { SpinnerGrow } from '@/components/common/Spinner';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';
import { mailService } from '@/service/services/Mail.service';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';
import { campaingService } from '@/service/services/Campaing.service';
import { contactService } from '@/service/services/Contact.service';
import { useHistory } from 'react-router-dom';
import { ModalVerCorreo } from '@/components/common/Modal/ModalVerCorreo';
import { ModalCorreoCliente } from '@/components/common/Modal/ModalCorreoCliente';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PhotoIcon from '@mui/icons-material/Photo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import FolderIcon from '@mui/icons-material/Folder';
import ShareIcon from '@mui/icons-material/Share';

export const CorreosEntrantesView : React.FC<Props> = (props:any) : JSX.Element => {
   const history = useHistory();
   const [open, setOpen] = useState<boolean>(false);
   const [openCorreo, setOpenCorreo] = useState<boolean>(false)
   const [openModalClient, setOpenModalClient] = useState<boolean>(false)
   const [clientCorreo, setClientCorreo] = useState<any>([]);
   const userData = readLocalStorage(KEY_USER_DATA)

   const [status, setStatus] = useState<any>('Recibidos');
   const [correoSelect, setCorreoSelect] = useState<any>({})
   const [correosEntrantes, setCorreosEntrantes ] = useState<any>([]);

   const [valueBuscador, setValueBuscador] = useState<any>('');
   const [rutaDownLoad,setRutaDownLoad] = useState<any>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
  })
  
   useEffect(()=>{
      setOpen(true)
      console.log(props)
      // if(props?.location?.state){
      //    getHiloCorreos(setOpen);
      // }
      // else{
         getCorreosEntrantes(setOpen);
      //}  
   },[])

   const handleClick = async(attach, uid) => {
      const resp:any = await mailService.getDescargarArchivo(attach, uid);
      if(resp.data){
         setRutaDownLoad(resp.data.ruta);
         window.open(resp.data.ruta)
      }
   }

      const getHiloCorreos = async(funcOpen) => {
      const resp:any = await mailService.getBuscadorCorreoCliente(props?.location?.state);
      setCorreosEntrantes(resp);
      funcOpen(false)

   }

   const getCorreosEntrantes = async(funcOpen) => {
      const resp:any = await mailService.getCorreosRecibidos();
      if(resp?.data != 'Correo sin credenciales'){
         setCorreosEntrantes(resp?.data)
      }else{
         console.log('sjdfjdsk')
         setCorreosEntrantes([])
         setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message: resp?.data})
      }
      
      funcOpen(false)
   }

   const BuscadorCorreo = async(data) => {
      const resp = await mailService.getBuscadorCorreo(data)
      setCorreosEntrantes(resp)
   }
   const handleBuscador = (e) => {

      setValueBuscador(e.target.value)
      if(e.target.value.length >= 3){
         BuscadorCorreo(e.target.value)
      }
   }

   const searchDateCorreo = async(fecha, funcOpen) => {
      funcOpen(true)
      const resp = await mailService.getBuscadorCorreoFecha(fecha);
      setCorreosEntrantes(resp)
      funcOpen(false)
   }

   const MarcarCorreo = async(data, especificacion) => {

      const detalleInsert:any = await mailService.insertHistorialCorreo( {
         data_correo : data,
         uid_correo: data.UID,
         fecha_correo: data.fecha,
         from_personal: data.from.personal,
         body_correo: data.body,
         html_correo: data.html,
         id_usuario: userData.user.idusuario,
         adjuntos_correo: JSON.stringify(data.attachments),
         especificacion: especificacion
     })

     if(detalleInsert){
      setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: `Correo marcado como ${especificacion} con éxito`})
     }
     
   }
   const MoverACampañaGeneral = async(data, funcOpen) => {
      funcOpen(true)

      const respVerify:any = await mailService.getVerifyCLient( data.mail);
      console.log(respVerify)
      if(respVerify){
         console.log(respVerify)
         setOpenModalClient(true)
         setCorreoSelect(data)
         setClientCorreo(respVerify?.data?respVerify?.data:[])    
      }

      // let campana = []
      // const respCanal = await campaingService.getCampanaProspecto('10')
      // console.log(respCanal)
      // if (respCanal) {
      //     campana = respCanal.data
      //     console.log(campana[0]['id'])
      // }
      // //Creo al cliente
      // const formValues = {
      //    rut: '',
      //    nombre: data.from.personal,
      //     telefono: '',
      //    correo: data.mail,
      // }
      // let client = [];
      // const respClient = await contactService.createClientProspecto(formValues)
      // if (respClient) {
      //     if (!respClient.data.status) {
      //         setSnackBarConfig(prev => ({
      //             ...prev,
      //             open: true,
      //             severity: 'error',
      //             message: respClient.data.error,
      //         }));
      //     } else {
      //         //Campana Negocio General
      //         client = respClient.data.cliente
      //         const dataClient = {
      //             idcampananegocio: campana[0]['id'],
      //             productos: null,
      //             idparameter: null,
      //             form_cliente: null,
      //             uid_correo: data.UID,
      //             data_correo: JSON.stringify(data),
      //             observacion: 'Ninguna',
      //             idcliente: client['idcliente'],
      //             asign_automatic: true
      //         }
      //         const respCampana = await campaingService.createCampanaGeneral(dataClient)
      //         if (respCampana) {
      //             history.push(`/campaña/generales/${campana[0]['id']}`, { cliente: respCampana.data.idcliente, nombre_campana: respCampana.data.nombre_campana, formValue: formValues, channel: '10' })
      //         }
      //     }
      // } else {
      //     console.log('error')
      // }
      funcOpen(false)
   }

   return(
   <Protected>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
             <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando bandeja de correos...</strong></h1>
               </Grid>
         </Backdrop>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
      <Container maxWidth='xl' sx={{mt:1}}>
      <Grid container justifyContent={'space-between'}>
         <Grid item xs={9}>
            <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em', mb: 2 }} >Mis Correos Recibidos</Typography>

         </Grid>
         <Grid item xs={3}  
               container
               direction="row"
               justifyContent="flex-end">
           <Grid item mt={2} >
           <Button variant='contained'
             sx={{background:'#E4980D', color:'#fff', '&:hover':{
               bgcolor:'#E4980D'
            }}}
            onClick={()=>{getCorreosEntrantes(setOpen);}}>Actualizar</Button>
           </Grid>

         </Grid>

      </Grid>
      <Grid container flexDirection='row' spacing={2}>
         <Grid item md={12} container>
         <Grid item md={8} container>
         <Grid item xs={12}>
               <span>Buscar correo:</span>
            </Grid>
            <Grid item xs={12} mt={1}>
               <TextField
                  type='input'
                  size='small'
                  fullWidth
                  value={valueBuscador}
                  sx={{ bgcolor: '#E3F4FD' }}
                  placeholder='Buscar Correo'
                  onChange={(e) => handleBuscador(e)}
               />
            </Grid>
         </Grid>
         <Grid item md={4} container pl={2} >
            <Grid item xs={12}>
               <span>Buscar por fecha:</span>
            </Grid>
            <Grid item xs={12} mt={1}>
               <TextField
                  type='date'
                  size='small'
                  sx={{ bgcolor: '#E3F4FD' }}
                  fullWidth
                  onChange={(e) => searchDateCorreo(e.target.value, setOpen)}
               />
            </Grid>
         </Grid>

         
         <Grid item md={12}
               sx={{bgcolor: 'background.paper',height:{xs:'55vh',md:'55vh'},overflowX:'hidden', overflowY:'scroll',borderRadius:'0.5rem', border:'0.5px solid #eff3f5',borderLeft: '5px solid #3F9BCC ', mt:3,ml:{xs:1, md:0}}}
         >
             
            {status=='Recibidos' && (correosEntrantes || []).map((value, i) => {
                   
                   return (
                      <>
                         <ListItem
                            key={i}
                            disablePadding
                            sx={{bgcolor:value?.todo?.flags?.seen=='Seen'?'#F2F5F5':'#fff'}}
                         >
                          
                          <ListItemButton sx={{zIndex:10}}  onClick={()=>{setCorreoSelect(value); setOpenCorreo(true)}}>
                           <ListItemText
                                       primary={
                                          <React.Fragment>
                                             <span style={{color:value?.todo?.flags?.seen=='Seen'?'#B5B2B2':'black'}}>
                                                {dateFormat(value.fecha)+' '+dateFormatTime(value.fecha)}
                                             </span>
                                             
                                          <Typography
                                             sx={{ display: 'inline' , fontSize:'20px',textDecoration:'none',color:value?.todo?.flags?.seen=='Seen'?'#B5B2B2':'black', fontWeight:700}}
                                          >
                                               <strong> {value.from.personal}</strong>
                                          </Typography>
                                       </React.Fragment>
                                       }
                                       secondary={
                                          <>
                                             <Typography
                                                sx={{ display: 'inline' , fontSize:'15px',textDecoration:'none', color:value?.todo?.flags?.seen=='Seen'?'#B5B2B2':'black'}}
                                                component="span"
                                                variant="body2"
                                             >
                                                {value.body? value.body.substring(0,120)+'...':'...'}
                                             </Typography>
                                             
                                          </>
                                       }
                                    />
                           </ListItemButton>
                           {
                             ! props?.location?.state && 
                             <>
                                 <Tooltip title="Destacar">
                                 <IconButton sx={{ justifyContent: 'right', bgcolor:'#686868' }} onClick={() => { MarcarCorreo(value, 'destacado') }}>
                                       {value.especificacion?<StarIcon sx={{color:'#FFFF00'}}/>:<StarBorderIcon/>}
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip title="Archivar">
                                 <IconButton sx={{ justifyContent: 'right' }} onClick={() => {  MarcarCorreo(value, 'archivado')   }}>
                                       <FolderIcon/>
                                    </IconButton>
                                 </Tooltip>
                                 <Tooltip title="Gestionar">
                                 <IconButton sx={{ justifyContent: 'right' }} onClick={() => {  MoverACampañaGeneral(value, setOpen) }}>
                                       <ShareIcon/>
                                    </IconButton>
                                 </Tooltip>
         
                             </>
                           }
                          
                         </ListItem>
                         <Stack direction="row" spacing={1} sx={{zIndex:1000, pl:2}}>
                                                {
                                                   (value?.attachments || []).map((item)=>{
                                                      let icono = <AttachFileIcon/>;

                                                      if(item.tipo == 'application/pdf'){ icono = <PictureAsPdfIcon />}
                                                      if(item.tipo == "image/png"){icono = <PhotoIcon/>}
                                                      return(
                                                         <Chip
                                                            label={item.nombre}
                                                            onClick={()=>{handleClick(item.nombre, value.UID)}}
                                                            icon={icono}
                                                            color="secondary"
                                                            variant="outlined"
                                                         />
                                                      )
                                                   })
                                                }
                                          </Stack>
                        <hr></hr> 
                      </>
                   );
                })}   
               
         </Grid>
         <Grid item md={12} container justifyContent={'space-between'} mt={2}>
               <Button>Anterior</Button> 
               <Button>Siguiente</Button>
         </Grid>
         </Grid>
        
      </Grid>
      </Container>
      {
         openCorreo && <ModalVerCorreo
         setOpen={setOpenCorreo}
         open= {openCorreo}
         data = {correoSelect}
         setData = {setCorreoSelect}
         /> 
      }
      {
         openModalClient && <ModalCorreoCliente
         setOpen={setOpenModalClient}
         open= {openModalClient}
         data = {correoSelect}
         dataClient = {clientCorreo}
         setDataClient = {setClientCorreo}
         /> 
      }
     
   </Protected>
)
   }
