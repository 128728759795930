
import { byPassRepository } from "../repositories/Bypass.repository";

export const bypassService= {
   getChannel,
   getInputs,
   getUrlsLeads,
   postCrearCampaña,
   postListaByCampaign,
   postCrearForm,
   postCrearEmpresa,
   loginByPass,
   getTypeDocumentForClient,
   saveDocumento,
   saveDocumentoPDFeditable,
   getClientDocuments,
   getClientDocumentsURL,
   getSearchClientDocuments,
   SendTemplateLeads
};

async function getChannel () {
   const bypass= await byPassRepository.getChannel();
   return bypass
}

async function getInputs () {
   const bypass= await byPassRepository.getInputs();
   return bypass
}
async function getUrlsLeads (idParameter) {
   const bypass= await byPassRepository.getUrlsLeads(idParameter);
   return bypass
}

async function postListaByCampaign(idCampaign) {
   const bypass= await byPassRepository.postListaByCampaign(idCampaign);
   return bypass
}

async function loginByPass (rut, user_nick, password) {
   const bypass= await byPassRepository.LoginByPass(rut, user_nick, password);
   return bypass
}

async function postCrearCampaña (name,idinputs,idchannel, idEmpresa, nameUri, domainEntity) {
   const bypass= await byPassRepository.postCrearCampaña(name,idinputs,idchannel,idEmpresa, nameUri, domainEntity);
   return bypass
}

async function postCrearForm (idParameter,channel,urlParameters) {
   const bypass= await byPassRepository.postCrearForm(idParameter,channel,urlParameters );
   return bypass
}

async function postCrearEmpresa (nameID) {
   const bypass= await byPassRepository.postCrearEmpresa(nameID);
   return bypass
}

//documents

async function getTypeDocumentForClient (id) {
   const resp= await byPassRepository.getTypeDocumentForClient(id);
   return resp
}

async function saveDocumento(data) {
   const resp= await byPassRepository.saveDocumento(data);
   return resp;
}

async function saveDocumentoPDFeditable(data) {
   const resp= await byPassRepository.saveDocumentoPDFeditable(data);
   return resp;
}


async function getClientDocuments() {
   const resp= await byPassRepository.getClientDocuments();
   return resp;
}

async function getClientDocumentsURL(id) {
   const resp= await byPassRepository.getClientDocumentsURL(id);
   return resp;
}

async function getSearchClientDocuments(search) {
   const resp= await byPassRepository.getSearchClientDocuments(search);
   return resp;
}

async function SendTemplateLeads(data) {
   const resp= await byPassRepository.SendTemplateLeads(data);
   return resp;
}
