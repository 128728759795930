import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, MenuItem, Select, InputLabel, Autocomplete, FormControl, Grid, FormControlLabel, Checkbox, InputAdornment, Typography, FormHelperText } from '@mui/material'
import { DownloadIcon, PencilIcon, CloseCircleIcon } from "@toolbox/constants/icons";
import { Props } from "./ModalAddTaskAndCall.type";
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CampaignIcon from '@mui/icons-material/Campaign';
import RepeatIcon from '@mui/icons-material/Repeat';
import './Modal.sass'
import {SaveIcon,CancelIcon } from "@toolbox/constants/icons";
import { campaingService } from '@/service/services/Campaing.service';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { contactService } from '@/service/services/Contact.service';
import { taskService } from '@/service/services/Task.service';
import { formatDate } from '@fullcalendar/react';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';
import { bypassService } from '@/service/services/Bypass.service';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import moment from 'moment';
import { ROLE_VENDEDOR } from '@/toolbox/defaults/static-roles';

const initialFormValues = {
   id:"",
   idtipofeeds: 0,
   idprioridad: 0,
   idcliente: "",
   fechacreacion: moment().format('YYYY-MM-DD'),
   horainicio: '00:00',
   fechacierre: moment().format('YYYY-MM-DD'),
   horafin:'00:00',
   comentarios: "",
   idcampananegocio:"",
   idfrecuencia:"",
   tipo:''
}

export const ModalAddTaskAndCall: React.FC<Props> = ({
   open,
   taskAdd,
   setOpen,
   taskEdit,
   setTaskEdit,
   taskUpdate,
   sentDateData,
   setSentDateData,
   setOpenDetailsEvent,
   Received,
   setReceived,
   campaingDataBoolean,
   statusLeads=false,
   IdCliente
}): JSX.Element => {
   const [formValues, setFormValues] = useState(initialFormValues);
   const [idCliente, setIdCliente] = useState(null);
   const [idasignado, setIdAsignado] = useState(null);
   const {id="", idtipofeeds = "", tipo="",idprioridad = "", idcliente = "",idcampananegocio="",idfrecuencia="", fechacreacion = "", horainicio = "", fechacierre = "", horafin = "", comentarios = "" } = formValues;
   const data = readLocalStorage(KEY_USER_DATA)
   const [error, setError] = useState(null);
   const [dataUser, setDataUser] = useState<any>([])
   const [visibleClientLeads, setVisibleClientLeads] = useState<boolean>(false);
   const [visibleRepeat, setVisibleRepeat] = useState<boolean>(false);
   const [visibleCampaing, setVisibleCampaing] = useState<boolean>(false);
   const [idCampaign, setIdCampaign] = useState(null);
   const [dataCampaign,setDataCampaign]=useState<any>([]);
   const [listCampaing, setListCampaing] = useState<any>([]);
   const [dataClientes,setDataClientes]=useState<any>([]);
   const [dataFrecuencia, setDataFrecuencia] = useState<any>([]);
   const [dataTipoTarea, setDataTipoTarea] = useState<any>([]);
   const [dataPrioridad, setDataPrioridad] = useState<any>([]);
   const [formCliente, setFormCliente] = useState(null);
   const [dataFormClientes,setDataFormClientes]=useState<any>('');
   const [RolUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
   const checkedIcon = <CheckBoxIcon fontSize="small" />;


   useEffect(() => {

      console.log(sentDateData)
      getFeedsData();
      getDataUser(data.user.idempresacliente)
      // console.log(getDataUser(data.user));
      if (taskEdit) {
         taskEdit.idfrecuencia&&setVisibleRepeat(true)
         taskEdit.idfrecuencia&&setVisibleCampaing(true)
         const dateCreacion = dateFormat(taskEdit.fechacreacion)
         const timeCreacion = dateFormatTime(taskEdit.fechacreacion)
         const dateCierre = dateFormat(taskEdit.fechacierre)
         const timeFin = dateFormatTime(taskEdit.fechacierre)


         setFormValues({...taskEdit, fechacreacion:dateCreacion, horainicio:timeCreacion,
         fechacierre: dateCierre, horafin: timeFin});

         if(taskEdit.idcampananegocio)
         {

            const taskCampaign = dataCampaign.filter(campaign => campaign.id == taskEdit.idcampananegocio)
            setIdCampaign(taskCampaign[0]);
            getContactandUserByCampaing(taskEdit?.idcampananegocio,taskEdit);
            setVisibleCampaing(true);
         }else{
            const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario)
            setIdAsignado(taskUsuario[0]);
           const taskClientes = dataClientes.filter(cliente => cliente.idcliente == taskEdit.idcliente)
           setIdCliente(taskClientes[0])
         }

      }
      else if (sentDateData) {
         setFormValues(sentDateData);
      }
      else {
         setFormValues(initialFormValues);
      }
   }, [taskEdit, sentDateData])

   const getSearchContacts = async (search) => {
      const resp= await contactService.getSearchContacts(search);
      setDataClientes(resp.data? resp.data.clients:[]);

   };

   const eventCampaing = (campaign)=>{
      if(!campaign)
      {
         getFeedsData();
         setIdAsignado(null);
         setIdCliente(null)
         setVisibleClientLeads(false);
         getDataUser(data.user.idempresacliente);
      }
      setIdCampaign(campaign)
     // setVisibleClientLeads(campaign?.tipo==1?true:false);
     setVisibleClientLeads(true)
      //idCampaign !== null && setError("");
      getContactandUserByCampaing(campaign?.id,null);
     // DataUsarioCliente(asignado.idusuario)
   }

   async function getFeedsData(){
      // setOpenView(true);
       const resp = await taskService.getFeedsData()
       if(IdCliente)
       {
       const respCampaing = await taskService.getCampaingbyCliente(IdCliente);
       setDataCampaign(respCampaing.data? respCampaing.data:[]);
       }
       setDataClientes(resp.data? resp.data.clients.data:[]);
       setDataFrecuencia(resp.data? resp.data.frecuencia:[]);
      //  const DataCampaing = resp.data.campaing.filter(value => value.tipo == '0')
      //  setDataCampaign(respCampaing.data? respCampaing.data:[]);
       getDataUser(data.user.idempresacliente)
       setListCampaing(resp.data? resp.data.campaing:[]);
       setDataTipoTarea(resp.data? resp.data.tipofeeds:[]);
       setDataPrioridad(resp.data?resp.data.prioridad:[])
       // setTasks(resp.data);
       // setOpenView(false);
    }

    async function getCampaingbyCliente(){
      // setOpenView(true);
       const resp = await taskService.getCampaingbyCliente(IdCliente);

    }

   async function getFormAndIdbyClienteAndCampaing(campaign){

      if(campaign.tipo == 0) {

         const idcliente = IdCliente
         const idcampana = ''
         const form_cliente = ''

      } else if(campaign.tipo == 1){

         const idcliente = IdCliente
         const idcampana = ''
         const form_cliente = ''

      }



      // const resp = await contactService.getFormAndIdbyClienteAndCampaing(campaign);

   }

   async function getDataUser(idempresacliente) {
      const resp = await entityService.getEntityUsers(idempresacliente);
      const dataUsers = resp.data.filter(user => user.idtipousuario > 3 && user.idtipousuario<5)
      setDataUser(dataUsers)
   }

   async function getContactandUserByCampaing(id,taskEdit) {
      console.log(id)
      setDataFormClientes('');
      if(id)
      {
         const CampaignTipo = dataCampaign?.filter(campaign => campaign.id == id)
         console.log(CampaignTipo[0].id)
         const idFormValue = await contactService.getFormAndIdbyClienteAndCampaing(IdCliente, CampaignTipo[0]?.id,'');
         console.log(idFormValue)
         if(idFormValue){
         setDataFormClientes(idFormValue.data);
         }
      }
      // if(id){
      //    const CampaignTipo = dataCampaign?.filter(campaign => campaign.id == id)
      // //console.log(CampaignTipo[0].tipo)
      // if(CampaignTipo[0].tipo==1){
      //    const paramter = await campaingService.getIdParameter(id)
      //    if(paramter.message == 'Server Error'){
      //    }else{
      //       const resp = await bypassService.postListaByCampaign(paramter.data);
      //       let ArrayCliente = []
      //       ArrayCliente = resp.map((value) => {
      //          let filtroCliente = {
      //             campaña: "",
      //             canal: "",
      //             codigo: "",
      //             idCampaña: 0,
      //             idCanal: 0,
      //             inputs: ""
      //          }
      //          filtroCliente.campaña = value.campaña
      //          filtroCliente.canal = value.canal
      //          filtroCliente.codigo = value.codigo
      //          filtroCliente.idCanal = value.idCanal
      //          filtroCliente.inputs = value.inputs.filter(input => input.input == 'nombre')[0].value

      //          return filtroCliente

      //       })


      //       setDataFormClientes(ArrayCliente)

      //       if(taskEdit)
      //       {
      //          setVisibleClientLeads(true)
      //       const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario)
      //       setIdAsignado(taskUsuario[0])

      //       const taskClientes = ArrayCliente.filter(cliente => cliente.codigo == taskEdit.form_cliente)

      //       setFormCliente(taskClientes[0])
      //       }

      //    }


      // }

      // if(CampaignTipo[0].tipo==0){

      //    setVisibleClientLeads(false)

      //    const resp = await contactService.getContactandUserByCampaing(id);

      //    setDataUser(resp.data? resp.data.user:[])
      //    //setDataClientes(resp.data? resp.data.clientes:[]);
      //    if(taskEdit)
      //    {
      //    const taskUsuario = dataUser.filter(user => user.idusuario == taskEdit.idusuario)
      //    setIdAsignado(taskUsuario[0])
      //    const taskClientes = resp.data.clientes.filter(cliente => cliente.idcliente == taskEdit.idcliente)
      //    setIdCliente(taskClientes[0])
      //    }
      // }
      // }

   }

   const getContactandUserAndCampaing = async (idcampananegocio,idusuario)=>
   {
      const resp = await contactService.getContactandUserAndCampaing(idcampananegocio,idusuario);

      setDataClientes(resp.data? resp.data.clientes:[]);
   }

   const handleInputChange = (e) => {
      const changedFormValues = {
         ...formValues,
         [e.target.name]: e.target.value
      }
      if(e.target.name==='idcampananegocio'){
         getContactandUserByCampaing(e.target.value,null);
      }
      idtipofeeds !== null && setError("");
      idprioridad !== null && setError("");
      fechacreacion.trim() !== "" && setError("")
      fechacierre.trim() !== "" && setError("")
      horainicio.trim() !== "" && setError("")
      horafin.trim() !== "" && setError("")
      comentarios.trim() !== "" && setError("")
      // if (idasignado==null) {
      //       return setError("asignado");
      //    }
      console.log(changedFormValues)
      setFormValues(changedFormValues);
   }
   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      console.log('submit')

      let newTaks = {};
      let propietario = readLocalStorage(KEY_USER_DATA);
      propietario= propietario.user.rut_empresa;
      if (taskEdit) {
         let editTaks={};
         if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
            editTaks = {
               id: id,
               tipo:tipo,
               idtipofeeds: idtipofeeds,
               idprioridad: idprioridad,
               idusuario: data.user.role !== ROLE_VENDEDOR ? idasignado.idusuario : data?.user?.idusuario,
               idcliente: idCliente?idCliente.idcliente:'',
               fechacreacion: fechacreacion,
               fechacierre: fechacierre,
               comentarios: comentarios,
               idcampananegocio: idCampaign?idCampaign.id:"",
               form_cliente: formCliente?formCliente.codigo: '',
		         campana: idCampaign,
               idfrecuencia: idfrecuencia,
               propietario: propietario,
            } || {}

         } else {
            editTaks = {
               id: id,
               tipo:tipo,
               idtipofeeds: idtipofeeds,
               idprioridad: idprioridad,
               idusuario: data.user.role !== ROLE_VENDEDOR ? idasignado.idusuario : data?.user?.idusuario,
               idcliente: idCliente?idCliente.idcliente:'',
               comentarios: comentarios,
               idcampananegocio: idCampaign?idCampaign.id:"",
               form_cliente: formCliente?formCliente.codigo: '',
		         campana: idCampaign,
               idfrecuencia: idfrecuencia,
               fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
               fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
               propietario: propietario,

            } || {}
         }
         taskUpdate(editTaks);
         LimpiarFormulario();
         setTaskEdit(null)
      } else {
         if (idtipofeeds==0 && Received==false) {
             return setError("idtipofeeds");
          }
         if (idprioridad==0) {
            return setError("idprioridad");
         }
         if (data.user.role !== ROLE_VENDEDOR && idasignado==null && statusLeads==false ) {
            return setError("asignado");
         }
         // if(visibleClientLeads){
         //    if(formCliente == null){
         //       return setError("cliente")
         //    }
         // }else{
         //    if (idasignado==null) {
         //    return setError("asignado");
         // }
         // }

         if (!fechacreacion.trim()) {
            return setError("fechacreacion");
         }
         if (!horainicio.trim()) {
            return setError("horainicio");
         }
         if (!fechacierre.trim()) {
            return setError("fechacierre");
         }
         if (!horafin.trim()) {
            return setError("horafin");
         }
         if (!comentarios.trim()) {
            return setError("comentarios");
         }
         if (fechacierre.trim() < fechacreacion.trim()) {
            return setError("fechacierremenor");
         }
          else if (fechacierre.trim() == fechacreacion.trim() && formValues.horafin.trim() < formValues.horainicio.trim()) {
            return setError("horafinmenor");
         }else {
               console.log('SUBTMID')

           // console.log(propietario.user.rut_empresa);
           if(dataFormClientes && idCampaign)
           {
            if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
               newTaks = {
                  idtipofeeds: Received? 1 : idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: data.user.role !== ROLE_VENDEDOR ? idasignado.idusuario : data?.user?.idusuario,
                  idcliente: '',
                  form_cliente: dataFormClientes,
                  fechacreacion: fechacreacion,
                  horainicio: formValues.horainicio,
                  fechacierre: fechacierre,
                  horafin: formValues.horafin,
                  comentarios: comentarios,
                  idcampananegocio:idCampaign?idCampaign.id:'',
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  propietario: propietario,
                  received:Received? '1' : '0',
               } || {}
               taskAdd(newTaks);
               LimpiarFormulario();
               if(sentDateData){
                  setSentDateData(null);
               }
            } else {
               newTaks = {
                  idtipofeeds: Received? 1 : idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: data.user.role !== ROLE_VENDEDOR ? idasignado.idusuario : data?.user?.idusuario,
                  idcliente: '',
                  horainicio: formValues.horainicio,
                  horafin: formValues.horafin,
                  comentarios: comentarios,
                  idcampananegocio: idCampaign?idCampaign.id:"",
                  form_cliente: dataFormClientes,
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                  fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                  propietario: propietario,
                  received:Received? '1' : '0',
               } || {}
               taskAdd(newTaks);
               LimpiarFormulario();
               if(sentDateData){
                  setSentDateData(null);
               }
            }
           }else
           {
            if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
               newTaks = {
                  idtipofeeds: Received? 1 : idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: data.user.role !== ROLE_VENDEDOR ? idasignado.idusuario : data?.user?.idusuario,
                  idcliente: idCliente?idCliente.idcliente:'',
                  form_cliente: formCliente?formCliente.codigo: '',
                  fechacreacion: fechacreacion,
                  horainicio: formValues.horainicio,
                  fechacierre: fechacierre,
                  horafin: formValues.horafin,
                  comentarios: comentarios,
                  idcampananegocio:idCampaign?idCampaign.id:'',
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  propietario: propietario,
                  received:Received? '1' : '0',
               } || {}
               taskAdd(newTaks);
               LimpiarFormulario();
               if(sentDateData){
                  setSentDateData(null);
               }
            } else {
               newTaks = {
                  idtipofeeds: Received? 1 : idtipofeeds,
                  idprioridad: idprioridad,
                  idusuario: data.user.role !== ROLE_VENDEDOR ? idasignado.idusuario : data?.user?.idusuario,
                  idcliente: idCliente?idCliente.idcliente:'',
                  horainicio: formValues.horainicio,
                  horafin: formValues.horafin,
                  comentarios: comentarios,
                  idcampananegocio: idCampaign?idCampaign.id:"",
                  form_cliente: formCliente?formCliente.codigo: '',
                  campana: idCampaign,
                  idfrecuencia: idfrecuencia,
                  fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
                  fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
                  propietario: propietario,
                  received:Received? '1' : '0',
               } || {}
               taskAdd(newTaks);
               LimpiarFormulario();
               setDataFormClientes('')
               if(sentDateData){
                  setSentDateData(null);
               }
            }
           }
            // if (formValues.horainicio === "00:00" && formValues.horafin === '00:00') {
            //    newTaks = {
            //       idtipofeeds: Received? 1 : idtipofeeds,
            //       idprioridad: idprioridad,
            //       idusuario: idasignado?idasignado.idusuario:'',
            //       idcliente: idCliente?idCliente.idcliente:'',
            //       form_cliente: formCliente?formCliente.codigo: '',
            //       fechacreacion: fechacreacion,
            //       horainicio: formValues.horainicio,
            //       fechacierre: fechacierre,
            //       horafin: formValues.horafin,
            //       comentarios: comentarios,
            //       idcampananegocio:idCampaign?idCampaign.id:'',
            //       campana: idCampaign,
            //       idfrecuencia: idfrecuencia,
            //       propietario: propietario,
            //       received:Received? '1' : '0',
            //    } || {}
            //    taskAdd(newTaks);
            //    LimpiarFormulario();
            //    if(sentDateData){
            //       setSentDateData(null);
            //    }
            // } else {
            //    newTaks = {
            //       idtipofeeds: Received? 1 : idtipofeeds,
            //       idprioridad: idprioridad,
            //       idusuario: idasignado?idasignado.idusuario:'',
            //       idcliente: idCliente?idCliente.idcliente:'',
            //       horainicio: formValues.horainicio,
            //       horafin: formValues.horafin,
            //       comentarios: comentarios,
            //       idcampananegocio: idCampaign?idCampaign.id:"",
            //       form_cliente: formCliente?formCliente.codigo: '',
            //       campana: idCampaign,
            //       idfrecuencia: idfrecuencia,
            //       fechacreacion: `${formValues.fechacreacion} ${formValues.horainicio}`,
            //       fechacierre: `${formValues.fechacierre} ${formValues.horafin}`,
            //       propietario: propietario,
            //       received:Received? '1' : '0',
            //    } || {}
            //    taskAdd(newTaks);
            //    LimpiarFormulario();
            //    if(sentDateData){
            //       setSentDateData(null);
            //    }
            // }
         }
      }
   }

   const LimpiarFormulario = () => {
      setFormValues(initialFormValues);
      setIdCliente(null);
      setIdAsignado(null);
      setIdCampaign(null);
      setFormCliente(null)
      setVisibleRepeat(false)
      setVisibleCampaing(false)
      setVisibleClientLeads(false)
      setOpen(false)
      if(taskEdit){
         setOpenDetailsEvent(false)
      }
      if(Received){
         setReceived(false)
      }
   }
   return (
      <>
         <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

         >
            <Box className='Modal' >
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column'
                  }}>
                  <Typography id="modal-modal-title" sx={{color:'#3F9BCC', fontWeight:'700', fontSize:'2em'}} variant="h5" component="h1" textAlign='center' color='primary'>
                     {/* {taskEdit ? 'Editar Tarea': 'Crear Tarea'} */}
                     {Received ? 'REGISTRAR LLAMADA': 'Crear Tarea'}
                  </Typography>

                  <form onSubmit={handleSubmit} >
                     <Grid container direction="row" spacing={2} mt={2}>
                        {!Received &&<Grid item xs={12} md={6}>
                           <FormControl fullWidth error={error === "idtipofeeds" ? true : false}>
                              <InputLabel id="tipo">Tipo</InputLabel>
                              <Select
                                 labelId="tipo"
                                 id="tipo"
                                 sx={{bgcolor:'#fff'}}
                                 size="small"
                                 fullWidth
                                 name="idtipofeeds"
                                 value={idtipofeeds}
                                 label="Tipo"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    formValues.tipo=='Llamada'?
                                    <InputAdornment position='start'>
                                       <PhoneIcon />
                                    </InputAdornment>
                                    :formValues.tipo=='Correo'?
                                    <InputAdornment position='start'>
                                       <EmailIcon />
                                    </InputAdornment>
                                    :formValues.tipo=='Reunion'?
                                    <InputAdornment position='start'>
                                       <VideocamIcon />
                                    </InputAdornment>:
                                    <InputAdornment position='start'>
                                    <AssignmentIcon />
                                 </InputAdornment>
                                 }
                              >
                                 {dataTipoTarea.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "idtipofeeds" ? "Tipo Tarea es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>}
                        <Grid item xs={12} md={Received ?12:6}>
                           <FormControl fullWidth error={error === "idprioridad" ? true : false}>
                              <InputLabel id="idprioridad">Prioridad</InputLabel>
                              <Select
                                 labelId="idprioridad"
                                 id="idprioridad"
                                 sx={{bgcolor:'#fff'}}
                                 size="small"
                                 fullWidth
                                 name="idprioridad"
                                 value={idprioridad}
                                 label="idprioridad*"
                                 onChange={handleInputChange}
                                 startAdornment={
                                    <InputAdornment position="start">
                                     <TimelapseIcon />
                                   </InputAdornment>
                                 }
                              >
                                 {dataPrioridad.map((option, i) => {
                                    return <MenuItem key={i} value={option.id}>{option.prioridad}</MenuItem>
                                 })
                                 }
                              </Select>
                              <FormHelperText>{error === "idprioridad" ? "Prioridad es un campo requerido" : ""}</FormHelperText>
                           </FormControl>
                        </Grid>
                       {taskEdit?.idcampananegocio&&(<Grid item xs={visibleCampaing?6:12}>
                           <FormControlLabel
                              value="end"
                              control={<Checkbox checked={visibleCampaing ? true : false} onClick={() => { setVisibleCampaing(!visibleCampaing),setIdCampaign(null),setIdAsignado(null),setIdCliente(null),setFormCliente(null),setVisibleClientLeads(false),getFeedsData(),getCampaingbyCliente() }} />}
                              label="Asignar a una Campaña"
                              labelPlacement="end"
                           />
                        </Grid>)}
                        {!campaingDataBoolean&&(<Grid item xs={visibleCampaing?6:12}>
                           <FormControlLabel
                              value="end"
                              control={<Checkbox checked={visibleCampaing ? true : false} onClick={() => { setVisibleCampaing(!visibleCampaing),setIdCampaign(null),setIdAsignado(null),setIdCliente(null),setFormCliente(null),setVisibleClientLeads(false),getFeedsData(),getCampaingbyCliente() }} />}
                              label="Asignar a una Campaña"
                              labelPlacement="end"
                           />
                        </Grid>)}
                        {visibleCampaing && (
                           // <Grid item xs={6} >
                           //    <FormControl fullWidth>
                           //       <Grid>
                           //          <InputLabel id="idcampananegocio">Seleccionar</InputLabel>
                           //       </Grid>
                           //       <Select
                           //          sx={{bgcolor:'#fff'}}
                           //          labelId="idcampananegocio"
                           //          id="idcampananegocio"
                           //          size="small"
                           //          //error={error === "idcampananegocio" ? true : false}
                           //          fullWidth
                           //          name="idcampananegocio"
                           //          value={idcampananegocio}
                           //          label="Campaña"
                           //          onChange={handleInputChange}
                           //          startAdornment={
                           //               <InputAdornment position="start">
                           //                 <CampaignIcon />
                           //               </InputAdornment>
                           //           }
                           //       >
                           //          {listCampaing.map((option, i) => {
                           //             return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                           //          })
                           //          }
                           //       </Select>
                           //    </FormControl>
                           // </Grid>
                           <Grid item xs={6} >
                            <Autocomplete
                               autoComplete
                               sx={{bgcolor:'#fff'}}
                               size='small'
                               includeInputInList
                               value={idCampaign}
                               onChange={(e, campaign) => {
                                  eventCampaing(campaign);
                                  console.log(campaign)
                               }}
                               id="campaign"
                               options={dataCampaign}
                               getOptionLabel={(option) => option.nombre}
                               renderInput={(params) => <TextField {...params} label="Campaña" error={error === "campaign" ? true : false} helperText={error === "campaign" ? "Campaign es un campo requerido" : ""} />}
                            />
                         </Grid>
                        )}
                          {(!campaingDataBoolean || data.user.role != ROLE_VENDEDOR) && <Grid item xs={12} >
                           <Autocomplete
                              //disabled={isDisabled}
                              autoComplete
                              sx={{bgcolor:'#fff'}}
                              size='small'
                              includeInputInList
                              value={idasignado}
                              onChange={(e, asignado) => {
                                 setIdAsignado(asignado)
                                 if(idCampaign && asignado)
                                 {
                                    getContactandUserAndCampaing(idCampaign.id,asignado.idusuario)
                                 }
                                 idasignado !== null && setError("");
                                // DataUsarioCliente(asignado.idusuario)
                              }}
                              id="asignado"
                              options={dataUser}
                              getOptionLabel={(option) => option.nombre}
                              renderInput={(params) => <TextField {...params} label={"Atendido por..*"} error={error === "asignado" ? true : false} helperText={error === "asignado" ? "Vendedor es un campo requerido" : ""} />}
                           />
                        </Grid>}
                      {/* {visibleClientLeads?(
                              <Grid item xs={12} >
                              <Autocomplete
                                 autoComplete
                                 sx={{ bgcolor: '#fff' }}
                                 size='small'
                                 includeInputInList
                                 value={formCliente}
                                 onChange={(e, form_cliente) => {
                                    setFormCliente(form_cliente)

                                 }}
                                 id="cliente"
                                 options={dataFormClientes}
                                 getOptionLabel={(option: any) => (option.inputs || '')}
                                 renderInput={(params) => <TextField {...params} label="Cliente Leads*" error={error === "cliente" ? true : false} helperText={error === "cliente" ? "Cliente es un campo requerido" : ""} />}
                              />
                           </Grid>
                        ):( <Grid item xs={12} >
                           <Autocomplete
                              //disabled={isDisabled}
                              autoComplete
                              sx={{bgcolor:'#fff'}}
                              size='small'
                              includeInputInList
                              value={idCliente}
                              onChange={(e, cliente) => {

                                 setIdCliente(cliente)
                              }}
                              id="cliente"
                              options={dataClientes}
                              getOptionLabel={(option:any) => (option.nombre_rz || '')}
                              renderInput={(params) => <TextField {...params} label="Cliente*" error={error === "cliente" ? true : false} helperText={error === "cliente" ? "Cliente es un campo requerido" : ""} />}
                           />
                        </Grid>)} */}

                        <Grid item xs={12} md={6} >
                           <TextField

                              fullWidth
                              error={error === "fechacreacion" ? true : false}
                              helperText={error === "fechacreacion" ? " La Fecha de Inicio es un campo requerido" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              sx={{bgcolor:'#fff'}}
                              size="small"
                              label="Fecha Inicio"
                              id="fechacreacion"
                              name="fechacreacion"
                              type="date"
                              autoFocus
                              value={fechacreacion}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              error={error === "horainicio" ? true : false}
                              helperText={error === "horainicio" ? " La Hora de Inicio es un campo requerido" : ""}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              sx={{bgcolor:'#fff'}}
                              size="small"
                              label="Hora Inicio"
                              id="horainicio"
                              name="horainicio"
                              type="time"
                              autoFocus
                              value={horainicio}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField

                              fullWidth
                              error={error === "fechacierre" ? true : false}
                              helperText={error === "fechacierre" ? " La Fecha de Fin es un campo requerido" : error === "fechacierremenor" ? "Seleccione una fecha superior" : ""}
                              InputLabelProps={{ shrink: true, required: true }}
                              sx={{bgcolor:'#fff'}}
                              size="small"
                              id="fechacierre"
                              label="Fecha Fin"
                              name="fechacierre"
                              type="date"
                              autoFocus
                              value={fechacierre}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} md={6} >
                           <TextField
                              error={error === "horafinmenor" ? true : false}
                              helperText={error === "horafinmenor" ? "Seleccione una hora superior" : ""}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              sx={{bgcolor:'#fff'}}
                              size="small"
                              id="horafin"
                              label="Hora Fin "
                              name="horafin"
                              type="time"
                              value={horafin}
                              onChange={handleInputChange}
                           />
                        </Grid>
                        <Grid item xs={12} >
                           <TextField
                              fullWidth
                              error={error === "comentarios" ? true : false}
                              helperText={error === "comentarios" ? " La Nota es un campo requerido" : ""}
                              sx={{bgcolor:'#fff'}}
                              size="small"
                              label={Received?"Registrar datos de la llamada":'AGREGAR NOTA'}
                              id="comentarios"
                              inputProps={{
                                 maxLength: 200,
                               }}
                              name="comentarios"
                              multiline
                              value={comentarios}
                              onChange={handleInputChange}
                              InputProps={{
                                 startAdornment: (
                                   <InputAdornment position="start">
                                     <NoteAddIcon />
                                   </InputAdornment>
                                 ),
                               }}
                           />
                        </Grid>


                        {/* <Grid item xs={visibleRepeat?4:12}>
                           <FormControlLabel
                              value="end"
                              control={<Checkbox checked={visibleRepeat ? true : false} onClick={() => { setVisibleRepeat(!visibleRepeat) }} />}
                              label="Repetir"
                              labelPlacement="end"
                           />
                        </Grid>
                        {visibleRepeat && (
                           <Grid item xs={8} >
                              <FormControl fullWidth>
                                 <Grid>
                                    <InputLabel id="tipo">Seleccionar</InputLabel>
                                 </Grid>
                                 <Select
                                    sx={{bgcolor:'#fff'}}
                                    labelId="idfrecuencia"
                                    id="idfrecuencia"
                                    size="small"
                                    fullWidth
                                    name="idfrecuencia"
                                    value={idfrecuencia}
                                    label="TipoRepeticion"
                                    onChange={handleInputChange}
                                    startAdornment={
                                       <InputAdornment position="start">
                                         <RepeatIcon />
                                       </InputAdornment>
                                   }
                                 >
                                    {dataFrecuencia.map((option, i) => {
                                       return <MenuItem key={i} value={option.id}>{option.nombre}</MenuItem>
                                    })
                                    }
                                 </Select>
                              </FormControl>
                           </Grid>
                        )} */}
                        <Grid item xs={12} md={6} >
                           {taskEdit ? (
                              <Button
                                 onClick={() => {
                                    setTaskEdit(null)
                                    setOpen(false)
                                    LimpiarFormulario();
                                 }}
                                 variant="contained"
                                 fullWidth
                                 color="error"
                                 endIcon={<CancelIcon/>}
                                 sx={{
                                    background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                              >
                                 Cancelar Edición
                              </Button>) : (<Button
                                 onClick={() => {
                                    LimpiarFormulario();
                                 }}
                                 variant="contained"
                                 fullWidth
                                 color="error"
                                 endIcon={<CancelIcon/>}
                                 sx={{
                                    background: '#bf6c00', color: '#fff', mt: '10px', '&:hover': {
                                       bgcolor: '#bf6c00'
                                    }
                                 }}
                              >
                                 Cancelar
                              </Button>)}

                        </Grid>
                        <Grid item xs={12} md={6} >
                           <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              endIcon={taskEdit ? <EditIcon /> : <SaveIcon/>}
                              sx={{background:'#155172', mt: "10px",color:'#fff', '&:hover':{
                                 bgcolor:'#155172'
                                }}}
                           >
                              {taskEdit ? 'Actualizar' : 'Agregar'}
                           </Button>
                        </Grid>
                     </Grid>
                  </form>
               </Box>
            </Box>
         </Modal>
      </>
   );
}
