import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './ListCampaign.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Container, Divider, Grid, InputLabel, TextField, Typography, Button, linearProgressClasses, FormControl, Select, MenuItem, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import { TableData } from '@/components/common/Table';
import { productService } from '@/service/services/Product.service';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import { Link } from 'react-router-dom';
import { ROUTE_PRODUCT_CREATE } from '@/toolbox/constants/route-map';
import { ProductoSoftnetService } from '@/service/api/services/ProductoSoftnet.service';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TOKEN_SOFTNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { sincronizacionService } from '@/service/services/Sincronizacion.service';
import { ROLE_ADMIN } from '@/toolbox/defaults/static-roles';
import { useLocalStorage } from '@/toolbox/hooks/local-storage.hook';
import { SpinnerGrow } from '@/components/common/Spinner';
import SyncIcon from '@mui/icons-material/Sync';
import { campaingService } from '@/service/services/Campaing.service';
import { TabsComponent } from '@/components/common/Tabs';

const header = [
   { name: 'codigo', label: 'Código', width: '' },
{ name: 'nombre', label: 'Nombre', width: '' },
{ name: 'precio', label: 'Precio', width: '', money:true },
// { name: 'stock', label: 'Stock', width: '',integer:true }
];
const headerPreventa = [
   { name: 'nombre', label: 'Nombre' },
   { name: 'fecha_inicio', label: 'Fecha de Creación' },
   { name: 'fecha_cierre', label: 'Fecha de Cierre' },
];
const headerPostventa = [
   { name: 'nombre', label: 'Nombre' },
   { name: 'fecha_inicio', label: 'Fecha de Creación' },
   { name: 'fecha_cierre', label: 'Fecha de Cierre' },
];
const headerGenerales = [
   { name: 'nombre', label: 'Nombre' },
   { name: 'fecha_inicio', label: 'Fecha de Creación' },
   { name: 'fecha_cierre', label: 'Fecha de Cierre' },
];

const dataAction = [
   { name: 'select', color: 'success'},
]
const dataActionPostventa = [
   { name: 'select', color: 'success'},
]
const dataActionPreventa = [
   { name: 'select', color: 'success'},
]
const dataActionGenerales = [
   { name: 'select', color: 'success'},
]
const labels = [{ label: 'POST-VENTA' }, { label: 'PRE-VENTA' }, { label: 'GENERALES' }]

export const ListCampaignView: React.FC<Props> = ({ }): JSX.Element => {
   const [dataProduct, setDataProduct] = useState<any>([])
   const [tablaBusqueda, setTablaBusqueda] = useState<any>([])
   const [busqueda, setBusqueda] = useState<any>("");
   const token = readLocalStorage(KEY_TOKEN_SOFTNET);
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   const [open, setOpen] = useState<boolean>(false);
   const [openSync, setOpenSync] = useState(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })


   const [search, setSearch] = useState<any>('');
   const [perPageData, setPerPageData] = useState<number>(5);
   const [pageData, setPageData] = useState<number>(1);
   const [Total, setTotal] = useState<any>(0);
   const [productPostVenta, setProductPostVenta] = useState<any>([]);
   const [productPreVenta, setProductPreVenta] = useState<any>([]);
   const [productGenerica, setProductGenerica] = useState<any>([]);
   const [valueForm, setValueForm] = useState<number>(0)
//    const dataAction = [
//       { name: 'select', color: 'primary' },
//   ]

   useEffect(() => {
      try{
         // getProduct();
         //  getCampaignProduct('000002222')
      }catch(e){
         console.log(e)
      }
   }, [pageData,perPageData])

   async function getProduct() {
      setOpen(true);
      const resp = await productService.getProduct(perPageData,pageData)
      setDataProduct(resp.data.products.data);
      setTablaBusqueda(resp.data.products)
      setTotal(resp.data.products.total)
      setOpen(false);
      // const resp = await ProductoSoftnetService.getProducto(token);
      // setDataProduct(resp?resp:[]);
   }

   const getCampaignProduct = async (code) => {
     if(code)
     {
      const resp = await campaingService.codProduct(code.codigo)
      console.log(resp)
      if(resp.data){
         setValueForm(1)
         setProductPostVenta(resp.data.postVenta);
          setProductPreVenta(resp.data.preVenta);
         setProductGenerica(resp.data.mantencion);
      }
     }
   }

   const PerPage = async (data) =>{
      setPerPageData(data)
      setPageData(0)
   }

   const page = async (data) =>{
      setPageData(data+1)
   }

   const getSearchProduct = async (search) => {
      try{
         const resp= await productService.getSearchProduct(search);
         setDataProduct(resp.data.products);
       }catch(e){
          console.log(e)
       }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  getSearchProduct(value)
               }
               if(value.length==0)
               {
                  // getProduct();
                  setDataProduct([]);
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };


   return (
      <Protected>
            <Backdrop
               sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 1, bgcolor: '#000' }}
               open={openSync}
            >

               <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{fontSize:'30px'}}><strong>Sincronizando...</strong></h1>
               </Grid>

            </Backdrop>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
           <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <SpinnerGrow/>
                     <h1 style={{marginLeft:'10px'}}><strong>Cargando...</strong></h1>
               </Grid>
         </Backdrop>
         <Container maxWidth='md'>
            {/* <Grid container justifyContent='space-between' spacing={3}>
               <Grid item xs={5} container alignItems="center">
               <Typography  sx={{color:'#155172', fontWeight:'700', fontSize:'2.8em'}} >Mis Productos</Typography>
               </Grid>
            </Grid> */}
            <Grid container spacing={1} >
               <Grid item xs={12}>
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="search"
                        value={search}
                        onChange={handleInput}
                        placeholder="Introduce un Nombre o Código Producto"
                        type="search"
                        id="password"
                        autoComplete="current-password"
                        size="small"
                     />
               </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
               <TableData
                  header={header}
                  data={dataProduct?dataProduct:[]}
                  action={dataAction}
                  RecuperarData={getCampaignProduct}
                  total={Total? Total:0}
                  perPage={PerPage}
                  page={page}
                  />
            </Grid>
         </Container>

         <Grid item xs={12} sx={{mt:4}}>
               <TabsComponent
                  labels={labels}
                  setValueForm={setValueForm}
                  tabValidate={valueForm}
                  childrenOne={
                     <>
                        <Grid container sx={{ mt: 1 }}>
                           <TableData
                              header={headerPostventa}
                              data={productPostVenta ? productPostVenta : []}
                              // colorHeader={'#3f9bcc'}
                               action={dataActionPostventa}
                              // total={Total? Total:0}
                              perPage={PerPage}
                              page={page}
                           />
                        </Grid>
                     </>
                  }
                  childrenTwo={
                     <>
                        <Grid container sx={{ mt: 1 }}>
                           <TableData
                              header={headerPreventa}
                              data={productPreVenta ? productPreVenta : []}
                              // colorHeader={'#3f9bcc'}
                               action={dataActionPreventa}
                              // total={Total? Total:0}
                              perPage={PerPage}
                              page={page}
                           />
                        </Grid>
                     </>
                  }
                  childrenThree={
                  <>
                     <Grid container sx={{ mt: 1 }}>
                        <TableData
                           header={headerGenerales}
                           data={productGenerica ? productGenerica : []}
                            action={dataActionGenerales}
                           // total={Total? Total:0}
                           perPage={PerPage}
                           page={page}
                        />
                     </Grid>
                  </>
                  }
               />
            </Grid>
      </Protected>
   )
};
