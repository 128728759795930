import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './ConfirmacionEmail.type';
import { InputAdornment, IconButton, Step, StepLabel, Button, Card, CircularProgress, Grid, CssBaseline, Container, Modal, Box, Divider, Typography, Alert, Snackbar, FormControl, Select, MenuItem, responsiveFontSizes, TextField } from '@mui/material'
import { ArrowBackIcon, ArrowDownIcon, SaveIcon, VisibilityIcon, VisibilityOffIcon } from "@toolbox/constants/icons";
import { Icon } from '@components/common/Icon';
import User from "@assets/img/user.png";
import { Link, useHistory } from 'react-router-dom'
import * as qs from 'qs'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { userService } from '@/service/services/User.service';
import { entityService } from '@/service/services/Entity.service';
import { ROUTE_ENTITY, ROUTE_LOGIN, ROUTE_USER } from '@toolbox/constants/route-map';
import { MenuLateral } from '@/components/common/LateralMenu';
import ConfirmEmail from "@assets/img/confirm-email.png";
import ErrorConfirmEmail from "@assets/img/error-confirm-email.jpg";
import { ConfirmationmailService } from '@/service/services/Confirmation.service';

export const ConfirmacionEmailView: React.FC<any> = (props): JSX.Element => {
   const history = useHistory();

   const [resp, setResp] = useState<any>(null)
   const [message, setMessage] = useState<any>('')
   const [emailValue, setEmailValue] = useState<any>('')

   const {source, email} = props.location && qs.parse(props.location.search.slice(1, -1));

   useEffect(()=>{

      if(email&& source){
        var decodeEmail;
         var decodeSource;
        var decodePassword;

        try {
           const password = 'hola'

          decodeEmail = atob(email);
          decodeSource = atob(source);
           decodePassword = btoa(password);
           setEmailValue(decodeEmail)

        // authSubmit(decodeUser,decodeRutEmpresa,decodePassword);
        } catch (error){
         }
       }else{
        //  if(session && session.token){
        //    props.history.push("/inicio");
        //  }else{
            props.history.push("/");
        //  }
       }
     }, []);


   const RespConfirmEmail = async () =>{


       const resp:any = await ConfirmationmailService.sendMailConfirmation(emailValue);

       if(resp?.data.status==true){
         setResp(true)
         setMessage(resp.data.descripcion)
       }else{
          setResp(false)
          setMessage(resp.data.descripcion)
       }

   }

   return (
      <>
         <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{mt:5}}
         >

            {resp ? (
               <>
                  <Typography variant='h5'>{message}</Typography>
                  <Typography>Confirmacion Exitosa</Typography>
                  <span>Se le envió un correo con sus credenciales </span>
                  <Box
                     component="img"
                     sx={{
                        width: "100px",
                        height: "100px",
                        p: 2,
                     }}
                     src={ConfirmEmail}
                  ></Box>
                   <Button
                  color='primary'
                  variant='contained'
                 >
                    <a href='https://crm.kyte.cl/login' style={{textDecoration:'none', color:'white'}}>IR</a>

               </Button>
               </>
            ) : ( resp==false?(<>
                  <Typography color='error' variant='h5'>Error</Typography>
                  <Box
                     component="img"
                     sx={{
                        width: "100px",
                        height: "100px",
                        p: 2,
                     }}
                     src={ErrorConfirmEmail}
                  ></Box>
                     <Typography>{message}</Typography></>):(
               <>

               <Typography variant='h5'>Confirmacion de Cuenta</Typography>
               <Grid item xs={8} md={3}>
                  <TextField
                        margin="normal"
                        fullWidth
                        //   id="rutempresa"
                        //   label="Rut-Empresa*"
                        //   name="rutempresa"
                        type="text"
                        autoFocus
                        value={emailValue}

                     />
               </Grid>
               <Button
                  color='success'
                  variant='contained'
                  onClick={() => {RespConfirmEmail()}}>
                  CONFIRMAR
               </Button>
                     </>))}
         </Grid>
      </>
   )
};
