import { reportingRepository } from "../repositories/Reporting.repository";

// export const productService = {
//    getProduct: () => {
//       return productRepository.getProduct()
//    },
// };


export const reportingService= {

    getReportingCampanas,
    getReportingCampanasById,
    getReportingCampanasLeads,
    getReportingCampanasLeadsById,
    getReportingLeadsChannel,
    getReportingLeadsChannelById,
    getUserSalesForMonth,
    getCampaignWin,
    getCampaignGanancias,
};

async function getReportingCampanas () {
   const resp= await reportingRepository.getReportingCampanas();

   return resp
}

async function getReportingCampanasById (id) {
   const resp= await reportingRepository.getReportingCampanasById(id);

   return resp
}

async function getReportingCampanasLeads () {
    const resp= await reportingRepository.getReportingCampanasLeads();

    return resp
 }

 async function getReportingCampanasLeadsById (id) {
   const resp= await reportingRepository.getReportingCampanasLeadsById(id);

   return resp
}


async function getReportingLeadsChannel () {
   const resp= await reportingRepository.getReportingLeadsChannel();

   return resp
}

 async function getReportingLeadsChannelById (id) {
   const resp= await reportingRepository.getReportingLeadsChannelById(id);

   return resp
}

async function getUserSalesForMonth () {
   const resp= await reportingRepository.getUserSalesForMonth();

   return resp
}
async function getCampaignWin () {
   const resp= await reportingRepository.getCampaignWin();

   return resp
}
async function getCampaignGanancias () {
   const resp= await reportingRepository.getCampaignGanancias();

   return resp
}
