import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';


export const sincronizacionRepository = {
    getModulosToSync: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const modulosync = await http.get<any>(`${API_URL_BASE}/v1/configuration/modulos?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = modulosync;
      return {
         data,
         error,
         message
      }
   },
   getSyncProduct: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const modulosync = await http.get<any>(`${API_URL_BASE}/softnet/api/insertProduct?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = modulosync;
      return {
         data,
         error,
         message
      }
   },
   getSyncCliente: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const modulosync = await http.get<any>(`${API_URL_BASE}/softnet/api/insertCliente?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = modulosync;
      return {
         data,
         error,
         message
      }
   },
   getSyncList: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const syncList = await http.get<any>(` ${API_URL_BASE}/softnet/sync/listEntity?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = syncList;
      return {
         data,
         error,
         message
      }
   },
   postAsync: async (): Promise<any>=> {
      const modulosync = await http.post<any>(`${API_URL_BASE}/softnet/sync`)
      const { data, error, message } = modulosync;
      return {
         data,
         error,
         message
      }
   },
}
