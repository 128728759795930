import { http } from '../http/http';
import { API_URL_BASE } from '@/toolbox/defaults/app';

export const ConfirmationmailRepository = {
   sendMailConfirmation: async (email) => {
      const resp = await http.post(`${API_URL_BASE}/v1/mail/acceptAcount`,{
         email: email
      })
      return resp
   }
}
