import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, ListItem, Chip, Autocomplete, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { Box, spacing } from '@mui/system';
import { SaveIcon, CancelIcon } from "@toolbox/constants/icons";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './Modal.sass'
import { productService } from '@/service/services/Product.service';
import { entityService } from '@/service/services/Entity.service';
import { campaingService } from '@/service/services/Campaing.service';
import { ROLE_ADMIN, ROLE_SUPERVISOR, ROLE_VENDEDOR } from "@/toolbox/defaults/static-roles";
import { useLocalStorage } from "@/toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage";

type ModalProps = {
  open: boolean,
  setOpen: any,
//   data?: any,
//   campana?: any,
//   estado?: any,
//   getContact?: any
}

export const ModalValidateTrasferClientCampaign: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element => {

   const [estado, setEstado] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
     open: false,
     severity: 'success',
     message: 'éxito',
     autoHideDuration: 3000,
  })

   const bodyModal = (
     <Box className='Modal'>
       <Box >
         <Typography id="modal-modal-title" variant="h6" component="h3" textAlign='center' sx={{color:'#3F9BCC',mt:1, mb:3, fontWeight:'700', fontSize:'1.6em'}}>
         Aldair es un prospecto flotante, mientras no lo enlaces a un prospecto o cliente creado en el kyte no podrás moverlo a otra campaña
         </Typography>
         <Grid container spacing={2}>
             <Grid item md={6}>
                 <Button
                 sx={{
                     background: '#155172', mt: "10px", color: '#fff', '&:hover': {
                        bgcolor: '#155172'
                     }
                  }}
                  fullWidth
                  style={{textTransform: "inherit"}}
               //   onClick={()=>{props.setDecision(1)
               //   props.setOpen(false)}}
                 >Cancelar</Button>
             </Grid>
             <Grid item md={6}>
                 <Button
                 sx={{
                     background: '#E4980D', mt: "10px", color: '#fff', '&:hover': {
                        bgcolor: '#E4980D'
                     }
                  }}
                  fullWidth
                  style={{textTransform: "inherit"}}
               //   onClick={()=>{props.setDecision(3)
               //   props.setOpen(false)}}
                 >
                  Enlazar Prospecto
                  </Button>
             </Grid>
         </Grid>
       </Box>
     </Box>
   )



   return (
     <div>
       <Modal
         open={props.open}
         onClose={() => {
           props.setOpen(false)

         }}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         {bodyModal}
       </Modal>
       <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
       >
         <Alert
           onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
           severity={snackBarConfig.severity}
           variant="filled"
         >
           {snackBarConfig.message}
         </Alert>
       </Snackbar>
     </div>
   );
}
