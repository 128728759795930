import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const ubigeoRepository = {
   getPais: async (): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/ubigeo/pais?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
         data,
         //data.map((raw)=>({
         //    id : raw.id,
         //    codigo: raw.codigo,
         //    pais: raw.pais
         // })),
         error,
         message
      }
   },
   getRegion: async (idPais:number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/ubigeo/region?idpais=${idPais}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
          data,
         //: data.map((raw)=>({
         //    id : raw.id,
         //    codigo: raw.codigo,
         //    region: raw.region,
         //    idpais: raw.idpais
         // })),
         error,
         message
      }
   },
   getProvincia: async (idRegion:number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/ubigeo/province?idregion=${idRegion}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
         data,
         // : data.map((raw)=>({
         //    id : raw.id,
         //    codigo: raw.codigo,
         //    provincia: raw.provincia,
         //    idregion: raw.idregion
         // })),
         error,
         message
      }
   },
   getComuna: async (idProvincia:number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/ubigeo/comuna?idprovince=${idProvincia}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
         data,
         // : data.map((raw)=>({
         //    id : raw.id,
         //    codigo: raw.codigo,
         //    comuna: raw.comuna,
         //    idprovincia: raw.idprovincia
         // })),
         error,
         message
      }
   },
   getComunaByName: async (name): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/ubigeo/name-comuna?description=${name}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
         data,
         error,
         message
      }
   },
   getUbigeo: async (idComuna:number): Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/ubigeo/${idComuna}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return{
         data,
         error,
         message
      }
   }


}
