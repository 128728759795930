import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA, DATA_CAMPAING_LEADS} from '@/toolbox/constants/local-storage';

export const LeadsFormRepository = {

   VisualizarImagen: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/getImagen`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   InsertSectionHeader: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/header`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   InsertSectionMain: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/main`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   InsertSectionUbicacion: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/ubicacion`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   InsertSectionForm: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/formulario`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   InsertSectionVideo: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/hipervinculo`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   InsertSectionMain2: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/main2`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   InsertSectionFooter: async (imagen) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/footer`,imagen)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   CreateViewForm: async (data) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms`,data)
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   getFormTemplate: async (id) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp= await http.get<any>(`${API_URL_BASE}/v1/forms/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp;
   },

   generateBrochureEmail: async (id, email, id_detallecampana, idcliente): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/import/Html`, { id: id, email: email, id_detallecampana: id_detallecampana, idcliente: idcliente,id_empresa:id_empresa })
      return resp;
   },

   editUriBrochure: async (id, data) : Promise<any> => {
      const resp= await http.patch<any>(`${API_URL_BASE}/v1/forms/editUri/brochure/${id}`,
      {
         uri_brochure: data.uriRedireccion,
         label_button: data.labelButton
      })
      return resp;
   },

   validateFormView: async (id) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp= await http.get<any>(`${API_URL_BASE}/v1/forms/validate/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp;
   },

   editImage: async (id, data) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/editImage/${id}`,data);
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   editText: async (id, data) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/editText/${id}`,data);
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   listFormView: async () : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const id_empresa = dataUser?.user?.idempresacliente;
      const resp= await http.get<any>(`${API_URL_BASE}/v1/forms/listFormView/form?&id_empresa=${id_empresa}`)
      return resp;
   },

   copyForm: async (id, data) : Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const datacampana = readLocalStorage(DATA_CAMPAING_LEADS)
      // console.log(data)
      const rut_empresa = dataUser?.user?.rut_empresa;
      const resp= await http.post<any>(`${API_URL_BASE}/v1/forms/copyForm/${id}`,{idcampana:datacampana.id,rut_empresa:rut_empresa});
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   HistoryViewLanding: async (data) : Promise<any> => {
      const resp= await http.post<any>(`${API_URL_BASE}/v1/import/history`,data);
      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },


}

