import { Typography } from "@mui/material";

export const DatosCliente: React.FC<any> = (props): JSX.Element => {
    const { dataArriendo } = props;

    const containerStyle = {
        minHeight: "200px",
        background: "#fff",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px", // Espacio inferior para separar de otros elementos
    };

    const titleStyle = {
        color: "#155172",
        fontWeight: "bold",
        marginBottom: "20px", // Separación entre el título y el contenido
    };

    const contentStyle = {
        marginTop: "20px",
    };

    return (
        <div style={containerStyle}>
            <Typography variant="h4" sx={titleStyle}>
                Datos del Cliente
            </Typography>
            <div style={contentStyle}>
                <Typography>
                    <strong>RUT:</strong> {dataArriendo?.rut_cliente}
                </Typography>
                <Typography>
                    <strong>Nombre:</strong> {dataArriendo?.nombre_rz}
                </Typography>
                <Typography>
                    <strong>Giro:</strong> {dataArriendo?.giro}
                </Typography>
                <Typography>
                    <strong>Email:</strong> {dataArriendo?.email}
                </Typography>
            </div>
        </div>
    );
};
